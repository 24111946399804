/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import {
  PatientJourneyType,
  StepData,
  StepStatus,
  TimelineDataType,
  TimelineType,
} from 'src/types';
import { RootState } from '..';

const initialState: TimelineType = {};

type PayloadType = {
  payload: {
    patientId: string;
    records: { medicalTimeline: TimelineDataType[]; total: number };
  };
};

interface PastJourneyType {
  journey: PatientJourneyType;
}

const recordSlice = createSlice({
  name: 'record',
  initialState,
  reducers: {
    setTimeline: (state: TimelineType, action: PayloadType) => {
      const { patientId, records } = action.payload;
      state[patientId] = records;
    },
    deletePastJourneyStep: (
      state: TimelineType,
      action: {
        payload: { patientId: string; journeyId: string; stepId: string };
      },
    ) => {
      const { patientId, journeyId, stepId } = action.payload;
      const patientRecords = { ...state[patientId] };
      const journeyIdx = patientRecords.medicalTimeline.findIndex((record) => {
        const r = record as PastJourneyType;
        return r?.journey?.id === journeyId;
      });
      (
        patientRecords.medicalTimeline[journeyIdx] as PastJourneyType
      ).journey.steps = (
        patientRecords.medicalTimeline[journeyIdx] as PastJourneyType
      ).journey.steps.filter((s) => s.id !== stepId);

      state[patientId] = patientRecords;
    },
    updatePastJourneyStepStatus: (
      state: TimelineType,
      action: {
        payload: {
          patientId: string;
          journeyId: string;
          stepId: string;
          status: StepStatus;
        };
      },
    ) => {
      const { patientId, journeyId, stepId, status } = action.payload;
      const patientRecords = { ...state[patientId] };
      const journeyIdx = patientRecords.medicalTimeline.findIndex((record) => {
        const r = record as PastJourneyType;
        return r?.journey?.id === journeyId;
      });
      (
        patientRecords.medicalTimeline[journeyIdx] as PastJourneyType
      ).journey.steps.map((s, i) => {
        if (s.id === stepId) {
          (
            patientRecords.medicalTimeline[journeyIdx] as PastJourneyType
          ).journey.steps[i] = {
            ...s,
            status,
          };
        }
        return s;
      });
      state[patientId] = patientRecords;
    },
    updatePastJourneyStepActionItem: (
      state: TimelineType,
      action: {
        payload: {
          patientId: string;
          journeyId: string;
          stepId: string;
          arrIdx: number;
          checked: boolean;
          step: StepData;
        };
      },
    ) => {
      const { patientId, journeyId, arrIdx, stepId, checked, step } =
        action.payload;
      const patientRecords = { ...state[patientId] };
      const journeyIdx = patientRecords.medicalTimeline.findIndex((record) => {
        const r = record as PastJourneyType;
        return r?.journey?.id === journeyId;
      });
      (
        patientRecords.medicalTimeline[journeyIdx] as PastJourneyType
      ).journey.steps.map((s, i) => {
        if (s.id === stepId) {
          (
            patientRecords.medicalTimeline[journeyIdx] as PastJourneyType
          ).journey.steps[i] = {
            ...s,
            status: checked ? 'STARTED' : s.status,
            eventLogs: step.eventLogs,
            updatedAt: step.updatedAt,
            actions: s.actions.map((a, idx) =>
              idx === arrIdx ? { ...a, isCompleted: checked } : a,
            ),
          };
        }
        return s;
      });
      state[patientId] = patientRecords;
    },
  },
});

export const getPatientRecordById =
  (patientId: string, recordId: string) => (state: RootState) => {
    return state?.records?.[patientId]?.medicalTimeline?.find(
      (record) => record._id === recordId,
    );
  };

export const getPastJourney =
  (patientId: string, journeyId: string) => (state: RootState) => {
    const { records } = state;
    const foundRecord = records?.[patientId]?.medicalTimeline?.find(
      (record) => {
        const r = record as PastJourneyType;
        return r?.journey?.id === journeyId;
      },
    ) as PastJourneyType;
    return foundRecord?.journey;
  };

export const getStepInPastJourney =
  (patientId: string, journeyId: string, stepId: string) =>
  (state: RootState) => {
    const { records } = state;
    const foundRecord = records?.[patientId]?.medicalTimeline?.find(
      (record) => {
        const r = record as PastJourneyType;
        return r?.journey?.id === journeyId;
      },
    ) as PastJourneyType;

    return foundRecord?.journey?.steps.filter((step) => step.id === stepId)[0];
  };

export const {
  setTimeline,
  deletePastJourneyStep,
  updatePastJourneyStepStatus,
  updatePastJourneyStepActionItem,
} = recordSlice.actions;

export default recordSlice.reducer;
