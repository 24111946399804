import React from 'react';
import { RecordTemplate } from 'src/types';
import { TimelineType } from './provider';

export type TimelineContextType = {
  timeline: TimelineType;
  setTimeline: React.Dispatch<React.SetStateAction<TimelineType>>;
  showTimeLine: boolean;
  toggleShowTimeLine: React.Dispatch<React.SetStateAction<boolean>>;
  recordTemplates: Record<string, RecordTemplate>;
  setRecordTemplates: React.Dispatch<
    React.SetStateAction<Record<string, RecordTemplate>>
  >;
};

const RecordContext = React.createContext({} as TimelineContextType);

export default RecordContext;
