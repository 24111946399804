import { ThreeDots, BinIcon } from 'src/assets/icons';
import { useAlert, useStaff, useStepTemplates, useToast } from 'src/state';
import { Ellipsis, EllipsisButton } from '../styled';

interface Props {
  id: string;
  row: Record<string, unknown>;
}

export const StepsActionColumn: React.FC<Props> = ({ id, row }) => {
  const {  hasPermission  } = useStaff();
  const { showToast } = useToast();
  const { showAlert, removeAlert } = useAlert();
  const { useDeleteStepTemplate } = useStepTemplates();
  const { deleteStepTemplate } = useDeleteStepTemplate();

  const handleDelete = () => {
    if (row?.isGlobalClone) {
      return showToast('Cannot delete a global template', 'error');
    }
    showAlert({
      title: 'Confirm Delete',
      message: `Do you want to delete ${row.name}`,
      confirm: () =>
        deleteStepTemplate(id)?.then((res) => {
          if (res?.data) {
            removeAlert();
          }
        }),
    });
  };

  return !row.isGlobalClone ? (
    <>
      <ThreeDots className="icon" />
      <Ellipsis gap={1} justify="flex-end">
        {hasPermission("DELETE_STEP") && (
          <EllipsisButton
            toolTipTitle="Delete"
            variant="text"
            data-testid="delete-template-btn"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <BinIcon />
          </EllipsisButton>
        )}
      </Ellipsis>
    </>
  ) : null;
};
