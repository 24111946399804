import * as React from 'react';
import { PaginatedTemplates, RecordTemplate } from 'src/types';
import NoteTemplateContext from './context';

export interface NoteTemplateProps {
  getNoteTemplates: PaginatedTemplates,
  defaultNoteTemplate: RecordTemplate
};

export type NoteTemplateContextType = {
  initialNoteTemplates: NoteTemplateProps;
  setInitialNoteTemplates: React.Dispatch<React.SetStateAction<NoteTemplateProps>>;
};

const NoteTempProvider: React.FC = ({ children }) => {
  const [initialNoteTemplates, setInitialNoteTemplates] =
    React.useState<NoteTemplateProps>({} as NoteTemplateProps);

  return (
    <NoteTemplateContext.Provider
      value={{ initialNoteTemplates, setInitialNoteTemplates }}
    >
      {children}
    </NoteTemplateContext.Provider>
  );
};

export default NoteTempProvider;