/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import { Button, Column, H6, Row, Text } from 'src/components';
import {
  currencyformatter,
  invoiceStyles,
  printContent,
  theme,
} from 'src/utils';
import { Invoice, InvoiceItem } from 'src/types';
import moment from 'moment';
import { useStaff } from 'src/state';
import { WrappedText } from 'src/modals/Inventory/styled';
import mixpanel from 'mixpanel-browser';
import {
  ImagePreview,
  PreviewHeader,
  PreviewInner,
  PreviewWrapper,
  TD,
  TR,
  TableContent,
} from './styled';
import { getTotals, previewHeaders } from './constants';
import SummaryInfo from './SummaryInfo';

const getOOPAmount = (items: InvoiceItem[]) =>
  items?.reduce((acc, item) => {
    if (item.isHMOPayable === 'OOP' || item.isHMOPayable === false)
      acc += item.totalCost || 0;
    return acc;
  }, 0);

const getHMOAmount = (items: InvoiceItem[]) =>
  items?.reduce((acc, item) => {
    if (item.isHMOPayable === 'HMO' || item.isHMOPayable === true)
      acc += item.totalCost || 0;
    return acc;
  }, 0);

const getReturned = (items: InvoiceItem[]) =>
  items?.reduce((acc, item) => {
    if (item.returned?.length) {
      item.returned.map((itm) => {
        acc.push({
          ...item,
          units: itm.quantity,
          totalCost: (item.unitCost || 0) * itm.quantity,
          reason: itm.reason,
          returnedQty: itm.quantity,
        });
        return itm;
      });
    }
    return acc;
  }, [] as InvoiceItem[]);

const InvoicePrintPreview: React.FC<{
  invoice: Invoice;
}> = ({ invoice: currentInvoice }) => {
  const { staff } = useStaff();
  const defaultFacility = staff.getStaff.facility;
  const sums = {
    hmoAmount: getHMOAmount([...(currentInvoice?.items || [])]),
    oopAmount: getOOPAmount([...(currentInvoice?.items || [])]),
  };
  const invoice: Invoice = {
    ...currentInvoice,
    ...sums,
  };
  const totals = getTotals(invoice);
  const invoiceRef = useRef<HTMLDivElement>(null);
  const lastInvItem = invoice?.items?.filter((itm) => itm?.units !== 0)?.[
    invoice?.items?.length - 1
  ];
  const lastTotalItem = totals?.[totals.length - 1];
  return (
    <>
      <PreviewWrapper className="col" ref={invoiceRef} id="print-invoice">
        <PreviewInner className="col inner gap-1" gap={1}>
          <PreviewHeader
            className="header row"
            justify="space-between"
            width="100%"
            modStyles={{ px: 1.2, pb: 1.2, pt: 2 }}
          >
            <Row className="row">
              <ImagePreview className="img-preview">
                <img src={defaultFacility?.organisation.logo} alt="file" />
              </ImagePreview>
            </Row>
            <Column className="col gap-1" gap={1}>
              <Column className="col">
                <H6>INVOICE</H6>
                <Text
                  className="inv-id-orange"
                  size="xs"
                  weight="bold"
                  color={theme.secondary[700]}
                >
                  {invoice?.invoiceId}
                </Text>
              </Column>
              <SummaryInfo
                labelColor={900}
                label="Facility Name"
                value={defaultFacility.organisation.name}
              />
            </Column>
          </PreviewHeader>
          <Column
            modStyles={{ px: 1.2 }}
            gap={0.9}
            className="col px-1-2 gap-1"
          >
            <Row
              justify="space-between"
              width="100%"
              className="row justify-between"
            >
              {invoice.patient ? (
                <SummaryInfo
                  labelColor={900}
                  label="Invoice to"
                  value={`${invoice.patient?.user.firstName} ${invoice.patient?.user.lastName}`}
                />
              ) : (
                <SummaryInfo
                  labelColor={900}
                  label="Invoice Type"
                  value={invoice.invoiceType || ''}
                />
              )}
              <SummaryInfo
                labelColor={900}
                label="Payment Method"
                value={invoice?.paymentMethod || ''}
              />
            </Row>
            {invoice?.hmo && (
              <Row justify="space-between">
                <SummaryInfo
                  labelColor={900}
                  label="HMO Provider"
                  value={invoice?.hmo?.name || ''}
                />
                <SummaryInfo
                  labelColor={900}
                  label="HMO ID"
                  value={invoice?.hmo?.hmoId || ''}
                />
              </Row>
            )}
            <Row
              justify="space-between"
              width="100%"
              className="row justify-between"
            >
              <SummaryInfo
                labelColor={900}
                label="INVOICE ID"
                value={invoice?.invoiceId || ''}
              />
              <SummaryInfo
                labelColor={900}
                label="Date Generated"
                value={moment(invoice?.createdAt || '').format(
                  'h:mm a, MMM. D, YYYY',
                )}
              />
            </Row>

            <Row
              justify="space-between"
              width="100%"
              className="row justify-between"
            >
              <SummaryInfo
                labelColor={900}
                label="Marked By"
                value={`${invoice?.markedBy?.user.firstName} ${invoice?.markedBy?.user.lastName}`}
              />
              <SummaryInfo
                labelColor={900}
                label="Payment Date"
                value={moment(invoice?.updatedAt || '').format(
                  'h:mm a, MMM. D, YYYY',
                )}
              />
            </Row>
            <Row
              justify="space-between"
              width="100%"
              className="row justify-between"
            >
              <SummaryInfo
                labelColor={900}
                label="Facility Address"
                value={defaultFacility.address}
              />
              {invoice.requestedBy && (
                <SummaryInfo
                  labelColor={900}
                  label="Requested By"
                  value={`${invoice?.requestedBy?.user.firstName} ${invoice?.requestedBy?.user.lastName}`}
                />
              )}
            </Row>
          </Column>
          <TableContent className="table-content">
            <table>
              <thead>
                <TR className="border-b">
                  {previewHeaders.map((title, i) => (
                    <th key={i}>{title}</th>
                  ))}
                </TR>
              </thead>
              <tbody>
                {invoice?.items
                  ?.filter((itm) => itm?.units !== 0)
                  ?.slice(0, invoice?.items.length - 1)
                  .map(
                    (
                      { unitCost, units, isHMOPayable, description, totalCost },
                      i,
                    ) => (
                      <TR key={i} noBorder>
                        <TD>
                          <WrappedText>{description}</WrappedText>
                        </TD>
                        <TD>
                          {isHMOPayable === 'HMO' ? 'Hmo' : 'Out-of-pocket'}
                        </TD>
                        <TD>
                          {currencyformatter.format(
                            parseInt(Number(unitCost).toString(), 10),
                          )}
                        </TD>
                        <TD>{units}</TD>
                        <TD>
                          {currencyformatter.format(
                            parseInt(Number(totalCost).toString(), 10),
                          )}
                        </TD>
                      </TR>
                    ),
                  )}
                {lastInvItem && (
                  <TR noBorder>
                    <TD>
                      <WrappedText>{lastInvItem?.description}</WrappedText>
                    </TD>
                    <TD className="border-b" hasBorder>
                      {lastInvItem?.isHMOPayable === 'HMO'
                        ? 'Hmo'
                        : 'Out-of-pocket'}
                    </TD>
                    <TD className="border-b" hasBorder>
                      {currencyformatter.format(
                        parseInt(Number(lastInvItem?.unitCost).toString(), 10),
                      )}
                    </TD>
                    <TD className="border-b" hasBorder>
                      {lastInvItem?.units}
                    </TD>
                    <TD className="border-b" hasBorder>
                      {currencyformatter.format(
                        parseInt(Number(lastInvItem?.totalCost).toString(), 10),
                      )}
                    </TD>
                  </TR>
                )}
                {totals
                  ?.slice(0, totals.length - 1)
                  ?.map(({ summary, total }, i) => (
                    <TR key={i} noBorder>
                      <TD size="base" weight="bold" />
                      <TD size="base" weight="bold" />
                      <TD
                        className="totals"
                        size="base"
                        weight="bold"
                        hasBorder={i === totals.length - 2}
                        colored={i === totals.length - 1}
                      >
                        {summary}
                      </TD>
                      <TD
                        className="totals"
                        size="base"
                        weight="bold"
                        hasBorder={i === totals.length - 2}
                        colored={i === totals.length - 1}
                      />
                      <TD
                        className="totals"
                        size="base"
                        weight="bold"
                        hasBorder={i === totals.length - 2}
                        colored={i === totals.length - 1}
                      >
                        {currencyformatter.format(
                          parseInt(Number(total).toString(), 10),
                        )}
                      </TD>
                    </TR>
                  ))}
                {lastTotalItem && (
                  <TR noBorder>
                    <TD size="base" weight="bold" />
                    <TD size="base" weight="bold" />
                    <TD
                      className="totals border-t bg-gray"
                      size="base"
                      weight="bold"
                      colored
                    >
                      {lastTotalItem.summary}
                    </TD>
                    <TD
                      className="totals border-t bg-gray"
                      size="base"
                      weight="bold"
                      colored
                    />
                    <TD
                      className="totals border-t bg-gray"
                      size="base"
                      weight="bold"
                      colored
                    >
                      {currencyformatter.format(
                        parseInt(Number(lastTotalItem?.total).toString(), 10),
                      )}
                    </TD>
                  </TR>
                )}
              </tbody>
            </table>
          </TableContent>
          {getReturned(invoice.items)?.length > 0 && (
            <Column gap={0.3}>
              <Text size="sm" weight="semibold" modStyles={{ ml: 1 }}>
                Return & Refunds
              </Text>
              <TableContent className="table-content">
                <table>
                  <thead>
                    <TR className="border-b">
                      {previewHeaders.map((title, i) => (
                        <th key={i}>{title}</th>
                      ))}
                    </TR>
                  </thead>
                  <tbody>
                    {getReturned(invoice.items)?.map(
                      (
                        {
                          unitCost,
                          units,
                          isHMOPayable,
                          description,
                          totalCost,
                        },
                        i,
                      ) => (
                        <TR key={i} noBorder>
                          <TD>
                            <WrappedText>{description}</WrappedText>
                          </TD>
                          <TD>
                            {isHMOPayable === 'HMO' ? 'Hmo' : 'Out-of-pocket'}
                          </TD>
                          <TD>
                            {currencyformatter.format(
                              parseInt(Number(unitCost).toString(), 10),
                            )}
                          </TD>
                          <TD>{units}</TD>
                          <TD>
                            {currencyformatter.format(
                              parseInt(Number(totalCost).toString(), 10),
                            )}
                          </TD>
                        </TR>
                      ),
                    )}
                  </tbody>
                </table>
              </TableContent>
            </Column>
          )}
          {invoice?.note && (
            <Column modStyles={{ px: 1.2 }}>
              <Text size="xs" weight="semibold" modStyles={{ mb: 0 }}>
                Notes
              </Text>
              <Text size="xs" weight="bold">
                {invoice?.note}
              </Text>
            </Column>
          )}
        </PreviewInner>
      </PreviewWrapper>
      <Button
        width="max-content"
        modStyles={{ mt: 1.5 }}
        onClick={() => {
          mixpanel.track('Print Invoice Item', { feature: 'Patient Invoice' });
          printContent(invoiceRef, invoiceStyles);
        }}
      >
        Print Invoice
      </Button>
    </>
  );
};

export default InvoicePrintPreview;
