import { useState } from 'react';


interface UseModalParams {
  setModalParams: (newParams: Record<string, string | undefined>) => void;
  params: URLSearchParams;
  hash: string;
}

export const useModalParams = (): UseModalParams => {
  const [hash, ...otherValue] = window.location.hash.split("&");
  const hashValue = otherValue.length > 0 ? `?${otherValue.join(',')}`.replaceAll(",", '&') : ``;
  const [params, setParams] = useState<URLSearchParams>(new URLSearchParams(hashValue))


  const updateModalParams = (newParams: Record<string, string | undefined>) => {
    const hashParams = new URLSearchParams(hashValue);
  
    Object.entries(newParams).forEach(([key, value]) => 
      value === undefined ? 
        hashParams.delete(key) : 
        hashParams.set(key, value)
    );
    window.history.pushState(null, '', `${hash}${hashParams.toString() ? `&${hashParams.toString()}` : ''}`);

    setParams(hashParams);
  };

  return { setModalParams: updateModalParams, params, hash };
};
