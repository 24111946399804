import { Column, H6 } from 'src/components';
import { Modal } from 'src/containers';
import { useHMOs } from 'src/state';
import SettlementForm from './settlementForm';
import SuccessComponent from './successComponent';

const AddSettlement = () => {
  const { useCreateSettlement } = useHMOs()
  const { createSettlement, loading, data } = useCreateSettlement();

  return (
    <Modal width="35.5625rem">
      {data && <SuccessComponent data={data?.createSettlement?.settlement} />}
      {!data && (
        <Column gap={1}>
          <H6 weight="bold">Create New Settlement</H6>
          <SettlementForm
            loading={loading}
            createSettlement={createSettlement}
          />
        </Column>
      )}
    </Modal>
  );
};

export default AddSettlement;
