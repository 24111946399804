import {
  Button,
  Column,
  DropdownSearch,
  Input,
  Row,
  Select,
} from 'src/components';
import { useStaff, useMeta, usePatients, useStaffs } from 'src/state';
import { useFormik } from 'formik';
import Validator from 'validatorjs';
import moment from 'moment';
import { CURRENT_DATE, RULES, TIME_PATTERN } from 'src/constants';
import { addAppointmentValidation } from 'src/utils';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { FormValues } from './index';

interface AppointmentProps {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  loading: boolean;
  id?: string;
}

const AppointmentForm: React.FC<AppointmentProps> = ({
  initialValues,
  loading,
  onSubmit,
  id,
}) => {
  const { staff } = useStaff();
  const { metaData } = useMeta();
  const facilityId = staff.getStaff.facility.id;
  const types = metaData?.getAppointmentMeta?.types;
  const urlParams = new URLSearchParams(window.location.search);
  const facilityParams = urlParams.get('facility') || facilityId;
  const isSameFacility = facilityId === facilityParams;
  const isDoctor = staff.getStaff.staffGroup.name.toLowerCase() === 'doctor';
  const { useDoctors, useOwner } = useStaffs();
  const {
    activeDoctors,
    loading: doctorsLoading,
    data: doctorsData,
  } = useDoctors({
    status: 'Active',
    facility: facilityParams,
  });

  const { owner } = useOwner();

  const { usePatientsDropdown } = usePatients();
  const {
    loadMorePatients,
    loading: searchLoading,
    handleSearchChange,
    resetPatientsDropdown,
    hasNextPage,
    patientsDropdown,
  } = usePatientsDropdown();

  const { handleChange, handleSubmit, handleBlur, values, touched, errors } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: (_values) => {
        mixpanel.track('Created Appointment');
        return onSubmit(_values);
      },
      validate: (_values) => {
        const validation = new Validator(
          _values,
          {
            type: RULES.requiredfield,
            patient: RULES.requiredfield,
            provider: RULES.requiredfield,
            date: RULES.date,
            startTime: [
              ...RULES.startTime,
              `at_least:${_values.date}`,
              `max_time`,
            ],
            endTime: [
              `required`,
              `regex:${TIME_PATTERN}`,
              `greater:${_values.startTime}`,
              `duration:${_values.startTime}`,
            ],
            appointmentFrequency: RULES.requiredfield,
          },
          addAppointmentValidation,
        );
        validation.passes();
        return validation.errors.errors;
      },
    });

  const physiciansList = isSameFacility
    ? activeDoctors?.searchStaff?.staff
    : doctorsData?.searchStaff?.staff;

  const generateAppointmentFrequency = [
    `does not repeat`,
    `every day`,
    `every week on ${moment(values?.date).format('dddd')}`,
    `every 2 weeks on ${moment(values?.date).format('dddd')}`,
    `every month on the ${moment(values?.date).format('Do')}`,
    `every 3 months on the ${moment(values?.date).format('Do')}`,
    `every 6 months on the ${moment(values?.date).format('Do')}`,
  ];

  const minEndTime = moment(values?.startTime, 'hh:mm a')
    .add(30, 'minutes')
    .format('hh:mm a');

  return (
    <form onSubmit={handleSubmit}>
      <Column gap={1.25}>
        <Select
          label="Appointment Type"
          name="type"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.type ? (errors.type as string) : ''}
          value={values.type}
        >
          <option disabled value="">
            Select an appointment
          </option>
          {types?.map((type: string, idx: number) => (
            <option key={idx} value={type}>
              {type}
            </option>
          ))}
        </Select>
        <Select
          label="Attending Physician"
          name="provider"
          required
          loading={doctorsLoading && physiciansList?.length === 0}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.provider}
          error={touched.provider ? (errors.provider as string) : ''}
        >
          <option disabled value="">
            Select a physician
          </option>
          {owner?.id && (
            <option value={owner?.id}>
              {`Owner ${_.startCase(owner?.user?.fullName)}`}
            </option>
          )}
          {physiciansList &&
            physiciansList?.length > 0 &&
            physiciansList?.map((physician) => (
              <option key={physician?.id} value={physician?.id}>
                {`Dr. ${_.startCase(physician?.user.fullName)}`}
              </option>
            ))}
          {physiciansList?.length === 0 && isDoctor && (
            <option value={staff.getStaff.id}>
              {`Dr. ${_.startCase(staff.getStaff.user.fullName)}`}
            </option>
          )}
        </Select>
        <DropdownSearch
          label="Attach Patient"
          name="patient"
          title="Select a Patient"
          value={values.patient}
          onLoadMore={loadMorePatients}
          required
          hasNextPage={hasNextPage}
          onChange={handleChange}
          refetchLoading={searchLoading}
          loading={searchLoading}
          disabled={Boolean(id)}
          onBlur={handleBlur}
          error={touched.patient ? (errors.patient as string) : ''}
          onSearchInputChange={handleSearchChange}
          placeholder="search patients"
          // loading={searchLoading}
          onClose={resetPatientsDropdown}
          data={
            patientsDropdown?.patients as unknown as Record<string, unknown>[]
          }
          valueAccessor="id"
          labelAccessor={['user.firstName', 'user.lastName']}
        />
        <Column gap={1}>
          <Input
            type="date"
            value={values.date}
            label="Appointment Date"
            name="date"
            min={CURRENT_DATE}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.date ? (errors.date as string) : ''}
          />
          <Select
            label="Appointment Frequency"
            name="appointmentFrequency"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!values.date}
            value={values.appointmentFrequency}
            error={
              touched.appointmentFrequency
                ? (errors.appointmentFrequency as string)
                : ''
            }
          >
            <option disabled value="">
              Select a schedule
            </option>
            {generateAppointmentFrequency.map((type: string, idx: number) => (
              <option key={idx} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </Column>
        <Row align="flex-end" gap={1}>
          <Input
            type="time"
            label="Visit Time"
            required
            disabled={!values.date}
            name="startTime"
            max="23:29"
            value={values.startTime || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.startTime ? (errors.startTime as string) : ''}
          />
          <Input
            type="time"
            label=""
            name="endTime"
            disabled={!values.startTime}
            min={minEndTime}
            max="23:59"
            onChange={handleChange}
            value={values.endTime || ''}
            onBlur={handleBlur}
            error={touched.endTime ? (errors.endTime as string) : ''}
          />
        </Row>
        <Button
          type="submit"
          width="max-content"
          disabled={loading}
          isLoading={loading}
        >
          Save
        </Button>
      </Column>
    </form>
  );
};

export default AppointmentForm;
