/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { GET_INVOICE_LOGS } from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import {
  RootState,
  useAppSelector,
  useAppDispatch,
  useInvoiceLogsApis,
  setInvoiceLogs,
} from 'src/store';
import { useToast } from 'src/state';
import { RangeData } from 'src/components';
import { InvoiceLogs } from 'src/types';
import { getModalParams } from 'src/utils';

const useInvoiceLogs = (id?: string) => {
  const invoiceId = id || getModalParams('id');
  const allInvoiceLogs = useAppSelector(
    (state: RootState) => state.allInvoiceLogs,
  );
  const invoiceLogs = allInvoiceLogs[invoiceId];
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const limit = 30;

  const {
    getInvoiceLogs,
    isLogsLoading,
    isLogsError,
    fetchMoreLogs,
    isFetchMoreLogsLoading,
    isFetchMoreLogsError,
  } = useInvoiceLogsApis();

  const [filteredData, setFilteredData] = useState(invoiceLogs);

  const {
    handleSearchChange,
    searchData,
    searchLoading,
    searchValue,
    searchQuery,
  } = useSearch(GET_INVOICE_LOGS, {
    onCompleted: (d: unknown) => {
      const data = d as { getInvoiceLogs: InvoiceLogs };
      setFilteredData(data.getInvoiceLogs);
    },
  });

  const fetchInvoiceLogs = useCallback(
    async (pid?: string) => {
      await getInvoiceLogs({
        variables: {
          input: {
            invoiceId: pid || invoiceId,
            page: 1,
            limit,
          },
        },
        onCompleted(d) {
          const data = d.getInvoiceLogs;
          dispatch(setInvoiceLogs({ invoiceId: pid || invoiceId, logs: data }));
          setFilteredData(data);
        },
      });
    },
    [dispatch, getInvoiceLogs, invoiceId],
  );

  useEffect(() => {
    fetchInvoiceLogs();
  }, [fetchInvoiceLogs]);

  const [dateFilterApplied, setDateFilterApplied] = useState(false);
  const [dateRange, setDateRange] = useState<RangeData>({
    startDate: null as unknown as Date,
    endDate: new Date(''),
    key: 'selection',
  });

  const formattedRange = {
    startDate: moment(dateRange.startDate).format('Do MMM, yyyy'),
    endDate: moment(dateRange.endDate).format('Do MMM, yyyy'),
  };

  const getDateFilters = () => {
    if (dateFilterApplied) {
      return {
        startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
        endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
      };
    }
    return {};
  };

  const applyFilters = () => {
    setDateFilterApplied(true);

    searchQuery({
      variables: {
        input: {
          limit,
          page: 1,
          search: searchValue || '',
          startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
          endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
        },
      },
      onCompleted(data) {
        setFilteredData(data.getInvoiceLogs);
      },
    });
  };

  const clearFilters = () => {
    setFilteredData(invoiceLogs);
    setDateFilterApplied(false);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilteredData(invoiceLogs);
    }
    handleSearchChange(e, {
      limit,
      page: 1,
      invoiceId,
      ...getDateFilters(),
    });
  };

  const currentPage = Math.ceil((filteredData?.logs?.length as number) / limit);

  const fetchMore = async () => {
    await fetchMoreLogs({
      variables: {
        input: {
          limit,
          page: currentPage + 1,
          search: searchValue || '',
          invoiceId,
          ...getDateFilters(),
        },
      },
      onCompleted(d) {
        if (isFetchMoreLogsError) {
          showToast('Error fetching more records', 'error');
        } else {
          setFilteredData((prev) => ({
            ...prev,
            logs: [...prev.logs, ...d?.getInvoiceLogs?.logs],
          }));
          if (!(dateFilterApplied || searchValue)) {
            dispatch(
              setInvoiceLogs({
                invoiceId,
                logs: {
                  ...invoiceLogs,
                  logs: [...invoiceLogs.logs, ...d?.getInvoiceLogs?.logs],
                },
              }),
            );
          }
        }
      },
    });
  };

  return {
    limit,
    fetchMore,
    fetchInvoiceLogs,
    invoiceId,
    searchData,
    searchValue,
    clearFilters,
    applyFilters,
    formattedRange,
    onSearchChange,
    handleSearchChange,
    isLogsLoading,
    isLogsError,
    searchLoading,
    setDateRange,
    filteredData,
    dateFilterApplied,
    dateRange,
    currentPage,
    fetchMoreLoading: isFetchMoreLogsLoading,
  };
};

export { useInvoiceLogs };
