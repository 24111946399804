import { Table } from 'src/containers';
import {
  Row,
  IconButton,
  SearchInput,
  Column,
  Select,
  Card,
  Input,
  InputLabel,
  PasswordInput,
  Button,
  Chip,
} from 'src/components';
import { font, theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { ChildrenWrapper } from 'src/containers/Accordion/styled';

export const Ellipsis = styled(Row)`
  display: none;
  justify-content: center;
`;

export const ManageSearchInput = styled(SearchInput)`
  border-radius: 0.625rem;
  font-weight: ${font.weights.normal};
  font-size: ${font.sizes.base};
  background-color: ${theme.primary[100]};
  max-width: 25.125rem;
  &::placeholder {
    color: ${theme.primary[400]};
    font-weight: ${font.weights.normal};
    @media (max-width: 960px) {
      font-size: ${font.sizes.sm};
    }
  }
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
`;

export const ManagePasswordInput = styled(PasswordInput)`
  border-radius: 0.625rem;
  font-weight: ${font.weights.normal};
  font-size: ${font.sizes.base};
  background-color: ${theme.primary[100]};
  &::placeholder {
    color: ${theme.primary[400]};
    font-weight: ${font.weights.normal};
    @media (max-width: 960px) {
      font-size: ${font.sizes.sm};
    }
  }
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
`;

export const ManageInput = styled(Input)`
  border-radius: 0.625rem;
  font-weight: ${font.weights.normal};
  font-size: ${font.sizes.base};
  background-color: ${theme.primary[100]};
  &::placeholder {
    color: ${theme.primary[300]};
    font-weight: ${font.weights.normal};
    @media (max-width: 960px) {
      font-size: ${font.sizes.sm};
    }
  }
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
`;

export const ManageSelect = styled(Select)`
  border-radius: 0.625rem;
  font-weight: ${font.weights.normal};
  background-color: ${theme.primary[100]};
  color: ${theme.primary[400]};
  font-weight: ${font.weights.normal};
`;

export const ActionTable = styled(Table)<{
  fullHeight?: boolean;
  maxHeight?: string;
}>`
  ${({ fullHeight, maxHeight }) =>
    !fullHeight
      ? css`
          max-height: ${maxHeight || 'calc(100vh - 20rem)'};
        `
      : ''}
  thead {
    tr {
      th {
        @media (max-width: 960px) {
          padding: 1rem;
        }
      }
      th:last-child {
        text-align: center;
      }
    }
  }
  tr {
    position: relative
    width: max-content;
    th:last-child {
      text-align: right;
    }
    td:last-child {
      min-width: 150px;
      text-align: center;
    }
    td:first-child {
      padding-left: 1rem;
    }
  }
  tr:hover > td > .icon {
    display: none;
  };
  tr:hover > td > ${Ellipsis} {
    display: flex;
  }
  img {
    padding: 0;
  }
  .document-icon {
    path {
      stroke-width: 0;
      fill: ${theme.accent[500]}
    }  
  }
  .steps-icon {
    padding: 0.65rem 2rem;
    path {
      stroke-width: 0;
    } 
  }
  @media (max-width: 960px) {
    box-shadow: none;
    max-height: calc(100vh - 26rem);
  }
`;

export const EllipsisButton = styled(IconButton)`
  width: max-content;
  height: max-content;
  z-index: 20;
  svg: {
    width: 100%;
  }
  path {
    stroke-width: 0;
  }
`;

export const PageWrapper = styled(Column)`
  padding-top: 1rem;
`;

export const SettingsCard = styled(Card)`
  width: 100%;
  img {
    filter: drop-shadow(0px 10px 17px rgba(0, 0, 0, 0.29));
    padding: 0;
  }
  form {
    width: 100%;
  }
`;

export const UploadButton = styled(InputLabel)`
  height: max-content;
  background-color: ${theme.primary[700]};
  color: ${theme.white[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: ${font.sizes.base};
  font-weight: ${font.weights.semibold};
  width: max-content;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const OverflowContainer = styled(Row)`
  overflow-y: auto;
  max-height: 100%;
  padding: 1rem;
  @media print {
    width: 100vw;
    max-height: unset;
    max-width: unset;
  }
`;

export const SidePaddingContainer = styled(Column)`
  @media (max-width: 960px) {
    padding: 0 1rem;
    h5 {
      font-size: ${font.sizes.base};
    }
  }
`;

export const ResetButton = styled(Button)`
  font-size: ${font.sizes.sm};
  width: max-content;
  padding: 0.5rem;
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
  }
`;

export const TagChip = styled(Chip)`
  gap: 0.75rem;
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
    gap: 0.5rem;
  }
  button {
    padding: 0.25rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    @media (max-width: 960px) {
      padding: 0.15rem;
      width: 0.75rem;
      height: 0.75rem;
    }
    svg,
    path {
      width: 0.5rem;
      height: 0.5rem;
      stroke-width: 2.5;
      @media (max-width: 960px) {
        width: 0.35rem;
        height: 0.35rem;
        stroke-width: 1.5;
      }
    }
  }
`;

export const AccordionWrapper = styled(Column)`
  @media (max-width: 960px) {
    & > ${ChildrenWrapper} {
      padding: 0;
    }
  }
`;
