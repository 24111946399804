import {
  OngoingVisitsIcon,
  TodosIcon
} from 'src/assets/icons';
import { Row } from 'src/components';
import {
  TabContent,
  TabContentItem,
  TabHeaderItem,
  TabHeaders,
  Tabs,
} from 'src/containers/Tabs';
import { useStaff } from 'src/state';
import { TodoTab } from '../TodoTab';
import { AppointmentTab } from '../AppointmentTab';
import {
  FixedTabs,
  CalendarTabContent,
} from '../styled';

interface CalendarSideMenuTabHeaderProps {
  text: string;
  children: React.ReactNode;
}

export const CalendarSideMenuTabHeader: React.FC<
  CalendarSideMenuTabHeaderProps
> = ({ children, text }) => {
  return (
    <Row gap={1}>
      {children}
      {text}
    </Row>
  );
};


export const CalendarTabHeader = () => {
  const {hasPermission} = useStaff();
  return (
    <FixedTabs>
      <TabHeaders>
        {hasPermission("VIEW_CALENDAR") && (
          <TabHeaderItem id="ongoing_visit">
            <CalendarSideMenuTabHeader text="Ongoing Visits">
              <OngoingVisitsIcon />
            </CalendarSideMenuTabHeader>
          </TabHeaderItem>
        )}
        {hasPermission("VIEW_TODOS_LIST") && (
          <TabHeaderItem id="todos">
            <CalendarSideMenuTabHeader text="Todos">
              <TodosIcon />
            </CalendarSideMenuTabHeader>
          </TabHeaderItem>
        )}
      </TabHeaders>
    </FixedTabs>
  )
};


export const CalendarTab = () => {
  const {hasPermission} = useStaff();
  return (
    <Tabs>
      <CalendarTabHeader />
      <TabContent>
        <CalendarTabContent>
          {hasPermission("VIEW_TODOS_LIST") && (
            <TabContentItem id="todos">
              <TodoTab />
            </TabContentItem>
          )}
          {hasPermission("VIEW_CALENDAR") && (
            <TabContentItem id="ongoing_visit">
              <AppointmentTab />
            </TabContentItem>
          )}
        </CalendarTabContent>
      </TabContent>
    </Tabs>
  );
}