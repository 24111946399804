import React from 'react';
import { ToastContextType } from 'src/types';


import { initialState } from './reducer';

const ToastContext = React.createContext<ToastContextType>({
  state: initialState,
  showToast: () => null,
  removeToast: () => null,
});

export default ToastContext;
