import React, { useContext } from 'react';
import Validator from 'validatorjs';
import { TabContext } from 'src/containers';
import _ from 'lodash';
import moment from 'moment';
import {
  addPatientErrorMessages,
  addPatientValidation,
  getModalParams,
} from 'src/utils';
import { Patient, PatientProps } from 'src/types';
import { usePatientDetails } from 'src/pages/admin/PatientDetails/hooks/usePatientDetails';
import { FormProps } from '../index';
import { TabItems } from './tabItems';

interface AddPatientTabContentProps {
  setCompleted: (value: FormProps) => void;
  completed: FormProps;
  setData: (value: string) => void;
  isEditPatient: boolean;
  setPatientData: (value: PatientProps) => void;
}

export const AddPatientTabsContent: React.FC<AddPatientTabContentProps> = ({
  setCompleted,
  completed,
  setData,
  setPatientData,
  isEditPatient,
}) => {
  const id = getModalParams('id');
  const { patient } = usePatientDetails(id);
  const { setActiveTab, activeTab } = useContext(TabContext);

  const formValues = {
    basicInfo: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
    },
    otherInfo: {
      dateOfBirth: '',
      gender: '',
      maritalStatus: '',
      nationality: '',
      languageSpoken: '',
      secondaryPhoneNumber: '',
      homeAddress: '',
      workAddress: '',
      hmo: {
        hmoProviderId: '',
        hmo: '',
      },
    },
    nextOfKin: {
      firstName: '',
      lastName: '',
      relationshipToPatient: '',
      phoneNumber: '',
      emailAddress: '',
      homeAddress: '',
    },
    identity: {
      identityNumber: '',
      typeOfIdentity: '',
    },
    family: ''
  };

  const editFormValues = {
    basicInfo: {
      firstName: patient?.user.firstName || '',
      lastName: patient?.user.lastName || '',
      phoneNumber: patient?.basicInfo.phoneNumber || '',
      emailAddress: patient?.user.emails[0] || '',
      dateOfBirth:
        (moment(
          patient?.basicInfo.dateOfBirth || '',
          'YYYY-MM-DDTHH:mm:ssZ',
        ).format('YYYY-MM-DD') as string) || '',
      gender: patient?.basicInfo.gender || '',
      maritalStatus: patient?.basicInfo.maritalStatus || '',
      nationality: patient?.basicInfo.nationality || '',
    },
    otherInfo: {
      languageSpoken: patient?.otherInfo.languageSpoken || '',
      secondaryPhoneNumber: patient?.otherInfo?.secondaryPhoneNumber || '',
      homeAddress: patient?.otherInfo.homeAddress || '',
      workAddress: patient?.otherInfo?.workAddress || '',
      hmo: {
        hmoProviderId: patient?.otherInfo?.hmo?.hmoProviderId || '',
        hmo: patient?.otherInfo?.hmo?.id || '',
      },
    },
    nextOfKin: {
      firstName: patient?.nextOfKin[0].firstName || '',
      lastName: patient?.nextOfKin[0].lastName || '',
      relationshipToPatient: patient?.nextOfKin[0].relationshipToPatient || '',
      phoneNumber: patient?.nextOfKin[0].phoneNumber || '',
      emailAddress: patient?.nextOfKin[0].emailAddress || '',
      homeAddress: patient?.nextOfKin[0].homeAddress || '',
    },
    identity: {
      identityNumber: patient?.identity?.identityNumber || '',
      typeOfIdentity: patient?.identity?.typeOfIdentity || '',
    },
    family: patient?.family?.id || ''
  };

  const initialValues = isEditPatient ? editFormValues : formValues;

  const render = () => {
    switch (activeTab) {
      case 'add-patient-1':
        return setActiveTab(`add-patient-${0}`);
      case 'add-patient-2':
        return setActiveTab(`add-patient-${1}`);
      case 'add-patient-3':
        return setActiveTab(`add-patient-${2}`);
      case 'add-patient-0':
      default:
        return null;
    }
  };

  const basicInfoKeys = [
    'basicInfo.firstName',
    'basicInfo.lastName',
    'basicInfo.emailAddress',
    'basicInfo.phoneNumber',
  ];

  const otherInfoKeys = [
    'otherInfo.homeAddress',
    'otherInfo.secondaryPhoneNumber',
    'otherInfo.languageSpoken',
    'otherInfo.workAddress',
    'otherInfo.hmo.hmo',
    'otherInfo.hmo.hmoProviderId',
  ];

  const next = (addPatientValid: typeof addPatientValidation) => {
    switch (activeTab) {
      case 'add-patient-0':
        return !isEditPatient
          ? _.pick(addPatientValid, basicInfoKeys)
          : _.pickBy(addPatientValid, (_value, key) =>
              _.startsWith(key, 'basicInfo'),
            );

      case 'add-patient-1':
        return isEditPatient
          ? _.pick(addPatientValid, otherInfoKeys)
          : _.pickBy(addPatientValid, (_value, key) =>
              _.startsWith(key, 'otherInfo'),
            );

      case 'add-patient-2':
        return _.pickBy(addPatientValid, (_value, key) =>
          _.startsWith(key, 'nextOfKin'),
        );
      case 'add-patient-3':
      default:
        return !isEditPatient
          ? _.omit(addPatientValid, [
              'basicInfo.dateOfBirth',
              'basicInfo.gender',
              'basicInfo.maritalStatus',
              'basicInfo.nationality',
            ])
          : _.omit(addPatientValid, [
              'otherInfo.dateOfBirth',
              'otherInfo.gender',
              'otherInfo.maritalStatus',
              'otherInfo.nationality',
            ]);
    }
  };

  const checkValidity = (values: Record<string, unknown>) => {
    const addPatientValid = addPatientValidation;
    const { otherInfo, identity } = values as unknown as Patient;

    addPatientValid['otherInfo.hmo.hmo'] =
      (otherInfo.hmo.hmoProviderId as string).length > 0
        ? `string|required_if:${otherInfo.hmo.hmoProviderId}`
        : `string`;

    addPatientValid['otherInfo.hmo.hmoProviderId'] = (
      otherInfo.hmo.hmo as string
    ).length
      ? `string|required_if:${otherInfo.hmo.hmo}`
      : `string`;

    addPatientValid['identity.typeOfIdentity'] =
      identity?.identityNumber.length > 0
        ? `string|required_if:${identity?.identityNumber}`
        : `string`;

    addPatientValid['identity.identityNumber'] =
      identity?.typeOfIdentity.length > 0
        ? `string|required_if:${identity?.typeOfIdentity}`
        : `string`;

    const validation = new Validator(
      values,
      next(addPatientValid),
      addPatientErrorMessages,
    );
    validation.passes();

    const newState = {} as FormProps;
    const setSectionState = (
      section: keyof FormProps,
      state: 'invalid' | 'active',
    ) => {
      newState[section] = state;
    };

    const isSectionInvalid = (keys: string[], prefix: string) =>
      keys.some((value) => value.startsWith(prefix));

    if (activeTab === 'add-patient-3') {
      const keys = Object.keys(validation.errors.errors);

      if (isEditPatient) {
        setSectionState(
          'otherInfo',
          isSectionInvalid(keys, 'other') ? 'invalid' : 'active',
        );

        setSectionState(
          'basicInfo',
          isSectionInvalid(keys, 'basicInfo') ? 'invalid' : 'active',
        );
      } else {
        setSectionState(
          'otherInfo',
          otherInfoKeys.some((value) => keys.includes(value))
            ? 'invalid'
            : 'active',
        );
        setSectionState(
          'basicInfo',
          basicInfoKeys.some((value) => keys.includes(value))
            ? 'invalid'
            : 'active',
        );
      }

      setSectionState(
        'nextOfKin',
        isSectionInvalid(keys, 'nextOfKin') ? 'invalid' : 'active',
      );

      setSectionState(
        'family',
        isSectionInvalid(keys, 'family') ? 'invalid' : 'active',
      );

      setCompleted(newState);
    }
    return validation.errors.errors;
  };

  return (
    <TabItems
      render={render}
      setData={setData}
      completed={completed}
      setPatientData={setPatientData}
      setCompleted={setCompleted}
      isEditPatient={isEditPatient}
      initialValues={initialValues}
      checkValidity={checkValidity}
    />
  );
};
