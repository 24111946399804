import { gql } from '@apollo/client';
import { stepFieldsFragment } from '../Fragments/step';

export const GET_STEPS = gql`
  query GetSteps($input: GetStepsInput) {
    getSteps(input: $input) {
      total
      steps {
        ...StepFields
      }
    }
  }
  ${stepFieldsFragment}
`;

export const GET_STEP = gql`
  query GetStep($stepId: ID!) {
    getStep(stepId: $stepId) {
      ...StepFields
    }
  }
  ${stepFieldsFragment}
`;
