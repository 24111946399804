import 'moment/locale/en-gb';
import moment from 'moment';
import { ClockIcon, UserIcon } from 'src/assets/icons';
import { Avatar, Text, Column, DotIndicator } from 'src/components';
import { theme, generateAvatar } from 'src/utils';
import { UpdatedAppointment } from '../BigCalendar';
import { getColor } from '../index';
import { 
  CalendarText, 
  DateHeaderRow, 
  DoctorHeading, 
  EventWrapper,
  EventIndicator
} from '../styled';


interface ResourceProps {
  name: string;
  avatar: string;
}

interface DayHeaderProps {
  date?: Date;
}


export const ResourceHeaders: React.FC<ResourceProps> = ({
  name,
  avatar
}) => {
  const words = name?.split(" ")

  return (
    <DoctorHeading
      align="center"
      justify="flex-start"
      gap={0.5}
    >
      <Avatar size={45} src={avatar || generateAvatar(name)} />
      <Text transform='capitalize' size="sm" weight="semibold">
        {`${words?.[0]} ${words?.[1]?.[0]}.`}
      </Text>
    </DoctorHeading>
  )
}

export const EventContainer: React.FC<UpdatedAppointment> = ({
  title,
  start,
  end, 
  appointment,
}) => {
  const endTime = moment(end);
  const startTime = moment(start);
  const timeString = moment().toISOString();
  const duration = endTime.diff(startTime, 'minutes');
  const isSameTime = moment(appointment?.createdAt).isSame(timeString, "minutes");

  const { 
    status, 
    patient: {
      user: {
        firstName,
        lastName
      }
    } 
  } = appointment || {};

  const color = getColor(title as string);
  const isCancelled = status?.toLowerCase() === "cancelled";


  return (
    <Column
      minWidth="6.6875rem" 
      height="100%"
      className={appointment?.id}
      data-time={isSameTime ? "latest" : appointment?.createdAt}
      modStyles={{
        px: 0.75,
        br: 0.625,
        py: duration < 15 ? `0.25rem` : `0.5rem`,
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        bg: isCancelled ? "#8A0406" : (theme[color || "grey"][100])
      }}
    >
      <Column gap={0.5}>
        <EventWrapper align="center" gap={0.25}>
          <EventIndicator color={isCancelled ? 'white' : color} />
          <CalendarText
            weight="semibold"
            size="sm"
            color={isCancelled ? "white" : theme.primary[700]}
          >
            {title}
          </CalendarText>
        </EventWrapper>
        {duration >= 45 && (
          <EventWrapper gap={0.25} align="stretch">
            <ClockIcon color={isCancelled ? "white" : theme.grey[700]} />
            <CalendarText
              size="xs"
              weight="semibold"
              color={isCancelled ? "white" : undefined}
            >
              {`${moment(start).format("hh:mm a")} - 
              ${moment(end).format("hh:mm a")}`}
            </CalendarText>
          </EventWrapper>
        )}
        {duration >= 30 && (
          <EventWrapper gap={0.25} height="0.875rem" align="center">
            <UserIcon color={isCancelled ? "white" : theme.grey[700]} />
            <CalendarText
              size="xs"
              weight="semibold"
              transform='capitalize'
              color={isCancelled ? "white" : undefined}
            >
              {`${firstName} ${lastName?.[0]}.`}
            </CalendarText>
          </EventWrapper>
        )}
        {isCancelled && duration >= 30 && (
          <CalendarText size="xs" weight="semibold" color="white">
            Appointment Cancelled
          </CalendarText>
        )}
      </Column>
    </Column>
  )
};


export const DayHeader: React.FC<DayHeaderProps> = ({
  date
}) => {
  const currDate = moment(date).format('MMM DD') ===
    moment().format('MMM DD');

  return (
    <DateHeaderRow isToday={currDate}>
      <Column gap={0.25} align="center" width="100%">
        <CalendarText
          color={currDate ? theme.white[100] : theme.black[100]}
          weight="semibold"
          size="sm"
        >
          {moment(date).format('ddd')}
        </CalendarText>
        {currDate ?
          <DotIndicator color="red" /> :
          <CalendarText color={theme.grey[500]}>
            {moment(date).format('MMM DD')}
          </CalendarText>}
      </Column>
    </DateHeaderRow>
  )
};