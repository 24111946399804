import React from 'react';
import { Column, Row, Button, Avatar } from 'src/components';
import { usePatients } from 'src/state';
import { useFormikContext } from 'formik';
import { pinify, generateAvatar, theme } from 'src/utils';
import { Patient, PatientData } from 'src/types';
import { RegisteredPatientRow, RegisteredPatientText } from '../styled';


interface RegisteredPatientProps {
  patientInfo: PatientData;
  setContent: (value: PatientData) => void;
};

export const RegisteredPatient: React.FC<RegisteredPatientProps> = ({
  patientInfo,
  setContent
}) => {
  const { values } = useFormikContext();
  const { basicInfo } = values as Patient
  const { useRequestAccess } = usePatients();
  const { requestAccess, loading} = useRequestAccess()

  return (
    <RegisteredPatientRow align="center" gap={0.75}>
      <Avatar
        size={50}
        src={generateAvatar(`${patientInfo.user.firstName} ${patientInfo.user.lastName}`)}
      />
      <Column>
        <RegisteredPatientText weight="semibold" color={theme.primary[700]}>
          {`${patientInfo.user.firstName} ${patientInfo.user.lastName}`}
        </RegisteredPatientText>
        <Row justify="space-between" gap={3.4}>
          <RegisteredPatientText color={theme.primary[700]}>
            {patientInfo.user.emails.find(email => email === basicInfo.emailAddress) || patientInfo.user.emails[0] || "---"}
          </RegisteredPatientText>
          <RegisteredPatientText color={theme.primary[700]}>
            {pinify(patientInfo.basicInfo.phoneNumber)}
          </RegisteredPatientText>
        </Row>
      </Column>
      <Button
        disabled={loading}
        isLoading={loading}
        type="button"
        width="max-content"
        onClick={async() => {
          const result = await requestAccess(patientInfo?.id)
          if (result?.data) {
            setContent(patientInfo)
          }
        }}
      >
        Request Access
      </Button>
    </RegisteredPatientRow>
  )
}