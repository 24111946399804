import { Text } from 'src/components';
import { removeModalHash } from 'src/utils';
import { theme } from 'src/utils/variables';
import { useLocation } from 'wouter';
import { ResultTextRow } from './styled';

export interface ResultLinkType {
  title: string;
  category: string;
  date: string;
  icon: JSX.Element;
  url: string;
}

const ResultLink: React.FC<ResultLinkType> = ({
  category,
  title,
  date,
  icon,
  url,
}) => {
  const [, setLocation] = useLocation();

  const handleClick = (path: string) => {
    removeModalHash();
    setLocation(path);
  };
  return (
    <ResultTextRow
      data-testid={`result-${category.toLowerCase()}`}
      gap={0.5}
      align="center"
      onClick={() => handleClick(url)}
    >
      {icon}
      <Text weight="semibold" color={theme.black[100]}>
        {category}
      </Text>
      <Text weight="semibold" color={theme.black[100]}>
        •
      </Text>
      <Text weight="semibold" color={theme.black[100]}>
        {title}
      </Text>
      <Text weight="semibold" color={theme.black[100]}>
        •
      </Text>
      <Text weight="semibold" color="#9B9B9B">
        {date}
      </Text>
    </ResultTextRow>
  );
};

export default ResultLink;
