import { Button, Column, H6, LoadingText, Text } from 'src/components';
import { useStaff, useStepTemplates } from 'src/state';
import { StepTemplate } from 'src/types';
import { openModal } from 'src/utils';
import { UpdateIcon } from 'src/assets/icons';
import { Modal } from 'src/containers';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { ActionItemsSummary, StepParamsSummary } from "../AddStep/Component";

const ViewStep = () => {
  const { useStepTemplate, currentStepTemplate } = useStepTemplates();
  const { loading, error } = useStepTemplate();
  const { hasPermission } = useStaff();

  const {
    description,
    name,
    parameters,
    actions,
    id
  } = (currentStepTemplate || {}) as StepTemplate;

  if (error) return <></>

  return (
    <Modal width="40.25rem">
      {loading && <LoadingText />}
      {!loading && (
        <Column gap={3}>
          <Column gap={1.25}>
            <Column gap={0.75}>
              <H6 color="black" modStyles={{ mb: 0, mt: 0 }}>
                {_.capitalize(name)}
              </H6>
              <Text color="#303030" modStyles={{ mb: 0, mt: 0 }}>
                {description}
              </Text>
            </Column>
            <Column gap={2}>
              <StepParamsSummary parameters={parameters} />
              <ActionItemsSummary actions={actions} />
            </Column>
          </Column>
          {hasPermission("EDIT_STEP") && (
            <Button 
              width="max-content" 
              onClick={() =>  {
                mixpanel.track(`Click 'Edit Step' button`, {
                  feature: 'Manage Steps',
                });
                openModal("edit-step", id)}}
              data-testid="edit-step-btn"
            >
              <UpdateIcon className='fill-white' />
              Edit Step
            </Button>
          )}
          
        </Column>
      )}
    </Modal>
  );
};

export default ViewStep;
