import { Row, Column, Button, Input, Switch, Select } from 'src/components';
import { Formik, Form } from 'formik';
import Validator from 'validatorjs';
import {
  REQUIRED_FIELD_OPTIONS,
  RULES,
  serviceCategoryOption,
} from 'src/constants';
import { Modal } from 'src/containers';
import { Service } from 'src/types';
import { useRowData, useServices } from 'src/state';
import { getModalParams, removeModalHash } from 'src/utils/modalHelper';
import { ModalTitle } from '../AddServices/styled';

const EditServicesModal = () => {
  const { updateService, updateServiceLoading } = useServices();
  const id = getModalParams('id');
  const { getDataByKey } = useRowData();
  const { description, amount, isHMOPayable, category } = (getDataByKey(
    'service',
  ) || {}) as unknown as Service;

  return (
    <Modal>
      <ModalTitle>Edit Services</ModalTitle>
      <Formik
        initialValues={{ description, amount, isHMOPayable, category }}
        validate={(values) => {
          const validation = new Validator(
            values,
            {
              description: RULES.requiredfield,
              amount: RULES.requiredfield,
            },
            { ...REQUIRED_FIELD_OPTIONS, ...REQUIRED_FIELD_OPTIONS },
          );
          validation.passes();
          return validation.errors.errors;
        }}
        onSubmit={async (values) => {
          if (
            values.description === description &&
            values.amount === amount &&
            values.isHMOPayable === isHMOPayable &&
            values.category === category
          ) {
            removeModalHash();
            return;
          }
          await updateService({ ...values, serviceId: id }).then((res) => {
            if (res?.data) removeModalHash();
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Column width="26.25rem" gap={2.5}>
              <Column gap={1}>
                <Input
                  label="Short Description"
                  required
                  data-testid="service-description"
                  onChange={handleChange}
                  name="description"
                  type="text"
                  value={values.description}
                  onBlur={handleBlur}
                  error={touched.description ? errors.description : ''}
                />
                <Input
                  label="Amount (₦)"
                  required
                  data-testid="service-amount"
                  name="amount"
                  type="text"
                  inputMode="numeric"
                  onChange={handleChange}
                  value={values.amount.replace(/\D/g, '')}
                  onBlur={handleBlur}
                  error={touched.amount ? errors.amount : ''}
                />
                <Select
                  label="Service Category"
                  name="category"
                  onChange={handleChange}
                  value={values.category || ''}
                >
                  <option disabled value="">
                    Select Category
                  </option>
                  {serviceCategoryOption?.map(({ label, value }, idx) => (
                    <option key={idx} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
                <Switch
                  label="HMO Payable"
                  onChange={handleChange}
                  checked={values.isHMOPayable}
                  name="isHMOPayable"
                />
              </Column>
              <Row width="100%" justify="space-between" gap={1.625}>
                <Button
                  type="button"
                  onClick={() => removeModalHash()}
                  background="neutral"
                  color="black"
                  width="max-content"
                >
                  Cancel Changes
                </Button>
                <Button
                  type="submit"
                  data-testid="save-changes"
                  disabled={updateServiceLoading}
                  isLoading={updateServiceLoading}
                  width="max-content"
                >
                  Save Changes
                </Button>
              </Row>
            </Column>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditServicesModal;
