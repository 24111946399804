/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { Button, Column, Input, Select, DropdownSearch, Avatar, Text } from 'src/components';
import {
  useToast,
  useRowData,
  useStaff,
  usePatients,
  useStaffs,
} from 'src/state';
import { Formik, Form } from 'formik';
import Validator from 'validatorjs';
import {
  getModalParams,
  getURLParams,
  removeModalHash,
} from 'src/utils/modalHelper';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  CREATE_TODOS,
  GET_TODOS,
  REQUIRED_FIELD_OPTIONS,
  RULES,
  TIME_PATTERN,
  DATE_OPTIONS,
  DUE_TIME_OPTIONS,
  CURRENT_DATE,
  CURRENT_TIME,
  UPDATE_TODO
} from 'src/constants';
import _ from 'lodash';
import { Staff, TimelineDataType, Todos } from 'src/types';
import { useTimeline, useRecordApis } from 'src/store';
import { generateAvatar } from 'src/utils';
import { ModalTitle, TodoModal } from './styled';

const AddTodo = () => {
  const { showToast } = useToast();
  const { staff, hasPermission } = useStaff();
  const { getDataByKey } = useRowData();
  const facilityId = staff.getStaff.facility.id;
  const hash = window.location.hash.split('&')[0];
  const facilityParams = getURLParams('facility') || facilityId;
  const id = getModalParams('id') || '';
  const patientId = getModalParams('patientId') || '';
  const { useStaffsDropdown } = useStaffs();

  const { 
    staffDropdown, 
    resetDropdown, 
    handleSearchChange: handleStaffSearch, 
    loadMore,
    hasNextPage: staffHasNextPage,
    loading: staffLoading,
  } = useStaffsDropdown({
    status: 'Active',
    facility: facilityParams,
  })

  const [addTodo, { loading, error: todoError }] = useMutation(CREATE_TODOS);
  const [updateTodo, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_TODO);

  const { usePatientsDropdown } = usePatients();
  const { 
    loadMorePatients, 
    loading: searchLoading, 
    handleSearchChange, 
    resetPatientsDropdown,
    hasNextPage,
    patientsDropdown 
  } = usePatientsDropdown()


  const { title, description, dueDate, assignee, patient, dueTime } =
    (getDataByKey('todo') || {}) as unknown as Todos;

  const { getRecordFromCache } = useTimeline();
  const { fetchRecordMeta } = useRecordApis();

  const cachedRecord = getRecordFromCache(
    patientId,
    id as string,
  ) as unknown as TimelineDataType;
  const [record, setRecord] = useState<TimelineDataType>(cachedRecord);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    patient: '',
    assignee: '',
    dueTime: '',
    dueDate: '',
  });

  Validator.register(
    'min_time',
    (value, req) => {
      if (moment(req).isSame(CURRENT_DATE)) {
        return CURRENT_TIME <= value;
      }
      return true;
    },
    'due time should be present or future time',
  );

  useEffect(() => {
    if (hash === '#update-todo') {
      setFormData({
        ...formData,
        title,
        description,
        patient: patient?.id,
        assignee: assignee?.id,
        dueTime,
        dueDate: dueDate?.split('T')[0],
      });
    }
    if (record && record.__typename === 'Record') {
      setFormData({
        ...formData,
        title: `Follow up: ${record.template.title}`,
        patient: record.patient.id,
      });
    }
    if (!record && id && hash === '#create-task') {
      fetchRecordMeta({
        variables: {
          input: id,
        },
        onCompleted(d: { getRecord: TimelineDataType }) {
          setRecord(d.getRecord);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRecordMeta, id, record]);

  useEffect(() => {
    if (todoError) {
      showToast(todoError.message, 'error');
    }
    if (updateError) {
      showToast(updateError.message, 'error');
    }
  }, [showToast, todoError, updateError]);


  const renderStaff = (dataB: Record<string, unknown>) => {
    const staffItem = dataB as unknown as Staff;
    const { 
      staffGroup: { name },
      user: { firstName, lastName} 
    } = staffItem || {}
    
    return (
      <>
        <Avatar size={33} src={generateAvatar(`${firstName} ${lastName}`, 12)} />
        <Text>
          {`(${name?.endsWith("s") ? name?.slice(0, -1) : name}) ${firstName} ${lastName}`}
        </Text>
      </>
    )
  };

  return (
    <TodoModal width="32rem">
      <Column gap={1}>
        <ModalTitle>
          {['#create-todo', '#create-task'].includes(hash)
            ? 'Create Todo'
            : 'Edit Todo'}
        </ModalTitle>
        <Formik
          initialValues={formData}
          enableReinitialize
          validate={(values) => {
            const validation = new Validator(
              values,
              {
                title: RULES.requiredfield,
                description: RULES.requiredfield,
                patient: RULES.requiredfield,
                assignee: RULES.requiredfield,
                dueDate: RULES.date,
                dueTime: [
                  `regex:${TIME_PATTERN}`,
                  `min_time:${values.dueDate}`,
                ],
              },
              {
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
                ...DATE_OPTIONS,
                ...DUE_TIME_OPTIONS,
              },
            );
            validation.passes();
            return validation.errors.errors;
          }}
          onSubmit={async (values) => {
            if (['#create-todo', '#create-task'].includes(hash)) {
              return addTodo({
                variables: {
                  input: {
                    ...values,
                  },
                },
                refetchQueries: [GET_TODOS],
                onCompleted: (dataD) => {
                  if (dataD) {
                    showToast(dataD.createTodo.message, 'success');
                    removeModalHash();
                  }
                },
              });
            }
            return updateTodo({
              variables: {
                input: { ...values },
                todoId: id,
              },
              refetchQueries: [GET_TODOS],
              onCompleted: (dataD) => {
                if (dataD) {
                  showToast(dataD.updateTodo.message, 'success');
                  removeModalHash();
                }
              },
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Column gap={1.5}>
                <Input
                  value={values.title}
                  placeholder="E.g Go to ward VIP"
                  label="Title"
                  required
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title ? errors.title : ''}
                />
                <Input
                  value={values.description}
                  required
                  placeholder="Describe assigned task"
                  label="Task Description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description ? errors.description : ''}
                />
                {record && record.__typename === 'Record' && id ? (
                  <Select label="Attach Patient" name="patient" disabled>
                    <option value="">{`${record?.patient.user.firstName} ${record?.patient.user.lastName}`}</option>
                  </Select>
                ) : (
                  <DropdownSearch
                    label="Attach Patient"
                    name="patient"
                    title="Select a Patient"
                    required
                    value={values.patient}
                    disabled={!!id}
                    refetchLoading={searchLoading}
                    loading={searchLoading}
                    onLoadMore={loadMorePatients}
                    hasNextPage={hasNextPage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.patient ? (errors.patient as string) : ''}
                    onSearchInputChange={handleSearchChange}
                    placeholder="search patients"
                    data={
                      patientsDropdown?.patients as unknown as Record<
                        string,
                        unknown
                      >[]
                    }
                    onClose={resetPatientsDropdown}
                    valueAccessor="id"
                    labelAccessor={['user.firstName', 'user.lastName']}
                  />
                )}
                {!hasPermission("VIEW_STAFF_LIST") ? (
                  <Select
                    value={values.assignee}
                    label="Assign Staff"
                    required
                    name="assignee"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    loading={staffLoading}
                    error={touched.assignee ? errors.assignee : ''}
                  >
                    <option disabled value="">
                      @ Doctor Marvin
                    </option>
                    <option value={staff.getStaff.id}>
                      {`${staff.getStaff.staffGroup.name} 
                      ${_.startCase(staff.getStaff.user.fullName)}`}
                    </option>
                  </Select>
                ) : (
                  <DropdownSearch
                    label="Assign Staff"
                    name="assignee"
                    title="@ Doctor Marvin"
                    required
                    value={values.assignee}
                    loading={staffLoading}
                    refetchLoading={staffLoading}
                    onLoadMore={loadMore}
                    render={renderStaff}
                    hasNextPage={staffHasNextPage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.assignee ? (errors.assignee as string) : ''}
                    onSearchInputChange={handleStaffSearch}
                    placeholder="search staffs"
                    data={
                      staffDropdown?.staff as unknown as Record<
                        string,
                        unknown
                      >[]
                    }
                    onClose={resetDropdown}
                    valueAccessor="id"
                    labelAccessor={['user.firstName', 'user.lastName']}
                  />
                )}
                <Input
                  value={values.dueDate}
                  label="Due Date"
                  type="date"
                  name="dueDate"
                  required
                  min={CURRENT_DATE}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.dueDate ? errors.dueDate : ''}
                />
                <Input
                  value={values.dueTime}
                  label="Due Time"
                  type="time"
                  name="dueTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.dueTime ? errors.dueTime : ''}
                />
                <Button
                  type="submit"
                  width="max-content"
                  disabled={loading || updateLoading}
                  isLoading={loading || updateLoading}
                >
                  Save
                </Button>
              </Column>
            </Form>
          )}
        </Formik>
      </Column>
    </TodoModal>
  );
};
export default AddTodo;
