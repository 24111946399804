import moment from 'moment';
import { SlotInfo } from 'react-big-calendar';
import { useRoute } from 'wouter';
import { RRule } from 'rrule';
import { Column, Paragraph } from 'src/components';
import { useRowData, useStaff, useAppointments } from 'src/state';
import { getURLParams, removeModalHash } from 'src/utils';
import {
  FREQUENCY_SET,
  WEEKLY_SET,
  MONTHLY_SET
} from 'src/constants';
import _ from 'lodash';
import { Modal } from 'src/containers';
import { useSearchParams } from 'src/hooks';
import AppointmentForm from './AppointmentForm';

export interface FormValues {
  type: string;
  patient: string;
  provider: string;
  date: string;
  startTime: string;
  endTime: string;
  appointmentFrequency: string;
}

const CreateAppointment = () => {
  const { getDataByKey } = useRowData();
  const [, params] = useRoute('/admin/patients/:id');
  const { params: queryParams } = useSearchParams();
  const { updateRowData } = useRowData();
  const { staff } = useStaff();
  const facility = getURLParams("facility") || staff?.getStaff?.facility?.id;

  const { start, end, resourceId } = (getDataByKey('slot') || {}) as SlotInfo;
  const { useAddAppointment } = useAppointments();
  const { addAppointment, loading } = useAddAppointment()
  const isDoctor = staff.getStaff.staffGroup.name.toLowerCase() === 'doctor';
  const doctorId = staff.getStaff.id;

  const getProvider = () => {
    if (resourceId) return resourceId as string;
    if (isDoctor) return doctorId;
    return '';
  };

  const getStartTime = () => {
    if (start && moment(start).isSame(moment().toDate(), 'hour'))
      return moment().format('HH:mm');
    if (start) return moment(start).format('HH:mm');
    return '';
  };

  const formData = {
    type: '',
    patient: params?.id || '',
    provider: getProvider(),
    date: start ? moment(start).format('YYYY-MM-DD') : '',
    startTime: getStartTime(),
    endTime: end ? moment(end).format('HH:mm') : '',
    appointmentFrequency: '',
  };


  return (
    <Modal width="29.5rem">
      <Column gap={1}>
        <Paragraph size="base" weight="bold">
          Add Appointment
        </Paragraph>
        <AppointmentForm
          initialValues={formData}
          onSubmit={async (values: FormValues) => {
            const getRuleObject = RRule.fromText(values.appointmentFrequency);
            const ruleValues = {
              frequency:
                FREQUENCY_SET[
                  getRuleObject.origOptions.freq as keyof typeof FREQUENCY_SET
                ],
              byMonth:
                MONTHLY_SET[
                  getRuleObject.origOptions?.bymonth as keyof typeof MONTHLY_SET
                ],
              byWeekDay:
                WEEKLY_SET[
                  getRuleObject.origOptions
                    ?.byweekday as keyof typeof WEEKLY_SET
                ],
              interval: getRuleObject.origOptions?.interval,
              byMonthDay: getRuleObject.origOptions?.bymonthday,
            };

            const trueRuleValues = _.pickBy(ruleValues, _.identity);

            const recurrenceValue =
              values.appointmentFrequency === 'does not repeat'
              ? _.omit(values, ['appointmentFrequency'])
              : {
                  ..._.omit(values, ['appointmentFrequency']),
                  recurrenceRule: trueRuleValues,
                };

            await addAppointment({
              ...recurrenceValue,
              facility
            })?.then(({ data }) => {
              if (data) {
                removeModalHash()
                const dataDate = data?.addAppointment.appointment.date.split("T")[0]
                if (queryParams.get('date') === dataDate) {
                  updateRowData("scroll", { scrollId: data?.addAppointment.appointment.id })
                }
              }
            })
          }}
          loading={loading}
          id={params?.id}
        />
      </Column>
    </Modal>
  );
};

export default CreateAppointment
