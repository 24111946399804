import { Column, LoadingText, Paragraph } from 'src/components';
import { useRowData, useAppointments, useAlert } from 'src/state';
import { getModalParams, getURLParams, removeModalHash } from 'src/utils';
import moment from 'moment';
import { Frequency, RRule } from 'rrule';
import { 
  FREQUENCY_SET,
  WEEKLY_SET,
  MONTHLY_SET,
  MonthValues,
  WeekValues,
  APPLY_TO,
} from 'src/constants';
import _, { isEqual } from 'lodash';
import { Appointments } from 'src/types';
import { useSearchParams } from 'src/hooks';
import { AppointmentModal } from '../AddAppointment/styled';
import AppointmentForm from '../AddAppointment/AppointmentForm';


const EditAppointment = () => {
  const { showAlert, removeAlert } = useAlert();
  const { getDataByKey, updateRowData } = useRowData();
  const { params } = useSearchParams();
  const { useUpdateAppointment, useAppointment } = useAppointments();
  const { updateAppointment, loading } = useUpdateAppointment();
  const id = getModalParams("id");
  const currentAppointment = (getDataByKey('appointment') || {}) as unknown as Appointments;

  const { loading: appointmentLoading, data, error: fetchError} = useAppointment({
    input: id,
    date: getURLParams('date') as string
  }, !!currentAppointment?.id)


  const appointment = (data?.getAppointment?.[0] || currentAppointment || {}) as Appointments
  
  const {
    recurrenceRule,
    patient,
    provider,
    appointmentDate,
    visitTime,
    type,
    isRecurringException
  } = appointment;


  const vals = {
    freq: Frequency[recurrenceRule?.frequency as unknown as number] || 0,
    byweekday: recurrenceRule?.byWeekDay?.[0] ? 
      WeekValues[recurrenceRule?.byWeekDay[0] as keyof typeof WeekValues] : null,
    bymonth: recurrenceRule?.byMonth?.[0] ? 
      MonthValues[recurrenceRule?.byMonth[0] as keyof typeof MonthValues] : null,
    bymonthday: recurrenceRule?.byMonthDay?.[0],
    interval: recurrenceRule?.interval
  };

  const rule = new RRule(_.pickBy(vals, _.identity));

  const formData = {
    type,
    patient: patient?.id,
    provider: provider?.id,
    date: moment(appointmentDate).format('YYYY-MM-DD'),
    startTime: visitTime?.startTime,
    endTime: visitTime?.endTime,
    appointmentFrequency: recurrenceRule ? rule?.toText() : "does not repeat" ,
  };

  if (fetchError) return <></>


  return (
    <AppointmentModal>
      {appointmentLoading && <LoadingText />}
      {!appointmentLoading && (
        <Column gap={1}>
          <Paragraph size="base" weight="bold">
            Edit Appointment
          </Paragraph>
          <AppointmentForm
            loading={loading}
            initialValues={formData}
            id={id}
            onSubmit={
              async (values) => {
                const getRuleObject = RRule.fromText(values.appointmentFrequency);
                const ruleValues = {
                  frequency: FREQUENCY_SET[getRuleObject.origOptions.freq as keyof typeof FREQUENCY_SET],
                  byMonth: MONTHLY_SET[getRuleObject.origOptions?.bymonth as keyof typeof MONTHLY_SET],
                  byWeekDay: WEEKLY_SET[getRuleObject.origOptions?.byweekday as keyof typeof WEEKLY_SET],
                  interval: getRuleObject.origOptions?.interval,
                  byMonthDay: getRuleObject.origOptions?.bymonthday
                };

                const trueRuleValues = _.pickBy(ruleValues, _.identity)

                const editFormValues = values.appointmentFrequency ===
                  "does not repeat" ?
                  {
                    ...(_.omit(values, ['appointmentFrequency', 'patient'])),
                    removeRecurrenceRule: true,
                    id
                  } :
                  {
                    ...(_.omit(values, ['appointmentFrequency', 'patient'])),
                    recurrenceRule: trueRuleValues,
                    id
                  };

                const isDirty = !isEqual(values, formData)
                
                if (!isDirty) {
                  return removeModalHash()
                }

                const appointmentAction = (value?: string) => {
                  updateAppointment({
                    input: {
                      ...editFormValues,
                      "applyTo": value
                    },
                    date: editFormValues?.date
                  })?.then(({ data: dataD }) => {
                    if (dataD?.updateAppointment) {
                      removeModalHash();
                      removeAlert()
                      const dataDate = dataD?.updateAppointment.appointment.date.split("T")[0]
                      if (params.get('date') === dataDate) {
                        updateRowData("scroll", { scrollId: dataD?.updateAppointment.appointment.id })
                      }
                    }
                  })
                }

                if (recurrenceRule && !isRecurringException) {
                  return showAlert({
                    width: '29.125rem',
                    hasCancelBtn: true,
                    title: "Edit Appointment",
                    type: "decision",
                    message: "Are these changes for this appointment or all recurring appointments?",
                    confirmText: 'This Appointment',
                    closeText: 'All Appointments',
                    confirm: () => appointmentAction(APPLY_TO.This),
                    remove: () => appointmentAction(APPLY_TO.ThisAndFuture)
                  })
                }

                return appointmentAction()
              }
            }
          />
        </Column>
      )}
    </AppointmentModal>
  );
};

export default EditAppointment;