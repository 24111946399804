import React, { useContext, useState } from 'react';
import {
  CheckmarkIcon,
  WarningIcon,
  ServiceIcon,
  ExclamationIcon,
  BarsIcon,
  CheckedIcon,
} from 'src/assets/icons';
import { Column, Row, Paragraph, ConfirmationPrompt } from 'src/components';
import {
  Modal,
  TabContext,
  TabHeaderItem,
  TabHeaders,
  Tabs,
} from 'src/containers';
import { getModalParams, openModal, removeModalHash, theme } from 'src/utils';
import { StepTemplate } from 'src/types';
import { useRowData, useStepTemplates } from 'src/state';
import { AddStepsTabsContent } from './AddStepsTabsContent';
import {
  FixedTabs,
  ModalTitle,
  AddPatientTabContainer,
  TabHeaderIndicatorButton,
  TabIndicatorText,
} from './styled';

export type FormElementProps = 'active' | 'inactive' | 'invalid';
export interface FormProps {
  stepDetails: FormElementProps;
  stepParameters: FormElementProps;
  actionItem: FormElementProps;
  summary: FormElementProps;
}

export interface AddStepProps {
  completed: FormProps;
}

export const stepDetailsType = {
  active: CheckmarkIcon,
  inactive: ExclamationIcon,
  invalid: WarningIcon,
};

export const stepParametersType = {
  active: CheckmarkIcon,
  inactive: BarsIcon,
  invalid: WarningIcon,
};

export const actionItemsType = {
  active: CheckmarkIcon,
  inactive: CheckedIcon,
  invalid: WarningIcon,
};

export const summaryType = {
  active: CheckmarkIcon,
  inactive: ServiceIcon,
  invalid: WarningIcon,
};

const AddStep: React.FC<AddStepProps> = ({ completed }) => {
  const { activeTab } = useContext(TabContext);
  const { stepDetails, stepParameters, actionItem, summary } = completed;

  const tabs = [
    {
      icon: stepDetailsType[stepDetails],
      title: `Step Details`,
    },
    {
      icon: stepParametersType[stepParameters],
      title: 'Step Parameters',
    },
    {
      icon: actionItemsType[actionItem],
      title: 'Action Items',
    },
    {
      icon: summaryType[summary],
      title: 'Summary',
    },
  ];

  const getIsActive = (value: FormElementProps) => {
    switch (value) {
      case 'active':
      case 'invalid':
        return true;
      case 'inactive':
      default:
        return false;
    }
  };

  return (
    <TabHeaders>
      {tabs.map((tab, index) => {
        const { icon: Icon, title } = tab;
        const isActive = `add-step-${index}` === activeTab;
        return (
          <TabHeaderItem id={`add-step-${index}`} key={index}>
            <Row gap={0.5} align="center">
              <TabHeaderIndicatorButton
                size="2.625rem"
                active={
                  isActive ||
                  getIsActive(stepDetails) ||
                  getIsActive(stepParameters) ||
                  getIsActive(actionItem) ||
                  getIsActive(summary)
                }
              >
                <Icon />
              </TabHeaderIndicatorButton>
              <Column width="max-content" align="center" justify="center">
                {isActive ? (
                  <TabIndicatorText align="left" size="sm" color="secondary">
                    Step {index + 1} of {tabs.length}
                  </TabIndicatorText>
                ) : (
                  <></>
                )}
                <TabIndicatorText
                  color={isActive ? theme.black[100] : theme.grey[500]}
                  weight={isActive ? 'bold' : 'normal'}
                >
                  {title}
                </TabIndicatorText>
              </Column>
            </Row>
          </TabHeaderItem>
        );
      })}
    </TabHeaders>
  );
};

const AddStepModal = () => {
  const [data, setData] = useState<StepTemplate>({} as StepTemplate);
  const id = getModalParams('id') as string;
  const isEditStep = Boolean(id);
  const { updateRowData } = useRowData();
  const { useStepTemplate } = useStepTemplates();
  useStepTemplate();

  const [completed, setCompleted] = useState<FormProps>({
    stepDetails: id ? 'active' : 'inactive',
    stepParameters: id ? 'active' : 'inactive',
    actionItem: id ? 'active' : 'inactive',
    summary: 'inactive',
  });

  const isCompleted = data?.id && completed.summary === 'active';

  return (
    <Modal width={!isCompleted ? '56rem' : '45rem'}>
      <Column gap={1.5} width="100%">
        {!isCompleted ? (
          <>
            <Column width="100%">
              <ModalTitle>
                {isEditStep ? `Edit Step` : `Create New Steps`}
              </ModalTitle>
              <Paragraph color={theme.grey[500]}>
                {isEditStep
                  ? `Make changes to this step`
                  : `Create a new step for patients journey`}
              </Paragraph>
            </Column>

            <AddPatientTabContainer justify="space-between">
              <Row width="100%">
                <Tabs>
                  <FixedTabs>
                    <AddStep completed={completed} />
                  </FixedTabs>
                  <AddStepsTabsContent
                    setCompleted={setCompleted}
                    completed={completed}
                    setData={setData}
                  />
                </Tabs>
              </Row>
            </AddPatientTabContainer>
          </>
        ) : (
          <ConfirmationPrompt
            title={`${isEditStep ? 'Changes Saved!' : 'New Step Created !'}`}
            message={
              isEditStep
                ? `You have made changes to this step.`
                : `You have successfully created a new step ${data?.name}`
            }
            onConfirm={() => {
              removeModalHash();
              updateRowData('stepTemplate', data);
              openModal('view-step', data?.id);
            }}
            confirmText="View Step"
            dismissText="Close"
          />
        )}
      </Column>
    </Modal>
  );
};

export default AddStepModal;
