import { gql } from '@apollo/client';

export const GET_PATIENT_OPEN_JOURNEY = gql`
  query GetPatientOpenJourney($patientId: String!) {
    getPatientOpenJourney(patientId: $patientId) {
      id: _id
      steps {
        actions {
          isCompleted
          description
          metadata
          updatedAt
          tag
          id: _id
          special
        }
        id: _id
        stepId
        status
        updatedAt
        patient {
          id: _id
          user {
            firstName
            lastName
          }
        }
        stepTemplate {
          name
        }
        parameters {
          description
          type
          text
          staffGroup {
            name
          }
          staff {
            user {
              firstName
              lastName
            }
          }
        }
        eventLogs {
          updatedAt
          staff {
            id: _id
            user {
              firstName
              lastName
              profileAvatar
            }
          }
          nextState {
            id: _id
            createdAt
            description
            isCompleted
            metadata
            special
            updatedAt
          }
          previousState {
            id: _id
            createdAt
            description
            isCompleted
            metadata
            special
            updatedAt
          }
        }
      }
      patient {
        id: _id
        user {
          firstName
          lastName
        }
      }
      status
      updatedAt
    }
  }
`;
