import { gql } from '@apollo/client';

export const DELETE_STAFF_INVITATION = gql`
  mutation DeleteInvitation($invitationId: ID!) {
    deleteInvitation(invitationId: $invitationId) {
      message
      success
    }
  }
`;
