import { gql } from '@apollo/client';


export const facilityFieldsFragment = gql`
  fragment FacilityFields on Facility {
    id: _id
    state
    country
    address
    staffCount
    createdAt
    organisation {
      id: _id
      name
      logo
      isVerified
      onboardingSteps {
        welcome
        inviteStaff
        setupServices
        staffGroups
      }
    }
  }
`;