import {
  IconSearch,
  UserPlusIcon,
  DashboardIcon,
  ServiceIcon,
  FacilityIcon,
  CalendarIcon,
  UserThreeIcon,
  SettingsIcon,
  UsersTwoIcon,
  ShieldIcon,
  EnterArrowIcon,
  InventoryIcon,
  CreditCardIcon,
  UserIcon,
  UserCheckIcon,
  // NoteIcon,
  FourSquareIcon,
  HouseIcon,
  AccountIcon,
  SquaredUserIcon,
  ReportIcon,
} from 'src/assets/icons';
import { NotificationsDot } from 'src/components/NotificationsDot';
import { SidebarConfig } from '../types';

export const sidebar: SidebarConfig[] = [
  {
    title: 'QUICK ACTIONS',
    group: [
      {
        title: 'Quick Search',
        icon: IconSearch,
        path: '#search',
        noFill: true,
      },
      {
        title: 'Add Patient',
        icon: UserPlusIcon,
        path: '#add-patient',
        noFill: true,
        permission: 'ADD_NEW_PATIENT',
        showOnMobile: false,
      },
      {
        title: 'Sell Product',
        icon: CreditCardIcon,
        permission: 'SELL_PRODUCT',
        showOnMobile: false,
        noStroke: true,
        subGroup: {
          title: 'Sell Product',
          group: [
            {
              title: 'External Customer',
              icon: UserIcon,
              path: '#inventory-invoice&mView=open&type=external',
              noStroke: true,
              showOnMobile: false,
            },
            {
              title: 'Internal Patient',
              icon: UserCheckIcon,
              path: '#inventory-invoice&mView=open&type=patient',
              noStroke: true,
              noFill: true,
              showOnMobile: false,
            },
            {
              title: 'Facility Use',
              icon: HouseIcon,
              path: '#inventory-invoice&mView=open&type=facility',
              noStroke: true,
              showOnMobile: false,
            },
          ],
        },
      },
      {
        title: 'Notifications',
        icon: NotificationsDot,
        path: '#notifications',
      },
    ],
  },
  {
    title: 'OVERVIEW',
    group: [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        path: '/admin/overview',
        permission: ['VIEW_ADMINS_DASHBOARD', 'VIEW_DOCTORS_DASHBOARD'],
      },
      {
        title: 'Patients',
        icon: SquaredUserIcon,
        noStroke: true,
        permission: ['VIEW_IN_PATIENTS_AND_THEIR_JOURNEY', 'VIEW_PATIENT_LIST'],
        showOnMobile: false,
        subGroup: {
          title: 'Patients',
          group: [
            {
              title: 'In-Patients',
              icon: AccountIcon,
              noStroke: true,
              path: '/admin/in-patients',
              permission: 'VIEW_IN_PATIENTS_AND_THEIR_JOURNEY',
              showOnMobile: false,
            },
            {
              title: 'Manage Patients',
              icon: UserThreeIcon,
              path: '/admin/patients',
              permission: 'VIEW_PATIENT_LIST',
              noFill: true,
            },
          ],
        },
      },
      {
        title: 'Calendar',
        icon: CalendarIcon,
        path: '/calendar',
        permission: 'VIEW_CALENDAR',
        noFill: true,
      },
    ],
  },
  {
    title: 'ADMINISTRATION',
    group: [
      // {
      //   title: 'Reports',
      //   icon: NoteIcon,
      //   path: 'admin/inventory/report',
      //   permission: 'VIEW_SERVICE_LIST',
      //   noStroke: true,
      //   showOnMobile: false,
      // },
      {
        title: 'More',
        icon: FourSquareIcon,
        showOnMobile: false,
        noStroke: true,
        subGroup: {
          title: 'MORE OPTIONS',
          group: [
            {
              title: 'Inventory',
              icon: InventoryIcon,
              path: '/admin/inventory',
              permission: 'VIEW_INVENTORY_LIST',
              showOnMobile: false,
              noStroke: true,
            },
            {
              title: 'Reports',
              icon: ReportIcon,
              path: '/admin/reports',
              permission: 'VIEW_REPORT',
              showOnMobile: false,
              noStroke: true,
            },
            {
              title: 'Manage Staff',
              icon: UsersTwoIcon,
              path: '/admin/manage-staff',
              permission: 'VIEW_STAFF_LIST',
              showOnMobile: false,
            },
            {
              title: 'Services',
              icon: ServiceIcon,
              path: '/admin/services',
              permission: 'VIEW_SERVICE_LIST',
              showOnMobile: false,
            },
            {
              title: 'Manage HMO',
              icon: ShieldIcon,
              path: '/admin/hmo',
              permission: 'VIEW_HMO_LIST',
              showOnMobile: false,
            },
            {
              title: 'Steps',
              icon: EnterArrowIcon,
              path: '/admin/manage-step',
              permission: 'VIEW_STEP_LIST',
              showOnMobile: false,
            },
            {
              title: 'Manage Facility',
              icon: FacilityIcon,
              path: '/admin/manage-facility',
              permission: 'VIEW_FACILITY_LIST',
              showOnMobile: false,
            },
            {
              title: 'Settings',
              icon: SettingsIcon,
              path: '/admin/settings',
              noFill: true,
              showOnMobile: false,
            },
          ],
        },
      },
    ],
  },
];
