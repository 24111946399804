import { gql } from '@apollo/client';

export const GET_SERVICES = gql`
  query GetServices($input: ServiceSearchInput) {
    getServices(input: $input) {
      services {
        id: _id
        serviceCode
        description
        category
        isHMOPayable
        amount
        facility {
          state
          id: _id
        }
        status
      }
      total
    }
  }
`;

export const SEARCH_SERVICES = gql`
  query SearchServices($input: SearchServicesInput!) {
    searchServices(input: $input) {
      services {
        id: _id
        serviceCode
        description
        isHMOPayable
        amount
        facility {
          state
          id: _id
        }
        status
      }
      total
    }
  }
`;

export const GET_SERVICE = gql`
  query GetService($serviceId: ID!) {
    getService(serviceId: $serviceId) {
      id: _id
      serviceCode
      description
      isHMOPayable
      amount
      facility {
        state
        id: _id
      }
      status
    }
  }
`;
