import styled from 'styled-components';
import { Column } from '../Flex';

export const EditorPreviewContainer = styled(Column)`
  height: fit-content;
  width: 100%;
  background: #f9f9f9;
  border-radius: 0.5rem;

  ul,
  ol {
    margin-left: 25px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    border: 1px double #b3b3b3;
    padding-inline: 0.5rem;
    padding-block: 0.3rem;
  }
`;
