import {
  Card,
  Column,
  DateFilter,
  ErrorComponent,
  H6,
  IconButton,
  Paragraph,
  Row,
  SearchInput,
} from 'src/components';
import { useLocation } from 'wouter';
import { useContext } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { getModalParams, theme } from 'src/utils';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT } from 'src/constants';
import { Product } from 'src/types';
import { useProductLogs } from 'src/store';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ArrowDown, CancelIcon } from 'src/assets/icons';
import { TagChip } from 'src/pages/admin/styled';
import { DropdownTrigger } from 'src/components/Dropdown/styled';
import { NoLogs, ProductLogsLoader } from '../components/ProductLogsLoader';
import { LogsScrollContainer, VerticalLineSharp } from '../styled';
import { ViewContext } from '.';
import ProductExtraInfo from '../components/ProductExtraInfo';
import LogItem from '../components/ProductLogItem';

const Logs = () => {
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const productId = getModalParams('id');

  const { data, error: productError } = useQuery(GET_PRODUCT, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: !productId,
    variables: {
      input: productId,
    },
  });

  const product = data?.getProduct as Product;

  const goBack = () => {
    setView('details');
    setLocation(`#product&id=${productId}&mView=details`);
  };

  const {
    limit,
    fetchMore,
    isLogsError,
    searchLoading,
    filteredData,
    dateRange,
    setDateRange,
    applyFilters,
    clearFilters,
    onSearchChange,
    searchValue,
    formattedRange,
    dateFilterApplied,
    fetchMoreLoading,
    isLogsLoading,
    currentPage,
  } = useProductLogs();

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: searchLoading || false,
    hasNextPage: filteredData?.logs?.length < filteredData?.total || false,
    disabled: !!isLogsError,
    onLoadMore: fetchMore,
    rootMargin: '0px 0px 400px 0px',
  });

  if (productError) {
    return <ErrorComponent />;
  }

  return (
    <ViewWrapper goBack={goBack}>
      <Column gap={1.5}>
        <Column gap={0.3}>
          <H6 modStyles={{ ma: 0 }}>Update Log</H6>
        </Column>
        <Row width="100%" modStyles={{ px: 0.2 }}>
          <Card width="100%">
            <ProductExtraInfo
              name={product?.name}
              extraInfo={product?.extraInfo}
            />
          </Card>
        </Row>
        <Row gap={0.5}>
          <SearchInput
            onChange={onSearchChange}
            defaultValue={searchValue}
            placeholder="Search batch, staff, patient or anything"
            data-testid="product-log-search"
          />
          <DateFilter
            range={dateRange}
            setRange={setDateRange}
            onApply={applyFilters}
            triggerComponent={(click) => (
              <DropdownTrigger background={theme.grey[100]} onClick={click}>
                Date
                <ArrowDown />
              </DropdownTrigger>
            )}
          />
        </Row>
        {dateFilterApplied && (
          <Row align="center" gap={1}>
            <Paragraph>Filters:</Paragraph>
            <Row gap={1} width="max-content">
              <TagChip type="round" color="grey">
                {`${formattedRange.startDate} to ${formattedRange.endDate}`}
                <IconButton
                  size="0.5"
                  color="grey"
                  background="primary"
                  onClick={clearFilters}
                >
                  <CancelIcon />
                </IconButton>
              </TagChip>
            </Row>
          </Row>
        )}
        {(searchValue || dateFilterApplied) &&
          filteredData?.logs?.length === 0 &&
          !isLogsLoading &&
          !searchLoading && (
            <LogsScrollContainer width="100%">
              <NoLogs />
            </LogsScrollContainer>
          )}
        {(isLogsLoading || searchLoading) && <ProductLogsLoader />}
        {!isLogsLoading &&
          !searchLoading &&
          filteredData?.logs &&
          filteredData?.logs?.length > 0 && (
            <LogsScrollContainer position="relative" width="100%" ref={rootRef}>
              <Column style={{ marginLeft: '1.5rem' }} gap={1.5}>
                <VerticalLineSharp color="#303030" />
                {filteredData?.logs?.map((log) => (
                  <LogItem key={log.id} log={log} />
                ))}
              </Column>
              {fetchMoreLoading ||
                (currentPage < Math.ceil(filteredData?.total / limit) && (
                  <p ref={sentryRef} />
                ))}
              {fetchMoreLoading &&
                currentPage < Math.ceil(filteredData?.total / limit) && (
                  <p>loading...</p>
                )}
            </LogsScrollContainer>
          )}
      </Column>
    </ViewWrapper>
  );
};

export default Logs;
