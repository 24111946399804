import { Paragraph, Row } from 'src/components';
import { theme, font } from 'src/utils/variables';
import styled, { css } from 'styled-components';

export const UpperCriticalRegion = styled.span`
  padding: 0.3125rem 0.625rem;
  color: ${theme.secondary[700]};
  background: ${theme.secondary[200]};
  font-size: ${font.sizes.xxs};
  font-weight: ${font.weights.bold};
  position: absolute;
  border-radius: 1.43rem;
  top: 0.625rem;
  left: 0.625rem;
  z-index: 99;
`;

export const LowerCriticalRegion = styled.span`
  padding: 0.3125rem 0.625rem;
  color: ${theme.secondary[700]};
  background: ${theme.secondary[200]};
  font-size: ${font.sizes.xxs};
  font-weight: ${font.weights.bold};
  position: absolute;
  border-radius: 1.43rem;
  bottom: 0.625rem;
  left: 0.625rem;
  z-index: 99;
`;

export const ChartContainer = styled(Row)`
  position: relative;
  border: 1px solid rgba(80, 80, 80, 0.3);
  border-radius: 0.625rem;
  padding: 2rem 0;
`;

export const EmptyContainer = styled(Row)`
  padding: 2rem;
`;

export const EmptyText = styled(Paragraph)`
  color: ${theme.grey[600]};
  max-width: 20rem;
  span {
    font-weight: ${font.weights.semibold};
    color: ${theme.secondary[700]};
    cursor: pointer;
  }
`;

export const EntriesButton = styled.button<{
  isActive?: boolean;
  color?: string;
}>`
  ${({ color }) => {
    return css`
      color: ${theme[color ?? 'grey'][700]};
      background: ${color ? theme[color][200] : '#E5E5E5'};
    `;
  }};
  ${({ isActive, color }) => {
    return isActive
      ? css`
          border: 1px solid ${theme[color ?? 'grey'][700]};
        `
      : css`
          border: none;
        `;
  }};
  border-radius: 0.9375rem;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  font-weight: ${font.weights.bold};
  font-size: ${font.sizes.xxs};
`;
