import { TextareaProps } from 'src/types';
import { Row } from '../Flex';
import { TextareaBase } from './styled';
import { InputLabel } from '../InputLabel';
import { ErrorText, InputContainer } from '../Input/styled';

export const Textarea: React.FC<TextareaProps> = ({
  label,
  error,
  required,
  ...props
}) => {
  return (
    <InputContainer required={!label && required}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Row>
        <TextareaBase {...props} />
      </Row>
      {Boolean(error) && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </InputContainer>
  );
};
