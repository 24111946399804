import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Label,
  LabelProps,
  Cell,
} from 'recharts';
import { AxisDomain, Margin } from 'recharts/types/util/types';
import { font, theme } from 'src/utils';
import { CustomTooltipProps } from '../ChartToolTip';

export interface TinyBarChartProps {
  data: Record<string, unknown>[];
  horizontalCartesianLines?: boolean;
  verticalCartesianLines?: boolean;
  colorKey?: string;
  color?: string | string[];
  dataKey: string;
  barSize?: string | number;
  width?: string | number;
  axisLine?: boolean;
  radius?: number;
  height?: string | number;
  xAxisLabel?: string;
  showYAxis?: boolean;
  backgroundBarColor?: string;
  toolTip?: boolean;
  yLabelValue?: string | number;
  yLabelPosition?: LabelProps['position'];
  margin?: Margin;
  domain?: AxisDomain;
  xTickCount?: number;
  yTickCount?: number;
  customToolTip?: React.FC<CustomTooltipProps>;
  tickXFormatter?: (value: string | number) => string | number;
  adjust?: {
    x?: number;
    y?: number;
  };
}

const makeEven = (number: number) => {
  return typeof number === 'number' && number % 2 !== 0 ? number + 1 : number;
};

export const TinyBarChart: React.FC<TinyBarChartProps> = ({
  data,
  color,
  colorKey = 'color',
  dataKey,
  barSize = 14,
  axisLine = false,
  radius = 10,
  width = '100%',
  height = '100%',
  margin,
  xAxisLabel,
  yLabelPosition,
  tickXFormatter,
  backgroundBarColor,
  yLabelValue,
  domain = [
    (min: number) => min,
    (max: number) => {
      return makeEven(max + 1) < 5 ? 5 : makeEven(max + 1);
    },
  ],
  horizontalCartesianLines = false,
  verticalCartesianLines = false,
  showYAxis = true,
  xTickCount,
  yTickCount,
  adjust,
  toolTip,
  customToolTip: CustomToolTip,
}) => {
  const tickStyle = {
    stroke: theme.primary[700],
    strokeWidth: 0.4,
    fontSize: font.sizes.sm,
  };

  const getColor = (index: number) => {
    if (data[index][colorKey]) return data[index][colorKey];
    if (color) {
      if (Array.isArray(color)) return color[index];
      return color;
    }
    return '#ECAF68';
  };

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        barSize={barSize}
        data={data}
        width={20}
        height={80}
        margin={margin}
      >
        <CartesianGrid
          strokeDasharray="0"
          strokeWidth={0.3}
          color={theme.grey[50]}
          horizontal={horizontalCartesianLines}
          vertical={verticalCartesianLines}
        />
        {toolTip &&
          (CustomToolTip ? (
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<CustomToolTip />}
            />
          ) : (
            <Tooltip cursor={{ fill: 'transparent' }} />
          ))}
        <XAxis
          hide={!xAxisLabel}
          tick={tickStyle}
          tickFormatter={(v) => (tickXFormatter ? tickXFormatter(v) : v)}
          dataKey={xAxisLabel}
          axisLine={axisLine}
          tickLine={false}
          tickCount={xTickCount}
          height={20}
          fontSize={font.sizes.sm}
          className="recharts-label"
        />
        <YAxis
          tick={tickStyle}
          hide={!showYAxis}
          axisLine={axisLine}
          tickLine={false}
          scale="linear"
          type="number"
          width={10}
          tickCount={yTickCount}
          textAnchor="start"
          domain={domain}
          fontSize={font.sizes.sm}
        >
          {yLabelValue && (
            <Label
              value={yLabelValue}
              position={yLabelPosition}
              dx={adjust?.x}
              dy={adjust?.y}
              fill="#9b9b9b"
              fontSize={font.sizes.sm}
              fontWeight={font.weights.semibold}
            />
          )}
        </YAxis>
        <Bar
          dataKey={dataKey}
          radius={[radius, radius, 0, 0]}
          background={{ fill: backgroundBarColor }}
          activeBar={false}
        >
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(index) as string} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
