import {
  BinIcon,
  DisableIcon,
  EditIcon,
  EnableIcon,
  ThreeDots,
} from 'src/assets/icons';
import { STAFF_STATUS_ENUM } from 'src/constants';
import { useStaff, useRowData, useAlert, useStaffs } from 'src/state';
import { Staff } from 'src/types';
import { openModal } from 'src/utils';
import mixpanel from 'mixpanel-browser';
import { Ellipsis, EllipsisButton } from '../styled';

interface ActionColumnProps {
  id: string;
  _id: string;
  invitationId?: string;
  row: Record<string, unknown>;
}
const ActionColumn: React.FC<ActionColumnProps> = ({
  id,
  _id,
  row,
  invitationId,
}) => {
  const { updateRowData } = useRowData();
  const { useDeleteInvite, useDeleteStaff, useEnableDisableStaff } = useStaffs();
  const { enableDisableStaff } = useEnableDisableStaff()
  const { deleteStaff } = useDeleteStaff();
  const { deleteInvite } = useDeleteInvite();
  const { showAlert, removeAlert } = useAlert();
  const {  hasPermission  } = useStaff();
  const isDisabled = row.status === STAFF_STATUS_ENUM.DISABLED;

  const handleDelete = async () => {
    mixpanel.track(`Click 'Delete Staff' button`, {
      feature: 'Manage Staff',
    });
    showAlert({
      title: 'Confirm Delete',
      message: `Do you want to remove ${
        (row as unknown as Staff).user.fullName
      }`,
      confirm: async () => {
        const result = await deleteStaff(id || _id);
        if (result?.data) {
          removeAlert();
        }
      },
    });
  };

  const disableStaff = async () => {
    mixpanel.track(`Click 'Disable Staff' button`, {
      feature: 'Manage Staff',
    });
    showAlert({
      title: `${isDisabled ? 'Enable' : 'Disable'} this staff ?`,
      message: `Do you want to ${isDisabled ? 'enable' : 'disable'} ${
        (row as unknown as Staff).user.fullName
      }`,
      confirm: async () => {
        const result = await enableDisableStaff(id || _id, {
          status: `${
            isDisabled ? STAFF_STATUS_ENUM.ACTIVE : STAFF_STATUS_ENUM.DISABLED
          }`,
        });

        if (result?.data) {
          removeAlert();
        }
      },
    });
  };

  const deleteInvitation = () => {
    mixpanel.track(`Click 'Delete Staff Invite' button`, {
      feature: 'Manage Staff',
    });
    showAlert({
      title: `Delete this Invite ?`,
      message: `Do you want to delete this Invite`,
      confirm: async () => {
        const result = await deleteInvite(invitationId as string);
        if (result?.data) {
          removeAlert();
        }
      },
    });
  };

  return (
    <>
      <ThreeDots className="icon" />
      {(row as unknown as Staff).staffGroup.name !== 'Owner' && (
        <Ellipsis gap={1} justify="flex-end">
          {!invitationId ? (
            <>
              {hasPermission("ENABLE_DISABLE_STAFF") && (
                <EllipsisButton
                  toolTipTitle={isDisabled ? 'Enable' : 'Disable'}
                  variant="text"
                  onClick={disableStaff}
                  data-testid="staff-disable"
                >
                  {isDisabled ? <EnableIcon /> : <DisableIcon />}
                </EllipsisButton>
              )}
              
              {hasPermission("EDIT_STAFF") && (
                <EllipsisButton
                  onClick={() => {
                    mixpanel.track(`Click 'Edit Staff' button`, {
                      feature: 'Manage Staff',
                    });
                    openModal('edit-staff', id || _id);
                    updateRowData('staff', row);
                  }}
                  toolTipTitle="Edit"
                  data-testid="staff-edit"
                  variant="text"
                >
                  <EditIcon />
                </EllipsisButton>
              )}
              {hasPermission("DELETE_STAFF") && (
                <EllipsisButton
                  toolTipTitle="Delete"
                  variant="text"
                  data-testid="staff-delete"
                  onClick={handleDelete}
                >
                  <BinIcon />
                </EllipsisButton>
              )}
              
            </>
          ) : (
            <>
              {hasPermission("DELETE_STAFF") &&
                Boolean(invitationId) && (
                  <EllipsisButton
                    toolTipTitle="Delete Invite"
                    variant="text"
                    onClick={deleteInvitation}
                    data-testid="staff-delete-invite"
                  >
                    <BinIcon />
                  </EllipsisButton>
                )}
            </>
          )}
        </Ellipsis>
      )}
    </>
  );
};

export default ActionColumn;
