import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect } from 'react';
import { CheckCircleIcon } from 'src/assets/icons';
import { Button, Column, H4, Select, Text, Textarea } from 'src/components';
import { CREATE_TODOS, GET_STAFFS_META, TAG_SOMEONE } from 'src/constants';
import { GET_PATIENT_BASIC_INFO } from 'src/constants/Queries';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { useToast } from 'src/state';
import { Staff } from 'src/types';
import { removeModalHash } from 'src/utils';
import Validator from 'validatorjs';
import { useLocation, useRoute } from 'wouter';

type RecordSuccessModalType = {
  templateTitle: string;
  recordId: string;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecordSuccessModal: React.FC<RecordSuccessModalType> = ({
  templateTitle,
  setSubmitted,
  recordId,
}) => {
  const [addTodo, { loading: creatingTask, error: createTaskError }] =
    useMutation(CREATE_TODOS);
  const [tagSomeone] = useMutation(TAG_SOMEONE);

  const { data: allStaffs, error: fetchStaffError } = useQuery(
    GET_STAFFS_META,
    {
      variables: {
        query: {
          isWithInvitedStaff: false,
        },
      },
    },
  );
  const { showToast } = useToast();
  const staffs = allStaffs?.getStaffs.staff;
  const [, params] = useRoute('/admin/patients/:id');
  const [, setLocation] = useLocation();
  const { data: patientMeta } = useQuery(GET_PATIENT_BASIC_INFO, {
    variables: {
      patientId: params?.id,
    },
  });

  useEffect(() => {
    if (fetchStaffError) {
      showToast('Unable to fetch Staff at the moment', 'error');
    }
    if (createTaskError) {
      showToast(createTaskError.message, 'error');
    }
  }, [fetchStaffError, showToast, createTaskError, patientMeta]);

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      assignee: '',
      title: `Follow up: ${templateTitle}`,
      description: '',
      patient: params?.id,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(validationValues, {
        assignee: 'required_with:description',
      });
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: (value) => {
      if (value.description && value.assignee) {
        addTodo({
          variables: {
            input: {
              ...value,
            },
          },
          onCompleted() {
            showToast('Task Created', 'success');
            tagSomeone({
              variables: {
                input: {
                  description: 'Patient follow up',
                  providers: [value.assignee],
                  resource: recordId,
                  resourceType: 'Record',
                },
              },
            });
            removeModalHash();
            setSubmitted(false);
            setLocation(`?tab=timeline`);
          },
        });
      } else {
        removeModalHash();
        setSubmitted(false);
        setLocation(`?tab=timeline`);
      }
    },
  });
  return (
    <Column gap={1} align="center">
      <CheckCircleIcon />
      <Column maxWidth="25rem" align="center">
        <H4 align="center">Record Created Successfully!!!</H4>
        <Text align="center" size="sm">
          {`You have successfully created ${templateTitle} for ${patientMeta?.getPatient?.user.firstName} ${patientMeta?.getPatient?.user.lastName}.`}
        </Text>
      </Column>
      <Column maxWidth="25rem" align="center" gap={1.2}>
        <Text align="center" size="sm">
          You can also tag &amp; drop a comment for another nurse or doctor to
          share this record with.
        </Text>
        <FullWidthForm onSubmit={handleSubmit}>
          <Column gap={1.5}>
            <Select
              value={values.assignee}
              name="assignee"
              onChange={handleChange}
              error={errors.assignee}
            >
              <option disabled hidden value="">
                Select Staff
              </option>
              {staffs &&
                staffs?.map((staff: Staff) => (
                  <option key={staff?.id} value={staff?.id}>
                    {`${staff?.staffGroup?.name}. ${_.startCase(
                      staff?.user?.fullName,
                    )}`}
                  </option>
                ))}
            </Select>
            <Textarea
              name="description"
              placeholder="Drop a comment..."
              onChange={handleChange}
              error={errors.description}
              value={values.description}
            />
            <Button
              type="submit"
              data-testid="record-saved"
              disabled={creatingTask}
              isLoading={creatingTask}
              width="max-content"
            >
              Done
            </Button>
          </Column>
        </FullWidthForm>
      </Column>
    </Column>
  );
};

export default RecordSuccessModal;
