/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import { Invoice } from 'src/types';

export type PatientInvoicesType = {
  invoices: Invoice[];
  total: number;
};

const initialState = {} as { [key: string]: PatientInvoicesType };

type PayloadType = {
  payload: {
    patientId: string;
    invoices: PatientInvoicesType;
  };
};

const patientsInvoicesSlice = createSlice({
  name: 'patients-invoices',
  initialState,
  reducers: {
    setPatientsInvoices: (
      state: { [key: string]: PatientInvoicesType },
      action: PayloadType,
    ) => {
      const { invoices, patientId } = action.payload;
      state[patientId] = invoices;
    },
  },
});

export const { setPatientsInvoices } = patientsInvoicesSlice.actions;

export default patientsInvoicesSlice.reducer;
