import React, { useCallback } from 'react';
import { 
  FeaturePermission, 
  FeaturePermissionGroup, 
  FeaturePermissionState, 
  GetPermissionConfig, 
  Perms 
} from 'src/types';
import { useQuery } from '@apollo/client';
import { GET_PERMISSIONS, GET_PERMISSIONS_CONFIG } from 'src/constants';
import _ from 'lodash';
import PermsContext from './context';

const usePerms = () => {
  const { 
    perms, 
    setPerms,
    permissionConfig,
    setPermissionConfig,
  } = React.useContext(PermsContext);



  const transformDataToArray = useCallback(
    (config: FeaturePermissionGroup, permissions?: Perms): FeaturePermissionState[] => {
      const allFeatures = _.flatMap(_.omit(config, "__typename"));

      const stringifyName = (value: string) => _.startCase(_.toLower(value));

      const nameToIdMap = (name: string): string | undefined => {
        const value = _.find(permissions?.getPermissions, { name });
        return value?.id;
      };

      const findFeatureByName = (name: string): FeaturePermission | undefined => _.find(allFeatures, { name });

      const transformedFeatures = _.map(allFeatures, (feature) => {
        const dependencies = _.chain(feature.dependencies)
          .map((dependencyName) => ({
            name: stringifyName(dependencyName),
            description: findFeatureByName(dependencyName)?.description,
            id: nameToIdMap(dependencyName) as string,
          }))
          .compact()
          .value() as FeaturePermission[];

  
        return {
          name: stringifyName(feature.name),
          description: feature.description,
          dependents: dependencies.map(el => stringifyName(el.name)),
          dependencies,
          id: nameToIdMap(feature.name) as string,
        };
      });

      return transformedFeatures;
    }, []
  );

  
  const usePermsissions = (skip?: boolean) => {
    const result = useQuery<Perms>(GET_PERMISSIONS, {
      errorPolicy: 'all',
      skip: skip || perms?.getPermissions?.length > 0,
      onCompleted: (data) => setPerms(data)
    });

    return { ...result, perms };
  };


  const usePermsissionsConfig = (skip?: boolean) => {
    const { loading: permsLoading } = usePermsissions();
    const { loading: permsConfigLoading, ...result } = useQuery<GetPermissionConfig>(GET_PERMISSIONS_CONFIG, {
      errorPolicy: 'all',
      skip: skip || permissionConfig?.length > 0 || !perms?.getPermissions?.[0]?.name,
      onCompleted: (data) => {
        setPermissionConfig(transformDataToArray(data.getFeaturesPermissionsConfig, perms)) 
      }
    });

    return { 
      ...result, 
      permissionConfig,
      loading: permsLoading || permsConfigLoading 
    };
  };


  const nameToIdMap = (name: string): string | undefined => {
    const value = perms?.getPermissions?.find(el => _.startCase(el.name).toLowerCase() === _.startCase(name).toLowerCase())
    return value?.id;
  };


  return {  
    nameToIdMap,
    usePermsissions, 
    usePermsissionsConfig
  }
};

export { usePerms };