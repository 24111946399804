import React, { useContext, useState } from 'react';
import { ExclamationIcon } from 'src/assets/icons';
import { Column, Row, Button, Paragraph, Text } from 'src/components';
import { TabContext } from 'src/containers';
import { usePatients } from 'src/state';
import { theme } from 'src/utils';
import { PatientData, PatientProps } from 'src/types';
import { RegisteredPatient } from '../RegisteredPatients';
import { GetPatientData } from '../GetPatientData';
import { ModalSubTitle } from '../styled';
import { FormProps } from '../index';

interface CheckPatientProps {
  setPatientData: (value: PatientProps) => void;
  patientInfo: Array<PatientData>;
  setData: (Value: string) => void;
  completed: FormProps;
  setCompleted: (value: FormProps) => void;
}

export const CheckPatient: React.FC<CheckPatientProps> = ({
  patientInfo,
  setPatientData,
  setData,
  completed,
  setCompleted,
}) => {
  const { useRequestAccess } = usePatients();
  const { requestAccess, loading } = useRequestAccess();
  const { setActiveTab } = useContext(TabContext);
  const [content, setContent] = useState<PatientData>({
    user: {
      firstName: '',
      lastName: '',
      emails: [],
    },
    basicInfo: {
      phoneNumber: '',
    },
    id: '',
  });

  return (
    <Column width="inherit" gap={1.75}>
      <Column width="100%">
        <ModalSubTitle>User Information Exists</ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          The user you are trying to add already exist
        </Paragraph>
      </Column>

      {!content.id ? (
        <Column gap={1}>
          <Row gap={0.5} align="center">
            <ExclamationIcon />
            <Text color={theme.primary[700]}>
              Click on{' '}
              <Text color={theme.primary[700]} weight="bold">
                “Request Access”
              </Text>{' '}
              to import user details to your own organisation
            </Text>
          </Row>
          {patientInfo?.map((patient: PatientData, idx: number) => (
            <RegisteredPatient
              key={idx}
              setContent={setContent}
              patientInfo={patient}
            />
          ))}
          <Row width="max-content">
            <Button
              type="button"
              variant="text"
              background={theme.secondary[700]}
              onClick={() => {
                setActiveTab(`add-patient-${1}`);
                setCompleted({
                  ...completed,
                  basicInfo: 'active',
                });
              }}
            >
              This is not the Patient?
            </Button>
          </Row>
        </Column>
      ) : (
        <Column width="100%" gap={1.75}>
          <GetPatientData 
            setPatientData={setPatientData}
            content={content} 
            setData={setData} 
          />
          <Button
            width="max-content"
            variant="text"
            type="button"
            background={loading ? theme.grey[200] : theme.secondary[700]}
            disabled={loading}
            isLoading={loading}
            onClick={() => requestAccess(content?.id)}
          >
            Didn’t get code? Resend Code
          </Button>
        </Column>
      )}
    </Column>
  );
};
