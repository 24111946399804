import Skeleton, { SkeletonProps } from "react-loading-skeleton"
import { SkeletonWrapper } from "src/components"

interface SkeletonLoaderProps extends SkeletonProps {
  width?: string | number;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  width = '100%',
  ...props
}) => {
  return (
    <SkeletonWrapper>
      <Skeleton width={width} {...props} />
    </SkeletonWrapper>
  )
}