import { useCallback, useEffect } from 'react';
import { useRoute } from 'wouter';
import {
  useAppSelector,
  useAppDispatch,
  setPatientJourney,
  useJourneyApis,
  getPatientJourney,
} from 'src/store';
import { PatientJourneyType, CreateStepTemplate, StepData } from 'src/types';
import { getModalParams } from 'src/utils';
import { useApolloClient } from '@apollo/client';
import { GET_PATIENT_DATA } from 'src/constants';

export type StepStatus = 'Pending' | 'Ongoing' | 'Done' | 'All';
export const stepStatusMap: { [key: string]: string } = {
  Pending: 'PENDING',
  Ongoing: 'STARTED',
  Done: 'COMPLETED',
};

export const stepStatus = (
  value: 'PENDING' | 'STARTED' | 'COMPLETED' | string,
): StepStatus => {
  if (value === 'PENDING') return 'Pending';
  if (value === 'STARTED') return 'Ongoing';
  if (value === 'COMPLETED') return 'Done';
  return 'All';
};

const usePatientJourney = (pid?: string) => {
  const [, params] = useRoute('/admin/patients/:id');
  const pId = getModalParams('patientId')?.split('?')[0];
  const patientId = pid || (params?.id as string) || pId;
  const patientJourney = useAppSelector(getPatientJourney(patientId));
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const patient = client.readQuery({
    query: GET_PATIENT_DATA,
    variables: {
      patientId,
    },
  });

  const {
    updateStep,
    addStepError,
    stepTemplates,
    addStepLoading,
    addStepToJourney,
    fetchStepTemplate,
    updateStepItemError,
    stepTemplatesErrors,
    stepTemplatesLoading,
    updateStepActionItem,
    fetchStepTemplateError,
    fetchPatientOpenJourney,
    fetchStepTemplateLoading,
    updateStepActionItemError,
    updateStepActionItemLoading,
    fetchPatientOpenJourneyError,
    fetchPatientOpenJourneyLoading,
  } = useJourneyApis();

  const getPatientOpenJourney = useCallback(async () => {
    await fetchPatientOpenJourney({
      variables: {
        patientId,
      },
      onCompleted(data) {
        dispatch(
          setPatientJourney({ patientId, journey: data.getPatientOpenJourney }),
        );
      },
    });
  }, [dispatch, fetchPatientOpenJourney, patientId]);

  useEffect(() => {
    if (
      !patientJourney &&
      patientId &&
      !fetchPatientOpenJourneyError &&
      patient
    ) {
      getPatientOpenJourney();
    }
  }, [
    fetchPatientOpenJourneyError,
    getPatientOpenJourney,
    patient,
    patientId,
    patientJourney,
  ]);

  const findStepTemplate = useCallback(
    async (name: string) => {
      const stepTemplate = stepTemplates?.stepTemplates?.find(
        (temp: CreateStepTemplate) => temp.name === name,
      );
      if (!stepTemplate) {
        const template = await fetchStepTemplate({
          variables: { input: { search: name } },
        });
        return template?.data?.getStepTemplates?.stepTemplates?.[0];
      }
      return stepTemplate;
    },
    [fetchStepTemplate, stepTemplates?.stepTemplates],
  );

  const filterStepsInJourney = (
    status: StepStatus,
    journey: PatientJourneyType,
  ) => {
    if (status === 'All') {
      return journey?.steps;
    }
    const filtered = journey?.steps.reduce((acc, step) => {
      if (step.status === stepStatusMap[status]) acc.push(step);
      return acc;
    }, [] as StepData[]);
    return filtered;
  };

  return {
    patientId,
    updateStep,
    addStepError,
    stepTemplates,
    addStepLoading,
    patientJourney,
    addStepToJourney,
    findStepTemplate,
    updateStepItemError,
    stepTemplatesErrors,
    filterStepsInJourney,
    stepTemplatesLoading,
    updateStepActionItem,
    getPatientOpenJourney,
    updateStepActionItemError,
    updateStepActionItemLoading,
    fetchPatientOpenJourneyError,
    fetchPatientOpenJourneyLoading,
    findStepTemplateError: fetchStepTemplateError,
    findStepTemplateLoading: fetchStepTemplateLoading,
  };
};

export { usePatientJourney };
