import { AsyncSearchInput, Input, Select } from 'src/components';
import { SEARCH_STAFF } from 'src/constants';
import { Assigned } from 'src/types';

type ParameterType = {
  idx: number;
  label: string;
  type: string;
  value: string;
  required?: boolean;
  error?: string;
  dataTestId?: string;
  assigned?: Assigned[];
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    idx: number,
  ) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>, idx: number) => void;
  onSelect?: (
    e: React.ChangeEvent<HTMLInputElement>,
    d?: Record<string, unknown>,
    idx?: number,
  ) => void;
};
const Parameter: React.FC<ParameterType> = ({
  idx,
  label,
  type,
  value,
  required,
  onChange,
  onBlur,
  onSelect,
  assigned,
  dataTestId,
  error,
}) => {
  return (
    <>
      {type === 'Text' && (
        <Input
          name="text"
          placeholder="Enter Answer"
          label={label}
          background="#fff"
          required={required}
          error={error}
          data-testid={dataTestId}
          onChange={(e) => {
            onChange?.(e, idx);
          }}
        />
      )}
      {type === 'Staff' && onSelect && (
        <AsyncSearchInput
          label={label}
          required={required}
          dataTestId={dataTestId}
          name="staff"
          background="#fff"
          emptyText="No staff found"
          placeholder={`Search or select ${assigned
            ?.map((group) => group.name)
            .join(', ')}`}
          value={value}
          error={error}
          query={SEARCH_STAFF}
          queryOptions={{
            staffGroup: assigned?.map((group) => group.id) as string[],
          }}
          handleSelect={(e, d) => onSelect(e, d, idx)}
          handleChange={(e) => onChange?.(e, idx)}
          handleBlur={(e) => onBlur?.(e, idx)}
          selectors={{
            dataSelector: 'searchStaff.staff',
            valueSelector: 'id',
            labelSelector: ['user.firstName', 'user.lastName'],
            totalSelector: 'searchStaff.total',
          }}
        />
      )}
      {type === 'StaffGroup' && onSelect && (
        <Select
          label="Select Staff Group"
          name="facility"
          value={value}
          background="#fff"
          data-testid={dataTestId}
          onChange={(e) => {
            onChange?.(e, idx);
          }}
          error={error}
        >
          <option disabled value="">
            Choose Staff Group
          </option>
          {assigned?.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name.endsWith("s") ? group.name : `${group.name}s`}
            </option>
          ))}
        </Select>
      )}
    </>
  );
};

export default Parameter;
