import { theme, font } from 'src/utils/variables';
import { Row, H6 } from 'src/components';
import styled from 'styled-components';

export const ProfileCard = styled(Row)`
background: ${theme.grey[100]};
width: 100%;
padding: 1rem;
border-radius:0.8rem;
* > p { 
  margin: 0rem;
  padding: 0rem;
}
img {
  padding: 0;
}
`;

export const ModalTitle = styled(H6)`
  font-size: 1.25rem;
  margin: 0;
  font-weight: ${font.weights.bold}
`;

