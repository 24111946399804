import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_LOGS } from 'src/constants';

export const useProductLogsApis = () => {
  const [getProductLogs, { loading: isLogsLoading, error: isLogsError }] =
    useLazyQuery(GET_PRODUCT_LOGS);
  const [
    fetchMoreLogs,
    { loading: isFetchMoreLogsLoading, error: isFetchMoreLogsError },
  ] = useLazyQuery(GET_PRODUCT_LOGS);

  return {
    getProductLogs,
    isLogsError,
    isLogsLoading,
    fetchMoreLogs,
    isFetchMoreLogsError,
    isFetchMoreLogsLoading,
  };
};
