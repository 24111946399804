import { SettingsIcon } from 'src/assets/icons';
import { ToastConfig } from 'src/types';

export const toast: ToastConfig = {
  success: {
    icon: SettingsIcon,
    color: 'green',
  },
  warning: {
    icon: SettingsIcon,
    color: 'accent',
  },
  error: {
    icon: SettingsIcon,
    color: 'red',
  },
};
