import { gql } from '@apollo/client';
import { RECORD_TEMPLATE_FRAGMENT } from './recordTemplate.fragment';

export const GET_RECORD_TEMPLATE = gql`
  ${RECORD_TEMPLATE_FRAGMENT}
  query GetRecordTemplate($input: ID!) {
    getRecordTemplate(input: $input) {
      ...recordTemplateFragment
    }
  }
`;

export const GET_ALL_RECORD_TEMPLATE = gql`
  ${RECORD_TEMPLATE_FRAGMENT}
  query GetRecordTemplates($input: GetRecordTemplatesInput) {
    getRecordTemplates(input: $input) {
      recentTemplates {
        ...recordTemplateFragment
      }
      pinnedTemplates {
        ...recordTemplateFragment
      }
      otherTemplates {
         recordTemplates {
          ...recordTemplateFragment
        }
        total
      }
      globalTemplates {
         recordTemplates {
          ...recordTemplateFragment
        }
        total
      }
    }
  }
`;

export const SEARCH_RECORD_TEMPLATE = gql`
  ${RECORD_TEMPLATE_FRAGMENT}
  query SearchRecordTemplates($input: SearchRecordTemplatesInput!) {
    searchRecordTemplates(input: $input) {
      recordTemplates {
        ...recordTemplateFragment
      }
      total
    }
  }
`;
