import styled, { css } from 'styled-components';
import { font, theme } from 'src/utils/variables';
import { ChipProps } from 'src/types';
import { injectModStyles } from '../ModStyles';

export const Pill = styled.span<ChipProps>`
  ${({ color, outlined, textColor, backgroundColor, borderColor }) => {
    return css`
      color: ${textColor || theme[color][700]};
      background: ${backgroundColor || theme[color][100]};
      path {
        fill: ${textColor || theme[color][700]};
      }
      border: ${outlined
        ? `1px solid ${borderColor || theme[color][300]}`
        : 'none'};
    `;
  }};
  border-radius: 0.9375rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  padding: 0.4rem 0.8rem;
  text-transform: capitalize;
  font-weight: ${font.weights.semibold};
  font-size: ${font.sizes.xs};
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
  @media (max-width: 960px) {
    font-size: ${font.sizes.xxs};
  }
`;
