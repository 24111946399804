import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import styled from 'styled-components';

export const VitalsChartModal = styled(Modal)`
  ${ModalChildContainer} {
    @media (min-width: 960px) {
      max-width: 57.875rem;
    }
  }
`;
