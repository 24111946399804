import { OperationVariables, useQuery } from "@apollo/client";
import { useCallback, useContext } from "react";
import { 
  GET_STEP, 
  GET_STEPS, 
  GET_STEP_TEMPLATE, 
  GET_STEP_TEMPLATES 
} from "src/constants";
import { 
  StepTemplateProps, 
  GetStepTemplateProps, 
  Step, 
  StepTemplate, 
  GetStepProps, 
  StepProps
} from "src/types";
import _ from "lodash";
import { getModalParams } from "src/utils";
import { useApolloSearch } from "src/hooks";
import { useStaff } from "../staff";
import StepTemplateContext from "./context";
import { useRowData } from "../rowData";



const useQueries = () => {
  const stepTemplateId = getModalParams('id');
  const stepId = getModalParams('id');
  const { 
    steps,
    stepTemplates, 
    setSteps,
    setStepTemplates 
  } = useContext(StepTemplateContext);
  const { hasPermission, staff } = useStaff();
  const { updateRowData, getDataByKey } = useRowData();


  const useStepTemplate = (skip?: boolean) => {
    const result = useQuery<GetStepTemplateProps>(GET_STEP_TEMPLATE, {
      errorPolicy: "all",
      skip: skip ||
        !!currentStepTemplate?.id ||
        !hasPermission("VIEW_STEP_DETAILS") || 
        !stepTemplateId,
      variables: { getStepTemplateId: stepTemplateId },
      onCompleted: (data) => updateRowData('stepTemplate', data.getStepTemplate)
    });

    return { ...result };
  };


  const useStepTemplates = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();
    const defaultValue = { 
      page: 1,
      facility: staff.getStaff.facility.id
    };
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptyValue = _.isEmpty(value);
    const isEmptySearchValue = _.isEmpty(searchValue);

    const result = useQuery<StepTemplateProps>(GET_STEP_TEMPLATES, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_STEP_LIST"),
      variables: {
        input: {
          ...value,
          search: searchValue,
        },
      },
      onCompleted: (data) => {
        if ((isEqualValue || isEmptyValue) && isEmptySearchValue) {
          setStepTemplates(data)
        }
      }
    });


    return { ...result, stepTemplates, ...others, searchValue };
  };


  const useStep = (skip?: boolean) => {
    const result = useQuery<GetStepProps>(GET_STEP, {
      errorPolicy: "all",
      skip: skip ||
        !!currentStep?.id ||
        !hasPermission("VIEW_STEP_DETAILS") || 
        !stepId,
      variables: { stepId },
      onCompleted: (data) => updateRowData('step', data.getStep)
    });

    return { ...result };
  };


  const useSteps = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();
    const defaultValue = { 
      facility: staff.getStaff.facility.id
    };
    const isEmptyValue = _.isEmpty(value);
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptySearchValue = _.isEmpty(searchValue);

    const result = useQuery<StepProps>(GET_STEPS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_STEP_LIST"),
      variables: { 
        input: {
          ...value,
          search: searchValue
        } 
      },
      onCompleted: (data) => {
        if ((isEqualValue || isEmptyValue) && isEmptySearchValue) {
          setSteps(data)
        }
      }
    });

    return { ...result, steps, ...others, searchValue };
  };


  const getDataByStatus = useCallback((data: Step[] | undefined) => {
    if (!Array.isArray(data)) {
      return { 
        pendingTasks: [] as Step[], 
        completedTasks: [] as Step[], 
        ongoingTasks: [] as Step[],
        movedTasks: [] as Step[],
        cancelledTasks: []as Step[]
      };
    };

    const filteredTasks = {
      pendingTasks: data.filter(item => item.status === "PENDING"),
      ongoingTasks: data.filter(item => item.status === "STARTED"),
      completedTasks: data.filter(item => item.status === "COMPLETED"),
      movedTasks: data.filter(item => item.status === "MOVED"),
      cancelledTasks: data.filter(item => item.status === "CANCELLED")
    };

    return { ...filteredTasks }
  }, [])


  const updateStepProperty = <T extends keyof Step>(
    id: string,
    propertyName: T,
    value: Step[T]
  ): void => {
    const updatedSteps = [...steps.getSteps];
    const indexOfStepToUpdate = updatedSteps.findIndex(
      (step) => step?.id === id
    );

    if (indexOfStepToUpdate !== -1) {
      updatedSteps[indexOfStepToUpdate][propertyName] = value;
    }
    setSteps({ getSteps: updatedSteps });
  }

  
  const currentStepTemplate = getDataByKey('stepTemplate') as StepTemplate;
  const currentStep = getDataByKey('step') as Step;

  return {
    setSteps,
    currentStepTemplate,
    currentStep,
    getDataByStatus,
    useStepTemplates,
    useStepTemplate,
    useSteps,
    useStep,
    updateStepProperty
  }
}

export default useQueries