import { theme } from 'src/utils';

export const FILTER_COLORS: { [key: string]: string } = {
  'All Steps': theme.primary[700],
  Pending: theme.secondary[700],
  Done: theme.green[700],
  Ongoing: theme.yellow[600],
};

export const FILTERS = [
  {
    label: 'All Steps',
    value: 'All',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Done',
    value: 'Done',
  },
  {
    label: 'Ongoing',
    value: 'Ongoing',
  },
];

export const stepStatusMap: { [key: string]: string } = {
  PENDING: 'Pending',
  STARTED: 'Ongoing',
  COMPLETED: 'Done',
};
