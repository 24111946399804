import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from 'recharts';
import { ImplicitLabelType } from 'recharts/types/component/Label';
import { AxisDomain, Margin } from 'recharts/types/util/types';
import { font, formatNumber, theme } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import { CustomTooltipProps } from '../ChartToolTip';

export interface MedianBarChartProps {
  data: Record<string, unknown>[];
  horizontalCartesianLines?: boolean;
  verticalCartesianLines?: boolean;
  color?: string | string[];
  dataKey: string;
  colorKey?: string;
  barSize?: string | number;
  width?: string | number;
  axisLine?: boolean;
  radius?: number;
  height?: string | number;
  xAxisLabel?: string;
  showYAxisLabel?: boolean;
  toolTip?: boolean;
  customToolTip?: React.FC<CustomTooltipProps>;
  backgroundBarColor?: string;
  domain?: AxisDomain;
  margin?: Margin;
  label?: ImplicitLabelType;
}

export const MedianBarChart: React.FC<MedianBarChartProps> = ({
  data,
  color,
  colorKey = 'color',
  dataKey,
  label,
  barSize = 20,
  axisLine = false,
  radius = 5,
  width = '100%',
  height = '100%',
  domain = [0, 'dataMax'],
  margin,
  xAxisLabel,
  backgroundBarColor = theme.white[200],
  horizontalCartesianLines = false,
  verticalCartesianLines = false,
  showYAxisLabel = true,
  toolTip,
  customToolTip: CustomToolTip,
}) => {
  const isMobile = !useMediaQuery('(min-width: 960px)');

  const tickStyle = {
    stroke: '#9b9b9b',
    strokeWidth: 0.4,
    fontSize: isMobile ? font.sizes.xs : font.sizes.sm,
  };

  const getColor = (index: number) => {
    if (data[index][colorKey]) return data[index][colorKey];
    if (color) {
      if (Array.isArray(color)) return color[index];
      return color;
    }
    return '#ECAF68';
  };

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        barSize={barSize}
        data={data}
        width={500}
        height={300}
        margin={margin}
      >
        <CartesianGrid
          strokeDasharray="0"
          strokeWidth={0.2}
          color={theme.grey[50]}
          horizontal={horizontalCartesianLines}
          vertical={verticalCartesianLines}
        />
        {toolTip &&
          (CustomToolTip ? (
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<CustomToolTip />}
            />
          ) : (
            <Tooltip cursor={{ fill: 'transparent' }} />
          ))}
        {xAxisLabel && (
          <XAxis
            tick={tickStyle}
            dataKey={xAxisLabel}
            axisLine={axisLine}
            tickLine={false}
            fontSize={isMobile ? font.sizes.xs : font.sizes.sm}
            dy={10}
          />
        )}
        {showYAxisLabel && (
          <YAxis
            tick={tickStyle}
            tickFormatter={(values) => formatNumber(values)}
            tickCount={11}
            axisLine={axisLine}
            fontSize={isMobile ? font.sizes.xs : font.sizes.sm}
            allowDataOverflow
            tickLine={false}
            dx={-10}
            domain={domain}
          />
        )}
        <Bar
          dataKey={dataKey}
          radius={[radius, radius, 0, 0]}
          background={{ fill: backgroundBarColor }}
          label={label}
        >
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(index) as string} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
