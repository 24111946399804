import { useContext } from 'react';
import { SEARCH_FACILITIES } from 'src/constants';
import { OperationVariables, useQuery } from '@apollo/client';
import { SearchFacilitiesProps } from 'src/types';
import _ from 'lodash';
import { useApolloSearch } from 'src/hooks';
import FacilitiesContext from './context';
import { useStaff } from '../staff/hook';


const useQueries = () => {
  const { initialFacilities, setInitialFacilities } = useContext(FacilitiesContext);
  const {  hasPermission  } = useStaff();

  const useFacilities = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();

    const defaultValue = { page: 1 };
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptyValue = _.isEmpty(value);
    const isEmptySearchValue = _.isEmpty(searchValue)

    const result = useQuery<SearchFacilitiesProps>(SEARCH_FACILITIES, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_FACILITY_LIST"),
      variables: {
        input: {
          ...value,
          search: searchValue,
        },
      },
      onCompleted: (data) => {
        if ((isEqualValue || isEmptyValue) && isEmptySearchValue) {
          setInitialFacilities(data);
        }
      }
    });

    return { ...result, ...others, initialFacilities, searchValue };
  };

  return { useFacilities };
}

export default useQueries;