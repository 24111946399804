/* eslint-disable no-underscore-dangle */
import {
  AsyncSearchInput,
  Avatar,
  Column,
  Pill,
  Row,
  Text,
} from 'src/components';
import { useContext, useState } from 'react';
import {
  generateAvatar,
  getFormErrorMessage,
  getModalParams,
  theme,
} from 'src/utils';
import { PencilNote } from 'src/assets/icons';
import { SEARCH_PATIENTS, SEARCH_STAFF, UPDATE_INVOICE } from 'src/constants';
import { PatientProps, HMOEntity, Staff, InvoiceItem } from 'src/types';
import { FormikErrors } from 'formik';
import { useMutation } from '@apollo/client';
import { ViewContext } from '../invoice/index';
import { AccordionButton } from '../styled';
import { InvoiceType } from '../invoice/OpenInvoice';

const types = ['facility', 'patient'];

const renderPatient = (data: Record<string, unknown>) => {
  const patient = data as unknown as PatientProps;
  return `${patient?.user?.firstName} ${patient?.user?.lastName}`;
};

const renderStaff = (data: Record<string, unknown>) => {
  const staff = data as unknown as Staff;
  return `${staff?.user?.firstName} ${staff?.user?.lastName}`;
};

const InvoiceParticipants: React.FC<{
  elementRef?: React.RefObject<HTMLDivElement>;
  isFacilityPatientOpen?: boolean;
  errors?: FormikErrors<{
    invoice: {
      items: InvoiceItem[];
      [key: string]: string | InvoiceItem[];
    };
  }>;
}> = ({ elementRef, isFacilityPatientOpen, errors }) => {
  const { patient, staff, setPatient, setStaff, currentInvoice } =
    useContext(ViewContext);
  const invoiceType = getModalParams('type') as InvoiceType;
  const [patientName, setPatientName] = useState(
    patient ? `${patient?.firstName} ${patient?.lastName}` : '',
  );
  const [staffName, setStaffName] = useState(
    staff ? `${staff?.user?.firstName} ${staff?.user?.lastName}` : '',
  );

  const [updateInvoice] = useMutation(UPDATE_INVOICE, { errorPolicy: 'all' });

  const handlePatientSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    data?: Record<string, unknown>,
  ) => {
    const p = data as unknown as PatientProps;
    setPatient({
      id: p.id as string,
      firstName: p.user.firstName as string,
      lastName: p.user.lastName as string,
      hmo: p?.otherInfo?.hmo as unknown as HMOEntity,
      inJourney: p.status === 'CHECKED_IN',
    });
    if (currentInvoice._id) {
      updateInvoice({
        variables: {
          invoiceId: currentInvoice._id,
          input: {
            patient: p.id,
          },
        },
      });
    }
  };

  const handleStaffSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    data?: Record<string, unknown>,
  ) => {
    const s = data as unknown as Staff;
    setStaff(s);
    if (currentInvoice._id) {
      updateInvoice({
        variables: {
          invoiceId: currentInvoice._id,
          input: {
            requestedBy: s.id,
          },
        },
      });
    }
  };

  return types.includes(invoiceType) ? (
    <Row gap={1} ref={elementRef}>
      {invoiceType === 'facility' && !staff?.id && (
        <AsyncSearchInput
          label="Requested By"
          name="requestedBy"
          required
          emptyText="No Staff found"
          placeholder="Search Staff"
          dataTestId="search-staff"
          query={SEARCH_STAFF}
          value={staffName}
          handleChange={(e) => setStaffName(e.target.value)}
          handleSelect={handleStaffSelect}
          render={renderStaff}
          error={getFormErrorMessage(errors, 'requestedBy')}
          handleBlur={() => {
            if (!staff) setStaffName('');
          }}
          selectors={{
            dataSelector: 'searchStaff.staff',
            valueSelector: 'id',
            labelSelector: 'id',
            totalSelector: 'searchStaff.total',
          }}
        />
      )}
      {staff?.id && (
        <Column>
          <Text
            size="sm"
            color={theme.primary[500]}
            modStyles={{ ma: 0, mb: 0.25 }}
          >
            Requested By
          </Text>
          <Row gap={0.5} align="center">
            <Avatar
              size={33}
              src={generateAvatar(
                staff
                  ? `${staff?.user?.firstName} ${staff?.user?.lastName}`
                  : 'O O',
              )}
            />
            <Text
              size="base"
              weight="semibold"
              color={theme.black[700]}
              modStyles={{ ma: 0 }}
            >
              {`${staff?.user?.firstName} ${staff?.user?.lastName}`}
            </Text>
            <AccordionButton
              color={theme.yellow[600]}
              variant="text"
              width="max-content"
              onClick={() => setStaff(undefined)}
              modStyles={{ pl: 0.3 }}
            >
              <PencilNote />
              Edit
            </AccordionButton>
          </Row>
        </Column>
      )}
      {(invoiceType === 'patient' || isFacilityPatientOpen) && !patient && (
        <AsyncSearchInput
          label="Patient's Name"
          name="patient"
          required={invoiceType === 'patient'}
          emptyText="No patient found"
          autoFocused={invoiceType === 'facility'}
          placeholder="Search Patient"
          dataTestId="search-patient"
          query={SEARCH_PATIENTS}
          value={patientName}
          handleChange={(e) => setPatientName(e.target.value)}
          handleSelect={handlePatientSelect}
          render={renderPatient}
          error={getFormErrorMessage(errors, 'patient')}
          handleBlur={() => {
            if (!patient) setPatientName('');
          }}
          selectors={{
            dataSelector: 'searchPatients.patients',
            valueSelector: 'id',
            labelSelector: 'id',
            totalSelector: 'searchPatients.total',
          }}
        />
      )}
      {patient?.id && (
        <Column>
          <Text
            size="sm"
            color={theme.primary[500]}
            modStyles={{ ma: 0, mb: 0.25 }}
          >
            Patient&apos;s Name
          </Text>
          <Row gap={0.5} align="center">
            <Text
              size="base"
              weight="semibold"
              color={theme.black[700]}
              modStyles={{ ma: 0 }}
            >
              {`${patient?.firstName} ${patient?.lastName}`}
            </Text>
            <AccordionButton
              color={theme.yellow[600]}
              variant="text"
              width="max-content"
              onClick={() => setPatient(undefined)}
              modStyles={{ pl: 0.3 }}
            >
              <PencilNote />
              Edit
            </AccordionButton>
          </Row>
        </Column>
      )}
      {patient?.id && (
        <Pill color={patient?.inJourney ? 'yellow' : 'secondary'} outlined>
          {patient.inJourney ? 'Patient In Journey' : 'Patient not in Journey'}
        </Pill>
      )}
    </Row>
  ) : null;
};

export default InvoiceParticipants;
