import styled from 'styled-components';
import { Row } from 'src/components';
import { theme } from 'src/utils';

export const InputWrapper = styled(Row)`
  align-content: center;
  position: relative;
  input {
    padding-block: 0.5rem;
    padding-left: 1rem;
    padding-right: 4rem;
  }
  ${Row} {
    width: max-content;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  hr {
    border: 1px solid ${theme.grey[200]};
    height: 1.5rem;
  }
  button {
    padding: 0.45rem;
    &:hover {
      background: ${theme.grey[200]};
    }
    svg, path {
      stroke-width: 0;
      width: 0.5rem;
      height: 0.5rem;
      fill: ${theme.green[500]};
    }
    &:first-of-type {
      svg, path {
        fill: ${theme.red[500]};
      }
    }
  }
`;
