import { OperationVariables, useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { 
  CHECK_IF_PATIENT_EXISTS, 
  GET_PATIENTS, 
  GET_PATIENT_DATA, 
  REQUEST_FACILITY_ACCESS_TO_PATIENT, 
  SEARCH_PATIENTS 
} from "src/constants";
import { useRoute } from "wouter";
import useSearch from "src/hooks/useSearch";
import { 
  GetPatientProps,
  PatientProps, 
  PatientsEntities, 
  PatientsProps, 
  SearchPatientProps 
} from "src/types";
import _ from "lodash";
import { useApolloSearch, useDebounceCallback } from "src/hooks";
import { useStaff } from "../staff";
import { useToast } from "../toast";
import PatientsContext from "./context";
import { useRowData } from "../rowData";


const useQueries = () => {
  const { 
    initialPatients, 
    activePatients,
    setInitialPatients,
    setActivePatients
  } = useContext(PatientsContext);

  const initialValue = initialPatients?.searchPatients;
  const { showToast } = useToast();
  const {  hasPermission  } = useStaff();
  const { updateRowData , getDataByKey} = useRowData();
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id;


  const usePatients = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();
    const defaultValue = { page: 1 };
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptyValue = _.isEmpty(value);
    const isEmptySearchValue = _.isEmpty(searchValue)

    const result = useQuery<SearchPatientProps>(SEARCH_PATIENTS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_PATIENT_LIST"),
      variables: {
        input: {
          ...value,
          search: searchValue,
        },
      },
      onCompleted: (data) => {
        if ((isEqualValue || isEmptyValue) && isEmptySearchValue) {
          setInitialPatients(data)
        }
      }
    });

    return { 
      ...result,
      searchValue,
      ...others,
      initialPatients
    };
  };


  const usePatientsDropdown = () => {
    const delayCallback = useDebounceCallback(1000);
    const [searchValue, setSearchValue] = useState<string>("");
    const [patientsDropdown, setPatientsDropdown] = useState<PatientsEntities>(initialValue);
    const getPageNumber = (docLength: number) => Math.ceil(docLength / 10);
    const pageNumber = patientsDropdown?.patients?.length;
    const patientsDropdownPage = getPageNumber(pageNumber);
    const [page, setPage] = useState<number>(patientsDropdownPage || 1);

    const loadMore = () => {
      setPage(patientsDropdownPage + 1);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      delayCallback(() => {
        setPage(1)
        setSearchValue(e.target.value.trim())
      });
    }

    const {data, ...result} = useQuery<SearchPatientProps>(SEARCH_PATIENTS, {
      errorPolicy: 'all',
      skip: !hasPermission("VIEW_PATIENT_LIST"),
      variables: {
        input: {
          search: searchValue,
          page
        },
      },
    });

    useEffect(() => {
      const isPageGreaterThanOne = page > 1;
      setPatientsDropdown((prev) => ({
        ...prev,
        total:  data?.searchPatients?.total as unknown as number,
        patients: isPageGreaterThanOne
          ? [...(prev.patients || []) as unknown as PatientProps[], ...(data?.searchPatients?.patients || []) as unknown as PatientProps[]]
          : data?.searchPatients?.patients as unknown as PatientProps[],
      }));
    }, [data, page])

    const resetPatientsDropdown = () => {
      setPatientsDropdown(initialPatients?.searchPatients);
    };

    return { 
      ...result,
      data,
      searchValue,
      handleSearchChange,
      loadMorePatients: loadMore,
      hasNextPage: patientsDropdown?.total > patientsDropdown?.patients?.length,
      patientsDropdown,
      resetPatientsDropdown
    };
  };


  const useActivePatients = (skip?: boolean) => {
    const result = useQuery<PatientsProps>(GET_PATIENTS, {
      errorPolicy: "all",
      variables: { query: { isActive: true } },
      skip: skip || !hasPermission("VIEW_PATIENT_LIST"),
      onCompleted: (data) => setActivePatients(data)
    });

    return { ...result, activePatients };
  };

  
  const usePatient = (id?: string, skip?: boolean) => {
    const result = useQuery<GetPatientProps>(GET_PATIENT_DATA, {
      errorPolicy: "all",
      skip: skip || !hasPermission("VIEW_PATIENT"),
      variables: { patientId: patientId || id },
      onCompleted: (d) => updateRowData('patient', d.getPatient)
    });

    return { ...result };
  };

  
  const usePatientExists = () => {
    const [execute, result] = useLazyQuery(CHECK_IF_PATIENT_EXISTS, {
      errorPolicy: 'all'
    });

    const patientExists = (value?: OperationVariables) => {
      return execute({
        variables: { query: value },
        onError: (err) => showToast(err.message, "error")
      });
    }
    return { ...result, execute, patientExists };
  };


  const useRequestAccess = () => {
    const [execute, result] = useLazyQuery(REQUEST_FACILITY_ACCESS_TO_PATIENT, {
      errorPolicy: 'all'
    });

    const requestAccess = (id: string) => {
      return execute({
        variables: { patientId: id },
        onCompleted: (d) => showToast(d.requestAccessToPatientData, 'success'),
        onError: (err) => showToast(err.message, "error")
      });
    }
    return { ...result, execute, requestAccess };
  };


    
  const {
    handleSearchChange,
    searchData,
    searchLoading,
    searchValue,
    searchQuery
  } = useSearch(SEARCH_PATIENTS);

  
  const [searchPatients, {
    loading: refetchLoading,
    data: searchPatientData,
    error: dropdownError
  }] = useLazyQuery<SearchPatientProps>(SEARCH_PATIENTS, { 
    errorPolicy: 'all',
    onError: (err) => showToast(err.message, "error")
  });



  const currentPatient = getDataByKey("patient") as PatientProps;

  return {
    searchPatients,
    searchPatientData,
    dropdownError,
    refetchLoading,
    handleSearchChange,
    searchQuery,
    searchData: searchData as SearchPatientProps,
    searchLoading,
    searchValue,
    currentPatient,
    initialPatients,
    activePatients,
    useActivePatients,
    usePatients,
    usePatientExists,
    useRequestAccess,
    usePatient,
    setInitialPatients,
    usePatientsDropdown
  }
}

export default useQueries;