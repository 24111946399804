import React from 'react';
import {
  Input,
  MultiSelect,
  Select,
  Textarea,
  TextEditor,
} from 'src/components';
import ImageUpload from 'src/components/ImageUpload';
import { FormFieldInput, Option } from 'src/types';

export const FieldInput: React.FC<FormFieldInput> = ({
  value,
  field,
  name,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  if (field.type === 'TextArea') {
    return (
      <Textarea
        id={name}
        data-testid={name}
        value={value}
        name={name}
        placeholder={field.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors}
      />
    );
  }
  if (field.type === 'Select') {
    return (
      <Select
        id={name}
        data-testid={name}
        value={value}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors}
      >
        {[
          { label: `Select ${field.title}`, value: undefined },
          ...(field?.options as Option[]),
        ].map(({ label, value: val }, i) => (
          <option key={i} value={val} data-testid={val}>
            {label}
          </option>
        ))}
      </Select>
    );
  }
  if (field.type === 'MultiSelect' && setFieldValue) {
    const handleMultiSelectChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (value === undefined) {
        setFieldValue(name, [e.target.value]);
      } else if (e.target.checked) {
        setFieldValue(name, [...(value as Array<string>), e.target.value]);
      } else {
        setFieldValue(
          name,
          [...(value as Array<string>)].filter((val) => val !== e.target.value),
        );
      }
    };
    return (
      <MultiSelect
        id={name}
        dataTestId={name}
        name={name}
        placeholder={field.placeholder}
        defaultSelected={value as Array<string>}
        onChange={handleMultiSelectChange}
        onBlur={handleBlur}
        error={errors}
        options={field.options ? (field.options as Array<Option>) : []}
      />
    );
  }
  if (field.type === 'Image' && setFieldValue) {
    return (
      <ImageUpload
        fileType="Image"
        dataTestId={name}
        id={name}
        error={errors as string}
        onUploadComplete={setFieldValue}
      />
    );
  }
  if (field.type === 'File' && setFieldValue) {
    return (
      <ImageUpload
        dataTestId={name}
        fileType="File"
        id={name}
        error={errors as string}
        onUploadComplete={setFieldValue}
      />
    );
  }
  if (field.type === 'WYSIWYG' && setFieldValue) {
    return (
      <TextEditor
        data-testid={name}
        name={name}
        value={value as string}
        error={errors as string}
        onChange={(e, v) => setFieldValue(name, v)}
      />
    );
  }
  return (
    <Input
      type={field.type.toLocaleLowerCase()}
      name={name}
      id={name}
      data-testid={name}
      value={value}
      placeholder={field.placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors}
    />
  );
};
