export const StethoscopeIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1665 7.33342C16.8332 6.33342 15.8332 5.66675 14.8332 5.66675C13.4165 5.66675 12.3332 6.75008 12.3332 8.16675C12.3332 9.25008 12.9998 10.1667 13.9998 10.5001V11.9167C13.9998 14.0001 12.3332 15.6667 10.2498 15.6667C8.1665 15.6667 6.49984 14.0001 6.49984 11.9167V11.4167C8.9165 11.0001 10.6665 8.91675 10.6665 6.50008V1.50008C10.6665 1.00008 10.3332 0.666748 9.83317 0.666748H8.1665C7.6665 0.666748 7.33317 1.00008 7.33317 1.50008C7.33317 2.00008 7.6665 2.33341 8.1665 2.33341H8.99984V6.50008C8.99984 8.33342 7.49984 9.83342 5.6665 9.83342C3.83317 9.83342 2.33317 8.33342 2.33317 6.50008V2.33341H3.1665C3.6665 2.33341 3.99984 2.00008 3.99984 1.50008C3.99984 1.00008 3.6665 0.666748 3.1665 0.666748H1.49984C0.999837 0.666748 0.666504 1.00008 0.666504 1.50008V6.50008C0.666504 8.91675 2.4165 11.0001 4.83317 11.4167V11.9167C4.83317 14.9167 7.24984 17.3334 10.2498 17.3334C13.2498 17.3334 15.6665 14.9167 15.6665 11.9167V10.5001C16.9998 10.0834 17.6665 8.66675 17.1665 7.33342Z"
      fill={color || '#FFBF5F'}
    />
  </svg>
);

