import { CreateStepTemplate } from 'src/types';
import { Column } from 'src/components';
import { useFormikContext } from 'formik';
import { ErrorAlert } from 'src/components/ErrorAlert';
import { 
  ActionItemsSummary, 
  StepDetailsSummary, 
  StepParamsSummary 
} from '../Component';


const Summary = () => {
  const { values, errors } = useFormikContext<CreateStepTemplate>();
  const {
    name,
    description,
    parameters,
    actions,
    assigned
  } = values


  const getErrorMessage = () => {
    if (indexError.startsWith("parameters")) return "Error(s) in Step Parameters Section";
    if (indexError.startsWith("actions")) return "Errors(s) in Step Actions Section ";
    return "Error(s) in Step Details Section" 
  }

  const indexError = Object.keys(errors)[0]
  return (
    <Column justify="space-between" width="inherit" gap={1}>
      {Object.keys(errors).length > 0 && (
        <ErrorAlert>{getErrorMessage()}</ErrorAlert>
      )}
      <StepDetailsSummary title={name} text={description} assigned={assigned} />
      <StepParamsSummary parameters={parameters} />
      <ActionItemsSummary actions={actions} />
    </Column>
  );
};

export default Summary;