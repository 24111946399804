import { Avatar, Column, H6, Paragraph, Row, Text } from 'src/components';
import { useAuth, useStaff, useToast, useUser } from 'src/state';
import { theme } from 'src/utils/variables';
import { EmptyTodo } from 'src/assets/images';
import { generateAvatar, removeModalHash } from 'src/utils';
import { useMutation } from '@apollo/client';
import { SWITCH_WORKSPACE } from 'src/constants';
import { useState } from 'react';
import { Spinner } from 'src/components/PageLoader/styled';
import { Staff } from 'src/types';
import { Address, SwitchWorkspaceModal, WorkspaceItem } from './styled';

const SwitchWorkspace = () => {
  const [selected, setSelected] = useState<string>('');
  const { user } = useUser();
  const { staff } = useStaff();
  const [switchWorkspace, { loading }] = useMutation(SWITCH_WORKSPACE);
  const { showToast } = useToast();
  const { logIn } = useAuth();
  const workspaces = [
    user?.getUser.staff.find((s) => s.id === staff?.getStaff.id),
    ...user?.getUser.staff.sort().filter((s) => s.id !== staff?.getStaff.id),
  ];

  const handleSwitch = async (staffId: string) => {
    setSelected(staffId);
    if (staffId === staff.getStaff.id) {
      removeModalHash();
      return;
    }
    await switchWorkspace({
      variables: {
        input: {
          staffId,
        },
      },
      onError: (error) => showToast(error.message, 'error'),
      onCompleted: (data) => {
        if (data) {
          logIn(
            data?.switchWorkspace?.accessToken,
            data?.switchWorkspace?.refreshToken,
          );
          removeModalHash();
          showToast(
            `switched to ${data.switchWorkspace.staff.facility.organisation.name}, ${data.switchWorkspace.staff.facility.address}`,
            'success',
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      },
    });
  };

  return (
    <SwitchWorkspaceModal>
      <Column gap={0.5}>
        <H6>Switch Workspace</H6>
        {workspaces?.length > 0 && (
          <Column>
            {workspaces?.map((s) => {
              const current = s as unknown as Staff;
              const { name, logo } = current?.facility.organisation;
              const nameSplit = name?.split(' ');
              return (
                <WorkspaceItem
                  hasBorderButtom
                  isDefault={current.id === staff.getStaff.id}
                  key={current.id}
                  onClick={() => handleSwitch(current.id)}
                  role="button"
                  data-testid="workspace"
                >
                  <Row gap={0.5} width="100%" align="center">
                    <Avatar
                      size={45}
                      src={
                        !logo && nameSplit
                          ? generateAvatar(
                              `${nameSplit[0]} ${
                                nameSplit[nameSplit.length - 1]
                              }`,
                            )
                          : logo
                      }
                    />{' '}
                    <Column>
                      <Paragraph
                        size="sm"
                        weight="semibold"
                        style={{ marginRight: 'auto' }}
                      >
                        {name}
                      </Paragraph>
                      <Address>{current.facility?.address}</Address>
                    </Column>
                    {loading && current.id === selected && <Spinner />}
                  </Row>
                </WorkspaceItem>
              );
            })}
          </Column>
        )}
        {user?.getUser.staff.length === 0 && !loading && (
          <Column gap={0.5} width="100%" justify="center" align="center">
            <EmptyTodo />
            <Text size="xs" weight="semibold" color={theme.grey[500]}>
              You have no other workspaces
            </Text>
          </Column>
        )}
      </Column>
    </SwitchWorkspaceModal>
  );
};
export default SwitchWorkspace;
