import { FetchResult, OperationVariables } from '@apollo/client';
import { Column, Button, Input, Row } from 'src/components';
import ImageUpload from 'src/components/ImageUpload';
import { Form, Formik } from 'formik';
import Validator from 'validatorjs';
import { useHMOs } from 'src/state';
import { currencyformatter } from 'src/utils';
import { useRoute } from 'wouter';
import _ from 'lodash';
import { CURRENT_DATE, REQUIRED_FIELD_OPTIONS, RULES } from 'src/constants';
import { CreateSettlementProps } from 'src/types';
import SettlementCard from './SettlementCard';


interface SettlementFormprops {
  loading: boolean;
  createSettlement: (value?: OperationVariables) => Promise<FetchResult<CreateSettlementProps, Record<string, unknown>>> | undefined
}

const SettlementForm: React.FC<SettlementFormprops> = ({ 
  loading,
  createSettlement
}) => {
  const [, params] = useRoute('/admin/hmo/:id');
  const hmoId = params?.id as string;
  const { currentHMODebt, useHMODebt } = useHMOs();
  const { loading: debtLoading } = useHMODebt(hmoId, !!currentHMODebt?.getHmoDebt);

  const formData = {
    proof: '',
    paidOn: '',
    amount: '',
  };

  return (
    <Formik
      initialValues={formData}
      validate={(values) => {
        const validation = new Validator(
          values,
          {
            amount: RULES.requiredfield,
            proof: RULES.requiredfield,
            paidOn: RULES.requiredfield,
          },
          {
            ...REQUIRED_FIELD_OPTIONS,
            ...REQUIRED_FIELD_OPTIONS,
            ...REQUIRED_FIELD_OPTIONS,
          },
        );
        validation.passes();
        return validation.errors.errors;
      }}
      onSubmit={async (values) => {
        await createSettlement({ ...values, hmo: hmoId })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        const isNotFilled = _.every(values, (value) => _.isEmpty(value));

        return (
          <Form>
            <Column gap={5}>
              <Column gap={0.5}>
                <SettlementCard
                  label="Total Debt"
                  amount={currentHMODebt?.getHmoDebt as unknown as number}
                  loading={debtLoading}
                />
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  label="Settlement amount"
                  placeholder={currencyformatter.format(0)}
                  name="amount"
                  type="text"
                  value={values.amount.replace(/\D/g, '')}
                  error={touched.amount ? errors.amount : ''}
                />
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Payment Date"
                  required
                  placeholder="24 June, 2021"
                  max={CURRENT_DATE}
                  name="paidOn"
                  type="date"
                  value={values.paidOn}
                  error={touched.paidOn ? errors.paidOn : ''}
                />
                <ImageUpload
                  id="proof"
                  required
                  label="Upload proof of Payment"
                  labelText="Click to upload proof of payment"
                  fileType="image/*,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.csv"
                  error={touched.proof ? errors.proof : ''}
                  onUploadComplete={setFieldValue}
                />
              </Column>
              <Row justify="space-between" gap={1}>
                <Button
                  type="submit"
                  width="max-content"
                  disabled={isNotFilled || loading}
                  isLoading={loading}
                >
                  Create Settlement
                </Button>
                <Button
                  width="max-content"
                  type="button"
                  background="neutral"
                  color="black"
                >
                  Cancel
                </Button>
              </Row>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SettlementForm;
