import { RangeKeyDict, DateRange } from 'react-date-range';
import { FilterIcon } from 'src/assets/icons';
import { theme } from 'src/utils';
import useClickAway from 'src/utils/useClickAway';
import { Positions } from 'src/types';
import { Button, IconButton } from '../Button';
import { DateFilterWrapper } from './styled';
import { Column } from '../Flex';


type DateRangeProps = {
  range: RangeData;
  setRange: React.Dispatch<React.SetStateAction<RangeData>>;
};

export type RangeData = {
  startDate: Date;
  endDate: Date;
  key: string;
};

export const CustomDateRange: React.FC<DateRangeProps> = ({
  range,
  setRange,
}) => {
  const onChange = (rangesByKey: RangeKeyDict) => {
    const startDate = rangesByKey.selection.startDate as Date;
    const endDate = rangesByKey.selection.endDate as Date;
    setRange({
      ...range,
      startDate,
      endDate,
    });
  };
  return (
    <DateRange
      color={theme.secondary[700]}
      rangeColors={[theme.secondary[700]]}
      ranges={[range]}
      onChange={onChange}
      dateDisplayFormat="MMM d, yyyy"
    />
  );
};

interface DateFilterType {
  range: RangeData;
  onApply: () => void;
  triggerComponent?: (onClick: () => void) => React.ReactNode;
  setRange: React.Dispatch<React.SetStateAction<RangeData>>;
  position?: Positions;
  buttonText?: string;
}

export const DateFilter: React.FC<DateFilterType> = ({
  range,
  setRange,
  onApply,
  triggerComponent,
  position,
  buttonText = "Apply"
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);
  return (
    <>
      <Column
        position="relative"
        gap={0.5}
        ref={ref}
        width="unset"
        height="100%"
      >
        {triggerComponent ? (
          triggerComponent(() => setIsComponentVisible(!isComponentVisible))
        ) : (
          <IconButton
            size="0.85"
            background={theme.primary[100]}
            onClick={() => setIsComponentVisible(!isComponentVisible)}
          >
            <FilterIcon />
          </IconButton>
        )}
        {isComponentVisible && (
          <DateFilterWrapper wrapperPosition={position}>
            <CustomDateRange range={range} setRange={setRange} />
            <Button
              type="button"
              width="max-content"
              onClick={() => {
                onApply();
                setIsComponentVisible(false);
              }}
            >
              {buttonText}
            </Button>
          </DateFilterWrapper>
        )}
      </Column>
    </>
  );
};
