import { FormikErrors } from 'formik';
import { StackedSquare } from 'src/assets/icons';
import { Checkbox, Column, Input, Row } from 'src/components';
import { getFormErrorMessage } from 'src/utils';
import { ProductBatchType } from 'src/types';
import moment from 'moment';
import { useContext } from 'react';
import _ from 'lodash';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { FlexContainer, SectionHeader } from '../styled';
import { ViewContext } from '../product';

export type BatchFormErrors = FormikErrors<Record<string, unknown>>;

interface BatchFormProps {
  values: ProductBatchType;
  errors: BatchFormErrors;
  setFieldValue?: (
    field: string,
    value: number | string | boolean | string[],
  ) => void;
  handleChange: (e: React.ChangeEvent) => void;
}

const BatchForm: React.FC<BatchFormProps> = ({
  values,
  errors,
  setFieldValue,
  handleChange,
}) => {
  const { view } = useContext(ViewContext);
  const { staff } = useStaff();

  const isReadOnly =
    view === 'edit-batch' &&
    _.isNumber(values.quantityLeft) &&
    values.quantity > values.quantityLeft;
  return (
    <Column gap={1} minWidth="50vw">
      <SectionHeader justify="space-between">
        <Row gap={0.5} align="center" width="max-content">
          <StackedSquare />
          Batch Details
        </Row>
      </SectionHeader>
      <Column>
        <Row gap={1}>
          <Input
            id="batch-number"
            label="Batch Number"
            value={values.batchNumber}
            name="batch.batchNumber"
            placeholder="Auto-generated if blank"
            onChange={handleChange}
            disabled={
              !['Owner', 'Admin'].includes(staff.getStaff.staffGroup.name)
            }
          />
          <Input
            id="quantity"
            type="number"
            required={values.status?.[0] !== 'ORDER_PLACED'}
            label="Quantity"
            value={values.quantity}
            name="batch.quantity"
            placeholder="0"
            onChange={handleChange}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            error={getFormErrorMessage(errors, 'batch.quantity')}
          />
        </Row>
        <Row gap={1}>
          <Input
            id="buying-price"
            type="number"
            required={values.status?.[0] !== 'ORDER_PLACED'}
            label="Buying Price (Naira)"
            value={values.buyingPrice}
            name="batch.buyingPrice"
            placeholder="0"
            onChange={handleChange}
            error={getFormErrorMessage(errors, 'batch.buyingPrice')}
          />
          <Input
            id="selling-price"
            type="number"
            required={values.status?.[0] !== 'ORDER_PLACED'}
            label="Selling Price (Naira)"
            value={values.sellingPrice}
            name="batch.sellingPrice"
            placeholder="0"
            onChange={handleChange}
            error={getFormErrorMessage(errors, 'batch.sellingPrice')}
          />
        </Row>
        <Row gap={1}>
          <Input
            id="expiry-alert"
            type="number"
            label="Expiry Alert (Days)"
            value={values.expiryAlert}
            name="batch.expiryAlert"
            placeholder="Number of days before expiry date e.g 1 "
            onChange={handleChange}
            error={getFormErrorMessage(errors, 'batch.expiryAlert')}
          />
          <Input
            id="quantity"
            type="number"
            required={values.status?.[0] !== 'ORDER_PLACED'}
            label="Out-of-stock Alert (Quantity)"
            value={values.stockAlert}
            name="batch.stockAlert"
            placeholder="Quantity to trigger out-of-stock warning"
            onChange={handleChange}
            error={getFormErrorMessage(errors, 'batch.stockAlert')}
          />
        </Row>
        <Row gap={1} align="center">
          <FlexContainer>
            <Input
              id="expiry-date"
              type="date"
              label="Expiry Date"
              min={moment(Date.now()).format('YYYY-MM-DD')}
              value={moment(values.expiryDate).format('YYYY-MM-DD')}
              name="batch.expiryDate"
              placeholder="DD-MM-YYYY"
              onChange={handleChange}
              error={getFormErrorMessage(errors, 'batch.expiryDate')}
            />
          </FlexContainer>
          <FlexContainer modStyles={{ mt: 1.5 }}>
            <Checkbox
              data-testid="order-placed"
              activeColor="green"
              label="Order placed but not in stock yet"
              name="batch.status"
              checked={values.status?.[0] === 'ORDER_PLACED'}
              onClick={() => {
                mixpanel.track(
                  `Clicked 'Order placed but not in stock yet' checkbox`,
                  { feature: 'Inventory Product Form' },
                );
              }}
              onChange={() => {
                setFieldValue?.('batch.pushToShelf', false);
                setFieldValue?.(
                  'batch.status',
                  values.status?.[0] === 'ORDER_PLACED'
                    ? ['IN_STOCK']
                    : ['ORDER_PLACED'],
                );
              }}
            />
          </FlexContainer>
        </Row>
        <Row>
          <FlexContainer modStyles={{ my: 1.5 }}>
            <Checkbox
              data-testid="push-all-to-shelf"
              activeColor="green"
              label="Push All To Shelf"
              name="batch.pushToShelf"
              checked={
                values.status?.[0] === 'ORDER_PLACED'
                  ? false
                  : values.pushToShelf
              }
              onChange={handleChange}
              disabled={values.status?.[0] === 'ORDER_PLACED'}
            />
          </FlexContainer>
        </Row>
      </Column>
    </Column>
  );
};

export default BatchForm;
