import { Text, IconButton, Row, Column } from 'src/components';
import { theme, font } from 'src/utils/variables';
import { ClockIcon } from 'src/assets/icons';
import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import styled from 'styled-components';

export const TodoInfoModal = styled(Modal)`
  ${ModalChildContainer} {
    img {
      padding: 0;
    }
    h6 {
      font-size: 1.25rem;
    }
  }
`;

export const TodoInfoText = styled(Text)`
  margin: 0;
`;

export const TodoActionButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${theme.grey[100]};
  svg,
  path {
    width: 0.75rem;
    heigth: 0.75rem;
    stroke-width: 0.15;
    fill: ${theme.grey[300]};
  }
`;

export const DueTimeIcon = styled(ClockIcon)`
  svg,
  path {
    width: 0.75rem;
    heigth: 0.75rem;
    stroke-width: 0.15;
    fill: ${theme.grey[600]};
  }
`;

export const InputContainer = styled(Row)`
  div:first-of-type {
    margin-bottom: 0rem;
  }
  input {
    border-radius: 0.625rem;
    font-weight: ${font.weights.normal};
    font-size: ${font.sizes.base};
    background-color: ${theme.primary[100]};
    padding-right: 3rem;
    &::placeholder {
      color: ${theme.primary[400]};
      font-weight: ${font.weights.normal};
    }
  }
  ${IconButton} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0.25rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    svg,
    path {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`;

export const OverflowContainer = styled(Column)`
  overflow-y: auto;
  max-height: 20rem;
`;

export const NotesSubmitButton = styled(IconButton)<{
  isFilled?: boolean;
}>`
  background-color: ${({ isFilled }) =>
    isFilled ? theme.red[500] : theme.grey[200]};
  & > svg {
    fill: ${({ isFilled }) => (isFilled ? theme.white[100] : theme.grey[500])};
  }
`;
