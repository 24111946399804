import React from 'react';
import { CheckCircleIcon } from 'src/assets/icons';
import { Button, Column, H4, Row, Text } from 'src/components';
import { useLocation } from 'wouter';
import { getModalParams, openModal, removeModalHash } from 'src/utils';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { ViewContext } from '.';
import { StepViewType } from './Step';

const SuccessModal: React.FC<{
  stepName: string;
  journeyId?: string;
  viewType: StepViewType;
}> = ({ stepName, viewType, journeyId }) => {
  const patientId = getModalParams('patientId')?.split('?')[0];
  const stepId = getModalParams('id');
  const { hasPermission } = useStaff();
  const [, setLocation] = useLocation();
  const { setView } = React.useContext(ViewContext);
  return (
    <Column gap={1} align="center" minWidth="35vw">
      <CheckCircleIcon />
      <Column maxWidth="25rem" align="center">
        <H4 align="center" modStyles={{ my: 0 }}>
          Step Completed
        </H4>
        <Text
          align="center"
          data-testid="step-completed-success"
          size="sm"
          modStyles={{ textTransform: 'capitalize', my: 0 }}
        >
          {viewType === 'checkout'
            ? 'Patient Checkout Successful'
            : `You have Completed "${stepName}" step`}
        </Text>
      </Column>
      {viewType === 'checkout' && (
        <Row gap={1} width="100%" justify="center">
          <Button
            data-testid="close-step"
            width="max-content"
            modStyles={{ minW: '10rem' }}
            onClick={removeModalHash}
            variant="cancel"
          >
            Close
          </Button>
        </Row>
      )}
      {viewType === 'current-journey' && (
        <Row gap={1} width="100%" justify="center">
          {hasPermission('VIEW_STEPS_IN_A_JOURNEY') && (
            <Button
              data-testid="view-all-steps"
              width="max-content"
              modStyles={{ minW: '10rem' }}
              variant="cancel"
              onClick={() => {
                setView('allSteps');
                mixpanel.track(`Click 'See All Steps' button`, {
                  feature: 'Patient Journey',
                });
                setLocation(`#journey&mView=allSteps`);
              }}
            >
              See All Steps
            </Button>
          )}
          {hasPermission('ADD_STEPS_TO_A_JOURNEY') && (
            <Button
              data-testid="create-new-step"
              width="max-content"
              modStyles={{ minW: '10rem' }}
              onClick={() => {
                setView('newStep');
                mixpanel.track(`Click 'Create New step' button`, {
                  feature: 'Patient Journey',
                });
                setLocation(`#journey&mView=newStep`);
              }}
            >
              Create New Step
            </Button>
          )}
        </Row>
      )}
      {viewType === 'in-patients' && (
        <Row gap={1} width="100%" justify="center">
          {hasPermission('VIEW_STEPS_IN_A_JOURNEY') && (
            <Button
              data-testid="view-all-steps"
              width="max-content"
              modStyles={{ minW: '10rem' }}
              variant="cancel"
              onClick={() => {
                mixpanel.track(`Click 'See All Steps' button`, {
                  feature: 'In-Patients',
                });
                openModal(
                  'step-info',
                  stepId,
                  `&patientId=${patientId}&view=allSteps`,
                );
              }}
            >
              See All Steps
            </Button>
          )}
          {hasPermission('ADD_STEPS_TO_A_JOURNEY') && (
            <Button
              data-testid="create-new-step"
              width="max-content"
              modStyles={{ minW: '10rem' }}
              onClick={() => {
                mixpanel.track(`Click 'Create New Step' button`, {
                  feature: 'In-Patients',
                });
                openModal(
                  'step-info',
                  stepId,
                  `&patientId=${patientId}&view=newStep`,
                );
              }}
            >
              Create New Step
            </Button>
          )}
        </Row>
      )}
      {viewType === 'past-journey' && hasPermission('VIEW_STEPS_IN_A_JOURNEY') && (
        <Button
          width="max-content"
          data-testid="view-all-steps"
          variant="cancel"
          modStyles={{ minW: '10rem' }}
          onClick={() => {
            mixpanel.track(`Click 'Back To All Steps' button`, {
              feature: 'Past Journey',
            });
            setView('allSteps');
            setLocation(`#journey&id=${journeyId}&mView=allSteps`);
          }}
        >
          Back To All Steps
        </Button>
      )}
    </Column>
  );
};

export default SuccessModal;
