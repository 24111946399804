import { theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { CardProps } from 'src/types';
import { injectModStyles } from '../ModStyles';

export const Card = styled.div<CardProps>`
  ${({ width = 'max-content', height = 'max-content', display = 'block', modStyles }) => css`
    display: ${display};
    width: ${width};
    height: ${height};
    background: ${theme.white[100]};
    border-radius: 0.9375rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);
    padding: 1.5em;
    ${modStyles && injectModStyles(modStyles)}
  `}
`;
