import { TableImage } from 'src/assets/images';
import { Column } from 'src/components';

export const Empty: React.FC<{children: React.ReactNode}> = ({children}) => {

  return (
    <Column align="center" justify="center" height="100%" gap={1} width="100%">
      <TableImage />
      {children}
    </Column>
  );
};
