import {
  Column,
  ErrorComponent,
  H6,
  Pill,
  Row,
  SkeletonWrapper,
  Text,
} from 'src/components';
import { useLocation } from 'wouter';
import { useContext } from 'react';
import { Accordion } from 'src/containers';
import {
  FileFolder,
  HistoryClockIcon,
  PencilNote,
  StackedSquare,
} from 'src/assets/icons';
import { getModalParams, theme } from 'src/utils';
import { GET_PRODUCT } from 'src/constants';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { Product, ProductBatchType } from 'src/types';
import ReactTooltip from 'react-tooltip';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { AccordionButton, SectionHeader } from '../styled';
import { ViewContext } from '.';
import ProductInfo from '../components/ProductInfo';
import BatchCard from '../components/BatchCard';

export const getBatchTotal = (batches?: ProductBatchType[]) =>
  batches?.reduce((acc, batch) => {
    acc += batch.quantityLeft as number;
    return acc;
  }, 0);

export const getTotalShelfQty = (batches?: ProductBatchType[]) =>
  batches?.reduce((acc, batch) => {
    acc += batch.quantityOnShelf as number;
    return acc;
  }, 0);
const Details = () => {
  const { hasPermission } = useStaff();
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const productId = getModalParams('id');

  const {
    data,
    loading: isProductLoading,
    error: productError,
  } = useQuery(GET_PRODUCT, {
    errorPolicy: 'all',
    skip: !productId,
    variables: {
      input: productId,
    },
  });

  const product = data?.getProduct as Product;

  if (productError) {
    return <ErrorComponent />;
  }

  return (
    <Column
      width="100%"
      height="100%"
      minHeight="70vh"
      maxWidth="50vw"
      gap={1.5}
    >
      <Column gap={0.3}>
        <H6 modStyles={{ ma: 0, mb: 2 }} data-testid="product-title">
          {product?.name}
        </H6>
        <Accordion>
          <Accordion.Header>
            <SectionHeader justify="space-between">
              <Row gap={0.5} align="center" width="max-content">
                <FileFolder />
                Product Details
              </Row>
              <Row gap={2} width="max-content" align="center">
                {hasPermission('EDIT_PRODUCT') && (
                  <AccordionButton
                    color={theme.secondary[700]}
                    variant="text"
                    width="max-content"
                    data-testid="edit-product-btn"
                    disabled={isProductLoading}
                    onClick={() => {
                      mixpanel.track(`Click 'Edit Product' Button`, {
                        feature: 'Inventory',
                      });
                      setView('edit-product');
                      setLocation(
                        `#product&id=${productId}&mView=edit-product`,
                      );
                    }}
                  >
                    <PencilNote />
                    Edit Product
                  </AccordionButton>
                )}
                {hasPermission('VIEW_INVENTORY_INVOICE_HISTORY') && (
                  <AccordionButton
                    data-tip
                    data-for="product-log"
                    background={theme.grey[100]}
                    data-testid="view-product-logs-btn"
                    width="max-content"
                    color={theme.grey[600]}
                    onClick={() => {
                      mixpanel.track(`Click 'View Logs' Button`, {
                        feature: 'Inventory',
                      });
                      setView('logs');
                      setLocation(`#product&id=${productId}&mView=logs`);
                    }}
                  >
                    <HistoryClockIcon />
                  </AccordionButton>
                )}
                <ReactTooltip
                  id="product-log"
                  place="right"
                  backgroundColor="#666666"
                >
                  <Text size="xs" weight="bold" color="#FFFFFF">
                    View Logs
                  </Text>
                </ReactTooltip>
              </Row>
            </SectionHeader>
          </Accordion.Header>
          <Accordion.Content>
            <Column gap={0.75}>
              <Row gap={1}>
                <ProductInfo
                  title="Product Code"
                  info={product?.productCode}
                  isLoading={isProductLoading}
                />
                <ProductInfo
                  title="Product Name"
                  info={product?.name}
                  isLoading={isProductLoading}
                  dataTestId="product-name"
                />
              </Row>
              <Row gap={1}>
                <ProductInfo
                  title="Extra Information"
                  info={product?.extraInfo || 'N/A'}
                  isLoading={isProductLoading}
                  dataTestId="extra-info"
                />
                <ProductInfo
                  title="Brand Name"
                  info={product?.brandName}
                  isLoading={isProductLoading}
                  dataTestId="brand-name"
                />
              </Row>
              <Row gap={1}>
                <ProductInfo
                  title="Over the Counter (OTC)"
                  info={product?.isOTC ? 'Yes' : 'No'}
                  isLoading={isProductLoading}
                />
                <Column>
                  <Text
                    size="sm"
                    color={theme.primary[500]}
                    modStyles={{ ma: 0, mb: 0.25 }}
                  >
                    Product Category
                  </Text>
                  {isProductLoading && !product ? (
                    <Skeleton />
                  ) : (
                    <Row gap={0.2}>
                      {product?.categories?.map((cat, idx) => (
                        <Pill key={idx} color="grey" outlined>
                          {cat}
                        </Pill>
                      ))}
                    </Row>
                  )}
                </Column>
              </Row>
            </Column>
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Header>
            <SectionHeader justify="space-between">
              <Row gap={0.5} align="center" width="max-content">
                <StackedSquare />
                Batches
              </Row>
              {hasPermission('ADD_TO_STOCK') && (
                <AccordionButton
                  color={theme.secondary[700]}
                  variant="text"
                  width="max-content"
                  data-testid="add-new-batch-btn"
                  disabled={isProductLoading}
                  onClick={() => {
                    mixpanel.track(`Click 'Add New Batch' Button`, {
                      feature: 'Inventory',
                    });
                    setView('add-batch');
                    setLocation(`#product&id=${productId}&mView=add-batch`);
                  }}
                >
                  <StackedSquare />
                  Add new Batch
                </AccordionButton>
              )}
            </SectionHeader>
          </Accordion.Header>
          <Accordion.Content>
            <Column gap={1.5}>
              {isProductLoading && !product ? (
                <Column gap={1.5}>
                  <SkeletonWrapper>
                    <Skeleton
                      borderRadius="0.625rem"
                      width="50vw"
                      height={300}
                    />
                  </SkeletonWrapper>
                  <SkeletonWrapper>
                    <Skeleton
                      borderRadius="0.625rem"
                      width="50vw"
                      height={250}
                    />
                  </SkeletonWrapper>
                </Column>
              ) : (
                product?.batches.map((batch, idx) => (
                  <BatchCard key={idx} batch={batch} productId={product?.id} />
                ))
              )}
            </Column>
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Header>
            <SectionHeader justify="space-between">
              <Row gap={0.5} align="center" width="max-content">
                <StackedSquare />
                Metrics
              </Row>
            </SectionHeader>
          </Accordion.Header>
          <Accordion.Content>
            <Column gap={1.5}>
              <Row gap={1}>
                <ProductInfo
                  title="Total Quantity Left Across Batches"
                  info={getBatchTotal(product?.batches)}
                  isLoading={isProductLoading}
                />
                <ProductInfo
                  title="Total Number of Batches"
                  info={product?.batches?.length}
                  isLoading={isProductLoading}
                  dataTestId="batch-count"
                />
              </Row>
              <Row gap={1}>
                <ProductInfo
                  title="Total Quantity on Shelf"
                  info={getTotalShelfQty(product?.batches)}
                  isLoading={isProductLoading}
                  dataTestId="shelf"
                />
              </Row>
            </Column>
          </Accordion.Content>
        </Accordion>
      </Column>
    </Column>
  );
};

export default Details;
