import { FormRenderer } from 'src/containers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { FormConfigType } from 'src/types';
import { useMedicalSummary, useStaff, useToast } from 'src/state';
import { UPDATE_MEDICAL_SUMMARY } from 'src/constants/Mutations';
import { FormRendererModal } from 'src/pages/admin/PatientDetails/MedicalTimeline/styled';
import { bloodInfoForm } from 'src/constants/summaryForms';
import { useRoute } from 'wouter';
import { removeModalHash } from 'src/utils';

const BloodInfo: React.FC = () => {
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id as string;
  const { refreshMedicalSummary } = useMedicalSummary();
  const { hasPermission } = useStaff();
  const { showToast } = useToast();
  const [updateMedicalSummary, { error: failedUpdate }] = useMutation(
    UPDATE_MEDICAL_SUMMARY,
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = (d: Record<string, unknown>) => {
    if (!hasPermission("UPDATE_MEDICAL_SUMMARY")) return showToast("You do not have permission to perform this action", "warning");
    setLoading(true);
    const rawData = d.rawData as { bloodInfo: Array<Record<string, unknown>> };
    updateMedicalSummary({
      variables: {
        input: {
          bloodInfo: rawData.bloodInfo[0],
        },
        patientId,
      },
      async onCompleted() {
        if (!failedUpdate) {
          refreshMedicalSummary({
            onCompleted: () => {
              showToast('Summary updated', 'success');
              setLoading(false);
              removeModalHash();
            },
          });
        } else {
          showToast('Error Updating Summary', 'error');
          setLoading(false);
        }
      },
    });
  };

  return (
    <FormRendererModal>
      <FormRenderer
        config={{
          ...(bloodInfoForm as FormConfigType),
        }}
        onSubmit={onSubmit}
        isSubmitting={loading}
      />
    </FormRendererModal>
  );
};

export default BloodInfo;
