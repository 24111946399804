import { theme, font } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { InputProps } from 'src/types';
import { Column, Row } from '../Flex';
import { Text } from '../Typography';
import { injectModStyles } from '../ModStyles';

export const InputBase = styled.input<{ background?: string }>`
  width: 100%;
  border: none;
  background-color: ${({ background }) => background || theme.grey[100]};
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 0;
  outline: 0;
  position: relative;
  font-size: ${font.sizes.sm};
  font-weight: ${font.weights.semibold};
  color: ${theme.grey[600]};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
  &::placeholder {
    color: ${theme.grey[200]};
    font-weight: ${font.weights.bold};
    @media (max-width: 960px) {
      font-size: ${font.sizes.xs};
    }
  }
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
    &::before {
      width: 100%;
      background-color: red;
      position: absolute;
    }
  }
`;

export const ErrorText = styled(Text)`
  position: absolute;
  z-index: 100;
  top: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 0.25rem;
  font-weight: ${font.weights.bold};
`;

export const MobilePlaceholders = styled(Row)<{
  value?: string;
}>`
  display: none;
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
    display: flex;
    border-radius: 0.5rem;
    font-size: ${font.sizes.sm};
    color: ${({ value }) => (value ? theme.grey[600] : theme.grey[200])};
    font-weight: ${font.weights.bold};
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
    background-color: ${theme.grey[100]};
    pointer-events: none;
    z-index: 10;
  }
`;

export const InputContainer = styled(Column)<InputProps>`
  margin-bottom: 1rem;
  ${({ required }) => {
    return (
      required &&
      css`
        &::after {
          content: ' *';
          color: ${theme.red[500]};
          position: absolute;
          left: 0;
        }
      `
    );
  }}
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;


export const Icon = styled.div`
  position: absolute;
  right: 0;
  margin: 0 0.75rem;
  svg {
    max-width: 0.75rem;
    max-height: 0.75rem;
  }
`;