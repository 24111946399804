import { Column, Dropdown, H6, Row, Text } from 'src/components';
import { getModalParams, openModal, theme } from 'src/utils';
import { TurnLeftDownIcon } from 'src/assets/icons';
import { Fragment, useEffect, useState } from 'react';
import { StepStatus, stepStatus, usePatientJourney } from 'src/store';
import { PatientJourneyType, StepData } from 'src/types';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { FILTER_COLORS, FILTERS } from '../PatientJourney/constants';
import NoSteps, { StepsLoader } from '../PatientJourney/Empty';
import StepListItem from '../PatientJourney/StepListItem';
import {
  Circle,
  StepStart,
  SemiCircle,
  VerticalLine,
  AddStepButton,
} from '../PatientJourney/styled';

interface AllStepsProps {
  journey: PatientJourneyType;
  journeyId?: string;
  isPastJourney?: boolean;
}

export const getCompletedActions = (step: StepData) =>
  step?.actions?.reduce((acc, action) => {
    if (action.isCompleted) acc += 1;
    return acc;
  }, 0);

const AllSteps: React.FC<AllStepsProps> = ({ journey, isPastJourney }) => {
  const pId = getModalParams('patientId')?.split('?')[0];
  const stepId = getModalParams('id');
  const patientId = journey?.patient?.id || pId;
  const { hasPermission } = useStaff();
  const { filterStepsInJourney, fetchPatientOpenJourneyLoading = true } =
    usePatientJourney(patientId);
  const [filter, setFilter] = useState('All');
  const [steps, setSteps] = useState(filterStepsInJourney('All', journey));

  const allStep = filterStepsInJourney('All', journey);
  useEffect(() => {
    setSteps(allStep);
  }, [allStep]);

  return (
    <Column width="100%" height="100%" gap={1.5} minWidth="36vw">
      <Column gap={0.3}>
        <H6 modStyles={{ ma: 0 }}>Patient Journey</H6>
        <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
          Click on the cards to see step Information
        </Text>
      </Column>
      <Dropdown
        width="8.6875rem"
        name="filter"
        handleDropdownChange={(n: string, v: string) => {
          setFilter(v);
          setSteps(filterStepsInJourney(v as StepStatus, journey));
        }}
        labelComponent={(label) => (
          <Row align="center">
            <Row
              width="0.75rem"
              height="0.75rem"
              modStyles={{ br: '50%', mr: 0.3, bg: FILTER_COLORS[label] }}
            />
            <Text>{label}</Text>
          </Row>
        )}
        options={FILTERS.map((f: { value: string }) => ({
          value: f.value,
          label: f.value,
          checked: f.value === filter,
        }))}
      />
      {steps && (
        <Column
          align="center"
          height="100%"
          background={theme.primary[100]}
          modStyles={{ pa: 1, br: 0.6875 }}
        >
          {fetchPatientOpenJourneyLoading && <StepsLoader />}
          {steps.length === 0 && !fetchPatientOpenJourneyLoading && <NoSteps />}
          {!!steps.length && !fetchPatientOpenJourneyLoading && (
            <>
              <Column
                align="center"
                height="max-content"
                maxHeight="35rem"
                modStyles={{ overflowY: 'auto' }}
              >
                {isPastJourney ? (
                  <Circle />
                ) : (
                  <>
                    <StepStart>
                      <TurnLeftDownIcon />
                      <Text modStyles={{ my: 0, ml: 0.3 }}>Begins</Text>
                    </StepStart>
                    <SemiCircle shouldRotate />
                  </>
                )}

                {steps?.map((step, idx) => (
                  <Fragment key={idx}>
                    {isPastJourney && idx === 0 ? (
                      <VerticalLine height={0.1} />
                    ) : (
                      <VerticalLine />
                    )}
                    <StepListItem
                      idx={idx}
                      title={step.stepTemplate?.name}
                      status={stepStatus(step.status) as StepStatus}
                      completedCount={getCompletedActions(step)}
                      taskCount={step.actions.length}
                      onClick={() => {
                        mixpanel.track(`Clicked on 'Step' button`, {
                          feature: 'In-Patients',
                        });
                        openModal(
                          'step-info',
                          stepId,
                          `&patientId=${patientId}&view=history`,
                        );
                      }}
                      step={step}
                      patientId={patientId}
                      isPastJourney={isPastJourney}
                    />
                  </Fragment>
                ))}
              </Column>
              {isPastJourney ? <VerticalLine height={0.1} /> : <VerticalLine />}
              {isPastJourney ? <Circle /> : <SemiCircle />}
              {!isPastJourney && hasPermission('ADD_STEPS_TO_A_JOURNEY') && (
                <AddStepButton
                  data-testid="add-step-to-journey"
                  onClick={() => {
                    mixpanel.track(`Clicked 'Add Step' button`, {
                      feature: 'In-Patient',
                    });
                    openModal(
                      'step-info',
                      stepId,
                      `&patientId=${patientId}&view=newStep`,
                    );
                  }}
                >
                  Add Step
                </AddStepButton>
              )}
            </>
          )}
        </Column>
      )}
    </Column>
  );
};
export default AllSteps;
