import {
  DELETE_STAFF,
  DELETE_STAFF_INVITATION,
  GET_STAFFS,
  GET_STAFF_GROUPS,
  INVITE_NEW_STAFF,
  PERMISSION_ACTION_MESSAGE,
  UPDATE_STAFF
} from 'src/constants';
import { OperationVariables, useMutation } from '@apollo/client';
import { DeleteStaffInvitationProps, InviteStaffProps, Staff } from 'src/types';
import { useToast } from '../toast';
import { useStaff } from '../staff/hook';


const useMutations = () => {
  const {  hasPermission  } = useStaff();
  const { showToast } = useToast();


  const useDeleteStaff = () => {
    const [execute, result] = useMutation<Staff>(DELETE_STAFF);

    const deleteStaff = (id: string) => {
      if (!hasPermission("DELETE_STAFF")) return;
      return execute({
        variables: { staffId: id },
        onCompleted: () => showToast('Staff successfully removed', 'success'),
        refetchQueries: [GET_STAFFS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, deleteStaff };
  };


  const useInviteStaff = () => {
    const [execute, result] = useMutation<InviteStaffProps>(INVITE_NEW_STAFF);

    const inviteStaff = (value: OperationVariables) => {
      if (!hasPermission("INVITE_NEW_STAFF")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.inviteStaff.message, 'success'),
        refetchQueries: [GET_STAFFS, GET_STAFF_GROUPS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, inviteStaff };
  };


  const useUpdateStaff = () => {
    const [execute, result] = useMutation<Staff>(UPDATE_STAFF);

    const updateStaff = (id: string, values: OperationVariables) => {
      if (!hasPermission("EDIT_STAFF")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
      return execute({
        variables: {
          staffId: id,
          input: values
        },
        onCompleted: () => showToast('Staff information updated successfully', 'success'),
        refetchQueries: [GET_STAFFS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, updateStaff };
  };

  const useEnableDisableStaff = () => {
    const [execute, result] = useMutation<Staff>(UPDATE_STAFF);

    const enableDisableStaff = (id: string, values: OperationVariables) => {
      if (!hasPermission("ENABLE_DISABLE_STAFF")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
      return execute({
        variables: {
          staffId: id,
          input: values
        },
        onCompleted: () => showToast('Staff information updated successfully', 'success'),
        refetchQueries: [GET_STAFFS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, enableDisableStaff };
  };


  const useDeleteInvite = () => {
    const [execute, result] = useMutation<DeleteStaffInvitationProps>(DELETE_STAFF_INVITATION);

    const deleteInvite = (id: string) => {
      if (!hasPermission("DELETE_STAFF")) return;
      return execute({
        variables: { invitationId: id },
        refetchQueries: [GET_STAFFS],
        onCompleted: (data) => showToast(data.deleteInvitation.message, 'success'),
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, deleteInvite };
  };


  return {
    useDeleteInvite,
    useUpdateStaff,
    useDeleteStaff,
    useInviteStaff,
    useEnableDisableStaff
  };
}

export default useMutations;