import styled, { css } from 'styled-components';
import { font, theme } from 'src/utils/variables';
import { ChipProps } from 'src/types';
import { injectModStyles } from '..';

export const Chip = styled.span<ChipProps>`
  ${({ color, type }) => {
    switch (type) {
      case 'round':
        return css`
          color: ${theme[color][600]};
          border: 1px solid ${theme[color][600]};
          background: ${theme[color][100]};
          border-radius: 0.9375rem;
        `;
      default:
        return css`
          color: ${theme[color][600]};
          border: 1px solid ${theme[color][600]};
          background: ${theme[color][100]};
          border-radius: 0.3125rem;
        `;
    }
  }};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  font-weight: ${font.weights.semibold};
  font-size: ${font.sizes.xs};
  @media (max-width: 960px) {
    font-size: ${font.sizes.xxs};
  }
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;
