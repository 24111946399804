export const CheckedIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="User Interface / Checked">
      <path
        id="Vector"
        d="M8.50964 12.5381C8.5871 12.6162 8.67927 12.6782 8.78082 12.7205C8.88237 12.7628 8.99129 12.7846 9.1013 12.7846C9.21131 12.7846 9.32023 12.7628 9.42178 12.7205C9.52333 12.6782 9.6155 12.6162 9.69297 12.5381L13.093 9.13809C13.2499 8.98117 13.338 8.76834 13.338 8.54642C13.338 8.3245 13.2499 8.11167 13.093 7.95475C12.936 7.79783 12.7232 7.70968 12.5013 7.70968C12.2794 7.70968 12.0666 7.79783 11.9096 7.95475L9.1013 10.7714L8.09297 9.75475C7.93605 9.59783 7.72322 9.50968 7.5013 9.50968C7.27938 9.50968 7.06656 9.59783 6.90964 9.75475C6.75272 9.91167 6.66456 10.1245 6.66456 10.3464C6.66456 10.5683 6.75272 10.7812 6.90964 10.9381L8.50964 12.5381ZM17.5013 1.91309H2.5013C2.28029 1.91309 2.06833 2.00088 1.91205 2.15716C1.75577 2.31344 1.66797 2.52541 1.66797 2.74642V17.7464C1.66797 17.9674 1.75577 18.1794 1.91205 18.3357C2.06833 18.492 2.28029 18.5798 2.5013 18.5798H17.5013C17.7223 18.5798 17.9343 18.492 18.0906 18.3357C18.2468 18.1794 18.3346 17.9674 18.3346 17.7464V2.74642C18.3346 2.52541 18.2468 2.31344 18.0906 2.15716C17.9343 2.00088 17.7223 1.91309 17.5013 1.91309ZM16.668 16.9131H3.33464V3.57975H16.668V16.9131Z"
        fill={color || '#FDC8BB'}
      />
    </g>
  </svg>
);
