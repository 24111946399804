import { gql } from "@apollo/client";
import { settlementFieldsFragment } from "../Fragments/settlement";

export const GET_SETTLEMENTS = gql`
  query GetSettlements($input: GetSettlementsInput) {
    getSettlements(input: $input) {
      total
      settlements {
        ...SettlementFields
      }
    }
  }
  ${settlementFieldsFragment}
`;

export const GET_SETTLEMENT = gql`
  query GetSettlement($getSettlementId: ID!) {
    getSettlement(id: $getSettlementId) {
      ...SettlementFields
    }
  }
  ${settlementFieldsFragment}
`;