import * as React from 'react';
import { OperationVariables } from '@apollo/client';
import RowDataContext from './context';


const RowDataProvider: React.FC = ({ children }) => {
  const [rowData, setRowData] = React.useState<Record<string, OperationVariables>>({});
  

  return (
    <RowDataContext.Provider value={{setRowData, rowData}}>{children}</RowDataContext.Provider>
  );
};

export default RowDataProvider;