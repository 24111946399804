import styled from 'styled-components';
import { DotIndicator } from '../DotIndicator';
import { Column } from '../Flex';

export const NotificationIconContainer = styled(Column)`
	width: max-content;
	height: max-content;
	position: relative;
  ${DotIndicator} {
	  	border-radius: 1rem;
		position: absolute;
		bottom: -2px;
		right: -2px;
		width: auto;
		height: auto;
		font-size: 8px;
		padding: 0.125rem 0.125rem;
	}
`;