import { useState, useEffect, useRef } from 'react';

export default function useClickAway(
    isVisible: boolean,
    onClose?: () => void
) {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean>(isVisible);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsComponentVisible(false);
            if (typeof onClose === 'function') {
                onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}