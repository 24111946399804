import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_MOST_USED_STEP_TEMPLATES,
  GET_PATIENT_OPEN_JOURNEY,
  GET_STEP_TEMPLATES,
  UPDATE_STEP,
} from 'src/constants';
import {
  ADD_STEP_TO_JOURNEY,
  UPDATE_STEP_ACTION_ITEM,
  DELETE_STEP,
} from 'src/constants/Mutations/journey';
import { useStaff } from 'src/state';

export const useJourneyApis = () => {
  const { staff, hasPermission } = useStaff();
  const facilityId = staff.getStaff.facility.id;

  const [
    fetchPatientOpenJourney,
    {
      loading: fetchPatientOpenJourneyLoading,
      error: fetchPatientOpenJourneyError,
    },
  ] = useLazyQuery(GET_PATIENT_OPEN_JOURNEY);

  const [
    fetchStepTemplate,
    { loading: fetchStepTemplateLoading, error: fetchStepTemplateError },
  ] = useLazyQuery(GET_STEP_TEMPLATES);

  const {
    data: stepTemplates,
    loading: stepTemplatesLoading,
    error: stepTemplatesErrors,
  } = useQuery(GET_STEP_TEMPLATES, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: !hasPermission('VIEW_STEP_LIST'),
    variables: {
      input: {
        facility: facilityId,
        skipPagination: true,
      },
    },
  });

  const {
    data: mostUsedStepTemplates,
    loading: fetchMostUsedStepTemplates,
    error: mostUsedStepTemplatesError,
  } = useQuery(GET_MOST_USED_STEP_TEMPLATES, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    skip: !hasPermission('VIEW_STEP_LIST'),
  });

  const [addStepToJourney, { error: addStepError, loading: addStepLoading }] =
    useMutation(ADD_STEP_TO_JOURNEY, { errorPolicy: 'all' });

  const [
    updateStepActionItem,
    { error: updateStepActionItemError, loading: updateStepActionItemLoading },
  ] = useMutation(UPDATE_STEP_ACTION_ITEM, { errorPolicy: 'all' });

  const [updateStep, { error: updateStepItemError }] = useMutation(
    UPDATE_STEP,
    { errorPolicy: 'all' },
  );

  const [deleteStep, { error: deleteStepError }] = useMutation(DELETE_STEP, {
    errorPolicy: 'all',
  });

  return {
    deleteStep,
    updateStep,
    addStepError,
    addStepLoading,
    deleteStepError,
    addStepToJourney,
    fetchStepTemplate,
    updateStepItemError,
    stepTemplatesErrors,
    stepTemplatesLoading,
    updateStepActionItem,
    fetchStepTemplateError,
    fetchPatientOpenJourney,
    fetchStepTemplateLoading,
    updateStepActionItemError,
    updateStepActionItemLoading,
    fetchPatientOpenJourneyError,
    fetchPatientOpenJourneyLoading,
    stepTemplates: stepTemplates?.getStepTemplates,
    mostUsedStepTemplates: mostUsedStepTemplates?.getMostUsedStepTemplates,
    fetchMostUsedStepTemplates,
    mostUsedStepTemplatesError,
  };
};
