import { useLazyQuery } from '@apollo/client';
import {
  GET_RECORD,
  GET_RECORD_META,
  GET_TIMELINE,
  SEARCH_RECORDS,
} from 'src/constants';

export const useRecordApis = () => {
  const [
    fetchRecordMeta,
    { loading: fetchRecordMetaLoading, error: fetchRecordMetaError },
  ] = useLazyQuery(GET_RECORD_META);
  const [
    fetchSingleRecord,
    { loading: fetchSingleRecordLoading, error: fetchSingleRecordError },
  ] = useLazyQuery(GET_RECORD);
  const [getTimeline, { loading: timelineLoading, error: timelineError }] =
    useLazyQuery(GET_TIMELINE);
  const [
    fetchMoreTimeline,
    { loading: fetchMoreTimilineLoading, error: fetchMoreTimelineError },
  ] = useLazyQuery(GET_TIMELINE);
  const [
    fetchMoreSearchData,
    { loading: fetchMoreSearchLoading, error: fetchMoreSearchError },
  ] = useLazyQuery(SEARCH_RECORDS, { errorPolicy: 'all' });

  return {
    getTimeline,
    fetchRecordMeta,
    timelineError,
    timelineLoading,
    fetchMoreTimeline,
    fetchSingleRecord,
    fetchMoreSearchData,
    fetchMoreSearchError,
    fetchRecordMetaError,
    fetchRecordMetaLoading,
    fetchMoreTimelineError,
    fetchMoreSearchLoading,
    fetchSingleRecordError,
    fetchMoreTimilineLoading,
    fetchSingleRecordLoading,
  };
};
