import React from 'react';
import { AuthContextType } from 'src/types';
import { initialState } from './reducer';

const AuthContext = React.createContext<AuthContextType>({
  state: initialState,
  logIn: () => null,
  logOut: () => null,
});

export default AuthContext;
