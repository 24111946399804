import { ClipboardIcon, PrinterIcon } from 'src/assets/icons';
import { Button } from 'src/components';
import { openModal, removeModalHash, theme } from 'src/utils';
import { useLocation, useRoute } from 'wouter';
import { ToolbarWrapper } from '../styled';

const RecordToolBar: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.hash);
  const id = urlParams.get('id');
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id;
  const hashId = `${id}&patientId=${patientId}`;
  const printRoute = `/admin/patients/${patientId}/print?recordId=${id}`;
  const [, setLocation] = useLocation();

  return (
    <ToolbarWrapper justify="flex-start">
      <Button
        width="max-content"
        color={theme.grey[600]}
        background={theme.grey[100]}
        type="button"
        onClick={() => {
          removeModalHash();
          setTimeout(() => {
            setLocation(printRoute);
          });
        }}
      >
        <PrinterIcon />
        Export
      </Button>
      <Button
        width="max-content"
        color={theme.grey[600]}
        background={theme.grey[100]}
        onClick={() => openModal('create-task', hashId)}
      >
        <ClipboardIcon />
        Create task
      </Button>
    </ToolbarWrapper>
  );
};

export default RecordToolBar;
