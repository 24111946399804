/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { GET_INVOICES } from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { OptionProps } from 'src/types';
// import { useStaff } from 'src/state';
import _ from 'lodash';
import { useInventoryInvoiceApis } from './api';
import { InventoryInvoiceType, setInvoices } from './reducer';

interface FilterType {
  name: string;
  key: string;
  type?: 'select';
  checked: Omit<OptionProps, 'checked'>;
  options: Omit<OptionProps, 'checked'>[];
}

const status = {
  name: 'Status',
  key: 'status',
  checked: {} as Omit<OptionProps, 'checked'>,
  options: [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Paid',
      value: 'PAID',
    },
    {
      label: 'Unpaid',
      value: 'UNPAID',
    },
  ],
};

const invoiceType = {
  name: 'Invoice Type',
  key: 'invoiceType',
  checked: {} as Omit<OptionProps, 'checked'>,
  options: [
    {
      label: 'External',
      value: 'EXTERNAL',
    },
    {
      label: 'Facility',
      value: 'FACILITY',
    },
    {
      label: 'Patient',
      value: 'PATIENT',
    },
  ],
};

const getFilters = (filters: FilterType[]) =>
  filters.reduce((acc, filter) => {
    if (filter.checked?.label?.length > 0) {
      acc[filter.key] = filter.checked.value;
    }
    return acc;
  }, {} as Record<string, string | number | boolean>);

const useInventoryInvoices = () => {
  const allInvoices = useAppSelector(
    (state: RootState) => state.inventoryInvoice,
  );
  const dispatch = useAppDispatch();
  const limit = 10;

  const { fetchAllInvoices, fetchAllInvoicesLoading, fetchAllInvoicesError } =
    useInventoryInvoiceApis();

  const [filteredData, setFilteredData] =
    useState<InventoryInvoiceType>(allInvoices);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<FilterType[]>([
    { ...invoiceType },
    { ...status },
  ]);

  const { handleSearchChange, searchLoading, searchValue, searchQuery } =
    useSearch(GET_INVOICES, {
      onCompleted: (d: unknown) => {
        const data = d as { getInvoices: InventoryInvoiceType };
        setFilteredData(data?.getInvoices);
      },
    });

  const fetchInventoryInvoices = useCallback(async () => {
    const unpaid = await fetchAllInvoices({
      variables: {
        input: {
          page: 1,
          search: '',
          createdFrom: 'INVENTORY',
          status: 'UNPAID',
          limit,
        },
      },
      onCompleted(d) {
        const data = d?.getInvoices as InventoryInvoiceType;
        if (data.total) {
          setFilteredData(data);
          const f = _.cloneDeep(filters);
          f[1].checked = {
            label: 'Unpaid',
            value: 'UNPAID',
          };
          setFilters(f);
        }
      },
    });
    searchQuery({
      variables: {
        input: {
          page: 1,
          search: '',
          createdFrom: 'INVENTORY',
          limit,
        },
      },
      onCompleted(d) {
        const data = d?.getInvoices as InventoryInvoiceType;
        dispatch(setInvoices(data));
        if (!unpaid.data?.getInvoices?.total) setFilteredData(data);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetchAllInvoices]);

  useEffect(() => {
    fetchInventoryInvoices();
  }, [fetchInventoryInvoices]);

  const filter = (
    searchPage: number,
    currentFilters: { [key: string]: string | number | boolean | string[] },
  ) => {
    if (!currentFilters.status) delete currentFilters.status;

    searchQuery({
      variables: {
        input: {
          limit,
          page: searchPage,
          search: searchValue,
          createdFrom: 'INVENTORY',
          ...currentFilters,
        },
      },
      onCompleted(data) {
        if (!fetchAllInvoicesError) {
          setFilteredData((prev) => ({
            ...prev,
            ...data?.getInvoices,
          }));
        }
      },
    });
  };

  const applyFilters = (newFilter: {
    [key: string]: string | number | boolean | string[];
  }) => {
    setPage(1);
    filter(1, newFilter);
  };

  const removeFilter = (newFilter: {
    [key: string]: string | number | boolean | string[];
  }) => {
    setPage(1);
    filter(1, newFilter);
  };

  const clearFilters = () => {
    setFilteredData(allInvoices);
    setFilters([{ ...invoiceType }, { ...status }]);
    setPage(1);
  };

  const fetchNewpage = (newPage: number) => {
    filter(newPage, getFilters(filters));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e, {
      limit,
      createdFrom: 'INVENTORY',
      page: 1,
      ...getFilters(filters),
    });
  };

  return {
    limit,
    searchValue,
    clearFilters,
    applyFilters,
    onSearchChange,
    isLoading: (searchLoading && !filteredData) || fetchAllInvoicesLoading,
    fetchAllInvoicesLoading,
    fetchAllInvoicesError,
    searchLoading,
    fetchInventoryInvoices,
    filteredData,
    page,
    setPage,
    fetchNewpage,
    removeFilter,
    filters,
    setFilters,
    getFilters,
  };
};

export { useInventoryInvoices };
