import { getModalParams } from 'src/utils';
import {
  getStep,
  useAppSelector,
  usePatientJourney,
  useSteps,
} from 'src/store';
import { PatientJourneyType, StepData } from 'src/types/patientJourney.type';
import Step from '../PatientJourney/Step';
import { JourneyModal } from '../PatientJourney/styled';
import NewStep from './newStep';
import AllSteps from './allStep';
import { StepLoader } from '../PatientJourney/Empty';

const StepInfo = () => {
  const id = getModalParams('id');
  const patientId = getModalParams('patientId')?.split('?')[0];
  const view = getModalParams('view');
  const { patientJourney } = usePatientJourney(patientId);
  const step = useAppSelector(getStep(id));

  const { loading } = useSteps();

  return (
    <JourneyModal>
      {view === null && loading && <StepLoader />}
      {(view === null || view === 'history') && !loading && (
        <Step id={id} step={step as StepData} viewType="in-patients" />
      )}
      {view === 'allSteps' && (
        <AllSteps
          isPastJourney={patientJourney?.status === 'ENDED'}
          journeyId={patientJourney?.id}
          journey={patientJourney as PatientJourneyType}
        />
      )}
      {view === 'newStep' && <NewStep />}
    </JourneyModal>
  );
};

export default StepInfo;
