import { Pill } from 'src/components';
import { theme } from 'src/utils';

const colorMap = {
  blue: {
    textColor: '#0075FF',
    backgroundColor: '#E7F2FF',
    borderColor: '#0075FF',
  },
  orange: {
    textColor: theme.secondary[700],
    backgroundColor: theme.secondary[200],
    borderColor: theme.secondary[700],
  },
  yellow: {
    textColor: theme.accent[700],
    backgroundColor: theme.accent[200],
    borderColor: theme.accent[700],
  },
  green: {
    textColor: theme.green[700],
    backgroundColor: '#F1FFF5',
    borderColor: theme.green[300],
  },
};

export type ColorKey = keyof typeof colorMap;

const Tag: React.FC<{ color: ColorKey; value: string }> = ({
  color,
  value,
}) => {
  return (
    <Pill color="blue" modStyles={{ br: 0.25 }} outlined {...colorMap[color]}>
      {value}
    </Pill>
  );
};

export default Tag;
