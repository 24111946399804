import Skeleton from 'react-loading-skeleton';
import { Column, SkeletonWrapper, Text } from 'src/components';
import { theme } from 'src/utils';

const ProductInfo: React.FC<{
  title: string;
  info?: string | number;
  isLoading?: boolean;
  dataTestId?: string;
}> = ({ title, info, isLoading, dataTestId }) => {
  return (
    <Column data-testid={dataTestId}>
      <Text
        size="sm"
        color={theme.primary[500]}
        modStyles={{ ma: 0, mb: 0.25 }}
        data-testid="info-title"
      >
        {title}
      </Text>
      {isLoading && !info ? (
        <SkeletonWrapper>
          <Skeleton height={10} width="100%" />
        </SkeletonWrapper>
      ) : (
        <Text
          size="base"
          weight="semibold"
          color={theme.black[700]}
          modStyles={{ ma: 0 }}
          data-testid="info-value"
        >
          {info}
        </Text>
      )}
    </Column>
  );
};

export default ProductInfo;
