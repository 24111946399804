/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { SearchData } from 'src/types/search.type';

const formatDate = (date: string) => {
  return moment.utc(date).format('Do MMM YYYY');
};

export const mapCallback = (value: SearchData) => {
  const returnValue = { title: '', category: '', date: '', url: '' };
  if (value) {
    if (value.__typename === 'PatientSearchType' && value.user) {
      returnValue.category = 'patients';
      returnValue.title = `${value.user?.firstName} ${value?.user.lastName}`;
      returnValue.date = formatDate(value.createdAt);
      returnValue.url = contructUrl('patients', [value?._id]);
    }
    if (value.__typename === 'StaffSearchType' && value.user) {
      returnValue.category = 'staffs';
      returnValue.title = `${value.user?.firstName} ${value?.user.lastName}`;
      returnValue.date = formatDate(value.createdAt);
      returnValue.url = contructUrl('staffs', [value?._id]);
    }
    if (
      value.__typename === 'RecordSearchType' &&
      value.template &&
      value.patient
    ) {
      returnValue.category = 'records';
      returnValue.title = `${value.template.title}`;
      returnValue.date = formatDate(value.createdAt);
      returnValue.url = contructUrl('records', [value.patient._id, value?._id]);
    }
    if (value.__typename === 'AppointmentSearchType') {
      returnValue.category = 'schedule';
      returnValue.title = `${value?.type}`;
      returnValue.date = formatDate(value.createdAt);
      returnValue.url = contructUrl('schedule', [value?._id]);
    }
  }
  return returnValue;
};

export const contructUrl = (cat: string, ids: string[]) => {
  if (cat === 'patients') {
    return `/admin/patients/${ids[0]}`;
  }
  if (cat === 'records') {
    return `/admin/patients/${ids[0]}#record&id=${ids[1]}`;
  }
  if (cat === 'schedule') {
    return `/calendar#view-appointment&id=${ids[0]}`;
  }
  if (cat === 'staffs') {
    return `/admin/manage-staff`;
  }
  return '';
};
