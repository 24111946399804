import styled from 'styled-components';
import { Row } from 'src/components';
import { theme } from 'src/utils/variables';

export const AvatarGroupContent = styled(Row)`
  img {
    border: ${theme.secondary[500]} solid 2px;
    margin-left: -1.3rem;
    padding: 0;
  }
  img:first-child {
    margin-left: 0;
  }
`;
