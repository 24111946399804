import React, { useState } from 'react';
import { CheckCircleIcon } from 'src/assets/icons';
import { Button, Column, H4, Row, Text } from 'src/components';
import { removeModalHash } from 'src/utils';

interface PromptType {
  onConfirm: () => void | Promise<void>;
  onDismiss?: () => void | Promise<void>;
  icon?: React.ReactNode;
  title: string;
  message: string;
  confirmText: string;
  dismissText: string;
}

export const ConfirmationPrompt: React.FC<PromptType> = ({
  icon,
  onConfirm,
  onDismiss,
  title,
  message,
  confirmText,
  dismissText,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dismissLoading, setDismissLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setConfirmLoading(true);
      await onConfirm();
    } catch {
      setConfirmLoading(false);
    }
  };

  const handleDismiss = async () => {
    try {
      setDismissLoading(true);
      await onDismiss?.();
    } catch {
      setDismissLoading(false);
    }
  };

  return (
    <Column gap={1} align="center" minWidth="35vw">
      {icon || <CheckCircleIcon />}
      <Column maxWidth="25rem" align="center">
        <H4 align="center" modStyles={{ my: 0 }}>
          {title}
        </H4>
        <Text
          data-testid="invoice-success-msg"
          align="center"
          size="sm"
          modStyles={{ my: 0 }}
        >
          {message}
        </Text>
      </Column>
      <Row gap={1} width="100%" justify="center">
        <Button
          variant="cancel"
          width="max-content"
          onClick={onDismiss? handleDismiss : removeModalHash}
          isLoading={dismissLoading}
          disabled={dismissLoading || confirmLoading}
          modStyles={{ minW: '10rem' }}
        >
          {dismissText}
        </Button>
        <Button
          width="max-content"
          data-testid="view-new-invoice"
          onClick={handleConfirm}
          isLoading={confirmLoading}
          disabled={confirmLoading || dismissLoading}
          modStyles={{ minW: '10rem' }}
        >
          {confirmText}
        </Button>
      </Row>
    </Column>
  );
};
