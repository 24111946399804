import React from 'react';

export interface Features {
  [key: string]: {
    enabled: boolean;
  };
}

export interface FeatureFlagContextType {
  feats: Features | undefined;
  getFeature: (feat: string) =>
    | {
        enabled: boolean;
      }
    | undefined;
  setFeature: (key: string, value: boolean) => void;
}

const FeatureFlagContext = React.createContext({} as FeatureFlagContextType);

export default FeatureFlagContext;
