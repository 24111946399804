import * as React from 'react';
import { PatientsProps, SearchPatientProps } from 'src/types';
import PatientsContext from './context';

const PatientsProvider: React.FC = ({ children }) => {
  const [initialPatients, setInitialPatients] = React.useState<SearchPatientProps>({} as SearchPatientProps);
  const [activePatients, setActivePatients] = React.useState<PatientsProps>({} as PatientsProps);

  return (
    <PatientsContext.Provider 
      value={{ 
        initialPatients, 
        activePatients,
        setInitialPatients,
        setActivePatients
      }}
    >
      {children}
    </PatientsContext.Provider>
  );
};

export default PatientsProvider;
