import { useState, ChangeEvent } from "react";
import { useDebounceCallback } from "./useDebounceCallback";

export interface ApolloSearchHook {
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const useApolloSearch = (debounceTime?: number): ApolloSearchHook => {
  const [searchValue, setSearchValue] = useState<string>("");
  const delayCallback = useDebounceCallback(debounceTime || 1000);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    delayCallback(() => setSearchValue(e.target.value.trim()));
  };

  return { handleSearchChange, searchValue, setSearchValue };
};
