import { useCallback, useEffect, useState } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useStepsApis,
  setSteps,
  getSteps,
} from 'src/store';
import { OperationVariables } from '@apollo/client';
import { StaffProps, StepData } from 'src/types';
import { GET_STEPS } from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import { removeEmptyValues } from 'src/utils';
import _ from 'lodash';
import { useStaff } from 'src/state';

type Filters = {
  assigned?: string;
  stepTemplate?: string;
};

const stepMap: Record<string, string> = {
  STARTED: 'ongoingSteps',
  PENDING: 'pendingSteps',
  COMPLETED: 'completedSteps',
};

export type GroupedSteps = {
  pendingSteps: {
    steps: StepData[];
  };
  ongoingSteps: {
    steps: StepData[];
  };
  completedSteps: {
    steps: StepData[];
  };
};

const transformResponse = (
  d: OperationVariables,
  filters?: Filters,
  staff?: StaffProps,
) => {
  let filteredData: StepData[] = [...d.getSteps.steps];
  const staffId = staff?.getStaff?.id;
  const staffGroupId = staff?.getStaff?.staffGroup?.id as string;

  if (filters && !_.isEmpty(removeEmptyValues(filters))) {
    if (!filters?.assigned && filters?.stepTemplate) {
      filteredData = filteredData.filter(
        (step) => step?.stepTemplate?.id === filters.stepTemplate,
      );
    }


    if (filters.assigned === 'me') {
      filteredData = filteredData.filter((step) => {
        const parameters = step.parameters.map((param) => param.staff?.id);
        const logs = step.eventLogs.map((log) => log.staff?.id);
        
        return filters.stepTemplate
          ? (parameters.includes(staffId) || logs.includes(staffId as string)) &&
          step?.stepTemplate?.id === filters.stepTemplate

          : (parameters.includes(staffId) || logs.includes(staffId as string))
      });
    }
    if (filters.assigned === 'group') {
      filteredData = filteredData.filter((step) => {
        const groups = step.stepTemplate?.assigned?.map((group) => group.id);

        return filters.stepTemplate
          ? groups?.includes(staffGroupId) &&
          step?.stepTemplate?.id === filters.stepTemplate
          : groups?.includes(staffGroupId);
      });
    }

  }

  const data = filteredData.reduce(
    (acc: { [key: string]: { steps: StepData[] } }, step: StepData) => {
      acc[stepMap[step.status]].steps.push(step);
      return acc;
    },
    {
      pendingSteps: {
        steps: [],
      },
      ongoingSteps: {
        steps: [],
      },
      completedSteps: {
        steps: [],
      },
    },
  );
  return data as GroupedSteps;
};

const useSteps = () => {
  const { staff, hasPermission } = useStaff();
  const facilityValue= staff.getStaff.facility.id;
  const [facility, setFacility] = useState(facilityValue)
  const steps = useAppSelector(getSteps);
  const [filters, setFilters] = useState({
    assigned: '',
    stepTemplate: '',
  } as Filters);
  const [filteredData, setFilteredData] = useState(
    transformResponse({ getSteps: { steps: steps || [] } }),
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { fetchSteps } = useStepsApis();

  const fetchAllSteps = useCallback(async () => {
    if (!hasPermission("VIEW_STEPS_IN_A_JOURNEY")) return;
    setLoading(true);
    await fetchSteps({
      variables: {
        input: {
          skipPagination: true,
          facility: staff.getStaff.facility.id
        },
      },
      onCompleted(d) {
        if (d) {
          dispatch(setSteps({ data: d.getSteps }));
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
    });
  }, [dispatch, fetchSteps, hasPermission, staff.getStaff.facility.id]);

  useEffect(() => {
    setFacility(facilityValue)
  }, [facilityValue])

  useEffect(() => {
    if (!steps || (steps.length > 0 && steps?.[0]?.facility.id !== facility)) {
      fetchAllSteps();
    } else {
      setFilteredData(
        transformResponse({ getSteps: { steps } }, filters, staff),
      );
    }
  }, [facility, fetchAllSteps, filters, staff, steps]);


  const { handleSearchChange, searchLoading } = useSearch(GET_STEPS, {
    onCompleted: (d) => {
      setFilteredData(
        transformResponse(d as OperationVariables, filters, staff),
      );
      // setFilters({ assigned: '', stepTemplate: '' });
    },
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilteredData(
        transformResponse({ getSteps: { steps } }, filters, staff),
      );
    }
    handleSearchChange(e, {
      skipPagination: true,
      facility: staff.getStaff.facility.id
      // ...removeEmptyValues(filters),
    });
  };

  const onApplyFilters = (filter: Filters) => {
    setFilters(filter);
    if (!_.isEmpty(removeEmptyValues(filter))) {
      setFilteredData(
        transformResponse({ getSteps: { steps } }, filter, staff),
      );
    } else {
      setFilteredData(transformResponse({ getSteps: { steps } }));
    }

    // return;
    // searchQuery({
    //   variables: {
    //     input: {
    //       search: searchValue || '',
    //       ...removeEmptyValues(filter),
    //     },
    //   },
    //   onCompleted(d) {
    //     if (d) {
    //       setFilteredData(transformResponse(d));
    //     }
    //   },
    // });
  };

  return {
    filters,
    setFilters,
    filteredData,
    fetchAllSteps,
    onSearchChange,
    onApplyFilters,
    loading: loading || searchLoading,
  };
};

export { useSteps };
