/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { GET_PRODUCT_LOGS } from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import {
  RootState,
  useAppSelector,
  useAppDispatch,
  useProductLogsApis,
  setProductLogs,
} from 'src/store';
import { useToast } from 'src/state';
import { RangeData } from 'src/components';
import { ProductLogs } from 'src/types';
import { getModalParams } from 'src/utils';

const useProductLogs = (id?: string) => {
  const productId = id || getModalParams('id');
  const allProductLogs = useAppSelector(
    (state: RootState) => state.allProductLogs,
  );
  const productLogs = allProductLogs[productId];
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const limit = 30;

  const {
    getProductLogs,
    isLogsLoading,
    isLogsError,
    fetchMoreLogs,
    isFetchMoreLogsLoading,
    isFetchMoreLogsError,
  } = useProductLogsApis();

  const [filteredData, setFilteredData] = useState(productLogs);

  const {
    handleSearchChange,
    searchData,
    searchLoading,
    searchValue,
    searchQuery,
  } = useSearch(GET_PRODUCT_LOGS, {
    onCompleted: (d: unknown) => {
      const data = d as { getProductLogs: ProductLogs };
      setFilteredData(data.getProductLogs);
    },
  });

  const fetchProductLogs = useCallback(
    async (pid?: string) => {
      await getProductLogs({
        variables: {
          input: {
            productId: pid || productId,
            page: 1,
            limit,
          },
        },
        onCompleted(d) {
          const data = d.getProductLogs;
          dispatch(setProductLogs({ productId: pid || productId, logs: data }));
          setFilteredData(data);
        },
      });
    },
    [dispatch, getProductLogs, productId],
  );

  useEffect(() => {
    fetchProductLogs();
  }, [fetchProductLogs]);

  const [dateFilterApplied, setDateFilterApplied] = useState(false);
  const [dateRange, setDateRange] = useState<RangeData>({
    startDate: null as unknown as Date,
    endDate: new Date(''),
    key: 'selection',
  });

  const formattedRange = {
    startDate: moment(dateRange.startDate).format('Do MMM, yyyy'),
    endDate: moment(dateRange.endDate).format('Do MMM, yyyy'),
  };

  const getDateFilters = () => {
    if (dateFilterApplied) {
      return {
        startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
        endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
      };
    }
    return {};
  };

  const applyFilters = () => {
    setDateFilterApplied(true);

    searchQuery({
      variables: {
        input: {
          limit,
          page: 1,
          search: searchValue || '',
          startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
          endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
        },
      },
      onCompleted(data) {
        setFilteredData(data.getProductLogs);
      },
    });
  };

  const clearFilters = () => {
    setFilteredData(productLogs);
    setDateFilterApplied(false);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilteredData(productLogs);
    }
    handleSearchChange(e, {
      limit,
      page: 1,
      productId,
      ...getDateFilters(),
    });
  };

  const currentPage = Math.ceil((filteredData?.logs?.length as number) / limit);

  const fetchMore = async () => {
    await fetchMoreLogs({
      variables: {
        input: {
          limit,
          page: currentPage + 1,
          search: searchValue || '',
          productId,
          ...getDateFilters(),
        },
      },
      onCompleted(d) {
        if (isFetchMoreLogsError) {
          showToast('Error fetching more records', 'error');
        } else {
          setFilteredData((prev) => ({
            ...prev,
            logs: [...prev.logs, ...d?.getProductLogs?.logs],
          }));
          if (!(dateFilterApplied || searchValue)) {
            dispatch(
              setProductLogs({
                productId,
                logs: {
                  ...productLogs,
                  logs: [...productLogs.logs, ...d?.getProductLogs?.logs],
                },
              }),
            );
          }
        }
      },
    });
  };

  return {
    limit,
    fetchMore,
    fetchProductLogs,
    productId,
    searchData,
    searchValue,
    clearFilters,
    applyFilters,
    formattedRange,
    onSearchChange,
    handleSearchChange,
    isLogsLoading,
    isLogsError,
    searchLoading,
    setDateRange,
    filteredData,
    dateFilterApplied,
    dateRange,
    currentPage,
    fetchMoreLoading: isFetchMoreLogsLoading,
  };
};

export { useProductLogs };
