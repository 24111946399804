import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import './index.css';
import App from './App';

mixpanel.init(
  (process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN as string) || 'DUMMY_TOKEN', // mixpanel breaks if undefined or null is passed to init
  {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  },
);
LogRocket.init(`${process.env.REACT_APP_LOGROCKET_ID}`);
LogRocket.identify(`${process.env.REACT_APP_LOGROCKET_ID}`);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
