import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { useLocation } from 'wouter';
import { Frequency, RRule } from 'rrule';
import _ from 'lodash';
import { Column, IconButton, Row } from 'src/components';
import { useStaff } from 'src/state';
import {
  openModal,
  theme,
  removeModalHash,
  getModalParams
} from 'src/utils';
import {
  ClockIcon,
  EditIcon,
  ExternalLinkIcon,
  StethoscopeIcon,
  UserIcon,
} from 'src/assets/icons';
import useMediaQuery from 'src/utils/useMediaQuery';
import { WeekValues, MonthValues, APPOINTMENT_STATUS } from 'src/constants';
import { AppointmentDetailsType } from '.';
import {
  ModalTitle,
  AppointmentText,
  PaddingWrapper
} from './styled';


const AppointmentDetails: React.FC<AppointmentDetailsType> = ({
  type,
  status,
  patient,
  provider,
  isExpired,
  visitTime,
  eventDate,
  recurrenceRule,
  appointmentLoading
}) => {
  const timeFormat = 'h:mm a';
  const { hasPermission } = useStaff();
  const [, setLocation] = useLocation();
  const id = getModalParams('id') as string;
  const isMobile = !useMediaQuery('(min-width: 960px)');

  const { user: patientInfo, id: patientId } = patient || {};
  const { user: providerInfo, staffGroup } = provider || {};
  const { lastName: providerLastName,  firstName: providerFirstName } = providerInfo || {};
  const { lastName: patientLastName, firstName: patientFirstName } = patientInfo || {};

  const {
    byMonth,
    interval,
    byWeekDay,
    frequency,
    byMonthDay
  } = recurrenceRule || {};


  const { startTime: sT, endTime: eT } = visitTime || {};
  const endTime = moment(eT, 'hh:mm').format(timeFormat);
  const startTime = moment(sT, 'hh:mm').format(timeFormat);
  const appointmentTimeDisplay = `${eventDate}, ${startTime} - ${endTime}`;

  const isCancelledAppointment = status === APPOINTMENT_STATUS.CANCELLED;
  const isCompletedAppointment = status === APPOINTMENT_STATUS.COMPLETED;


  const vals = {
    freq: Frequency[frequency as unknown as number] || 0,
    byweekday: byWeekDay?.[0]
      ? WeekValues[byWeekDay[0] as keyof typeof WeekValues]
      : null,
    bymonth: byMonth?.[0]
      ? MonthValues[byMonth[0] as keyof typeof MonthValues]
      : null,
      bymonthday: byMonthDay,
    interval
  };




  const rule = new RRule(_.pickBy(vals, _.identity));

  return (
    <PaddingWrapper gap={0.75}>
      <Row align="center" gap={0.5} justify="space-between">
        {appointmentLoading ? <Skeleton width="19.75rem" height={20} /> : (
          <>
            <ModalTitle weight="bold" color={theme.primary[700]}>
              {type}
            </ModalTitle>
            {!isMobile && !isCancelledAppointment && !isCompletedAppointment && hasPermission("EDIT_APPOINTMENT") && (
              <IconButton
                size="min-content"
                variant="text"
                noIconStyles
                background={theme.grey[700]}
                disabled={isExpired}
                onClick={() => {
                  openModal('edit-appointment', id as string);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
      </Row>
      <Column>
        <Row align="flex-start" gap={0.5}>
          {appointmentLoading ? <Skeleton width="19.75rem" height={20} /> : (
            <>
              <ClockIcon color={theme.red[300]} />
              <Column>
                <AppointmentText
                  weight="semibold"
                  color={theme.grey[700]}
                  size="sm"
                >
                  {appointmentTimeDisplay}
                </AppointmentText>
                <AppointmentText
                  weight="semibold"
                  color={theme.grey[500]}
                  size="xs"
                  transform="capitalize"
                >
                  {_.isEmpty(recurrenceRule)
                    ? 'Does not repeat'
                    : rule.toText()}
                </AppointmentText>
              </Column>
            </>
          )}
        </Row>
      </Column>
      <Row align="center" gap={0.5}>
        {appointmentLoading ? <Skeleton width="19.75rem" height={20} /> : (
          <>
            <StethoscopeIcon color={theme.red[300]} />
            <AppointmentText
              weight="semibold"
              color={theme.grey[700]}
              size="sm"
              transform='capitalize'
            >
              {`${staffGroup?.name.endsWith("s") ? staffGroup?.name?.slice(0, -1) : staffGroup?.name} 
              ${providerLastName} ${providerFirstName}`}
            </AppointmentText>
          </>
        )}
      </Row>
      <Row align="center">
        {appointmentLoading ? <Skeleton width="19.75rem" height={20} /> : (
          <>
            <Row align="center" gap={0.5}>
              <UserIcon color={theme.red[300]} />
              <AppointmentText
                weight="semibold"
                color={theme.grey[700]}
                size="sm"
              >
                {`${patientLastName} ${patientFirstName}`}
              </AppointmentText>
            </Row>
            {hasPermission("VIEW_PATIENT") && (
              <IconButton
                width="max-content"
                size="min-content"
                noIconStyles
                onClick={() => {
                  removeModalHash();
                  setLocation(`/admin/patients/${patientId}`);
                }}
                variant="text"
                background={theme.grey[700]}
              >
                <ExternalLinkIcon />
              </IconButton>
            )}
          </>
        )}
      </Row>
    </PaddingWrapper>
  );
};

export default AppointmentDetails
