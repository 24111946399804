import { OperationVariables, useMutation } from "@apollo/client";
import { 

  UPDATE_STEP_TEMPLATE,
  CREATE_STEP_TEMPLATE,
  GET_STEP_TEMPLATES,
  DELETE_STEP_TEMPLATE,
  UPDATE_STEP,
  ADD_STEP,
  GET_STEPS
} from "src/constants";
import { 
  AddStepProps,
  CreateStepTemplateProps, 
  DeleteStepTemplateProps, 
  UpdateStepProps, 
  UpdateStepTemplateProps, 
} from "src/types";
import { useStaff } from "../staff";
import { useToast } from "../toast";
import { useRowData } from "../rowData";



const useMutations = () => {
  const { showToast } = useToast();
  const { staff,  hasPermission  } = useStaff();
  const { updateRowData } = useRowData();


  const useUpdateStepTemplate = () => {
    const [execute, result] = useMutation<UpdateStepTemplateProps>(UPDATE_STEP_TEMPLATE);

    const updateStepTemplate = (id: string, value?: OperationVariables) => {
      if (!hasPermission("EDIT_STEP")) return;
      return execute({
        variables: { 
          updateStepTemplateId: id, 
          input: value 
        },
        onCompleted: (d) => {
          showToast(d.updateStepTemplate.message, 'success')
          updateRowData('stepTemplate', d.updateStepTemplate.stepTemplate)
        },
        refetchQueries: [GET_STEP_TEMPLATES, {query: GET_STEP_TEMPLATES, variables: {input: {
          facility: staff.getStaff.facility.id,
          skipPagination: true,
        }}}],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, updateStepTemplate };
  };


  const useCreateStepTemplate = () => {
    const [execute, result] = useMutation<CreateStepTemplateProps>(CREATE_STEP_TEMPLATE);

    const createStepTemplate = (value?: OperationVariables) => {
      if (!hasPermission("CREATE_A_NEW_STEP")) return;
      return execute({
        variables: { 
          input: {
            ...value,
            facility: staff.getStaff.facility.id
          }
        },
        onCompleted: (d) => showToast(d.createStepTemplate.message, 'success'),
        refetchQueries: [GET_STEP_TEMPLATES, {query: GET_STEP_TEMPLATES, variables: {input: {
          facility: staff.getStaff.facility.id,
          skipPagination: true,
        }}}],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, createStepTemplate };
  };


  const useDeleteStepTemplate = () => {
    const [execute, result] = useMutation<DeleteStepTemplateProps>(DELETE_STEP_TEMPLATE);

    const deleteStepTemplate = (id: string) => {
      if (!hasPermission("DELETE_STEP")) return;
      return execute({
        variables: { deleteStepTemplateId: id },
        onCompleted: (d) => showToast(d.deleteStepTemplate.message, 'success'),
        refetchQueries: [GET_STEP_TEMPLATES, {query: GET_STEP_TEMPLATES, variables: {input: {
          facility: staff.getStaff.facility.id,
          skipPagination: true,
        }}}],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, deleteStepTemplate };
  };

  const useUpdateStep = () => {
    const [execute, result] = useMutation<UpdateStepProps>(UPDATE_STEP);

    const updateStep = (id: string, values: OperationVariables) => {
      if (!hasPermission("EDIT_STEP")) return;
      return execute({
        variables: { 
          stepId: id,
          input: values 
        },
        // optimisticResponse
        // optimisticResponse: (d) {
        //   // Define the structure of your response here
        //   addComment: {
        //     __typename: 'Comment',
        //     id: 'TEMP_ID', // Use a temporary ID until the actual ID is generated
        //     text: 'Optimistically added comment text',
        //   },
        // },
        onCompleted: (d) => showToast(d.updateStep.message, 'success'),
        refetchQueries: [GET_STEPS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, updateStep };
  };


  const useAddStep = () => {
    const [execute, result] = useMutation<AddStepProps>(ADD_STEP);

    const addStep = (values: OperationVariables) => {
      if (!hasPermission("CREATE_A_NEW_STEP")) return;
      return execute({
        variables: { input: values },
        onCompleted: (d) => showToast(d.addStep.message, 'success'),
        refetchQueries: [GET_STEPS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, addStep };
  };


  return {
    useCreateStepTemplate,
    useDeleteStepTemplate,
    useUpdateStepTemplate,
    useUpdateStep,
    useAddStep
  }
}

export default useMutations;