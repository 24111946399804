import { useLocation } from 'wouter';
import { ArrowBackIcon } from 'src/assets/icons';
import { openModal, theme } from 'src/utils';
import { Row } from '../Flex';
import { GoButton } from './styled';

interface Props {
  label: string;
  link: string;
  icon?: React.ReactNode;
}

interface HashDetails {
  hash: string;
  id: string;
}

export const BackButton: React.FC<Props> = ({
  label,
  link,
  icon = <ArrowBackIcon />,
}) => {
  const [, setLocation] = useLocation();

  const getHashDetails = (url: string): HashDetails => {
    const hashDetails: HashDetails = {
      hash: '',
      id: '',
    };

    if (url.includes('&')) {
      const hashValue = url.split('&');
      hashDetails.hash = hashValue[0] || '';
      hashDetails.id = hashValue[1]?.split('=')[1] || '';
    }
    return hashDetails;
  };

  const { hash, id } = getHashDetails(link);

  return (
    <GoButton
      variant="text"
      modStyles={{ py: 0, px: 0 }}
      width="max-content"
      onClick={() => {
        if (hash && id) {
          return openModal(hash, id);
        }
        setLocation(link);
      }}
    >
      <Row
        color={theme.primary[200]}
        width="2.125rem"
        height="2.125rem"
        align="center"
        justify="center"
      >
        {icon}
      </Row>
      {label}
    </GoButton>
  );
};
