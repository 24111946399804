import { theme, font } from 'src/utils/variables';
import styled from 'styled-components';

export const TextareaBase = styled.textarea`
  width: 100%;
  border: none;
  background-color: ${theme.grey[100]};
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 0;
  outline: 0;
  font-size: ${font.sizes.sm};
  font-weight: ${font.weights.semibold};
  color: ${theme.grey[600]};
  &::placeholder {
    color: ${theme.grey[400]};
    font-weight: ${font.weights.bold};
  }
`;
