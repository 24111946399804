import { Modal, MultiRowForm } from 'src/containers';
import { useMutation } from '@apollo/client';
import { useRef, useState } from 'react';
import { Drug, Medications as MedicationsType } from 'src/types';
import { useMedicalSummary, useStaff, useToast } from 'src/state';
import { ADD_MEDICATION } from 'src/constants/Mutations';
import { useRoute } from 'wouter';
import { medicationValidation, medicationValidationErrorMessages, removeModalHash } from 'src/utils';
import { PERMISSION_ACTION_MESSAGE, SEARCH_DRUGS } from 'src/constants';
import { AsyncSearchInput, Button, H4, Row, SkeletonLoader } from 'src/components';
import { useMultiRowParams } from 'src/hooks';
import { useFormik } from 'formik';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import Validator from 'validatorjs';
import ProductExtraInfo from '../Inventory/components/ProductExtraInfo';
import RowInputGroup from './RowInputGroup';


interface FormState {
  medications: Omit<MedicationsType, '__typename'>[]
};


const Medications: React.FC = () => {
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id as string;
  const { hasPermission } = useStaff();
  const { activePatientSummary, refreshMedicalSummary, loading: summaryLoading } = useMedicalSummary();


  const state = {
    drugName: '',
    form: '',
    dosage: '',
    frequency: '',
    duration: '',
    prescribedBy: ''
  }

  const inputHeaders = [
    'Drug Name',
    'Form',
    'Dosage',
    'Frequency',
    'Duration'
  ];


  const defaultState = !activePatientSummary?.medications.length
    ? { medications: [state] as unknown as Omit<MedicationsType, '__typename'>[] }
    : { medications: activePatientSummary?.medications as unknown as Omit<MedicationsType, '__typename'>[] };

  const searchRef = useRef<HTMLInputElement | null>(null);
  const { staff } = useStaff();
  const { showToast } = useToast();
  const [addMedication, { error: failedUpdate, loading }] = useMutation(
    ADD_MEDICATION,
  );

  
  const { values, setValues, errors, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: defaultState,
    enableReinitialize: true,
    onSubmit: (_values) => onSubmit(_values),
    validateOnChange: false,
    validate: (validationValues) => {
      const validation = new Validator(
        validationValues, 
        medicationValidation, 
        medicationValidationErrorMessages
      );
      validation.passes();
      return validation.errors.errors;
    },
  })
  

  const defaultActiveRow = {
    idx: -1,
    value: '',
  };

  const [isActiveRow, setIsActiveRow] = useState({ ...defaultActiveRow });

  const onSubmit = (d: FormState) => {
    if (!hasPermission("UPDATE_MEDICAL_SUMMARY")) return showToast(PERMISSION_ACTION_MESSAGE, "warning");
    addMedication({
      variables: {
        input: {
          medications: d.medications.map((med) => ({
            dosage: med.dosage,
            duration: med.duration,
            frequency: med.frequency,
            form: med.form || "",
            drugName: med.drugName,
            // eslint-disable-next-line no-underscore-dangle
            prescribedBy: med.prescribedBy._id || staff.getStaff.id,
          })),
        },
        patientId,
      },
      async onCompleted() {
        if (!failedUpdate) {
          refreshMedicalSummary({
            onCompleted: () => {
              showToast('Summary updated', 'success');
              removeModalHash();
            },
          });
        } else {
          showToast('Error Updating Summary', 'error');
        }
      },
    });
  };


  const { addNewRow, deleteRow } = useMultiRowParams<FormState>(
    'medications',
    state,
    values, 
    setValues
  );

  

  const getDrugExtraInfo = (drug: Drug) =>
    `Form: ${
      drug.form || 'N/A'
    }, Ingredients: ${drug.ingredients || 'N/A'}, Strength: ${
      drug.strength || 'N/A'
    }`;

  const renderDrugItem = (drug: Record<string, unknown>) => {
    const d = drug as unknown as Drug;
    const extraInfo = getDrugExtraInfo(d).replace('&amp;', '&');
    return <ProductExtraInfo name={d.name} brandName={d.brandName} extraInfo={extraInfo} />;
  };


  const handleSearchSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    d?: Record<string, unknown>,
  ) => {
    const drug = d as unknown as Drug;
    setFieldValue?.(`medications[${isActiveRow.idx}].drugName`, drug.name);
    setFieldValue?.(`medications[${isActiveRow.idx}].form`, drug.form);
  };

  
  const handleRowInputClick = (idx: number) => {
    setIsActiveRow({
      idx,
      value: '',
    });
    searchRef.current?.focus();
  };
 

  return (
    <Modal width='79rem'>
      <H4>Add Medication</H4>
      <Row width="50rem">
        <AsyncSearchInput
          elementRef={searchRef}
          name="isActiveRow.value"
          emptyText="No item found"
          placeholder="Search or choose product"
          dataTestId="search-drug"
          value={isActiveRow.value}
          handleChange={(e) =>
            setIsActiveRow((prev) => ({ ...prev, value: e.target.value }))}
          query={SEARCH_DRUGS}
          handleSelect={handleSearchSelect}
          disabled={summaryLoading}
          render={renderDrugItem}
          refetchOnMount
          selectors={{
            dataSelector: 'searchDrugs.drugs',
            valueSelector: 'name',
            labelSelector: 'name',
            totalSelector: 'searchDrugs.total',
          }}
        />
      </Row>
      {summaryLoading ? (
        <SkeletonLoader height={100} />
        ) : (
          <FullWidthForm onSubmit={handleSubmit}>
            <MultiRowForm
              shouldShowSuggestion={values?.medications?.[values?.medications?.length - 1]?.drugName !== ''}
              onAddNewRow={addNewRow}
              options={{
              isTable: true,
              headers: inputHeaders,
              moreProps: { item: state },
            }}
            >
              {values?.medications?.map((item, idx) => (
                <RowInputGroup
                  handleRowInputClick={handleRowInputClick}
                  item={item}
                  key={idx}
                  arrIdx={idx}
                  errors={errors}
                  handleChange={handleChange}
                  deleteRow={deleteRow}
                />
            ))}
            </MultiRowForm>
            <Button 
              modStyles={{ mt: '1rem' }} 
              type="submit" 
              isLoading={loading}
              width="15.875rem"
            >
              Submit
            </Button>
          </FullWidthForm>
      )}
    </Modal>
  );
};

export default Medications;