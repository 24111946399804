import { useLazyQuery } from '@apollo/client';
import { GET_INVOICE_LOGS } from 'src/constants';

export const useInvoiceLogsApis = () => {
  const [getInvoiceLogs, { loading: isLogsLoading, error: isLogsError }] =
    useLazyQuery(GET_INVOICE_LOGS);
  const [
    fetchMoreLogs,
    { loading: isFetchMoreLogsLoading, error: isFetchMoreLogsError },
  ] = useLazyQuery(GET_INVOICE_LOGS);

  return {
    getInvoiceLogs,
    isLogsError,
    isLogsLoading,
    fetchMoreLogs,
    isFetchMoreLogsError,
    isFetchMoreLogsLoading,
  };
};
