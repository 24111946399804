import { gql } from '@apollo/client';
import { facilityFieldsFragment } from '../Fragments/facility';
import { organisationFieldsFragment } from '../Fragments/organisation';

export const REGISTER_FACILITY = gql`
  mutation RegisterOrganisation($input: RegisterOrganisationInput!) {
    registerOrganisation(input: $input) {
      success
      message
    }
  }
`;

export const FACILITY_SETUP = gql`
  mutation SetupAccount($input: SetupAccountInput!) {
    setupAccount(input: $input) {
      success
      message
      organisation {
        ...OrganisationFields
      }
      facility {
        ...FacilityFields
      }
      auth {
        accessToken
        refreshToken
      }
    }
  }
  ${organisationFieldsFragment}
  ${facilityFieldsFragment}
`;

export const SWITCH_WORKSPACE = gql`
  mutation SwitchWorkspace($input: SwitchWorkspaceInput) {
    switchWorkspace(input: $input) {
      accessToken
      refreshToken
      staff {
        facility {
          address
          organisation {
            name
          }
        }
      }
    }
  }
`;
