import { getModalParams } from 'src/utils';
import { createContext, useEffect, useState } from 'react';
import {
  getPastJourney,
  getStepById,
  getStepInPastJourney,
  useAppSelector,
  usePatientJourney,
} from 'src/store';
import { PatientJourneyType, StepData } from 'src/types';
import { useRoute } from 'wouter';
import { JourneyModal } from './styled';
import AllSteps from './AllSteps';
import NewStep from './NewStep';
import Step from './Step';

type ViewContextType = {
  view: string;
  setView: React.Dispatch<React.SetStateAction<string>>;
};
export const ViewContext = createContext<ViewContextType>(
  {} as ViewContextType,
);

const PatientJourney = () => {
  const [, params] = useRoute('/admin/patients/:id');
  const pId = getModalParams("patientId")?.split("?")[0];
  const patientId = params?.id as string || pId;

  const modalView = getModalParams('mView');
  const [view, setView] = useState(modalView);
  const stepId = getModalParams('stepId');
  const journeyId = getModalParams('id');
  const step = useAppSelector(getStepById(patientId, stepId)) as StepData;
  const pastJourney = useAppSelector(
    getPastJourney(patientId, journeyId as string),
  ) as PatientJourneyType;

  const pastJourneyStep = useAppSelector(
    getStepInPastJourney(patientId, journeyId, stepId),
  ) as unknown as StepData;

  const { patientJourney } = usePatientJourney(patientId);

  useEffect(() => {
    setView(modalView);
  }, [modalView]);

  return (
    <JourneyModal>
      <ViewContext.Provider value={{ view, setView }}>
        {view === 'allSteps' &&
          (!journeyId ? (
            <AllSteps journey={patientJourney as PatientJourneyType} />
          ) : (
            <AllSteps
              journey={pastJourney}
              journeyId={journeyId}
              isPastJourney
            />
          ))}
        {view === 'newStep' && <NewStep />}
        {view === 'step' &&
          stepId &&
          (!journeyId ? (
            <Step id={stepId} step={step} viewType="current-journey" />
          ) : (
            <Step
              id={stepId}
              step={pastJourneyStep}
              journeyId={journeyId}
              viewType="past-journey"
            />
          ))}
      </ViewContext.Provider>
    </JourneyModal>
  );
};

export default PatientJourney;
