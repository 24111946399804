import { gql } from '@apollo/client';

export const stepTemplateFieldsFragment = gql`
  fragment StepTemplateFields on StepTemplate {
    id: _id
    isGlobalClone
    facility {
      id: _id
    }
    name
    parameters {
      type
      title
      entryType
    }
    stepTemplateId
    updatedAt
    createdAt
    description
    actions {
      title
      tag
    }
    assigned {
      id: _id
      name
    }
  }
`;
