import moment from 'moment';
import { PatientProps } from 'src/types';

export const LOW = 'Low';
export const TOO_LOW = 'Too low';
export const HIGH = 'High';
export const TOO_HIGH = 'Too high';
export const NORMAL = 'Good condition';

interface FieldType {
  id: string;
  title: string;
  type: 'TextValue' | 'SelectValue';
  selectValue?: string;
  textValue?: string;
}

interface RowFieldType {
  id: string;
  title: string;
  type: 'RowValue';
  value: FieldType[];
}

export type VitalsType = FieldType | RowFieldType;

export const getTemperatureLevel = (measurementType: string, value: string) => {
  const valueToNumber = Number(value);
  if (measurementType === 'Oral') {
    switch (true) {
      case valueToNumber >= 36.44444 && valueToNumber <= 37.55556:
        return NORMAL;
      case valueToNumber < 36.44444 && valueToNumber >= 35:
        return LOW;
      case valueToNumber < 35:
        return TOO_LOW;
      case valueToNumber > 37.55556 && valueToNumber <= 39.4444:
        return HIGH;
      case valueToNumber > 39.4444:
        return TOO_HIGH;

      default:
        break;
    }
  }
  if (measurementType === 'Rectal') {
    switch (true) {
      case valueToNumber >= 37 && valueToNumber <= 38.111111:
        return NORMAL;
      case valueToNumber < 37 && valueToNumber >= 36.1111:
        return LOW;
      case valueToNumber < 36.1111:
        return TOO_LOW;
      case valueToNumber > 38.111111 && valueToNumber <= 40:
        return HIGH;
      case valueToNumber > 40:
        return TOO_HIGH;

      default:
        break;
    }
  }
  return '';
};

export const calculateBMI = (weight: string, height: string) => {
  const weightToNumber = Number(weight);
  const heightToNumber = Number(height);

  const BMI = (weightToNumber / (heightToNumber * heightToNumber)).toFixed(2);
  return BMI;
};

export const getBMIlevel = (BMI: string) => {
  const BMIToNumber = Number(BMI);
  switch (true) {
    case BMIToNumber >= 18.5 && BMIToNumber <= 24.9:
      return NORMAL;
    case BMIToNumber < 18.5 && BMIToNumber >= 18:
      return LOW;
    case BMIToNumber < 18:
      return TOO_LOW;
    case BMIToNumber > 24.9 && BMIToNumber <= 29.9:
      return HIGH;
    case BMIToNumber > 29.9:
      return TOO_HIGH;

    default:
      break;
  }
};

export const getPulseLevel = (pulse: string) => {
  const pulseToNumber = Number(pulse);
  switch (true) {
    case pulseToNumber >= 60 && pulseToNumber <= 100:
      return NORMAL;
    case pulseToNumber < 60 && pulseToNumber >= 55:
      return LOW;
    case pulseToNumber < 55:
      return TOO_LOW;
    case pulseToNumber > 100 && pulseToNumber <= 120:
      return HIGH;
    case pulseToNumber > 120:
      return TOO_HIGH;

    default:
      break;
  }
  return '';
};

export const getBloodPressureLevel = (systolic: string, diastolic: string) => {
  const systolicToNumber = Number(systolic);
  const diastolicTonumber = Number(diastolic);

  switch (true) {
    case (systolicToNumber >= 90 && systolicToNumber <= 119) ||
      (diastolicTonumber >= 60 && diastolicTonumber <= 79):
      return NORMAL;
    case (systolicToNumber < 90 && systolicToNumber >= 85) ||
      (diastolicTonumber < 60 && diastolicTonumber >= 55):
      return LOW;
    case systolicToNumber < 85 || diastolicTonumber < 55:
      return TOO_LOW;
    case (systolicToNumber > 119 && systolicToNumber <= 129) ||
      (diastolicTonumber > 79 && diastolicTonumber <= 89):
      return HIGH;
    case systolicToNumber > 129 || diastolicTonumber > 89:
      return TOO_HIGH;

    default:
      break;
  }
  return '';
};

export const getRespiratoryRateLevel = (age: number, value: string) => {
  const valueToNumber = Number(value);

  if (age <= 12) {
    switch (true) {
      case valueToNumber >= 20 && valueToNumber <= 30:
        return NORMAL;
      case valueToNumber < 20 && valueToNumber >= 18:
        return LOW;
      case valueToNumber < 18:
        return TOO_LOW;
      case valueToNumber > 30 && valueToNumber < 40:
        return HIGH;
      case valueToNumber >= 40:
        return TOO_HIGH;

      default:
        break;
    }
  } else {
    switch (true) {
      case valueToNumber >= 12 && valueToNumber <= 18:
        return NORMAL;
      case valueToNumber < 12 && valueToNumber >= 10:
        return LOW;
      case valueToNumber < 10:
        return TOO_LOW;
      case valueToNumber > 18 && valueToNumber <= 20:
        return HIGH;
      case valueToNumber > 20:
        return TOO_HIGH;

      default:
        break;
    }
  }
};

type VitalType = {
  name: string;
  value?: string | number;
  level: string;
};

export const getVitals = (vitals: VitalsType[], patient?: PatientProps) => {
  const vitalsToObject = vitals.reduce((acc, vital) => {
    acc[vital.id] = vital;
    return acc;
  }, {} as Record<string, VitalsType>);

  const newVitals: VitalType[] = [];

  if (vitalsToObject.temperature) {
    const vital = vitalsToObject.temperature as RowFieldType;
    const measurementType = vital.value[0].selectValue as string;
    const value = vital.value[1].textValue as string;
    newVitals.push({
      name: vital.title,
      value,
      level: getTemperatureLevel(measurementType, value),
    });
  }

  if (vitalsToObject.blood_pressure) {
    const vital = vitalsToObject.blood_pressure as RowFieldType;
    const systolic = vital.value[0].textValue as string;
    const diastolic = vital.value[1].textValue as string;
    newVitals.push({
      name: vital.title,
      value: `${systolic}/${diastolic}`,
      level: getBloodPressureLevel(systolic, diastolic),
    });
  }

  if (vitalsToObject.pulse) {
    const vital = vitalsToObject.pulse as FieldType;
    const value = vital.textValue as string;
    newVitals.push({
      name: vital.title,
      value,
      level: getPulseLevel(value),
    });
  }

  // if (vitalsToObject.weight && vitalsToObject.height) {
  //   const weightVital = vitalsToObject.weight as FieldType;
  //   const heightVital = vitalsToObject.height as FieldType;
  //   const weight = weightVital.textValue as string;
  //   const height = heightVital.textValue as string;
  //   const BMI = calculateBMI(weight, height);
  //   newVitals.push({
  //     name: 'BMI',
  //     value: BMI,
  //     level: getBMIlevel(BMI) as string,
  //   });
  // }

  if (vitalsToObject.respiratory_rate) {
    const vital = vitalsToObject.respiratory_rate as FieldType;
    const value = vital.textValue as string;
    const patientAge = moment().diff(
      moment(patient?.basicInfo?.dateOfBirth),
      'years',
    );
    newVitals.push({
      name: vital.title,
      value,
      level: getRespiratoryRateLevel(patientAge, value) as string,
    });
  }

  return newVitals;
};
