import * as React from 'react';
import { MedicalSummary } from 'src/types';
import MedicalSummaryContext from './context';

const MedicalSummaryProvider: React.FC = ({ children }) => {
  const [summary, setSummary] = React.useState<MedicalSummary>({});

  return (
    <MedicalSummaryContext.Provider
      value={{
        summary,
        setSummary,
      }}
    >
      {children}
    </MedicalSummaryContext.Provider>
  );
};

export default MedicalSummaryProvider;
