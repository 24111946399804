import styled, { css } from 'styled-components';
import { Text, InputLabel, Column } from 'src/components';
import { theme } from 'src/utils/variables';

export const LabelText = styled(Text)`
  margin: 0rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  & > svg {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

export const InputLabelText = styled(InputLabel)`
  margin: 0rem;
  width: 100%;
  color: ${theme.grey[500]};
  font-size: 0.625rem;
  cursor: pointer;
`;

export const UploadIconLabel = styled(InputLabel)<{ required?: boolean }>`
  svg,
  path {
    stroke: ${theme.accent[500]};
  }
  width: 2.875rem;
  height: 2.875rem;
  display: flex;
  justify-content: center;
  border-radius: 0.3125rem;
  cursor: pointer;
  align-items: center;
  border: 1px solid ${theme.accent[500]};
  ${({ required }) => {
    return (
      required &&
      css`
        &::after {
          content: ' *';
          color: ${theme.red[500]};
          position: absolute;
          top: 0;
          left: 0;
        }
      `
    );
  }}
`;

export const ImagePreview = styled(Column)`
  position: relative;
  padding: 0.5rem;
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.grey[300]};
  background-color: ${theme.white[100]};
  & img {
    width: 100%;
  }
  & svg {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
  }
`;
