import Skeleton from 'react-loading-skeleton';
import { InvoiceImage } from 'src/assets/images';
import { Column, Row, SkeletonWrapper, Text } from 'src/components';
import useMediaQuery from 'src/utils/useMediaQuery';
import { theme } from 'src/utils/variables';
import { Hr } from './styled';

const Invoice = () => {
  const isMobile = !useMediaQuery('(min-width: 960px)');
  return (
    <Row
      height={isMobile ? 'calc(100vh - 20rem)' : '100%'}
      justify="center"
      gap={1}
      align="center"
    >
      <Column width="43%" gap={2.2} align="center">
        <InvoiceImage />
        <Text
          size={isMobile ? 'xs' : 'base'}
          weight="semibold"
          color={theme.grey[700]}
        >
          Patient has no invoice yet.
        </Text>
      </Column>
    </Row>
  );
};

export const InvoiceLoader: React.FC = () => {
  return (
    <Column style={{ paddingRight: '2rem' }} gap={1}>
      <SkeletonWrapper>
        <Skeleton height={40} />
      </SkeletonWrapper>
      <Hr />
      <SkeletonWrapper>
        <Column gap={0.5}>
          <Row width="100%" justify="space-between">
            <Skeleton width={200} height={40} />
            <Skeleton width={50} height={40} />
          </Row>
          <Row width="100%" justify="space-between">
            <Skeleton width={200} height={40} />
            <Skeleton width={50} height={40} />
          </Row>
          <Row width="100%" justify="space-between">
            <Skeleton width={200} height={40} />
            <Skeleton width={50} height={40} />
          </Row>
        </Column>
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton height={60} />
      </SkeletonWrapper>
    </Column>
  );
};

export default Invoice;
