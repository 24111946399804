import { useFormik } from 'formik';
import _ from 'lodash';
import {
  Avatar,
  Button,
  Column,
  Input,
  Paragraph,
  Row,
  Select,
  SkeletonColumn
} from 'src/components';
import { Modal } from 'src/containers';
import {
  useFacilities,
  useStaffGroups,
  useRowData,
  useStaffs,
} from 'src/state';
import { Staff } from 'src/types';
import { generateAvatar } from 'src/utils';
import { getModalParams, removeModalHash } from 'src/utils/modalHelper';
import Validator from 'validatorjs';
import { PlusIcon } from 'src/assets/icons';
import { useModalParams } from 'src/hooks';
import { FacilityProps } from '../InviteStaff';
import { ModalTitle, ProfileCard } from './styled';
import PermissionsModal from '../PermissionsModal';


const EditStaffModal: React.FC = () => {
  const id = getModalParams('id');
  const { setModalParams, params } = useModalParams()
  const { useApolloStaffGroups } = useStaffGroups();
  const {  staffGroups } = useApolloStaffGroups();
  const { useFacilities: useFacilitiesData } = useFacilities();
  const { initialFacilities, loading: facilitiesLoading } = useFacilitiesData();
  const { useUpdateStaff, useGetStaff } = useStaffs();
  const { updateStaff, loading } = useUpdateStaff();
  const { getDataByKey } = useRowData();

  const { staffGroup, permissions, user, facility } = (getDataByKey('staff') || {}) as unknown as Staff;
  const defaultpermissions = permissions?.map((permission) => permission.id);

  const { data, loading: staffLoading } = useGetStaff(id, !!user);
  const defaultUser = user || data?.getStaff.user;
  

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      staffGroup: staffGroup?.id || data?.getStaff.staffGroup.id,
      permissions: defaultpermissions || _.map(data?.getStaff.permissions, "id"),
      facility: facility?.id || data?.getStaff.facility.id,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(validationValues, {
        staffGroup: 'required',
        permissions: 'at_least_one_item',
        facility: 'required',
      });
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: async (value) => {
      const result = await updateStaff(id, value);
      if (result?.data) {
        removeModalHash();
      }
    },
  });

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const suggestions = staffGroups.getStaffGroups.staffGroups
      .find((r) => r.id === e.target.value)
      ?.suggestedPermissions.map((suggestion) => suggestion.id);
    setFieldValue(name, value);
    if (name === 'staffGroup') {
      setFieldValue('permissions', [...(suggestions as string[])]);
    }
  };

  const allStaffGroups = _.filter(staffGroups?.getStaffGroups?.staffGroups, ({ name}) => name.toLowerCase() !== "owner");

  return (
    <Modal>
      <Column>
        <ModalTitle>Edit Staff</ModalTitle>
        <Paragraph>You can change user staffGroups and permissions</Paragraph>
        <Column width="26.25rem" gap={2}>
          <ProfileCard align="center" gap={0.5}>
            <Avatar
              size={40}
              src={defaultUser?.profileAvatar || generateAvatar(defaultUser?.fullName)}
            />
            <Column width="max-width">
              <Paragraph weight="bold">{_.startCase(defaultUser?.fullName)}</Paragraph>
              <Paragraph size="xs">{defaultUser?.emails[0]}</Paragraph>
            </Column>
          </ProfileCard>
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            {staffLoading ? <SkeletonColumn count={4} height={81} width={420} /> : (
              <Column width="100%" gap={1}>
                <Select
                  label="Staff Groups"
                  name="staffGroup"
                  required
                  value={values.staffGroup}
                  error={touched.staffGroup ? errors.staffGroup : ''}
                  onChange={handleSelectChange}
                >
                  <option disabled value="">
                    Select a Staff Group
                  </option>
                  {allStaffGroups?.map((item: { id: string; name: string }, idx) => (
                    <option key={idx} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Input
                  label="Extra Permissions"
                  className='text-left'
                  required
                  data-testid="extraPermissions"
                  value={`${values.permissions.length > 0 ? values.permissions.length : 'No'} permissions selected`}
                  error={(touched?.permissions ? errors?.permissions : "") as string}
                  readOnly
                  type="button"
                  onClick={() => setModalParams({ "edit-permission": defaultUser?.fullName })}
                  icon={<PlusIcon className='fill-primary' />}
                />
                <Select
                  label="Select a facility to invite staff"
                  name="facility"
                  required
                  value={values.facility}
                  onChange={handleSelectChange}
                  loading={
                    facilitiesLoading &&
                    initialFacilities?.searchFacilities?.total === 0
                  }
                  error={errors.facility && 'Facility is required'}
                >
                  <option disabled value="">
                    {' '}
                    Select a Facility
                  </option>
                  {initialFacilities?.searchFacilities?.total > 0 &&
                    initialFacilities?.searchFacilities?.facilities?.map(
                      (fac: FacilityProps, idx) => (
                        <option key={idx} value={fac.id}>
                          {fac.state}
                        </option>
                      ),
                    )}
                </Select>
                <Row justify="space-between" gap={1.5}>
                  <Button isLoading={loading} width="max-content" disabled={loading} type="submit">
                    Save Changes
                  </Button>
                  <Button
                    width="max-content"
                    type="button"
                    background='neutral'
                    color="black"
                    onClick={() => removeModalHash()}
                  >
                    Cancel Changes
                  </Button>
                </Row>
                {params.get("edit-permission") === defaultUser?.fullName && (
                  <PermissionsModal
                    setModalParams={setModalParams}
                    modalParams={defaultUser?.fullName}
                    defaultPermissions={values.permissions}
                    setFieldValue={setFieldValue}
                    hash="#edit-staff"
                    name="permissions"
                  />
                )}
              </Column>
            )}
          </form>
        </Column>
      </Column>
    </Modal>
  );
};
export default EditStaffModal;
