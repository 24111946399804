export const measurementForms = [
  'Suspension',
  'Granules for oral solution',
  'Pressurised inhalation',
  'Emulsion for injection/infusion',
  'Lotion',
  'Intravenous infusion',
  'Ear/eye/nose drops',
  'Syrup',
  'Suspension/Drops',
  'Drops',
  'Cream',
  'Pessary',
  'Concentrate for solution for infusion',
  'Solution for injection/infusion',
  'Prolonged-release capsule',
  'Nebuliser liquid',
  'Granules',
  'Powder',
  'Film coated tablet',
  'Effervescent tablet',
  'Powder for solution',
  'Ear drops',
  'Nasal drops',
  'Orodispersible tablet',
  'Kit',
  'Injection',
  'Eye drops',
  'Vaginal capsule',
  'Mouthwash',
  'Inhalation powder',
  'Ear/eye drops',
  'Intrauterine delivery system',
  'Effervescent granules',
  'Spray, Metered-Dose',
  'Shampoo',
  'Solution for infusion',
  'Eye ointment',
  'Suppository',
  'Vaginal tablet',
  'Intrauterine device',
  'Solution/Drops',
  'Tablet',
  'Concentrate for solution for injection/infusion',
  'Liquid',
  'Oral liquid',
  'Dispersible tablet',
  'Powder for injection',
  'Dental gel',
  'Oral powder',
  'Aerosol, Metered-Dose',
  'Nasal spray',
  'Soluble tablet',
  'Dermal gel',
  'Powder for suspension',
  'Solution for injection (Inravitreal)',
  'Inhalation vapour',
  'Prolonged-release tablet',
  'Transdermal patch',
  'Capsule',
  'Powder for infusion',
  'Oral gel',
  'Solution for injection',
  'Lozenge',
  'Enema',
  'Gel',
  'Suspension for injection',
  'Powder for solution for injection/infusion',
  'Ointment',
  'Gargle',
  'Implant',
  'Powder for concentrate for solution for infusion',
  'Chewable/dispersible tablet',
  'Spray',
  'Solution',
  'Chewable tablet',
  'Caplet',
  'Granules for oral suspension',
  'Inhalation solution',
];
