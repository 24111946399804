import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_MEDICAL_SUMMARY } from 'src/constants/Queries/medicalSummary';
import { MedicalSummary } from 'src/types';
import { getVitals } from 'src/utils';
import { useRoute } from 'wouter';
import MedicalSummaryContext from './context';
import { usePatients, useStaff } from '..';

const useMedicalSummary = () => {
  const [, params] = useRoute('/admin/patients/:id');
  const { hasPermission } = useStaff();
  const patientId = params?.id as string;
  const { summary = {}, setSummary } = React.useContext(MedicalSummaryContext);
  const { currentPatient: patient } = usePatients();
  const { data, loading, error } = useQuery(GET_MEDICAL_SUMMARY, {
    variables: {
      patientId,
    },
    skip: summary[patientId] !== undefined || !hasPermission("VIEW_MEDICAL_SUMMARY"),
  });

  const [getMedicalSummary] = useLazyQuery(GET_MEDICAL_SUMMARY);

  useEffect(() => {
    if (data && data.getMedicalSummary && hasPermission("VIEW_MEDICAL_SUMMARY")) {
      setSummary({
        ...summary,
        [patientId]: {
          ...data.getMedicalSummary,
          bloodInfo: {
            'Blood Group': data.getMedicalSummary.bloodInfo.bloodType,
            Genotype: data.getMedicalSummary.bloodInfo.genoType,
          },
          vitals: data.getMedicalSummary.vitals
            ? getVitals(data.getMedicalSummary.vitals.data, patient)
            : [],
        },
      });
    }

    if (error) {
      // show toast
    }
  }, [data, patientId, setSummary, summary, error, patient, hasPermission]);

  const refreshMedicalSummary = async (refreshOptions?: {
    onCompleted: () => void;
  }) => {
    if (!hasPermission('VIEW_MEDICAL_SUMMARY')) return;
    await getMedicalSummary({
      variables: { patientId },
      onCompleted(dt) {
        setSummary({
          ...summary,
          [patientId]: {
            ...dt.getMedicalSummary,
            bloodInfo: {
              'Blood Group': dt.getMedicalSummary.bloodInfo.bloodType,
              Genotype: dt.getMedicalSummary.bloodInfo.genoType,
            },
            vitals: dt.getMedicalSummary.vitals
              ? getVitals(dt.getMedicalSummary.vitals.data, patient)
              : [],
          },
        });
        if (refreshOptions?.onCompleted) {
          refreshOptions.onCompleted();
        }
      },
    });
  };

  const updateSummary = (smry: MedicalSummary) => {
    setSummary({ ...smry });
  };

  return {
    loading,
    setSummary,
    updateSummary,
    refreshMedicalSummary,
    allPatientsSummary: summary,
    activePatientSummary: summary[patientId],
  };
};

export { useMedicalSummary };
