import { BinIcon, ChatIcon, EditIcon } from 'src/assets/icons';
import { Row, Column, Card, Avatar } from 'src/components';
import moment from 'moment';
import {
  theme,
  openModal,
  capitalizeFirstLetter,
  generateAvatar,
  generateTimeObject,
} from 'src/utils';
import { useAlert, useToast, useStaff } from 'src/state';
import { Todos } from 'src/types';
import {
  GET_TODOS,
  TODO_STATUS,
  DELETE_TODOS,
  UPDATE_TODO,
  PERMISSION_ACTION_MESSAGE,
} from 'src/constants';
import { useMutation } from '@apollo/client';
import {
  TodoCheckbox,
  TodoText,
  TodoActionButton,
  DueTimeIcon,
} from '../../styled';

interface TodoProps {
  todo: Todos;
  onClick: () => void;
  show: boolean;
}

export const TodoCards: React.FC<TodoProps> = ({ todo, onClick, show }) => {
  const { showToast } = useToast();
  const {  hasPermission  } = useStaff();
  const { showAlert, removeAlert } = useAlert();
  const [updateTodo, { loading }] = useMutation(UPDATE_TODO);
  const [deleteTodo] = useMutation(DELETE_TODOS);

  const handleChange = async () => {
    showAlert({
      title: 'Mark as Completed?',
      message: 'Are you sure you want to mark this todo as completed?',
      confirm: () => {
        updateTodo({
          variables: {
            input: {
              status: TODO_STATUS.COMPLETED,
            },
            todoId: todo.id,
          },
          refetchQueries: [GET_TODOS],
          onCompleted: (data) => {
            if (data) {
              showToast(data.updateTodo.message, 'success');
              removeAlert()
            }
          },
        });
      },
    });
  };

  const handleDelete = async () => {

    showAlert({
      title: 'Delete Todo ?',
      message: 'Are you sure you want to delete this todo ?',
      confirm: () => {
        deleteTodo({
          variables: {
            input: todo.id,
          },
          onCompleted: (dataD) => {
            if (dataD) {
              removeAlert()
              showToast(dataD.deleteTodo.message, 'success');
            }
          },
          refetchQueries: [GET_TODOS],
        });
      },
    });
  };

  const timeObject =
    todo.dueDate &&
    moment(generateTimeObject(todo.dueDate, todo.dueTime)).format('YYYY-MM-DD');

  const isPastDueDate = moment(timeObject).isBefore(
    moment().format('YYYY-MM-DD'),
  );

  return (
    <Card
      width="100%"
      data-testid={!show ? "todo-cards" : `todo-cards-visible`}
      onClick={() => {
        if (!hasPermission('VIEW_TODOS_DETAILS')) {
          return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
        }
        openModal('todo-info', todo.id);
        onClick();
      }}
    >
      <Column gap={2}>
        <Column width="100%" gap={1.5}>
          <Row width="100%" gap={1.5} justify="space-between">
            <TodoText weight="semibold" size="sm" color={theme.black[100]}>
              {capitalizeFirstLetter(todo.title)}
            </TodoText>
            <TodoCheckbox
              disabled={loading}
              onChange={handleChange}
              defaultValue={todo.status}
              checked={todo.status === TODO_STATUS.COMPLETED}
              onClick={(e) => e.stopPropagation()}
            />
          </Row>
          <Row width="100%" gap={3}>
            <Column gap={1} width="max-content">
              <Column width="max-content">
                <TodoText size="xxs" color={theme.grey[700]}>
                  Assigned to
                </TodoText>
                <Row align="center" gap={0.25}>
                  <Avatar
                    src={
                      todo.assignee.user.profileAvatar ||
                      generateAvatar(
                        `${todo.assignee.user.firstName} ${todo.assignee.user.lastName}`,
                        8,
                      )
                    }
                    size={15}
                  />
                  <TodoText weight="semibold" size="sm" color={theme.grey[800]}>
                    {`${
                      todo.assignee.staffGroup.name === 'Doctor'
                        ? 'Dr'
                        : `(${todo.assignee.staffGroup.name})`
                    } ${todo.assignee.user.firstName || `Graye`}`}
                  </TodoText>
                </Row>
              </Column>
              <Column width="max-content">
                <TodoText size="xxs" color={theme.grey[700]}>
                  Requester
                </TodoText>
                <Row align="center" gap={0.25}>
                  <Avatar
                    src={
                      todo.creator.user.profileAvatar ||
                      generateAvatar(
                        `${todo.creator.user.firstName} ${todo.creator.user.lastName}`,
                        8,
                      )
                    }
                    size={15}
                  />
                  <TodoText weight="semibold" size="sm" color={theme.grey[800]}>
                    {`${
                      todo.creator.staffGroup.name === 'Doctor'
                        ? 'Dr'
                        : `(${todo.creator.staffGroup.name})`
                    } ${todo.creator.user.firstName || `Graye`}`}
                  </TodoText>
                </Row>
              </Column>
            </Column>

            <Column width="max-content">
              <TodoText size="xxs" color={theme.grey[700]}>
                Attached patient
              </TodoText>
              <Row align="center" gap={0.25}>
                <Avatar
                  src={generateAvatar(todo.patient.user.fullName, 8)}
                  size={15}
                />
                <TodoText weight="semibold" size="sm" color={theme.grey[800]}>
                  {todo.patient.user.fullName || 'Igwe Patrick'}
                </TodoText>
              </Row>
            </Column>
          </Row>
        </Column>
        <Row align="center" justify="space-between">
          <Row align="center" gap={0.35}>
            {todo.dueDate && (
              <DueTimeIcon
                color={isPastDueDate ? theme.accent[600] : theme.red[400]}
              />
            )}
            <TodoText
              size="xs"
              color={isPastDueDate ? theme.accent[600] : theme.red[400]}
            >
              {todo.dueDate &&
                `Due by ${moment(
                  generateTimeObject(todo.dueDate, todo.dueTime),
                ).calendar()}`}
            </TodoText>
          </Row>
          <Row width="max-content" gap={0.75}>
            {hasPermission("VIEW_TODOS_DETAILS") && (
              <TodoActionButton
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('todo-info', todo.id);
                  onClick();
                }}
              >
                <ChatIcon />
              </TodoActionButton>
            )}
            
            {hasPermission("EDIT_TODOS") && (
              <TodoActionButton
                data-testid={!show ? "todo-edit" : `todo-edit-visible`}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('update-todo', todo.id);
                  onClick();
                }}
              >
                <EditIcon />
              </TodoActionButton>
            )}
            
            {hasPermission("DELETE_TODOS") && (
              <TodoActionButton
                data-testid={!show ? "todo-delete" : `todo-delete-visible`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
              >
                <BinIcon />
              </TodoActionButton>
            )}
          </Row>
        </Row>
      </Column>
    </Card>
  );
};
