import React from 'react';
import { getLocalStorage, setLocalStorage } from 'src/utils';
import FeatureFlagContext, { Features } from './context';
import featureFlags from '../../constants/featureFlags.json';

const writeToStorage = (key: string, value: boolean) => {
  let ft = getLocalStorage('features');
  ft = {
    ...ft,
    features: {
      ...ft.features,
      [key]: {
        enabled: value,
      },
    },
  };
  setLocalStorage('features', ft);
  return ft;
};

const FeatureFlagProvider: React.FC = ({ children }) => {
  const [feats, setFeats] = React.useState<Features>();

  const ft = getLocalStorage('features');
  React.useEffect(() => {
    if (!ft) {
      setLocalStorage('features', featureFlags);
      window.location.reload();
    }
    if (
      ft &&
      (Object.keys(ft.features).length !==
        Object.keys(featureFlags.features).length ||
        ft.version !== featureFlags.version)
    ) {
      setLocalStorage('features', featureFlags);
      window.location.reload();
    }
    if (!feats && ft) {
      setFeats(ft.features);
    }
  }, [ft, feats]);

  const getFeature = (feat: string) => {
    if (feats) {
      return feats[feat];
    }
  };

  const setFeature = (key: string, value: boolean) => {
    const data = writeToStorage(key, value);
    if (data) {
      setFeats({ ...data.features });
    }
  };

  return (
    <FeatureFlagContext.Provider value={{ feats, getFeature, setFeature }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
