import React, { useContext } from 'react';
import { Column, Row } from 'src/components';
import { Form, Formik } from 'formik';
import { TabContent, TabContentItem, TabContext } from 'src/containers';
import { useStepTemplates } from 'src/state';
import { getModalParams } from 'src/utils';
import { CreateStepTemplate, StepTemplate } from 'src/types';
import { SubmitButton } from 'src/modals/AddPatient/styled';
import _ from 'lodash';
import StepDetails from '../StepDetails';
import StepParameters from '../StepParmeters';
import ActionItems from '../ActionItems';
import Summary from '../Summary';
import { FormProps } from '../index';

type StepProps = Omit<CreateStepTemplate, 'facility'>;

interface TabItems {
  completed: FormProps;
  setData: (value: StepTemplate) => void;
  initialValues: StepProps;
  setCompleted: (value: FormProps) => void;
  checkValidity: (values: Record<string, unknown>) => void;
}

export const TabItems: React.FC<TabItems> = ({
  setData,
  completed,
  setCompleted,
  initialValues,
  checkValidity,
}) => {
  const id = getModalParams('id') as string;
  const { activeTab, setActiveTab } = useContext(TabContext);
  const { useCreateStepTemplate, useUpdateStepTemplate, currentStepTemplate } = useStepTemplates();
  const { loading, createStepTemplate } = useCreateStepTemplate();
  const { updateStepTemplate } = useUpdateStepTemplate();

  const tabs = [
    <StepDetails key={1} />,
    <StepParameters key={2} />,
    <ActionItems key={3} />,
    <Summary key={4} />,
  ];

  const updateCompleted = (key: keyof FormProps) => {
    setCompleted({ ...completed, [key]: 'active' });
  };

  const handleSubmit = async (values: StepProps) => {
    const { name, description, assigned, parameters, actions } =
      values as unknown as StepProps;

    let res;
    const stepDetailKeys = ['name', 'description', 'assigned'];
    const mapParameters = _.map(
      parameters,
      _.partialRight(_.omit, '__typename'),
    );
    const mapActions = _.map(actions, _.partialRight(_.omit, '__typename'));

    switch (activeTab) {
      case 'add-step-0':
        updateCompleted('stepDetails');
        setActiveTab(`add-step-1`);
        if (
          id &&
          !_.isEqual(
            _.pick(values, stepDetailKeys),
            _.pick(initialValues, stepDetailKeys),
          )
        ) {
          res = await updateStepTemplate(id, {
            name,
            description,
            assigned,
          });
        }
        break;

      case 'add-step-1':
        updateCompleted('stepParameters');
        setActiveTab(`add-step-2`);
        if (
          id &&
          !_.isEqual(parameters, initialValues.parameters) &&
          mapParameters.length > 0
        ) {
          res = await updateStepTemplate(id, {
            parameters: mapParameters,
          });
        }
        break;

      case 'add-step-2':
        updateCompleted('actionItem');
        setActiveTab(`add-step-3`);
        if (
          id &&
          !_.isEqual(actions, initialValues.actions) &&
          mapActions.length > 0
        ) {
          res = await updateStepTemplate(id, {
            actions: mapActions,
          });
          if (res?.data) {
            setData(res?.data.updateStepTemplate.stepTemplate);
          }
        }
        break;

      case 'add-step-3':
        updateCompleted('summary');
        if (id) {
          setData(currentStepTemplate);
        }
        if (!id) {
          res = await createStepTemplate({
            name,
            assigned,
            description,
            parameters: parameters[0].type !== '' ? parameters : [],
            actions: actions[0].tag !== '' ? actions : [],
          });
          if (res?.data) {
            setData(res?.data.createStepTemplate.stepTemplate);
          }
        }
        break;

      default:
        break;
    }
  };

  let buttonText: string;
  switch (activeTab) {
    case 'add-step-3':
      buttonText = id ? 'Done' : 'Finish';
      break;
    case 'add-step-2':
      buttonText = id ? 'Save' : 'Next';
      break;
    default:
      buttonText = id ? 'Save' : 'Next';
  }

  return (
    <TabContent>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validate={(values) => checkValidity(values)}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          {tabs.map((tab, idx) => {
            return (
              <TabContentItem key={idx} id={`add-step-${idx}`}>
                <Column gap={2.5}>
                  {tab}
                  <Row gap={1} justify={idx === 0 ? "flex-end" : "space-between"}>
                    <SubmitButton
                      onClick={() => setActiveTab(`add-step-${idx - 1}`)}
                      visibility={idx === 0 ? 'hidden' : 'visible'}
                      background="neutral"
                      color="black"
                      type="button"
                      width="max-content"
                      data-testid="prev-progress"
                    >
                      Back
                    </SubmitButton>
                    <SubmitButton
                      width="max-content"
                      type="submit"
                      disabled={loading}
                      isLoading={loading}
                      data-testid="next-progress"
                    >
                      {buttonText}
                    </SubmitButton>
                  </Row>
                </Column>
              </TabContentItem>
            );
          })}
        </Form>
      </Formik>
    </TabContent>
  );
};
