import { Column, Row, Text } from "src/components"
import { FamilyDetailsProps } from "../types"

export const FamilyDetails: React.FC<FamilyDetailsProps> = ({ title, children, width = "100%" }) => {
  return (
    <Column width={width}>
      <Text modStyles={{ my: 0}} size="sm" color="#282828">{title}</Text>
      <Row align="center" height="2.1875rem" gap={2}>{children}</Row>
    </Column>
  )
}