import { useState, useEffect } from 'react';
import {
  Column,
  Input,
  H6,
  Row,
  Avatar,
  Paragraph
} from 'src/components';
import { useAlert, useRowData, useStaff, useToast } from 'src/state';
import { theme } from 'src/utils/variables';
import {
  removeModalHash,
  generateAvatar,
  generateTimeObject,
  openModal,
  getModalParams,
} from 'src/utils';
import { useMutation } from '@apollo/client';
import {
  BinIcon,
  // ChatIcon,
  EditIcon,
  SendIcon,
} from 'src/assets/icons';
import { ADD_TODO_NOTES, DELETE_TODOS, GET_TODOS } from 'src/constants';
import _ from 'lodash';
import moment from 'moment';
import { NotesEntity, Todo, Todos } from 'src/types';
import {
  TodoInfoModal,
  TodoInfoText,
  DueTimeIcon,
  TodoActionButton,
  InputContainer,
  OverflowContainer,
  NotesSubmitButton,
} from './styled';

interface FormDataProps {
  notes: NotesEntity[];
  comments: string;
}

const TodoInfo = () => {
  const { hasPermission } = useStaff();
  const { showToast } = useToast();
  const { showAlert } = useAlert();
  const id = getModalParams('id');
  const { updateRowData, getDataByKey } = useRowData();
  const todo = getDataByKey('todo') as unknown as Todos;
  const { title, patient, assignee, dueDate, dueTime, notes, creator } = todo || {} as Todo;

  const [formData, setFormData] = useState<FormDataProps>({
    notes: notes as NotesEntity[],
    comments: '',
  });

  const [addTodoNotes, { error }] = useMutation(ADD_TODO_NOTES);
  const [deleteTodo] = useMutation(DELETE_TODOS);

  const handleDelete = async () => {
    showAlert({
      type: 'decision',
      title: 'Delete Todo ?',
      message: 'Are you sure you want to delete this todo ?',
      confirm: () => {
        deleteTodo({
          variables: {
            input: id,
          },
          onCompleted: (dataD) => {
            showToast(dataD.deleteTodo.message, 'success');
            removeModalHash();
          },
          refetchQueries: [GET_TODOS],
        });
      },
    });
  };

  useEffect(() => {
    if (error) {
      showToast(error.message, 'error');
    }
  }, [showToast, error]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const timeObject =
    dueDate &&
    moment(generateTimeObject(dueDate, dueTime)).format('YYYY-MM-DD');

  const isPastDueDate = moment(timeObject).isBefore(
    moment().format('YYYY-MM-DD'),
  );

  const handleSubmit = async () => {
    if (!formData.comments) return null;
    await addTodoNotes({
      variables: {
        input: {
          ..._.pick(formData, ['comments']),
          todoId: id,
        },
      },
      onCompleted: (data) => {
        showToast(data.addTodoNotes.message, 'success');
        setFormData({
          ...formData,
          comments: '',
          notes: data?.addTodoNotes?.todo.notes,
        });
      },
      refetchQueries: [GET_TODOS],
    });
  };

  if (_.isEmpty(todo)) {
    removeModalHash();
    return <></>;
  }

  return (
    <TodoInfoModal>
      <Column gap={1.25}>
        <Row align="center" justify="space-between">
          <Row align="center" gap={0.35}>
            {dueDate && (
              <DueTimeIcon
                color={isPastDueDate ? theme.accent[600] : theme.red[400]}
              />
            )}
            <TodoInfoText
              size="xs"
              color={
                dueDate && isPastDueDate ? theme.accent[600] : theme.red[400]
              }
            >
              {dueDate &&
                `Due by ${moment(
                  generateTimeObject(dueDate, dueTime),
                ).calendar()}`}
            </TodoInfoText>
          </Row>
          <Row width="max-content" gap={0.75}>
            {/* <TodoActionButton>
              <ChatIcon />
            </TodoActionButton> */}
            {hasPermission("EDIT_TODOS") && (
              <TodoActionButton
                onClick={() => {
                  updateRowData('todo', getDataByKey('todo'));
                  openModal('update-todo', id);
                }}
              >
                <EditIcon />
              </TodoActionButton>
            )}
            {hasPermission("DELETE_TODOS") && (
              <TodoActionButton onClick={handleDelete}>
                <BinIcon />
              </TodoActionButton>
            )}
          </Row>
        </Row>
        <H6 weight="bold">{title}</H6>
        <Column gap={1.5}>
          <Row width="100%" gap={3.25}>
            <Column gap={1} width="max-content">
              <Column gap={0.5} width="max-content">
                <TodoInfoText size="sm" color={theme.grey[700]}>
                  Assigned to
                </TodoInfoText>
                <Row align="center" gap={0.25}>
                  <Avatar
                    src={generateAvatar(
                      `${assignee.user.firstName} ${assignee.user.lastName}`,
                      10,
                    )}
                    size={20}
                  />
                  <TodoInfoText weight="semibold" color={theme.grey[800]}>
                    {`${
                      assignee.staffGroup.name === 'Doctor'
                        ? assignee.staffGroup.name
                        : `(${assignee.staffGroup.name})`
                    } 
                  ${assignee.user.firstName}`}
                  </TodoInfoText>
                </Row>
              </Column>
              <Column gap={0.5} width="max-content">
                <TodoInfoText size="sm" color={theme.grey[700]}>
                  Requester
                </TodoInfoText>
                <Row align="center" gap={0.25}>
                  <Avatar
                    src={generateAvatar(
                      `${creator.user.firstName} ${creator.user.lastName}`,
                      10,
                    )}
                    size={20}
                  />
                  <TodoInfoText weight="semibold" color={theme.grey[800]}>
                    {`${
                      creator.staffGroup.name === 'Doctor'
                        ? creator.staffGroup.name
                        : `(${creator.staffGroup.name})`
                    } 
                  ${creator.user.firstName}`}
                  </TodoInfoText>
                </Row>
              </Column>
            </Column>

            <Column gap={0.5} width="max-content">
              <TodoInfoText size="sm" color={theme.grey[700]}>
                Attached patient
              </TodoInfoText>
              <Row align="center" gap={0.25}>
                <Avatar
                  src={generateAvatar(patient?.user.fullName, 10)}
                  size={20}
                />
                <TodoInfoText weight="semibold" color={theme.grey[800]}>
                  {patient?.user.fullName}
                </TodoInfoText>
              </Row>
            </Column>
          </Row>
          <Column width="26.8rem">
            {formData.notes.length > 0 && (
              <TodoInfoText size="sm" color={theme.grey[700]}>
                Notes
              </TodoInfoText>
            )}
            <OverflowContainer>
              {formData.notes.length > 0 &&
                formData.notes.map((note) => (
                  <Paragraph key={note?.id}>{note?.comment}</Paragraph>
                ))}
            </OverflowContainer>
          </Column>
        </Column>
        <InputContainer align="center">
          <Input
            onChange={handleChange}
            name="comments"
            placeholder="Drop a comment"
            value={formData.comments}
          />
          <NotesSubmitButton
            onClick={handleSubmit}
            isFilled={formData.comments.length > 0}
          >
            <SendIcon />
          </NotesSubmitButton>
        </InputContainer>
      </Column>
    </TodoInfoModal>
  );
};

export default TodoInfo;
