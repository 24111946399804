import { gql } from '@apollo/client';
import { patientFieldsFragment } from '../Fragments/patient';

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($patientId: ID!, $input: UpdatePatientInput!) {
    updatePatient(patientId: $patientId, input: $input) {
      message
      success
      patient {
        ...PatientFields
      }
    }
  }
  ${patientFieldsFragment}
`;


export const ADD_NEW_PATIENT = gql`
  mutation AddPatients($input: AddPatientsInput!) {
    addPatients(input: $input) {
      success
      message
      patient {
        ...PatientFields
      }
    }
  }
  ${patientFieldsFragment}
`;

export const GRANT_ACCESS_TO_PATIENT = gql`
  mutation GrantFacilityAccessToPatientData($input: GrantAccessToPatientInput) {
    grantFacilityAccessToPatientData(input: $input) {
      success
      message
      patient {
        ...PatientFields
      }
    }
  }
  ${patientFieldsFragment}
`;