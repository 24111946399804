import moment from "moment"

export const ageCalculator = (birthdate: string) => {
  const year = moment().diff(birthdate, 'years');
	const month = moment().diff(birthdate, 'months');
	const week = moment().diff(birthdate, 'weeks');
	const day = moment().diff(birthdate, 'days');
	const hour = moment().diff(birthdate, 'hours');
	if (hour < 24) {
		return hour > 1 ? `${hour} hours old` : `an hour old`;
	}
	if (day < 7) {
		return day > 1 ? `${day} days old` : `a day old`;
	}
	if (week < 4) {
		return week > 1 ? `${week} weeks old` : `a week old`;
	}
	if (month < 12) {
		return month > 1 ? `${month} months old` : `a month old`;
	}
	return year > 1 ? `${year} years old` : `a year old`;
}