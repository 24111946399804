import { gql } from '@apollo/client';

export const ADD_RECORD = gql`
  mutation AddRecord($input: AddRecordInput) {
    addRecord(input: $input) {
      record {
        id: _id
      }
      message
      success
    }
  }
`;

export const ADD_RECORD_TEMPLATE = gql`
  mutation AddRecordTemplate($input: AddRecordTemplateInput) {
    addRecordTemplate(input: $input) {
      message
      success
    }
  }
`;


export const PIN_RECORD_TEMPLATE = gql`
  mutation PinRecordTemplate($input: ID!) {
    pinRecordTemplate(input: $input) {
      success
      message
    }
  }
`;

export const UNPIN_RECORD_TEMPLATE = gql`
  mutation UnPinRecordTemplate($input: ID!) {
    unPinRecordTemplate(input: $input) {
      success
      message
    }
  }
`;