import { useEffect, useState } from 'react';

interface UseSearchParams {
  setModalParams: (newParams: Record<string, string | undefined>) => void;
  params: URLSearchParams;
}

export const useSearchParams = (): UseSearchParams => {
  const searchValue = window.location.search;
  const [params, setParams] = useState<URLSearchParams>(new URLSearchParams(searchValue));

  const updateQueryParams = (newParams: Record<string, string | undefined>) => {
    const searchParams = new URLSearchParams(searchValue);
    Object.entries(newParams).forEach(([key, value]) => 
      value === undefined ? 
        searchParams.delete(key) : 
        searchParams.set(key, value)
    );
    window.history.pushState(null, '', `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    setParams(new URLSearchParams(searchValue))
  }, [searchValue])

  return { params, setModalParams: updateQueryParams };
};


