import {
  ModalChildContainer,
  ModalCard,
  ModalCloseButton,
} from 'src/containers/Modal/styled';
import styled from 'styled-components';

export const NotesTempModal = styled(ModalCard)`
  max-width: 54rem;
  & > div:first-child {
    min-width: 50rem;
    & > div:first-child {
      button {
        width: 12rem;
        height: 3.375rem;
      }
      img {
        display: none;
      }
    }
  }
  ${ModalCloseButton} {
    justify-self: flex-end;
  }
  ${ModalChildContainer} {
    padding: 0;
    padding-right: 0.5rem;
    & > div {
      gap: 2rem;
      & > form {
        & > div {
          gap: 2.5rem;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 1.5rem;
  }
`;
