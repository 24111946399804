import { useCallback, useEffect, useState } from 'react';
import { PlusIcon } from 'src/assets/icons';
import {
  Row,
  Column,
  ErrorComponent,
  PageLoader,
  SearchInput,
  Text,
} from 'src/components';
import { openModal } from 'src/utils';
import { useRowData, useToast, useStaff } from 'src/state';
import { Todos } from 'src/types';
import {
  GET_TODOS,
  SEARCH_TODOS,
  TODO_STATUS,
} from 'src/constants';
import { useQuery, useLazyQuery } from '@apollo/client';
import useSearch from 'src/hooks/useSearch';
import _ from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { NoTodos } from 'src/assets/images';
import {
  AddTodoButton,
  PaddingContainer,
  VisibilityContainer,
} from '../styled';
import { TodoCards } from './TodoCard';

export const TodoTab = () => {
  const [tData, setTData] = useState<Array<Todos>>([] as unknown as Todos[]);
  const { staff,  hasPermission  } = useStaff();
  const urlParams = new URLSearchParams(window.location.search);
  const facility = urlParams.get('facility') || staff.getStaff.facility.id;
  const { updateRowData } = useRowData();
  const { showToast } = useToast();
  const {
    data: todoData,
    loading,
    error,
  } = useQuery(GET_TODOS, {
    skip: !hasPermission("VIEW_TODOS_LIST"),
    variables: {
      input: { facility },
    },
    errorPolicy: 'all',
  });

  const { handleSearchChange, searchData, searchLoading } =
    useSearch(SEARCH_TODOS);

  const data = searchData?.searchTodos || todoData?.getTodos;
  const currPage = Math.ceil(data?.todos.length / 10) || 0;
  const hasNextPage = data?.total > tData.length;

  const [lazyTodo, { error: lazyError, loading: lazyLoading }] =
    useLazyQuery(GET_TODOS);

  const handleLoadMore = useCallback(() => {
    if (hasNextPage) {
      return lazyTodo({
        variables: {
          input: {
            facility,
            page: currPage + 1,
          },
        },
        onCompleted: (dataD) => {
          setTData([
            ...(data?.todos as Todos[]),
            ...(dataD?.getTodos.todos as Todos[]),
          ]);
        },
      });
    }
    return tData;
  }, [lazyTodo, currPage, facility, hasNextPage, data, tData]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    if (data) {
      setTData([...(data?.todos as Todos[])]);
    }
  }, [data, setTData]);

  useEffect(() => {
    if (lazyError) {
      showToast(lazyError.message, 'error');
    }
  }, [lazyError, showToast]);

  if (error) {
    return <ErrorComponent />;
  }

  const unCompletedTodos = _.filter(
    data?.todos,
    (v) => (v as unknown as Todos).status !== TODO_STATUS.COMPLETED,
  );

  return (
    <Column gap={1} align="center" width="100%">
      <Row gap={0.5} align="center" width="100%">
        <SearchInput placeholder="Search Todos" onChange={handleSearchChange} />
        {hasPermission("CREATE_TODOS") && (
          <AddTodoButton
            size="0.5"
            data-testid="todo-button"
            onClick={() => openModal('create-todo')}
          >
            <PlusIcon />
          </AddTodoButton>
        )}
        
      </Row>

      {(loading || searchLoading) && <PageLoader />}
      <PaddingContainer width="100%" ref={rootRef} gap={1.25} align="stretch">
        {!(loading || searchLoading) &&
          data?.todos.length > 0 &&
          tData.map((todo: Todos, idx: number) => (
            <VisibilityContainer
              key={idx}
              ref={sentryRef}
              show={todo.status !== TODO_STATUS.COMPLETED}
            >
              <TodoCards 
                show={todo.status !== TODO_STATUS.COMPLETED}
                onClick={() => updateRowData('todo', todo as unknown as Record<string, unknown>)}
                todo={todo} 
              />
            </VisibilityContainer>
          ))}
        {lazyLoading && <Text align="center">Loading...</Text>}
        {!(loading || searchLoading) &&
          (data?.todos.length === 0 || unCompletedTodos.length === 0) && (
            <Column align="center" height="100%" justify="center">
              <NoTodos />
            </Column>
          )}
      </PaddingContainer>
    </Column>
  );
};
