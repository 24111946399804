import React from 'react';
import { OperationVariables } from '@apollo/client';
import RowDataContext from './context';


const useRowData = () => {
  const { rowData, setRowData } = React.useContext(RowDataContext);

  const updateRowData = (key: string, updater: OperationVariables) => {
    setRowData((prevData) => ({
      ...prevData,
      [key]: updater,
    }));
  };

  
  const getDataByKey = (key: string) => {
    return rowData?.[key]
  }

  return { rowData, setRowData, updateRowData, getDataByKey }
};

export { useRowData };
