import React from 'react';
import { Row, Text, Avatar, AvatarGroup } from 'src/components';
import { Staff } from 'src/types';
import { generateAvatar, theme } from 'src/utils';
import useClickAway from 'src/utils/useClickAway';
import moment from 'moment';
import { AvatarGroupContainer, StaffList, StaffListItem } from './styled';

const getAvatar = (staff: Staff) =>
  staff?.user?.profileAvatar ||
  generateAvatar(`${staff?.user?.firstName} ${staff?.user?.lastName}`);

const StaffAvatarGroup: React.FC<{
  events: { staff: Staff; updatedAt: string }[];
}> = ({ events }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);

  const avatars = events.map((event) => getAvatar(event.staff));

  return (
    <AvatarGroupContainer
      ref={ref}
      gap={1}
      align="flex-start"
      width="max-content"
      onMouseEnter={() => setIsComponentVisible(true)}
      onClick={() => setIsComponentVisible(true)}
    >
      <AvatarGroup width="max-content">
        {avatars.map((url, index) => (
          <Avatar key={index} src={url} size={40} />
        ))}
      </AvatarGroup>
      {isComponentVisible && (
        <StaffList onMouseLeave={() => setIsComponentVisible(false)}>
          {events.map(({ staff, updatedAt }, idx) => (
            <StaffListItem key={idx}>
              <Row align="center" width="unset">
                <Avatar src={getAvatar(staff)} size={40} />{' '}
                <Text color={theme.grey[900]} modStyles={{ ml: 0.5 }}>
                  {`${staff?.user?.firstName} ${staff?.user?.lastName}`}
                </Text>
              </Row>
              <Text weight="semibold" size="sm" color={theme.grey[650]}>
                {moment(updatedAt).format('HH:mma')}
              </Text>
            </StaffListItem>
          ))}
        </StaffList>
      )}
    </AvatarGroupContainer>
  );
};

export default StaffAvatarGroup;
