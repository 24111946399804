import {
  ADD_APPOINTMENT,
  CANCEL_APPOINTMENT,
  CHECK_IN_APPOINTMENT,
  CHECK_OUT_APPOINTMENT,
  GET_APPOINTMENTS,
  PERMISSION_ACTION_MESSAGE,
  UPDATE_APPOINTMENT,
} from 'src/constants';
import { OperationVariables, useMutation } from '@apollo/client';
import { 
  AddAppointmentProps, 
  CancelAppointmentProps, 
  UpdateAppointmentProps, 
  checkInAppointmentProps, 
  checkOutAppointmentProps 
} from 'src/types';
import { useToast } from '../toast';
import { useStaff } from '../staff/hook';
import { useRowData } from '..';


const useMutations = () => {
  const { showToast } = useToast();
  const {  hasPermission  } = useStaff();
  const { updateRowData } = useRowData();


  const useAddAppointment = () => {
    const [execute, result] = useMutation<AddAppointmentProps>(ADD_APPOINTMENT);

    const addAppointment = (value?: OperationVariables) => {

      if (!hasPermission("CREATE_APPOINTMENT")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning'); 
      return execute({
        variables: { input: value },
        refetchQueries: [GET_APPOINTMENTS],
        awaitRefetchQueries: true,
        onCompleted: (data) => showToast(data.addAppointment.message, 'success'),
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, addAppointment };
  };


  const useUpdateAppointment = () => {
    const [execute, result] = useMutation<UpdateAppointmentProps>(UPDATE_APPOINTMENT);

    const updateAppointment = (value?: OperationVariables) => {
      if (!hasPermission("EDIT_APPOINTMENT")) return;
      return execute({
        variables: value,
        refetchQueries: [GET_APPOINTMENTS],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
          showToast(data.updateAppointment.message, 'success')
          updateRowData('appointment', data.updateAppointment.appointment)
        },
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, updateAppointment };
  };


  const useCancelAppointment = () => {
    const [execute, result] = useMutation<CancelAppointmentProps>(CANCEL_APPOINTMENT);

    const cancelAppointment = (value?: OperationVariables) => {
      if (!hasPermission("CANCEL_APPOINTMENT")) return;
      return execute({
        variables: value,
        refetchQueries: [GET_APPOINTMENTS],
        awaitRefetchQueries: true,
        onCompleted: (data) => showToast(data.cancelAppointment.message, 'success'),
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, cancelAppointment };
  };


  const useCheckInAppointment = () => {
    const [execute, result] = useMutation<checkInAppointmentProps>(CHECK_IN_APPOINTMENT);

    const checkInAppointment = (value?: OperationVariables) => {
      if (!hasPermission("CHECK_IN_APPOINTMENT")) return;
      return execute({
        variables: value,
        refetchQueries: [GET_APPOINTMENTS],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
          showToast(data.checkInAppointment.message, 'success')
          updateRowData('appointment', data.checkInAppointment.appointment)
        },
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, checkInAppointment };
  };


  const useCheckOutAppointment = () => {
    const [execute, result] = useMutation<checkOutAppointmentProps>(CHECK_OUT_APPOINTMENT);

    const checkOutAppointment = (value?: OperationVariables) => {
      if (!hasPermission("CHECK_OUT_APPOINTMENT")) return;
      return execute({
        variables: value,
        refetchQueries: [GET_APPOINTMENTS],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
          showToast(data.checkOutAppointment.message, 'success')
          updateRowData('appointment', data.checkOutAppointment.appointment)
        },
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, checkOutAppointment };
  };


  return {
    useAddAppointment,
    useUpdateAppointment,
    useCancelAppointment,
    useCheckInAppointment,
    useCheckOutAppointment
  };
}

export default useMutations;