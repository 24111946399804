import { useContext, useEffect, useState } from 'react';
import { GET_STAFF, GET_STAFFS, SEARCH_STAFF } from 'src/constants';
import { OperationVariables, useQuery } from '@apollo/client';
import _ from 'lodash';
import { GetStaffsProps, SearchStaffsProps, Staff, StaffProps, Staffs } from 'src/types';
import { useApolloSearch, useDebounceCallback } from 'src/hooks';
import { useStaff } from '../staff/hook';
import StaffsContext from './context';
import { useStaffGroups } from '../staff-groups';

const useQueries = () => {
  const {
    initialStaffs,
    setInitialStaffs,
    // staffGroups,
    // setStaffGroups,
    owner,
    doctors,
    setDoctors,
    setOwner,
    activeDoctors,
    setActiveDoctors,
    activeNurses,
    nurses,
    setActiveNurses,
    setNurses,
  } = useContext(StaffsContext);

  const { useApolloStaffGroups } = useStaffGroups();
  const [defaultStaff, setDefaultStaff] = useState<Staffs>({} as unknown as Staffs);
  const { staffGroups } = useApolloStaffGroups();
  const { staff, hasPermission } = useStaff();
  const defaultFacility = staff.getStaff.facility.id;
  const doctor =
    staffGroups &&
    staffGroups?.getStaffGroups?.staffGroups?.find(
      (v) => v.name.toLowerCase() === 'doctor' ||
      v.name.toLowerCase() === "doctors" ,
    );
  const nurse =
    staffGroups &&
    staffGroups?.getStaffGroups?.staffGroups?.find(
      (v) => v.name.toLowerCase() === 'nurse' ||
      v.name.toLowerCase() === "nurses",
    );

  const ownerId =
    staffGroups &&
    staffGroups?.getStaffGroups?.staffGroups?.find(
      (v) => v?.name?.toLowerCase() === 'owner' ||
      v?.name?.toLowerCase() === "owners",
    )?.id;


  const useStaffs = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();

    const defaultValue = {
      page: 1,
    };
    const isEmptyValue = _.isEmpty(value);
    const isEmptySearchValue = _.isEmpty(searchValue);
    const isEqualValue = _.isEqual(value, defaultValue);

    const initialQuery = useQuery<GetStaffsProps>(GET_STAFFS, {
      errorPolicy: 'all',
      skip:
        skip || !isEmptySearchValue || !hasPermission('VIEW_STAFF_LIST'),
      variables: {
        query: {
          ...value,
          isWithInvitedStaff: true,
          facility: value?.facility || defaultFacility,
        },
      },
      onCompleted: (data) => {
        if (isEqualValue || isEmptyValue) {
          const updatedData = data.getStaffs.staff.map((item) => {
            const { permissions, staffGroup } = item;

            return { 
              ...item, 
              permissions: _.uniqBy(_.concat(staffGroup.permissions, permissions), "id"),
            };
          })


          setInitialStaffs((prev) => ({
            ...prev,
            getStaffs: {
              total: data.getStaffs.total,
              staff: updatedData
            }
          }));;
        }
      },
    });

    const filteredQuery = useQuery<SearchStaffsProps>(SEARCH_STAFF, {
      errorPolicy: 'all',
      skip:
        skip || isEmptySearchValue,
      variables: {
        input: {
          ...value,
          facility: value?.facility || defaultFacility,
          search: searchValue,
        },
      },
    });

    return {
      initialQuery,
      filteredQuery,
      initialStaffs,
      ...others,
      searchValue,
    };
  };

  const useOwner = () => {
    const result = useQuery<SearchStaffsProps>(SEARCH_STAFF, {
      errorPolicy: 'all',
      skip: !hasPermission('VIEW_STAFF_LIST') || !!owner?.id,
      variables: { input: {
        search: ``,
        staffGroup: ownerId
      }},
      onCompleted: (data) => setOwner(data?.searchStaff?.staff?.[0])
    });

    return { ...result, owner }
  }

  const useDoctors = (value?: OperationVariables, skip?: boolean) => {
    const defaultValue = {
      page: 1,
      facility: staff.getStaff.facility.id,
    };

    const variables = {
      staffGroup: doctor?.id,
      search: '',
      ...value,
    };
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptyValue = _.isEmpty(value);
    
    const result = useQuery<SearchStaffsProps>(SEARCH_STAFF, {
      errorPolicy: 'all',
      skip: skip || !hasPermission('VIEW_STAFF_LIST'),
      variables: { input: variables },
      onCompleted: (data) => {
        if (isEqualValue || isEmptyValue) {
          setDoctors(data);
        }
        if (value?.status === 'Active') {
          setActiveDoctors(data);
        }
      },
    });

    return { ...result, doctors, activeDoctors };
  };

  const useNurses = (value?: OperationVariables, skip?: boolean) => {
    const defaultValue = {
      page: 1,
      facility: staff.getStaff.facility.id,
    };

    const variables = {
      staffGroup: nurse?.id,
      search: '',
      ...value,
    };
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptyValue = _.isEmpty(value);

    const result = useQuery<SearchStaffsProps>(SEARCH_STAFF, {
      errorPolicy: 'all',
      skip: skip || !hasPermission('VIEW_STAFF_LIST'),
      variables: { input: variables },
      onCompleted: (data) => {
        if (isEqualValue || isEmptyValue) {
          setNurses(data);
        }
        if (value?.status === 'Active') {
          setActiveNurses(data);
        }
      },
    });

    return { ...result, nurses, activeNurses };
  };


  const useGetStaff = (id: string, skip?: boolean) => {
    const result = useQuery<StaffProps>(GET_STAFF, {
      errorPolicy: 'all',
      skip: skip || !staff?.getStaff?.id,
      variables: { staffId: id },
    });

    return { ...result };
  };


  const useStaffsDropdown = (value?: OperationVariables) => {
    const delayCallback = useDebounceCallback(1000);
    const [searchValue, setSearchValue] = useState<string>("");
    const [staffDropdown, setStaffDropdown] = useState<Staffs>({} as unknown as Staffs);
    const getPageNumber = (docLength: number) => Math.ceil(docLength / 10);
    const pageNumber = staffDropdown?.staff?.length;
    const staffsDropdownPage = getPageNumber(pageNumber || 1);
    const [page, setPage] = useState<number>(staffsDropdownPage);

    const loadMore = () => {
      setPage(staffsDropdownPage + 1);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      delayCallback(() => {
        setPage(1)
        setSearchValue(e.target.value.trim())
      });
    }

    const {data, ...result} = useQuery<SearchStaffsProps>(SEARCH_STAFF, {
      errorPolicy: 'all',
      skip: !hasPermission("VIEW_STAFF_LIST"),
      variables: {
        input: {
          ...value,
          search: searchValue,
          page
        },
      },
      onCompleted: (d) => {
        if (searchValue === "" && result?.variables?.input?.page === 1) {
          setDefaultStaff(d.searchStaff)
        }
      }
    });


    useEffect(() => {
      const isPageGreaterThanOne = page > 1;
      setStaffDropdown((prev) => ({
        ...prev,
        total:  data?.searchStaff?.total as unknown as number,
        patients: isPageGreaterThanOne
          ? [...(prev.staff || []) as unknown as Staff[], ...(data?.searchStaff?.staff || []) as unknown as Staff[]]
          : data?.searchStaff?.staff as unknown as Staff[],
      }));
    }, [data, page])

    const resetDropdown = () => {
      setStaffDropdown(defaultStaff);
    };

    return { 
      ...result,
      searchValue,
      handleSearchChange,
      loadMore,
      hasNextPage: staffDropdown?.total > staffDropdown?.staff?.length,
      staffDropdown,
      resetDropdown
    };
  };



  return {
    useStaffGroups,
    useStaffs,
    useDoctors,
    useNurses,
    useGetStaff,
    useOwner,
    useStaffsDropdown
  };
};

export default useQueries;
