import * as React from 'react';
import { RecordData, RecordTemplate } from 'src/types';
import { ActivityType } from 'src/types/activity.type';
import TimelineContext from './context';

export type TimelineType = {
  [key: string]: {
    tmln: {
      [key: string]: RecordData | ActivityType;
    };
    currentPage?: number;
    total?: number;
  };
};

const TimelineProvider: React.FC = ({ children }) => {
  const [timeline, setTimeline] = React.useState<TimelineType>({});
  const [recordTemplates, setRecordTemplates] = React.useState<
    Record<string, RecordTemplate>
  >({});
  const [showTimeLine, toggleShowTimeLine] = React.useState(true);

  return (
    <TimelineContext.Provider
      value={{
        timeline,
        setTimeline,
        recordTemplates,
        setRecordTemplates,
        showTimeLine,
        toggleShowTimeLine,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export default TimelineProvider;
