import {
  Column,
  Row,
  Text,
  H4,
  IconButton,
  SkeletonWrapper,
} from 'src/components';
import { Modal } from 'src/containers';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { CancelIcon, CheckmarkIcon, UpdateIcon } from 'src/assets/icons';
import { InputBase } from 'src/components/Input/styled';
import { usePatientDetails } from 'src/pages/admin/PatientDetails/hooks/usePatientDetails';
import { PatientProps } from 'src/types';
import { getModalParams } from 'src/utils';
import { Spinner } from 'src/components/PageLoader/styled';
import Skeleton from 'react-loading-skeleton';
import mixpanel from 'mixpanel-browser';
import { InputWrapper } from './styled';
import { useFamily } from '../AddPatient/familyInfo/api';
import { IndividualDetails } from './components/individualDetails';
import { FamilyDetails } from './components/familyDetails';
import { StyledButton } from './components/styledButton';

const FamilyCard: React.FC = () => {
  const id = getModalParams('id');
  const { patient } = usePatientDetails();
  const [show, setShow] = useState<boolean>(false);
  const { family } = (patient || {}) as PatientProps;

  const { useGetFamily, useEditFamily } = useFamily();
  const { data, loading } = useGetFamily(id);
  const { editFamily, loading: editFamilyLoading } = useEditFamily();

  const { handleBlur, handleSubmit, handleChange, initialValues, values } =
    useFormik({
      initialValues: {
        name: family?.name,
      },
      onSubmit: async (_values) => {
        await editFamily({
          familyId: id,
          input: _values,
        });
        mixpanel.track('Edited Patient Family');
        setShow(false);
      },
    });

    return (
      <Modal>
        <Column minWidth="33.5rem" gap={0.75}>
          {patient?.id && (
          <Column gap={0.65}>
            <H4>Family Information</H4>
            <Row gap={0.75}>
              <FamilyDetails width="55%" title="Family Name">
                {!show ? (
                  <>
                    <Text
                      modStyles={{ my: 0 }}
                      color="primary"
                      size="sm"
                      weight="bold"
                    >
                      {family?.name}
                    </Text>
                    <StyledButton onClick={() => setShow(true)}>
                      <UpdateIcon className="fill-red-500 size-1" />
                      Edit
                    </StyledButton>
                  </>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <InputWrapper>
                      <InputBase 
                        name="name"
                        disabled={editFamilyLoading}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Family Name" 
                      />
                      <Row gap={0.25} align="center">
                        {editFamilyLoading && <Spinner size={1.5} />}
                        {!editFamilyLoading && (
                          <IconButton
                            onClick={() => setShow(false)}
                            variant="text"
                            size={1}
                            toolTipTitle="Cancel edit"
                          >
                            <CancelIcon />
                          </IconButton>
                        )}
                        {!editFamilyLoading &&
                          values.name &&
                          values.name !== initialValues.name && (
                            <IconButton
                              type="submit"
                              variant="text"
                              toolTipTitle="Confirm edit"
                            >
                              <CheckmarkIcon />
                            </IconButton>
                          )}
                      </Row>
                    </InputWrapper>
                  </form>
                )}
              </FamilyDetails>
              <FamilyDetails width="45%" title="Family ID">
                <Text modStyles={{ my: 0, px: 0 }} weight="semibold">
                  {family?.familyId}
                </Text>
              </FamilyDetails>
            </Row>
          </Column>
        )}
          {loading && (
          <SkeletonWrapper>
            <Skeleton width="20%" height={10} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={40} />
          </SkeletonWrapper>
        )}
          {data && data?.getFamily?.patients?.length > 0 && (
          <>
            <Text modStyles={{ my: 0 }}>Members</Text>
            {data?.getFamily?.patients?.map((el) => {
              return (
                <IndividualDetails
                  id={el?.id}
                  key={el?.id}
                  user={el?.user}
                  familyName={data?.getFamily?.name}
                />
              );
            })}
          </>
        )}
        </Column>
      </Modal>
  );
};

export default FamilyCard;