import React from 'react';
import { ArchiveIcon } from 'src/assets/icons';
import { theme } from 'src/utils';
import { Product } from 'src/types';
import { GET_PRODUCTS, PERMISSION_ACTION_MESSAGE } from 'src/constants';
import { useStaff, useToast, useAlert } from 'src/state';
import { Button, Text } from 'src/components';
import { useInventoryApis } from 'src/store';
import mixpanel from 'mixpanel-browser';

interface ActionColumnProps {
  row: Record<string, unknown>;
}

export const InventoryActionColumn: React.FC<ActionColumnProps> = ({ row }) => {
  const { showAlert, removeAlert } = useAlert();
  const { showToast } = useToast();
  const { hasPermission, staffPermissions } = useStaff();
  const { editProduct } = useInventoryApis();
  const rowValue = row as unknown as Product;

  const archiveProduct = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    mixpanel.track('Clicked Archive Button', { feature: 'Inventory' });
    e.stopPropagation();
    if (!hasPermission('ARCHIVE_PRODUCT')) {
      return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
    }
    showAlert({
      type: 'decision',
      title: 'Archive This Product',
      message: `Do you want to archive product: ${rowValue.name}?`,
      confirm: async () => {
        mixpanel.track('Confirmed Archive', { feature: 'Inventory' });
        await editProduct({
          variables: {
            input: {
              isArchived: true,
            },
            productId: rowValue.id,
          },
          refetchQueries: [GET_PRODUCTS],
          awaitRefetchQueries: true,
          onCompleted(d) {
            if (d?.editProduct?.success) {
              showToast(d?.editProduct?.message, 'success');
              removeAlert();
            }
          },
        });
      },
    });
  };

  const unArchiveProduct = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    mixpanel.track('Clicked Unarchive Button', { feature: 'Inventory' });
    e.stopPropagation();
    if (!staffPermissions.includes('ARCHIVE_PRODUCT')) {
      return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
    }
    showAlert({
      type: 'decision',
      title: 'Archive This Product?',
      message: `Do you want to unarchive product: ${rowValue.name}`,
      confirm: async () => {
        mixpanel.track('Confirmed Unarchive', { feature: 'Inventory' });
        await editProduct({
          variables: {
            input: {
              isArchived: false,
            },
            productId: rowValue.id,
          },
          refetchQueries: [GET_PRODUCTS],
          awaitRefetchQueries: true,
          onCompleted(d) {
            if (d?.editProduct?.success) {
              showToast(d?.editProduct?.message, 'success');
              removeAlert();
            }
          },
        });
      },
    });
  };

  return (
    <Button
      variant="text"
      data-testid="archive-product"
      onClick={rowValue.isArchived ? unArchiveProduct : archiveProduct}
      width="max-content"
      background={theme.secondary[700]}
      modStyles={{ pa: 0, mx: 'auto' }}
      noIconStyles
    >
      <ArchiveIcon />
      <Text color={theme.secondary[700]} modStyles={{ ma: 0 }}>
        {rowValue.isArchived ? 'Unarchive' : 'Archive'}
      </Text>
    </Button>
  );
};
