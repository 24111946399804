import { ToastAction, ToastStateType } from 'src/types';
import { v4 as uuidV4 } from 'uuid';

export const SHOW_TOAST = 'SHOW_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const initialState: ToastStateType = {
  toasts: [],
};

const reducer = (
  state: ToastStateType = initialState,
  action: ToastAction,
): ToastStateType => {
  const current: ToastStateType = state;
  const message = action.payload?.message ?? '';
  const type = action.payload?.type ?? 'success';
  const duration = action.payload?.duration ?? 3000;
  const id = action.payload?.id ?? uuidV4();
   const newToasts =
     current.toasts.length >= 5
       ? current.toasts.slice(5, current.toasts.length)
       : current.toasts;
  switch (action.type) {
    case SHOW_TOAST:
     
      return {
        ...current,

        toasts: [
          ...newToasts,
          {
            message,
            type,
            duration,
            id,
          },
        ],
      };
    case REMOVE_TOAST:
      return {
        ...current,
        toasts: [...current.toasts.filter((t) => t.id !== id)],
      };
    default:
      return current;
  }
};

export default reducer;
