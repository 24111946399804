import { routes } from 'src/configs/routes';
import { useEffect } from 'react';
import { RouteBuilder } from './containers/RouteBuilder';
import ApolloWrapper from './client';
import {
  ToastProvider,
  FacilitiesProvider,
  AuthProvider,
  StaffProvider,
  StaffsProvider,
  StaffGroupsProvider,
  RowDataProvider,
  MetaProvider,
  PermsProvider,
  NotificationProvider,
  UserProvider,
  TimelineProvider,
  RecordTemplateProvider,
  FeatureFlagProvider,
  MedicalSummaryProvider,
  NoteTempProvider,
  PatientsProvider,
  ServicesProvider,
  InvoiceProvider,
  AppointmentProvider,
  HmosProvider,
  StepTemplatesProvider,
} from './state';
// import { InvoiceProvider } from './state/invoice';
import { ToastManager } from './containers';
import { AlertProvider } from './state/alert';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-quill/dist/quill.snow.css';
import { ErrorBoundary, ErrorComponent } from './components';

const App = () => {
  useEffect(() => {
    window.addEventListener('storage', ({ key, newValue }) => {
      if (key === 'token') {
        if (typeof newValue === 'string') {
          window.location.reload();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary fallbackComponent={ErrorComponent}>
      <FeatureFlagProvider>
        <ToastProvider>
          <AlertProvider>
            <ApolloWrapper>
              <AuthProvider>
                <UserProvider>
                  <PatientsProvider>
                    <StaffGroupsProvider>
                      <StaffProvider>
                        <ToastManager />
                        <StaffsProvider>
                          <FacilitiesProvider>
                            <ServicesProvider>
                              <InvoiceProvider>
                                <AppointmentProvider>
                                  <StepTemplatesProvider>
                                    <HmosProvider>
                                      <MetaProvider>
                                        <PermsProvider>
                                          <RowDataProvider>
                                            <NotificationProvider>
                                              <TimelineProvider>
                                                <RecordTemplateProvider>
                                                  <NoteTempProvider>
                                                    <MedicalSummaryProvider>
                                                      <RouteBuilder
                                                        routes={routes}
                                                      />
                                                    </MedicalSummaryProvider>
                                                  </NoteTempProvider>
                                                </RecordTemplateProvider>
                                              </TimelineProvider>
                                            </NotificationProvider>
                                          </RowDataProvider>
                                        </PermsProvider>
                                      </MetaProvider>
                                    </HmosProvider>
                                  </StepTemplatesProvider>
                                </AppointmentProvider>
                              </InvoiceProvider>
                            </ServicesProvider>
                          </FacilitiesProvider>
                        </StaffsProvider>
                      </StaffProvider>
                    </StaffGroupsProvider>
                  </PatientsProvider>
                </UserProvider>
              </AuthProvider>
            </ApolloWrapper>
          </AlertProvider>
        </ToastProvider>
      </FeatureFlagProvider>
    </ErrorBoundary>
  );
};

export default App;
