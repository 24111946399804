import { Button } from 'src/components';
import { theme, font } from 'src/utils';
import styled from 'styled-components';

export const FamilyButton = styled(Button)`
  margin-bottom: 1rem;
  font-size: ${font.sizes.sm};
  svg, path {
    width: 1rem;
    height: 1rem;
    fill: ${theme.red[500]};
    stroke-width: 0;
  }
`;
