/* eslint-disable no-prototype-builtins */
import { Column, Text } from 'src/components';
import { BatchStatus } from 'src/types';
import { theme } from 'src/utils';
import _ from 'lodash';
import { statusToColorMap } from './BatchCard';
import Tag from './Tag';

const ProductExtraInfo: React.FC<{
  name: string;
  brandName?: string;
  extraInfo: string;
  batchId?: string;
  status?: BatchStatus[];
  quantityLeft?: number;
  expiryDate?: string;
  isOTC?: boolean;
}> = ({
  name,
  extraInfo,
  batchId,
  status,
  expiryDate,
  brandName,
  ...props
}) => {
  return (
    <Column gap={0.3}>
      <p>
        <Text
          size="base"
          weight="bold"
          color={theme.primary[700]}
          modStyles={{ ma: 0 }}
          dangerouslySetInnerHTML={{ __html: `${name} -` }}
        />
        <Text size="base" color="#666666" modStyles={{ ma: 0 }}>
          {' '}
          {brandName}
        </Text>
      </p>
      {extraInfo && (
        <p>
          <Text
            weight="semibold"
            size="base"
            color="#666666"
            modStyles={{ ma: 0, ml: 0.2 }}
          >
            {extraInfo}
          </Text>
        </p>
      )}
      <p>
        {props.hasOwnProperty('quantityLeft') && (
          <Text size="base" color="#666666" modStyles={{ ma: 0, ml: 0.2 }}>
            quantity on shelf: {props.quantityLeft}
          </Text>
        )}
        {props.isOTC && (
          <>
            <Text modStyles={{ mx: 0.5 }}>•</Text>
            <Text size="base" color="#666666" modStyles={{ ma: 0, ml: 0.2 }}>
              OTC
            </Text>
          </>
        )}
        {expiryDate && (
          <>
            <Text modStyles={{ mx: 0.5 }}>•</Text>
            <Text size="base" color="#666666" modStyles={{ ma: 0, ml: 0.2 }}>
              Expiry Date: {expiryDate}
            </Text>
          </>
        )}
        {batchId && (
          <>
            <Text modStyles={{ mx: 0.5 }}>•</Text>
            <Tag color="blue" value={batchId as string} />
          </>
        )}
        {!!status?.length && (
          <>
            <Text modStyles={{ mx: 0.5 }}>•</Text>
            <>
              {status.map((stat) => (
                <Tag
                  key={stat}
                  color={statusToColorMap[stat as string]}
                  value={_.capitalize(stat?.split('_')?.join(' '))}
                />
              ))}
            </>
          </>
        )}
      </p>
    </Column>
  );
};

export default ProductExtraInfo;
