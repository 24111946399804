import { gql } from '@apollo/client';
import { RECORD_FRAGMENT, RECORD_META_FRAGMENT } from './record.fragment';

export const GET_RECORD_META = gql`
  ${RECORD_META_FRAGMENT}
  query GetRecord($input: ID!) {
    getRecord(input: $input) {
      ...recordMetaFragment
    }
  }
`;

export const GET_RECORD = gql`
  ${RECORD_FRAGMENT}
  query GetRecord($input: ID!) {
    getRecord(input: $input) {
      ...recordFragment
    }
  }
`;

export const GET_TIMELINE = gql`
  ${RECORD_FRAGMENT}
  query MedicalTimeline($query: GetMedicalTimelineInput!) {
    medicalTimeline(query: $query) {
      medicalTimeline {
        ... on Record {
          ...recordFragment
        }
        ... on Activity {
          _id
          activityCreateAt: createdAt
          activityFacility: facility {
            address
          }
          description
          journey {
            id: _id
            steps {
              actions {
                isCompleted
                description
                metadata
                updatedAt
                tag
                id: _id
                special
              }
              id: _id
              stepId
              status
              updatedAt
              patient {
                id: _id
                user {
                  firstName
                  lastName
                }
              }
              stepTemplate {
                name
              }
              parameters {
                description
                type
                text
                staffGroup {
                  name
                }
                staff {
                  user {
                    firstName
                    lastName
                  }
                }
              }
              eventLogs {
                updatedAt
                staff {
                  id: _id
                  user {
                    firstName
                    lastName
                    profileAvatar
                  }
                }
                nextState {
                  id: _id
                  createdAt
                  description
                  isCompleted
                  metadata
                  special
                  updatedAt
                }
                previousState {
                  id: _id
                  createdAt
                  description
                  isCompleted
                  metadata
                  special
                  updatedAt
                }
              }
            }
            patient {
              user {
                firstName
                lastName
              }
            }
            status
            updatedAt
          }
        }
      }
      total
    }
  }
`;

export const SEARCH_RECORDS = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      total
      result {
        ... on RecordSearchType {
          _id
          data {
            id
            title
            type
            ... on InfoData {
              id
              title
              type
              infoValue: description
            }
            ... on SelectData {
              id
              title
              type
              selectValue: value
            }
            ... on TextData {
              id
              title
              type
              textValue: value
            }
            ... on ImageData {
              id
              title
              type
              imageValue: value
            }
            ... on FileData {
              id
              title
              type
              fileValue: value
            }
            ... on TextAreaData {
              id
              title
              type
              textAreaValue: value
            }
            ... on DateData {
              id
              title
              type
              dateValue: value
            }
            ... on MultiSelectData {
              id
              title
              type
              multiSelectValue: value
            }
            ... on NumberData {
              id
              title
              type
              numberValue: value
            }
            ... on WYSIWYGData {
              createdAt
              id
              title
              type
              wysiwygValue: value
            }
            ... on RowData {
              id
              title
              type
              rowValue: value {
                id
                title
                type
                ... on DateData {
                  id
                  title
                  type
                  dateValue: value
                }
                ... on MultiSelectData {
                  id
                  title
                  type
                  multiSelectValue: value
                }
                ... on NumberData {
                  id
                  title
                  type
                  numberValue: value
                }
                ... on SelectData {
                  id
                  title
                  type
                  selectValue: value
                }
                ... on ImageData {
                  id
                  title
                  type
                  imageValue: value
                }
                ... on FileData {
                  id
                  title
                  type
                  fileValue: value
                }
                ... on TextAreaData {
                  id
                  title
                  type
                  textAreaValue: value
                }
                ... on TextData {
                  id
                  title
                  type
                  textValue: value
                }
              }
            }
            ... on TableData {
              id
              title
              type
              tableValue: value {
                id
                title
                type
                ... on RowData {
                  id
                  title
                  type
                  rowValue: value {
                    id
                    title
                    type
                    ... on DateData {
                      id
                      title
                      type
                      dateValue: value
                    }
                    ... on ImageData {
                      id
                      title
                      type
                      imageValue: value
                    }
                    ... on FileData {
                      id
                      title
                      type
                      fileValue: value
                    }
                    ... on TextAreaData {
                      id
                      title
                      type
                      textAreaValue: value
                    }
                    ... on MultiSelectData {
                      id
                      title
                      type
                      multiSelectValue: value
                    }
                    ... on NumberData {
                      id
                      title
                      type
                      numberValue: value
                    }
                    ... on SelectData {
                      id
                      title
                      type
                      selectValue: value
                    }
                    ... on TextData {
                      id
                      title
                      type
                      textValue: value
                    }
                  }
                }
              }
            }
          }
          id: _id
          patient {
            id: _id
            user {
              fullName
              id: _id
              firstName
              lastName
            }
          }
          template {
            id: _id
            description
            title
          }
          createdAt
          provider {
            id: _id
            user {
              _id
              firstName
              lastName
              fullName
            }
          }
          facility {
            address
          }
        }
        ... on ActivitySearchType {
          _id
          activityCreateAt: createdAt
          activityFacility: facility {
            address
          }
          description
          journey {
            id: _id
            steps {
              actions {
                isCompleted
                description
                metadata
                updatedAt
                tag
                id: _id
                special
              }
              id: _id
              stepId
              status
              updatedAt
              patient {
                id: _id
                user {
                  firstName
                  lastName
                }
              }
              stepTemplate {
                name
              }
              parameters {
                description
                type
                text
                staffGroup {
                  name
                }
                staff {
                  user {
                    firstName
                    lastName
                  }
                }
              }
              eventLogs {
                updatedAt
                staff {
                  id: _id
                  user {
                    firstName
                    lastName
                    profileAvatar
                  }
                }
                nextState {
                  id: _id
                  createdAt
                  description
                  isCompleted
                  metadata
                  special
                  updatedAt
                }
                previousState {
                  id: _id
                  createdAt
                  description
                  isCompleted
                  metadata
                  special
                  updatedAt
                }
              }
            }
            patient {
              user {
                firstName
                lastName
              }
            }
            status
            updatedAt
          }
        }
      }
    }
  }
`;
