import { StopIcon } from "src/assets/icons"
import { theme, font } from "src/utils"
import { Row } from "../Flex"

interface ErrorAlertProps {
  children: React.ReactNode;
  icon?: React.ReactElement
}


export const ErrorAlert: React.FC<ErrorAlertProps> = ({ 
  children, 
  icon = <StopIcon className="fill-red-500 size-1" /> 
}) => {
  return (
    <Row 
      modStyles={{ 
        border: `1px solid ${theme.red[500]}`, 
        px: 0.5,
        py: 0.25,
        mr: "auto",
        ml: "auto",
        br: 0.625,
        fontSize: font.sizes.xs,
        color: theme.red[500]
      }} 
      width="max-content" 
      align="center" 
      justify="center" 
      gap={0.5}
    >
      {icon}
      {children}
    </Row>
  )
}