import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import styled from 'styled-components';

export const AppointmentModal = styled(Modal)`
  ${ModalChildContainer} {
    form {
      width: 100%;
    }
  }
`;
