import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_INVOICE,
  GET_INVOICES,
  GET_INVOICES_TOTAL,
  RETURN_INVOICE_ITEM,
} from 'src/constants';

export const useInventoryInvoiceApis = () => {
  const [
    fetchAllInvoices,
    { loading: fetchAllInvoicesLoading, error: fetchAllInvoicesError },
  ] = useLazyQuery(GET_INVOICES);

  const [
    fetchInvoice,
    { loading: fetchInvoiceLoading, error: fetchInvoiceError },
  ] = useLazyQuery(GET_INVOICE);

  const [
    fetchInvoiceTotal,
    { loading: fetchTotalLoading, error: fetchTotalError },
  ] = useLazyQuery(GET_INVOICES_TOTAL);

  const [
    returnInvoiceItem,
    { error: returnItemError, loading: returnItemLoading },
  ] = useMutation(RETURN_INVOICE_ITEM, { errorPolicy: 'all' });

  return {
    fetchAllInvoices,
    fetchAllInvoicesLoading,
    fetchAllInvoicesError,
    fetchInvoice,
    fetchInvoiceError,
    fetchInvoiceLoading,
    fetchInvoiceTotal,
    fetchTotalLoading,
    fetchTotalError,
    returnInvoiceItem,
    returnItemError,
    returnItemLoading,
  };
};
