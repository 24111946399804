import { Select, Input } from 'src/components';
import { useHMOs } from 'src/state';
import { FormFieldInput } from 'src/types';
import { getModalParams } from 'src/utils';

interface Props {
  value: string
};

type InputProps = Pick<FormFieldInput, "name" | "errors"> & Props;
type FormProps = Pick<FormFieldInput, "handleChange" | "setFieldValue">
type HMOFormProps = FormProps & {
  name: InputProps;
  email: InputProps;
  phoneNumber: InputProps;
}

const HMOForm: React.FC<HMOFormProps> = ({
  handleChange,
  name,
  email,
  phoneNumber,
  setFieldValue,
}) => {

  const id = getModalParams("id");
  const { useHMOList, currentHMO } = useHMOs();
  const { hmoList: data, loading } = useHMOList();

  const hmoList = !id && Array.isArray(data) ? 
  data : [ ...(Array.isArray(data) ? data : []),
    {
      name: currentHMO?.name,
      logo: currentHMO?.logo
    }
  ];


  return (
    <>
      <Select
        label="HMO Name"
        name={name.name}
        required
        onChange={handleChange}
        loading={loading && hmoList?.length === 0}
        disabled={loading && hmoList?.length === 0}
        value={name.value}
        error={name.errors}
      >
        <option disabled value="">
          Choose Provider
        </option>
        {hmoList?.length === 0 && (
          <option value="">No HMOs available</option>
        )}
        {hmoList?.length > 0 && hmoList?.map((item, idx) => (
          <option key={idx} value={item?.name}>
            {item?.name}
          </option>
        ))}
      </Select>
      <Input
        onChange={(e) => setFieldValue?.(email.name, e.target.value.trim())}
        label="Email Address"
        required
        placeholder="@businessmail.com"
        name={email.name}
        type="text"
        value={email.value?.trim()}
        error={email.errors}
      />
      <Input
        onChange={handleChange}
        required
        label="Phone Number"
        placeholder="E.g. 080 000 0000"
        name={phoneNumber.name}
        type="text"
        value={phoneNumber.value?.replace(/\D/g, '')}
        error={phoneNumber.errors}
      />
    </>
  );
};

export default HMOForm;
