import { gql } from "@apollo/client";
import { appointmentFieldsFragment } from "../Fragments/appointment";

export const ADD_APPOINTMENT = gql`
  mutation AddAppointment($input: AddAppointmentInput!) {
    addAppointment(input: $input) {
      success
      message
      appointment {
        ...AppointmentFields
      }
    }
  }
  ${appointmentFieldsFragment}
`;


export const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment($date: DateTime, $input: UpdateAppointmentInput!) {
    updateAppointment(date: $date, input: $input) {
      success
      message
      appointment {
        ...AppointmentFields
      }
    }
  }
  ${appointmentFieldsFragment}
`;

export const CHECK_IN_APPOINTMENT = gql`
  mutation CheckInAppointment($appointmentId: ID!, $date: DateTime) {
    checkInAppointment(appointmentId: $appointmentId, date: $date) {
      success
      message
      appointment {
        ...AppointmentFields
      }
    }
  }
  ${appointmentFieldsFragment}
`;

export const CHECK_OUT_APPOINTMENT = gql`
  mutation CheckOutAppointment($date: DateTime, $appointmentId: ID!) {
    checkOutAppointment(date: $date, appointmentId: $appointmentId) {
      success
      message
      appointment {
        ...AppointmentFields
      }
    }
  }
  ${appointmentFieldsFragment}
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($appointmentId: ID!, $date: DateTime, $applyTo: ApplyTo) {
    cancelAppointment(appointmentId: $appointmentId, date: $date, applyTo: $applyTo) {
      success
      message
    }
  }
`;