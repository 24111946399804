export function formatAgeRange(ageRange: string) {
  if (!ageRange.startsWith('AGE_')) {
    return '';
  }

  const agePart = ageRange.slice(4); // Remove the "AGE_" part
  let formattedAge;

  if (agePart.includes('_ABOVE')) {
    const age = agePart.split('_')[0];
    formattedAge = `Age: above ${age}`;
  } else {
    const [start, end] = agePart.split('_');
    formattedAge = `Age: ${start}-${end}`;
  }

  return formattedAge;
}
