import { gql } from '@apollo/client';
import { productFragment } from '../Fragments/inventory';

export const SEARCH_DRUGS = gql`
  query Drugs($input: SearchDrugsInput!) {
    searchDrugs(input: $input) {
      drugs {
        brandName
        drugId
        form
        ingredients
        nafdac
        name
        strength
        isDrugAdded
        productId
      }
      total
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($input: GetProductInput) {
    getProducts(input: $input) {
      total
      products {
        id: _id
        brandName
        categories
        createdAt
        deletedAt
        form
        isArchived
        isOTC
        name
        productCode
        updatedAt
        batches {
          status
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($input: ID!) {
    getProduct(input: $input) {
      ...productFields
    }
  }
  ${productFragment}
`;

export const GET_STOCK_ALERT = gql`
  query GetStockAlerts {
    getStockAlerts {
      critical
      warning
    }
  }
`;

export const GET_EXPIRY_ALERT = gql`
  query GetExpiryAlerts {
    getExpiryAlerts {
      critical
      warning
    }
  }
`;

export const GET_TOTAL_SALES = gql`
  query GetTotalSales($input: SingleDateInput!) {
    getTotalSales(input: $input) {
      inc
      total
    }
  }
`;

export const SEARCH_INVENTORY = gql`
  query SearchInventory($input: SearchInventoryInput) {
    searchInventory(input: $input) {
      total
      batches {
        id: _id
        batchNumber
        sellingPrice
        expiryDate
        expiryAlert
        quantityLeft
        quantityOnShelf
        quantity
        status
        stockAlert
        product {
          extraInfo
          brandName
          id: _id
          name
          productCode
          isOTC
        }
      }
    }
  }
`;

export const GET_PRODUCT_LOGS = gql`
  query GetProductLogs($input: GetProductLogsInput!) {
    getProductLogs(input: $input) {
      total
      logs {
        id: _id
        action
        actionBy {
          user {
            firstName
            lastName
          }
          id: _id
        }
        batch {
          batchNumber
        }
        createdAt
        updatedAt
        deletedAt
        ... on AddProduct {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
            quantity
          }
          createdAt
          updatedAt
          deletedAt
        }
        ... on AddProductBatch {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          quantity
          createdAt
          updatedAt
          deletedAt
        }
        ... on ArchiveBatch {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          createdAt
          updatedAt
          deletedAt
        }
        ... on ArchiveProduct {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          product {
            isArchived
          }
          createdAt
          updatedAt
          deletedAt
        }
        ... on EditBatch {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          createdAt
          updatedAt
          deletedAt
          updates {
            field
            before
            after
          }
        }
        ... on EditProduct {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          createdAt
          updatedAt
          deletedAt
          updates {
            field
            before
            after
          }
        }
        ... on PushToShelf {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          createdAt
          updatedAt
          deletedAt
          quantity
        }
        ... on ReturnAndRefund {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          createdAt
          updatedAt
          deletedAt
          quantity
        }
        ... on SellProduct {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          batch {
            batchNumber
          }
          createdAt
          updatedAt
          deletedAt
          buyerType
          quantity
          requester {
            user {
              firstName
              lastName
            }
            id: _id
          }
          patient {
            user {
              firstName
              lastName
            }
            id: _id
          }
        }
      }
    }
  }
`;
