import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import {
  ADD_NEW_SERVICE,
  DELETE_SERVICE,
  GET_SERVICE,
  GET_SERVICES,
  SEARCH_SERVICES,
  UPDATE_SERVICE,
} from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import { ServicesProps } from 'src/types';
import { removeEmptyValues } from 'src/utils';
import _ from 'lodash';
import ServicesContext from './context';
import { useStaff, useToast } from '..';

const defaultFilters = {
  facility: '',
};

const useServices = () => {
  const { initialServices, setInitialServices } = useContext(ServicesContext);
  const [filteredServices, setFilteredServices] = useState<ServicesProps>(
    {} as ServicesProps,
  );
  const {  hasPermission  } = useStaff();
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [page, setPage] = useState(1);

  const { showToast } = useToast();

  const pageLimit = 10;
  const servicesCount = (initialServices?.total as number) || 0;

  const [getServices, { loading: servicesLoading, error: servicesError }] =
    useLazyQuery(GET_SERVICES);

  const {
    handleSearchChange,
    setSearchValue,
    searchData,
    searchLoading,
    searchValue,
  } = useSearch(SEARCH_SERVICES);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e, {
      limit: pageLimit,
      page: 1,
      ...removeEmptyValues(filters),
    });
  };

  useEffect(() => {
    if (!hasPermission("VIEW_SERVICE_LIST")) return;
    if (servicesCount === 0) {
      getServices({
        variables: {
          input: {
            page: 1,
            limit: pageLimit,
            ...removeEmptyValues(filters),
          },
        },
        onCompleted(data) {
          setInitialServices({
            services: [...data.getServices.services],
            total: data.getServices.total,
          });
        },
      });
    }
  }, [
    filters,
    getServices,
    servicesCount,
    setInitialServices,
     hasPermission ,
  ]);

  useEffect(() => {
    if (!hasPermission('VIEW_SERVICE_LIST')) return;
    if ((searchValue || _.some(filters, Boolean)) && searchData) {
      setFilteredServices({
        services: [...searchData.searchServices.services],
        total: searchData.searchServices.total,
      });
    }
  }, [filters, searchData, searchValue,  hasPermission ]);

  const [
    apolloSearchServices,
    { loading: searchServicesLoading, error: searchServicesError },
  ] = useLazyQuery(SEARCH_SERVICES, {
    errorPolicy: 'all',
  });

  const [
    apolloGetService,
    { loading: singleServiceLoading, error: singleServiceError },
  ] = useLazyQuery(GET_SERVICE, {
    errorPolicy: 'all',
  });

  const [
    apolloAddService,
    { loading: addServiceLoading, error: addServiceError },
  ] = useMutation(ADD_NEW_SERVICE);

  const [
    apolloUpdateService,
    { loading: updateServiceLoading, error: updateServiceError },
  ] = useMutation(UPDATE_SERVICE);

  const [
    apolloDeleteService,
    { loading: deleteServiceLoading, error: deleteServiceError },
  ] = useMutation(DELETE_SERVICE);

  const filter = (
    searchPage: number,
    currentFilters: { [key: string]: unknown },
  ) => {
    apolloSearchServices({
      variables: {
        input: {
          limit: pageLimit,
          page: searchPage,
          search: searchValue,
          ...currentFilters,
        },
      },
      onCompleted(data) {
        if (!searchServicesError) {
          setFilteredServices((prev) => ({
            ...prev,
            services: [...data.searchServices.services],
          }));
        } else {
          showToast('Unable to fetch service, please try again', 'error');
        }
      },
    });
  };

  const applyFilters = (newFilter: { [key: string]: string }) => {
    setFilters((prev) => {
      filter(1, { ...prev, ...newFilter });
      return { ...prev, ...newFilter };
    });
    filter(1, { ...removeEmptyValues(newFilter) });
  };

  const removeFilter = (name: string) => {
    setFilters((prev) => ({
      ...prev,
      [name]: '',
    }));
    filter(1, { ..._.pickBy({ ...filters, [name]: '' }, _.identity) });
  };

  const clearFilters = () => {
    setFilteredServices({ services: [], total: 0 });
    setPage(1);
    setFilters({ ...defaultFilters });
  };

  const fetchNewpage = (newPage: number) => {
    filter(newPage, { ...removeEmptyValues(filters) });
  };

  const getServiceFromCache = async (id: string) => {
    if (!hasPermission('VIEW_SERVICE_LIST')) return;
    const service = initialServices.services.find((s) => s?.id === id);
    return service;
  };

  const addService = async (
    value: Record<string, unknown>,
    facilityId: string,
  ) => {
    if (!hasPermission("ADD_NEW_SERVICE")) return;
    const result = await apolloAddService({
      variables: { input: { ...value }, facilityId },
      awaitRefetchQueries: true,
      refetchQueries: [GET_SERVICES],
      onCompleted() {
        if (!addServiceError) {
          showToast('Service added Successfully', 'success');
        } else {
          showToast(addServiceError.message, 'error');
        }
      },
    });

    return result;
  };

  const updateService = async (value?: Record<string, unknown>) => {
    if (!hasPermission("EDIT_SERVICE")) return;
    const result = await apolloUpdateService({
      variables: {
        input: { ...value },
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_SERVICES, SEARCH_SERVICES],
      onCompleted: () => {
        if (!updateServiceError) {
          showToast('Service Updated Successfully', 'success');
        } else {
          showToast(updateServiceError.message, 'error');
        }
      },
    });
    return result;
  };

  const enableDisableService = async (value?: Record<string, unknown>) => {
    if (!hasPermission("ENABLE_DISABLE_SERVICE")) return;
    const result = await apolloUpdateService({
      variables: {
        input: { ...value },
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_SERVICES, SEARCH_SERVICES],
      onCompleted: () => {
        if (!updateServiceError) {
          showToast('Service Updated Successfully', 'success');
        } else {
          showToast(updateServiceError.message, 'error');
        }
      },
    });
    return result;
  };

  const deleteService = async (id: string) => {
    if (!hasPermission("DELETE_SERVICE")) return;
    const result = await apolloDeleteService({
      variables: {
        input: { id },
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_SERVICES, SEARCH_SERVICES],
      onCompleted: () => {
        if (!deleteServiceError) {
          showToast('Service Deleted Successfully', 'success');
        } else {
          showToast(deleteServiceError.message, 'error');
        }
      },
    });

    return result;
  };

  return {
    filters,
    page,
    setPage,
    fetchNewpage,
    deleteService,
    clearFilters,
    removeFilter,
    pageLimit,
    addService,
    getServiceFromCache,
    searchValue,
    applyFilters,
    servicesError,
    updateService,
    enableDisableService,
    searchLoading,
    setSearchValue,
    onSearchChange,
    initialServices,
    apolloGetService,
    deleteServiceLoading,
    filteredServices,
    addServiceLoading,
    singleServiceError,
    updateServiceLoading,
    singleServiceLoading,
    servicesLoading,
    searchServicesLoading: searchLoading || searchServicesLoading,
    displayData:
      searchValue || filters.facility.length || page !== 1
        ? filteredServices.services
        : initialServices.services,
    hasNextPage:
      initialServices.services?.length < (initialServices.total as number) ||
      filteredServices.services?.length < (filteredServices.total as number),
  };
};

export { useServices };
