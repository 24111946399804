import { useState } from 'react';
import { AddService } from 'src/assets/icons';
import { Button, Column, Row, Text } from 'src/components';
import { Modal } from 'src/containers';
import { FORMID_3 } from 'src/constants';
import ServicesMultiRowForm from './Services';
import { ModalTitle } from './styled';

const AddServicesModal = () => {
  const [loading, setLoading] = useState(false);

  const [modalVisible, setModalVisible] = useState<boolean>(true);

  return (
    <Modal visible={modalVisible} onDismiss={() => setModalVisible(false)}>
      <Column gap={2}>
        <Column width="34.44rem">
          <ModalTitle>Add Services</ModalTitle>
          <Text weight="semibold">
            You can specify the services you render, their prices wich would be
            used when creating an invoice for their patients.
          </Text>
        </Column>
        <Column gap={1}>
          <ServicesMultiRowForm setLoading={setLoading} />
          <Row width="100%" justify="flex-end">
            <Row height="3.125rem" width="10.8125rem">
              <Button
                width="max-content"
                form={FORMID_3}
                data-testid="submit-services"
                type="submit"
                disabled={loading}
                isLoading={loading}
              >
                <AddService /> Add Services
              </Button>
            </Row>
          </Row>
        </Column>
      </Column>
    </Modal>
  );
};

export default AddServicesModal;
