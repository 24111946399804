import { font, theme } from 'src/utils/variables';
import styled from 'styled-components';
import { CheckboxProps } from 'src/types';

export const CheckboxBase = styled.input<CheckboxProps>`
  appearance: none;
  background-color: ${theme.white[100]};
  margin-top: 0;
  min-width: 1.25rem;
  min-height: 1.25rem;
  border: 1px solid ${theme.grey[200]};
  border-radius: 3px;
  display: grid;
  margin-right: 12px;
  place-content: center;
  box-sizing: border-box;
  background-position: center;
  cursor: pointer;
  & + label {
    margin-top: 0px;
    cursor: pointer;
    font-size: ${font.sizes.sm};
    color: theme.grey[700]};
  }
  &:checked {
    background-color: ${(props) =>
      props.background ||
      theme[props.activeColor as string]?.[100] ||
      theme.black[100]};
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNy4zNTUyMiAwLjYwNDkzQzcuMzA4NzQgMC41NTgwNjYgNy4yNTM0NCAwLjUyMDg2OSA3LjE5MjUxIDAuNDk1NDg1QzcuMTMxNTggMC40NzAxIDcuMDY2MjMgMC40NTcwMzEgNy4wMDAyMiAwLjQ1NzAzMUM2LjkzNDIxIDAuNDU3MDMxIDYuODY4ODYgMC40NzAxIDYuODA3OTMgMC40OTU0ODVDNi43NDcgMC41MjA4NjkgNi42OTE3IDAuNTU4MDY2IDYuNjQ1MjIgMC42MDQ5M0wyLjkyMDIyIDQuMzM0OTNMMS4zNTUyMiAyLjc2NDkzQzEuMzA2OTYgMi43MTgzMSAxLjI0OTk5IDIuNjgxNjUgMS4xODc1NiAyLjY1NzA1QzEuMTI1MTMgMi42MzI0NSAxLjA1ODQ3IDIuNjIwMzkgMC45OTEzOCAyLjYyMTU1QzAuOTI0MjkgMi42MjI3MSAwLjg1ODA4NSAyLjYzNzA3IDAuNzk2NTQ2IDIuNjYzODJDMC43MzUwMDcgMi42OTA1NiAwLjY3OTMzOCAyLjcyOTE3IDAuNjMyNzE5IDIuNzc3NDNDMC41ODYxIDIuODI1NjkgMC41NDk0NDMgMi44ODI2NiAwLjUyNDg0MSAyLjk0NTA5QzAuNTAwMjM5IDMuMDA3NTIgMC40ODgxNzQgMy4wNzQxOCAwLjQ4OTMzNCAzLjE0MTI3QzAuNDkwNDk1IDMuMjA4MzYgMC41MDQ4NTkgMy4yNzQ1NiAwLjUzMTYwNiAzLjMzNjFDMC41NTgzNTIgMy4zOTc2NCAwLjU5Njk1OCAzLjQ1MzMxIDAuNjQ1MjE5IDMuNDk5OTNMMi41NjUyMiA1LjQxOTkzQzIuNjExNyA1LjQ2Njc5IDIuNjY3IDUuNTAzOTkgMi43Mjc5MyA1LjUyOTM4QzIuNzg4ODYgNS41NTQ3NiAyLjg1NDIxIDUuNTY3ODMgMi45MjAyMiA1LjU2NzgzQzIuOTg2MjMgNS41Njc4MyAzLjA1MTU4IDUuNTU0NzYgMy4xMTI1MSA1LjUyOTM4QzMuMTczNDQgNS41MDM5OSAzLjIyODc0IDUuNDY2NzkgMy4yNzUyMiA1LjQxOTkzTDcuMzU1MjIgMS4zMzk5M0M3LjQwNTk3IDEuMjkzMTEgNy40NDY0OCAxLjIzNjI4IDcuNDc0MTggMS4xNzMwM0M3LjUwMTg4IDEuMTA5NzggNy41MTYxOSAxLjA0MTQ4IDcuNTE2MTkgMC45NzI0M0M3LjUxNjE5IDAuOTAzMzggNy41MDE4OCAwLjgzNTA3NyA3LjQ3NDE4IDAuNzcxODI3QzcuNDQ2NDggMC43MDg1NzcgNy40MDU5NyAwLjY1MTc1MiA3LjM1NTIyIDAuNjA0OTNWMC42MDQ5M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-size: 8px;
    border: ${(props) => {
      const color = theme[props.activeColor as string]?.[700] || theme.grey[200];
      return props.border || `1px solid ${color}`;
    }};
    
    background-position: center;
  }
`;
