import React from 'react';
import { InputProps } from 'src/types';
import { CalendarIcon, ClockIcon } from 'src/assets/icons';
import { Row } from '../Flex';
import {
  ErrorText,
  Icon,
  InputBase,
  InputContainer,
  MobilePlaceholders,
} from './styled';
import { InputLabel } from '../InputLabel';

interface CustomInputProps extends InputProps {
  icon?: React.ReactNode;
}

const CustomInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CustomInputProps
> = ({ label, required, error, icon, modStyles, ...props }, ref) => {
  const date =
    props.type === 'date' && props.value && (props?.value as string).split('-');
  const dateValue = date && `${date[2]}/${date[1]}/${date[0]}`;
  if (props.type === 'number') props.min = 0;

  return (
    <InputContainer modStyles={modStyles} required={!label && required}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Row position="relative" align="center">
        <InputBase ref={ref} {...props} />
        {icon && <Icon>{icon}</Icon>}
        {['date', 'time'].includes(props.type || 'text') && (
          <MobilePlaceholders
            position="absolute"
            width="100%"
            height="100%"
            gap={1}
            justify="space-between"
            align="center"
            value={props.value as string}
          >
            {props.type === 'date' && (!props.value ? 'dd/mm/yyyy' : dateValue)}
            {props.type === 'time' && (!props.value ? '--:--' : props.value)}
            {!props.disabled && props.type === 'date' && <CalendarIcon />}
            {!props.disabled && props.type === 'time' && (
              <ClockIcon color="black" />
            )}
          </MobilePlaceholders>
        )}
      </Row>
      {Boolean(error) && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </InputContainer>
  );
};

export const Input = React.forwardRef(CustomInput);
