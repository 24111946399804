/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import { AllProductLogs, ProductLog } from 'src/types';

const initialState: AllProductLogs = {};

type PayloadType = {
  payload: {
    productId: string;
    logs: { logs: ProductLog[]; total: number };
  };
};

const productLogSlice = createSlice({
  name: 'productLogs',
  initialState,
  reducers: {
    setProductLogs: (state: AllProductLogs, action: PayloadType) => {
      const { productId, logs } = action.payload;
      state[productId] = logs;
    },
  },
});

export const { setProductLogs } = productLogSlice.actions;

export default productLogSlice.reducer;
