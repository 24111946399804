import moment from "moment";

export const getRelativeDate = (date: Date) => {
  const weekNumber = moment(date).isoWeek();
  const currentWeekNumber = moment().isoWeek();

  if (currentWeekNumber === weekNumber) return 'This Week';
  if (currentWeekNumber - weekNumber === 1) return 'Last Week';
  if (currentWeekNumber - weekNumber === 2) return 'Two Weeks Ago';
  if (currentWeekNumber - weekNumber === 3) return 'Three Weeks Ago';
  if (currentWeekNumber - weekNumber === 4) return 'Four Weeks Ago';
  if (currentWeekNumber - weekNumber === 5) return 'Five Weeks Ago';

  // Return date range for the week
  return `${moment(date).startOf("week").format("MMM D, YYYY")} - ${moment(date).endOf("week").format("MMM D, YYYY")}`;
}
