import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { DefaultTableValue, Fields, FormConfigType } from 'src/types';
import { decapitalizeFirstLetter } from './capitalizeFirstLetter';

export const reduceFormConfig = (config: FormConfigType) => {
  return config.fields.reduce(
    (acc, field) => {
      if (!field.fields && !field.columns) {
        if (config.defaultState && config.defaultState[field.id]) {
          acc.state[field.id] = config.defaultState[field.id];
        } else {
          acc.state[field.id] = !['Select', 'MultiSelect'].includes(field.type)
            ? ''
            : undefined;
        }
        if (field.validation) {
          acc.validation[field.id] = field.validation;
        }
      }
      if (field.fields) {
        if (config.defaultState && config.defaultState[field.id]) {
          acc.state[field.id] = [
            {
              ...field.fields.reduce((fieldAcc, fieldVal, idx) => {
                if (
                  config.defaultState &&
                  config.defaultState[field.id] &&
                  Array.isArray(config.defaultState[field.id])
                ) {
                  const val = (
                    config.defaultState[field.id] as DefaultTableValue[]
                  )[idx][fieldVal.id];
                  fieldAcc[fieldVal.id] = val;
                }
                return fieldAcc;
              }, {} as Record<string, unknown>),
            },
          ];
        } else {
          acc.state[field.id] = [
            {
              ...field.fields.reduce((fieldAcc, fieldVal) => {
                fieldAcc[fieldVal.id] = !['Select', 'MultiSelect'].includes(
                  fieldVal.type,
                )
                  ? ''
                  : undefined;
                return fieldAcc;
              }, {} as Record<string, unknown>),
            },
          ];
        }
        field.fields.map((fieldVal) => {
          if (fieldVal.validation) {
            acc.validation[`${field.id}.*.${fieldVal.id}`] =
              fieldVal.validation;
            return fieldVal;
          }
          return fieldVal;
        });
      }
      if (field.columns) {
        if (config.defaultState && config.defaultState[field.id]) {
          acc.state[field.id] = (
            config.defaultState[field.id] as DefaultTableValue[]
          ).map((elm, i) => {
            if (field.columns) {
              return {
                ...field.columns.reduce((fieldAcc, fieldVal) => {
                  if (
                    config.defaultState &&
                    config.defaultState[field.id] &&
                    Array.isArray(config.defaultState[field.id])
                  ) {
                    const val = (
                      config.defaultState[field.id] as DefaultTableValue[]
                    )[i][fieldVal.id];

                    fieldAcc[fieldVal.id] = val;
                  }
                  return fieldAcc;
                }, {} as Record<string, unknown>),
              };
            }
            return elm;
          });
        } else {
          acc.state[field.id] = [
            {
              ...field.columns.reduce((fieldAcc, fieldVal) => {
                fieldAcc[fieldVal.id] = !['Select', 'MultiSelect'].includes(
                  fieldVal.type,
                )
                  ? ''
                  : undefined;
                return fieldAcc;
              }, {} as Record<string, unknown>),
            },
          ];
        }
        field.columns.map((fieldVal) => {
          if (fieldVal.validation) {
            acc.validation[`${field.id}.*.${fieldVal.id}`] =
              fieldVal.validation;
            return fieldVal;
          }
          return fieldVal;
        });
      }
      return acc;
    },
    {
      state: {} as Record<string, unknown>,
      meta: {} as Record<string, unknown>,
      validation: {} as Record<string, string>,
    },
  );
};

export function filterErrors(
  errors: FormikErrors<Record<string, unknown>> | undefined,
  prefix: string,
) {
  const cleanedErrors = {} as FormikErrors<Record<string, unknown>>;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in errors) {
    if (!key.startsWith(prefix)) {
      cleanedErrors[key] = errors[key];
    }
  }
  return cleanedErrors;
}

export const getFormErrorMessage = (
  errors: FormikErrors<Record<string, unknown>> | undefined,
  name: string,
) => {
  const error = errors?.[name];
  const msgKey = name.split('_').join(' ');
  if (error) {
    return error[0].split(msgKey)[1];
  }
  return undefined;
};

export const checkRequired = (field: Fields) => {
  if (field.type === 'Row' && field.fields) {
    const validations = field.fields.map((f) => f.validation);
    return validations.join('').includes('required');
  }
  if (field.type === 'Table' && field.columns) {
    const validations = field.columns.map((f) => f.validation);
    return validations.join('').includes('required');
  }
  return field.validation?.includes('required');
};

export const touch = (
  touched: FormikTouched<Record<string, unknown>>,
  fieldId: string,
  index?: number,
  innerFieldId?: string,
) => {
  const t = touched[fieldId] as boolean | Array<Record<string, boolean>>;
  if (t) {
    if (typeof t !== 'boolean' && innerFieldId && index) {
      if (t[index]) {
        if (t[index][innerFieldId]) {
          return t[index][innerFieldId];
        }
      }
      return;
    }
    if (typeof t === 'boolean') {
      return t;
    }
  }
};

export const checkLastItemValue: (
  arr: Array<Record<string, unknown>>,
) => boolean = (arr) => {
  const last = arr[arr.length - 1] as Record<string, unknown>;
  return Object.values(last).some((v) => Boolean(v) === true);
};

export const transformData = (
  keys: Array<string>,
  config: FormConfigType,
  value: Record<string, unknown>,
) => {
  return keys.map((k, i) => {
    const field = config.fields[i];
    if (!['Row', 'Table', 'Info', 'WYSIWYG'].includes(field.type)) {
      return {
        id: field.id,
        title: field.title,
        type: `${field.type}Value`,
        value: {
          [`${decapitalizeFirstLetter(field.type)}Value`]: getFieldValue(
            field.type,
            value[k] as string,
          ),
        },
      };
    }
    if (['WYSIWYG'].includes(field.type)) {
      return {
        id: field.id,
        title: field.title,
        type: `WYSIWYGValue`,
        value: {
          wysiwygValue: getFieldValue(field.type, value[k] as string),
        },
      };
    }
    if (['Info'].includes(field.type)) {
      return {
        id: field.id,
        title: field.title,
        type: 'InfoValue',
        description: field?.description,
      };
    }
    if (['Row'].includes(field.type)) {
      const rowValue = Object.values(
        (value[k] as Record<string, unknown>[])[0],
      ).map((v, idx) => {
        if (field.fields) {
          return {
            id: field.fields[idx].id,
            title: field.fields[idx].title,
            type: `${field.fields[idx].type}Value`,
            value: {
              [`${decapitalizeFirstLetter(field.fields[idx].type)}Value`]:
                getFieldValue(field.type, v as string),
            },
          };
        }
        return null;
      });
      return {
        id: field.id,
        title: field.title,
        type: 'RowValue',
        value: {
          rowValue,
        },
      };
    }
    if (['Table'].includes(field.type) && field.columns) {
      const tableValue = (value[k] as Record<string, unknown>[]).map(
        (val, id) => {
          const rowValue = Object.values(
            (value[k] as Record<string, unknown>[])[id],
          ).map((v, idx) => {
            if (field.columns) {
              return {
                id: field.columns[idx].id,
                title: field.columns[idx].title,
                type: `${field.columns[idx].type}Value`,
                value: {
                  [`${decapitalizeFirstLetter(field.columns[idx].type)}Value`]:
                    getFieldValue(field.type, v as string),
                },
              };
            }
            return null;
          });
          return {
            id: _.uniqueId('row'),
            title: _.uniqueId('ROW'),
            type: 'RowValue',
            value: {
              rowValue,
            },
          };
        },
      );
      return {
        id: field.id,
        title: field.title,
        type: 'TableValue',
        value: {
          tableValue,
        },
      };
    }
    return null;
  });
};

const getFieldValue = (type: string, value: string | number) => {
  if (type === 'Date') {
    return moment(value as string).toISOString();
  }
  if (type === 'Number') {
    return Number(value);
  }
  return value;
};

export type DataType = string | Record<string, string | number>[];

export const isDeeplyEmpty: (
  obj:
    | string
    | number
    | DefaultTableValue
    | Record<string, unknown>
    | Array<string>
    | Array<DefaultTableValue>,
) => boolean = (obj) => {
  if (_.isObject(obj)) {
    return _.every(obj, (value) => isDeeplyEmpty(value as DefaultTableValue));
  }
  if (_.isArray(obj)) {
    return _.isEmpty(obj) || obj.every((item) => isDeeplyEmpty(item));
  }
  return _.isEmpty(_.toString(obj).trim());
};

export const removeUnwantedRowFields = (data: Record<string, DataType>) => {
  const keys = Object.keys(data);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const value = data[keys[i]];
    if (Array.isArray(value)) {
      if (value.length > 1) {
        const lastItem = value[value.length - 1];
        if (typeof lastItem === 'object') {
          data = {
            ...data,
            [keys[i]]: value.filter((v) => {
              if (
                Object.values(v).filter((val) => val === undefined).length !==
                Object.keys(v).length
              )
                return true;
              return false;
            }),
          };
        }
      }
    }
  }
  return data;
};
