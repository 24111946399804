import { ErrorIcon } from 'src/assets/icons';
import { Column, Text } from 'src/components';
import { theme } from 'src/utils';
import { ReloadButton } from './styled';

export const ErrorComponent = () => {
  return (
    <Column 
      width="100%" 
      height="100%"
      gap={1} 
      align="center" 
      justify="center"
    >
      <ErrorIcon 
        width="100%" 
        stroke={theme.grey[500]} 
        strokeWidth="0" 
        fill={theme.grey[500]} 
      />
      <Column width="max-content" align="center">
        <Text size="sm" weight="bold" color={theme.grey[500]}>
          An error has occured
        </Text>
        <ReloadButton
          width="100%"
          onClick={() => window.location.reload()}
        >
          Reload
        </ReloadButton>
      </Column>
    </Column>
  );
};
