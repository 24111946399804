import moment from "moment";
import { Calendar } from "react-date-range";
import { ArrowDown } from "src/assets/icons";
import { DatePickerProps } from "src/types";
import { theme } from "src/utils";
import useClickAway from "src/utils/useClickAway";
import { DropdownTrigger } from "../Dropdown/styled";
import { Column } from "../Flex";
import { DatePickerWrapper } from "./styled";

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  width,
  setDate,
  position
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);
  

  const handleChange = (dateD: Date) => {
    setDate(dateD)
    setIsComponentVisible(!isComponentVisible);
  }


  return (
    <Column
      position="relative"
      gap={0.5}
      ref={ref}
      width="unset"
      height="100%"
    >
      <DropdownTrigger
        background={theme.grey[100]}
        width={width || "100%"}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        aria-expanded={isComponentVisible}
      >
        {moment(date).calendar({
          lastDay: '[Yesterday]',
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastWeek: '[last] dddd',
          nextWeek: 'dddd',
          sameElse: 'L'
        })}
        <ArrowDown />
      </DropdownTrigger>
      {isComponentVisible && (
        <DatePickerWrapper wrapperPosition={position}>
          <Calendar 
            date={date}
            onChange={handleChange}
          />
        </DatePickerWrapper>
      )}
    </Column>
  )
};
