/* eslint-disable no-underscore-dangle */
import { getModalParams, removeEmptyValues, removeModalHash } from 'src/utils';
import { createContext, useEffect, useState } from 'react';
import { HMOEntity, Invoice, Medications, Staff } from 'src/types';
import { useAlert } from 'src/state';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  DELETE_INVOICE,
  UPDATE_INVOICE,
  GET_INVOICES_TOTAL,
} from 'src/constants';
import { Button } from 'src/components';
import mixpanel from 'mixpanel-browser';
import { InventoryModal } from '../styled';
import OpenInvoice from './OpenInvoice';
import InvoiceConfirmation from './InvoiceConfirmation';
import HistoryTable from './HistoryTable';
import InvoicePreview from './InvoicePreview';

type ViewType = 'open' | 'confirmation' | 'discard' | 'history' | 'preview';

type ViewContextType = {
  view: ViewType;
  setView: React.Dispatch<React.SetStateAction<ViewType>>;
  patient: PatientInfo | undefined;
  setPatient: React.Dispatch<React.SetStateAction<PatientInfo | undefined>>;
  staff: Staff | undefined;
  setStaff: React.Dispatch<React.SetStateAction<Staff | undefined>>;
  currentInvoice: Invoice;
  setCurrentInvoice: React.Dispatch<React.SetStateAction<Invoice>>;
};

export type PatientInfo = {
  id: string;
  firstName: string;
  lastName: string;
  hmo: HMOEntity;
  inJourney?: boolean;
  medications?: Medications[];
  // openInvoice?: Invoice;
};

export const ViewContext = createContext<ViewContextType>(
  {} as ViewContextType,
);

const InventoryInvoice = () => {
  const { showAlert, removeAlert } = useAlert();
  const modalView = getModalParams('mView') as ViewType;
  const [view, setView] = useState<ViewType>(modalView);
  const [patient, setPatient] = useState<PatientInfo>();
  const [staff, setStaff] = useState<Staff>();
  const [currentInvoice, setCurrentInvoice] = useState<Invoice>({} as Invoice);
  const client = useApolloClient();

  useEffect(() => {
    setView(modalView);
  }, [modalView]);

  const [deleteInvoice] = useMutation(DELETE_INVOICE, { errorPolicy: 'all' });
  const [updateInvoice] = useMutation(UPDATE_INVOICE, { errorPolicy: 'all' });

  const onDiscard = () => {
    mixpanel.track(`Click 'Discard Invoice' button`, {feature: 'Inventory Invoice'})
    client.cache.updateQuery(
      {
        query: GET_INVOICES_TOTAL,
        variables: { input: { createdFrom: 'INVENTORY', status: 'UNPAID' } },
      },
      (data) => {
        if (data) {
          return {
            getInvoices: {
              ...data.getInvoices,
              total: data.getInvoices.total - 1,
            },
          };
        }
        return { getInvoices: {} };
      },
    );
    deleteInvoice({
      variables: {
        invoiceId: currentInvoice._id,
      },
      refetchQueries: [GET_INVOICES_TOTAL],
    });
    removeAlert();
  };

  const onKeep = () => {
    mixpanel.track(`Click 'Save Invoice' button`, {feature: 'Inventory Invoice'})
    const extraFields = {
      requestedBy: staff?.id,
      patient: patient?.id,
    };

    updateInvoice({
      variables: {
        input: {
          note: currentInvoice.note,
          ...removeEmptyValues(extraFields),
        },
        invoiceId: currentInvoice._id,
      },
      refetchQueries: [GET_INVOICES_TOTAL],
    });
    removeAlert();
  };

  const openAlert = () =>
    showAlert({
      type: 'decision',
      title: 'Close Invoice',
      message:
        'You are about to close this invoice. Do you want to keep this invoice for later or discard it?',
      confirmButton: (
        <Button onClick={onKeep} width="max-content">
          Keep Invoice
        </Button>
      ),
      closeButton: (
        <Button variant="cancel" onClick={onDiscard} width="max-content">
          Discard Invoice
        </Button>
      ),
    });
  return (
    <InventoryModal
      handleDismiss={() => {
      mixpanel.track(`Click 'Close Dialog' button`, {feature: 'Inventory Invoice'})
        if (view === 'discard') return;
        if (
          (view === 'open' || view === 'confirmation') &&
          currentInvoice._id
        ) {
          openAlert();
        }
        removeModalHash();
      }}
    >
      <ViewContext.Provider
        value={{
          view,
          setView,
          patient,
          setPatient,
          staff,
          setStaff,
          currentInvoice,
          setCurrentInvoice,
        }}
      >
        {view === 'open' && <OpenInvoice />}
        {view === 'confirmation' && <InvoiceConfirmation />}
        {view === 'history' && <HistoryTable />}
        {view === 'preview' && <InvoicePreview />}
      </ViewContext.Provider>
    </InventoryModal>
  );
};

export default InventoryInvoice;
