import useSearch from 'src/hooks/useSearch';
import { DocumentNode } from 'graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import { OperationVariables, useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import useClickAway from 'src/utils/useClickAway';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { DropdownContainer, DropdownItem } from '../Dropdown/styled';
import {
  DropdownLabelContainer,
  DropdownSearchWrapper,
  LabelContainer,
} from '../DropdownSearch/styled';
import { Input, Row, SearchInput, Text } from '..';

interface Data {
  data: Record<string, unknown>[];
  total: number;
}

type QueryOptionType = {
  [key: string]:
    | string
    | string[]
    | boolean
    | Record<string, string | string[]>;
};

interface AsyncSearchInputType {
  name: string;
  background?: string;
  hasSearchIcon?: boolean;
  placeholder?: string;
  value?: string;
  label?: string;
  emptyText?: string;
  data?: OperationVariables;
  query: DocumentNode;
  autoFocused?: boolean;
  fetchLimit?: number;
  queryOptions?: QueryOptionType;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  readOnly?: boolean;
  dataTestId?: string;
  refetchOnMount?: boolean;
  isStatic?: boolean;
  elementRef?: React.MutableRefObject<HTMLInputElement | null>;
  selectors: {
    dataSelector: string;
    totalSelector: string;
    valueSelector: string;
    labelSelector: string | string[];
  };
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  render?: (item: Record<string, unknown>) => React.ReactNode | undefined;
  handleSelect: (
    e: React.ChangeEvent<HTMLInputElement>,
    d?: Record<string, unknown>,
  ) => void;
  handleBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AsyncSearchInput: React.FC<AsyncSearchInputType> = ({
  data,
  name,
  query,
  value,
  label,
  readOnly,
  fetchLimit,
  required,
  background,
  error: errorMsg,
  emptyText,
  selectors,
  placeholder,
  handleChange,
  handleSelect,
  handleBlur,
  hasSearchIcon,
  dataTestId,
  disabled,
  render,
  queryOptions,
  elementRef,
  refetchOnMount,
  autoFocused,
  isStatic,
}) => {
  const [filteredData, setFilteredData] = useState<Data>();
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const limit = 10;
  const [fetchMoreData, { loading: fetchMoreLoading, error: fetchMoreError }] =
    useLazyQuery(query, { errorPolicy: 'all' });
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);

  const {
    data: queryData,
    loading,
    refetch,
  } = useQuery(query, {
    variables: {
      input: {
        page: 1,
        limit: fetchLimit || 10,
        search: '',
        ...(queryOptions || {}),
      },
    },
    skip: !!data || isStatic,
    errorPolicy: 'all',
    onCompleted(d) {
      setFilteredData({
        data: _.get(d, selectors.dataSelector),
        total: _.get(d, selectors.totalSelector),
      });
    },
  });

  const fetchData = useCallback(
    async (input: { limit?: number; page: number; search?: string }) => {
      await fetchMoreData({
        variables: {
          input: {
            ...input,
            ...(queryOptions || {}),
          },
        },
        onCompleted(d) {
          if (fetchMoreError) {
            setError('Unable to fetch');
          } else if (input.page === 1) {
            setFilteredData({
              data: _.get(d, selectors.dataSelector),
              total: _.get(d, selectors.totalSelector),
            });
          } else {
            setFilteredData((prev) => ({
              ...prev,
              data: [
                ...(prev?.data || []),
                ...(_.get(d, selectors.dataSelector) || []),
              ],
              total: _.get(d, selectors.totalSelector),
            }));
          }
        },
      });
    },
    [
      fetchMoreData,
      fetchMoreError,
      queryOptions,
      selectors.dataSelector,
      selectors.totalSelector,
    ],
  );

  useEffect(() => {
    if (isComponentVisible && refetchOnMount) {
      refetch();
    }
    if (data) {
      setFilteredData({
        data: _.get(data, selectors.dataSelector),
        total: _.get(data, selectors.totalSelector),
      });
    }
  }, [
    isComponentVisible,
    refetch,
    refetchOnMount,
    data,
    selectors.dataSelector,
    selectors.totalSelector,
  ]);

  const { handleSearchChange, searchLoading, searchValue } = useSearch(query, {
    onCompleted: (d: unknown) => {
      setFilteredData({
        data: [..._.get(d, selectors.dataSelector)],
        total: _.get(d, selectors.totalSelector),
      });
    },
  });
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilteredData({
        data: _.get(queryData || data, selectors.dataSelector),
        total: _.get(queryData || data, selectors.totalSelector),
      });
    }
    if (isStatic) {
      const d = _.get(data, selectors.dataSelector)?.filter(
        (row: Record<string, unknown>) => {
          const lbl =
            typeof selectors.labelSelector === 'string'
              ? (_.get(row, selectors.labelSelector) as string)
              : getLabel(row, selectors.labelSelector);
          return lbl
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(e.target.value.toLowerCase().replace(/\s+/g, ''));
        },
      );
      setFilteredData({ data: d || [], total: data?.total || 0 });
      return;
    }
    handleSearchChange(e, {
      page: 1,
      limit: fetchLimit || 10,
      ...queryOptions,
    });
  };

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef.current?.focus();
    setIsComponentVisible(false);
    const selectedItem = filteredData?.data.find(
      (d) => d[selectors.valueSelector] === e.target.id,
    );
    handleSelect(e, selectedItem);
  };

  const fetchMore = async () => {
    if (isStatic) return;
    const currentPage = Math.ceil((filteredData?.data.length || 0) / limit);
    await fetchData({
      limit: fetchLimit || 10,
      page: currentPage + 1,
      search: searchValue,
    });
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: searchLoading || false,
    hasNextPage:
      (filteredData?.data?.length || 0) < (filteredData?.total || 0) || false,
    onLoadMore: fetchMore,
    rootMargin: '0px 0px 200px 0px',
  });

  const getLabel = (row: Record<string, unknown>, slctr: string[]) => {
    const labels = slctr.map((selector) => _.get(row, selector));
    return labels.join(' ');
  };

  return (
    <DropdownContainer width="100%" ref={ref}>
      {hasSearchIcon ? (
        <SearchInput
          ref={elementRef || inputRef}
          required={required}
          background={background}
          disabled={disabled}
          autoComplete="off"
          label={label}
          name={name}
          autoFocus={autoFocused}
          value={value}
          readOnly={readOnly}
          onBlur={handleBlur}
          data-testid={dataTestId}
          error={error || errorMsg}
          placeholder={placeholder}
          onFocus={() => {
            if (readOnly) return;
            setIsComponentVisible(true);
          }}
          onChange={(e) => {
            handleChange?.(e);
            onSearchChange(e);
            setIsComponentVisible(true);
          }}
        />
      ) : (
        <Input
          ref={elementRef || inputRef}
          label={label}
          required={required}
          disabled={disabled}
          background={background}
          autoComplete="off"
          data-testid={dataTestId}
          name={name}
          autoFocus={autoFocused}
          value={value}
          readOnly={readOnly}
          onBlur={handleBlur}
          error={error || errorMsg}
          placeholder={placeholder}
          onFocus={() => {
            if (readOnly) return;
            setIsComponentVisible(true);
          }}
          onChange={(e) => {
            handleChange?.(e);
            onSearchChange(e);
            setIsComponentVisible(true);
          }}
        />
      )}
      {isComponentVisible && (
        <DropdownSearchWrapper>
          <LabelContainer ref={rootRef}>
            {(searchLoading || loading) && (
              <Row
                width="100%"
                justify="center"
                data-testid="search-dropdown-loader"
              >
                Loading...
              </Row>
            )}
            {!searchLoading &&
              filteredData?.data &&
              filteredData.data.map((row, idx) => {
                const val = _.get(row, selectors.valueSelector) as string;
                const lbl =
                  typeof selectors.labelSelector === 'string'
                    ? (_.get(row, selectors.labelSelector) as string)
                    : getLabel(row, selectors.labelSelector);

                return (
                  <Row ref={sentryRef} key={idx} data-testid="dropdown-item">
                    <DropdownItem
                      type="radio"
                      id={val}
                      value={val}
                      name={name}
                      onChange={onSelect}
                      onClick={() => setIsComponentVisible(false)}
                      onBlur={handleBlur}
                    />
                    <DropdownLabelContainer
                      data-testid={`item-${lbl?.toLowerCase()}`}
                      htmlFor={val}
                    >
                      {!render ? <Text>{lbl}</Text> : render(row)}
                    </DropdownLabelContainer>
                  </Row>
                );
              })}
            {fetchMoreLoading && (
              <Row width="100%" justify="center">
                Loading...
              </Row>
            )}
          </LabelContainer>
          {!filteredData?.data?.length &&
            !searchLoading &&
            !fetchMoreLoading &&
            !loading && (
              <Row width="100%" justify="center">
                {emptyText || 'No Data'}
              </Row>
            )}
        </DropdownSearchWrapper>
      )}
    </DropdownContainer>
  );
};
