import { Button, Column, ErrorComponent, H6, Text } from 'src/components';
import { useLocation } from 'wouter';
import { useContext } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { strictProductValidation, getModalParams } from 'src/utils';
import { useFormik } from 'formik';
import { Product } from 'src/types';
import Validator from 'validatorjs';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { SolidCheck } from 'src/assets/icons';
import { GET_PRODUCT, GET_PRODUCTS } from 'src/constants';
import { useQuery } from '@apollo/client';
import { useInventoryApis } from 'src/store';
import { useToast } from 'src/state';
import { ViewContext } from '.';
import ProductForm from '../components/ProductForm';

const EditProduct = () => {
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const productId = getModalParams('id');
  const { editProduct, editProductLoading } = useInventoryApis();
  const { showToast } = useToast();

  const { data, error: productError } = useQuery(GET_PRODUCT, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: !productId,
    variables: {
      input: productId,
    },
  });

  const product = data?.getProduct as Product;

  const goBack = () => {
    setView('details');
    setLocation(`#product&id=${productId}&mView=details`);
  };

  const { handleChange, handleSubmit, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        details: {
          name: product?.name,
          brandName: product?.brandName,
          categories: product?.categories,
          extraInfo: product?.extraInfo,
          form: product?.form,
          isOTC: product?.isOTC,
        },
      },
      validateOnChange: false,
      enableReinitialize: true,
      validate: (validationValues) => {
        const validation = new Validator(
          validationValues,
          strictProductValidation,
        );
        validation.passes();
        return validation.errors.errors;
      },
      onSubmit: async (value) => {
        await editProduct({
          variables: {
            productId,
            input: { ...value.details },
          },
          refetchQueries: [
            GET_PRODUCTS,
            { query: GET_PRODUCT, variables: { input: productId } },
          ],
          awaitRefetchQueries: true,
          onCompleted(d) {
            if (d?.editProduct) {
              showToast(d.editProduct?.message, 'success');
              goBack();
            }
          },
        });
        return value;
      },
    });

  if (productError) {
    return <ErrorComponent />;
  }

  return (
    <ViewWrapper goBack={goBack}>
      <Column gap={1.5}>
        <Column gap={0.3}>
          <H6 modStyles={{ ma: 0 }}>Edit Product</H6>
          <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
            Make changes to this product details
          </Text>
        </Column>
        <FullWidthForm onSubmit={handleSubmit}>
          <ProductForm
            values={values.details}
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={handleChange}
          />
          <Button
            variant="solid"
            width="max-content"
            type="submit"
            data-testid="save-product-edit-btn"
            isLoading={editProductLoading}
            modStyles={{ ml: 'auto' }}
            noIconStyles
          >
            <SolidCheck /> Save Edit
          </Button>
        </FullWidthForm>
      </Column>
    </ViewWrapper>
  );
};

export default EditProduct;
