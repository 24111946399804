export const CreditCardIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
  >
    <path
      d="M5.54036 12.3737H7.91536C8.12533 12.3737 8.32669 12.2903 8.47516 12.1418C8.62362 11.9934 8.70703 11.792 8.70703 11.582C8.70703 11.3721 8.62362 11.1707 8.47516 11.0222C8.32669 10.8738 8.12533 10.7904 7.91536 10.7904H5.54036C5.3304 10.7904 5.12904 10.8738 4.98057 11.0222C4.83211 11.1707 4.7487 11.3721 4.7487 11.582C4.7487 11.792 4.83211 11.9934 4.98057 12.1418C5.12904 12.2903 5.3304 12.3737 5.54036 12.3737ZM15.0404 4.45703H3.95703C3.32714 4.45703 2.72305 4.70725 2.27765 5.15265C1.83225 5.59805 1.58203 6.20214 1.58203 6.83203V13.957C1.58203 14.5869 1.83225 15.191 2.27765 15.6364C2.72305 16.0818 3.32714 16.332 3.95703 16.332H15.0404C15.6703 16.332 16.2743 16.0818 16.7197 15.6364C17.1651 15.191 17.4154 14.5869 17.4154 13.957V6.83203C17.4154 6.20214 17.1651 5.59805 16.7197 5.15265C16.2743 4.70725 15.6703 4.45703 15.0404 4.45703ZM15.832 13.957C15.832 14.167 15.7486 14.3684 15.6002 14.5168C15.4517 14.6653 15.2503 14.7487 15.0404 14.7487H3.95703C3.74707 14.7487 3.5457 14.6653 3.39724 14.5168C3.24877 14.3684 3.16536 14.167 3.16536 13.957V9.20703H15.832V13.957ZM15.832 7.6237H3.16536V6.83203C3.16536 6.62207 3.24877 6.4207 3.39724 6.27224C3.5457 6.12377 3.74707 6.04036 3.95703 6.04036H15.0404C15.2503 6.04036 15.4517 6.12377 15.6002 6.27224C15.7486 6.4207 15.832 6.62207 15.832 6.83203V7.6237Z"
      fill={color || '#626262'}
    />
  </svg>
);
