import { Button } from "src/components"
import { theme, font } from "src/utils"
import { StyledButtonProps } from "../types"

export const StyledButton: React.FC<StyledButtonProps> = ({ children, onClick }) => {
  return (
    <Button 
      onClick={onClick} 
      noIconStyles 
      width="max-content"
      variant='text' 
      background={theme.red[500]}
      modStyles={{ py: 0.25, px: 0.25, fontSize: font.sizes.xs }}
    >
      {children}
    </Button>
  )
}