/* eslint-disable no-underscore-dangle */
import { Column, H6 } from 'src/components';
import { inventoryInvoiceColumn } from 'src/configs/tableColumns';
import { useInventoryInvoices } from 'src/store/inventory-invoices';
import { ActionTable } from 'src/pages/admin/styled';
import { Invoice } from 'src/types';
import { useContext } from 'react';
import { useLocation } from 'wouter';
import InventoryHistoryFilters from '../components/HistoryFilters';
import { ViewContext } from '.';

const HistoryTable = () => {
  const [, setLocation] = useLocation();
  const {
    filteredData,
    isLoading,
    setPage,
    page,
    fetchNewpage,
    onSearchChange,
    filters,
    setFilters,
    getFilters,
    applyFilters,
    removeFilter,
    clearFilters,
  } = useInventoryInvoices();
  const { setView } = useContext(ViewContext);

  return (
    <Column width="100%" height="100%" minHeight="70vh" minWidth="50vw" gap={1}>
      <Column gap={0.3}>
        <H6 modStyles={{ ma: 0 }}>Invoice History</H6>
      </Column>
      <InventoryHistoryFilters
        onSearchChange={onSearchChange}
        filters={filters}
        setFilters={setFilters}
        getFilters={getFilters}
        applyFilters={applyFilters}
        removeFilter={removeFilter}
        clearFilters={clearFilters}
        setIsModalOpen={() => {
          return {};
        }}
      />
      <ActionTable
        fullHeight
        data={filteredData.invoices as unknown as Record<string, unknown>[]}
        isLoading={isLoading}
        columns={inventoryInvoiceColumn}
        handleRowClick={(row) => {
          const inv = row as unknown as Invoice;
          if (inv?.status === 'PAID') {
            setView('preview');
            setLocation(`#inventory-invoice&mView=preview&id=${inv?._id}`);
            return;
          }
          setView('open');
          setLocation(
            `#inventory-invoice&mView=open&id=${
              inv._id
            }&type=${inv?.invoiceType?.toLowerCase()}`,
          );
        }}
        pagination={{
          page,
          size: filteredData?.invoices?.length || 0,
          total: filteredData?.total || 0,
        }}
        handleGoToPage={(index) => {
          setPage(index);
          if (index !== page) {
            fetchNewpage(index);
          }
        }}
      />
    </Column>
  );
};

export default HistoryTable;
