import { useQuery } from '@apollo/client';
import * as React from 'react';
import { GET_USER } from 'src/constants';
import { UserProps } from 'src/types';
import { useAuth } from '../auth';
import UserContext from './context';

const UserProvider: React.FC = ({ children }) => {

  const initialUser: UserProps = {
    getUser: {
      id: '',
      firstName: '',
      lastName: '',
      fullName: '',
      emails: [],
      address: '',
      profileAvatar: '',
      isTourComplete: true,
      isPasswordSet: false,
      password: '',
      staff: []
    }
  };

  const [user, setUser] = React.useState<UserProps>(initialUser);
  const { state } = useAuth();
  useQuery(GET_USER, {
    skip: !state.token,
    onCompleted: (data) => {
      setUser(data);
    }
  });

  return (
    <UserContext.Provider value={{user, setUser}}>{children}</UserContext.Provider>
  );
};

export default UserProvider;