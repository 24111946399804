import { DocumentIcon } from 'src/assets/icons';
import { Button, IconButton, Row } from 'src/components';
import { useToast } from 'src/state';
import { openModal } from 'src/utils';

interface Props {
  proof: string;
};

export const SettlementActionColumn: React.FC<Props> = ({ proof }) => {
  const { showToast } = useToast();
  const file = proof?.split("/")?.slice(-1)?.[0];
  const fileExtension = file?.split(".", 2)[1];
  const isDisplayable = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(fileExtension);

  return (
    <Row justify="center">
      {isDisplayable ? (
        <IconButton
          variant="text"
          className="document-icon"
          onClick={(e) => {
            e.stopPropagation();
            openModal("settlement-info", `&url=${proof}`);
          }}
        >
          <DocumentIcon />
        </IconButton>
      ) : (
        <Button
          variant='text'
          width="max-content"
          className="document-icon"
          modStyles={{ px: 0, py: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            showToast("File is being downloaded", "success");
          }}
        >
          <a download href={proof}>
            <DocumentIcon />
          </a>
        </Button>
      )}
    </Row>
  );
};