import { gql } from '@apollo/client';

export const stateFieldsFragment = gql`
  fragment stateFields on State {
    id: _id
    createdAt
    description
    isCompleted
    metadata
    special
    updatedAt
  }
`;

export const stepFieldsFragment = gql`
  fragment StepFields on Step {
    id: _id
    updatedAt
    stepId
    status
    createdAt
    stepTemplate {
      id: _id
      name
      assigned {
        id: _id
        name
      }
    }
    parameters {
      description
      type
      text
      staffGroup {
        id: _id
        name
      }
      staff {
        id: _id
        user {
          firstName
          lastName
        }
      }
    }
    nextSteps {
      stepTemplateId
      _id
      createdAt
      description
      name
      parameters {
        type
        title
        entryType
      }
      actions {
        title
        tag
      }
    }
    facility {
      id: _id
    }
    eventLogs {
      nextState {
        id: _id
        createdAt
        description
        isCompleted
        metadata
        special
        updatedAt
      }
      previousState {
        id: _id
        createdAt
        description
        isCompleted
        metadata
        special
        updatedAt
      }
      staff {
        id: _id
        user {
          firstName
          lastName
        }
      }
    }
    actions {
      id: _id
      createdAt
      description
      isCompleted
      metadata
      special
      updatedAt
      tag
    }
    patient {
      id: _id
      user {
        firstName
        emails
        lastName
        profileAvatar
      }
    }
  }
`;
