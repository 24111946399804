import { gql } from '@apollo/client';
import { patientFieldsFragment } from '../Fragments/patient';
import { familyFieldsFragment } from '../Fragments/family';

export const GET_PATIENT_BASIC_INFO = gql`
  query GetPatient($patientId: ID!) {
    getPatient(patientId: $patientId) {
      ...PatientFields
    }
  }
  ${patientFieldsFragment}
`;

export const GET_PATIENT_VITALS_CHART_DATA = gql`
  query GetVitalsChart($input: GetVitalsChartInput!) {
    getVitalsChart(input: $input) {
      id
      title
      type
      createdAt
      ... on RowData {
        id
        title
        type
        rowValue: value {
          id
          title
          type
          ... on TextData {
            id
            title
            type
            textValue: value
          }
          ... on SelectData {
            id
            title
            type
            selectValue: value
          }
        }
      }
      ... on TextData {
        id
        title
        type
        textValue: value
      }
      ... on NumberData {
        id
        title
        type
        numberValue: value
      }
    }
  }
`;

export const GET_PATIENTS = gql`
  query GetPatients($query: GetPatientsInput) {
    getPatients(query: $query) {
      patients {
        ...PatientFields
      }
      total
    }
  }
  ${patientFieldsFragment}
`;

export const SEARCH_PATIENTS = gql`
  query SearchPatients($input: SearchPatientsInput!) {
    searchPatients(input: $input) {
      patients {
        ...PatientFields
      }
      total
    }
  }
  ${patientFieldsFragment}
`;

export const CHECK_IF_PATIENT_EXISTS = gql`
  query CheckIfPatientExists($query: CheckIfPatientExistsInput!) {
    checkIfPatientExists(query: $query) {
      patients {
        ...PatientFields
      }
    }
  }
  ${patientFieldsFragment}
`;

export const REQUEST_FACILITY_ACCESS_TO_PATIENT = gql`
  query Query($patientId: ID!) {
    requestAccessToPatientData(patientId: $patientId)
  }
`;

export const GET_PATIENT_DATA = gql`
  query GetPatient($patientId: ID!) {
    getPatient(patientId: $patientId) {
      ...PatientFields
      family {
        ...FamilyFields
      }
    }
  }
  ${familyFieldsFragment}
  ${patientFieldsFragment}
`;
