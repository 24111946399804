/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import { InventoryState } from 'src/types';

const initialState = {} as InventoryState;

type PayloadType = {
  payload: InventoryState;
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setProducts: (state: InventoryState, action: PayloadType) => {
      const { products, total } = action.payload;
      state.products = products;
      state.total = total;
    },
  },
});

export const { setProducts } = inventorySlice.actions;

export default inventorySlice.reducer;
