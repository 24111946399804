import { OperationVariables, useMutation, useQuery } from "@apollo/client";
import { 
  CREATE_FAMILY,
  EDIT_FAMILY,
  GET_FAMILY,
  GET_PATIENT_DATA,
  REMOVE_FAMILY,
  SEARCH_FAMILIES
} from "src/constants";
import { useToast } from "src/state";
import { 
  CreateFamilyProps, 
  EditFamilyProps, 
  GetFamilyProps, 
  RemoveFamilyProps, 
  SearchFamilyProps 
} from "src/types/family.type";


const useFamily = () => {
  const {showToast} = useToast()

  const useCreateFamily = () => {
    const [execute, result] = useMutation<CreateFamilyProps>(CREATE_FAMILY);
    const createFamily = (value?: OperationVariables) => {
      return execute({
        variables: { input: value },
        refetchQueries: [GET_FAMILY],
        awaitRefetchQueries: true,
        onCompleted: (data) => showToast(data.createFamily.message, 'success'),
      });
    }

    return { ...result, execute, createFamily };
  }


  const useRemoveFamilyMember = () => {
    const [execute, result] = useMutation<RemoveFamilyProps>(REMOVE_FAMILY);
    const removeFamilyMember = (value: string) => {
      return execute({
        variables: { patientId: value },
        refetchQueries: [
          GET_FAMILY, 
          GET_PATIENT_DATA,
          SEARCH_FAMILIES
        ],
        awaitRefetchQueries: true,
        onCompleted: (data) => showToast(data.removeFamily.message, 'success'),
      });
    }

    return { ...result, execute, removeFamilyMember };
  }


  const useEditFamily = () => {
    const [execute, result] = useMutation<EditFamilyProps>(EDIT_FAMILY);
    const editFamily = (value?: OperationVariables) => {
      return execute({
        variables: value,
        refetchQueries: [GET_FAMILY],
        awaitRefetchQueries: true,
        onCompleted: (data) => showToast(data.editFamily.message, 'success'),
      });
    }

    return { ...result, execute, editFamily };
  }


  const useSearchFamily = (value: OperationVariables, skip?: boolean) => {
    const result = useQuery<SearchFamilyProps>(SEARCH_FAMILIES, {
      errorPolicy: "all",
      skip,
      variables: { input: value }
    });

    return { ...result };
  };


  const useGetFamily = (value: string, skip?: boolean) => {
    const result = useQuery<GetFamilyProps>(GET_FAMILY, {
      errorPolicy: "all",
      skip,
      variables: { familyId: value }
    });

    return { ...result };
  };


  return {
    useCreateFamily,
    useEditFamily,
    useGetFamily,
    useRemoveFamilyMember,
    useSearchFamily
  }
}

export { useFamily };