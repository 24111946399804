import * as React from 'react';
import { GetStaffGroups } from 'src/types';
import StaffGroupContext from './context';

const StaffGroupsAndPermsProvider: React.FC = ({ children }) => {

  const state = {
    id: ``,
    name: ``
  };

  const initialState: GetStaffGroups = {
    getStaffGroups: {
      staffGroups: [
        { ...state, suggestedPermissions: [{ ...state }] },
        { ...state, suggestedPermissions: [{ ...state }] },
        { ...state, suggestedPermissions: [{ ...state }] },
      ],
      total: 0,
    },
  };
  
  const [staffGroups, setStaffGroups] = React.useState<GetStaffGroups>(initialState);

  return (
    <StaffGroupContext.Provider value={{ staffGroups, setStaffGroups }}>
      {children}
    </StaffGroupContext.Provider>
  );
};

export default StaffGroupsAndPermsProvider;
