import {
  AsyncSearchInput,
  Button,
  Column,
  H6,
  Row,
  Text,
} from 'src/components';
import { getModalParams, removeModalHash, theme } from 'src/utils';
import { useJourneyApis, usePatientJourney } from 'src/store';
import { GET_STEP_TEMPLATES } from 'src/constants';
import { Assigned, StepTemplate } from 'src/types';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { TemplateLoader } from '../PatientJourney/Empty';
import Parameter from '../PatientJourney/Parameter';
import { useFormControls } from '../PatientJourney/hooks/useFormControls';
import { filterStepTemplates } from '../PatientJourney/NewStep';
import { TemplateButton } from '../PatientJourney/styled';

const NewStep = () => {
  const { hasPermission } = useStaff();
  const patientId = getModalParams('patientId')?.split('?')[0];
  const { patientJourney } = usePatientJourney(patientId);
  const { stepTemplates, mostUsedStepTemplates } = useJourneyApis();
  const {
    step,
    search,
    loading,
    template,
    onAddItem,
    checkInLoading,
    parameters,
    onParamBlur,
    onParamChange,
    onParamSelect,
    validationErrors,
    handleSearchBlur,
    handleSearchChange,
    handleSearchSelect,
    findStepTemplateError,
    loadingTemplate,
    setParameters,
    getParameters,
    setStep,
    setSearch,
  } = useFormControls();

  const allStep = patientJourney?.steps.map((s) => s.stepTemplate.name);
  const availableTemplates = filterStepTemplates(
    stepTemplates,
    allStep as string[],
  );
  const mostUsed = filterStepTemplates(
    mostUsedStepTemplates,
    allStep as string[],
  );

  return (
    <Column width="100%" height="100%" gap={1.5} minWidth="36vw">
      <Column gap={0.3}>
        <H6 modStyles={{ ma: 0 }}>New Step</H6>
        <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
          Add a new step to user journey
        </Text>
      </Column>
      <Column width="100%" gap={0.3}>
        <AsyncSearchInput
          hasSearchIcon
          name="step"
          emptyText="No step found"
          placeholder="Search or choose step"
          dataTestId="search-step"
          value={search}
          readOnly={!!template}
          disabled={!hasPermission('VIEW_STEP_LIST')}
          query={GET_STEP_TEMPLATES}
          handleSelect={handleSearchSelect}
          handleChange={handleSearchChange}
          handleBlur={handleSearchBlur}
          isStatic
          data={{
            total: (stepTemplates?.total as number) || 0,
            data:
              (availableTemplates as unknown as Record<string, unknown>[]) ||
              [],
          }}
          selectors={{
            dataSelector: 'data',
            valueSelector: 'id',
            labelSelector: 'name',
            totalSelector: 'total',
          }}
        />
        {mostUsed && (
          <Column gap={0.3}>
            <Text size="sm">Frequently Added Steps:</Text>
            <Row gap={0.5} wrap="wrap" width="max-content">
              {search === '' &&
                mostUsed?.map((el: StepTemplate) => (
                  <TemplateButton
                    modStyles={{ br: 0.5 }}
                    variant="outlined"
                    width="max-content"
                    key={el.id}
                    onClick={() => {
                      mixpanel.track(`Clicked step from most used options`, {
                        feature: 'In-Patients',
                      });
                      setStep(el as unknown as undefined);
                      setParameters(getParameters(el as unknown as never));
                      setSearch(el.name);
                    }}
                  >
                    {el.name}
                  </TemplateButton>
                ))}
            </Row>
          </Column>
        )}
      </Column>
      {!loadingTemplate && findStepTemplateError && (
        <Text>Error Loading Template...</Text>
      )}
      {loadingTemplate && !step && !findStepTemplateError && <TemplateLoader />}
      {step && !loadingTemplate && !findStepTemplateError && (
        <Column gap={0.3} background={theme.primary[100]} modStyles={{ pa: 1 }}>
          <Column gap={0.2}>
            <Text size="base" weight="bold" modStyles={{ ma: 0 }}>
              {step?.name}
            </Text>
            <Text
              size="sm"
              weight="bold"
              color={theme.grey[650]}
              modStyles={{ ma: 0 }}
            >
              {step?.description}
            </Text>
          </Column>
          {parameters?.map((stp, idx) => (
            <Parameter
              assigned={step.assigned as unknown as Assigned[]}
              dataTestId="step-parameter"
              label={stp.description}
              key={idx}
              idx={idx}
              type={stp.type}
              error={validationErrors[idx]}
              required={stp.required}
              value={parameters[idx].displayValue}
              onChange={onParamChange}
              onSelect={onParamSelect}
              onBlur={onParamBlur}
            />
          ))}
          <Column gap={1}>
            <Row align="center">
              <Text color={theme.grey[650]} weight="bold">
                Tasks in this Step
              </Text>{' '}
              <Text
                weight="bold"
                color={theme.white[100]}
                modStyles={{
                  ml: 0.5,
                  px: 0.6,
                  py: 0.3,
                  br: 0.5,
                  bg: theme.black[100],
                }}
              >
                {step?.actions?.length}
              </Text>
            </Row>
            {step?.actions?.map((action, idx) => (
              <Text
                key={idx}
                color={theme.black[100]}
                size="sm"
                modStyles={{ ma: 0 }}
              >
                {action.title}
              </Text>
            ))}
          </Column>
        </Column>
      )}
      <Row justify="space-between" modStyles={{ mt: parameters ? 3 : 8 }}>
        <Button
          width="max-content"
          modStyles={{ mr: 0.7 }}
          background="neutral"
          color="black"
          onClick={() => removeModalHash()}
        >
          Cancel
        </Button>
        {hasPermission('ADD_STEPS_TO_A_JOURNEY') && (
          <Button
            width="max-content"
            data-testid="add-step"
            modStyles={{ mr: 0.7 }}
            disabled={!step}
            isLoading={loading || checkInLoading}
            onClick={() => {
              mixpanel.track(
                `Click '${
                  template === 'CHECKOUT' && step?.actions?.length === 0
                    ? 'Check Out'
                    : 'Add Step'
                }' button`,
                { feature: 'In-Patients' },
              );
              onAddItem();
            }}
          >
            {template === 'CHECKOUT' && step?.actions?.length === 0
              ? 'Check Out'
              : 'Add Step'}
          </Button>
        )}
      </Row>
    </Column>
  );
};

export default NewStep;
