import { gql } from '@apollo/client';


export const userFieldsFragment = gql`
  fragment UserFields on User {
    id: _id
    firstName
    lastName
    fullName
    emails
    address
    profileAvatar
    isTourComplete
    settings {
      email {
        whenTagged
        taskReminders
        paymentMade
      }
    }
  }
`;