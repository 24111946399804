import { Column, Dropdown, H6, Row, Text } from 'src/components';
import { getModalParams, theme } from 'src/utils';
import { TurnLeftDownIcon } from 'src/assets/icons';
import { useLocation } from 'wouter';
import { Fragment, useContext, useEffect, useState } from 'react';
import { StepStatus, usePatientJourney } from 'src/store';
import { PatientJourneyType, StepData } from 'src/types';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import {
  AddStepButton,
  Circle,
  SemiCircle,
  StepStart,
  VerticalLine,
} from './styled';
import StepListItem from './StepListItem';
import { ViewContext } from '.';
import { FILTERS, FILTER_COLORS, stepStatusMap } from './constants';
import NoSteps, { StepsLoader } from './Empty';

interface AllStepsProps {
  journey: PatientJourneyType;
  journeyId?: string;
  isPastJourney?: boolean;
}

export const getCompletedActions = (step: StepData) =>
  step?.actions?.reduce((acc, action) => {
    if (action.isCompleted) acc += 1;
    return acc;
  }, 0);

const getUrl = (stepId: string, journeyId: string) => {
  if (journeyId) {
    return `#journey&id=${journeyId}&mView=step&stepId=${stepId}`;
  }
  return `#journey&mView=step&stepId=${stepId}`;
};

const AllSteps: React.FC<AllStepsProps> = ({
  journey,
  journeyId,
  isPastJourney,
}) => {
  const pId = getModalParams('patientId');
  const patientId = journey?.patient?.id || pId;
  const { filterStepsInJourney, fetchPatientOpenJourneyLoading = true } =
    usePatientJourney(patientId);
  const { hasPermission } = useStaff();
  const [filter, setFilter] = useState('All');
  const [steps, setSteps] = useState(filterStepsInJourney('All', journey));
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);

  const allStep = filterStepsInJourney('All', journey);
  useEffect(() => {
    setSteps(allStep);
  }, [allStep]);

  return (
    <Column width="100%" height="100%" gap={1.5} minWidth="36vw">
      <Column gap={0.3}>
        <H6 modStyles={{ ma: 0 }}>Patient Journey</H6>
        <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
          Click on the cards to see step Information
        </Text>
      </Column>
      <Dropdown
        width="8.6875rem"
        name="filter"
        handleDropdownChange={(n: string, v: string) => {
          setFilter(v);
          setSteps(filterStepsInJourney(v as StepStatus, journey));
        }}
        labelComponent={(label) => (
          <Row align="center">
            <Row
              width="0.75rem"
              height="0.75rem"
              modStyles={{ br: '50%', mr: 0.3, bg: FILTER_COLORS[label] }}
            />
            <Text>{label}</Text>
          </Row>
        )}
        options={FILTERS.map((f) => ({ ...f, checked: f.value === filter }))}
      />
      {steps && (
        <Column
          align="center"
          height="100%"
          background={theme.primary[100]}
          modStyles={{ pa: 1, br: 0.6875 }}
        >
          {fetchPatientOpenJourneyLoading && <StepsLoader />}
          {steps.length === 0 && !fetchPatientOpenJourneyLoading && <NoSteps />}
          {!!steps.length && !fetchPatientOpenJourneyLoading && (
            <>
              <Column
                align="center"
                height="max-content"
                maxHeight="35rem"
                modStyles={{ overflowY: 'auto' }}
              >
                {isPastJourney ? (
                  <Circle />
                ) : (
                  <>
                    <StepStart>
                      <TurnLeftDownIcon />
                      <Text modStyles={{ my: 0, ml: 0.3 }}>Begins</Text>
                    </StepStart>
                    <SemiCircle shouldRotate />
                  </>
                )}

                {steps?.map((step, idx) => (
                  <Fragment key={idx}>
                    {isPastJourney && idx === 0 ? (
                      <VerticalLine height={0.1} />
                    ) : (
                      <VerticalLine />
                    )}
                    <StepListItem
                      idx={idx}
                      title={step.stepTemplate?.name}
                      status={stepStatusMap[step.status] as StepStatus}
                      completedCount={getCompletedActions(step)}
                      taskCount={step.actions.length}
                      url={getUrl(step.id, journeyId as string)}
                      step={step}
                      patientId={patientId}
                      isPastJourney={isPastJourney}
                    />
                  </Fragment>
                ))}
              </Column>
              {isPastJourney ? <VerticalLine height={0.1} /> : <VerticalLine />}
              {isPastJourney ? <Circle /> : <SemiCircle />}
              {!isPastJourney && hasPermission('ADD_STEPS_TO_A_JOURNEY') && (
                <AddStepButton
                  data-testid="add-step-to-journey"
                  onClick={() => {
                    mixpanel.track(`Click 'Add Step' button`, {
                      feature: 'Patient Journey',
                    });
                    setView('newStep');
                    setLocation(`#journey&mView=newStep`);
                  }}
                >
                  Add Step
                </AddStepButton>
              )}
            </>
          )}
        </Column>
      )}
    </Column>
  );
};
export default AllSteps;
