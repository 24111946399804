import React from 'react';
import { OperationVariables, useMutation, useQuery } from '@apollo/client';
import { 
  CREATE_STAFFGROUP, 
  DELETE_STAFFGROUP, 
  GET_STAFFGROUP, 
  GET_STAFFGROUPS, 
  GET_STAFFS, 
  PERMISSION_ACTION_MESSAGE, 
  UPDATE_STAFFGROUP 
} from 'src/constants';
import { CreateStaffGroup, DeleteStaffGroup, GetStaffGroups, UpdateStaffGroup } from 'src/types';
import StaffGroupContext from './context';
import { useStaff } from '../staff';
import { useToast } from '../toast';



const useStaffGroups = () => {
  const { showToast } = useToast();
  const { hasPermission } = useStaff();
  const { staffGroups, setStaffGroups } = React.useContext(StaffGroupContext);

  const useApolloStaffGroups = (skip?: boolean) => {
    const result = useQuery<GetStaffGroups>(GET_STAFFGROUPS, {
      errorPolicy: 'all',
      skip,
      onCompleted: (data) => setStaffGroups(data)
    });

    return { ...result, staffGroups };
  };


  const useStaffGroup = (skip?: boolean) => {
    const result = useQuery<GetStaffGroups>(GET_STAFFGROUP, {
      errorPolicy: 'all',
      skip
    });

    return { ...result };
  };


  const useCreateStaffGroup = () => {
    const [execute, result] = useMutation<CreateStaffGroup>(CREATE_STAFFGROUP);

    const createStaffGroup = (value?: OperationVariables) => {
      if (!hasPermission("CREATE_NEW_STAFF_GROUP")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
      return execute({
        variables: { input: value },
        refetchQueries: [GET_STAFFGROUPS],
        awaitRefetchQueries: true,
        onCompleted: (d) => showToast(d.createStaffGroup.message, 'success'),
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, createStaffGroup };
  };


  const useUpdateStaffGroup = () => {
    const [execute, result] = useMutation<UpdateStaffGroup>(UPDATE_STAFFGROUP);

    const updateStaffGroup = (value?: OperationVariables) => {
      if (!hasPermission("EDIT_STAFF_GROUP")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
      return execute({
        variables: { input: value },
        refetchQueries: [GET_STAFFGROUPS, GET_STAFFS],
        awaitRefetchQueries: true,
        onCompleted: (d) => showToast(d.updateStaffGroup.message, 'success'),
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, updateStaffGroup };
  };


  const useDeleteStaffGroup = () => {
    const [execute, result] = useMutation<DeleteStaffGroup>(DELETE_STAFFGROUP);

    const deleteStaffGroup = (id: string) => {
      if (!hasPermission("DELETE_STAFF_GROUP")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
      return execute({
        variables: { deleteStaffGroupId: id },
        refetchQueries: [GET_STAFFGROUPS, GET_STAFFS],
        onCompleted: (d) => showToast(d.deleteStaffGroup.message, 'success'),
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, deleteStaffGroup };
  };


  return {
    useApolloStaffGroups,
    useStaffGroup,
    useCreateStaffGroup,
    useDeleteStaffGroup,
    useUpdateStaffGroup
  }
}

export { useStaffGroups };
