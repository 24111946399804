import * as React from 'react';
import { RecordTemplates } from 'src/types';
import RecordTemplateContext from './context';

const RecordTemplateProvider: React.FC = ({ children }) => {
  const [recordTemplates, setRecordTemplates] =
    React.useState<RecordTemplates>({} as RecordTemplates);

  return (
    <RecordTemplateContext.Provider
      value={{
        recordTemplates,
        setRecordTemplates,
      }}
    >
      {children}
    </RecordTemplateContext.Provider>
  );
};

export default RecordTemplateProvider;
