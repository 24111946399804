import { gql } from '@apollo/client';

export const GENERAL_SEARCH = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      total
      result {
        ... on RecordSearchType {
          _id
          template {
            title
          }
          patient {
            _id
          }
          createdAt
        }
        ... on PatientSearchType {
          _id
          createdAt
          user {
            firstName
            lastName
          }
        }
        ... on StaffSearchType {
          _id
          user {
            firstName
            lastName
          }
          createdAt
        }
        ... on AppointmentSearchType {
          _id
          createdAt
          type
        }
      }
    }
  }
`;
