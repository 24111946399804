import { gql } from '@apollo/client';

export const GET_ACTIVITIES_ANALYTICS = gql`
  query GetActivitiesAnalytics($startDate: DateTime!, $endDate: DateTime!) {
    getActivitiesAnalytics(startDate: $startDate, endDate: $endDate) {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
`;

export const GET_PATIENTS_STATISTICS = gql`
  query GetPatientStatistics($input: DateFilterInput!) {
    getPatientStatistics(input: $input) {
      male
      female
    }
  }
`;

export const GET_TOTAL_NUMBER_OF_STAFFS = gql`
  query GetTotalNumberOfStaff($input: DateFilterInput!) {
    getTotalNumberOfStaff(input: $input) {
      total
      inc
    }
  }
`;

export const GET_TOTAL_NUMBER_OF_PATIENTS = gql`
  query GetTotalNumberOfPatient($input: DateFilterInput!) {
    getTotalNumberOfPatients(input: $input) {
      total
      inc
    }
  }
`;

export const GET_TOTAL_AMOUNT_GENERATED = gql`
  query GetTotalAmountGenerated($input: DateFilterInput!) {
    getTotalAmountGenerated(input: $input) {
      total
      inc
    }
  }
`;

export const GET_STAFF_STATISTICS = gql`
  query GetStaffStatistics($input: DateFilterInput!) {
    getStaffStatistics(input: $input) {
      male {
        value
        key
      }
      female {
        value
        key
      }
    }
  }
`;

export const GET_INCOME_STATISTICS = gql`
  query GetIncomeStatistics($input: DateFilterInput!) {
    getIncomeStatistics(input: $input) {
      data {
        value
        key
      }
    }
  }
`;

export const GET_MOST_USED_SERVICES = gql`
  query GetMostUsedServices($input: DateFilterInput!) {
    getMostUsedServices(input: $input) {
      totalAmount
      description
      count
    }
  }
`;

export const GET_PATIENT_VISITATION = gql`
  query GetPatientVisitation($input: DateFilterInput!) {
    getPatientVisitation(input: $input) {
      total
      inc
    }
  }
`;

export const GET_HIGHEST_SERVICE_USED = gql`
  query GetHighestServiceUsed {
    getHighestServiceUsed {
      data {
        value
        key
      }
    }
  }
`;

export const GET_MOST_PROFITED_DAY = gql`
  query GetMostProfitedDay {
    getMostProfitedDay {
      data {
        value
        key
      }
    }
  }
`;

export const GET_MOST_BUSY_DAY = gql`
  query GetMostBusyDay {
    getMostBusyDay {
      data {
        value
        key
      }
    }
  }
`;