import { gql } from '@apollo/client';

export const invitedStaffFieldsFragment = gql`
  fragment InvitedStaffFields on InvitedStaff {
    invitedUser: user {
      emails
    }
    invitedUserPermissions: permissions {
      id: _id
      name
    }
    staffGroup {
      id: _id
      name
      permissions {
        id: _id
        name
      }
    }
    facility {
      _id
      state
    }
    invitationId
    status
  }
`;
