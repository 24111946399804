import React, { useState } from 'react';
import {
  Paragraph,
  Row,
  Column,
  Avatar,
  AvatarGroup,
  Select,
} from 'src/components';
import { AVATAR_PLACHOLDERS } from 'src/constants';
import { SendIcon } from 'src/assets/icons';
import { Modal } from 'src/containers';
import { removeModalHash } from 'src/utils/modalHelper';
import { FormFields } from 'src/types';
import { useFacilities, useStaffGroups, useStaffs } from 'src/state';
import Validator from 'validatorjs';
import { useFormik } from 'formik';
import {
  AddStaffButton,
  FullWidthForm,
  HiddenContainer,
  ModalTitle,
} from './styled';
import AddStaffsForm from './AddNewStaff';

export interface FacilityProps {
  state: string;
  id: string;
}

export type FormState = {
  staffs: FormFields[];
  facilityId: string;
};

export const defaultState = {
  staffs: [
    {
      email: '',
      staffGroup: '',
      permissions: [],
    },
  ],
  facilityId: '',
};

const InviteStaffModal: React.FC = () => {
  const { useApolloStaffGroups } = useStaffGroups();
  const {  staffGroups } = useApolloStaffGroups();
  const [activeTab, setActiveTab] = useState(0);
  const [formState, setFormState] = useState<FormState>(defaultState);
  const { useInviteStaff } = useStaffs();
  const { loading, inviteStaff } = useInviteStaff();
  const { useFacilities: useFacilitiesData } = useFacilities();
  const { initialFacilities, loading: facilitiesLoading } = useFacilitiesData();

  Validator.register(
    'already_exists',
    (value, req) => {
      const countOccurrences = (arr: string[], val: string) =>
        arr.filter((item) => item === val).length;
      const val = req.trim().split(',');

      return countOccurrences(val, value as string) < 2;
    },
    'The :attribute Enter a unique email address',
  );

  Validator.register(
    'staffgroup_exists',
    (value) => {
      const staffGroupTitles = staffGroups.getStaffGroups.staffGroups.map(
        (el) => el.name.toLowerCase(),
      );
      return !staffGroupTitles.includes((value as string).toLowerCase());
    },
    'The :attribute name is already in use',
  );

  const { handleChange, handleSubmit, setFieldValue, values, errors } =
    useFormik({
      initialValues: formState,
      validateOnChange: false,
      enableReinitialize: true,
      validate: (validationValues) => {
        const validation = new Validator(validationValues, {
          'staffs.*.email': [
            'required',
            'email',
            `already_exists: ${validationValues.staffs.map((v) => v.email)}`,
          ],
          'staffs.*.staffGroup': ['required', `staffgroup_exists: `],
          'staffs.*.permissions': 'at_least_one_item',
          facilityId: 'required',
        });
        validation.passes();
        return validation.errors.errors;
      },
      onSubmit: async (value) => {
        const result = await inviteStaff({
          invites: value.staffs,
          facilityId: value.facilityId,
        });
        if (result?.data) {
          removeModalHash();
        }
      },
    });

  return (
    <Modal>
      <Column gap={1}>
        <Column gap={0.2}>
          <ModalTitle>Invite a new staff</ModalTitle>
          <Row width="60%">
            <Paragraph>
              Invite your staff to get you started, you can set the staffGroups
              and individual permission of each member of your staff
            </Paragraph>
          </Row>
          <Row width="max-content" height="5rem">
            <AvatarGroup>
              {AVATAR_PLACHOLDERS.map((url, index) => (
                <Avatar key={index} src={url} size={40} />
              ))}
            </AvatarGroup>
          </Row>
        </Column>
        <FullWidthForm noValidate onSubmit={handleSubmit}>
          <Column gap={1.5}>
            <HiddenContainer display={activeTab === 0 ? 'flex' : 'none'}>
              <AddStaffsForm
                values={values}
                setFieldValue={setFieldValue}
                setFormState={setFormState}
                handleChange={handleChange}
                errors={errors}
              />
            </HiddenContainer>
            {activeTab === 1 && (
              <Select
                label="Select a facility to invite staff"
                name="facilityId"
                value={values.facilityId}
                onChange={handleChange}
                loading={
                  facilitiesLoading &&
                  initialFacilities?.searchFacilities?.total === 0
                }
                error={errors.facilityId && 'Facility is required'}
              >
                <option disabled value="">
                  Select a Facility
                </option>
                {initialFacilities?.searchFacilities?.total > 0 &&
                  initialFacilities?.searchFacilities?.facilities?.map(
                    (facility: FacilityProps) => (
                      <option key={facility.id} value={facility.id}>
                        {facility.state}
                      </option>
                    ),
                  )}
              </Select>
            )}
            <Row align="center" justify={activeTab === 0 ? "flex-end" : "space-between"} gap={2}>
              {activeTab === 1 && (
                <>
                  <AddStaffButton
                    type="button"
                    background="neutral"
                    color="black"
                    onClick={() => setActiveTab(0)}
                  >
                    Back
                  </AddStaffButton>
                  <AddStaffButton
                    type="submit"
                    isLoading={loading}
                    disabled={loading}
                  >
                    <SendIcon />
                    Send Invite
                  </AddStaffButton>
                </>
              )}
              {activeTab === 0 && (
                <AddStaffButton type="button" onClick={() => setActiveTab(1)}>
                  <SendIcon />
                  Proceed
                </AddStaffButton>
              )}
            </Row>
          </Column>
        </FullWidthForm>
      </Column>
    </Modal>
  );
};

export default InviteStaffModal;
