import { Column } from 'src/components';
import { TIMEOUT } from 'src/constants';
import { font, theme } from 'src/utils/variables';
import styled, { css, keyframes } from 'styled-components';

const slideOutRight = keyframes`
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const slideInRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;


const slideInTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const slideOutTop = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const ToastContainer = styled.div<{
  color: string;
  duration: number;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem;
  gap: 0.5rem;
  box-sizing: border-box;
  padding: 0.75rem;
  color: white;
  z-index: 99999;
  ${({ duration }) => {
    return css`
      animation: ${slideInRight} ${TIMEOUT}ms, ${slideOutRight} ${TIMEOUT}ms ${duration - TIMEOUT}ms forwards;
      @media (max-width: 960px) {
        animation: ${slideInTop} ${TIMEOUT}ms, ${slideOutTop} ${TIMEOUT}ms ${duration - TIMEOUT}ms forwards;
      }
  `}};
  ${({ color }) => {
    return css`
      background: ${theme[color][500]};
    `;
  }};
  border-radius: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  svg,
  path,
  * {
    stroke:white;
    color: white;
  }
  @media (max-width: 960px) {
    max-width: 100%;
    font-size: ${font.sizes.sm}
  }
`;

export const ToastWrapper = styled(Column)`
  position: fixed;
  z-index: 1000;
  top: 1rem;
  right: 1rem;
  @media (max-width: 960px) {
    right: 0;
    & > div {
      align-items: center;
      justify-items: center;
    }
  }

`;