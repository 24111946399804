import { OperationVariables, useMutation } from '@apollo/client';
import {
  ADD_RECORD,
  ADD_RECORD_TEMPLATE,
  GET_ALL_RECORD_TEMPLATE,
  GET_RECORDS,
  GET_RECORD_TEMPLATE,
  PIN_RECORD_TEMPLATE,
  UNPIN_RECORD_TEMPLATE,
} from 'src/constants';
import { useToast } from '../toast';


const useMutations = () => {
  const { showToast } = useToast();


  const usePinTemplate = () => {
    const [execute, result] = useMutation(PIN_RECORD_TEMPLATE);

    const pinTemplate = (value: string) => {
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.pinRecordTemplate.message, 'success'),
        refetchQueries: [GET_ALL_RECORD_TEMPLATE],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, pinTemplate };
  };

  const useUnPinTemplate = () => {
    const [execute, result] = useMutation(UNPIN_RECORD_TEMPLATE);

    const unPinTemplate = (value: string) => {
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.unPinRecordTemplate.message, 'success'),
        refetchQueries: [GET_ALL_RECORD_TEMPLATE],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, unPinTemplate };
  };



  const useAddRecordTemplate = () => {
    const [execute, result] = useMutation(ADD_RECORD_TEMPLATE);

    const addRecordTemplate = (value?: OperationVariables) => {
      return execute({
        variables: { input: value },
        refetchQueries: [GET_RECORD_TEMPLATE],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, addRecordTemplate };
  };

  const useAddRecord = () => {
    const [execute, result] = useMutation(ADD_RECORD);

    const addRecord = (value?: OperationVariables) => {
      return execute({
        variables: { input: value },
        refetchQueries: [GET_RECORDS],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, addRecord };
  };

  return {
    useAddRecordTemplate,
    useAddRecord,
    usePinTemplate,
    useUnPinTemplate
  };
};

export default useMutations;
