export const printContent = (
  ref: React.RefObject<HTMLDivElement>,
  styles?: string,
) => {
  const printContents = ref.current?.outerHTML;
  // const styledComponent = document.querySelector('[data-styled="active"]');
  const doc = `<html><head><link href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap" rel="stylesheet"><style>body {padding: 30px;-webkit-print-color-adjust: exact;font-family: 'Nunito', sans-serif;} ${styles} @media print {img {display:block;}}</style></head><body>${printContents}</body></html>`;
  const newWindow = window.open('', '', 'height=500, width=800');
  newWindow?.document.write(doc);
  newWindow?.document.close();
  setTimeout(() => {
    newWindow?.print();
    newWindow?.close();
  }, 500);
};
