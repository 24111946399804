import { FormikErrors, useFormikContext } from 'formik';
import { Column, Row, Input, Select, Paragraph } from 'src/components';
import { theme } from 'src/utils';
import { MultiRowForm } from 'src/containers';
import { DeleteIcon } from 'src/assets/icons';
import { CreateStepTemplate } from 'src/types';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import { useMultiRowParams } from 'src/hooks/useMultiRowParams';
import { ENTRY_TYPE, INFORMATION_TYPE } from 'src/constants';

const StepParameters = () => {
  const state = {
    type: "",
    title: "",
    entryType: ""
  };

  const {
    values,
    setValues,
    handleChange,
    errors
  } = useFormikContext<CreateStepTemplate>();
  
  const { parameters } = values;
  const { addNewRow, deleteRow } = useMultiRowParams<CreateStepTemplate>(
    'parameters',
    state,
    values, 
    setValues, 
  );

  const getErrorMessage = (idx: number, field: string) => {
    return (
      (errors as FormikErrors<Record<string, unknown>>)[
        `parameters.${idx}.${field}`
      ] &&
      (
        (errors as FormikErrors<Record<string, unknown>>)[
          `parameters.${idx}.${field}`
        ] as unknown as string[]
      )[0]
        .split(' ')
        .splice(2)
        .join(' ')
    );
  };


  return (
    <Column justify="space-between" gap={1}>
      <Paragraph color={theme.grey[500]}>
        Enter a list of information needed to start this step
      </Paragraph>
      <MultiRowForm
        shouldShowSuggestion={parameters[parameters.length - 1].type !== ''}
        onAddNewRow={addNewRow}
      >
        {parameters?.map(({ title, type, entryType }, index) => (
          <Column key={index}>
            <Row gap={1} align="flex-end">
              <Row
                align="center"
                justify="space-between"
                width="100%"
                gap={1}
              >
                <Select
                  label="Information type"
                  data-testid="information-type"
                  name={`parameters[${index}].type`}
                  value={type}
                  error={getErrorMessage(index, 'type')}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    Choose one
                  </option>
                  {INFORMATION_TYPE.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <Input
                  label="Information title"
                  data-testid="information-title"
                  placeholder="E.g What is wrong"
                  name={`parameters[${index}].title`}
                  value={title}
                  error={getErrorMessage(index, 'title')}
                  onChange={handleChange}
                />
                <Select
                  label="Entry type"
                  data-testid="entry-type"
                  name={`parameters[${index}].entryType`}
                  value={entryType}
                  error={getErrorMessage(index, 'entryType')}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    Select type
                  </option>
                  {ENTRY_TYPE.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </Row>
              <Row width="2.875rem">
                <MultiRowDeleteButton
                  variant="solid"
                  data-testid="delete-param-row"
                  background={theme.grey[100]}
                  color={theme.grey[200]}
                  size="2.5rem"
                  type="button"
                  onFocus={(e) => e.stopPropagation()}
                  onClick={() => deleteRow(index)}
                >
                  <DeleteIcon />
                </MultiRowDeleteButton>
              </Row>
            </Row>
          </Column>
        ))}
      </MultiRowForm>
    </Column>
  );
};

export default StepParameters;