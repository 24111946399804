import styled from 'styled-components';
import { H6, Column } from 'src/components';

export const ModalTitle = styled(H6)`
  font-size: 1.25rem;
  margin: 0
`;

export const ModalSizer = styled(Column)`
  width: 29.3rem;
  @media (max-width: 960px) {
    width: 100%
  }
`;