import { Column, H1 } from 'src/components';

const NotFound = () => {
  return (
    <Column height="100vh" justify="center" align="center">
      <H1>404</H1>
    </Column>
  );
};

export default NotFound;
