import * as React from 'react';
import { AlertContextType } from 'src/types';
import { Alert } from 'src/containers';
import AlertContext from './context';
import reducer, { initialState } from './reducer';
import actions from './actions';

const AlertProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const stateD: Pick<AlertContextType, 'state'> = React.useMemo(
    () => ({
      state,
    }),
    [state],
  );
  const dispatches: Omit<AlertContextType, 'state'> = React.useMemo(
    () => ({
      showAlert: actions.showAlert(dispatch),
      removeAlert: actions.removeAlert(dispatch),
    }),
    [dispatch],
  );

  return (
    <AlertContext.Provider value={{ ...stateD, ...dispatches }}>
      <Alert />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
