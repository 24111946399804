import { H6 } from 'src/components';
import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import { theme } from 'src/utils';
import styled from 'styled-components';

export const TodoModal = styled(Modal)`
  ${ModalChildContainer} {
    form {
      width: 100%;
    }
  }
`;

export const ModalTitle = styled(H6)`
  font-size: 1.25rem;
  color: ${theme.black[100]};
`;
