export const formatAsPercentage = (num: number | string) => {
  const value = Number(num);
  if (!Number.isFinite(value)) return ""
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(num) / 100);
}

export const isPositive = (num: number | string) => {
  const value = Number(num);
  if (!Number.isFinite(value) || Math.sign(value) === 0) return ""
  return Math.sign(value) === 1;
}