/* eslint-disable no-nested-ternary */
import { Column, Row, SearchInput } from 'src/components';
import {
  CalendarIconComponent,
  MedicalFileIcon,
  UserGroupIcon,
  // RecentIcon,
  UserThreeIcon,
} from 'src/assets/icons';
import { theme } from 'src/utils/variables';
import { useRef, useState } from 'react';
import { GENERAL_SEARCH } from 'src/constants/Queries/search';
import useSearch from 'src/hooks/useSearch';
import { useStaff, useToast } from 'src/state';
import useMediaQuery from 'src/utils/useMediaQuery';
import { SearchData } from 'src/types/search.type';
import { removeEmptyValues } from 'src/utils';
import { useLazyQuery } from '@apollo/client';
import {
  GlobalSearchModal,
  ModalTitle,
  PillButton,
  // RecentText,
  RowHeader,
  SearchContainer,
  WrappingContainer,
  // SearchLogo,
  // SearchResultContainer,
} from './styled';
import ResultLink, { ResultLinkType } from './ResultLink';
import { mapCallback } from './searchUtils';
import NoResult, { SearchLoader } from './Loader';
import Pagination from './Pagination';

type ResponseType = {
  search: {
    total: number;
    result: SearchData[];
  };
};

type TransformedDataType = {
  data: Omit<ResultLinkType, 'icon'>[];
  total: number;
};

const SearchModal = () => {
  const [searchResults, setSearchResults] = useState<TransformedDataType>(
    {} as TransformedDataType,
  );
  const [filters, setFilters] = useState({ searchIn: '' });
  const [page, setPage] = useState(1);
  const { showToast } = useToast();

  const pageLimit = 10;
  const searchRef = useRef<HTMLInputElement>(null);
  const { staff, hasPermission } = useStaff();
  const facilityId = staff && staff.getStaff.facility.id;
  const isMobile = !useMediaQuery('(min-width: 960px)');

  const [getFilteredSearch, { loading: filteredSearchLoading }] =
    useLazyQuery(GENERAL_SEARCH);

  const onCompleted = (d: unknown) => {
    const data = d as ResponseType;
    if (data) {
      setSearchResults({
        total: data.search.total,
        data: data.search.result.map(mapCallback),
      });
    } else {
      showToast('Unable to get search results, please try again', 'error');
    }
  };

  const { handleSearchChange, searchLoading, searchValue } = useSearch(
    GENERAL_SEARCH,
    {
      onCompleted,
    },
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e, {
      limit: pageLimit,
      page: 1,
      facility: facilityId,
      ...removeEmptyValues(filters),
    });
  };

  const handleFilterChange = (
    searchPage: number,
    currentFilters: { searchIn: string },
  ) => {
    setFilters(currentFilters);
    if (searchValue?.length === 0) return;
    // if (currentFilters.searchIn === filters.searchIn) return;
    getFilteredSearch({
      variables: {
        input: {
          limit: pageLimit,
          page: searchPage,
          search: searchValue,
          ...removeEmptyValues(currentFilters),
        },
      },
      onCompleted,
    });
  };

  const categories: {
    label: string;
    value: string;
    render: (color: string) => React.ReactNode | undefined;
    show: boolean;
  }[] = [
    {
      label: 'All',
      value: '',
      render: (color) => <MedicalFileIcon color={color} />,
      show: true,
    },
    {
      label: 'Records',
      value: 'Records',
      render: (color) => <MedicalFileIcon color={color} />,
      show: hasPermission("VIEW_PATIENT_RECORD"),
    },
    {
      label: 'Schedule',
      value: 'Schedule',
      render: (color) => <CalendarIconComponent color={color} />,
      show: true,
    },
    {
      label: 'Patients',
      value: 'Patients',
      render: (color) => <UserGroupIcon color={color} />,
      show: hasPermission("VIEW_PATIENT_RECORD"),
    },
    {
      label: 'Staff',
      value: 'Staff',
      render: (color) => <UserGroupIcon color={color} />,
      show: hasPermission('VIEW_STAFF_LIST') && !isMobile,
    },
  ];

  return (
    <GlobalSearchModal>
      <ModalTitle>Search</ModalTitle>
      <SearchContainer width="100%">
        <SearchInput
          ref={searchRef}
          autoFocus
          data-testid="global-search"
          placeholder="What would you like to search for?"
          onChange={onSearchChange}
        />
      </SearchContainer>
      <Column gap={1}>
        <RowHeader>Search results in</RowHeader>
        <WrappingContainer gap={1}>
          {categories.map(({ label, value, render, show }) => (
            <PillButton
              key={value}
              data-testid={value}
              active={value === filters.searchIn}
              onClick={() => handleFilterChange(1, { searchIn: value })}
              show={show}
            >
              {render(value === filters.searchIn ? theme.secondary[600] : '')}
              {label}
            </PillButton>
          ))}
        </WrappingContainer>
      </Column>
      <Column
        gap={0.25}
        width="100%"
        height="350px"
        data-testid="search-result"
        modStyles={{ overflowY: 'auto', mt: 1.5 }}
      >
        {searchValue &&
          searchResults?.total === 0 &&
          !(searchLoading || filteredSearchLoading) && <NoResult />}
        {(searchLoading || filteredSearchLoading) && <SearchLoader />}
        {!(searchLoading || filteredSearchLoading) &&
          searchResults.data &&
          searchResults.data?.map(
            ({ title, category: cat, date, url }, idx) => (
              <ResultLink
                key={`${date}-${idx}`}
                title={title}
                category={cat}
                date={date}
                icon={
                  cat === 'records' ? (
                    <MedicalFileIcon />
                  ) : cat === 'patients' || cat === 'staffs' ? (
                    <UserThreeIcon />
                  ) : (
                    <CalendarIconComponent />
                  )
                }
                url={url}
              />
            ),
          )}
      </Column>
      {searchResults.data && (
        <Row justify="center" align="center" modStyles={{ mt: 'auto' }}>
          <Pagination
            currentPage={page}
            pageSize={pageLimit}
            total={searchResults?.total}
            handleGoToPage={(index) => {
              setPage(index);
              if (index !== page) {
                handleFilterChange(index, filters);
              }
            }}
          />
        </Row>
      )}
    </GlobalSearchModal>
  );
};

export default SearchModal;
