import styled from 'styled-components';
import { theme } from 'src/utils/variables';
import { SearchIcon } from '../../assets/icons/index';
import { InputBase } from '../Input/styled';
import { Row } from '../Flex';

export const SearchInputBase = styled(InputBase)`
  padding-left: 2.5rem;
`;

export const SearchLogo = styled(SearchIcon)`
  position: absolute;
  left: 0;
  margin: 0 0.75rem;
  stroke: ${theme.grey[400]};
  z-index: 1;
`;

export const SearchContainer = styled(Row)`
  position: relative;
`;
