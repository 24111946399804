import Skeleton from 'react-loading-skeleton';
import { Column, SkeletonWrapper } from 'src/components';
import { TimelineImage } from 'src/assets/images';
import { EmptyText } from 'src/containers/VitalChart/styled';
import {
  ItemIndicator,
  LogsScrollContainer,
  VerticalLineSharp,
} from '../styled';

export const ProductLogsLoader: React.FC = () => {
  return (
    <LogsScrollContainer position="relative" width="100%">
      <Column style={{ marginLeft: '1.5rem' }} gap={2.5}>
        <VerticalLineSharp color="#d3d3d3" />
        <Column style={{ paddingRight: '2rem' }} gap={0.2}>
          <ItemIndicator color="#AEAEAE" />
          <SkeletonWrapper>
            <Skeleton height={50} width="50vw" />
          </SkeletonWrapper>
        </Column>
        <Column style={{ paddingRight: '2rem' }} gap={0.2}>
          <ItemIndicator color="#AEAEAE" />
          <SkeletonWrapper>
            <Skeleton height={50} width="50vw" />
          </SkeletonWrapper>
        </Column>
        <Column style={{ paddingRight: '2rem' }} gap={0.2}>
          <ItemIndicator color="#AEAEAE" />
          <SkeletonWrapper>
            <Skeleton height={50} width="50vw" />
          </SkeletonWrapper>
        </Column>
        <Column style={{ paddingRight: '2rem' }} gap={0.2}>
          <ItemIndicator color="#AEAEAE" />
          <SkeletonWrapper>
            <Skeleton height={50} width="50vw" />
          </SkeletonWrapper>
        </Column>
        <Column style={{ paddingRight: '2rem' }} gap={0.2}>
          <ItemIndicator color="#AEAEAE" />
          <SkeletonWrapper>
            <Skeleton height={50} width="50vw" />
          </SkeletonWrapper>
        </Column>
      </Column>
    </LogsScrollContainer>
  );
};

export const NoLogs: React.FC = () => {
  return (
    <Column align="center" gap={1} justify="center" height="100%" width="100%">
      <TimelineImage />
      <EmptyText align="center">No logs found</EmptyText>
    </Column>
  );
};
