import styled from 'styled-components';
import { Row } from 'src/components';

export const RouteWrapper = styled(Row)`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const ComponentWrapper = styled(Row)<{
  hasSidebar: boolean
}>`
  width: 100vw;
  position: relative;
  & > div: first-child {
    margin-left: ${(props) => props.hasSidebar ? '8.125rem' : '0'};
  }
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 0;
    & > div:first-child {
      flex: 1;
      margin-left: 0;
      max-height: ${(props) => props.hasSidebar ? 'calc(100vh - 88px)' : '100%'};
      flex-direction: column;
    }
  }
`;

export const PaddingContainer = styled(Row)`
  padding: 1rem 1rem 0 1rem;
  position: sticky:
  top: 0;
`;

