import moment from "moment"
import ReactDatePicker from "react-datepicker"
import { CalendarInputIcon } from "src/assets/icons"
import { Row } from "src/components"
import { getRelativeDate } from "src/utils"
import { MonthYearPickerButton } from "./styled"

interface CustomWeekPickerProps {
  date: Date;
  loading?: boolean;
  setDate: React.Dispatch<React.SetStateAction<Date>>
}


export const CustomWeekPicker: React.FC<CustomWeekPickerProps> = ({ date, setDate, loading }) => {
  return (
    <Row width="max-content">
      <ReactDatePicker 
        selected={date}
        onChange={(dDate) => setDate(dDate as Date)}
        showWeekNumbers
        calendarStartDay={1}
        dayClassName={(dDate: Date) => {
          const weekNumber = moment(dDate).isoWeek()
          const currentWeekNumber = moment(date).isoWeek()
          return weekNumber === currentWeekNumber ? 'react-datepicker__day--selected' : ""}}
        showPopperArrow={false}
        disabled={loading}
        popperPlacement="bottom-end"
        customInput={
          <MonthYearPickerButton 
            modStyles={{ px: 0.94, py: 0.62 }} 
            variant="outlined" 
            background="white"
            width="max-content"
          >
            <CalendarInputIcon />
            {getRelativeDate(date)}
          </MonthYearPickerButton>
        }
      />
    </Row>
  )
}