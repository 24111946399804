import { Link } from 'wouter';
import styled from 'styled-components';
import { font, theme } from 'src/utils/variables';
import { LinkProps } from 'src/types';

export const StyledLink = styled(Link)<LinkProps>`
  font-size: ${(props) => props.size || font.sizes.base};
  color: ${(props) => props.color || theme.secondary[600]};
  font-weight: ${(props) => props.weight || font.weights.normal};
  text-decoration: none;
  display: inline-block;
`;
