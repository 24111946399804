export const openModal = (modalPath: string, id?: string, params?: string) => {
  window.location.hash = modalPath + (id ? `&id=${id}` : '') + (params ?? '');
};

export const removeModalHash = () => {
  window.location.hash = '';
  const noHashURL = window.location.href.replace(/#.*$/, '');
  window.history.replaceState('', document.title, noHashURL);
};

export const getModalParams = (value: string) => {
  const searchParams = new URLSearchParams(window.location.hash);
  return searchParams.get(value) as string;
};

export const getURLParams = (value: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(value) as string;
};
