import Skeleton from 'react-loading-skeleton';
import { Column, SkeletonWrapper, Row } from 'src/components';

export const FormLoader: React.FC = () => {
  return (
    <Column gap={2} width="100%">
      <Column gap={1}>
        <Skeleton width={200} height={40} />
        <Skeleton width={300} height={10} />
      </Column>
      <SkeletonWrapper>
        <Skeleton width="100%" height={60} />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton width="100%" height={60} />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton width="100%" height={60} />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton width="100%" height={60} />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton width="100%" height={60} />
      </SkeletonWrapper>
      <Skeleton width={100} height={40} />
    </Column>
  );
};

export const RecordLoader: React.FC = () => {
  return (
    <Column gap={2} width="100%">
      <Row>
        <Skeleton width={100} height={40} style={{ marginRight: '1rem' }} />
        <Skeleton width={100} height={40} />
      </Row>
      <Row width="100%" justify="space-between">
        <Skeleton width={100} height={20} style={{ marginRight: '1rem' }} />
        <Skeleton width={300} height={20} />
      </Row>
      <SkeletonWrapper>
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
      </SkeletonWrapper>
    </Column>
  );
};
