import { createSlice } from '@reduxjs/toolkit';
import { StepData, StepStatus } from 'src/types';
import { RootState } from '..';

export type InitialState = {
  steps: StepData[];
  total: number;
};

const initialState = {} as InitialState;

type PayloadType = {
  payload: {
    data: InitialState;
  };
};

const stepsSlice = createSlice({
  name: 'all-steps',
  initialState,
  reducers: {
    setSteps: (state: InitialState, action: PayloadType) => {
      const { data } = action.payload;
      state.steps = data.steps;
      state.total = data.total;
    },
    addStep: (state: InitialState, action: { payload: { step: StepData } }) => {
      const { step } = action.payload;
      if (state.steps) {
        state.steps = [step, ...state.steps];
        state.total += 1;
      }
    },
    deleteStep: (
      state: InitialState,
      action: { payload: { stepId: string } },
    ) => {
      const { stepId } = action.payload;
      if (state.steps) {
        state.steps = state.steps.filter((s) => s.id !== stepId);
        state.total -= 1;
      }
    },
    updateStepStatus: (
      state: InitialState,
      action: {
        payload: { stepId: string; status: StepStatus };
      },
    ) => {
      const { stepId, status } = action.payload;
      const index = state.steps.findIndex((s) => s.id === stepId);
      const stepToUpdate = state.steps[index];
      state.steps.splice(index, 1);
      stepToUpdate.status = status;
      state.steps = [stepToUpdate, ...state.steps];
    },
    updateStepActionItem: (
      state: InitialState,
      action: {
        payload: {
          stepId: string;
          arrIdx: number;
          checked: boolean;
          step: StepData;
        };
      },
    ) => {
      const { stepId, arrIdx, checked, step } = action.payload;
      const stepIndex = state.steps.findIndex((s) => s.id === stepId);
      let stepToUpdate = state.steps[stepIndex];
      state.steps.splice(stepIndex, 1);
      stepToUpdate = {
        ...stepToUpdate,
        status: checked ? 'STARTED' : stepToUpdate.status,
        eventLogs: step.eventLogs,
        updatedAt: step.updatedAt,
        actions: stepToUpdate.actions.map((a, idx) =>
          idx === arrIdx ? { ...a, isCompleted: checked } : a,
        ),
      };
      state.steps = [stepToUpdate, ...state.steps];
    },
  },
});

export const getSteps = (state: RootState) => {
  return state?.allSteps.steps;
};

export const getStep = (stepId: string) => (state: RootState) => {
  return state?.allSteps?.steps?.find((step) => step.id === stepId);
};

export const {
  setSteps,
  addStep,
  deleteStep,
  updateStepStatus,
  updateStepActionItem,
} = stepsSlice.actions;

export default stepsSlice.reducer;
