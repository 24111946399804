import * as React from 'react';
import { FeaturePermissionState, Perms } from 'src/types/perms.type';
import PermsContext from './context';


const PermsProvider: React.FC = ({ children }) => {
  const [perms, setPerms] = React.useState<Perms>({} as Perms);
  const [permissionConfig, setPermissionConfig] = React.useState<FeaturePermissionState[]>([])

  return (
    <PermsContext.Provider value={{perms, permissionConfig, setPerms, setPermissionConfig }}>
      {children}
    </PermsContext.Provider>
  );
};

export default PermsProvider;