import { NotificationIcon } from 'src/assets/icons';
import { useNotification } from 'src/state';
import { DotIndicator } from '../DotIndicator';
import { NotificationIconContainer } from './styled';

export const NotificationsDot = () => {
  const { unreadSize } = useNotification();

  return (
    <NotificationIconContainer>
      <NotificationIcon />
      {unreadSize > 0 && <DotIndicator color="red">{unreadSize}</DotIndicator>}
    </NotificationIconContainer>
  );
};
