import React from 'react';
import { RecordTemplates } from 'src/types';

export type RecordTemplateContextType = {
  recordTemplates: RecordTemplates;
  setRecordTemplates: React.Dispatch<React.SetStateAction<RecordTemplates>>;
};

const RecordTemplateContext = React.createContext(
  {} as RecordTemplateContextType,
);

export default RecordTemplateContext;
