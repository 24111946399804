export const setLocalStorage = (key: string, value: unknown) => {
    return localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
    const data = localStorage.getItem(key);
    if (!data) return null;
    const item = JSON.parse(data);
    return item
};

export const removeLocalStorage = (key: string) => {
    return localStorage.removeItem(key)
}
