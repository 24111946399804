import React, { useEffect, useRef } from 'react';
import { Column, Row } from 'src/components';
import { TabContextType, TabItemProps } from 'src/types';
import { useLocation } from 'wouter';
import { TabHeaderBase, TabHeaderItemButton } from './styled';

export const TabContext = React.createContext<TabContextType>(
  {} as TabContextType,
);

export const Tabs: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState<number | string>(0);
  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      <Column height="inherit" gap={1}>
        {children}
      </Column>
    </TabContext.Provider>
  );
};

export const TabContent: React.FC = ({ children }) => {
  return (
    <Row height="inherit" id="content">
      {children}
    </Row>
  );
};

export const TabContentItem: React.FC<TabItemProps> = ({ id, children }) => {
  const { activeTab } = React.useContext(TabContext);
  return activeTab === id ? <>{children}</> : null;
};

export const TabHeaders: React.FC<{
  urlKey?: string;
  urlAccessible?: boolean;
}> = ({ urlKey, urlAccessible, children }) => {
  const { setActiveTab } = React.useContext(TabContext);

  const firstRef = useRef(
    React.Children.toArray(children)[0] as React.ReactElement<TabItemProps>,
  );
  const searchParams = new URLSearchParams(window.location.search);
  const urlTab = searchParams.get(urlKey || '');

  useEffect(() => {
    const id = firstRef.current && firstRef.current?.props?.id;
    if (urlTab && urlAccessible) setActiveTab(urlTab);
    else if (id) setActiveTab(id);
  }, [setActiveTab, urlAccessible, urlTab]);

  return <TabHeaderBase>{children}</TabHeaderBase>;
};

export const TabHeaderItem: React.FC<TabItemProps> = ({
  id,
  children,
  urlKey,
  urlAccessible,
  dataTestId,
}) => {
  const [, setLocation] = useLocation();

  const { activeTab, setActiveTab } =
    React.useContext<TabContextType>(TabContext);
  return (
    <TabHeaderItemButton
      active={activeTab === id}
      data-testid={dataTestId}
      onClick={() => {
        if (urlAccessible) {
          setLocation(`?${urlKey}=${id}`);
        }
        return setActiveTab(id);
      }}
    >
      {React.Children.map(children, (item) => {
        const child = item as React.ReactElement;
        if (typeof child === 'string') {
          return child;
        }
        return React.cloneElement(child, {
          active: activeTab === id,
        });
      })}
    </TabHeaderItemButton>
  );
};
