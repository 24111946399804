import { gql } from '@apollo/client';

export const doughnutReportFieldsFragment = gql`
  fragment DoughnutReportFieldData on DoughnutReportFieldData {
    data {
      color
      label
      value
      tooltipData
    }
    total
    totalLabel
    label
    chartType
    hasFilter
    filterType
  }
`;

export const verticalReportFieldsFragment = gql`
  fragment VerticalBarReportFieldData on VerticalBarReportFieldData {
    baseColor
    chartType
    data {
      y {
        color
        label
        type
        value
      }
      x
      tooltipData
      color
    }
    filterType
    hasFilter
    label
    variant
    yExtra {
      label
      total
      type
    }
  }
`;

export const pieChartReportFieldsFragment = gql`
  fragment PieReportFieldData on PieReportFieldData {
    chartType
    hasFilter
    filterType
    data {
      color
      label
      value
    }
    label
  }
`;

export const lineChartReportFieldsFragment = gql`
  fragment LineGraphReportFieldData on LineGraphReportFieldData {
    chartType
    hasFilter
    filterType
    label
    data {
      x
      y {
        color
        label
        type
        value
      }
    }
    baseColor
  }
`;

export const tableReportFieldsFragment = gql`
  fragment TableReportFieldData on TableReportFieldData {
    chartType
    filterType
    hasFilter
    headers
    isPaginated
    label
    rows
    subRows
  }
`;

export const cardReportFieldsFragment = gql`
  fragment CardReportFieldData on CardReportFieldData {
    chartType
    hasFilter
    filterType
    value
    valueUnit
    increment
    incrementUnit
    label
    labelIcon
  }
`;
