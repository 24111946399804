import { Facility, StethoscopeIcon, TimeIcon } from 'src/assets/icons';
import { theme } from 'src/utils';
import { TimelineTag } from '../../styled';
import { RecordTitle, RowWrap, TableRowWrapper } from '../styled';

const titleColors: { [key: string]: { bg: string; color: string } } = {
  activity: {
    bg: '#F8FBFB',
    color: '#117785',
  },
  record: {
    bg: '#f1fff5',
    color: theme.green[400],
  },
  journey: {
    bg: '#FFFDF8',
    color: theme.accent[600],
  },
};

export type RecordMetaType = {
  title: string;
  time: string;
  provider?: string;
  facility?: string;
  type: 'activity' | 'record' | 'journey';
};

const RecordMeta: React.FC<RecordMetaType> = ({
  time,
  type,
  title,
  provider,
  facility,
}) => {
  return (
    <RowWrap width="100%" justify="space-between">
      <RecordTitle
        data-testid="record-title"
        bg={titleColors[type].bg}
        color={titleColors[type].color}
      >
        {title}
      </RecordTitle>
      <TableRowWrapper width="fit-content">
        <TimelineTag marginRight={1.25}>
          <TimeIcon />
          {time}
        </TimelineTag>
        {provider && (
          <TimelineTag marginRight={1.25}>
            <StethoscopeIcon />
            {provider}
          </TimelineTag>
        )}
        {facility && (
          <TimelineTag>
            <Facility />
            {facility}
          </TimelineTag>
        )}
      </TableRowWrapper>
    </RowWrap>
  );
};

export default RecordMeta;
