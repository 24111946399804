import React from 'react';
import { AlertContextType } from 'src/types';
import { initialState } from './reducer';

const AlertContext = React.createContext<AlertContextType>({
  state: initialState,
  showAlert: () => null,
  removeAlert: () => null,
});

export default AlertContext;