import React, { useEffect, useState } from 'react';
import { MultiRowFormProps } from 'src/types';
import {
  MultiRowFormHolder,
  MultiRowFormTableHeader,
  MultiRowTable,
} from './styled';

export const MultiRowForm: React.FC<MultiRowFormProps> = ({
  children,
  onAddNewRow,
  shouldShowSuggestion,
  options,
}) => {
  const [lastChild, setLastChild] = useState<
    React.ReactChild | React.ReactFragment | React.ReactPortal | null
  >(null);

  useEffect(() => {
    if (!lastChild) {
      const item: React.ReactChild | React.ReactFragment | React.ReactPortal =
        React.Children.toArray(children)[React.Children.count(children) - 1];

      setLastChild(item);
    }
  }, [children, lastChild]);

  return !options?.isTable ? (
    <>
      {children}
      {shouldShowSuggestion && (
        <MultiRowFormHolder data-testid="last-row-input" onFocus={onAddNewRow}>
          {lastChild}
        </MultiRowFormHolder>
      )}
    </>
  ) : (
    <MultiRowTable>
      {options.headers && (
        <thead>
          <tr>
            {options.headers.map((title, i) => (
              <MultiRowFormTableHeader key={`${title}-${i}`}>
                {title}
              </MultiRowFormTableHeader>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {children}
        {shouldShowSuggestion &&
          lastChild &&
          React.cloneElement(lastChild as React.ReactElement, {
            onFocus: onAddNewRow,
            style: { opacity: 0.5 },
            ...options?.moreProps,
          })}
      </tbody>
    </MultiRowTable>
  );
};
