import { gql } from '@apollo/client';

export const RECORD_TEMPLATE_FRAGMENT = gql`
  fragment recordTemplateFragment on RecordTemplate {
    id: _id
    description
    title
    fields {
      id
      title
      type
      ... on DateField {
        id
        placeholder
        title
        type
        validation
      }
      ... on MultiSelectField {
        id
        options {
          label
          value
        }
        placeholder
        title
        type
        validation
      }
      ... on NumberField {
        id
        placeholder
        title
        type
        validation
      }
      ... on RowField {
        fields {
          ... on DateField {
            id
            placeholder
            title
            type
            validation
          }
          ... on MultiSelectField {
            id
            options {
              label
              value
            }
            placeholder
            title
            type
            validation
          }
          ... on NumberField {
            id
            placeholder
            title
            type
            validation
          }
          ... on SelectField {
            id
            options {
              label
              value
            }
            title
            type
            validation
          }
          ... on TextField {
            id
            placeholder
            title
            type
            validation
          }
          type
          title
          id
        }
        id
        title
        type
      }
      ... on SelectField {
        id
        options {
          label
          value
        }
        title
        type
        validation
      }
      ... on TableField {
        columns {
          id
          title
          type
          ... on DateField {
            id
            placeholder
            title
            type
            validation
          }
          ... on MultiSelectField {
            id
            options {
              label
              value
            }
            placeholder
            title
            type
            validation
          }
          ... on NumberField {
            id
            placeholder
            title
            type
            validation
          }
          ... on SelectField {
            id
            options {
              label
              value
            }
            title
            type
            validation
          }
          ... on TextField {
            id
            placeholder
            title
            type
            validation
          }
        }
        id
        title
        type
      }
      ... on TextField {
        id
        placeholder
        title
        type
        validation
      }
    }
  }
`;
