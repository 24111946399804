import {
  REQUIRED_FIELD_OPTIONS,
  DATE_OPTIONS,
  START_TIME_OPTIONS,
  END_TIME_OPTIONS,
  BIRTH_DATE_OPTIONS,
  ERROR_RESPONSES,
  MAX_PHONE_NUMBER_CHARACTER,
  MIN_PHONE_NUMBER_CHARACTER,
  NAME_OPTIONS,
  PHONE_NUMBER_OPTIONS,
  STRING_OPTIONS,
  RULES,
} from 'src/constants';
import { renameObjectKeys } from './renameObjectKeys';

export const addNewStaffValidation = {
  'staffs.*.email': 'required|email',
  'staffs.*.staffGroup': 'required',
  'staffs.*.permissions': 'at_least_one_item',
  facilityId: 'required',
};

export const addStaffGroupValidation = {
  'staffGroups.*.name': RULES.requiredfield as string,
  'staffGroups.*.permissions': 'at_least_one_item',
};

export const addServiceValidation = {
  'services.*.description': 'required',
  'services.*.amount': 'required',
};

export const addInvoiceItemValidation = {
  'invoice.items.*.unitCost': 'required|min:1',
  'invoice.items.*.description': 'required',
  'invoice.items.*.units': 'required|min:1',
};

export const addMultipleHMOValidation = {
  'hmos.*.name': RULES.requiredfield as string,
  'hmos.*.email': RULES.email as unknown as string,
  'hmos.*.phoneNumber': RULES.phoneNumber as string,
};

export const addAppointmentValidation = {
  ...REQUIRED_FIELD_OPTIONS,
  ...DATE_OPTIONS,
  ...START_TIME_OPTIONS,
  ...END_TIME_OPTIONS,
};

export const createStepValidation = {
  name: RULES.requiredfield as string,
  description: RULES.requiredfield as string,
  assigned: RULES.requiredfield as string,
  'parameters.*.type': 'string',
  'parameters.*.title': 'string',
  'parameters.*.entryType': 'string',
  'actions.*.title': 'string',
  'actions.*.tag': 'string',
};

export const medicationValidation = {
  'medications.*.drugName': RULES.requiredfield as string,
  'medications.*.form': RULES.string,
  'medications.*.duration': RULES.requiredfield as string,
  'medications.*.frequency': RULES.requiredfield as string,
  'medications.*.dosage': RULES.requiredfield as string
};

export const medicationValidationErrorMessages = {
  'required.medications.*.drugName': ERROR_RESPONSES.required,
  ...renameObjectKeys(STRING_OPTIONS, 'medications.*.form'),
  'required.medications.*.duration': ERROR_RESPONSES.required,
  'required.medications.*.frequency': ERROR_RESPONSES.required,
  'required.medications.*.dosage': ERROR_RESPONSES.required,
};

export const stepValidationErrorMessages = {
  ...renameObjectKeys(NAME_OPTIONS, 'name'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'description'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'assigned'),
  ...renameObjectKeys(STRING_OPTIONS, 'actions.*.title'),
  'required_if.actions.*.title': 'Field cannot Field cannot be empty',
  ...renameObjectKeys(STRING_OPTIONS, 'actions.*.tag'),
  'required_if.actions.*.tag': 'Field cannot Field cannot be empty',

  ...renameObjectKeys(STRING_OPTIONS, 'parameters.*.title'),
  'required_if.parameters.*.title': 'Field cannot Field cannot be empty',
  ...renameObjectKeys(STRING_OPTIONS, 'parameters.*.type'),
  'required_if.parameters.*.type': 'Field cannot Field cannot be empty',
  ...renameObjectKeys(STRING_OPTIONS, 'parameters.*.entryType'),
  'required_if.parameters.*.entryType': 'Field cannot Field cannot be empty',
};

export const strictBatchValidation = {
  'batch.quantity': 'required|min:1',
  'batch.buyingPrice': 'required|min:1',
  'batch.sellingPrice': 'required|min:1',
  'batch.stockAlert': 'required|min:1',
};

export const strictProductValidation = {
  'details.name': RULES.requiredfield as string,
  'details.extraInfo': RULES.requiredfield as string,
  'details.categories': RULES.requiredfield as string,
  'details.brandName': RULES.requiredfield as string,
  'details.form': RULES.requiredfield as string,
  'details.isOTC': RULES.requiredfield as string,
};

export const addPatientValidation = {
  'basicInfo.firstName': RULES.name as unknown as string,
  'basicInfo.lastName': RULES.name as unknown as string,
  'basicInfo.phoneNumber': RULES.phoneNumber as string,
  'basicInfo.emailAddress': `email`,
  'basicInfo.dateOfBirth': RULES.birthDate as string,
  'basicInfo.gender': RULES.requiredfield as string,
  'basicInfo.maritalStatus': RULES.requiredfield as string,
  'basicInfo.nationality': RULES.requiredfield as string,
  'otherInfo.dateOfBirth': RULES.birthDate as string,
  'otherInfo.gender': RULES.requiredfield as string,
  'otherInfo.maritalStatus': RULES.requiredfield as string,
  'otherInfo.nationality': RULES.requiredfield as string,
  'otherInfo.languageSpoken': 'string',
  'otherInfo.secondaryPhoneNumber':
    `min:${MIN_PHONE_NUMBER_CHARACTER}|max:${MAX_PHONE_NUMBER_CHARACTER}` as string,
  'otherInfo.homeAddress': RULES.requiredfield as string,
  'otherInfo.workAddress': 'string',
  'otherInfo.hmo.hmo': `string`,
  'otherInfo.hmo.hmoProviderId': 'string',
  'nextOfKin.firstName': RULES.name as unknown as string,
  'nextOfKin.lastName': RULES.name as unknown as string,
  'nextOfKin.relationshipToPatient': RULES.requiredfield as string,
  'nextOfKin.phoneNumber': RULES.phoneNumber as string,
  'nextOfKin.emailAddress': `email`,
  'nextOfKin.homeAddress': RULES.requiredfield as string,
  'identity.identityNumber': 'string',
  'identity.typeOfIdentity': 'string',
};

export const addPatientErrorMessages = {
  ...renameObjectKeys(NAME_OPTIONS, 'basicInfo.firstName'),
  ...renameObjectKeys(NAME_OPTIONS, 'basicInfo.lastName'),
  'email.basicInfo.emailAddress': ERROR_RESPONSES.invalidEmail,
  ...renameObjectKeys(PHONE_NUMBER_OPTIONS, 'basicInfo.phoneNumber'),
  ...renameObjectKeys(BIRTH_DATE_OPTIONS, 'basicInfo.dateOfBirth'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'basicInfo.gender'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'basicInfo.maritalStatus'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'basicInfo.nationality'),
  ...renameObjectKeys(BIRTH_DATE_OPTIONS, 'otherInfo.dateOfBirth'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'otherInfo.gender'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'otherInfo.maritalStatus'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'otherInfo.nationality'),
  ...renameObjectKeys(STRING_OPTIONS, 'otherInfo.languageSpoken'),
  'min.otherInfo.secondaryPhoneNumber': ERROR_RESPONSES.tooShort(
    MIN_PHONE_NUMBER_CHARACTER,
  ),
  'max.otherInfo.secondaryPhoneNumber': ERROR_RESPONSES.tooLong(
    MAX_PHONE_NUMBER_CHARACTER,
  ),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'otherInfo.homeAddress'),
  ...renameObjectKeys(STRING_OPTIONS, 'otherInfo.workAddress'),
  ...renameObjectKeys(STRING_OPTIONS, 'otherInfo.hmo.hmo'),
  'required_if.otherInfo.hmo.hmo': ERROR_RESPONSES.notEmpty,
  ...renameObjectKeys(STRING_OPTIONS, 'otherInfo.hmo.hmoProviderId'),
  'required_if.otherInfo.hmo.hmoProviderId': ERROR_RESPONSES.notEmpty,
  ...renameObjectKeys(NAME_OPTIONS, 'nextOfKin.firstName'),
  ...renameObjectKeys(NAME_OPTIONS, 'nextOfKin.lastName'),
  ...renameObjectKeys(
    REQUIRED_FIELD_OPTIONS,
    'nextOfKin.relationshipToPatient',
  ),
  ...renameObjectKeys(PHONE_NUMBER_OPTIONS, 'nextOfKin.phoneNumber'),
  'min.nextOfKin.phoneNumber': ERROR_RESPONSES.tooShort(
    MIN_PHONE_NUMBER_CHARACTER,
  ),
  'max.nextOfKin.phoneNumber': ERROR_RESPONSES.tooLong(
    MAX_PHONE_NUMBER_CHARACTER,
  ),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'nextOfKin.homeAddress'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'identity.identityNumber'),
  ...renameObjectKeys(REQUIRED_FIELD_OPTIONS, 'identity.typeOfIdentity'),
  'required_if.identity.typeOfIdentity': ERROR_RESPONSES.notEmpty,
  'required_if.identity.identityNumber': ERROR_RESPONSES.notEmpty,
};
