import * as React from 'react';
import { GetStaffsProps, SearchStaffsProps, Staff } from 'src/types';
import StaffsContext from './context';

const StaffProvider: React.FC = ({ children }) => {
  // const [staffGroups, setStaffGroups] = React.useState<GetStaffGroups>({} as GetStaffGroups);
  const [initialStaffs, setInitialStaffs] = React.useState<GetStaffsProps>(
    {} as GetStaffsProps,
  );
  const [doctors, setDoctors] = React.useState<SearchStaffsProps>(
    {} as SearchStaffsProps,
  );
  const [nurses, setNurses] = React.useState<SearchStaffsProps>(
    {} as SearchStaffsProps,
  );
  const [activeDoctors, setActiveDoctors] = React.useState<SearchStaffsProps>(
    {} as SearchStaffsProps,
  );
  const [activeNurses, setActiveNurses] = React.useState<SearchStaffsProps>(
    {} as SearchStaffsProps,
  );
  const [owner, setOwner] = React.useState<Staff>({} as Staff);

  return (
    <StaffsContext.Provider
      value={{
        initialStaffs,
        owner,
        setInitialStaffs,
        // setStaffGroups,
        // staffGroups,
        activeDoctors,
        activeNurses,
        nurses,
        doctors,
        setOwner,
        setActiveDoctors,
        setActiveNurses,
        setDoctors,
        setNurses,
      }}
    >
      {children}
    </StaffsContext.Provider>
  );
};

export default StaffProvider;
