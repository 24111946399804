import * as React from 'react';
import { SearchFacilitiesProps } from 'src/types';
import FacilitiesContext from './context';

const FacilitiesProvider: React.FC = ({ children }) => {
  const [initialFacilities, setInitialFacilities] = React.useState<SearchFacilitiesProps>({} as SearchFacilitiesProps);

  return (
    <FacilitiesContext.Provider 
      value={{ initialFacilities, setInitialFacilities }}
    >
      {children}
    </FacilitiesContext.Provider>
  );
};

export default FacilitiesProvider;
