import { Column, Paragraph, Button, H6 } from 'src/components';
import { useHMOs } from 'src/state';
import { Form, Formik } from 'formik';
import Validator from 'validatorjs';
import { getModalParams, removeModalHash } from 'src/utils';
import {
  EMAIL_OPTIONS,
  PHONE_NUMBER_OPTIONS,
  REQUIRED_FIELD_OPTIONS,
  RULES,
} from 'src/constants';
import { Modal } from 'src/containers';
import { HMOType } from 'src/types';
import HMOForm from './hmoForm';

const AddHMO = () => {
  const {
    useAddHMO,
    useUpdateHMO,
    currentHMO
  } = useHMOs();

  const id = getModalParams("id");
  const { addHMO, loading: addHMOLoading } = useAddHMO();
  const { updateHMO, loading: updateHMOLoading } = useUpdateHMO();
  const { name, email, phoneNumber } = (currentHMO || {}) as HMOType;

  const formData = {
    name: id ? name : '',
    email: id ? email : '',
    phoneNumber: id ? phoneNumber : '',
  };

  return (
    <Modal width="46.1875rem">
      <Column gap={1}>
        <Column>
          <H6 weight="bold">
            {id ? 'Edit HMO Company' : 'Add HMO Company'}
          </H6>
          <Paragraph>
            {id ? 'Make Changes to HMO Company' : 'Add a new HMO to the list'}
          </Paragraph>
        </Column>
        <Formik
          initialValues={formData}
          enableReinitialize
          validate={(values) => {
            const validation = new Validator(
              values,
              {
                name: RULES.requiredfield,
                email: RULES.email,
                phoneNumber: RULES.phoneNumber,
              },
              {
                ...REQUIRED_FIELD_OPTIONS,
                ...EMAIL_OPTIONS,
                ...PHONE_NUMBER_OPTIONS,
              },
            );
            validation.passes();
            return validation.errors.errors;
          }}
          onSubmit={async (values) => {
            if (id) {
              await updateHMO({ ...values, id });
            } else {
              await addHMO(values);
            }
            removeModalHash();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue
          }) => {
            return (
              <Form>
                <Column gap={2.5}>
                  <Column gap={1}>
                    <HMOForm
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      name={{
                        name: "name",
                        value: values.name,
                        errors: touched.name ? errors.name as string : ''
                      }}
                      email={{
                        name: "email",
                        value: values.email,
                        errors: touched.email ? errors.email as string : ''
                      }}
                      phoneNumber={{
                        name: "phoneNumber",
                        value: values.phoneNumber,
                        errors: touched.phoneNumber ? errors.phoneNumber as string : ''
                      }}
                    />
                  </Column>
                  <Button
                    type="submit"
                    width="max-content"
                    disabled={addHMOLoading || updateHMOLoading}
                    isLoading={addHMOLoading || updateHMOLoading}
                  >
                    {id ? 'Save Changes' : 'Add HMO'}
                  </Button>
                </Column>
              </Form>
            );
          }}
        </Formik>
      </Column>
    </Modal>
  );
};

export default AddHMO;
