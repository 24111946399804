/* eslint-disable prefer-const */
import { CalendarIconComponent, TimeIcon } from 'src/assets/icons';
import { Column, Text } from 'src/components';
import { RowWrap } from 'src/containers/FormRenderer/styled';
import { TableRowWrapper } from 'src/pages/admin/PatientDetails/MedicalTimeline/styled';
import { TimelineTag } from 'src/pages/admin/PatientDetails/styled';
import { ProductLog } from 'src/types';
import { currencyformatter, removeModalHash, theme } from 'src/utils';
import { useLocation } from 'wouter';
import moment from 'moment';
import { ItemCard, ItemIndicator, StyledLink } from '../styled';

const fieldMap: Record<string, string> = {
  brandName: 'brand name',
  categories: 'categories',
  extraInfo: 'extra information',
  form: 'form',
  isArchived: 'active',
  isOTC: 'is OTC',
  name: 'name',
  batchNumber: 'batch number',
  buyingPrice: 'buying price',
  expiryAlert: 'expiry alert',
  expiryDate: 'expiry date',
  quantity: 'quantity',
  sellingPrice: 'selling price',
  status: 'status',
  stockAlert: 'stock alert',
  weight: 'weight',
};

const getEditedFields = (log: ProductLog) => {
  const value = log?.updates?.reduce((acc, val, i) => {
    let { before, after, field } = val;
    if (field === 'expiryDate') {
      before = moment(new Date(Number(before))).format('DD-MM-YY');
      after = moment(new Date(Number(after))).format('DD-MM-YY');
    }
    if (field?.includes('Price')) {
      before = currencyformatter.format(
        parseInt(Number(before).toString(), 10),
      );
      after = currencyformatter.format(parseInt(Number(after).toString(), 10));
    }
    if (`${before}` !== `${after}`) {
      acc += `${fieldMap[field as string]} from ${before} to ${after}${
        i !== (log?.updates?.length as number) - 1 ? ', ' : '.'
      }`;
    }
    return acc;
  }, '');
  return value?.slice(0, -2)
};

const GetLogDescription: React.FC<{ log: ProductLog }> = ({ log }) => {
  const [, setLocation] = useLocation();
  const actionType = log.action;
  const invoiceType = log?.buyerType;
  if (actionType === 'ADD_PRODUCT') {
    return (
      <Text>
        <Text color={theme.secondary[600]}>
          {`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        added this product with {log?.batch?.quantity} items to batch{' '}
        {log?.batch?.batchNumber}
      </Text>
    );
  }
  if (actionType === 'ADD_BATCH') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        added new batch ({log?.batch?.batchNumber}) of {log?.quantity} items
      </Text>
    );
  }
  if (actionType === 'PUSH_TO_SHELF') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        pushed {log?.quantity} items from {log?.batch?.batchNumber} to shelf
      </Text>
    );
  }
  if (actionType === 'ARCHIVE_PRODUCT') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        archived this product
      </Text>
    );
  }
  if (actionType === 'UNARCHIVE_PRODUCT') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        unarchived this product
      </Text>
    );
  }
  if (actionType === 'SELL_PRODUCT') {
    if (invoiceType === 'PATIENT') {
      return (
        <Text>
          <Text
            color={theme.secondary[600]}
          >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
          </Text>{' '}
          sold {log?.quantity} items from batch {log?.batch?.batchNumber} to{' '}
          <StyledLink
            onClick={() => {
              removeModalHash()
              setLocation(`/admin/patients/${log?.patient?.id}`)
            }}
            color={theme.secondary[600]}
          >{`${log.patient?.user?.firstName} ${log.patient?.user?.lastName}`}
          </StyledLink>
        </Text>
      );
    }

    if (invoiceType === 'FACILITY') {
      return (
        <Text>
          <Text color={theme.secondary[600]}>
            {`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
          </Text>{' '}
          sold {log?.quantity} items from batch {log?.batch?.batchNumber} for
          facility use{' '}
          {log.requester && (
            <>
              requested by:{' '}
              <Text color={theme.secondary[600]}>
                {`${log.requester?.user?.firstName} ${log.requester?.user?.lastName}`}
              </Text>
            </>
          )}
          {log.patient && (
            <>
              requested for:{' '}
              <StyledLink
                onClick={() =>
                  setLocation(`/admin/patients/${log?.patient?.id}`)}
                color={theme.secondary[600]}
              >{`${log.patient?.user?.firstName} ${log.patient?.user?.lastName}`}
              </StyledLink>
            </>
          )}
        </Text>
      );
    }

    if (invoiceType === 'EXTERNAL') {
      return (
        <Text>
          <Text
            color={theme.secondary[600]}
          >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
          </Text>{' '}
          sold {log?.quantity} items from batch {log?.batch?.batchNumber} to an
          external customer
        </Text>
      );
    }
  }
  if (actionType === 'EDIT_BATCH') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        updated the following in batch({log.batch?.batchNumber}):{' '}
        {getEditedFields(log)}
      </Text>
    );
  }
  if (actionType === 'EDIT_PRODUCT') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        updated the following in product information: {getEditedFields(log)}
      </Text>
    );
  }
  if (actionType === 'RETURN_AND_REFUND') {
    return (
      <Text>
        <Text
          color={theme.secondary[600]}
        >{`${log.actionBy?.user?.firstName} ${log.actionBy?.user?.lastName}`}
        </Text>{' '}
        returned {log.quantity} item(s) of batch {log.batch?.batchNumber}
      </Text>
    );
  }
  return (
    <p>
      <StyledLink>Sylvia Jones</StyledLink> pushed 250 items of batch B20034 to
      shelf
    </p>
  );
};

const LogItem: React.FC<{ log: ProductLog }> = ({ log }) => {
  return (
    <Column style={{ paddingRight: '1.25rem' }} gap={0.2}>
      <ItemIndicator color="#303030" />
      <ItemCard width="100%" data-testid="log-item">
        <RowWrap width="100%" justify="space-between">
          {log && GetLogDescription({ log })}
          <TableRowWrapper width="fit-content">
            <TimelineTag width="max-content" marginLeft={1} marginRight={1}>
              <CalendarIconComponent color="#FFBF5F" />
              {moment(log.createdAt).format('Do, MMM, YYYY')}
            </TimelineTag>
            <TimelineTag width="max-content">
              <TimeIcon />
              {moment(log.createdAt).format('h:mm a')}
            </TimelineTag>
          </TableRowWrapper>
        </RowWrap>
      </ItemCard>
    </Column>
  );
};

export default LogItem;
