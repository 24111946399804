export const GrowthGraphIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M7.29167 16.6654C7.70607 16.6654 8.1035 16.5007 8.39652 16.2077C8.68955 15.9147 8.85417 15.5173 8.85417 15.1029C8.85925 15.0509 8.85925 14.9986 8.85417 14.9466L11.7604 12.0404H12H12.2396L13.9167 13.7174C13.9167 13.7174 13.9167 13.7695 13.9167 13.8008C13.9167 14.2152 14.0813 14.6126 14.3743 14.9056C14.6673 15.1987 15.0648 15.3633 15.4792 15.3633C15.8936 15.3633 16.291 15.1987 16.584 14.9056C16.877 14.6126 17.0417 14.2152 17.0417 13.8008V13.7174L20.8333 9.89453C21.1424 9.89453 21.4445 9.80289 21.7014 9.6312C21.9584 9.45951 22.1586 9.21548 22.2769 8.92997C22.3952 8.64446 22.4261 8.3303 22.3658 8.0272C22.3055 7.72411 22.1567 7.4457 21.9382 7.22718C21.7197 7.00866 21.4413 6.85984 21.1382 6.79955C20.8351 6.73926 20.5209 6.77021 20.2354 6.88847C19.9499 7.00673 19.7059 7.207 19.5342 7.46395C19.3625 7.7209 19.2708 8.023 19.2708 8.33203C19.2657 8.38399 19.2657 8.43632 19.2708 8.48828L15.5104 12.2487H15.3438L13.5417 10.4154C13.5417 10.001 13.377 9.60354 13.084 9.31051C12.791 9.01748 12.3936 8.85286 11.9792 8.85286C11.5648 8.85286 11.1673 9.01748 10.8743 9.31051C10.5813 9.60354 10.4167 10.001 10.4167 10.4154L7.29167 13.5404C6.87727 13.5404 6.47984 13.705 6.18681 13.998C5.89379 14.291 5.72917 14.6885 5.72917 15.1029C5.72917 15.5173 5.89379 15.9147 6.18681 16.2077C6.47984 16.5007 6.87727 16.6654 7.29167 16.6654ZM21.3542 20.832H3.64583V3.1237C3.64583 2.84743 3.53609 2.58248 3.34074 2.38713C3.14539 2.19178 2.88043 2.08203 2.60417 2.08203C2.3279 2.08203 2.06295 2.19178 1.8676 2.38713C1.67225 2.58248 1.5625 2.84743 1.5625 3.1237V21.8737C1.5625 22.15 1.67225 22.4149 1.8676 22.6103C2.06295 22.8056 2.3279 22.9154 2.60417 22.9154H21.3542C21.6304 22.9154 21.8954 22.8056 22.0907 22.6103C22.2861 22.4149 22.3958 22.15 22.3958 21.8737C22.3958 21.5974 22.2861 21.3325 22.0907 21.1371C21.8954 20.9418 21.6304 20.832 21.3542 20.832Z"
      fill={color || '#FA8E74'}
    />
  </svg>
);
