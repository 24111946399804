import moment from 'moment';
import React, { useContext } from 'react';
import { Column, Row } from 'src/components';
import { Formik } from 'formik';
import {
  TabContent,
  TabContentItem,
  TabContext,
} from 'src/containers';
import { usePatients } from 'src/state';
import _ from 'lodash';
import {
  removeEmptyObjects,
  removeEmptyValues,
  removeModalHash
} from 'src/utils';
import { Patient, PatientProps } from 'src/types';
import { ArrowBackIcon } from 'src/assets/icons';
import OtherInfo from '../otherInfo';
import NextOfKin from '../NextOfKin';
import FamilyInfo from '../familyInfo';
import BasicInfo from '../BasicInfo';
import { CheckPatient } from '../CheckPatient';
import { FormProps } from '../index';
import { PatientsTabForm, SubmitButton } from '../styled';


interface TabItems {
  completed: FormProps;
  isEditPatient: boolean;
  render: () => void | null;
  setData: (value: string) => void;
  initialValues: Record<string, unknown>;
  setCompleted: (value: FormProps) => void;
  setPatientData: (value: PatientProps) => void;
  checkValidity: (values: Record<string, unknown>) => void
};

export const TabItems: React.FC<TabItems> = ({
  render,
  setData,
  completed,
  setCompleted,
  setPatientData,
  isEditPatient,
  initialValues,
  checkValidity
}) => {

  const { setActiveTab, activeTab } = useContext(TabContext);
  const {
    useAddPatient,
    useUpdatePatient,
    usePatientExists
  } = usePatients();

  const { addPatient, loading: addPatientLoading } = useAddPatient();
  const { updatePatient } = useUpdatePatient();
  const { 
    loading: patientsExistsLoading, 
    data: patientExistsData, 
    patientExists
  } = usePatientExists()
  const loading = addPatientLoading || patientsExistsLoading;

  const tabs = [
    <BasicInfo key={1} isEditPatient={isEditPatient} />,
    <OtherInfo key={2} isEditPatient={isEditPatient} />,
    <NextOfKin key={3} />,
    <FamilyInfo key={4} />
  ];

  const updateCompleted = (key: keyof FormProps) => {
    setCompleted({ ...completed, [key]: "active" })
  }

  const handleSubmit = async (values: Record<string, unknown>) => {
    const fixedValues = removeEmptyObjects(removeEmptyValues(values)) as unknown as Patient;
    const { basicInfo } = values as unknown as Patient;
    let res;
    
    switch (activeTab) {
      case "add-patient-0":
        res = await patientExists(_.pickBy({
          email: basicInfo.emailAddress,
          phoneNumber: basicInfo.phoneNumber,
        }, _.identity));

        if (res?.data?.checkIfPatientExists?.patients?.[0]?.id) {
          updateCompleted("basicInfo");
          setActiveTab(`add-patient-4`);
        } else {
          setActiveTab(`add-patient-1`);
        }
        break;

      case "add-patient-1":
        updateCompleted("otherInfo");
        setActiveTab(`add-patient-2`);
        break;

      case "add-patient-2":
        updateCompleted("nextOfKin");
        setActiveTab(`add-patient-3`);
        break;

      case "add-patient-3":
        res = await addPatient({
          ...fixedValues,
          otherInfo: {
            ...fixedValues.otherInfo,
            dateOfBirth: moment(fixedValues.otherInfo.dateOfBirth).toISOString()
          }
        });
        if (res?.data) {
          setData(res?.data.addPatients.patient.id);
          updateCompleted("family");
          setPatientData(res?.data.addPatients.patient)
        }
        break;

      default:
        break;
    }
  };

  const editSubmit = async (values: Record<string, unknown>) => {
    const fixedValues = values as unknown as Patient;
    const initialInputValues = initialValues as unknown as Patient;
    const { basicInfo, otherInfo, nextOfKin, family } = initialInputValues;
    

    if (activeTab === "add-patient-0") {
      updateCompleted("basicInfo");
      if (_.isEqual(fixedValues.basicInfo, basicInfo)) {
        return setActiveTab(`add-patient-1`);
      }
      setActiveTab(`add-patient-1`)
      return updatePatient({
        basicInfo: _.omitBy(fixedValues.basicInfo, _.isEmpty) 
      });
    }

    if (activeTab === "add-patient-1") {
      updateCompleted("otherInfo");
      if (_.isEqual(fixedValues.otherInfo, otherInfo)) {
        return setActiveTab(`add-patient-2`);
      }
      setActiveTab(`add-patient-2`);
      return updatePatient({
        otherInfo: removeEmptyObjects(removeEmptyValues(
        fixedValues.otherInfo as unknown as Record<string, unknown>)) 
      });
    }

    if (activeTab === "add-patient-2") {
      updateCompleted("nextOfKin");
      if (_.isEqual(nextOfKin, fixedValues.nextOfKin)) {
        return setActiveTab(`add-patient-3`);
      }
      setActiveTab(`add-patient-3`);
      return updatePatient({
        nextOfKin: _.omitBy(fixedValues.nextOfKin, _.isEmpty)
      });
    }

    if (activeTab === "add-patient-3") {
      updateCompleted("family");
      if (_.isEqual(family, fixedValues.family)) {
        return removeModalHash();
      }
      updatePatient({
        family: fixedValues.family
      });
      removeModalHash();
    }
  };


  return (
    <TabContent>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validate={values => checkValidity(values)}
        onSubmit={values => {
          if (isEditPatient) return editSubmit(values);
          return handleSubmit(values);
        }}
      >
        <>
          <PatientsTabForm
            display={activeTab !== "add-patient-4" ? "block" : "none"}
          >
            {tabs.map((tab, idx) => {
              return (
                <TabContentItem key={idx} id={`add-patient-${idx}`}>
                  <Column gap={2.5}>
                    {tab}
                    <Row 
                      gap={1} 
                      justify={activeTab === "add-patient-0" ? "flex-end" : "space-between"}
                      display={activeTab === "add-patient-4" ? "none" : "flex"}
                    >
                      <SubmitButton
                        onClick={() => render()}
                        variant='solid'
                        background="neutral"
                        color="black"
                        type="button"
                        width="max-content"
                        data-testid="prev-progress"
                        visibility={activeTab === "add-patient-0" ? "hidden" : "visible"}
                      >
                        <ArrowBackIcon />
                        Back
                      </SubmitButton>
                      <SubmitButton
                        width="max-content"
                        type="submit"
                        isLoading={loading}
                        data-testid="next-progress"
                      >
                        {!isEditPatient && activeTab === 'add-patient-3' && 'Finish'}
                        {!isEditPatient && activeTab !== 'add-patient-3' && 'Next'}
                        {isEditPatient && "Save"}
                        <ArrowBackIcon className='rotate-180' />
                      </SubmitButton>
                    </Row>
                  </Column>
                </TabContentItem>
              )
            })}
          </PatientsTabForm>
          <TabContentItem id="add-patient-4">
            <CheckPatient
              setPatientData={setPatientData}
              patientInfo={patientExistsData?.checkIfPatientExists?.patients}
              setData={setData}
              completed={completed}
              setCompleted={setCompleted}
            />
          </TabContentItem> 
        </>
      </Formik>
    </TabContent>
  );
};