import { useEffect, useRef } from "react";

export const useDebounceCallback = <T extends (...args: Record<string, unknown>[]) => void>(
  delay = 100,
  cleaning = true
) => {
  const ref = useRef<number | undefined | NodeJS.Timeout>();
  
  useEffect(() => {
    if (cleaning) {
      return () => {
        if (ref.current !== undefined) clearTimeout(ref.current as NodeJS.Timeout);
      };
    }
  }, [cleaning]);
  
  return (callback: T) => {
    if (ref.current !== undefined) clearTimeout(ref.current as NodeJS.Timeout);
    ref.current = setTimeout(callback, delay);
  };
};
