import { TodosIcon, OngoingVisitsIcon, EventsIcon } from 'src/assets/icons';
import { Row, Column } from 'src/components';
import { useStaff } from 'src/state';
import useMediaQuery from 'src/utils/useMediaQuery';
import {
  TabContent,
  TabContentItem,
  TabHeaderItem,
  TabHeaders,
  Tabs,
} from 'src/containers';
import { CalendarSideMenuTabHeader, CalendarTab } from './CalendarTab';
import { CalendarToolBar } from './CalendarToolBar';
import { TodoTab } from './TodoTab';
import { AppointmentTab } from './AppointmentTab';
import { BigCalendar } from './BigCalendar';
import {
  Section,
  PageWrapper,
  MobileTabs,
  CalendarTabContent,
  VisibilityContainer,
} from './styled';

export function getColor(type: string): string {
  switch (type) {
    case 'Office Visit':
    case 'Doctor Visit':
    case 'Consultation Visit':
    case 'Optician Visit':
      return 'yellow';
    case 'Regular Checkup':
    case 'Dental Checkup':
      return 'green';
    case 'Laboratory Test':
    case 'Surgery':
    case 'Emergency':
    case 'Patient Admission':
      return 'red';
    default:
      return 'gray';
  }
}

export interface FormProps {
  startDate: string;
  endDate: string;
  facility: string;
  provider: string;
}

const CalendarPage = () => {
  const { staff } = useStaff();
  const staffGroup = staff.getStaff.staffGroup.name;
  const isNurse = staffGroup.toLowerCase() === 'nurse';
  const isMobile = !useMediaQuery('(min-width: 960px)');

  return (
    <PageWrapper width="100%" gap={isMobile ? 0 : 1.5}>
      <VisibilityContainer
        show={!isMobile}
        width="100%"
        align="center"
        justify="space-between"
      >
        <CalendarToolBar />
      </VisibilityContainer>
      <VisibilityContainer show={!isMobile}>
        <Row height="100%" width="100%" gap={2}>
          <Section flex={1} width="70%">
            <BigCalendar />
          </Section>
          <Section height="inherit" width="30%">
            {isNurse ? <CalendarTab /> : <TodoTab />}
          </Section>
        </Row>
      </VisibilityContainer>
      <VisibilityContainer show={isMobile} height="100%" width="100%">
        <Tabs>
          <MobileTabs>
            <TabHeaders>
              <TabHeaderItem id="calendar">
                <CalendarSideMenuTabHeader text="Calendar">
                  <EventsIcon />
                </CalendarSideMenuTabHeader>
              </TabHeaderItem>
              <TabHeaderItem id="todos">
                <CalendarSideMenuTabHeader text="Todos">
                  <TodosIcon />
                </CalendarSideMenuTabHeader>
              </TabHeaderItem>
              {isNurse && (
                <TabHeaderItem id="ongoing_visit">
                  <CalendarSideMenuTabHeader text="Ongoing Visits">
                    <OngoingVisitsIcon />
                  </CalendarSideMenuTabHeader>
                </TabHeaderItem>
              )}
            </TabHeaders>
          </MobileTabs>
          <TabContent>
            <CalendarTabContent>
              <TabContentItem id="todos">
                <TodoTab />
              </TabContentItem>
              {isNurse && (
                <TabContentItem id="ongoing_visit">
                  <AppointmentTab />
                </TabContentItem>
              )}
              <TabContentItem id="calendar">
                <Column>
                  <CalendarToolBar />
                  <BigCalendar />
                </Column>
              </TabContentItem>
            </CalendarTabContent>
          </TabContent>
        </Tabs>
      </VisibilityContainer>
    </PageWrapper>
  );
};

export default CalendarPage;
