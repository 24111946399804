import _ from 'lodash';
import { Avatar, Paragraph, Row } from 'src/components';
import { generateAvatar } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';

interface StaffNameProps {
  fullName: string;
  avatar: string;
}

const StaffNameRow: React.FC<StaffNameProps> = ({ fullName, avatar }) => {
  const isMobile = !useMediaQuery('(min-width: 960px)');
  return (
    <Row gap={0.5} align="center">
      <Avatar size={isMobile ? 25 : 35} src={avatar || generateAvatar(fullName)} />
      <Paragraph size={isMobile ? 'xs' : 'base'}>
        {_.startCase(fullName)}
      </Paragraph>
    </Row>
  );
};

export default StaffNameRow;
