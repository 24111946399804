import { CancelIcon, FilterIcon } from 'src/assets/icons';
import { Column, Dropdown, IconButton, Paragraph, Row } from 'src/components';
import { theme } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import { OptionProps } from 'src/types';
import {
  ManageSearchInput,
  ResetButton,
  TagChip,
} from 'src/pages/admin/styled';
import mixpanel from 'mixpanel-browser';

export type Filter = Record<string, string | number | boolean>;

interface FilterType {
  name: string;
  key: string;
  type?: 'select';
  checked: Omit<OptionProps, 'checked'>;
  options: Omit<OptionProps, 'checked'>[];
}

interface InventoryHistoryFilterProps {
  setIsModalOpen: () => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getFilters: (filters: FilterType[]) => Filter;
  filters: FilterType[];
  setFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
  applyFilters: (newFilter: Filter) => void;
  removeFilter: (newFilter: Filter) => void;
  clearFilters: () => void;
}

const InventoryHistoryFilters: React.FC<InventoryHistoryFilterProps> = ({
  setIsModalOpen,
  onSearchChange,
  filters,
  setFilters,
  getFilters,
  applyFilters,
  removeFilter,
  clearFilters,
}) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');

  const deleteFilter = (filter: FilterType) => {
    const newFilters = [...filters].map((f) => {
      if (f.name === filter.name) {
        f.checked = {} as Omit<OptionProps, 'checked'>;
      }
      return f;
    });

    setFilters(newFilters);
    removeFilter(getFilters(newFilters));
  };

  const handleDropdownChange = (id: string, value: string) => {
    const idx = Number(id);
    const filter = { ...filters[idx] };
    const checked = value.length
      ? filter.options.find((f) => f.value === value)
      : {};
    const newFilters = [...filters].map((f, i) => {
      if (i === idx) f.checked = checked as Omit<OptionProps, 'checked'>;
      return f;
    });
    mixpanel.track(`Applied ${filter.name} Filter`, {
      feature: 'Inventory History',
    });
    setFilters(newFilters);
    applyFilters(getFilters(newFilters));
  };

  const resetFilters = () => {
    clearFilters();
  };

  return (
    <Column gap={1}>
      <Row align="center" justify="space-between" width="100%">
        <Row width="100%" gap={1} justify="space-between">
          <ManageSearchInput
            onChange={onSearchChange}
            placeholder="Search Invoice ID"
          />
          {!isDesktop && (
            <IconButton
              background={theme.primary[100]}
              onClick={setIsModalOpen}
            >
              <FilterIcon />
            </IconButton>
          )}
        </Row>
        <Row width="max-content" gap={1}>
          {isDesktop &&
            filters.map((filter, idx) => (
              <Dropdown
                key={idx}
                width="max-content"
                title={filter.name}
                name={`${idx}`}
                handleDropdownChange={handleDropdownChange}
                options={filter.options?.map((v) => ({
                  ...v,
                  checked: filter.checked.value === v.value,
                }))}
              />
            ))}
        </Row>
      </Row>
      <Row align="center" gap={1}>
        {filters.filter((filter) => !!filter.checked.label).length > 0 && (
          <Paragraph>Filters:</Paragraph>
        )}
        <Row gap={1} width="max-content">
          {filters.map(
            (filter, idx) =>
              filter.checked.label && (
                <TagChip type="round" color="grey" key={idx}>
                  {filter.checked.label}
                  <IconButton
                    color="white"
                    variant="solid"
                    background="primary"
                    onClick={() => deleteFilter(filter)}
                  >
                    <CancelIcon />
                  </IconButton>
                </TagChip>
              ),
          )}
        </Row>
        {filters.filter((filter) => !!filter.checked.label).length > 0 && (
          <ResetButton
            color="primary"
            onClick={() => {
              resetFilters();
            }}
          >
            Reset Filters
          </ResetButton>
        )}
      </Row>
    </Column>
  );
};

export default InventoryHistoryFilters;
