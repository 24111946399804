import { Permissions } from 'src/types';

export const checkPermission = (
  staffPermissions: Permissions[] | string[],
  permissionName?: (Permissions | string) | (Permissions | string)[],
): boolean => {
  if (!permissionName) return true;
  const values = Array.isArray(permissionName)
    ? permissionName
    : [permissionName];
  return values.some((currentItem) =>
    staffPermissions.includes(currentItem as Permissions),
  );
};
