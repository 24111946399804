import { theme } from 'src/utils';
import styled, { keyframes } from 'styled-components';
import { ModStylesUtil } from 'src/types';
import { injectModStyles } from '..';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{
  size?: number;
  thickness?: number;
  modStyles?: ModStylesUtil;
}>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border: ${({ thickness }) =>
    `${thickness || 0.25}rem solid ${theme.grey[100]}`};
  border-bottom-color: ${theme.grey[400]};
  background: transparent;
  min-width: ${({ size }) => `${size || 2.5}rem`};
  min-height: ${({ size }) => `${size || 2.5}rem`};
  border-radius: 50%;
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;
