import { Column, Text } from 'src/components';
import { Spinner } from './styled';

export const PageLoader = () => {
  return (
    <Column align="center" justify="center" width="100%" height="100%">
      <Spinner />
      <Text size="sm">Loading</Text>
    </Column>
  )
}