import { Column, Text, Row, H6, LoadingText } from 'src/components';
import moment from 'moment';
import { useHMOs } from 'src/state';
import { getModalParams } from 'src/utils';
import { Settlement, User } from 'src/types';
import SettlementBlock from './settlementBlock';
import SettlementCard from '../../AddSettlement/SettlementCard';

const SettlementDetails = () => {
  const paramsId = getModalParams('id');
  const { currentSettlement, useSettlement } = useHMOs();
  const { error, loading } = useSettlement(paramsId);

  const { 
    settlementId, 
    createdBy, 
    debt, 
    amount, 
    proof, 
    createdAt, 
    id 
  } = (currentSettlement || {}) as Settlement;
  const { firstName, lastName } = (createdBy?.user || {}) as User;

  if (error) return <></>;
  if (loading) return <LoadingText />

  return (
    <Column gap={1.25}>
      <Column>
        <H6 modStyles={{ my: 0 }}>Settlement Information</H6>
        <Text modStyles={{ my: 0 }}>More information about settlement</Text>
      </Column>
      <Row gap={0.65}>
        <Column gap={1.56}>
          <SettlementBlock keys="Settlement ID" label={settlementId} />
          <SettlementBlock
            keys="Marked By"
            label={`${lastName || "Marvin"} ${firstName || "Tunji"}`}
          />
        </Column>
        <Column gap={1.56}>
          <SettlementBlock
            keys="Settlement Date"
            label={moment(createdAt).format(`h:mma, MMM. D, YYYY`)}
          />
          <SettlementBlock
            keys="Proof of Payment"
            attachment={proof}
            link="settlement-info"
            id={`${id}&url=${proof}`}
          />
        </Column>
      </Row>
      <Row gap={0.65}>
        <SettlementCard label="Total Debt" amount={debt} />
        <SettlementCard label="Settlement Amount" amount={amount} />
      </Row>
    </Column>
  );
};

export default SettlementDetails;
