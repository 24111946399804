import * as React from 'react';
import {
  AuthContextType,
  AuthAction,
  AuthStateType,
  AuthReducerType,
} from 'src/types';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from 'src/utils/storageHelper';
import AuthContext from './context';
import reducer, { initialState } from './reducer';
import actions from './actions';

const cache =
  (fun: AuthReducerType) => (state: AuthStateType, action: AuthAction) => {
    const cachable = fun(state, action);
    if (cachable) {
      setLocalStorage('token', cachable.token);
      setLocalStorage('refreshToken', cachable.refreshToken);
    }
    return cachable;
  };

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(cache(reducer), initialState);

  React.useEffect(() => {
    const token = getLocalStorage('token');
    if (!token) {
      removeLocalStorage('token');
      removeLocalStorage('refreshToken');
    }
  });

  const stateD: Pick<AuthContextType, 'state'> = React.useMemo(
    () => ({
      state,
    }),
    [state],
  );

  const dispatches: Omit<AuthContextType, 'state'> = React.useMemo(
    () => ({
      logIn: actions.logIn(dispatch),
      logOut: actions.logOut(dispatch),
    }),
    [dispatch],
  );
  return (
    <AuthContext.Provider value={{ ...stateD, ...dispatches }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
