import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { StepType, useTour } from '@reactour/tour';
import { UPDATE_USER } from 'src/constants';
import { useUser, useToast } from 'src/state';
import { steps } from 'src/configs/tourSteps';
import { font, theme } from 'src/utils';
import mixpanel from 'mixpanel-browser';
import { Button, H5, Row, Text } from '..';

type Navigation = {
  steps: StepType[];
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};

type Content = {
  content: string;
  currentStep: number;
};

export const TourContent: React.FC<Content> = ({ content, currentStep }) => {
  return (
    <div>
      <H5>{steps[currentStep]?.title}</H5>
      <Text>{content}</Text>
    </div>
  );
};

export const TourNavigation: React.FC<Navigation> = ({
  setCurrentStep,
  currentStep,
  steps: navSteps,
}) => {
  const { setIsOpen } = useTour();
  const { showToast } = useToast();
  const { user, setUser } = useUser();
  const [markTourComplete, { error }] = useMutation(UPDATE_USER);
  const stepsLength = navSteps.length;

  const onSkip = () => {
    setIsOpen(false);
    setUser({
      getUser: {
        ...user.getUser,
        isTourComplete: true,
      },
    });
    markTourComplete({
      variables: {
        input: {
          isTourComplete: true,
        },
      },
    });
  };

  useEffect(() => {
    if (error) {
      showToast('Invalid token', 'error');
    }
  }, [error, showToast]);

  const onNext = () => {
    if (currentStep + 1 === stepsLength) {
      onSkip();
    } else {
      setCurrentStep(Math.min(currentStep + 1, stepsLength - 1));
    }
  };

  return (
    <Row width="100%" justify="space-between" modStyles={{ mt: 1.5 }}>
      <Button
        data-testid="skip-tour"
        width="max-content"
        type="button"
        onClick={() => {
          mixpanel.track(`Click 'Skip Tour' button`, {
            feature: 'Tour',
          });
          onSkip()}}
        background={theme.secondary[700]}
        variant="text"
        size={font.sizes.lg}
        modStyles={{ pa: 0 }}
      >
        Skip
      </Button>
      <Row width="max-content">
        <Button
          type="button"
          width="max-content"
          onClick={() => setCurrentStep(Math.max(currentStep - 1, 0))}
          background={theme.secondary[700]}
          variant="text"
          size={font.sizes.base}
          modStyles={{ pa: 0, mr: 0.8 }}
        >
          Prev
        </Button>
        <Button
          type="button"
          width="max-content"
          onClick={() => {
            mixpanel.track(`Click 'Next Step' button`, {
              feature: 'Tour',
            });
            onNext()}}
          background={theme.secondary[700]}
          variant="text"
          size={font.sizes.lg}
          modStyles={{ pa: 0 }}
        >
          {currentStep + 1 === stepsLength ? 'Finish' : 'Next'}{' '}
        </Button>
      </Row>
    </Row>
  );
};
