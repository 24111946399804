import { theme } from 'src/utils';
import { Row, Paragraph, Column } from 'src/components';
import { PulseIcon } from 'src/assets/icons';
import VitalChart from 'src/containers/VitalChart';
import { useQuery } from '@apollo/client';
import { GET_PATIENT_DATA } from 'src/constants';
import { useRoute } from 'wouter';
import moment from 'moment';
import { useStaff } from 'src/state';
import { VitalsChartModal } from './styled';

const VitalsChart: React.FC = () => {
  const [, params] = useRoute('/admin/patients/:id');
  const { hasPermission } = useStaff()
  const { data, loading, error } = useQuery(GET_PATIENT_DATA, {
    variables: {
      patientId: params?.id,
    },
    skip: !params?.id || !hasPermission("VIEW_PATIENT"),
    errorPolicy: 'all',
  });
  const patient = data && data?.getPatient;
  const patientAge = moment().diff(
    moment(patient?.otherInfo.dateOfBirth),
    'years',
  );

  return (
    <VitalsChartModal>
      <Row align="center" width="max-content" gap={1}>
        <PulseIcon />
        <Paragraph size="md" weight="bold" color={theme.grey[700]}>
          Vitals History Chart
        </Paragraph>
      </Row>
      <Column gap={2}>
        <VitalChart
          chartConfig={{
            vitalId: 'temperature',
            vitalName: 'Temperature (°C)',
            boundKey: 'temperature',
          }}
        />
        <VitalChart
          chartConfig={{
            vitalId: 'diastolic',
            vitalName: 'Diastolic Blood Pressure (mmHg)',
            boundKey: 'diastolic',
          }}
        />
        <VitalChart
          chartConfig={{
            vitalId: 'systolic',
            vitalName: 'Systolic Blood Pressure (mmHg)',
            boundKey: 'systolic',
          }}
        />
        <VitalChart
          chartConfig={{
            vitalId: 'pulse',
            vitalName: 'Pulse (bpm)',
            boundKey: 'pulse',
          }}
        />
        {!loading && !error && patient && (
          <VitalChart
            chartConfig={{
              vitalId: 'respiratory_rate',
              vitalName: 'Respiratory Rate (Breath/min)',
              boundKey:
                patientAge <= 12
                  ? 'respiratory_rate_below_twelve_years'
                  : 'respiratory_rate_above_twelve_years',
            }}
          />
        )}
      </Column>
    </VitalsChartModal>
  );
};

export default VitalsChart;
