import { gql } from '@apollo/client';

export const batchFieldsFragment = gql`
  fragment batchFields on Batch {
    id: _id
    batchNumber
    buyingPrice
    createdAt
    deletedAt
    expiryAlert
    expiryDate
    quantity
    quantityLeft
    sellingPrice
    status
    stockAlert
    updatedAt
    weight
  }
`;

export const productLogFragment = gql`
  fragment productLogFields on ProductLog {
    action
    actionBy {
      user {
        firstName
        fullName
        id: _id
      }
    }
    batch {
      batchNumber
    }
    createdAt
    updatedAt
    deletedAt
  }
`;

export const productFragment = gql`
  fragment productFields on Product {
    id: _id
    batches {
      id: _id
      batchNumber
      buyingPrice
      createdAt
      deletedAt
      expiryAlert
      expiryDate
      quantity
      quantityLeft
      sellingPrice
      quantityOnShelf
      status
      stockAlert
      updatedAt
      weight
    }
    brandName
    categories
    createdAt
    deletedAt
    form
    isArchived
    isOTC
    extraInfo
    # logs {
    #   _id
    #   action
    #   actionBy {
    #     user {
    #       firstName
    #       lastName
    #     }
    #     _id
    #   }
    #   createdAt
    #   updatedAt
    # }
    name
    productCode
    shelf {
      quantityLeft
    }
    updatedAt
  }
`;
