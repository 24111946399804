export const UserIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6543 10.6507C13.5529 9.9436 14.2089 8.97401 14.5309 7.87678C14.8529 6.77955 14.8248 5.60924 14.4507 4.52868C14.0766 3.44811 13.375 2.51102 12.4435 1.84778C11.512 1.18454 10.3969 0.828125 9.25342 0.828125C8.10992 0.828125 6.99485 1.18454 6.06335 1.84778C5.13185 2.51102 4.43023 3.44811 4.05611 4.52868C3.68199 5.60924 3.65397 6.77955 3.97595 7.87678C4.29792 8.97401 4.95389 9.9436 5.85258 10.6507C4.31265 11.2676 2.96901 12.2909 1.9649 13.6114C0.960788 14.9319 0.333848 16.5002 0.150915 18.149C0.137673 18.2694 0.148272 18.3912 0.182105 18.5075C0.215938 18.6238 0.272343 18.7322 0.3481 18.8267C0.501099 19.0175 0.723633 19.1398 0.966748 19.1665C1.20986 19.1932 1.45364 19.1223 1.64446 18.9693C1.83528 18.8163 1.95751 18.5938 1.98425 18.3507C2.18553 16.5588 3.03996 14.9038 4.38428 13.7021C5.7286 12.5003 7.46857 11.8359 9.27175 11.8359C11.0749 11.8359 12.8149 12.5003 14.1592 13.7021C15.5035 14.9038 16.358 16.5588 16.5592 18.3507C16.5842 18.5759 16.6916 18.7839 16.8609 18.9346C17.0302 19.0853 17.2493 19.1679 17.4759 19.1665H17.5767C17.817 19.1388 18.0367 19.0174 18.1878 18.8285C18.3389 18.6396 18.4092 18.3987 18.3834 18.1582C18.1996 16.5047 17.5693 14.9324 16.56 13.6099C15.5508 12.2873 14.2006 11.2644 12.6543 10.6507ZM9.25342 9.99983C8.52822 9.99983 7.81931 9.78478 7.21633 9.38189C6.61334 8.97899 6.14338 8.40633 5.86586 7.73634C5.58834 7.06634 5.51572 6.3291 5.6572 5.61783C5.79868 4.90657 6.1479 4.25323 6.66069 3.74044C7.17348 3.22765 7.82682 2.87843 8.53808 2.73695C9.24935 2.59547 9.98659 2.66808 10.6566 2.94561C11.3266 3.22313 11.8992 3.69309 12.3021 4.29607C12.705 4.89905 12.9201 5.60797 12.9201 6.33316C12.9201 7.30562 12.5338 8.23826 11.8461 8.92589C11.1585 9.61352 10.2259 9.99983 9.25342 9.99983Z"
      fill={color || '#686868'}
    />
  </svg>
);
