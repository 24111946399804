import { Column, Row } from 'src/components';
import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import { theme, font } from 'src/utils/variables';
import styled from 'styled-components';

export const SwitchWorkspaceModal = styled(Modal)<{ loading?: boolean }>`
  max-width: 23rem;
  ${ModalChildContainer} {
    position: relative;
    ${({ loading }) =>
      loading &&
      `
    pointer-events: none;
    opacity: .5;
    `}
    @media (max-width: 960px) {
      min-width: 100%;
      h6 {
        font-size: ${font.sizes.base};
      }
    }
  }
`;

export const AbsoluteRow = styled(Row)<{ hasBorderButtom?: boolean }>`
  position: absolute;
`;

export const WorkspaceItem = styled(Column)<{
  hasBorderButtom?: boolean;
  isDefault?: boolean;
}>`
  align-items: center;
  padding: 5px;
  ${({ isDefault }) => !isDefault && 'cursor: pointer;'}
  ${({ isDefault }) => isDefault && 'opacity: 0.4;'}
  &:hover {
    opacity: 0.7;
  }
  ${({ hasBorderButtom }) =>
    hasBorderButtom &&
    `
  &::after {
    content: '';
    width: 90%;
    background-color: ${theme.grey[300]};
    height: 1px;
    margin-top: 0.625rem;
  }

  `}
`;

export const Address = styled.small`
  font-size: 0.75rem;
  font-weight: ${font.weights.semibold};
  color: ${theme.grey[500]};
`;
