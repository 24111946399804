import {
  Button,
  Column,
  IconButton,
  Paragraph,
  Row,
  Text,
} from 'src/components';
import { ChildrenWrapper } from 'src/containers/Accordion/styled';
import { TextProps } from 'src/types';
import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import { theme, font } from 'src/utils/variables';
import styled from 'styled-components';
import { SectionHeader } from '../styled';

export const EmptyText = styled(Paragraph)`
  color: ${theme.grey[600]};
  max-width: 20rem;
  span {
    font-weight: ${font.weights.semibold};
    color: ${theme.grey[800]};
  }
`;

export const RecordTitle = styled(Paragraph)<{ bg?: string; color: string }>`
  background: ${({ bg }) => bg || theme.grey[100]};
  border: 1px solid ${({ color }) => color || theme.grey[700]};
  color: ${({ color }) => color || theme.grey[700]};
  font-weight: ${font.weights.medium};
  padding: 0.2rem 0.5rem;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
`;

export const LinkText = styled.a<TextProps>`
  font-size: ${(props) => font.sizes[props.size ?? 'base']};
  color: ${(props) => props.color || theme.grey[700]};
  font-weight: ${(props) =>
    font.weights[props.weight ?? 'normal'] || font.weights.normal};
  text-align: ${(props) => props.align || 'left'};
  margin: 0.5rem 0;
  text-decoration: underline;
`;

export const ImagePreview = styled(Column)`
  position: relative;
  padding: 0.5rem;
  height: 8rem;
  width: 8rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.grey[300]};
  background-color: ${theme.white[100]};
  & img {
    width: 100%;
  }
`;

export const FormRendererModal = styled(Modal)`
  ${ModalChildContainer} {
    @media (min-width: 960px) {
      max-width: 43.75rem;
    }
  }
`;

export const ToolbarWrapper = styled(Row)`
  position: absolute;
  z-index: 99;
  background: white;
  margin-bottom: 2rem;
  & button {
    margin-right: 0.625rem;
    font-weight: 400;
  }
  & svg {
    stroke: unset;
    & path {
      stroke: unset;
    }
  }
  @media (max-width: 600px) {
    font-size: 0.75rem;
    margin-bottom: 1rem;
    & svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`;

export const RecordText = styled(Text)`
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0;
`;

export const RowWrap = styled(Row)`
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
`;

export const TableRowWrapper = styled(Row)`
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const CompoundRowWrapper = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TimelinePreview = styled(Row)`
  max-height: 10rem;
  position: relative;
  overflow: hidden;
`;

export const PreviewOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 30px 0;
  background-image: linear-gradient(to bottom, transparent, white);
`;

export const FilterButton = styled(IconButton)`
  height: 100%;
`;

export const FilterRow = styled(Row)`
  margin-left: auto;
  width: max-content;
`;

export const DateFilterWrapper = styled(Column)`
  width: unset;
  list-style: none;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 100%;
  right: 0%;
  min-width: 100%;
  z-index: 9999;
  background-color: ${theme.white[100]};
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-top: 0.375rem;
  box-shadow: 3px 8px 30px 5px rgba(0, 0, 0, 0.08);
`;

export const FormText = styled(Text)`
  margin: 0;
  text-overflow: ellipsis;
  width: 11.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  @media (max-width: 600px) {
    font-size: ${font.sizes.sm};
  }
`;

export const RoundIconButton = styled(IconButton)`
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgba(23, 23, 23, 0.38);
  border-radius: 50%;
  position: absolute;
  top: 0.4375rem;
  right: 0.4375rem;
  & > svg {
    stroke-width: 0;
  }
`;

export const TimeLineSection = styled(SectionHeader)`
  padding: 1rem 1.5rem;
`;

export const LoadMoreButton = styled(Button)`
  padding: 1rem 1.25rem;
`;

export const AccordionWrapper = styled(Column)`
  overflow-y: auto;
  max-height: calc(100vh - 18rem);
  padding-right: 0.5rem;
  @media (max-width: 600px) {
    max-height: calc(100vh - 28rem);
  }
  & > ${ChildrenWrapper} {
    padding: 0;
  }
`;
