import React, { useContext, useState } from 'react';
import {
  CheckmarkIcon,
  WarningIcon,
  OthersIcon,
  UserIcon,
  UsersTwoIcon,
  FamilyIcon,
} from 'src/assets/icons';
import { Column, Row, Paragraph, ConfirmationPrompt } from 'src/components';
import {
  Modal,
  TabContext,
  TabHeaderItem,
  TabHeaders,
  Tabs,
} from 'src/containers';
import { getModalParams, removeModalHash, theme } from 'src/utils';
import { useLocation } from 'wouter';
import { PatientProps } from 'src/types';
import { useRowData } from 'src/state';
import { AddPatientTabsContent } from './AddPatientTabsContent';
import {
  FixedTabs,
  ModalTitle,
  AddPatientTabContainer,
  TabHeaderIndicatorButton,
  TabIndicatorText,
} from './styled';

export type FormElementProps = 'active' | 'inactive' | 'invalid';
export interface FormProps {
  basicInfo: FormElementProps;
  otherInfo: FormElementProps;
  nextOfKin: FormElementProps;
  family: FormElementProps;
}

export interface AddPatientProps {
  completed: FormProps;
}

export const basicInfoType = {
  active: CheckmarkIcon,
  inactive: UserIcon,
  invalid: WarningIcon,
};

export const familyInfoType = {
  active: CheckmarkIcon,
  inactive: FamilyIcon,
  invalid: WarningIcon,
};

export const othersInfoType = {
  active: CheckmarkIcon,
  inactive: OthersIcon,
  invalid: WarningIcon,
};

export const nextOfKinType = {
  active: CheckmarkIcon,
  inactive: UsersTwoIcon,
  invalid: WarningIcon,
};

const AddPatient: React.FC<AddPatientProps> = ({ completed }) => {
  const { activeTab } = useContext(TabContext);
  const { basicInfo, otherInfo, nextOfKin, family } = completed;

  const tabs = [
    {
      icon: basicInfoType[basicInfo],
      title: `Basic Info`,
    },
    {
      icon: othersInfoType[otherInfo],
      title: 'Other Info',
    },
    {
      icon: nextOfKinType[nextOfKin],
      title: 'Next of Kin',
    },
    {
      icon: familyInfoType[family],
      title: 'Family',
    },
  ];

  const getIsActive = (value: FormElementProps) => {
    switch (value) {
      case 'active':
      case 'invalid':
        return true;
      case 'inactive':
      default:
        return false;
    }
  };

  return (
    <TabHeaders>
      {tabs.map((tab, index) => {
        const { icon: Icon, title } = tab;
        const isActive = `add-patient-${index}` === activeTab;
        return (
          <TabHeaderItem id={`add-patient-${index}`} key={index}>
            <Row gap={0.5} align="center">
              <TabHeaderIndicatorButton
                size="2.625rem"
                active={
                  isActive ||
                  getIsActive(basicInfo) ||
                  getIsActive(otherInfo) ||
                  getIsActive(nextOfKin) ||
                  getIsActive(family)
                }
              >
                <Icon />
              </TabHeaderIndicatorButton>
              <Column width="max-content" align="center" justify="center">
                {isActive ? (
                  <TabIndicatorText align="left" size="sm" color="secondary">
                    Step {index + 1} of {tabs.length}
                  </TabIndicatorText>
                ) : (
                  <></>
                )}
                <TabIndicatorText
                  color={isActive ? theme.black[100] : theme.grey[500]}
                  weight={isActive ? 'bold' : 'normal'}
                >
                  {title}
                </TabIndicatorText>
              </Column>
            </Row>
          </TabHeaderItem>
        );
      })}
    </TabHeaders>
  );
};

const AddPatientModal = () => {
  const [data, setData] = useState<string>('');
  const [patientData, setPatientData] = useState<PatientProps>(
    {} as PatientProps,
  );
  const [, setLocation] = useLocation();
  const { updateRowData } = useRowData();
  const id = getModalParams('id');
  const isEditPatient = Boolean(id);

  const [completed, setCompleted] = useState<FormProps>({
    basicInfo: 'inactive',
    otherInfo: 'inactive',
    nextOfKin: 'inactive',
    family: 'inactive',
  });

  return (
    <Modal>
      <Column gap={1.5} maxWidth='45rem' width="100%">
        {!data ? (
          <>
            <Column width="100%">
              <ModalTitle>
                {isEditPatient ? 'Edit patient' : 'Add New patient'}
              </ModalTitle>
              <Paragraph color={theme.grey[500]}>
                {isEditPatient
                  ? 'Edit a patient information '
                  : 'Create a new user '}{' '}
                on your company record file.
              </Paragraph>
            </Column>

            <AddPatientTabContainer justify="space-between">
              <Row width="100%">
                <Tabs>
                  <FixedTabs>
                    <AddPatient completed={completed} />
                  </FixedTabs>
                  <AddPatientTabsContent
                    setCompleted={setCompleted}
                    completed={completed}
                    setData={setData}
                    setPatientData={setPatientData}
                    isEditPatient={isEditPatient}
                  />
                </Tabs>
              </Row>
            </AddPatientTabContainer>
          </>
        ) : (
          <ConfirmationPrompt
            title={`Patient ${
              isEditPatient ? 'Edited ' : 'Created '
            } Successfully!!!`}
            message={`You have successfully
            ${
              isEditPatient
                ? 'edited a Patient’s information on the '
                : 'added a new user to the '
            }
            hospital’s record. Click on “Go to Patient’s info page” to add
            more details`}
            onConfirm={() => {
              removeModalHash();
              updateRowData('patient', patientData);
              setLocation(`/admin/patients/${data}`);
            }}
            confirmText="View Patient"
            dismissText="Close"
          />
        )}
      </Column>
    </Modal>
  );
};

export default AddPatientModal;
