import { Component, ReactNode, ErrorInfo } from 'react';

interface ErrorProps { 
  error: Error; 
  errorInfo: ErrorInfo 
};

interface Props {
  fallbackComponent: React.ComponentType<ErrorProps>;
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  };
  
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { fallbackComponent: FallbackComponent, children } = this.props;

    if (hasError) {
      return (
        <FallbackComponent 
          error={error as Error} 
          errorInfo={errorInfo as ErrorInfo} 
        />
      );
    }

    return children;
  }
}