export const useMultiRowParams = <T> (
  params: keyof T,
  state: Record<string, unknown>,
  formState: T,
  setFormState: React.Dispatch<React.SetStateAction<T>>,
) => {

  const addNewRow = () => {
    setFormState((prevState) => ({
      ...prevState,
      [params]: [...(prevState[params] as unknown as typeof state[]), state],
    }));
  };

  const deleteRow = (index: number) => {
    if ((formState[params] as unknown as typeof state[]).length === 1) return null
    const values = [...(formState[params] as unknown as typeof state[])];
    values.splice(index, 1);

    setFormState((prevState) => ({
      ...prevState,
      [params]: values
    }));
  };

  return { addNewRow, deleteRow };
}
