import { theme } from 'src/utils';
import styled, { css } from 'styled-components';
import { Positions } from 'src/types';
import { Column } from '../Flex';


export const DateFilterWrapper = styled(Column)<{
  wrapperPosition?: Positions
}>`
  width: unset;
  list-style: none;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  display: table;
  top: 100%;
  right: 0%;
  min-width: 100%;
  z-index: 9999;
  background-color: ${theme.white[100]};
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-top: 0.375rem;
  box-shadow: 3px 8px 30px 5px rgba(0, 0, 0, 0.08);
  ${({ wrapperPosition }) => {
    return (
      wrapperPosition && css`
        top: ${wrapperPosition.top || '100%'};
        right: ${wrapperPosition.right || '0%'};
        bottom: ${wrapperPosition.bottom || 'unset'};
        left: ${wrapperPosition.left || 'unset'};
      `
    );
  }}
`;
