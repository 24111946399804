import { gql } from "@apollo/client";
import { staffGroupFieldsFragment } from "../Fragments/staffGroup";

export const GET_STAFFGROUPS = gql`
  query GetStaffGroups($input: GetStaffGroupsQuery) {
    getStaffGroups(input: $input) {
      staffGroups {
        ...StaffGroupFields
      }
      total
    }
  }
  ${staffGroupFieldsFragment}
`;

export const GET_STAFFGROUP = gql`
  query GetStaffGroup($getStaffGroupId: ID!) {
    getStaffGroup(id: $getStaffGroupId) {
      ...StaffGroupFields
    }
  }
  ${staffGroupFieldsFragment}
`;