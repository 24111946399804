import { INIT, DOCTORS, APPOINTMENTS } from 'src/constants';
import {
  Appointment,
  CalendarAction,
  CalendarStateType,
  CalendarType,
} from 'src/types';

export const initialState: CalendarStateType = {
  calendar: {
    appointments: APPOINTMENTS,
    physicians: DOCTORS,
    calendarDate: new Date(),
    view: 'day',
  },
  error: undefined,
};

export const ADD_NEW_APPOINTMENT = 'ADD_NEW_APPOINTMENT';
export const CHANGE_DATE = 'CHANGE_DATE';
export const SET_VIEW = 'SET_VIEW';

const reducer = (
  state: CalendarStateType = initialState,
  action: CalendarAction,
): CalendarStateType => {
  const current: CalendarStateType = state;
  const appointment = action.payload?.appointment as Appointment;
  const calendar = action.payload?.calendar as CalendarType;
  const calendarDate = action.payload?.calendarDate as Date;
  const view = action.payload?.view as 'day' | 'week';
  switch (action.type) {
    case INIT:
      return {
        ...current,
        calendar: { ...calendar, calendarDate: new Date() },
      };
    case CHANGE_DATE:
      return {
        ...current,
        calendar: { ...current.calendar, calendarDate },
      };

    case ADD_NEW_APPOINTMENT:
      return {
        ...current,
        calendar: {
          ...current.calendar,
          appointments:
            current.calendar?.appointments &&
            current.calendar?.appointments.length > 0
              ? [...current.calendar.appointments, appointment]
              : [appointment],
        },
      };

    case SET_VIEW:
      return {
        ...current,
        calendar: { ...current.calendar, view },
      };
    default:
      return current;
  }
};

export default reducer;
