import { useState } from 'react';
import { VisibleIcon, InvisibleIcon } from 'src/assets/icons';
import { InputProps } from 'src/types';
import {
  PasswordToggleButton,
  PasswordInputBase,
  PasswordContainer,
  ErrorText,
  InputContainer
} from './styled';
import { InputLabel } from '../InputLabel';


export const PasswordInput: React.FC<InputProps> = ({ 
  label, 
  error,
  required, 
  ...props 
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <InputContainer>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <PasswordContainer>
        <PasswordInputBase
          type={showPassword ? 'text' : 'password'}
          {...props}
        />
        <PasswordToggleButton
          onClick={() => setShowPassword(!showPassword)}
          variant="text"
          type="button"
        >
          {showPassword ? <VisibleIcon /> : <InvisibleIcon />}
        </PasswordToggleButton>
      </PasswordContainer>
      {Boolean(error) && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </InputContainer>
  );
};
