import { Column, Text } from 'src/components';
import { theme } from 'src/utils/variables';

const SummaryInfo: React.FC<{
  label: string;
  value: string;
  labelColor?: number;
}> = ({ label, value, labelColor }) => {
  return (
    <Column className="col">
      <Text
        size="sm"
        className="label-text"
        weight="semibold"
        align="left"
        autoCapitalize="sentences"
        color={theme.grey[labelColor ?? 400]}
        modStyles={{ ma: 0 }}
      >
        {label}
      </Text>
      <Text
        className="value-text"
        autoCapitalize="sentences"
        size="base"
        weight="semibold"
        align="left"
        color={theme.black[100]}
      >
        {value}
      </Text>
    </Column>
  );
};

export default SummaryInfo;
