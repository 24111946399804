import { Modal } from 'src/containers';
import { Text, Column } from 'src/components';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import styled from 'styled-components';

export const AppointmentModal = styled(Modal)`
  & > div {
    padding: 0;
    width: 23.635rem;
  }
  ${ModalChildContainer} {
    padding: 0;
    svg,
    path {
      width: 1rem;
      height: 1rem;
    }
  }
  @media (max-width: 960px) {
    min-width: 100%;
  }
`;

export const ModalTitle = styled(Text)`
  font-size: 1.25rem;
`;

export const AppointmentText = styled(Text)`
  margin: 0;
`;

export const Divider = styled.hr`
  width: 100%;
  border: 0.01875rem solid #cccccc;
`;

export const PaddingWrapper = styled(Column)`
  padding-bottom: 1.25rem;
  padding-top: 1rem;
  padding-inline: 1.5rem;
  &:first-of-type {
    padding-top: 0rem;
  }
`;
