import React from 'react';
import {
  DisableIcon,
  EditIcon,
  ThreeDots,
  BinIcon,
  EnableIcon,
} from 'src/assets/icons';
import { openModal } from 'src/utils';
import { Service } from 'src/types';
import {
  useStaff,
  useRowData,
  useAlert,
  useServices,
} from 'src/state';
import mixpanel from 'mixpanel-browser';
import { Ellipsis, EllipsisButton } from '../styled';

interface ActionColumnProps {
  id: string;
  _id: string;
  row: Record<string, unknown>;
}

export const ServicesActionColumn: React.FC<ActionColumnProps> = ({
  id,
  row,
  _id,
}) => {
  const { deleteService, enableDisableService } = useServices();
  const { updateRowData } = useRowData();
  const { showAlert, removeAlert } = useAlert();
  const {  hasPermission  } = useStaff();
  const isActive =
    (row as unknown as Service).status.toLowerCase() === 'active';

  const handleDelete = async () => {
    mixpanel.track(`Click 'Delete Service' button`, {
      feature: 'Manage Services',
    });
    showAlert({
      title: 'Confirm Delete',
      message: `Do you want to delete service ${row.serviceCode}`,
      confirm: () =>
        deleteService(id || _id).then((res) => res?.data && removeAlert()),
    });
  };

  const disableService = async () => {
    mixpanel.track(`Click 'Edit Service' button`, {
      feature: 'Manage Services',
    });
    showAlert({
      title: `${!isActive ? 'Enable' : 'Disable'} this Service ?`,
      message: `Do you want to ${!isActive ? 'enable' : 'disable'} service ${
        (row as unknown as Service).serviceCode
      }`,
      confirm: () =>
        enableDisableService({
          serviceId: id || _id,
          isDisable: isActive,
        }).then((res) => res?.data && removeAlert()),
    });
  };

  return (
    <>
      <ThreeDots className="icon" />
      <Ellipsis gap={1} justify="flex-end">
        {hasPermission("ENABLE_DISABLE_SERVICE") && (
          <EllipsisButton
            toolTipTitle={isActive ? 'Disable' : 'Enable'}
            variant="text"
            data-testid="disable-service"
            onClick={disableService}
          >
            {isActive ? <DisableIcon /> : <EnableIcon />}
          </EllipsisButton>
        )}
        {hasPermission("EDIT_SERVICE") && (
          <EllipsisButton
            onClick={() => {
              openModal('edit-service', id || _id);
              updateRowData('service', row);
            }}
            data-testid="edit-service"
            toolTipTitle="Edit"
            variant="text"
          >
            <EditIcon />
          </EllipsisButton>
        )}
        {!hasPermission("DELETE_SERVICE") && (
          <EllipsisButton
            toolTipTitle="Delete"
            data-testid="delete-service"
            variant="text"
            onClick={handleDelete}
          >
            <BinIcon />
          </EllipsisButton>
        )}
      </Ellipsis>
    </>
  );
};
