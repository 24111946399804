/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Row, Column, Input, Select } from 'src/components';
import { ArrowUpIcon, DeleteIcon, PlusIcon } from 'src/assets/icons';
import { MultiRowForm } from 'src/containers';
import { useStaffGroups } from 'src/state';
import { theme } from 'src/utils';
import { FormikErrors } from 'formik';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import { useModalParams } from 'src/hooks';
import PermissionsModal from 'src/modals/PermissionsModal';
import { InnerInputGroup, MainInputGroup } from '../styled';
import { defaultState, FormState } from '..';

export type Fields = {
  values: FormState;
  errors: FormikErrors<FormState>;
  handleChange: (e: React.ChangeEvent) => void;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) => void;
};

const AddStaffsForm: React.FC<Fields> = ({
  values,
  errors,
  handleChange,
  setFormState,
  setFieldValue,
}) => {
  const { useApolloStaffGroups } = useStaffGroups();
const {  staffGroups } = useApolloStaffGroups();
  const { params, setModalParams } = useModalParams();

  const addNewStaffRow = () => {
    setFormState({
      ...values,
      staffs: [...values.staffs, defaultState.staffs[0]],
    });
    setFieldValue('staffs', values.staffs);
  };

  const deleteRow = (index: number) => {
    if (values.staffs.length === 1) return;
    const staffs = [...values.staffs];
    staffs.splice(index, 1);
    setFormState({
      ...values,
      staffs,
    });
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    idx: number,
  ) => {
    const suggestions = staffGroups.getStaffGroups.staffGroups
      .find((staffGroup) => staffGroup.id === e.target.value)
      ?.suggestedPermissions.map((suggestion) => suggestion.id);
    const staffs = [...values.staffs];
    staffs[idx] = {
      ...staffs[idx],
      staffGroup: e.target.value,
      permissions: [...(suggestions as string[])],
    };
    setFieldValue('staffs', staffs);
  };

  const allStaffGroups = staffGroups.getStaffGroups.staffGroups.filter(
    (item: { id: string; name: string }) => {
      return item.name.toLowerCase() !== 'owner';
    },
  );


  const getErrorMessage = (idx: number, field: string) => {
    if (field === 'permissions') {
      return (
        (errors as FormikErrors<Record<string, unknown>>)[
          `staffs.${idx}.${field}`
        ] &&
        (
          (errors as FormikErrors<Record<string, unknown>>)[
            `staffs.${idx}.${field}`
          ] as unknown as string[]
        )[0]
      );
    }
    return (
      (errors as FormikErrors<Record<string, unknown>>)[
        `staffs.${idx}.${field}`
      ] &&
      (
        (errors as FormikErrors<Record<string, unknown>>)[
          `staffs.${idx}.${field}`
        ] as unknown as string[]
      )[0]
        .split(' ')
        .splice(2)
        .join(' ')
    );
  };

  return (
    <MultiRowForm
      shouldShowSuggestion={
        values.staffs[values.staffs.length - 1].email !== ''
      }
      onAddNewRow={addNewStaffRow}
    >
      {values.staffs.map(({ email, staffGroup, permissions}, index) => {
        const modalParameter = email || "default"
        return (
          <Column key={index}>
            <MainInputGroup gap={1} align="flex-end">
              <InnerInputGroup
                align="center"
                justify="space-between"
                width="100%"
                gap={1}
              >
                <Input
                  label="Email"
                  placeholder="john@acme.com"
                  name={`staffs[${index}].email`}
                  value={email}
                  required
                  error={getErrorMessage(index, 'email')}
                  onChange={handleChange}
                />
                <Select
                  label="Staff Groups"
                  required
                  name={`staffs[${index}].staffGroup`}
                  value={staffGroup}
                  error={getErrorMessage(index, 'staffGroup')}
                  onChange={(e) => handleSelectChange(e, index)}
                >
                  <option disabled value="">
                    Select a Staff Group
                  </option>
                  {allStaffGroups.map((item: { id: string; name: string }) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Input
                  label="Extra Permissions"
                  className='text-left'
                  data-testid="extraPermissions"
                  required
                  value={email
                    ? `${permissions.length > 0 ? permissions.length : 'No'} permissions selected`
                    : 'Select Default Permissions'}
                  error={getErrorMessage(index, 'defaultPermissions')}
                  readOnly
                  type="button"
                  onClick={() => setModalParams({ "edit-permission": modalParameter })}
                  icon={email ? <PlusIcon className='fill-primary' /> : <ArrowUpIcon />}
                />
              </InnerInputGroup>
              <Row width="2.875rem">
                <MultiRowDeleteButton
                  variant="solid"
                  background={theme.grey[100]}
                  color={theme.grey[200]}
                  size="2.5rem"
                  type="button"
                  onClick={() => deleteRow(index)}
                  onFocus={(e) => e.stopPropagation()}
                >
                  <DeleteIcon />
                </MultiRowDeleteButton>
              </Row>
            </MainInputGroup>
            {params.get("edit-permission") === modalParameter && (
              <PermissionsModal
                setModalParams={setModalParams}
                modalParams={modalParameter}
                defaultPermissions={permissions}
                setFieldValue={setFieldValue}
                name={`staffs[${index}].permissions`}
              />
            )}
          </Column>
        )
      })}
    </MultiRowForm>
  );
};

export default AddStaffsForm;
