import styled from 'styled-components';
import { Column, Input } from 'src/components';
import { theme, font } from 'src/utils';
import { DropdownLabelItem } from '../Dropdown/styled';
import { MultiSelectContainer } from '../MultiSelect/styled';

export const DropdownLabelContainer = styled(DropdownLabelItem)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.25rem;
  gap: 0.625rem;
  img {
    padding: 0;
  }
`;

export const DropdownSearchWrapper = styled(MultiSelectContainer)`
  gap: 0.5rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  top: 100%;
  width: 100%;
  min-width: 100%;
  transform: translateY(-15px);
  max-height: 20rem;
  overflow: hidden;
`;

export const DropdownButton = styled(Input)`
  &[type="button"] {
    color: ${theme.grey[200]};
    text-align: left;
    font-weight: ${font.weights.bold};
    @media (max-width: 960px) {
      font-size: ${font.sizes.xs};
    }
  }
`;

export const LabelContainer = styled(Column)`
  min-height: 100%;
  min-width: 100%;
  overflow: auto;
  gap: 0.5rem;
`;
