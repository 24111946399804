import moment from "moment";

export const generateTimeObject = ( 
  date: string, 
  time: string
) => moment(date || moment().format("YYYY-MM-DD"))
  .set({
    hour: parseInt(time.split(':')[0], 10),
    minute: parseInt(time.split(':')[1], 10),
  })
  .toDate();