import { useFormikContext } from 'formik';
import {
  Column,
  Row,
  Paragraph,
  DropdownSearch,
  Button,
  Text,
  Card,
  AvatarGroup,
  Avatar,
  SkeletonWrapper,
} from 'src/components';
import { Patient, PatientProps } from 'src/types';
import { font, generateAvatar, getModalParams, theme } from 'src/utils';
import { PlusIcon } from 'src/assets/icons';
import { useApolloSearch } from 'src/hooks';
import { useEffect, useState } from 'react';
import { useAlert, useStaff } from 'src/state';
import { FamiliesProps, FamilyProps } from 'src/types/family.type';
import Skeleton from 'react-loading-skeleton';
import mixpanel from 'mixpanel-browser';
import { ModalSubTitle } from '../styled';
import { FamilyButton } from './styled';
import { useFamily } from './api';


const FamilyInfo = () => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldError,
    initialValues,
    setFieldTouched,
  } = useFormikContext<Patient>();
  const { staff } = useStaff();
  const patientId = getModalParams('id') as string;
  const hashValue = window.location.hash.slice(1);
  const [data, setData] = useState({} as FamiliesProps);
  const [page, setPage] = useState<number>(1);
  const { showAlert, removeAlert } = useAlert();
  const loadMore = () => setPage((prev) => prev + 1);
  const { handleSearchChange, searchValue, setSearchValue } = useApolloSearch();

  const isEditPatient = hashValue.startsWith('edit-patient');
  const {
    useCreateFamily,
    useSearchFamily,
    useGetFamily,
    useRemoveFamilyMember,
  } = useFamily();

  const { createFamily } = useCreateFamily();
  const { removeFamilyMember, data: removedFamilyData } =
    useRemoveFamilyMember();
  const { data: familyB, loading: familyLoading } = useGetFamily(
    values.family,
    !isEditPatient || !initialValues.family,
  );

  const patientExists = familyB?.getFamily?.patients
    ?.map((el) => el.id)
    .includes(patientId);

  const {
    data: result,
    loading,
    refetch,
  } = useSearchFamily({
    limit: 10,
    search: searchValue,
    organisation: staff.getStaff.facility.organisation.id,
    page,
  });

  useEffect(() => {
    const isPageGreaterThanOne = page > 1;
    setData((prev) => ({
      ...prev,
      total: result?.searchFamily?.total as unknown as number,
      families: isPageGreaterThanOne
        ? [
            ...((prev.families || []) as unknown as FamilyProps[]),
            ...((result?.searchFamily?.families || []) as FamilyProps[]),
          ]
        : (result?.searchFamily?.families as FamilyProps[]),
    }));

    if (removedFamilyData) {
      removeAlert();
      setFieldValue('family', '');
      setSearchValue('');
    }
  }, [
    page,
    removeAlert,
    removedFamilyData,
    result,
    setFieldValue,
    setSearchValue,
  ]);

  const returnFamilyMembers = (family: FamilyProps) => {
    if (family?.patients?.length > 0) {
      return (
        <Text
          transform="none"
          size="sm"
          weight="semibold"
          modStyles={{ my: 0 }}
        >
          Family members include:{' '}
          {family?.patients?.reduce(
            (acc: string, patient: PatientProps, index: number, arr) => {
              const { user } = patient;
              const fullName =
                user.fullName || `${user.firstName} ${user.lastName}`;

              if (index === 0) {
                return fullName;
              }
              if (index === arr.length - 3) {
                return `${acc}, ...,`;
              }
              if (index === arr.length - 1) {
                return `${acc} and ${fullName}`;
              }
              return `${acc}, ${fullName}`;
            },
            '',
          )}
        </Text>
      );    }

    return (
      <Text size="sm" transform="initial" modStyles={{ my: 0 }}>
        No patients attached to this family
      </Text>
    );
  };

  const renderFamily = (dataB: Record<string, unknown>) => {
    const family = dataB as unknown as FamilyProps;

    return (
      <Column gap={0.625}>
        <Text weight="bold" color={theme.black[100]} modStyles={{ my: 0 }}>
          {`${family?.name} - ${family?.familyId}`}
          {family?.patients?.length > 0 && (
            <Text transform="lowercase" modStyles={{ my: 0 }}>
              {` - ${family?.patients?.length} member(s)`}
            </Text>
          )}
        </Text>
        {returnFamilyMembers(family)}
      </Column>
    );
  };

  const FamilyCard = () => {
    const family = familyB?.getFamily as unknown as FamilyProps;
    const avatars = family?.patients?.map(
      ({ user }) => user.profileAvatar || user.fullName,
    ) as string[];

    return (
      <Card width="100%">
        <Column width="100%" align="flex-start">
          <AvatarGroup justify="flex-start" gap={0.75}>
            {avatars?.map((url, index) => (
              <Avatar key={index} src={generateAvatar(url, 12)} size={30} />
            ))}
          </AvatarGroup>
          <Text
            weight="bold"
            transform="capitalize"
            color={theme.black[100]}
            modStyles={{ mb: 0 }}
          >
            {`${family?.name}`}
            <Text transform="lowercase" modStyles={{ my: 0 }}>
              {` - ${family?.patients?.length} member(s)`}
            </Text>
          </Text>
          {returnFamilyMembers(family)}
          <Button
            width="max-content"
            variant="text"
            modStyles={{ px: 0, mt: 1, py: 0, fontSize: font.sizes.xs }}
            background={theme.red[500]}
            onClick={changeFamily}
          >
            Change Family
          </Button>
        </Column>
      </Card>
    );
  };

  const createNewFamily = () => {
    mixpanel.track('Click Create Patient Family');
    if (!searchValue) {
      setFieldTouched('family', true);
      setFieldError('family', 'Enter a name to create a family');
      return;
    }

    setFieldError('family', '');
    showAlert({
      type: 'decision',
      title: 'Create New Family',
      message: `Are you sure you want to create a new family card with the name "${searchValue}"?`,
      confirmText: `Yes, Create`,
      closeText: "No, Don't",
      confirm: () =>
        createFamily({
          name: searchValue,
        }).then((res) => {
          if (res) {
            refetch();
            setFieldValue('family', res.data?.createFamily.family.id);
            removeAlert();
          }
        }),
    });
  };

  const changeFamily = () => {
    showAlert({
      title: 'Remove a Patient',
      type: 'decision',
      message: `Are you sure you want to remove this patient from ${familyB?.getFamily?.name} Family plan?`,
      closeText: 'No',
      confirmText: 'Yes',
      confirm: () => removeFamilyMember(patientId),
    });
  };

  return (
    <Column justify="space-between" gap={1} align="center">
      <Column>
        <ModalSubTitle data-testid="section-title">
          Family Information
        </ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          Connect patient to family or create a new family
        </Paragraph>
      </Column>
      {familyLoading && (
        <SkeletonWrapper>
          <Skeleton width="100%" height={150} />
        </SkeletonWrapper>
      )}
      {isEditPatient && patientExists && !familyLoading && <FamilyCard />}
      {!patientExists && !familyLoading && (
        <Row gap={1} align="flex-end">
          <DropdownSearch
            label="Family Name"
            name="family"
            type="text"
            icon={false}
            placeholder="Search by Name or Card Number"
            value={values?.family || ''}
            loading={loading}
            description={
              searchValue && (
                <Text weight="semibold">
                  {`Family cards matching "${searchValue}"`}
                </Text>
              )
            }
            onLoadMore={loadMore}
            hasNextPage={data?.families?.length !== data?.total}
            render={renderFamily}
            valueAccessor="id"
            labelAccessor={['name']}
            onSearchInputChange={handleSearchChange}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.family ? (errors.family as string) : ''}
            data={data?.families as unknown as Record<string, unknown>[]}
          />
          <FamilyButton
            type="button"
            width="max-content"
            variant="text"
            onClick={createNewFamily}
            background={theme.red[500]}
          >
            <PlusIcon />
            Create New Family
          </FamilyButton>
        </Row>
      )}
    </Column>
  );
};

export default FamilyInfo;