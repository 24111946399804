import { Row, Avatar, Text } from "src/components"
import { theme, removeModalHash, generateAvatar } from "src/utils"
import { useLocation } from "wouter"
import { useAlert } from "src/state"
import { useFamily } from "src/modals/AddPatient/familyInfo/api"
import { StyledButton } from "./styledButton"
import { IndividualDetailsProps } from "../types"

export const IndividualDetails: React.FC<IndividualDetailsProps> = ({
  id,
  user,
  familyName
}) => {
  const { showAlert, removeAlert } = useAlert();
  const [, setLocation] = useLocation();
  const { useRemoveFamilyMember } = useFamily();
  const { removeFamilyMember: removePatient } = useRemoveFamilyMember();
  

  const removeFamilyMember = () => {
    return (
      showAlert({
        title: 'Remove a Patient',
        type: "decision",
        message: `Are you sure you want to remove this patient from ${familyName}  Family plan?`,
        closeText: "No",
        confirmText: "Yes",
        confirm: () => removePatient(id)?.then((res) => {
          if (res) {
            removeAlert()
          }
        })
      })
    )
  }

  return (
    <Row 
      background={theme.grey[100]} 
      modStyles={{ br: 0.625, py: 0.5, px: 0.5 }} 
      align="center"
      justify='space-between' 
      width="100%"
    >
      <Row gap={0.5} width="max-content" align="center">
        <Avatar 
          size={30}
          modStyles={{ py: 0, px: 0 }} 
          src={generateAvatar(`${user?.firstName} ${user?.lastName}`, 12)} 
        />
        <Text modStyles={{ my: 0}} size="sm" color="#282828">
          {`${user?.firstName} ${user?.lastName}`}
        </Text>
      </Row>
      <Row align="center" width="max-content" gap={1}>
        <StyledButton 
          onClick={() => {
            removeModalHash()
            setLocation(`/admin/patients/${id}`)
          }}
        >
          View Patient
        </StyledButton>
        <StyledButton onClick={removeFamilyMember}>
          Remove
        </StyledButton>
      </Row>
    </Row>
  )
}