import { Text } from 'src/components';
import { theme } from 'src/utils/variables';
import { LeftArrow, RightArrow } from 'src/assets/icons';
import {
  Pages,
  PaginationWrapper,
  PageButtons,
} from 'src/containers/Table/styled';

export interface PaginationType {
  pageSize: number;
  total: number;
  currentPage: number;
  handleGoToPage: (page: number) => void;
}

const Pagination: React.FC<PaginationType> = ({
  total,
  pageSize,
  currentPage,
  handleGoToPage,
}) => {
  const totalPages = Math.ceil((total || 0) / pageSize || 0);
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const paginationInfo =
    total > 0
      ? `${indexOfFirstItem + 1}-${Math.min(indexOfLastItem, total)}/${total}`
      : '0-0/0';

  return (
    <PaginationWrapper>
      <Text color={theme.black[100]} weight="bold">
        Showing: {paginationInfo}
      </Text>
      <Pages>
        <PageButtons
          onClick={() => handleGoToPage(currentPage - 1)}
          disabled={currentPage === 1}
          isPrevOrNext
          variant="text"
          color="secondary"
        >
          <LeftArrow />
        </PageButtons>
        {[...Array(totalPages)].map((_, index) => {
          const pageNumber = index + 1;
          const isCurrentPage = pageNumber === currentPage;
          const shouldRender =
            Math.abs(pageNumber - currentPage) <= 2 ||
            pageNumber === 1 ||
            pageNumber === totalPages;
          const renderEllipsis =
            Math.abs(pageNumber - currentPage) === 3 &&
            pageNumber !== 1 &&
            pageNumber !== totalPages;

          if (renderEllipsis) {
            return (
              <PageButtons variant="text" color="grey" key={index}>
                ...
              </PageButtons>
            );
          }

          if (!shouldRender) {
            return null;
          }

          return (
            <PageButtons
              onClick={() => handleGoToPage(pageNumber)}
              variant={isCurrentPage ? 'solid' : 'text'}
              background={isCurrentPage ? 'secondary' : 'grey'}
              key={index}
            >
              {pageNumber}
            </PageButtons>
          );
        })}
        <PageButtons
          isPrevOrNext
          variant="text"
          background="secondary"
          onClick={() => handleGoToPage(currentPage + 1)}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          <RightArrow />
        </PageButtons>
      </Pages>
    </PaginationWrapper>
  );
};

export default Pagination;
