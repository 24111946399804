import * as React from 'react';
import { ArrowUpIcon } from 'src/assets/icons';
import useClickAway from 'src/utils/useClickAway';
import { theme } from 'src/utils/variables';
import { MultiSelectProps } from 'src/types';
import _ from 'lodash';
import { Row } from '../Flex';
import {
  SelectButton,
  MultiSelectContainer,
  MultiSelectWrapper,
  ErrorText,
} from './styled';
import { InputLabel } from '../InputLabel';
import { Text } from '../Typography';
import { Checkbox } from '../Checkbox';

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  icon = <ArrowUpIcon />,
  defaultSelected = [],
  label,
  error,
  required,
  ...props
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);
  const onOpenMultiSelect = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <MultiSelectWrapper required={!label && required}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <SelectButton
        onClick={onOpenMultiSelect}
        type="button"
        data-testid={props.dataTestId}
      >
        <Text size="sm" weight="bold" color={theme.grey[400]}>
          {defaultSelected.length > 0
            ? `${defaultSelected.length} selected`
            : props.placeholder}
        </Text>{' '}
        {icon}
      </SelectButton>
      {isComponentVisible && (
        <MultiSelectContainer ref={ref}>
          {options &&
            options.map(({ label: optionLabel, value }, idx) => (
              <Row width="max-content" key={idx} align="center">
                <Checkbox
                  id={value}
                  data-testid={_.lowerCase(optionLabel)}
                  defaultChecked={defaultSelected
                    ?.map((v) => v === value)
                    .includes(true)}
                  value={value}
                  {...props}
                />
                <InputLabel htmlFor={value} key={idx}>
                  {_.startCase(optionLabel)}
                </InputLabel>
              </Row>
            ))}
        </MultiSelectContainer>
      )}
      {Boolean(error) && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </MultiSelectWrapper>
  );
};
