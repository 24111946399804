import moment from 'moment';
import Validator from 'validatorjs';

export const CURRENT_DATE = moment().format("YYYY-MM-DD");
export const CURRENT_TIME = moment().format("hh:mm");
export const MIN_APPOINTMENT_TIME = 30;
export const NAME_PATTERN = /^[a-zA-Z'-]+$/;
export const FULL_NAME_PATTERN = /^[a-zA-Z'-]+ [a-zA-Z'-]+$/;
export const TIME_PATTERN = /^(?:[01]\d|2[0123]):(?:[012345]\d)/;
export const EMAIL_PATTERN = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
export const MIN_NAME_CHARACTER = 2;
export const MAX_NAME_CHARACTER = 25;
export const MAX_FULL_NAME_CHARACTER = 50;
export const MIN_PASSWORD_CHARACTER = 6;
export const MAX_PASSWORD_CHARACTER = 50;
export const MIN_PHONE_NUMBER_CHARACTER = 11;
export const MAX_PHONE_NUMBER_CHARACTER = 13;
export const CODE_NUMBER = 6;


Validator.register(
  'greater',
  (value, req) => {
    return value > req;
  },
  'The end time must be greater than start time',
);

Validator.register(
  'at_least',
  (value, req) => {
    if (moment(req).isSame(moment().format('YYYY-MM-DD'))) {
      return value >= moment().format('HH:mm');
    }
    return true;
  },
  'The start time must be current or future time',
);

Validator.register(
  'duration',
  (value, req) => {
    return moment(value as string, "hh:mm")
      .diff(moment(req, "hh:mm"), 'minutes') >= MIN_APPOINTMENT_TIME;
  },
  'Duration must be at least 30 minutes',
);

Validator.register(
  'max_time',
  (value) => {
    return moment(value as string, "hh:mm")
      .isBefore(moment('23:30', 'hh:mm'));
  },
  'The start time must be less than 23:30',
);

Validator.register('same_or_after', (value, req) => {
  return typeof(value) === "string" && moment(value).isSameOrAfter(req)
}, 'The :attribute must be greater than start time');

Validator.register('max_date', (value) => {
  return CURRENT_DATE >= value
}, ':attribute should be present or past date');

Validator.register('name', (value) => {
  if (typeof (value) === "string" && value.length >= MIN_NAME_CHARACTER && value.length <= MAX_NAME_CHARACTER) {
    return NAME_PATTERN.test(value)
  }
  return true
}, 'Invalid format specified');

Validator.register('full_name', (value) => {
  if (typeof (value) === "string" && value.length >= MIN_NAME_CHARACTER && value.length <= MAX_FULL_NAME_CHARACTER) {
    return FULL_NAME_PATTERN.test(value)
  }
  return true
}, 'Invalid format specified');


export const ERROR_RESPONSES = {
  invalidEmail: 'Enter a valid Email',
  tooShort: (val: number) => `${val} characters minimum`,
  tooLong: (val: number) => `${val} characters maximum`,
  between: (min: number, max: number) => `Field must be between ${min} and ${max} characters`,
  fullName: `Enter your full name`,
  name: `Enter your name`,
  date: `Invalid date`,
  time: `Invalid time`,
  string: `Value can be letters, numbers and symbols`,
  nameRegex: `Alphabets, apostrophe, hyphens only`,
  fullNameRegex: `Alphabets, apostrophes, hyphens and single space between names allowed`,
  number: `Values must be numbers`,
  required: `Field is required`,
  requiredCheck: `Accept our terms and conditions`,
  passwordMatch: `Passwords must match`,
  futureDate: `Date must be today or in the future`,
  birthDate: `Date must be today or in the past`,
  futureTime: `Value must be greater than start time`,
  array: `Value must be an array of characters`,
  minTime: `Value should be present or future time`,
  atLeastOne: `Select at least one item`,
  code: (val: number) => `Field must be ${val} characters`,
  unauthorized: 'Unauthorized',
  disabledAccount: 'Account is disabled',
  userNotFound: 'User not found',
  invalidName: (name: string) => `${name} must be at least 2 characters long`,
  dependent: (name?: string) => `Field is dependent on ${name || "another field"}`,
  notEmpty: `Field cannot be empty`
};

export const RULES = {
  name: [
    'required', 
    `min:${MIN_NAME_CHARACTER}`, 
    `max:${MAX_NAME_CHARACTER}`, 
    `name`
  ],
  fullName: [
    'required', 
    `min:${MIN_NAME_CHARACTER}`, 
    `max:${MAX_FULL_NAME_CHARACTER}`, 
    `full_name`
  ],
  email: [`required`, `email`],
  requiredfield: 'required',
  date: [`required`, `date`,`same_or_after:${CURRENT_DATE}`],
  birthDate: `required|date|max_date:${CURRENT_DATE}`,
  startTime: [
    'required', 
    `regex:${TIME_PATTERN}`
  ],
  endTime: [
    'required', 
    `regex:${TIME_PATTERN}`, 
    `equal_or_less:startTime`
  ],
  dueTime: [
    'required', 
    `regex:${TIME_PATTERN}`, 
    `min_time:date`
  ],
  password: `required|min:${MIN_PASSWORD_CHARACTER}|max:${MAX_PASSWORD_CHARACTER}`,
  confirmPassword: 'required|same:password',
  phoneNumber: 
  `required|min:${MIN_PHONE_NUMBER_CHARACTER}|max:${MAX_PHONE_NUMBER_CHARACTER}`,
  isChecked: 'required|accepted',
  string: 'string',
  code: `required|size:${CODE_NUMBER}`,
};

export const EMAIL_OPTIONS = {
  'required.email': ERROR_RESPONSES.required,
  'email.email': ERROR_RESPONSES.invalidEmail,
};

export const PASSWORD_OPTIONS = {
  'required.password': ERROR_RESPONSES.required,
  'min.password': ERROR_RESPONSES.tooShort(MIN_PASSWORD_CHARACTER),
  'max.password': ERROR_RESPONSES.tooLong(MAX_PASSWORD_CHARACTER),
};

export const NAME_OPTIONS = {
  'required.name': ERROR_RESPONSES.name,
  'min.name': ERROR_RESPONSES.tooShort(MIN_NAME_CHARACTER),
  'max.name': ERROR_RESPONSES.tooLong(MAX_NAME_CHARACTER),
  'name.name': ERROR_RESPONSES.nameRegex,
};

export const FULL_NAME_OPTIONS = {
  'required.fullName': ERROR_RESPONSES.fullName,
  'min.fullName': ERROR_RESPONSES.tooShort(MIN_NAME_CHARACTER),
  'max.fullName': ERROR_RESPONSES.tooLong(MAX_FULL_NAME_CHARACTER),
  'full_name.fullName': ERROR_RESPONSES.fullNameRegex,
};

export const IS_CHECKED_OPTIONS = {
  'required.isChecked': ERROR_RESPONSES.required,
  'accepted.isChecked': ERROR_RESPONSES.requiredCheck,
};

export const REQUIRED_FIELD_OPTIONS = {
  'required': ERROR_RESPONSES.required,
};

export const CONFIRM_PASSWORD_OPTIONS = {
  'required.confirmPassword': ERROR_RESPONSES.required,
  'same.confirmPassword': ERROR_RESPONSES.passwordMatch,
};

export const DATE_OPTIONS = {
  'required.date': ERROR_RESPONSES.required,
  'date.date': ERROR_RESPONSES.date,
  'same_or_after.date': ERROR_RESPONSES.futureDate,
};

export const BIRTH_DATE_OPTIONS = {
  'required.birthDate': ERROR_RESPONSES.required,
  'date.birthDate': ERROR_RESPONSES.date,
  'max_date.birthDate': ERROR_RESPONSES.birthDate,
};

export const START_TIME_OPTIONS = {
  'required.startTime': ERROR_RESPONSES.required,
  'regex.startTime': ERROR_RESPONSES.time,
};

export const END_TIME_OPTIONS = {
  'required.endTime': ERROR_RESPONSES.required,
  'regex.endTime': ERROR_RESPONSES.time,
  'greater.endTime': ERROR_RESPONSES.futureTime,
};

export const DUE_TIME_OPTIONS = {
  'required.dueTime': ERROR_RESPONSES.required,
  'regex.dueTime': ERROR_RESPONSES.time,
  'min_time.dueTime': ERROR_RESPONSES.minTime,
};

export const PHONE_NUMBER_OPTIONS = {
  'required.phoneNumber': ERROR_RESPONSES.required,
  'min.phoneNumber': ERROR_RESPONSES.tooShort(MIN_PHONE_NUMBER_CHARACTER),
  'max.phoneNumber': ERROR_RESPONSES.tooLong(MAX_PHONE_NUMBER_CHARACTER),
  'numeric.phoneNumber': ERROR_RESPONSES.number,
};

export const STRING_OPTIONS = {
  'string.string': ERROR_RESPONSES.string,
};

export const SIZE_OPTIONS = {
  'required.code': ERROR_RESPONSES.required,
  'size.code': ERROR_RESPONSES.code(6),
};