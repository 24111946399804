import { ThreeDots, BinIcon } from 'src/assets/icons';
import { useAlert, useHMOs, useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { Ellipsis, EllipsisButton } from '../styled';


interface ActionColumnProps {
  id: string;
  row: Record<string, unknown>;
}

export const HmoActionColumn: React.FC<ActionColumnProps> = ({ id, row }) => {
  const { useDeleteHMO } = useHMOs();
  const { showAlert, removeAlert } = useAlert();
  const { deleteHMO,  } = useDeleteHMO();
  const { hasPermission } = useStaff();

  const handleDelete = () => {
    mixpanel.track(`Click 'Delete HMO' button`, {
      feature: 'Manage HMO',
    });
    showAlert({
      type: 'decision',
      title: 'Confirm Delete',
      message: `Do you want to delete ${row.name}`,
      confirm: () => deleteHMO(id)?.then((res) => {
        if (res?.data) {
          removeAlert()
        }
      })
    });
  };

  return (
    <>
      <ThreeDots className='icon' />
      <Ellipsis gap={1} justify="flex-end">
        {/* <EllipsisButton
          onClick={(e) => {
            e.preventDefault();
            if (!hasPermission('MANAGE_HMO')) {
              return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
            }
            openModal('#edit-hmo', id);
          }}
          data-testid="hmo-edit"
          toolTipTitle="Edit"
          variant="text"
        >
          <EditIcon />
        </EllipsisButton> */}
        {hasPermission("DELETE_HMO") && (
          <EllipsisButton
            toolTipTitle="Delete"
            variant="text"
            data-testid="hmo-delete"
            onClick={(e) => {
              e.stopPropagation()
              handleDelete()
            }}
          >
            <BinIcon />
          </EllipsisButton>
        )}
      </Ellipsis>
    </>
  );
};