import * as React from 'react';
import { AlertAction, AlertPayloadType } from 'src/types';
import { SHOW_ALERT, REMOVE_ALERT } from './reducer';


const showAlert =
  (dispatch: React.Dispatch<AlertAction>) =>
    async (value: AlertPayloadType) => {
      dispatch({
        type: SHOW_ALERT,
        payload: { ...value },
      });
    };

const removeAlert =
  (dispatch: React.Dispatch<AlertAction>) => async () => {
    dispatch({
      type: REMOVE_ALERT
    });
  };

export default {
  removeAlert,
  showAlert,
};

