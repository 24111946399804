import Skeleton from 'react-loading-skeleton';
import { Column } from '../Flex';

interface SkeletonColumnProps {
  count?: number;
  width?: number;
  height?: number;
  gap?: number
}

export const SkeletonColumn: React.FC<SkeletonColumnProps> = ({
  gap = 1,
  width = 120,
  height = 10,
  count = 4
}) => {

  return (
    <Column width='100%' align="center" gap={gap}>
      {Array.from({ length: count }, (_, index) => <Skeleton key={index} width={width} height={height} />)}
    </Column>
  )
};

