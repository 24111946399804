/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SEARCH_RECORDS } from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import { TimelineDataType } from 'src/types/timeline.type';
import { useLocation, useRoute } from 'wouter';
import {
  RootState,
  useAppSelector,
  useAppDispatch,
  setTimeline,
  useRecordApis,
} from 'src/store';
import { useStaff, useToast } from 'src/state';
import { RangeData } from 'src/components';

type ResponseData = {
  medicalTimeline: TimelineDataType[];
  total: number;
};

type TimelineResponse = {
  medicalTimeline?: ResponseData;
  search?: {
    result: TimelineDataType[];
    total: number;
  };
};

export const transformResponse = (data: TimelineResponse) => {
  if (data.medicalTimeline) {
    const formattedData = data.medicalTimeline?.medicalTimeline.map((t) => {
      if (
        t.__typename === 'Activity' ||
        t.__typename === 'ActivitySearchType'
      ) {
        t.createdAt = t.activityCreateAt;
      }
      return t;
    });
    return {
      medicalTimeline: formattedData,
      total: data?.medicalTimeline.total,
    };
  }
  return {
    medicalTimeline: data.search?.result as TimelineDataType[],
    total: data?.search?.total as number,
  };
};

const useTimeline = (_id?: string) => {
  const [location] = useLocation();
  const { hasPermission } = useStaff();
  const [, params] = useRoute(
    !location.includes('print')
      ? '/admin/patients/:id'
      : '/admin/patients/:id/print',
  );
  const patientId = _id || (params?.id as string);
  const allRecords = useAppSelector((state: RootState) => state.records);
  const patientRecords = allRecords[patientId];
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const limit = 30;

  const {
    getTimeline,
    timelineError,
    timelineLoading = true,
    fetchMoreTimeline,
    fetchMoreSearchData,
    fetchMoreSearchError,
    fetchMoreTimelineError,
    fetchMoreSearchLoading,
    fetchMoreTimilineLoading,
  } = useRecordApis();

  const [filteredData, setFilteredData] =
    useState<ResponseData>(patientRecords);

  const {
    handleSearchChange,
    searchData,
    searchLoading,
    searchValue,
    searchQuery,
  } = useSearch(SEARCH_RECORDS, {
    onCompleted: (d: unknown) =>
      setFilteredData(transformResponse(d as TimelineResponse)),
  });

  const fetchTimeline = useCallback(async () => {
    if (!hasPermission("VIEW_MEDICAL_TIMELINE")) return;
    await getTimeline({
      variables: {
        query: {
          patientId,
          page: 1,
          limit,
        },
      },
      onCompleted(d) {
        const data = transformResponse(d);
        dispatch(setTimeline({ patientId, records: data }));
        setFilteredData(data);
      },
    });
  }, [dispatch, getTimeline, hasPermission, patientId]);

  useEffect(() => {
    if (!patientRecords && patientId) {
      fetchTimeline();
    } else {
      setFilteredData(patientRecords);
    }
  }, [fetchTimeline, patientId, patientRecords]);

  const [dateFilterApplied, setDateFilterApplied] = useState(false);
  const [dateRange, setDateRange] = useState<RangeData>({
    startDate: null as unknown as Date,
    endDate: new Date(''),
    key: 'selection',
  });

  const formattedRange = {
    startDate: moment(dateRange.startDate).format('Do MMM, yyyy'),
    endDate: moment(dateRange.endDate).format('Do MMM, yyyy'),
  };

  const getDateFilters = () => {
    if (dateFilterApplied) {
      return {
        startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
        endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
      };
    }
    return {};
  };

  const applyFilters = () => {
    setDateFilterApplied(true);

    searchQuery({
      variables: {
        input: {
          limit,
          page: 1,
          search: searchValue || '',
          recordFilters: {
            withActivity: true,
            patient: patientId,
          },
          searchIn: 'Records',
          startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
          endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
        },
      },
      onCompleted(data) {
        setFilteredData(transformResponse(data));
      },
    });
  };

  const clearFilters = () => {
    setFilteredData(patientRecords);
    setDateFilterApplied(false);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilteredData(patientRecords);
    }
    handleSearchChange(e, {
      limit,
      page: 1,
      recordFilters: {
        withActivity: true,
        patient: patientId,
      },
      searchIn: 'Records',
      ...getDateFilters(),
    });
  };

  const currentPage = Math.ceil(
    (filteredData?.medicalTimeline?.length as number) / limit,
  );

  const fetchMore = async () => {
    if (searchValue || dateFilterApplied) {
      await fetchMoreSearchData({
        variables: {
          input: {
            limit,
            page: currentPage + 1,
            search: searchValue || '',
            recordFilters: {
              withActivity: true,
              patient: patientId,
            },
            searchIn: 'Records',
            ...getDateFilters(),
          },
        },
        onCompleted(d) {
          if (fetchMoreSearchError) {
            showToast('Error fetching more records', 'error');
          } else {
            setFilteredData({
              ...filteredData,
              medicalTimeline: [
                ...filteredData.medicalTimeline,
                ...transformResponse(d).medicalTimeline,
              ],
            });
          }
        },
      });
      return;
    }
    await fetchMoreTimeline({
      variables: {
        query: {
          patientId,
          page: currentPage + 1,
          limit,
        },
      },
      onCompleted(d) {
        if (fetchMoreTimelineError) {
          showToast('Error fetching more records', 'error');
        } else {
          setFilteredData((prev) => ({
            ...prev,
            medicalTimeline: [
              ...prev.medicalTimeline,
              ...transformResponse(d).medicalTimeline,
            ],
          }));
          dispatch(
            setTimeline({
              patientId,
              records: {
                ...patientRecords,
                medicalTimeline: [
                  ...patientRecords.medicalTimeline,
                  ...transformResponse(d).medicalTimeline,
                ],
              },
            }),
          );
        }
      },
    });
  };

  const getRecordFromCache = (pid: string, id: string) => {
    const record = allRecords[pid]?.medicalTimeline.find((r) => r?._id === id);
    return record;
  };

  return {
    limit,
    fetchMore,
    fetchTimeline,
    patientId,
    searchData,
    searchValue,
    setTimeline,
    clearFilters,
    applyFilters,
    formattedRange,
    onSearchChange,
    handleSearchChange,
    timelineLoading,
    timelineError,
    searchLoading,
    setDateRange,
    filteredData,
    dateFilterApplied,
    dateRange,
    currentPage,
    getRecordFromCache,
    fetchMoreLoading: fetchMoreSearchLoading || fetchMoreTimilineLoading,
  };
};

export { useTimeline };
