import { PencilNote, DownArrow, ArrowUpload } from 'src/assets/icons';
import { Card, Column, Row } from 'src/components';
import { Accordion } from 'src/containers';
import { theme } from 'src/utils';
import { useContext } from 'react';
import { useLocation } from 'wouter';
import { ProductBatchType } from 'src/types';
import _ from 'lodash';
import moment from 'moment';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { AccordionButton } from '../styled';
import ProductInfo from './ProductInfo';
import Tag, { ColorKey } from './Tag';
import { ViewContext } from '../product';

export const statusToColorMap: { [key: string]: ColorKey } = {
  OUT_OF_STOCK: 'orange',
  IN_STOCK: 'green',
  EXPIRING_SOON: 'yellow',
};

const now = moment().utc();

const isExpired = (date: string) => now.isAfter(moment.utc(date));

const BatchCard: React.FC<{ batch?: ProductBatchType; productId: string }> = ({
  batch,
  productId,
}) => {
  const { hasPermission } = useStaff();
  const { setView } = useContext(ViewContext);
  const [, setLocation] = useLocation();

  return (
    <Card width="100%" modStyles={{ pa: 1 }} data-testid="batch-card">
      <Accordion>
        <Accordion.Header>
          <Row justify="space-between">
            <Row gap={0.25}>
              <Tag color="blue" value={batch?.batchNumber as string} />
              <>
                {batch?.status.map((stat) => (
                  <Tag
                    key={stat}
                    color={statusToColorMap[stat as string]}
                    value={_.capitalize(stat?.split('_')?.join(' '))}
                  />
                ))}
              </>
            </Row>
            <Row align="center" width="max-content">
              {hasPermission('PUSH_PRODUCT_TO_SHELF') && (
                <AccordionButton
                  color={theme.secondary[700]}
                  variant="text"
                  width="max-content"
                  data-testid="push-to-shelf-btn"
                  disabled={
                    isExpired(batch?.expiryDate as string) ||
                    !batch?.quantityLeft
                  }
                  onClick={() => {
                    mixpanel.track(`Click 'Push to shelf' Button`, {
                      feature: 'Inventory',
                    });
                    setView('push-to-shelf');
                    setLocation(
                      `#product&id=${productId}&mView=push-to-shelf&batchId=${batch?.id}`,
                    );
                  }}
                >
                  <ArrowUpload />
                  Push to Shelf
                </AccordionButton>
              )}
              {hasPermission('EDIT_PRODUCT_BATCH') && (
                <AccordionButton
                  color={theme.yellow[600]}
                  variant="text"
                  width="max-content"
                  data-testid="edit-batch-btn"
                  onClick={() => {
                    mixpanel.track(`Click 'Edit Batch' Button`, {
                      feature: 'Inventory',
                    });
                    setView('edit-batch');
                    setLocation(
                      `#product&id=${productId}&mView=edit-batch&batchId=${batch?.id}`,
                    );
                  }}
                >
                  <PencilNote />
                  Edit
                </AccordionButton>
              )}
              <DownArrow />
            </Row>
          </Row>
        </Accordion.Header>
        <Accordion.Content>
          <Column gap={1}>
            <Row gap={1}>
              <ProductInfo
                dataTestId="expiry-date"
                title="Expiry Date"
                info={moment(batch?.expiryDate).format('DD-MM-YY')}
              />
              <ProductInfo
                dataTestId="batch-status"
                title="Status"
                info={_.capitalize(
                  batch?.status
                    ?.map((stat) => stat.split('_')?.join(' '))
                    .join(', '),
                )}
              />
            </Row>
            <Row gap={1}>
              <ProductInfo
                dataTestId="intial-quantity"
                title="Initial Quantity"
                info={batch?.quantity}
              />
              <ProductInfo
                dataTestId="quantity-left"
                title="Quantity Left In Batch"
                info={batch?.quantityLeft}
              />
            </Row>
            <Row gap={1}>
              <ProductInfo
                dataTestId="shelf-quantity"
                title="Quantity on Shelf"
                info={batch?.quantityOnShelf}
              />
            </Row>
          </Column>
        </Accordion.Content>
      </Accordion>
    </Card>
  );
};

export default BatchCard;
