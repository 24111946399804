import { gql } from '@apollo/client';

export const GET_MEDICAL_SUMMARY = gql`
  query GetMedicalSummary($patientId: ID!) {
    getMedicalSummary(patientId: $patientId) {
      allergies {
        level
        name
      }
      medications {
        dosage
        duration
        drugName
        frequency
        prescribedBy {
          _id
          user {
            firstName
            lastName
          }
        }
      }
      bloodInfo {
        bloodType
        genoType
      }
      vitals {
        data {
          ... on SelectData {
            id
            title
            type
            selectValue: value
          }
          ... on TextData {
            id
            title
            type
            textValue: value
          }
          ... on RowData {
            id
            title
            type
            value {
              id
              title
              type
              ... on SelectData {
                id
                title
                type
                selectValue: value
              }
              ... on TextData {
                id
                title
                type
                textValue: value
              }
            }
          }
          id
          title
          type
        }
      }
    }
  }
`;

export const GET_PATIENT_MEDICATION = gql`
  query GetMedicalSummary($patientId: ID!) {
    getMedicalSummary(patientId: $patientId) {
      medications {
        dosage
        duration
        drugName
        frequency
      }
    }
  }
`;
