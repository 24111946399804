import { Modal } from 'src/containers';
import { Column, BackButton } from 'src/components';
import { useHMOs } from 'src/state';
import { getModalParams, isValidUrl } from 'src/utils';
import SettlementDetails from './information';
import SettlementDocument from './document';

const SettlementInfo = () => {
  const imgUrl = getModalParams('url');
  const { currentSettlement } = useHMOs();
  const settlementId = currentSettlement?.id as string;
  const backLink = `#settlement-info&id=${settlementId}`;

  return (
    <Modal
      width="36.5625rem"
      height={isValidUrl(imgUrl) ? '100%' : 'max-content'}
    >
      {isValidUrl(imgUrl) ? (
        <Column height="100%" gap={1}>
          {settlementId && <BackButton label="Back" link={backLink} />}
          <SettlementDocument />
        </Column>
      ) : (
        <SettlementDetails />
      )}
    </Modal>
  );
};

export default SettlementInfo;
