import * as React from 'react';
import { StepProps, StepTemplateProps } from 'src/types';
import StepTemplatesContext from './context';

const StepsProvider: React.FC = ({ children }) => {
  const [stepTemplates, setStepTemplates] = React.useState<StepTemplateProps>({} as StepTemplateProps);
  const [steps, setSteps] = React.useState<StepProps>({} as StepProps);

  return (
    <StepTemplatesContext.Provider 
      value={{ 
        stepTemplates,
        steps,
        setSteps,
        setStepTemplates 
      }}
    >
      {children}
    </StepTemplatesContext.Provider>
  );
};

export default StepsProvider;
