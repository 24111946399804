import { gql } from '@apollo/client';
import { appointmentFieldsFragment } from '../Fragments/appointment';

export const GET_APPOINTMENT = gql`
  query GetAppointment($input: ID!, $date: DateTime) {
    getAppointment(input: $input, date: $date) {
      ...AppointmentFields
    }
  }
  ${appointmentFieldsFragment}
`;


export const SEARCH_APPOINTMENTS = gql`
  query SearchAppointments($input: SearchAppointmentsInput!) {
    searchAppointments(input: $input) {
      ...AppointmentFields
    }
  }
  ${appointmentFieldsFragment}
`;


export const GET_APPOINTMENTS = gql`
  query GetAppointments($input: GetAppointmentsInput) {
    getAppointments(input: $input) {
      ...AppointmentFields
    }
  }
  ${appointmentFieldsFragment}
`;