import { gql } from "@apollo/client";
import { facilityFieldsFragment } from "../Fragments/facility";
import { organisationFieldsFragment } from "../Fragments/organisation";

export const ADD_NEW_FACILITY = gql`
  mutation AddFacility($input: AddFacilityInput!) {
    addFacility(input: $input) {
      success
      message
      facility {
        ...FacilityFields
      }
    }
  }
  ${facilityFieldsFragment}
`;

export const UPDATE_FACILITY = gql`
  mutation UpdateFacility($input: UpdateFacilityInput!) {
    updateFacility(input: $input) {
      success
      message
      facility {
        ...FacilityFields
      }
    }
  }
  ${facilityFieldsFragment}
`;

export const DELETE_FACILITY = gql`
  mutation DeleteFacility($input: DeleteFacilityInput!) {
    deleteFacility(input: $input) {
      success
      message
      facility {
        ...FacilityFields
      }
    }
  }
  ${facilityFieldsFragment}
`;

export const UPDATE_ORGANISATION = gql`
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      success
      message
      organisation {
        ...OrganisationFields
      }
    }
  }
  ${organisationFieldsFragment}
`;