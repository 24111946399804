import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import recordReducer from './record/reducer';
import patientJourneyReducer from './patient-journey/reducer';
import stepsReducer from './steps/reducer';
import inventoryReducer from './inventory/reducer';
import inventoryInvoiceReducer from './inventory-invoices/reducer';
import productLogReducer from './product-logs/reducer';
import invoiceLogsReducer from './invoice-logs/reducer';
import patientInvoicesReducer from './patients-invoices/reducer';

export const store = configureStore({
  reducer: {
    records: recordReducer,
    patientJourneys: patientJourneyReducer,
    allSteps: stepsReducer,
    inventory: inventoryReducer,
    inventoryInvoice: inventoryInvoiceReducer,
    allProductLogs: productLogReducer,
    allInvoiceLogs: invoiceLogsReducer,
    patientsInvoices: patientInvoicesReducer,
  },
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
