import { gql } from '@apollo/client';

export const RECORD_META_FRAGMENT = gql`
  fragment recordMetaFragment on Record {
    id: _id
    patient {
      id: _id
      user {
        fullName
        id: _id
        firstName
        lastName
      }
    }
    template {
      id: _id
      description
      title
    }
    createdAt
    provider {
      id: _id
      user {
        _id
        firstName
        lastName
        fullName
      }
    }
    facility {
      address
      # organisation {
      #   name
      #   logo
      # }
    }
  }
`;

export const RECORD_FRAGMENT = gql`
  ${RECORD_META_FRAGMENT}
  fragment recordFragment on Record {
    _id
    data {
      id
      title
      type
      ... on InfoData {
        id
        title
        type
        infoValue: description
      }
      ... on SelectData {
        id
        title
        type
        selectValue: value
      }
      ... on TextData {
        id
        title
        type
        textValue: value
      }
      ... on DateData {
        id
        title
        type
        dateValue: value
      }
      ... on MultiSelectData {
        id
        title
        type
        multiSelectValue: value
      }
      ... on ImageData {
        id
        title
        type
        imageValue: value
      }
      ... on FileData {
        id
        title
        type
        fileValue: value
      }
      ... on TextAreaData {
        id
        title
        type
        textAreaValue: value
      }
      ... on NumberData {
        id
        title
        type
        numberValue: value
      }
      ... on WYSIWYGData {
        createdAt
        id
        title
        type
        wysiwygValue: value
      }
      ... on RowData {
        id
        title
        type
        rowValue: value {
          id
          title
          type
          ... on DateData {
            id
            title
            type
            dateValue: value
          }
          ... on MultiSelectData {
            id
            title
            type
            multiSelectValue: value
          }
          ... on NumberData {
            id
            title
            type
            numberValue: value
          }
          ... on SelectData {
            id
            title
            type
            selectValue: value
          }
          ... on ImageData {
            id
            title
            type
            imageValue: value
          }
          ... on FileData {
            id
            title
            type
            fileValue: value
          }
          ... on TextAreaData {
            id
            title
            type
            textAreaValue: value
          }
          ... on TextData {
            id
            title
            type
            textValue: value
          }
        }
      }
      ... on TableData {
        id
        title
        type
        tableValue: value {
          id
          title
          type
          ... on RowData {
            id
            title
            type
            rowValue: value {
              id
              title
              type
              ... on DateData {
                id
                title
                type
                dateValue: value
              }
              ... on MultiSelectData {
                id
                title
                type
                multiSelectValue: value
              }
              ... on NumberData {
                id
                title
                type
                numberValue: value
              }
              ... on SelectData {
                id
                title
                type
                selectValue: value
              }
              ... on ImageData {
                id
                title
                type
                imageValue: value
              }
              ... on FileData {
                id
                title
                type
                fileValue: value
              }
              ... on TextAreaData {
                id
                title
                type
                textAreaValue: value
              }
              ... on TextData {
                id
                title
                type
                textValue: value
              }
            }
          }
        }
      }
    }
    ...recordMetaFragment
  }
`;
