import { useState, useEffect } from 'react';
import moment from 'moment';
import { View, Views } from 'react-big-calendar';
import { RangeKeyDict, DateRange, Calendar } from 'react-date-range';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { Button, Column, Row } from 'src/components';
import { RangeData } from 'src/components/DateRange';
import useClickAway from 'src/utils/useClickAway';
import { CURRENT_DATE } from 'src/constants';
import { theme } from 'src/utils';
import {
  DateRangeTrigger,
  DateRangeWrapper,
  ErrorText,
  NoWrapParagraph
} from '../styled';


interface DateRangeProps {
  isDoctor: boolean;
};

export const DateRangePicker: React.FC<DateRangeProps> = ({ isDoctor }) => {
  const dateFormat = 'D MMMM YYYY';
  const [errors, setErrors] = useState<string[]>([]);
  const { params, setModalParams } = useSearchParams();
  const view = (params.get("view") || Views.DAY) as View;
  const dateParams = params.get("date") || CURRENT_DATE;
  const idx = params.get("idx") as string;
  const isSameDate = moment(dateParams).isSame(CURRENT_DATE, 'day');
  const weekStartDate = moment(dateParams).subtract(idx, 'd');
  const [range, setRange] = useState<RangeData>({} as RangeData);

  const weekValue = idx ? 
  `${weekStartDate.startOf('d').format('MMM D')} - ${weekStartDate.add(6, 'd').endOf("d").format('D MMM YYYY')}` :
  `${weekStartDate.startOf('w').format('MMM D')} - ${weekStartDate.endOf("w").format('D MMM YYYY')}`


  useEffect(() => {
    setRange({
      key: 'selection',
      startDate: idx ? 
        moment(dateParams).subtract(idx, 'd').startOf('d').toDate() : 
        moment(dateParams).subtract(idx, 'd').startOf('w').toDate(),
      endDate: idx ?
        moment(dateParams).subtract(idx, 'd').add(6, 'd').endOf('d').toDate() :
        moment(dateParams).subtract(idx, 'd').endOf('w').toDate()
    })
  }, [dateParams, idx])

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useClickAway(false);

  const onClose = () => {
    setErrors([]);
    setIsComponentVisible(!isComponentVisible);
  };

  const handleChange = (dateD: Date) => {
    const date = moment(dateD).format('YYYY-MM-DD');
    setModalParams({ date });
    onClose();
  };

  const onChange = (rangesByKey: RangeKeyDict) => {
    const startDate = rangesByKey.selection.startDate as Date;
    const endDate = rangesByKey.selection.endDate as Date;
    setRange({ ...range, startDate, endDate });
    setErrors([]);
  };

  const onApply = () => {
    const newErrors: string[] = [];
    const { startDate, endDate } = range;

    if (!startDate || !endDate) {
      newErrors.push("select a start date and end date.");
    }

    if (moment(endDate).diff(moment(startDate), 'days') !== 6) {
      newErrors.push("date range must be 7 days.");
    }

    setErrors(newErrors);
    if (newErrors.length === 0) {
      const date = moment(startDate).format('YYYY-MM-DD');
      setModalParams({ date, idx: "null" });
      onClose();
    }
  }

  
  return (
    <Column
      position="relative"
      gap={0.5}
      ref={ref}
      height="100%"
    >
      <DateRangeTrigger
        background={theme.grey[100]}
        onClick={() => onClose()}
      >
        {view === Views.WEEK && !isDoctor ? (
          <NoWrapParagraph size="base" weight="bold">
            {weekValue}
          </NoWrapParagraph>
        ) : (
          <NoWrapParagraph size="base" weight="medium">
            <strong>{moment(dateParams).format(dateFormat)}</strong>
            {isSameDate && ` Today`}
          </NoWrapParagraph>
        )}
      </DateRangeTrigger>
      {isComponentVisible && (
        <DateRangeWrapper>
          {view === "week" ? (
            <Column gap={1}>
              <DateRange
                weekStartsOn={1}
                ranges={[range]}
                onChange={onChange}
                scroll={{ enabled: true }}
                rangeColors={errors.length > 0 ?
                  [theme.secondary[700]] : [theme.grey[700]]}
                endDatePlaceholder='end Date'
                dateDisplayFormat="MMM d, yyyy"
                startDatePlaceholder="start Date"
              />
              <Column width="100%">
                <ErrorText
                  size="xs"
                  color={theme.secondary[700]}
                >
                  {errors[0]}
                </ErrorText>
                <Row justify="flex-end" gap={1}>
                  <Button
                    variant="text"
                    width="7.5rem"
                    type="button"
                    onClick={() => onClose()}
                  >
                    Close
                  </Button>
                  <Button width="7.5rem" onClick={() => onApply()}>
                    Apply
                  </Button>
                </Row>
              </Column>
            </Column>
          ) : (
            <Calendar
              date={moment(dateParams).toDate()}
              weekStartsOn={1}
              onChange={handleChange}
              color={theme.grey[700]}
              scroll={{ enabled: true }}
            />
          )}
        </DateRangeWrapper>
      )}
    </Column>
  )
};