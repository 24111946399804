import { useQuery } from '@apollo/client';
import * as React from 'react';
import { PageLoader } from 'src/components';
import { GET_NOTIFICATIONS } from 'src/constants';
import { Notification } from 'src/types';
import { useAuth } from '../auth';
import NotificationContext from './context';

const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = React.useState<Notification>(
    {} as Notification,
  );
  const [unreadSize, setUnreadSize] = React.useState(0);
  const { state } = useAuth();
  const { data, loading } = useQuery(GET_NOTIFICATIONS, {
    skip: !state.token,
    onCompleted(d) {
      setUnreadSize(
        d.getNotifications.filter(
          (not: { status: string }) => not.status !== 'READ',
        ).length,
      );
    },
  });

  React.useEffect(() => {
    setNotifications(data);
  }, [data]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <NotificationContext.Provider
      value={{ notifications, setNotifications, unreadSize, setUnreadSize }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
