import * as React from 'react';
import { SelectProps } from 'src/types';
import { ArrowUpIcon } from 'src/assets/icons';
import { InputLabel } from '../InputLabel';
import { SelectContainer, Icon, SelectBase, ErrorText } from './styled';
import { InputContainer } from '../Input/styled';
import { Spinner } from '../PageLoader/styled';

export const Select: React.FC<SelectProps> = ({
  label,
  icon = <ArrowUpIcon />,
  children,
  error,
  required,
  loading,
  modStyles,
  width,
  ...props
}) => {
  return (
    <InputContainer
      required={!label && required}
      modStyles={modStyles}
      width={width}
    >
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <SelectContainer align="center">
        <SelectBase {...props}>{children}</SelectBase>
        <Icon>{loading ? <Spinner size={1.5} /> : icon}</Icon>
      </SelectContainer>
      {Boolean(error) && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </InputContainer>
  );
};
