import { getModalParams } from 'src/utils';
import { createContext, useEffect, useState } from 'react';
import { useStaff } from 'src/state';
import { InventoryModal } from '../styled';
import Details from './Details';
import AddBatch from './AddBatch';
import EditBatch from './EditBatch';
import EditProduct from './EditProduct';
import PushToShelf from './PushToShelf';
import Logs from './Logs';

type ViewType =
  | 'details'
  | 'edit-product'
  | 'edit-batch'
  | 'add-batch'
  | 'logs'
  | 'push-to-shelf';

type ViewContextType = {
  view: ViewType;
  setView: React.Dispatch<React.SetStateAction<ViewType>>;
};

export const ViewContext = createContext<ViewContextType>(
  {} as ViewContextType,
);

const Product = () => {
  const { hasPermission } = useStaff();
  const modalView = getModalParams('mView') as ViewType;
  const [view, setView] = useState<ViewType>(modalView);

  useEffect(() => {
    setView(modalView);
  }, [modalView]);

  return (
    <InventoryModal>
      <ViewContext.Provider value={{ view, setView }}>
        {view === 'details' && hasPermission('VIEW_PRODUCT_DETAILS') && <Details />}
        {view === 'edit-product' && hasPermission('EDIT_PRODUCT') && (
          <EditProduct />
        )}
        {view === 'edit-batch' && hasPermission('EDIT_PRODUCT_BATCH') && (
          <EditBatch />
        )}
        {view === 'add-batch' && hasPermission('ADD_TO_STOCK') && <AddBatch />}
        {view === 'push-to-shelf' && hasPermission('PUSH_PRODUCT_TO_SHELF') && (
          <PushToShelf />
        )}
        {view === 'logs' && <Logs />}
      </ViewContext.Provider>
    </InventoryModal>
  );
};

export default Product;
