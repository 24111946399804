import { gql } from '@apollo/client';

export const ADD_NEW_SERVICE = gql`
  mutation AddServices($input: AddServicesInput!) {
    addServices(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      success
      message
    }
  }
`;
