import styled from 'styled-components';
import { Card } from 'src/components';
import { theme } from 'src/utils';

export const ColoredCard = styled(Card)`
  width: 100%;
  box-shadow: none;
  border-radius: 0.3125rem;
  background-color: ${theme.grey[100]};
  padding: 1.25rem 0.62em;
`;