import { useFormikContext } from 'formik';
import { ArrowUpIcon } from 'src/assets/icons';
import { Column, Input, MultiSelect, Paragraph } from 'src/components';
import { useRowData, useStaffGroups } from 'src/state';
import { CreateStepTemplate } from 'src/types';
import { getModalParams, theme } from 'src/utils';

const StepDetails = () => {
  const { values, touched, handleBlur, handleChange, errors, setFieldValue } =
    useFormikContext<CreateStepTemplate>();
  const id = getModalParams('id') as string;
  const isEditStep = Boolean(id);
  const { useApolloStaffGroups } = useStaffGroups();
const {  staffGroups } = useApolloStaffGroups();
  const staffGroupsData = staffGroups?.getStaffGroups?.staffGroups;
  const allStaffGroups = staffGroupsData?.filter(
    (item: { id: string; name: string }) => {
      return item.name.toLowerCase() !== 'owner';
    },
  );
  const { getDataByKey } = useRowData();
  const stepTemplate = getDataByKey('stepTemplate')

  const handleMultiSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue('assigned', [...values.assigned, e.target.value]);
    } else {
      setFieldValue(
        'assigned',
        [...values.assigned].filter((val) => val !== e.target.value),
      );
    }
  };

  return (
    <Column justify="space-between" gap={1}>
      <Paragraph color={theme.grey[500]}>
        Give information about this step
      </Paragraph>
      <Column gap={1} justify="space-between">
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={isEditStep && stepTemplate?.isGlobalClone}
          label="Step Name"
          placeholder="Give this step a name"
          name="name"
          type="text"
          autoFocus
          required
          value={values.name}
          error={touched?.name ? errors.name : ''}
        />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          label="Description"
          placeholder="Short description for easy understanding"
          name="description"
          required
          type="text"
          value={values.description}
          error={touched?.description ? errors.description : ''}
        />
        <MultiSelect
          name="assigned"
          dataTestId='assigned'
          error={errors?.assigned as string}
          onChange={handleMultiSelectChange}
          icon={<ArrowUpIcon className='rotate-180' />}
          required
          placeholder={
            values?.assigned?.length > 0
            ? `${values?.assigned?.length} selected`
            : 'Select Staff Group(s)'
          }
          defaultSelected={values?.assigned}
          label="Staff Group(s)"
          options={allStaffGroups?.map((v) => ({
            value: v.id,
            label: v.name.endsWith('s') ? v.name : `${v.name}s`,
          }))}
        />
      </Column>
    </Column>
  );
};

export default StepDetails;
