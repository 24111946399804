/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
import { useContext } from 'react';
import { Column, SkeletonWrapper, Text } from 'src/components';
import { theme } from 'src/utils';
import { GET_PATIENT_MEDICATION } from 'src/constants/Queries/medicalSummary';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { ViewContext } from '../invoice/index';
import { TR, TableContent } from '../styled';

const prescriptionHeaders = ['Drug', 'Dosage', 'Frequency', 'Duration'];

const PatientPrescription = () => {
  const { patient, setPatient } = useContext(ViewContext);
  const { loading: prescriptionLoading } = useQuery(GET_PATIENT_MEDICATION, {
    variables: {
      patientId: patient?.id,
    },
    // skip: !patient?.medications,
    errorPolicy: 'all',
    onCompleted(d) {
      if (d?.getMedicalSummary) {
        setPatient((prev) => {
          if (prev) {
            return {
              ...prev,
              medications: d?.getMedicalSummary.medications,
            };
          }
        });
      }
    },
  });

  return (
    <Column gap={0.3}>
      {!!patient?.medications?.length && prescriptionLoading && (
        <Text weight="bold" size="sm" color={theme.grey[650]}>
          Patient Prescriptions
        </Text>
      )}
      {prescriptionLoading && (
        <SkeletonWrapper>
          <Skeleton borderRadius="0.625rem" width="100%" height={100} />
        </SkeletonWrapper>
      )}
      {!prescriptionLoading && !!patient?.medications?.length && (
        <TableContent className="table-content">
          <table>
            <thead>
              <TR className="border-b" colored>
                {prescriptionHeaders.map((title, i) => (
                  <th key={i} dangerouslySetInnerHTML={{ __html: title }} />
                ))}
              </TR>
            </thead>
            <tbody>
              {patient?.medications.map((med, idx) => (
                <tr key={idx}>
                  <td>{med.drugName}</td>
                  <td>{med.dosage}</td>
                  <td>{med.frequency}</td>
                  <td>{med.duration}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContent>
      )}
    </Column>
  );
};

export default PatientPrescription;
