import React, { useEffect } from 'react';
import { Column, Text } from 'src/components';
import { BuildSideBarItemsProps, SidebarConfig } from 'src/types';
import useMediaQuery from 'src/utils/useMediaQuery';
import { theme } from 'src/utils';
import useClickAway from 'src/utils/useClickAway';
import { useTour } from '@reactour/tour';
import { useStaff, useUser } from 'src/state';
import { HR, SidebarItems, SubGroupItemsWrapper } from './styled';
import { SideBarButtonItem } from './SideBarButtonItem';

export const BuildSideBarItems: React.FC<BuildSideBarItemsProps> = ({
  config,
  expand,
}) => {
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);
  const { setIsOpen } = useTour();
  const { staff } = useStaff();
  const { user } = useUser();
  const isTourComplete = user?.getUser?.isTourComplete;
  const staffGroup = staff?.getStaff.staffGroup.name;

  useEffect(() => {
    if (!expand) {
      setIsComponentVisible(false);
    }
    const onboardingSteps =
      staff &&
      Object.values(staff.getStaff.facility.organisation.onboardingSteps);
    let timer: NodeJS.Timeout;
    if (
      (staffGroup === 'Owner' &&
        !onboardingSteps.includes(false) &&
        !isTourComplete) ||
      (staffGroup !== 'Owner' && !isTourComplete)
    ) {
      timer = setTimeout(() => {
        setIsOpen(true);
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [
    expand,
    isTourComplete,
    setIsComponentVisible,
    setIsOpen,
    staff,
    staffGroup,
  ]);

  const TitleText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <Text
        align="left"
        size="xs"
        color={theme.grey[700]}
        modStyles={{ ml: '0.625rem', my: '0.25rem' }}
      >
        {children}
      </Text>
    );
  };

  const SubGroupItem: React.FC<{ subGroup: SidebarConfig }> = ({
    subGroup,
  }) => {
    if (subGroup?.group?.length === 0 || !isComponentVisible || !expand)
      return <></>;

    return (
      <SubGroupItemsWrapper ref={ref}>
        <TitleText>{subGroup.title}</TitleText>
        {subGroup?.group?.map((item, index) => {
          return (
            <SidebarItems key={`sub-menu-item-${index}`}>
              <SideBarButtonItem
                item={item}
                onClick={() => setIsComponentVisible(false)}
              />
            </SidebarItems>
          );
        })}
      </SubGroupItemsWrapper>
    );
  };

  return (
    <>
      {config.map((item, index) => {
        if (isMobile && (item.group || item.subGroup?.group)) {
          return (
            <BuildSideBarItems
              key={`sb-line-group-${index}`}
              config={item.group || (item.subGroup?.group as SidebarConfig[])}
              expand={false}
            />
          );
        }

        if (item.group) {
          return (
            <Column gap={0.25} key={`sb-line-group-${index}`}>
              <Text
                align="left"
                size="xs"
                color={theme.grey[700]}
                modStyles={{ ml: '0.625rem', my: '0.25rem' }}
              >
                {item.title}
              </Text>
              <SidebarItems>
                <Column gap={0.25} align="center" width="100%">
                  <BuildSideBarItems config={item.group} expand={expand} />
                </Column>
                {index < config.length - 1 && !isMobile && (
                  <HR key={`sb-line-${index}`} />
                )}
              </SidebarItems>
            </Column>
          );
        }

        return (
          <React.Fragment key={`sb-item-${index}`}>
            <SideBarButtonItem
              item={item}
              onClick={() => {
                if (item.subGroup) {
                  setIsComponentVisible(!isComponentVisible);
                }
              }}
            />
            {item.subGroup && <SubGroupItem subGroup={item.subGroup} />}
          </React.Fragment>
        );
      })}
    </>
  );
};
