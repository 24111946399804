import { IconButton, Pill, Row, Text } from 'src/components';
import { theme } from 'src/utils/variables';
import { PageCollectionIcon, CheckedIcon, DeleteIcon } from 'src/assets/icons';
import { useLocation } from 'wouter';
import { useContext } from 'react';
import {
  StepStatus,
  deleteJourneyStep,
  deleteStep,
  useAppDispatch,
  useJourneyApis,
} from 'src/store';
import { useAlert, useToast, useStaff } from 'src/state';
import { StepData } from 'src/types';
import mixpanel from 'mixpanel-browser';
import { StepCard, TaskIconWrapper } from './styled';
import { getColor } from './helpers';
import { ViewContext } from '.';

export interface StepType {
  title: string;
  status: StepStatus;
  taskCount: number;
  completedCount: number;
  step: StepData;
  url?: string;
  idx: number;
  isPastJourney?: boolean;
  patientId?: string;
  onClick?: () => void;
}

const StepListItem: React.FC<StepType> = ({
  status,
  title,
  taskCount,
  completedCount,
  step,
  idx,
  isPastJourney,
  patientId,
  url,
  onClick,
}) => {
  const [, setLocation] = useLocation();
  const { hasPermission } = useStaff();
  const { setView } = useContext(ViewContext);
  const dispatch = useAppDispatch();
  const { showAlert, removeAlert } = useAlert();
  const { showToast } = useToast();

  const { deleteStep: apolloDeleteStep } = useJourneyApis();

  const handleDeleteStep = async () => {
    mixpanel.track(`Click 'Delete Step' button`, {
      feature: 'Patient Journey',
    });
    if (isPastJourney)
      return showToast('Cannot delete step in a past journey', 'error');
    if (step.status === 'STARTED')
      return showToast('Cannot delete an Ongoing step', 'error');

    return showAlert({
      type: 'decision',
      title: 'Are you sure you want to delete this step?',
      message:
        'Deleting this step removes it completely from the patient journey',
      confirm: () =>
        apolloDeleteStep({
          variables: {
            stepId: step.id,
          },
          onCompleted(d) {
            if (!d) {
              return d;
            }
            dispatch(
              deleteJourneyStep({
                patientId: patientId as string,
                stepId: step.id,
              }),
            );
            return dispatch(deleteStep({ stepId: step.id }));
          },
        }).then((res) => {
          if (res?.data) {
            removeAlert();
          }
        }),
    });
  };

  return (
    <StepCard
      role="button"
      data-testid="step-item"
      onClick={() => {
        // if (!hasPermission("VIEW_PATIENT_JOURNEY")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
        mixpanel.track(`Click 'Step' button`, { feature: 'Patient Journey' });
        if (url) {
          setView('step');
          setLocation(url);
        }
        onClick?.();
      }}
    >
      <Row align="center" modStyles={{ ml: 0.3 }}>
        <TaskIconWrapper>
          <PageCollectionIcon />
        </TaskIconWrapper>
        <Text
          size="base"
          weight="bold"
          color={theme.primary[700]}
          modStyles={{ my: 0, mx: 0.5 }}
        >
          {title}
        </Text>
        <Pill data-testid="step-status" color={getColor(status)} outlined>
          {status}
        </Pill>
      </Row>
      <Row align="center" width="max-content" modStyles={{ ml: 'auto' }}>
        <CheckedIcon
          color={taskCount === completedCount ? theme.green[700] : undefined}
        />
        <Text
          width="max-content"
          size="sm"
          weight="semibold"
          modStyles={{ my: 0, mx: 0.7 }}
        >
          {`${completedCount}`} / {`${taskCount}`}
        </Text>
        {status === 'Pending' &&
          idx !== 0 &&
          hasPermission('DELETE_STEPS_FROM_A_JOURNEY') && (
            <IconButton
              data-testid="delete-step"
              variant="solid"
              background={theme.grey[100]}
              color={theme.grey[200]}
              size={0.5}
              modStyles={{ px: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteStep();
              }}
              type="button"
            >
              <DeleteIcon />
            </IconButton>
          )}
      </Row>
    </StepCard>
  );
};

export default StepListItem;
