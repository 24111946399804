import { gql } from "@apollo/client";
import { familyFieldsFragment } from "../Fragments/family";
import { patientFieldsFragment } from "../Fragments/patient";


export const CREATE_FAMILY = gql`
  mutation CreateFamily($input: CreateFamilyInput!) {
    createFamily(input: $input) {
      message
      success
      family {
        ...FamilyFields
      }
    }
  }
  ${familyFieldsFragment}
`;

export const REMOVE_FAMILY = gql`
  mutation RemoveFamilyPatient($patientId: ID!) {
    removeFamilyPatient(patientId: $patientId) {
      message
      success
      family {
        ...FamilyFields
        patients {
          ...PatientFields
        }
      }
    }
  }
  ${patientFieldsFragment}
  ${familyFieldsFragment}
`;

export const EDIT_FAMILY = gql`
  mutation EditFamily($familyId: ID!, $input: UpdateFamilyInput!) {
    editFamily(familyId: $familyId, input: $input) {
      message
      success
      family {
        ...FamilyFields
      }
    }
  }
  ${familyFieldsFragment}
` 