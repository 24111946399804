import { useLazyQuery } from '@apollo/client';
import { GET_STEP, GET_STEPS } from 'src/constants';

export const useStepsApis = () => {
  const [fetchSteps] = useLazyQuery(GET_STEPS);
  const [fetchStep, { loading: fetchStepLoading }] = useLazyQuery(GET_STEP);

  return {
    fetchStep,
    fetchSteps,
    fetchStepLoading,
  };
};
