import Skeleton from 'react-loading-skeleton';
import { TimelineImage } from 'src/assets/images';
import { Column, SkeletonWrapper, Text } from 'src/components';

const NoResult = () => {
  return (
    <Column
      align="center"
      gap={1}
      justify="center"
      height="100%"
      width="100%"
      data-testid="no-search-result"
      modStyles={{ py: 2 }}
    >
      <TimelineImage />
      <Text align="center">No result</Text>
    </Column>
  );
};

export const SearchLoader: React.FC = () => {
  return (
    <Column align="center" modStyles={{ mt: 1 }}>
      <SkeletonWrapper>
        <Skeleton width="100%" height={320} borderRadius="0.5rem" />
      </SkeletonWrapper>
    </Column>
  );
};

export default NoResult;
