import * as React from 'react';
import { ServicesProps } from 'src/types';
import ServicesContext from './context';

const ServicesProvider: React.FC = ({ children }) => {
  const [initialServices, setInitialServices] = React.useState<ServicesProps>(
    {} as ServicesProps,
  );

  return (
    <ServicesContext.Provider
      value={{
        initialServices,
        setInitialServices,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesProvider;
