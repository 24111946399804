import { font, theme } from 'src/utils/variables';
import styled from 'styled-components';
import { Row } from '../Flex';
import { Text } from '../Typography';

export const Container = styled.svg`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Circle = styled(Row)<{
  radius: number;
  color: string;
}>`
  width: ${props => props.radius * 2}px;
  height: ${props => props.radius * 2}px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

export const Bar = styled(Row)<{
  color: string;
  topRightRadius?: string;
  topLeftRadius?: string;
  bottomRightRadius?: string;
  bottomLeftRadius?: string;
  opacity?: number;
}>`
  border-top-right-radius: ${props => props.topRightRadius};
  border-top-left-radius: ${props => props.topLeftRadius};
  border-bottom-left-radius: ${props => props.bottomLeftRadius};
  border-bottom-right-radius: ${props => props.bottomRightRadius};
  background-color: ${props => props.color};
  opacity: ${props => props.opacity};
`;

export const ChartText = styled(Text)`
  margin: 0;
  line-height: 1;
`;

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: ${font.sizes.xs};
  fill: ${theme.grey[500]};
`;