import { AlertAction, AlertStateType } from 'src/types';

export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const initialState: AlertStateType = {
  show: false,
  message: '',
  title: '',
  width: '',
  confirm: undefined,
  remove: undefined,
  confirmButton: null,
  closeButton: null,
  type: undefined,
  icon: null,
  confirmText: undefined,
  closeText: undefined,
  hasCancelBtn: false,
};

const reducer = (
  state: AlertStateType = initialState,
  action: AlertAction,
): AlertStateType => {
  const current: AlertStateType = state;
  const message = action.payload?.message ?? '';
  const title = action.payload?.title ?? '';
  const width = action.payload?.width ?? '';
  const confirm = action.payload?.confirm ?? undefined;
  const remove = action.payload?.remove ?? undefined;
  const confirmButton = action.payload?.confirmButton ?? null;
  const closeButton = action.payload?.closeButton ?? null;
  const icon = action.payload?.icon ?? undefined;
  const confirmText = action.payload?.confirmText ?? undefined;
  const closeText = action.payload?.closeText ?? undefined;
  const hasCancelBtn = action.payload?.hasCancelBtn ?? false;
  const type = action.payload?.type ?? 'decision';

  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...current,
        show: true,
        message,
        title,
        confirm,
        remove,
        confirmButton,
        closeButton,
        icon,
        confirmText,
        closeText,
        hasCancelBtn,
        type,
        width
      };

    case REMOVE_ALERT:
      return {
        ...current,
        show: false,
      };
    default:
      return state;
  }
};

export default reducer;
