/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TimelineImage } from 'src/assets/images';
import { Column } from 'src/components';
import { openModal } from 'src/utils';
import { EmptyText } from './styled';

export const NoChart: React.FC = () => {
  return (
    <Column align="center" gap={1} justify="center" height="100%" width="100%">
      <TimelineImage />
      <EmptyText align="center">
        Nothing to display.{' '}
        <span
          role="button"
          tabIndex={0}
          onClick={() =>
            openModal('form', process.env.REACT_APP_VITALS_FORM_ID)}
        >
          Add vitals
        </span>{' '}
        to view chart
      </EmptyText>
    </Column>
  );
};
