import * as React from 'react';
import { ToastContextType } from 'src/types';
import ToastContext from './context';
import reducer, { initialState } from './reducer';
import actions from './actions';

const ToastProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const stateD: Pick<ToastContextType, 'state'> = React.useMemo(
    () => ({
      state,
    }),
    [state],
  );
  const dispatches: Omit<ToastContextType, 'state'> = React.useMemo(
    () => ({
      showToast: actions.showToast(dispatch),
      removeToast: actions.removeToast(dispatch)
    
    }),
    [dispatch],
  );

  return (
    <ToastContext.Provider value={{ ...stateD, ...dispatches }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
