import * as React from 'react';
import { LOG_ERROR, SET_LOADING } from 'src/constants';
import { AuthAction } from 'src/types';
import { LOG_IN, LOG_OUT } from './reducer';

const logError = (dispatch: React.Dispatch<AuthAction>) => (error: string) => {
  dispatch({ type: LOG_ERROR, payload: { error } });
};

const logIn =
  (dispatch: React.Dispatch<AuthAction>) =>
  async (userToken: string, refreshToken?: string) => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: { loading: true },
      });
      dispatch({
        type: LOG_IN,
        payload: { token: userToken, refreshToken },
      });
      dispatch({
        type: SET_LOADING,
        payload: { loading: false },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_LOADING,
        payload: { loading: false },
      });
    }
  };

const logOut = (dispatch: React.Dispatch<AuthAction>) => async () => {
  dispatch({
    type: LOG_OUT,
  });
  window.history.replaceState(null, '', "/auth/signin");
};

export default {
  logOut,
  logIn,
};
