import {
  Button,
  Card,
  Column,
  ErrorComponent,
  H6,
  Input,
  Row,
  Text,
} from 'src/components';
import { useLocation } from 'wouter';
import { useContext } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { getModalParams } from 'src/utils';
import { useFormik } from 'formik';
import Validator from 'validatorjs';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { SolidCheck } from 'src/assets/icons';
import { useInventoryApis } from 'src/store';
import { useQuery } from '@apollo/client';
import { GET_EXPIRY_ALERT, GET_PRODUCT, GET_PRODUCTS } from 'src/constants';
import { useToast } from 'src/state';
import { Product } from 'src/types';
import ProductInfo from '../components/ProductInfo';
import ProductExtraInfo from '../components/ProductExtraInfo';
import { ViewContext } from '.';

const PushToShelf = () => {
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const productId = getModalParams('id');
  const batchId = getModalParams('batchId');

  const { pushToShelf, pushToShelfLoading } = useInventoryApis();
  const { showToast } = useToast();

  const { data, error: productError } = useQuery(GET_PRODUCT, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: !productId,
    variables: {
      input: productId,
    },
  });

  const product = data?.getProduct as Product;
  const batch = product?.batches?.find((b) => b.id === batchId);

  const goBack = () => {
    setView('details');
    setLocation(`#product&id=${productId}&mView=details`);
  };

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: { quantity: 0 },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(validationValues, {
        quantity: `required|min:1|max:${batch?.quantityLeft}`,
      });
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: async (value) => {
      await pushToShelf({
        variables: {
          input: { ...value },
          batchId,
        },
        refetchQueries: [
          GET_PRODUCTS,
          GET_EXPIRY_ALERT,
          { query: GET_PRODUCT, variables: { input: productId } },
        ],
        awaitRefetchQueries: true,
        onCompleted(d) {
          if (d?.pushToShelf) {
            showToast(d.pushToShelf?.message, 'success');
            goBack();
          }
        },
      });
      return value;
    },
  });

  if (productError) {
    return <ErrorComponent />;
  }

  return (
    <ViewWrapper goBack={goBack}>
      <Column gap={1.5} minWidth='50vw'>
        <Column gap={0.3}>
          <H6 modStyles={{ ma: 0 }}>Push to Shelf</H6>
          <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
            Add more product to front store shelf
          </Text>
        </Column>
        <Card width="100%">
          <ProductExtraInfo
            name={product?.name}
            extraInfo={product?.extraInfo}
          />
        </Card>
        <Row gap={1}>
          <ProductInfo
            title="Quantity Left in Batch"
            info={batch?.quantityLeft || 'N/A'}
          />
          <ProductInfo
            title="Quantity Left on Shelf"
            info={batch?.quantityOnShelf}
          />
          <ProductInfo
            title="Max. Quantity to be Pushed"
            info={batch?.quantityLeft || 'N/A'}
          />
        </Row>
        <FullWidthForm onSubmit={handleSubmit}>
          <Input
            id="quantity"
            type="number"
            label="Quantity to push to shelf"
            value={values.quantity}
            name="quantity"
            required
            placeholder="Enter how many items you want to push to shelf"
            onChange={handleChange}
            error={errors?.quantity}
          />
          <Button
            variant="solid"
            width="max-content"
            type="submit"
            isLoading={pushToShelfLoading}
            data-testid="push-confirm-btn"
            modStyles={{ ml: 'auto' }}
            noIconStyles
          >
            <SolidCheck /> Push to Shelf
          </Button>
        </FullWidthForm>
      </Column>
    </ViewWrapper>
  );
};

export default PushToShelf;
