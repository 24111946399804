import { OperationVariables, useMutation } from "@apollo/client";
import { 
  ADD_NEW_HMO_PROVIDER, 
  ADD_MULTIPLE_HMO_PROVIDER, 
  UPDATE_HMO_PROVIDER, 
  DELETE_HMO_PROVIDER, 
  CREATE_SETTLEMENT,
  DELETE_SETTLEMENT,
  GET_SETTLEMENTS,
  HMO_OVERVIEW,
  UPDATE_SETTLEMENT,
  GET_HMOS,
  GET_HMO_DEBT
} from "src/constants";
import { 
  AddHMOProps, 
  AddMultipleHMOProps, 
  CreateSettlementProps, 
  DeleteSettlementProps, 
  UpdateHMOProps, 
  UpdateSettlementProps, 
  deleteHMOProps 
} from "src/types";
import { useStaff } from "../staff";
import { useToast } from "../toast";
import { useRowData } from "../rowData";



const useMutations = () => {
  const { showToast } = useToast();
  const { hasPermission } = useStaff();
  const { updateRowData } = useRowData();

  const useAddHMO = () => {
    const [execute, result] = useMutation<AddHMOProps>(ADD_NEW_HMO_PROVIDER);

    const addHMO = (value?: OperationVariables) => {
      if (!hasPermission("ADD_NEW_HMO")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.addHmoProvider.message, 'success'),
        refetchQueries: [GET_HMOS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, addHMO };
  };


  const useAddMultipleHMO = () => {
    const [execute, result] = useMutation<AddMultipleHMOProps>(ADD_MULTIPLE_HMO_PROVIDER);

    const addMultipleHMO = (value?: OperationVariables) => {
      if (!hasPermission("ADD_NEW_HMO")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.addMultipleHmoProvider.message, 'success'),
        refetchQueries: [GET_HMOS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, addMultipleHMO };
  };


  const useUpdateHMO = () => {
    const [execute, result] = useMutation<UpdateHMOProps>(UPDATE_HMO_PROVIDER);

    const updateHMO = (value?: OperationVariables) => {
      if (!hasPermission("EDIT_HMO")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => {
          showToast(d.updateHmoProvider.message, 'success')
          updateRowData('hmo', d.updateHmoProvider.hmo)
        },
        refetchQueries: [GET_HMOS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, updateHMO };
  };


  const useDeleteHMO = () => {
    const [execute, result] = useMutation<deleteHMOProps>(DELETE_HMO_PROVIDER);

    const deleteHMO = (id?: string) => {
      if (!hasPermission("DELETE_HMO")) return;
      return execute({
        variables: { deleteHmoProviderId: id },
        onCompleted: (d) => showToast(d.deleteHmoProvider.message, 'success'),
        refetchQueries: [GET_HMOS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, deleteHMO };
  };


  const useUpdateSettlement = () => {
    const [execute, result] = useMutation<UpdateSettlementProps>(UPDATE_SETTLEMENT);

    const updateSettlement = (value?: OperationVariables) => {
      // if (!hasPermission("EDIT_HMO")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => {
          showToast(d.updateSettlement.message, 'success')
          updateRowData('settlement', d.updateSettlement.settlement)
        },
        refetchQueries: [GET_SETTLEMENTS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, updateSettlement };
  };


  const useCreateSettlement = () => {
    const [execute, result] = useMutation<CreateSettlementProps>(CREATE_SETTLEMENT);

    const createSettlement = (value?: OperationVariables) => {
      if (!hasPermission("CREATE_HMO_SETTLEMENTS")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.createSettlement.message, 'success'),
        refetchQueries: [GET_SETTLEMENTS, HMO_OVERVIEW, GET_HMO_DEBT],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, createSettlement };
  };


  const useDeleteSettlement = () => {
    const [execute, result] = useMutation<DeleteSettlementProps>(DELETE_SETTLEMENT);

    const deleteSettlement = (value?: OperationVariables) => {
      // if (!hasPermission("")) return;
      return execute({
        variables: { input: value },
        onCompleted: (d) => showToast(d.deleteSettlement.message, 'success'),
        refetchQueries: [GET_SETTLEMENTS],
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, deleteSettlement };
  };

  return {
    useAddHMO,
    useAddMultipleHMO,
    useDeleteHMO,
    useDeleteSettlement,
    useCreateSettlement,
    useUpdateHMO,
    useUpdateSettlement
  }
}

export default useMutations;