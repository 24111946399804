import { DocumentNode, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { useRef, useState, useEffect } from 'react';
import { useToast } from 'src/state';

interface options {
  onCompleted?: (d: unknown) => void;
}

export default function useSearch(query: DocumentNode, options?: options) {
  const [searchValue, setSearchValue] = useState('');
  const { showToast } = useToast();
  const [
    searchQuery,
    {
      loading: searchLoading,
      data: searchData,
      refetch: refetchSearch,
      error: searchError,
    },
  ] = useLazyQuery(query, {
    errorPolicy: 'all',
    onCompleted: (d) => options?.onCompleted?.(d),
  });

  const search = async (
    e: React.ChangeEvent<HTMLInputElement>,
    filters?: Record<string, unknown>,
  ) => {
    await searchQuery({
      variables: {
        input: {
          ...(filters || {}),
          search: e.target.value.trim(),
        },
      },
    });
  };
  const startDebounce = useRef(_.debounce(search, 1000));

  const handleSearchChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    filters?: Record<string, unknown>,
    config?: { searchOnEmpty?: boolean },
  ) => {
    setSearchValue(e.target.value);
    if (!e.target.value.trim() && !config?.searchOnEmpty) {
      startDebounce.current.cancel();
      return;
    }
    startDebounce.current.cancel();
    startDebounce.current(e, filters);
  };

  useEffect(() => {
    if (searchError) {
      return showToast(searchError.message, 'error');
    }
  }, [searchError, showToast]);

  return {
    handleSearchChange,
    setSearchValue,
    searchData,
    searchLoading,
    searchValue,
    searchQuery,
    refetchSearch,
    searchError,
  };
}
