/* eslint-disable no-underscore-dangle */
import { Button, Chip, Column, H6, Row } from 'src/components';
import { useLocation } from 'wouter';
import { useContext, useState } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { getModalParams, theme } from 'src/utils';
import moment from 'moment';
import SummaryInfo from 'src/pages/admin/PatientDetails/Invoice/SummaryInfo';
import { GET_INVOICE, INVOICE_STATUS } from 'src/constants';
import { useQuery } from '@apollo/client';
import InvoicePrintPreview from 'src/pages/admin/PatientDetails/Invoice/InvoicePrintPreview';
import { InvoiceItem } from 'src/types';
import { PrinterIcon } from 'src/assets/icons';
import { InvoiceLoader } from 'src/pages/admin/PatientDetails/Invoice/Empty';
import mixpanel from 'mixpanel-browser';
import SummaryTable from '../components/SummaryTable';
import { ViewContext } from '.';
import ReturnForm from './ReturnForm';

export type ReturnItem = {
  productDescription: string;
  invoiceId: string;
  invoiceItemId: string;
  isHmo: boolean;
  unitCost: number;
  units: number;
};

export const includeTotalCost = (items: InvoiceItem[]) => {
  return [...items].map((item) => ({
    ...item,
    totalCost: (item.units || 0) * (item.unitCost || 0),
  }));
};

const InvoicePreview = () => {
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const invoiceId = getModalParams('id');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [returnItem, setReturnItem] = useState<ReturnItem>();

  const {
    data: invoice,
    loading,
    // error,
  } = useQuery(GET_INVOICE, {
    variables: {
      invoiceId,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const currentInvoice = invoice?.getInvoice
    ? {
        ...invoice.getInvoice,
        items: includeTotalCost(invoice?.getInvoice?.items),
      }
    : undefined;

  const handleRowClick = (item: InvoiceItem) => {
    setReturnItem({
      invoiceId: currentInvoice.invoiceId,
      productDescription: item.description,
      unitCost: item.unitCost as number,
      isHmo: item.isHMOPayable as boolean,
      invoiceItemId: item._id as string,
      units: item.units as number,
    });
  };

  const goBack = () => {
    setView('history');
    setLocation(`#inventory-invoice&mView=history`);
  };

  return (
    <>
      {loading && !invoice && (
        <Column minWidth="50vw">
          <InvoiceLoader />
        </Column>
      )}
      {!loading && !isPreviewOpen && !returnItem && currentInvoice && (
        <ViewWrapper goBack={goBack}>
          <Column width="100%" height="100%" gap={0.5}>
            <Row justify="space-between">
              <H6>
                <span>Invoice </span>
                {currentInvoice?.invoiceId && (
                  <span data-testid="invoice-id">
                    {` : ${currentInvoice?.invoiceId}`}
                  </span>
                )}
              </H6>
              <Chip
                type="round"
                modStyles={{ px: 0.6, py: 0.2 }}
                color={
                  INVOICE_STATUS[
                    (
                      currentInvoice.status as string
                    ).toUpperCase() as keyof typeof INVOICE_STATUS
                  ]
                }
              >
                {currentInvoice.status === 'PAID' ? 'Paid' : 'Unpaid'}
              </Chip>
            </Row>
            {currentInvoice && (
              <Row justify="space-between">
                <SummaryInfo
                  label="INVOICE ID"
                  value={currentInvoice?.invoiceId || ''}
                />
                <SummaryInfo
                  label="Date Generated"
                  value={moment(currentInvoice?.createdAt || '').format(
                    'h:mm a, MMM. D, YYYY',
                  )}
                />
              </Row>
            )}
            {currentInvoice?.hmo && (
              <Row justify="space-between">
                <SummaryInfo
                  label="HMO Provider"
                  value={currentInvoice?.hmo?.name || ''}
                />
                <SummaryInfo
                  label="HMO ID"
                  value={currentInvoice?.hmo?.hmoId || ''}
                />
              </Row>
            )}
            <SummaryTable
              currentInvoice={currentInvoice}
              handleRowClick={handleRowClick}
            />
            <Button
              background={theme.grey[100]}
              data-testid="print-invoice"
              width="max-content"
              color={theme.grey[600]}
              onClick={() => {
                mixpanel.track(`Click 'Print Receipt' button`, {
                  feature: 'Inventory Invoice',
                });
                setIsPreviewOpen(true);
              }}
              modStyles={{ ml: 'auto' }}
            >
              <PrinterIcon />
              Print Receipt
            </Button>
          </Column>
        </ViewWrapper>
      )}
      {isPreviewOpen && !returnItem && (
        <ViewWrapper goBack={() => setIsPreviewOpen(false)}>
          <InvoicePrintPreview invoice={currentInvoice} />
        </ViewWrapper>
      )}
      {returnItem && !isPreviewOpen && (
        <ViewWrapper goBack={() => setReturnItem(undefined)}>
          <ReturnForm
            returnItem={returnItem}
            goBack={() => setReturnItem(undefined)}
          />
        </ViewWrapper>
      )}
    </>
  );
};

export default InvoicePreview;
