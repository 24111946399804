export const generateGreetings = () =>  {
  const currentHour: number = new Date().getHours();
  if (currentHour >= 0 && currentHour < 12) {
    return "Good Morning";
  } 
  if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } 
  return "Good Evening";

}
