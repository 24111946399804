import { Column, Row, Input, Select, Paragraph } from 'src/components';
import { theme } from 'src/utils';
import { DeleteIcon } from 'src/assets/icons';
import { MultiRowForm } from 'src/containers';
import { CreateStepTemplate } from 'src/types';
import { FormikErrors, useFormikContext } from 'formik';
import { useMultiRowParams } from 'src/hooks/useMultiRowParams';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import { ACTIONS_TYPE } from 'src/constants';

const ActionItems = () => {
  const {
    values,
    setValues,
    handleChange,
    errors
  } = useFormikContext<CreateStepTemplate>();

  const state = {
    tag: "",
    title: ''
  }

  const { actions } = values || {} as CreateStepTemplate;
  const { addNewRow, deleteRow } = useMultiRowParams<CreateStepTemplate>(
    'actions',
    state,
    values, 
    setValues, 
  );

  const getErrorMessage = (idx: number, field: string) => {
    return (
      (errors as FormikErrors<Record<string, unknown>>)[
        `actions.${idx}.${field}`
      ] &&
      (
        (errors as FormikErrors<Record<string, unknown>>)[
          `actions.${idx}.${field}`
        ] as unknown as string[]
      )[0]
        .split(' ')
        .splice(2)
        .join(' ')
    );
  };



  return (
    <Column justify="space-between" gap={1}>
      <Paragraph color={theme.grey[500]}>
        Give information about this step
      </Paragraph>
      <MultiRowForm
        shouldShowSuggestion={actions?.[actions.length - 1]?.title !== ''}
        onAddNewRow={addNewRow}
      >
        {actions.map(({ title, tag }, index) => (
          <Column key={index}>
            <Row gap={1} align="flex-end">
              <Row
                align="center"
                justify="space-between"
                width="100%"
                gap={1}
              >
                <Input
                  label="Task Title"
                  data-testid="task-title"
                  placeholder="E.g Make Payments"
                  name={`actions[${index}].title`}
                  value={title || ""}
                  error={getErrorMessage(index, 'title')}
                  onChange={handleChange}
                />
                <Row width="45%">
                  <Select
                    label="Tag Action"
                    data-testid="tag-action"
                    name={`actions[${index}].tag`}
                    value={tag || ""}
                    error={getErrorMessage(index, 'tag')}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Choose one
                    </option>
                    {ACTIONS_TYPE.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </Row>
              </Row>
              <Row width="2.875rem">
                <MultiRowDeleteButton
                  variant="solid"
                  data-testid="delete-action-row"
                  background={theme.grey[100]}
                  color={theme.grey[200]}
                  size="2.5rem"
                  type="button"
                  onClick={() => deleteRow(index)}
                  onFocus={(e) => e.stopPropagation()}
                >
                  <DeleteIcon />
                </MultiRowDeleteButton>
              </Row>
            </Row>
          </Column>
        ))}
      </MultiRowForm>
    </Column>
  );
};

export default ActionItems;
