import { TabHeaderItemButtonType } from 'src/types';
import { font, theme } from 'src/utils/variables';
import styled from 'styled-components';

export const TabHeaderBase = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  gap: 0.5rem;
  border-bottom: 0.5px solid #EDE9E5;
`;

export const TabHeaderItemButton = styled.div<TabHeaderItemButtonType>`
  background: transparent;
  border: none;
  color: ${({ active }) => (active ? theme.secondary[700] : theme.grey[700])};
  svg,
  path {
    stroke: ${({ active }) =>
      active ? theme.secondary[700] : theme.grey[700]};
    fill: none;
  }
  font-size: ${font.sizes.base};
  font-weight: ${font.weights.bold};
  padding: 0.5em;
  border-bottom: ${({ active }) => (active ? '2px solid' : 'none')};
  border-color: ${({ active }) => (active ? theme.secondary[700] : 'none')};
  cursor: pointer;
  height: 100%;
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
`;
