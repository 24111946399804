import styled, { css, keyframes } from 'styled-components';
import { font, theme } from 'src/utils/variables';
import { ButtonProps } from 'src/types';
import { injectModStyles } from '..';

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const getBg = (background: string) => {
  if (background === 'neutral') {
    return theme.grey[50]; 
  }

  if (Object.keys(theme).includes(background)) {
    return theme[background][700]; 
  }

  return background;
};


const getColor = (color: string) =>
  Object.keys(theme).includes(color) ? theme[color][100] : color;

export const BaseButton = styled.button<ButtonProps>`
  ${({ variant, background, color, noIconStyles }) => {
    const bg = getBg(background || 'primary');
    const col = getColor(color || 'white');

    switch (variant) {
      case 'solid':
        return css`
          border: none;
          background-color: ${bg};
          color: ${col};
          svg,
          path,
          * {
            stroke: ${!noIconStyles && col};
            color: ${!noIconStyles && col};
          }
        `;
      case 'secondary':
        return css`
          border: 1px solid #d5d5d5;
          background-color: ${theme.grey[100]};
          color: ${theme.grey[600]};
          svg,
          path,
          * {
            stroke: ${!noIconStyles && theme.grey[600]};
            color: ${!noIconStyles && theme.grey[600]};
          }
        `;
      case 'cancel':
        return css`
          border: 1px solid #d5d5d5;
          background-color: ${theme.grey[50]};
          color: ${theme.grey[800]};
          svg,
          path,
          * {
            stroke: ${!noIconStyles && theme.grey[800]};
            color: ${!noIconStyles && theme.grey[800]};
          }
        `;
      case 'outlined':
        return css`
          background-color: transparent;
          color: ${bg};
          border: 1px solid ${bg};
          svg,
          path,
          * {
            color: ${!noIconStyles && col};
            stroke: ${!noIconStyles && bg};
          }
        `;
      case 'text':
        return css`
          background-color: transparent;
          border: none;
          color: ${bg};
          svg,
          path,
          * {
            color: ${!noIconStyles && col};
            stroke: ${!noIconStyles && bg};
          }
        `;
      default:
        return css`
          border: none;
          background-color: ${bg};
          color: ${col};
          svg,
          path {
            color: ${!noIconStyles && col};
          }
        `;
    }
  }};

  ${({ isLoading, color }) => {
    const col = getColor(color || 'white');
    return (
      isLoading &&
      css`
        cursor: not-allowed;
        color: transparent;
        svg {
          visibility: hidden;
        }
        pointer-events: none;
        user-select: none;
        &::after {
          content: '';
          position: absolute;
          width: 1rem;
          height: 1rem;
          border: 0.25rem solid transparent;
          border-top-color: ${col};
          border-radius: 50%;
          animation: ${rotate360} 1s ease infinite;
        }
      `
    );
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-family: inherit;
  text-wrap: nowrap;
  font-size: ${font.sizes.base};
  font-weight: ${font.weights.semibold};
  width: ${({ width }) => width || '100%'};
  cursor: pointer;
  &:hover {
    opacity: 70%;
  }
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
    padding: 0.75rem 0.625rem;
    width: max-content;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const IconButton = styled(BaseButton)<
  ButtonProps & { toolTipTitle?: string }
>`
  padding: ${({ size }) => `${size || 0}rem`};
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 960px) {
    width: max-content;
  }
  &:hover {
    ${({ toolTipTitle }) => {
      return (
        toolTipTitle &&
        css`
          &::after {
            font-size: ${font.sizes.xs};
            content: '${toolTipTitle ?? ''}';
            width: max-content;
            position: absolute;
            bottom: 160%;
            z-index: 10;
            background-color: ${theme.grey[700]};
            font-weight: ${font.weights.bold};
            color: ${theme.white[100]};
            padding: 0.2rem 0.5rem;
            border-radius: 0.25rem;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 130%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 0.2rem solid transparent;
            border-right: 0.2rem solid transparent;
            border-top: 0.4rem solid ${theme.grey[700]};
          }
        `
      );
    }}
  }
`;
