import { ArrowUpIcon } from 'src/assets/icons';
import { DropdownSearchProps } from 'src/types';
import _ from 'lodash';
import { Avatar, Text, SearchInput, Row } from 'src/components';
import useClickAway from 'src/utils/useClickAway';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { generateAvatar } from 'src/utils';
import { DropdownContainer, DropdownItem } from '../Dropdown/styled';
import {
  DropdownButton,
  DropdownLabelContainer,
  DropdownSearchWrapper,
  LabelContainer,
} from './styled';

export const DropdownSearch: React.FC<DropdownSearchProps> = ({
  name,
  title = "",
  type = "button",
  width = '100%',
  onSearchInputChange,
  description = "",
  label,
  error,
  loading,
  refetchLoading,
  onChange,
  value,
  data,
  render,
  icon = <ArrowUpIcon />,
  valueAccessor = '',
  srcAccessor = '',
  onClose,
  labelAccessor = '',
  hasNextPage,
  onLoadMore,
  ...props
}) => {
  const loadmore = () => {
    if (onLoadMore && hasNextPage) {
      onLoadMore();
    }
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading || false,
    hasNextPage: hasNextPage || false,
    onLoadMore: loadmore,
    rootMargin: '0px 0px 200px 0px',
  });

  const { ref, isComponentVisible, setIsComponentVisible } = useClickAway(
    false,
    onClose,
  );

  const onOpen = () => {
    setIsComponentVisible(!isComponentVisible);
    onClose?.();
  };

  const titleObj = _.find(data, (v) => {
    return v?.[valueAccessor] === value;
  });

  const titleValue = _.map(labelAccessor, (path, idx) =>
    _.get(titleObj, path, idx === 0 ? title : ''),
  );

  const renderLoading = () => (
    <Row width="100%" justify="center">Loading...</Row>
  );

  const renderNoData = () => (
    <Row width="100%" justify="center">No data</Row>
  );

  const getValue = () => {
    if (type !== "button") {
      return (!isComponentVisible && value) ? titleValue.join(" ") : undefined;
    }
    return titleValue.join(" ");
  };


  return (
    <DropdownContainer
      width={width}
      ref={ref}
      modStyles={props.modStyles}
    >
      <DropdownButton 
        type={type}
        onClick={onOpen}
        label={label}
        value={getValue()}
        onChange={onSearchInputChange}
        placeholder={props.placeholder}
        icon={icon}
        disabled={props.disabled}
        required={props.required}
        aria-expanded={isComponentVisible}
        error={error}
      />
      {isComponentVisible && (
        <DropdownSearchWrapper>
          {type === "button" && (
            <SearchInput
              onChange={onSearchInputChange}
              placeholder={props.placeholder}
              autoFocus
            />
          )}
          <LabelContainer ref={rootRef}>
            {data && data?.length > 0 && description}
            {data && data?.length > 0 && data?.map((row, index) => {
              const val = _.get(row, valueAccessor, '') as string;
              const labelText = _.map(labelAccessor, (path) =>
                _.get(row, path, ''),
              ).join(' ') as string;
              const src = _.get(row, srcAccessor, generateAvatar(labelText)) as string;

              return (
                <Row ref={sentryRef} key={index}>
                  <DropdownItem
                    type="radio"
                    id={val}
                    value={val}
                    name={name}
                    onChange={(e) => onChange?.(e, row)}
                    onClick={onOpen}
                    {...props}
                  />
                  <DropdownLabelContainer htmlFor={val}>
                    {render ? render(row) : (
                      <>
                        <Avatar size={33} src={src} />
                        <Text>{labelText}</Text>
                      </>
                    )}
                  </DropdownLabelContainer>
                </Row>
              );
            })}
            {(loading || refetchLoading) && renderLoading()}
          </LabelContainer>
          {(!loading && data && data?.length === 0) && renderNoData()}
        </DropdownSearchWrapper>
      )}
    </DropdownContainer>
  );
};