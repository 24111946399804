import { Row, Text } from 'src/components';
import { Modal } from 'src/containers';
import { theme, font } from 'src/utils/variables';
import styled from 'styled-components';

export const NotificationModal = styled(Modal)`
  max-width: 30rem;
  h6 {
    @media (max-width: 960px) {
      font-size: ${font.sizes.base};
    }
  }
`;

export const NotificationCard = styled(Row)`
  background-color: ${theme.primary[100]};
  border-radius: 0.625rem;
  padding: 0.75rem;
  img {
    padding: 0rem;
  }
  ${Text} {
    margin: 0;
  }
`;
