import { gql } from '@apollo/client';

export const UPDATE_MEDICAL_SUMMARY = gql`
  mutation UpdateMedicalSummary(
    $input: UpdateMedicalSummaryInput!
    $patientId: ID!
  ) {
    updateMedicalSummary(input: $input, patientId: $patientId) {
      success
      message
    }
  }
`;

export const ADD_MEDICATION = gql`
  mutation AddMedication($patientId: ID!, $input: UpdateMedicationsInput!) {
    addMedication(patientId: $patientId, input: $input) {
      message
      success
    }
  }
`