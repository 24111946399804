import { useContext, useState } from 'react';
import { GET_APPOINTMENT, GET_APPOINTMENTS, SEARCH_APPOINTMENTS } from 'src/constants';
import { View } from 'react-big-calendar';
import _ from 'lodash';
import moment from 'moment';
import { OperationVariables, useQuery } from '@apollo/client';
import {
  AppointmentType,
  Appointments,
  GetAppointmentType,
  SearchAppointmentType,
} from 'src/types';
import { useApolloSearch } from 'src/hooks';
import AppointmentContext from './context';
import { useStaff } from '../staff/hook';
import { useRowData } from '../rowData';


interface GroupedAppointments {
  [date: string]: Appointments[];
}


const useQueries = () => {
  const { initialAppointments, setInitialAppointments } =
    useContext(AppointmentContext);
  const [appointmentData, setAppointmentData] = useState<Appointments[]>(
    initialAppointments?.getAppointments,
  );
  const appointmentLength = appointmentData?.length;
  const dateFormat = 'YYYY-MM-DD';
  const { staff, hasPermission } = useStaff();
  const { updateRowData } = useRowData();
  const isDoctor = staff.getStaff.staffGroup.name.toLowerCase() === 'doctor';

  const formatStartDate = (date: Date | string, view?: View) => {
    return view === 'week'
      ? moment(date).clone().startOf('w').subtract(1, 'w').format(dateFormat)
      : moment(date).subtract(1, 'd').format(dateFormat);
  };

  const formatEndDate = (date: Date | string, view?: View) => {
    return view === 'week'
      ? moment(date).clone().endOf('w').add(1, 'w').format(dateFormat)
      : moment(date).add(1, 'd').format(dateFormat);
  };

  const sortAppointment = (data: Appointments[]): GroupedAppointments | undefined => {
    if (data?.length === 0) return undefined
    return data?.reduce((result: GroupedAppointments, appointment: Appointments) => {
      const appointmentDate: string = appointment?.appointmentDate?.split?.('T')?.[0] || appointment?.date?.split?.('T')?.[0];

      if (!result[appointmentDate]) {
        result[appointmentDate] = [];
      }
      result[appointmentDate].push(appointment);
      result[appointmentDate].sort((a, b) => (a?.visitTime?.startTime > b?.visitTime?.startTime ? 1 : -1));

      return result;
    }, {});
  }

  const useSearchAppointment = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();

    const result = useQuery<SearchAppointmentType>(SEARCH_APPOINTMENTS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission('VIEW_CALENDAR'),
      variables: {
        input: {
          ...value,
          search: searchValue,
        },
      },
    });

    return { ...result, others, searchValue };
  };

  const useGetAppointments = (
    date: Date | string,
    facility: string,
    provider?: string | null,
    view?: View,
    skip?: boolean,
  ) => {
    const defaultValue = {
      startDate: formatStartDate(new Date()),
      endDate: formatEndDate(new Date()),
      provider: isDoctor ? staff.getStaff.id : undefined,
      facility: staff.getStaff.facility.id,
    };

    const value = {
      startDate: formatStartDate(date, view),
      endDate: formatEndDate(date, view),
      provider: provider || undefined,
      facility,
    };

    const isEqualValue = _.isEqual(defaultValue, value);

    const result = useQuery<AppointmentType>(GET_APPOINTMENTS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_CALENDAR"),
      variables: { input: value },
      onCompleted: (data) => {
        setAppointmentData(data?.getAppointments);
        if (isEqualValue) {
          setInitialAppointments(data);
        }
      },
    });

    return { ...result, initialAppointments, appointmentData };
  };

  const useAppointment = (value?: OperationVariables, skip?: boolean) => {
    const result = useQuery<GetAppointmentType>(GET_APPOINTMENT, {
      errorPolicy: 'all',
      skip: skip || !hasPermission('VIEW_APPOINTMENT_DETAILS'),
      variables: value,
      onCompleted: (data) => updateRowData('appointment', data.getAppointment[0]),
    });

    return { ...result };
  };

  return {
    formatStartDate,
    formatEndDate,
    sortAppointment,
    useSearchAppointment,
    useGetAppointments,
    useAppointment,
    appointmentLength
  };
};

export default useQueries;