import { useCallback, useEffect, useRef, useState } from 'react';
import { Column, ErrorComponent, PageLoader, Text } from 'src/components';
import { generateTimeObject, openModal, theme } from 'src/utils';
import {
  Event,
  Formats,
  momentLocalizer,
  Calendar,
  View,
  Views,
} from 'react-big-calendar';
import 'moment/locale/en-gb';
import moment from 'moment';
import { Appointments, Staff } from 'src/types';
import {
  useRowData,
  useToast,
  useStaff,
  usePatients,
  useStaffs,
  useAppointments,
} from 'src/state';
import { CURRENT_DATE, PERMISSION_ACTION_MESSAGE, VIEWS } from 'src/constants';
import { PlusIcon } from 'src/assets/icons';
import { NoEvents } from 'src/assets/images';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { ResourceHeaders, EventContainer, DayHeader } from '../Components';
import { FloatingButton, StyledContainer } from '../styled';


export type UpdatedAppointment = Event & {
  appointment: Appointments;
};


export const BigCalendar = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { showToast } = useToast();
  const { params } = useSearchParams();
  const { usePatients: usePatientsData } = usePatients();
  const { initialPatients } = usePatientsData();
  const { updateRowData, getDataByKey } = useRowData();
  const scrollOblectValue = getDataByKey("scroll");
  const localizer = momentLocalizer(moment);
  const {  hasPermission , staff } = useStaff();
  const staffGroup = staff.getStaff.staffGroup.name;
  const isDoctor = staffGroup.toLowerCase() === 'doctor' || staffGroup.toLowerCase() === 'doctors';
  const doctorId = isDoctor ? staff.getStaff.id : null;
  const noOfpatients = initialPatients?.searchPatients?.total;
  const defaultFacility = staff.getStaff.facility.id;
  const idx = params.get("idx") as string;
  const view = (params.get("view") || Views.DAY) as View;
  const date = (params.get("date") || CURRENT_DATE) as string;
  const facility = (params.get("facility") || defaultFacility) as string;
  const isSameFacility = facility === defaultFacility;
  const [, setDate] = useState<Date>(moment(date).toDate());
  const [, setView] = useState<View>(view);
  const onView = useCallback((newView) => setView(newView), [setView]);
  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
  const { useGetAppointments, sortAppointment } = useAppointments();

  const { useDoctors, useOwner } = useStaffs();
  const { owner, loading: ownerLoading } = useOwner();
  const {
    activeDoctors,
    data: doctorsData,
    loading: doctorsLoading
  } = useDoctors({ facility, status: 'Active' }, !owner?.id);

  const loading = ownerLoading || doctorsLoading;

  const noOfPhysicians = isSameFacility
    ? activeDoctors?.searchStaff?.staff?.length || 0 
    : doctorsData?.searchStaff?.total || 0;

  const physiciansList = isSameFacility
    ? activeDoctors?.searchStaff?.staff 
    : doctorsData?.searchStaff?.staff;

  const dateValue = moment(date).subtract(Number(idx), 'd').toDate();
  const { error, appointmentData, initialAppointments } = useGetAppointments(
    dateValue,
    facility,
    doctorId,
    view
  );


  const events = (sortAppointment(appointmentData)?.[date] || sortAppointment(initialAppointments?.getAppointments)?.[date] || []).map((appointment) => ({
    id: appointment.id,
    title: appointment.type,
    start: generateTimeObject(
      appointment.appointmentDate || appointment.date,
      appointment.visitTime.startTime,
    ),
    end: generateTimeObject(
      appointment.appointmentDate || appointment.date,
      appointment.visitTime.endTime,
    ),
    allDay: false,
    resourceId: appointment.provider.id,
    appointment,
  }));
  
  const appointmentsGrouped = sortAppointment(appointmentData);
  const appointments =  appointmentsGrouped?.[date] || [];
  const firstAppointment = appointments?.[0] || null;
  const eventRef = ref?.current?.getElementsByClassName(scrollOblectValue?.scrollId as string);
  
  useEffect(() => {
    if (firstAppointment) updateRowData("scroll", { scrollId: firstAppointment.id })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstAppointment]);


  useEffect(() => {
    if (eventRef && date && eventRef?.length > 0) {
      (eventRef as unknown as HTMLCollection)?.[0].scrollIntoView();
    }
  }, [date, eventRef, scrollOblectValue?.scrollId]);

  const resources = [...([owner] || [] as unknown as Staff[]), ...(physiciansList || [])]?.map((physician) => {
    return {
      id: physician?.id,
      title: physician?.user?.fullName,
      profileAvatar: physician?.user?.profileAvatar,
    };
  });

  const eventPropGetter = useCallback(() => {
    return {
      style: {
        backgroundColor: `initial`,
        border: `none`,
        padding: `0.1875rem 0.1875rem 0.1875rem 0.375rem`,
      },
    };
  }, []);


  const onSelectSlot = useCallback(
    (slotInfo) => {
      if (!moment(slotInfo.start).isSameOrAfter(moment().toDate(), 'hour'))
        return showToast('cannot select a time in the past', 'error');

      if (!hasPermission("CREATE_APPOINTMENT"))
        return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
      openModal('add-appointment');
      updateRowData('slot', slotInfo);
    },
    [updateRowData, showToast,  hasPermission ],
  );

  const onSelectEvent = useCallback(
    (calEvent) => {
      if (!hasPermission('VIEW_APPOINTMENT_DETAILS'))
        return showToast(PERMISSION_ACTION_MESSAGE, 'warning');

      updateRowData('appointment', calEvent.appointment);
      openModal('view-appointment', calEvent.id);
    },
    [updateRowData, showToast,  hasPermission ],
  );

  const formats: Formats = {
    timeGutterFormat: 'hA',
  };

  if (error) return <ErrorComponent />;
  if (!loading && (noOfPhysicians === 0 || noOfpatients === 0)) {
    return (
      <Column
        justify="center"
        align="center"
        gap={1}
        height="calc(100vh - 7.5rem)"
      >
        <NoEvents />
        <Text size="sm" color={theme.grey[500]}>
          {!hasPermission('VIEW_CALENDAR')
            ? 'You do not have permission to view appointments'
            : 'There are no doctors / patients attached to this Facility'}
        </Text>
      </Column>
    );
  }
  if (loading) {
    return (
      <Column
        justify="center"
        align="center"
        gap={1}
        height="calc(100vh - 7.5rem)"
      >
        <PageLoader />
      </Column>
    );
  }


  return (
    <StyledContainer
      ref={ref}
      isDoctorAndWeek={view === VIEWS.WEEK && isDoctor}
    >
      {hasPermission('CREATE_APPOINTMENT') && (
        <FloatingButton
          title="Add Appointment"
          data-testid='appointment-button-2'
          size="0.5"
          onClick={() => {
            updateRowData('slot', {});
            openModal('add-appointment');
          }}
        >
          <PlusIcon />
        </FloatingButton>
      )}
      
      <Calendar
        localizer={localizer}
        events={events}
        eventPropGetter={eventPropGetter}
        selectable
        selected
        formats={formats}
        timeslots={1}
        step={60}
        date={date}
        toolbar={false}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        onDoubleClickEvent={onSelectEvent}
        components={{
          resourceHeader: (props) => (
            <ResourceHeaders
              name={props.label as string}
              avatar={props.resource.profileAvatar}
            />
          ),
          header: (props) => <DayHeader date={props.date} />,
          event: (props) => {
            const event = props.event as UpdatedAppointment;
            return <EventContainer {...event} />;
          },
        }}
        resources={isDoctor ? undefined : resources}
        view={isDoctor ? view : Views.DAY}
        onNavigate={onNavigate}
        onView={onView}
      />
    </StyledContainer>
  );
};
