import { gql } from '@apollo/client';


export const hmoFieldsFragment = gql`
  fragment HMOFields on Hmo {
    name
    id: _id
    createdAt
    updatedAt
    deletedAt
    phoneNumber
    email
    logo
    numberOfPatients
    deletedAt
    hmoId
    addedBy {
      user {
        firstName
        lastName
      }
    }
  }
`;
