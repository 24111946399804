import {
  Button,
  Column,
  IconButton,
  Row,
  injectModStyles,
} from 'src/components';
import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import { font, theme } from 'src/utils/variables';
import styled from 'styled-components';

export const JourneyModal = styled(Modal)`
  @media (max-width: 960px) {
    min-width: 100vw;
  }
  ${ModalChildContainer} {
    width: max-content;
    ${(props) => props.modStyles && injectModStyles(props.modStyles)}
    @media (max-width: 960px) {
      padding: 0;
      min-width: 100%;
    }
  }
  h6 {
    @media (max-width: 960px) {
      font-size: ${font.sizes.base};
    }
  }
`;

export const StepStart = styled.p`
  display: flex;
  align-items: center;
  padding: 0.4375rem 0.9375rem;
  border-radius: 0.9375rem;
  border: 1px solid #ede9e5;
  background: #fff;
`;

export const VerticalLine = styled.div<{ height?: number }>`
  display: flex;
  border-left: 1px dashed ${theme.grey[700]};
  padding-block: 0.8rem;
`;

export const Circle = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  padding-block: 0.125rem;
  background-color: ${theme.grey[700]};
  border-radius: 50%;
  position: sticky;
  top: 0;
  z-index: 9999;
`;

export const SemiCircle = styled.div<{ shouldRotate?: boolean }>`
  width: 0.5rem;
  height: 0.25rem;
  padding-block: 0.125rem;
  background-color: ${theme.grey[700]};
  border-radius: 0.25rem 0.25rem 0 0;
  position: sticky;
  top: 0;
  z-index: 9999;
  ${({ shouldRotate }) => shouldRotate && `transform: rotate(180deg)`}
`;

export const StepCard = styled(Row)`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  padding: 0.94rem;
  max-width: 80%;
  border-radius: 1.0625rem;
  box-shadow: 3px 8px 30px 5px rgba(0, 0, 0, 0.04);
`;

export const TaskIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ede9e5;
`;

export const AddStepButton = styled.button`
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.9375rem 1.875rem;
  border-radius: 0.9375rem;
  border: 1px solid var(--lines, #ede9e5);
  background: var(--new-colors-white, #fff);
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.02);
`;

export const RoundButton = styled(IconButton)`
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  path {
    stroke-width: 0;
  }
  @media (max-width: 960px) {
    width: 1.5rem;
    height: 1.5rem;
    & + span {
      font-size: ${font.sizes.sm};
    }
    svg {
        width: 0.5rem;
        height: 0.5rem;
      }    
    }
  }
`;

export const TagLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  padding: 0.1875rem;
  background: #fff;
  border: 1px solid #d5d5d5;
  cursor: pointer;
`;

export const AvatarGroupContainer = styled(Column)`
  position: relative;
  // cursor: pointer;
`;

export const StaffList = styled.ul`
  max-height: 15rem;
  width: max-content;
  min-width: 17.625rem;
  max-width: 25rem;
  list-style: none;
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  list-style-type: none;
  z-index: 9999;
  background-color: ${theme.white[100]};
  border-radius: 0.625rem;
  padding: 0.3125rem;
  margin-top: 0.375rem;
  box-shadow: 2px 2px 36px 1px rgba(28, 27, 27, 0.11);
  top: 44px;
  overflow-y: auto;
`;

export const StaffListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  text-align: left;
  padding: 0.5rem 1rem;
  color: ${theme.grey[900]};
  gap: 1rem;
`;

export const ViewHeader = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  width: 100%;
`;

export const TemplateButton = styled(Button)`
  font-size: 0.725rem;
  padding-block: 0.625rem;
`;
