import { useCallback, useContext, useEffect, useState } from 'react';
import { 
  ADD_RECORD,
  DEFAULT_NOTES, 
  GET_RECORD_TEMPLATE, 
  NOTE, 
  SEARCH_RECORD_TEMPLATE 
} from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PaginatedTemplates } from 'src/types';
import NoteTemplateContext from './context';
import { useStaff } from '../staff';
import { useToast } from '../toast';


const useNoteTemplate = () => {
  const { initialNoteTemplates, setInitialNoteTemplates } = useContext(NoteTemplateContext);

  const [filteredNoteTemplates, setFilteredNoteTemplates] = useState<PaginatedTemplates>({} as PaginatedTemplates);

  const [notesDropdown, setNotesDropdown] = useState<PaginatedTemplates>(initialNoteTemplates?.getNoteTemplates);
  const [refetchLoading, setRefetchLoading] = useState<boolean>(false);

  const [getNoteTemplate, { 
    data: getNoteData,
    loading: noteLoading,
    error: noteError 
  }] = useLazyQuery(GET_RECORD_TEMPLATE, { errorPolicy: "all"});

  // const [searchRecordTemplate, {
  //   data: searchNoteData,
  //   loading: searchRecordLoading,
  //   error: searchError
  // }] = useLazyQuery(SEARCH_RECORD_TEMPLATE, { errorPolicy: "all" });

  const [addNote, { 
    data: addNoteData,
    loading: addNoteLoading, 
    error: addNoteError 
  }] = useMutation(ADD_RECORD);

  const {  hasPermission  } = useStaff();
  const { showToast } = useToast()

  const {
    handleSearchChange,
    searchData,
    searchLoading,
    searchValue,
    searchQuery
  } = useSearch(SEARCH_RECORD_TEMPLATE);


  useEffect(() => {
    [addNoteError].forEach((err) => {
      if (err) {
        showToast(err.message, "error");
      }
    });
  }, [addNoteError, showToast]);


  useEffect(() => {
    if (searchValue !== "" && searchData) {
      setFilteredNoteTemplates(searchData.searchRecordTemplates)
      setNotesDropdown(searchData.searchRecordTemplates)
    }
  }, [searchValue, searchData, setFilteredNoteTemplates, setNotesDropdown]);


  const availableNoteTemplates = initialNoteTemplates?.defaultNoteTemplate ? [
    ...(initialNoteTemplates?.getNoteTemplates?.recordTemplates || []),
    ...(filteredNoteTemplates?.recordTemplates || []),
    initialNoteTemplates?.defaultNoteTemplate
  ] : [];

  const getPageNumber = (
    docLength: number
  ) => Math.ceil(docLength / 10);

  const notesDropdownPage = getPageNumber(notesDropdown?.recordTemplates?.length) || 1;

  const useFetchNoteTemplates = (skip?: boolean) => {
 

    const defaultNoteResult = useQuery(SEARCH_RECORD_TEMPLATE, {
      errorPolicy: 'all',
      skip,
      variables: { input: { search: DEFAULT_NOTES } },
      onCompleted: (d) => setInitialNoteTemplates((prev) => ({
        ...prev,
        defaultNoteTemplate: d.searchRecordTemplates.recordTemplates[0]
      })),
    });

    const noteResult = useQuery(SEARCH_RECORD_TEMPLATE, {
      errorPolicy: 'all',
      skip,
      variables: { input: { search: NOTE } },
      onCompleted: (d) => {
        setInitialNoteTemplates((prev) => ({
          ...prev,
          getNoteTemplates: d.searchRecordTemplates,
        }))
        setNotesDropdown(d.searchRecordTemplates)
      }
    });

    return { defaultNoteResult, noteResult };
  };


  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!hasPermission("VIEW_RECORD_TEMPLATE")) return;
  //     // const { defaultNoteTemplate, getNoteTemplates } = initialNoteTemplates;

  //     await searchRecordTemplate({
  //       variables: { input: { search: DEFAULT_NOTES } },
  //       onCompleted: (d) => setInitialNoteTemplates((prev) => ({
  //         ...prev,
  //         defaultNoteTemplate: d.searchRecordTemplates.recordTemplates[0]
  //       }))
  //     });

  //     await searchRecordTemplate({
  //       variables: { input: { search: NOTE } },
  //       onCompleted: (d) => {
  //         setInitialNoteTemplates((prev) => ({
  //           ...prev,
  //           getNoteTemplates: d.searchRecordTemplates,
  //         }))
  //         setNotesDropdown(d.searchRecordTemplates)
  //       }
  //     });
  //   };

  //   fetchData();
  // }, [
  //   searchRecordTemplate,
  //   setInitialNoteTemplates,
  //   setNotesDropdown,
  //    hasPermission ,
  //   initialNoteTemplates
  // ]);

  const fetchNoteCallback = useCallback(async(id) => {
    await getNoteTemplate({
      variables: { input: id },
      onCompleted: (d) => setFilteredNoteTemplates(d.getRecordTemplate)
    });
  }, [getNoteTemplate, setFilteredNoteTemplates]);


  const addNoteCallback = useCallback(async (value) => {
    if (!hasPermission("ADD_NOTE_TO_PATIENT_PROFILE")) return;
    const result = await addNote({
      variables: { input: value },
      onCompleted: () => showToast('Note saved', 'success'),
    });

    return result;
  }, [addNote, showToast,  hasPermission ]);


  const loadMoreNote = useCallback(async () => {
    setRefetchLoading(true)
    await searchQuery({ 
      variables: { 
        input: { search: searchValue, page: notesDropdownPage + 1 } 
      },
      onCompleted: (d) => {
        setNotesDropdown(prev => ({
          ...prev,
          recordTemplates: [
            ...prev.recordTemplates,
            d.searchRecordTemplates.recordTemplates
          ]
        }))
        setRefetchLoading(false)
      },
      onError: () => setRefetchLoading(false)
    })
  }, [searchQuery, searchValue, notesDropdownPage]);


  const resetNoteDropdown = () => {
    setNotesDropdown(initialNoteTemplates?.getNoteTemplates)
  };



  return {
    getNoteData,
    // searchNoteData,
    useFetchNoteTemplates,
    fetchNoteCallback,
    setInitialNoteTemplates,
    initialNoteTemplates,
    addNoteCallback,
    loading: noteLoading,
    error: noteError,
    searchValue,
    handleSearchChange,
    availableNoteTemplates,
    filteredNoteTemplates,
    addNoteLoading,
    addNoteData,
    searchLoading,
    loadMoreNote,
    notesDropdown,
    hasNextPage: notesDropdown?.total > notesDropdown?.recordTemplates?.length,
    resetNoteDropdown,
    setNotesDropdown,
    refetchLoading
  }

} 

export { useNoteTemplate };
