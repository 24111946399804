/* eslint-disable no-underscore-dangle */
import { Column, Row, Text, TextEditorPreview } from 'src/components';
import { useEffect, useState } from 'react';
import { MedicalFileIcon } from 'src/assets/icons';
import _ from 'lodash';
import RecordMeta from 'src/pages/admin/PatientDetails/MedicalTimeline/Records/RecordMeta';
import RecordToolBar from 'src/pages/admin/PatientDetails/MedicalTimeline/Records/RecordToolBar';
import {
  FormRendererModal,
  RecordText,
  LinkText,
  ImagePreview,
} from 'src/pages/admin/PatientDetails/MedicalTimeline/styled';
import {
  RecordWrapper,
  TimelineTag,
  PillText,
  ContentWrapper,
} from 'src/pages/admin/PatientDetails/styled';
import moment from 'moment';
import { decapitalizeFirstLetter } from 'src/utils';
import { useRoute } from 'wouter';
import {
  FieldTitle,
  TableField,
  TableFieldHead,
} from 'src/pages/admin/RecordPreview/styled';
import { useRecordApis, useTimeline } from 'src/store';
import { TableValue, TimelineDataType } from 'src/types';
import { RecordLoader } from 'src/containers/FormRenderer/Loaders';

const RecordModal: React.FC = () => {
  const { getRecordFromCache } = useTimeline();
  const {
    fetchSingleRecord,
    fetchSingleRecordError,
    fetchSingleRecordLoading,
  } = useRecordApis();
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id as string;
  const urlParams = new URLSearchParams(window.location.hash);
  const id = urlParams.get('id');
  const cachedRecord = getRecordFromCache(
    patientId,
    id as string,
  ) as unknown as TimelineDataType;
  const [record, setRecord] = useState<TimelineDataType>(cachedRecord);

  useEffect(() => {
    if (!record) {
      fetchSingleRecord({
        variables: {
          input: id,
        },
        onCompleted(d: { getRecord: TimelineDataType }) {
          setRecord(d.getRecord);
        },
      });
    }
  }, [fetchSingleRecord, id, record]);

  return (
    <FormRendererModal>
      <RecordWrapper gap={1} minWidth="36vw">
        {!record && fetchSingleRecordLoading && <RecordLoader />}
        {!record && fetchSingleRecordError && (
          <Text>Error loading record...</Text>
        )}
        {record && !fetchSingleRecordLoading && (
          <Column gap={1}>
            <RecordToolBar />
            <ContentWrapper>
              {record && record.__typename === 'Record' && (
                <RecordMeta
                  time={moment(record.createdAt).format('h:mm a')}
                  title={record.template.title}
                  provider={record.provider?.user.firstName}
                  facility={record.facility.address}
                  type="record"
                />
              )}
              {record &&
                record.__typename === 'Record' &&
                record.data.map((field) => {
                  return (
                    <Row key={field.id}>
                      <Column gap={0.3}>
                        {field.type === 'InfoValue' && (
                          <Column gap={0.3}>
                            <FieldTitle>{field.title}</FieldTitle>
                            <RecordText>{field?.description}</RecordText>
                          </Column>
                        )}
                        {field.type !== 'InfoValue' && (
                          <TimelineTag>
                            <MedicalFileIcon />
                            {_.capitalize(field.title)}
                          </TimelineTag>
                        )}
                        {['TextValue', 'TextAreaValue', 'NumberValue'].includes(
                          field.type,
                        ) && (
                          <RecordText>
                            {
                              field[
                                decapitalizeFirstLetter(field.type)
                              ] as string
                            }
                          </RecordText>
                        )}
                        {['DateValue'].includes(field.type) && (
                          <RecordText>
                            {moment(field.dateValue).format('Do, MMM, YYYY')}
                          </RecordText>
                        )}
                        {['WYSIWYGValue'].includes(field.type) && (
                          <TextEditorPreview
                            value={field.wysiwygValue as string}
                          />
                        )}
                        {['SelectValue'].includes(field.type) && (
                          <RecordText>{field.selectValue}</RecordText>
                        )}
                        {['ImageValue'].includes(field.type) && (
                          <Row gap={1} align="flex-start">
                            <ImagePreview>
                              <img src={field.imageValue} alt="file" />
                            </ImagePreview>
                            <LinkText href={field.imageValue} target="_blank">
                              see image
                            </LinkText>
                          </Row>
                        )}
                        {['FileValue'].includes(field.type) && (
                          <LinkText href={field.imageValue} target="_blank">
                            see file
                          </LinkText>
                        )}
                        {['MultiSelectValue'].includes(field.type) && (
                          <Row gap={0.5} justify="flex-start">
                            {field.multiSelectValue?.map((val, idx) => (
                              <PillText key={idx}>{val}</PillText>
                            ))}
                          </Row>
                        )}
                        {['TableValue'].includes(field.type) && (
                          <TableField>
                            <TableFieldHead>
                              <tr>
                                {(
                                  field.tableValue as TableValue[]
                                )[0].rowValue.map((v, idx) => (
                                  <th key={`${v.id}-${idx}`}>{v.title}</th>
                                ))}
                              </tr>
                            </TableFieldHead>
                            <tbody>
                              {(field.tableValue as TableValue[]).map(
                                (row, idx) => (
                                  <tr key={`${row.id}-${idx}`}>
                                    {row.rowValue.map((col, i) => (
                                      <td key={`${col.id}-${i}`}>
                                        <RecordText>
                                          {col[
                                            decapitalizeFirstLetter(col.type)
                                          ] || 'Nil'}
                                        </RecordText>
                                      </td>
                                    ))}
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </TableField>
                        )}
                        {['RowValue'].includes(field.type) && (
                          <TableField>
                            <TableFieldHead>
                              <tr>
                                {field.rowValue?.map((v, idx) => (
                                  <th key={`${v.id}-${idx}`}>{v.title}</th>
                                ))}
                              </tr>
                            </TableFieldHead>
                            <tbody>
                              <tr>
                                {field.rowValue?.map((col, i) => (
                                  <td key={`${col.id}-${i}`}>
                                    <RecordText>
                                      {col[decapitalizeFirstLetter(col.type)] ||
                                        'Nil'}
                                    </RecordText>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </TableField>
                        )}
                      </Column>
                    </Row>
                  );
                })}
            </ContentWrapper>
          </Column>
        )}
      </RecordWrapper>
    </FormRendererModal>
  );
};

export default RecordModal;
