import { gql } from "@apollo/client";
import { staffGroupFieldsFragment } from "../Fragments/staffGroup";

export const CREATE_STAFFGROUP = gql`
  mutation CreateStaffGroup($input: CreateStaffGroupsInput!) {
    createStaffGroup(input: $input) {
      success
      message
      staffGroups {
        ...StaffGroupFields
      }
      message
    }
  }
  ${staffGroupFieldsFragment}
`;

export const DELETE_STAFFGROUP = gql`
  mutation DeleteStaffGroup($deleteStaffGroupId: ID!) {
    deleteStaffGroup(id: $deleteStaffGroupId) {
      success
      message
      staffGroups {
        ...StaffGroupFields
      }
    }
  }
  ${staffGroupFieldsFragment}
`;

export const UPDATE_STAFFGROUP = gql`
  mutation UpdateStaffGroup($input: UpdateStaffGroupsInput!) {
    updateStaffGroup(input: $input) {
      success
      message
      staffGroups {
        ...StaffGroupFields
      }
    }
  }
  ${staffGroupFieldsFragment}
`;