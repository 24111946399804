import {
  ADD_NEW_FACILITY,
  DELETE_FACILITY,
  FACILITY_SETUP,
  SEARCH_FACILITIES,
  GET_SERVICES,
  GET_STAFF,
  REGISTER_FACILITY,
  UPDATE_FACILITY,
  UPDATE_ORGANISATION,
} from 'src/constants';
import { useMutation, OperationVariables } from '@apollo/client';
import {
  AddFacilityProps,
  DeleteFacilityProps,
  RegisterFacilityProps,
  SetupAccountProps,
  UpdateFacilityProps,
  UpdateOrganisationProps,
} from 'src/types';
import { useToast } from '../toast';
import { useStaff } from '../staff/hook';

const useMutations = () => {
  const {  hasPermission  } = useStaff();
  const { showToast } = useToast();

  const useAddFacility = () => {
    const [execute, result] = useMutation<AddFacilityProps>(ADD_NEW_FACILITY);

    const addFacility = (value?: OperationVariables) => {
      if (!hasPermission("ADD_NEW_FACILITY")) return;
      return execute({
        variables: { input: value },
        onCompleted: (data) => showToast(data.addFacility.message, 'success'),
        refetchQueries: [SEARCH_FACILITIES],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, addFacility };
  };

  const useUpdateFacility = () => {
    const [execute, result] = useMutation<UpdateFacilityProps>(UPDATE_FACILITY);

    const updateFacility = (value?: OperationVariables) => {
      if (!hasPermission("EDIT_FACILITY")) return;
      return execute({
        variables: { input: value },
        onCompleted: (data) =>
          showToast(data.updateFacility.message, 'success'),
        refetchQueries: [SEARCH_FACILITIES],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, updateFacility };
  };

  const useDeleteFacility = () => {
    const [execute, result] = useMutation<DeleteFacilityProps>(DELETE_FACILITY);

    const deleteFacility = (id: string) => {
      if (!hasPermission("DELETE_FACILITY")) return;
      return execute({
        variables: { input: { facilityId: id } },
        onCompleted: (data) =>
          showToast(data.deleteFacility.message, 'success'),
        refetchQueries: [SEARCH_FACILITIES],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, deleteFacility };
  };

  const useRegisterFacility = () => {
    const [execute, result] =
      useMutation<RegisterFacilityProps>(REGISTER_FACILITY);

    const registerFacility = (value?: OperationVariables) => {
      return execute({
        variables: { input: value },
        onCompleted: (data) =>
          showToast(data.registerOrganisation.message, 'success'),
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, registerFacility };
  };

  const useUpdateOrganisation = () => {
    const [execute, result] =
      useMutation<UpdateOrganisationProps>(UPDATE_ORGANISATION);

    const updateOrganisation = (value?: OperationVariables) => {
      return execute({
        variables: { input: value },
        refetchQueries: [GET_STAFF, GET_SERVICES],
        onError: (err) => showToast(err.message, "error")
      });
    };

    return { ...result, execute, updateOrganisation };
  };

  const useAccountSetup = () => {
    const [execute, result] = useMutation<SetupAccountProps>(FACILITY_SETUP);

    const accountSetup = (value?: OperationVariables) => {
      return execute({
        variables: { input: value },
        onCompleted: (data) => showToast(data.setupAccount.message, 'success'),
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, accountSetup };
  };

  return {
    useAccountSetup,
    useAddFacility,
    useDeleteFacility,
    useRegisterFacility,
    useUpdateOrganisation,
    useUpdateFacility,
  };
};

export default useMutations;
