export const RightArrow: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6508 11.0001L8.40575 7.75505C8.23502 7.58331 8.13919 7.35097 8.13919 7.1088C8.13919 6.86663 8.23502 6.6343 8.40575 6.46255C8.49097 6.37664 8.59235 6.30844 8.70406 6.2619C8.81576 6.21537 8.93558 6.19141 9.05659 6.19141C9.1776 6.19141 9.29741 6.21537 9.40911 6.2619C9.52082 6.30844 9.6222 6.37664 9.70742 6.46255L13.5941 10.3492C13.68 10.4344 13.7482 10.5358 13.7947 10.6475C13.8413 10.7592 13.8652 10.879 13.8652 11.0001C13.8652 11.1211 13.8413 11.2409 13.7947 11.3526C13.7482 11.4643 13.68 11.5657 13.5941 11.6509L9.70742 15.5834C9.62177 15.6683 9.52018 15.7356 9.4085 15.7812C9.29682 15.8268 9.17723 15.8499 9.05659 15.8492C8.93595 15.8499 8.81636 15.8268 8.70467 15.7812C8.59299 15.7356 8.49141 15.6683 8.40575 15.5834C8.23502 15.4116 8.13919 15.1793 8.13919 14.9371C8.13919 14.695 8.23502 14.4626 8.40575 14.2909L11.6508 11.0001Z"
      fill={color || 'black'}
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
