import _ from 'lodash';

export const generateCsvContent = (
  data: Record<string, unknown>[],
  title: string,
) => {
  if (!data || data.length === 0) return [title, 'No Data'].join('\n');

  const filteredData = data.map((row) => {
    const filteredRow: Record<string, unknown> = {};
    Object.keys(row || {}).forEach((key) => {
      if (!key.startsWith('_')) {
        filteredRow[key] = row[key];
      }
    });
    return filteredRow;
  });

  const headers = Object.keys(filteredData?.[0] || {});
  const capitalizedHeaders = Object.keys(filteredData?.[0] || {}).map((el) =>
    _.capitalize(el),
  );

  const csvContent = [
    title,
    capitalizedHeaders?.join(','),
    ...(filteredData || {})?.map((row) =>
      headers?.map((key) => row[key])?.join(','),
    ),
  ].join('\n');
  return csvContent;
};

export const contentsToUrl = (title: string, contents: string[]) => {
  const groupedContents = contents.reduce((content, csvContent) => {
    if (csvContent) {
      return `${content}\n\n${csvContent}`;
    }
    return content;
  }, title);

  const blob = new Blob([groupedContents], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  return url;
};

export const downloadCSV = (url: string, name = 'inventory-report') => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.csv`);
  link.click();
  URL.revokeObjectURL(url);
};
