import * as React from 'react';
import { AllInvoices, OpenInvoices } from 'src/types';
import InvoiceContext from './context';

const InvoicesProvider: React.FC = ({ children }) => {
  const [allInvoices, setAllInvoices] = React.useState<AllInvoices>({});
  const [openInvoices, setOpenInvoices] = React.useState<OpenInvoices>({});

  return (
    <InvoiceContext.Provider
      value={{
        allInvoices,
        openInvoices,
        setAllInvoices,
        setOpenInvoices,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoicesProvider;
