import { ACTION_LIST } from 'src/constants';
import { HR, SidebarItems, SubGroupItemsWrapper, SwitchAccountButton } from 'src/containers/Sidebar/styled';
import { useAuth, useStaff, useUser } from 'src/state';
import { generateAvatar, openModal } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import { ArrowForwardIcon, BusinessIcon, LogoutIcon } from 'src/assets/icons';
import _ from 'lodash';
import { SideBarButtonItem } from 'src/containers/Sidebar/SideBarButtonItem';
import { SidebarConfig } from 'src/types';
import useClickAway from 'src/utils/useClickAway';
import { useEffect } from 'react';
import { Avatar } from '../Avatar';
import { Dropdown } from '../Dropdown';
import { Column, Row } from '../Flex';
import { Text } from '../Typography';

interface SwitchWorkspaceProps {
  expand?: boolean
}

export const SwitchWorkspace: React.FC<SwitchWorkspaceProps> = ({ expand }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickAway(false);
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const { logOut } = useAuth();
  const { staff } = useStaff();
  const { user } = useUser();

  useEffect(() => {
    if (!expand) {
      setIsComponentVisible(false)
    }
  }, [expand, setIsComponentVisible])

  const handleDropdownChange = (name: string, selectedValue: string) => {
    if (selectedValue === '#switch-workspace') {
      return openModal(selectedValue);
    }
    return logOut();
  };
  
  const config: SidebarConfig[] = [
    {
      title: 'Log Out',
      icon: LogoutIcon,
      action: logOut,
      noStroke: true
    },
    {
      title: 'Switch WorkSpace',
      icon: BusinessIcon,
      path: "#switch-workspace",
      noFill: true,
      noStroke: true
    },
  ];

  if (isMobile) {
    return (
      <Dropdown
        name="actions"
        options={
          user?.getUser?.staff.length > 1
            ? ACTION_LIST
            : ACTION_LIST.filter((action) => action.value !== '#switch-workspace')
        }
        position={{
          left: '-130px',
          top: '40px',
        }}
        handleDropdownChange={handleDropdownChange}
        triggerComponent={(toggleStatusDropdown) => (
          <SwitchAccountButton
            data-testid="profile-dropdown"
            data-iscapture="true"
            className="switch-account"
            onClick={() => toggleStatusDropdown()}
          >
            {staff && staff.getStaff && (
              <Avatar
                size={35}
                src={
                  staff.getStaff.facility.organisation.logo ||
                  generateAvatar(staff.getStaff.facility.organisation.name)
                }
              />
            )}
          </SwitchAccountButton>
        )}
      />
    )
  }

  return (
    <Column width="100%" gap={0.5} align="center">
      <HR />
      <SwitchAccountButton onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <>
          {staff && staff.getStaff && (
            <Avatar
              size={40}
              src={
                staff.getStaff.facility.organisation.logo ||
                generateAvatar(staff.getStaff.facility.organisation.name)
              }
            />
          )}
          <Column width="max-content" justify="center">
            <Text size="sm" modStyles={{ my: 0}}>
              {_.truncate(staff.getStaff.user.fullName, { length: 12 })}
            </Text>
            <Text size="xxs" modStyles={{ my: 0}}>
              {_.truncate(staff.getStaff.user.emails[0], { length: 16 })}
            </Text>
          </Column>
          <Row justify='center' align="center" width="1.25rem" height="1.25rem">
            <ArrowForwardIcon />
          </Row>
        </>
      </SwitchAccountButton>
      {isComponentVisible && (
        <SubGroupItemsWrapper ref={ref}>
          {config?.map((item, index) => {
            return (
              <SidebarItems key={`sub-menu-item-${index}`}>
                <SideBarButtonItem 
                  item={item} 
                  onClick={() => setIsComponentVisible(false)} 
                />
              </SidebarItems>
            )
          })}
        </SubGroupItemsWrapper>
      )}
    </Column>
  );
};
