import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_META_DATA } from 'src/constants';
import { MetaDataProps } from 'src/types';
import { useAuth } from '../auth';
import MetaContext from './context';

const MetaProvider: React.FC = ({ children }) => {
  const [metaData, setMetaData] = useState<MetaDataProps>({} as MetaDataProps);
  const { state } = useAuth();


  useQuery(GET_META_DATA, {
    skip: !state.token,
    onCompleted: (data) => {
      setMetaData(data);
    },
  });

  return (
    <MetaContext.Provider value={{ metaData,  setMetaData }}>
      {children}
    </MetaContext.Provider>
  );
};

export default MetaProvider;
