import React from 'react';
import { MedicalSummary } from 'src/types';

export type MedicalSummaryContextType = {
  summary: MedicalSummary;
  setSummary: React.Dispatch<React.SetStateAction<MedicalSummary>>;
};

const MedicalSummaryContext = React.createContext(
  {} as MedicalSummaryContextType,
);

export default MedicalSummaryContext;
