import { EditIcon, ThreeDots, BinIcon } from 'src/assets/icons';
import { openModal } from 'src/utils/modalHelper';
import {
  useRowData,
  useAlert,
  useToast,
  useStaff,
  useFacilities,
} from 'src/state';
import mixpanel from 'mixpanel-browser';
import { Ellipsis, EllipsisButton } from '../styled';

interface ActionColumnProps {
  id: string;
  row: Record<string, unknown>;
}

export const FacilityActionColumn: React.FC<ActionColumnProps> = ({
  id,
  row,
}) => {
  const { useDeleteFacility } = useFacilities();
  const { deleteFacility } = useDeleteFacility();
  const { updateRowData } = useRowData();
  const { showAlert, removeAlert } = useAlert();
  const { showToast } = useToast();
  const { hasPermission, staff } = useStaff();

  const handleDelete = async () => {
    mixpanel.track(`Click 'Delete Facility' button`, {
      feature: 'Manage Facility',
    });
    if (staff.getStaff.facility.id === id) {
      return showToast(
        `cannot delete the facility you are signed into`,
        'warning',
      );
    }

    showAlert({
      type: 'decision',
      title: 'Confirm Delete',
      message: `Do you want to delete ${row.state} facility`,
      confirm: () =>
        deleteFacility(id)?.then((res) => {
          if (res?.data) {
            removeAlert();
          }
        }),
    });
  };

  return (
    <>
      <ThreeDots className="icon" />
      <Ellipsis gap={1} justify="flex-end">
        {hasPermission("EDIT_FACILITY") && (
          <EllipsisButton
            onClick={() => {
              mixpanel.track(`Click 'Edit Facility' button`, {
                feature: 'Manage Facility',
              });
              openModal('edit-facility', id);
              updateRowData('facility', row);
            }}
            data-testid="edit-facility"
            toolTipTitle="Edit"
            variant="text"
          >
            <EditIcon />
          </EllipsisButton>
        )}
        {hasPermission("DELETE_FACILITY") && (
          <EllipsisButton
            toolTipTitle="Delete"
            variant="text"
            data-testid="delete-facility"
            onClick={handleDelete}
          >
            <BinIcon />
          </EllipsisButton>
        )}
      </Ellipsis>
    </>
  );
};
