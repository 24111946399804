/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import { AllInvoiceLogs, InvoiceLog } from 'src/types';

const initialState: AllInvoiceLogs = {};

type PayloadType = {
  payload: {
    invoiceId: string;
    logs: { logs: InvoiceLog[]; total: number };
  };
};

const invoiceLogSlice = createSlice({
  name: 'invoiceLogs',
  initialState,
  reducers: {
    setInvoiceLogs: (state: AllInvoiceLogs, action: PayloadType) => {
      const { invoiceId, logs } = action.payload;
      state[invoiceId] = logs;
    },
  },
});

export const { setInvoiceLogs } = invoiceLogSlice.actions;

export default invoiceLogSlice.reducer;
