import { gql } from '@apollo/client';


export const organisationFieldsFragment = gql`
  fragment OrganisationFields on Organisation {
    id: _id
    name
    logo
    isVerified
    onboardingSteps {
      welcome
      inviteStaff
      setupServices
      staffGroups
    }
  }
`;