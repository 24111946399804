import { useEffect, useRef } from 'react';
import { toast } from 'src/configs/toast';
import { useToast } from 'src/state';
import { ToastConfigTypes } from 'src/types';
import { Column, Row } from 'src/components';
import { ToastContainer, ToastWrapper } from './styled';

export const Toast = ({
  message,
  duration = 3000,
  type,
  id,
}: {
  message: string;
  duration?: number;
  type: ToastConfigTypes;
  id: string;
}) => {
  const { removeToast } = useToast();
  const toastRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!toastRef.current) return;
      removeToast(id);
    }, duration);
    return () => clearTimeout(timer);
  });

  const Icon = toast[type].icon as React.FC;
  return (
    <ToastContainer
      color={toast[type].color}
      ref={toastRef}
      duration={duration}
      data-testid={`toast-${type}`}
    >
      <Row data-testid="toast" gap={0.5}>
        <Icon />
        {message}
      </Row>
    </ToastContainer>
  );
};

export const ToastManager = () => {
  const {
    state: { toasts },
  } = useToast();

  if (toasts.length > 0) {
    return (
      <ToastWrapper>
        <Column align="flex-end" justify="flex-end" gap={1}>
          {toasts.map((toastDetails, index) => (
            <Toast {...toastDetails} key={index} />
          ))}
        </Column>
      </ToastWrapper>
    );
  }
  return null;
};
