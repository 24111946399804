import { font, theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { Column } from '../Flex';
import { Text } from '../Typography';

export const SelectButton = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: ${theme.grey[100]};
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 0;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  span {
    margin: 0;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65
  }
`;

export const MultiSelectContainer = styled(Column)`
  position: absolute;
  width: max-content;
  max-height: 200px;
  overflow-y: scroll;
  top: 0%;
  transform: translateY(-70%);
  z-index: 999;
  background-color: ${theme.white[100]};
  border-radius: 0.625rem;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  padding: 1rem 0.625rem;
  label {
    margin: 0;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 0;
  margin: 0 0.75rem;
`;

export const MultiSelectWrapper = styled(Column)<{ required?: boolean }>`
  position: relative;
  margin-bottom: 1rem;
  ${({ required }) => {
    return (
      required &&
      css`
        &::after {
          content: ' *';
          color: ${theme.red[500]};
          position: absolute;
        }
      `
    );
  }}
`;

export const ErrorText = styled(Text)`
  position: absolute;
  z-index: 100;
  top: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 0.25rem;
  font-weight: ${font.weights.bold};
`;
