import { gql } from '@apollo/client';

export const staffFieldsFragment = gql`
  fragment StaffFields on Staff {
    id: _id
    user {
      fullName
      firstName
      lastName
      emails
      profileAvatar
    }
    staffGroup {
      id: _id
      name
      permissions {
        id: _id
        name
      }
    }
    permissions {
      id: _id
      name
    }
    status
    updatedAt
    facility {
      id: _id
      state
    }
  }
`;
