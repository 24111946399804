import { gql } from '@apollo/client';

export const familyFieldsFragment = gql`
  fragment FamilyFields on Family {
    id: _id
    createdAt
    familyId
    name
  }
`;
