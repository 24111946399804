import { gql } from '@apollo/client';

export const appointmentFieldsFragment = gql`
  fragment AppointmentFields on Appointment {
    type
    id: _id
    status
    provider {
      id: _id
      staffGroup {
        name
      }
      user {
        firstName
        lastName
        fullName
        profileAvatar
      }
    }
    patient {
      id: _id
      user {
        firstName
        lastName
        fullName
      }
    }
    visitTime {
      startTime
      endTime
    }
    date
    appointmentDate
    createdBy {
      id: _id
      staffGroup {
        id: _id
        name
      }
      user {
        fullName
        firstName
        lastName
      }
    }
    isRecurringException
    parentAppointment {
      id: _id
    }
    endOfRecurrence
    recurrenceRule {
      byMonth
      byMonthDay
      byWeekDay
      frequency
      interval
    }
  }
`;
