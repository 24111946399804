export const ClockIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.41659 0.166626C5.26286 0.166626 4.13505 0.508745 3.17576 1.14972C2.21647 1.79069 1.4688 2.70174 1.02729 3.76764C0.585779 4.83354 0.47026 6.00643 0.69534 7.13799C0.920421 8.26954 1.47599 9.30894 2.2918 10.1247C3.1076 10.9406 4.14701 11.4961 5.27856 11.7212C6.41012 11.9463 7.58301 11.8308 8.64891 11.3893C9.71481 10.9477 10.6259 10.2001 11.2668 9.24079C11.9078 8.2815 12.2499 7.15368 12.2499 5.99996C12.2499 5.23391 12.099 4.47537 11.8059 3.76764C11.5127 3.05991 11.0831 2.41684 10.5414 1.87517C9.9997 1.33349 9.35664 0.903814 8.64891 0.610662C7.94118 0.31751 7.18263 0.166626 6.41659 0.166626ZM6.41659 10.6666C5.49361 10.6666 4.59136 10.3929 3.82393 9.88015C3.0565 9.36737 2.45836 8.63854 2.10515 7.78581C1.75194 6.93309 1.65953 5.99478 1.83959 5.08954C2.01965 4.18429 2.46411 3.35277 3.11676 2.70013C3.7694 2.04748 4.60092 1.60303 5.50617 1.42296C6.41141 1.2429 7.34972 1.33531 8.20244 1.68852C9.05517 2.04173 9.784 2.63987 10.2968 3.4073C10.8096 4.17473 11.0833 5.07698 11.0833 5.99996C11.0833 7.23764 10.5916 8.42462 9.71642 9.29979C8.84125 10.175 7.65426 10.6666 6.41659 10.6666ZM6.41659 2.49996C6.26188 2.49996 6.11351 2.56142 6.00411 2.67081C5.89471 2.78021 5.83325 2.92858 5.83325 3.08329V5.95913L5.03409 7.34163C4.95673 7.47622 4.93601 7.63604 4.97649 7.78591C5.01697 7.93578 5.11532 8.06344 5.24992 8.14079C5.38452 8.21815 5.54433 8.23886 5.6942 8.19839C5.84408 8.15791 5.97173 8.05956 6.04909 7.92496L6.92409 6.40829C6.98021 6.30285 7.00261 6.18271 6.98825 6.06413L6.99992 5.99996V3.08329C6.99992 2.92858 6.93846 2.78021 6.82907 2.67081C6.71967 2.56142 6.5713 2.49996 6.41659 2.49996Z"
      fill={color || '#F97B5C'}
    />
  </svg>
);
