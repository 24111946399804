import _ from "lodash";

export const removeEmptyValues = (obj: Record<string, unknown>): Record<string, unknown> => {
  return _.transform(obj, (result, value, key) => {
    if (_.isObject(value)) {
      result[key] = removeEmptyValues(value as Record<string, unknown>);
    } else if (!_.isEmpty(value)) {
      result[key] = value;
    }
  });
};

