import * as React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { ToastAction, ToastConfigTypes } from 'src/types';
import { SHOW_TOAST, REMOVE_TOAST } from './reducer';

const showToast =
  (dispatch: React.Dispatch<ToastAction>) =>
  async (
    message: string,
    type: ToastConfigTypes,
    duration = 3000,
    id = uuidV4(),
  ) => {
    dispatch({
      type: SHOW_TOAST,
      payload: { message, type, duration, id },
    });
  };

const removeToast =
  (dispatch: React.Dispatch<ToastAction>) => async (id: string) => {
    dispatch({
      type: REMOVE_TOAST,
      payload: { id },
    });
  };
export default {
  removeToast,
  showToast,
};
