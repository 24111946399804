import * as React from 'react';
import { HMOList, HMOsType } from 'src/types';
import HmosContext from './context';


const HmosProvider: React.FC = ({ children }) => {
  const [hmos, setHmos] = React.useState<HMOsType>({} as HMOsType);
  const [hmoList, setHmoList] = React.useState<HMOList>({} as HMOList)

  return (
    <HmosContext.Provider 
      value={{ 
        hmos, 
        setHmos,
        hmoList,
        setHmoList
      }}
    >
      {children}
    </HmosContext.Provider>
  );
};

export default HmosProvider;
