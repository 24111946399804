export const removeEmptyObjects = (obj: Record<string, unknown>): Record<string, unknown> => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => [key, removeEmptyObjects(value as Record<string, unknown>)])
      .filter(([, value]) => !isEmptyObject(value as Record<string, unknown>))
  );
};

const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return Object.keys(obj).length === 0;
};
