import styled, { css } from 'styled-components';
import { theme } from 'src/utils/variables';
import { DotIndicatorProps } from 'src/types';

export const DotIndicator = styled.div<DotIndicatorProps>`
  ${({ color }) => {
    return css`
      background: ${theme[color][600] || color};
    `;
  }};
  height: 0.4376rem;
  width: 0.4376rem;
  border-radius: 50%;
`;
