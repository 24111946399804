import styled from 'styled-components';
import { font } from 'src/utils';
import { InputBase } from '../Input/styled';
import { IconButton } from '../Button';
import { Column, Row } from '../Flex';
import { Text } from '../Typography';

export const PasswordInputBase = styled(InputBase)`
  padding-right: 2.85rem;
`;

export const PasswordToggleButton = styled(IconButton)`
  height: 100%;
  width: 2.75rem;
  max-width: 2.75rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  right: 0;
`;

export const PasswordContainer = styled(Row)`
  position: relative;
`;

export const ErrorText = styled(Text)`
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 1rem;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 0.25rem;
  font-weight: ${font.weights.bold};
`;

export const InputContainer = styled(Column)`
  margin-bottom: 1rem;
`;