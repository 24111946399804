import { 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer, 
  CartesianGrid,
  LineChart,
  Line
} from "recharts";
import { ImplicitLabelType } from "recharts/types/component/Label";
import { AxisDomain, Margin } from "recharts/types/util/types";
import { font, formatNumber, theme } from "src/utils";
import useMediaQuery from "src/utils/useMediaQuery";


export interface MedianLineChartProps {
  data: Record<string, unknown>[];
  horizontalCartesianLines?: boolean;
  verticalCartesianLines?: boolean;
  color?: string | string[];
  colorKey?: string;
  dataKey: string[];
  width?: string | number;
  axisLine?: boolean;
  height?: string | number;
  xAxisLabel?: string;
  showYAxisLabel?: boolean;
  toolTip?: boolean;
  domain?: AxisDomain;
  margin?: Margin;
  label?: ImplicitLabelType
};


export const MedianLineChart: React.FC<MedianLineChartProps> = ({ 
  data, 
  color, 
  dataKey,
  colorKey = "color",
  axisLine = false,
  width = "100%",
  height = "100%",
  domain = [0, 'dataMax'],
  margin,
  xAxisLabel,
  horizontalCartesianLines = false,
  verticalCartesianLines = false,
  showYAxisLabel = true,
  toolTip
}) => {
  const isMobile = !useMediaQuery('(min-width: 960px)');

  const tickStyle = { 
    stroke: '#9b9b9b', 
    strokeWidth: 0.4,
    fontSize: isMobile ? font.sizes.xs : font.sizes.sm, 
  };


  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart
        data={data}
        width={500}
        height={300}
        margin={margin}
      >
        <CartesianGrid 
          strokeDasharray="0" 
          strokeWidth={0.2}
          color={theme.grey[50]}
          horizontal={horizontalCartesianLines} 
          vertical={verticalCartesianLines} 
        />
        {toolTip && <Tooltip />}
        {xAxisLabel && (
          <XAxis 
            tick={tickStyle}
            dataKey={xAxisLabel} 
            axisLine={axisLine}
            tickLine={false}
            fontSize={isMobile ? font.sizes.xs : font.sizes.sm}
            dy={10}
          />
        )}
        {showYAxisLabel && (
          <YAxis 
            tick={tickStyle}
            tickFormatter={(values) => formatNumber(values)}
            tickCount={11}
            axisLine={axisLine}
            fontSize={isMobile ? font.sizes.xs : font.sizes.sm}
            allowDataOverflow
            tickLine={false}
            dx={-10}
            domain={domain}
          />
        )}
        {dataKey.map((_entry, index) => (
          <Line
            dataKey={dataKey[index]} 
            key={`cell-${index}`} 
            type="monotone"
            stroke={colorKey || Array.isArray(color) ? color?.[index] : color} 
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
