import _ from 'lodash';
import { DocumentIcon } from 'src/assets/icons';
import { Column, Row, Text } from 'src/components';
import { openModal, removeModalHash, theme } from 'src/utils';
import { useToast } from 'src/state';
import { StyledButton } from '../styled';

interface SettlementBlockProps {
  keys: string;
  label?: string;
  attachment?: string;
  link?: string;
  id?: string;
}

const SettlementBlock: React.FC<SettlementBlockProps> = ({
  label,
  link,
  id,
  keys,
  attachment,
}) => {
  const { showToast } = useToast();
  const file = attachment?.split('/')?.slice(-1)?.[0];
  const fileName = file?.split('.', 2)[0];
  const fileExtension = file?.split('.', 2)[1] as string;
  const isDisplayable = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(
    fileExtension,
  );

  return (
    <Column>
      <Text
        modStyles={{ my: 0 }}
        weight="semibold"
        color={theme.grey[500]}
        size="sm"
      >
        {keys}
      </Text>
      {label && (
        <Text
          color={theme.primary[700]}
          modStyles={{ my: 0 }}
          size="sm"
          weight="bold"
        >
          {label}
        </Text>
      )}
      {attachment && (
        <Row align="center" gap={0.56}>
          <DocumentIcon strokeWidth="0" />
          <Text
            color={theme.primary[700]}
            modStyles={{ my: 0 }}
            size="sm"
            weight="bold"
            title={attachment}
          >
            {`${_.truncate(fileName, {
              length: 15,
              omission: `[...]`,
              separator: ' ',
            })}.${fileExtension}`}
          </Text>
          {link && id && isDisplayable && (
            <StyledButton variant="text" onClick={() => openModal(link, id)}>
              View Doc
            </StyledButton>
          )}
          {link && id && !isDisplayable && (
            <a download href={attachment}>
              <StyledButton
                variant="text"
                onClick={() => {
                  showToast('File is being downloaded', 'success');
                  removeModalHash();
                }}
              >
                View Doc
              </StyledButton>
            </a>
          )}
        </Row>
      )}
    </Column>
  );
};

export default SettlementBlock;
