import { gql } from '@apollo/client';

export const GET_FINANCIAL_PERFORMANCE = gql`
  query GetFinancialPerformance($input: DateRangeInput!) {
    getFinancialPerformance(input: $input) {
      date
      purchase
      sales
    }
  }
`;


export const GET_SALES_STATISTICS = gql`
  query GetSalesStatistics($input: DateRangeInput!) {
    getSalesStatistics(input: $input) {
      date
      total
    }
  }
`;

export const GET_TOTAL_PRODUCTS = gql`
  query GetTotalProducts($input: SingleDateInput!) {
    getTotalProducts(input: $input) {
      inc
      total
    }
  }
`;

export const GET_TOTAL_INVENTORY_INVOICE = gql`
  query GetTotalInventoryInvoice($input: SingleDateInput!) {
    getTotalInventoryInvoice(input: $input) {
      inc
      total
    }
  }
`;

export const GET_TOTAL_RETURN_AND_REFUND = gql`
  query GetTotalReturnAndRefund($input: SingleDateInput!) {
    getTotalReturnAndRefund(input: $input) {
      inc
      total
    }
  }
`;


export const GET_PRODUCT_STATISTICS = gql`
  query GetProductStatistics($input: DateRangeInput!) {
    getProductStatistics(input: $input) {
      added
      remaining
      returned
      sold
    }
  }
`;

export const GET_INVOICE_STATISTICS = gql`
  query GetInvoiceStatistics($input: DateRangeInput!) {
    getInvoiceStatistics(input: $input) {
      external
      facility
      internal
      open
    }
  }
`;

export const GET_TOTAL_BUYING_VALUE = gql`
  query GetTotalBuyingValue($input: DateRangeInput!) {
    getTotalBuyingValue(input: $input) {
      inc
      total
    }
  }
`;


export const GET_TOTAL_SELLING_VALUE = gql`
  query GetTotalSellingValue($input: DateRangeInput!) {
    getTotalSellingValue(input: $input) {
      inc
      total
    }
  }
`;


export const GET_MOST_SOLD_PRODUCTS = gql`
  query GetMostSoldProducts($input: DateRangeInput!) {
    getMostSoldProducts(input: $input) {
      name
      quantity
      value
    }
  }
`;