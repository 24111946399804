export * from './ageCalaculator';
export * from './capitalizeFirstLetter';
export * from './formRenderHelper';
export * from './generateAvatar';
export * from './generateGreetings';
export * from './isoDateconverter';
export * from './modalHelper';
export * from './parseJson';
export * from './pinify';
export * from './storageHelper';
export * from './unixDateConverter';
export * from './useClickAway';
export * from './useWindowSize';
export * from './variables';
export * from './validations';
export * from './renameObjectKeys';
export * from './removeEmptyValues';
export * from './currencyFormatter';
export * from './useMediaQuery';
export * from './generateTimeObject';
export * from './parseTime';
export * from './medicalSummaryHelpers';
export * from './removeEmptyObjects';
export * from './print';
export * from './formatAsPercentage';
export * from './isValidUrl';
export * from './getRelativeDate';
export * from './printStyles';
export * from './getErrorMessage';
export * from './checkPermissions';
export * from './formatAgeRange';
export * from './csv';

export const pluralizeWord = (word: string) =>
  word.toLowerCase().slice(-1) !== 's' ? `${word}s` : word;

export const isAllFilled = (item: Record<string, unknown>) =>
  Object.values(item).every((v) => Boolean(v) === true);

export const isAllEmpty = (item: Record<string, unknown>) =>
  Object.values(item).every((v) => Boolean(v) === false);

export const formatNumber = (number: number): string => {
  let result: string;
  switch (true) {
    case number >= 1e9:
      result = `${(number / 1e9).toFixed(1)}B`;
      break;
    case number >= 1e6:
      result = `${(number / 1e6).toFixed(1)}M`;
      break;
    case number >= 1e3:
      result = `${(number / 1e3).toFixed(1)}K`;
      break;
    default:
      result = number.toString();
  }
  return result;
};
