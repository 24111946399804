import styled from 'styled-components';
import { IconButton, Row } from 'src/components';
import { font, theme } from 'src/utils';

export const MultiRowFormHolder = styled(Row)`
  opacity: 0.5;
  width: 100%;
`;

export const MultiRowFormTableHeader = styled.th`
  font-size: ${font.sizes.base};
  color: ${theme.black[200]};
  font-weight: ${font.weights.semibold};
  text-align: left;
  text-transform: capitalize;
  padding-left: 0.5rem;
`;

export const MultiRowDeleteButton = styled(IconButton)`
  margin-bottom: 1rem;
  svg {
    stroke-width: 0;
  }
`;

export const MultiRowTable = styled.table`
  width: 100%;
`;
