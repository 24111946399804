import React from 'react';
import { TooltipProps } from 'recharts';
import { ChartDataEntity } from 'src/types';
import { ToolTipWrapper } from './styled';

export type CustomTooltipProps = TooltipProps<number, string>;

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    const { color, tooltipData } = payload[0].payload as ChartDataEntity;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: `1px solid ${color}`,
        }}
      >
        {/* <p className="label" style={{ color }}>{`${key}: ${value}`}</p> */}
        <ToolTipWrapper
          className="tooltip-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: tooltipData }}
        />
      </div>
    );
  }

  return null;
};
