import styled, { css } from 'styled-components';
import {
  IconButton,
  Button,
  Row,
  Text,
  InputLabel,
  Column,
} from 'src/components';
import { theme } from 'src/utils/variables';
import { TabHeaderItem } from 'src/containers';

export const TabHeaderIndicatorButton = styled(IconButton)<{
  active?: boolean;
}>`
  background: ${(props) =>
    props.active ? theme.secondary[100] : theme.grey[50]};
  min-width: 2.625rem;
  min-height: 2.625rem;
  svg,
  path {
    fill: ${(props) => (props.active ? theme.secondary[700] : theme.grey[200])};
    stroke-width: 0;
    width: 1.125rem;
    height: 1.125rem;
  },
`;
export const TabIndicatorText = styled(Text)`
  margin: 0rem;
  width: 100%;
`;

export const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

export const NextTab = styled(TabHeaderItem)`
  div {
    button {
      background-color: yellow;
    }
  }
`;

export const UploadIconLabel = styled(InputLabel)<{ required?: boolean }>`
  width: max-content;
  display: flex;
  justify-content: center;
  cursor: pointer;
  button {
    width: 2.875rem;
    height: 2.875rem;
  }
  ${({ required }) => {
    return (
      required &&
      css`
        &::after {
          content: ' *';
          color: ${theme.red[500]};
          position: absolute;
          left: 0;
        }
      `
    );
  }}
`;

export const MainInputGroup = styled(Row)`
  @media (max-width: 960px) {
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5;
    margin-bottom: 1rem;
  }
`;
export const InnerInputGroup = styled(Row)`
  @media (max-width: 960px) {
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5;
  }
`;
export const AddStaffButton = styled(Button)`
  width: 10.8125rem;
  @media (max-width: 960px) {
    width: 100%;
  }
`;
export const InputIconButton = styled(IconButton)`
  background: ${theme.grey[100]};
  svg,
  path {
    stroke: ${theme.grey[200]};
  }
  width: 2.5rem;
  height: 2.5rem;
`;

export const FixedTabs = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: ${theme.white[100]};
  & > div {
    width: 100%;
    justify-content: center;
    gap: 3rem;
  }
`;

export const ImagePreview = styled(Column)`
  position: relative;
  padding: 0.5rem;
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.grey[300]};
  background-color: ${theme.white[100]};
  & img {
    width: 100%;
  }
  & svg {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
  }
`;

export const OwnerOnBoardingTabContainer = styled(Row)`
  width: max-content;
  @media (max-width: 960px) {
    width: 100%;
    max-width: unset;
  }
`;
