import {
  OperationVariables,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import _, { capitalize } from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useState, useCallback } from 'react';
import {
  GET_PATIENT_DATA,
  PERMISSION_ACTION_MESSAGE,
  UPDATE_PATIENT,
} from 'src/constants';
import { useAlert, useFeatureFlag, useStaff, useToast } from 'src/state';
import {
  usePatientJourney,
  useTimeline,
  useAppDispatch,
  endPatientJourney,
  useSteps,
} from 'src/store';
import { Color } from 'src/types';
import { openModal } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import { useRoute } from 'wouter';

export const usePatientDetails = (id?: string) => {
  const { patientJourney } = usePatientJourney();
  const [, params] = useRoute('/admin/patients/:id');
  const { hasPermission } = useStaff();
  const { showToast } = useToast();
  const patientId = id || (params?.id as string);
  const client = useApolloClient();
  const {
    data: apolloPatient,
    loading: patientLoading,
    error: loadError,
  } = useQuery(GET_PATIENT_DATA, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: !patientId || !hasPermission('VIEW_PATIENT'),
    variables: {
      patientId,
    },
  });
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const isMobile = !isDesktop;
  const { showAlert, removeAlert } = useAlert();
  const { fetchTimeline } = useTimeline(patientId);
  const { fetchAllSteps } = useSteps();
  const dispatch = useAppDispatch();

  const patient = apolloPatient?.getPatient;
  const status = patient?.status;

  const [apolloUpdatePatient, { error: updateError, loading: updateLoading }] =
    useMutation(UPDATE_PATIENT);

  const updatePatientStatus = (selectedStatus: string) => {
    if (!hasPermission('UPDATE_PATIENT_STATUS'))
      return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
    return apolloUpdatePatient({
      variables: { patientId, input: { status: selectedStatus } },
      refetchQueries: [{ query: GET_PATIENT_DATA, variables: { patientId } }],
    }).then((res) => {
      if (res?.data) {
        removeAlert?.();
        fetchTimeline();
      }
    });
  };

  const updatePatientCache = (data: OperationVariables) => {
    client.writeQuery({
      query: GET_PATIENT_DATA,
      variables: { patientId },
      data: {
        getPatient: {
          ...patient,
          ...data,
        },
      },
    });
  };
  const { feats } = useFeatureFlag();

  const getStatus = useCallback(
    (patientStatus: string) => {
      const statusValue: { value: string; color: Color } = {
        value: '',
        color: 'grey',
      };
      if (patient) {
        if (['CHECKED_IN'].includes(patientStatus)) {
          statusValue.value = 'Checked In';
          statusValue.color = 'green';
        }
        if (['ADMITTED'].includes(patientStatus)) {
          statusValue.value = 'Admitted';
          statusValue.color = 'red';
        }
        if (['INACTIVE', 'CHECKED_OUT'].includes(patientStatus)) {
          statusValue.value = 'Inactive';
          statusValue.color = 'grey';
        }
      }
      return statusValue;
    },
    [patient],
  );

  const details = patient && {
    generalInformation: {
      'First Name': capitalize(patient.user.firstName),
      'Last Name': capitalize(patient.user.lastName),
      'Date of Birth': moment(
        patient.basicInfo.dateOfBirth as string,
        'YYYY-MM-DDTHH:mm:ssZ',
      ).format('Do MMM[,] YYYY'),
      Gender: capitalize(patient.basicInfo.gender),
      'Marital Status': capitalize(patient.basicInfo.maritalStatus),
      Nationality: capitalize(patient.basicInfo.nationality),
      'Languages Spoken': capitalize(patient?.otherInfo?.languageSpoken),
    },
    contactInformation: {
      Email: patient.user.emails[0],
      'Phone Number': patient.basicInfo.phoneNumber,
      'Home Address': patient.otherInfo.homeAddress as string,
    },
    hmoInformation: {
      'Patient HMO ID': patient?.otherInfo?.hmo?.hmoProviderId as string,
      Provider: capitalize(patient?.otherInfo?.hmo?.hmo?.name as string),
      'Date Added': moment(
        patient?.otherInfo?.hmo?.hmo?.createdAt as string,
        'YYYY-MM-DDTHH:mm:ssZ',
      ).format('Do MMM[,] YYYY'),
    },
    familyInformation: {
      'Family Name': _.startCase(`${patient?.family?.name}`),
      'Family ID': patient?.family?.familyId,
      'Date Added': moment(
        patient?.family?.createdAt as string,
        'YYYY-MM-DDTHH:mm:ssZ',
      ).format('Do MMM[,] YYYY'),
    },
    nextOfKinInformation: {
      'Full Name': _.startCase(
        `${patient.nextOfKin[0].firstName} ${patient.nextOfKin[0].lastName}`,
      ),
      'Relationship to patient': capitalize(
        patient.nextOfKin[0].relationshipToPatient,
      ),
      'Email Address': patient.nextOfKin[0].emailAddress,
      'Phone Number': patient.nextOfKin[0].phoneNumber,
    },
  };

  const statusList = [
    {
      label: 'Check In',
      value: 'CHECKED_IN',
      checked: status === 'CHECKED_IN' || status === 'ADMITTED',
    },
    {
      label: 'Check Out',
      value: 'CHECKED_OUT',
      checked: status === 'INACTIVE' || status === 'CHECKED_OUT',
    },
    {
      label: 'Admit',
      value: 'ADMITTED',
      checked: status === 'ADMITTED',
    },
  ].filter((stat) => !stat.checked);

  const handleFilterChange = (_name: string, selectedStatus: string) => {
    mixpanel.track(`Clicked '${_name}' option`, { feature: 'Manage Patient' });
    if (selectedStatus === status) return null;
    if (selectedStatus === 'CHECKED_IN' && status !== 'ADMITTED') {
      return showAlert({
        title: 'Confirm Patient Check-In',
        message:
          'Checking this patient into this facility will check the patient out of other facilities, are you sure you want to proceed?',
        confirm: () => {
          if (
            feats &&
            feats['Patient Journey'] &&
            feats['Patient Journey'].enabled
          ) {
            removeAlert();
            openModal('journey', '', `&mView=newStep&template=CHECK-IN`);
            return;
          }

          return updatePatientStatus(selectedStatus);
        },
      });
    }

    if (selectedStatus === 'ADMITTED') {
      return showAlert({
        title: 'Confirm Patient Admission?',
        message:
          'Admitting this patient into this facility will check the patient out of other facilities',
        confirm: () => {
          if (
            feats &&
            feats['Patient Journey'] &&
            feats['Patient Journey'].enabled
          ) {
            removeAlert();
            openModal('journey', '', `&mView=newStep&template=ADMIT`);
            return;
          }

          return updatePatientStatus(selectedStatus);
        },
      });
    }

    if (selectedStatus === 'CHECKED_OUT' || selectedStatus === 'INACTIVE') {
      return showAlert({
        title: 'Confirm Checkout',
        message: 'Are you sure you want to proceed with this action?',
        confirm: async () => {
          if (
            feats &&
            feats['Patient Journey'] &&
            feats['Patient Journey'].enabled
          ) {
            const checkoutStep = patientJourney?.steps.find(
              (step) => step.stepTemplate.name === 'CHECKOUT',
            );
            if (checkoutStep) {
              if (checkoutStep?.actions?.length === 0) {
                try {
                  await updatePatientStatus(selectedStatus);
                  await fetchAllSteps();
                  dispatch(endPatientJourney({ patientId }));
                  removeAlert();
                } catch {
                  return undefined;
                }
              }
              removeAlert();
              return openModal(
                'journey',
                '',
                `&mView=step&stepId=${checkoutStep.id}`,
              );
            }
            if (!checkoutStep) {
              removeAlert();
              return openModal(
                'journey',
                '',
                `&mView=newStep&template=CHECKOUT`,
              );
            }
          }
          return updatePatientStatus(selectedStatus);
        },
      });

      // check journey if a checkout step exists
      // if no checkout step exists create and open it up
      // return changeStatus(selectedStatus).then(() =>
      //   dispatch(endPatientJourney({ patientId })),
      // );
    }
  };

  return {
    details,
    statusList,
    getStatus,
    patient,
    status,
    isMobile,
    updateLoading,
    updateError,
    handleFilterChange,
    patientLoading,
    loadError,
    showTabs,
    updatePatientStatus,
    setShowTabs,
    updatePatientCache,
  };
};
