import { useState } from 'react';
import { CancelIcon, UploadIcon } from 'src/assets/icons';
import { Column, Pill, Row } from 'src/components';
import { ErrorText, InputBase } from 'src/components/Input/styled';
import { useToast } from 'src/state';
import { theme } from 'src/utils';
import {
  ImagePreview,
  InputLabelText,
  LabelText,
  UploadIconLabel,
} from './styled';

type FileType = 'Image' | 'doc' | string;

type ImageUploadType = {
  fileType: FileType;
  dataTestId?: string;
  label?: string;
  error?: string;
  id: string;
  required?: boolean;
  labelText?: string;
  onUploadComplete: (
    field: string,
    value: Array<string> | string,
    shouldValidate?: boolean,
  ) => void;
};

const ImageUpload: React.FC<ImageUploadType> = ({
  onUploadComplete,
  fileType,
  label,
  error,
  dataTestId,
  required,
  labelText,
  id,
}) => {
  const { showToast } = useToast();
  const [image, setImage] = useState<File | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>('');
  const cldImgURL = `${process.env.REACT_APP_CLOUDINARY_API_URL}` as string;
  const cldRawURL = cldImgURL.replace('image', 'raw');

  const getFileType = (file: FileType) => {
    switch (file) {
      case 'Image':
        return '.jpg, .jpeg, .png, .svg';
      case 'doc':
        return 'audio/*,video/*,.doc,.pdf,.gif';
      default:
        return file;
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLoading(true);
    const file = event.target.files?.[0];
    const fileExtension = file?.type;
    setImage(file);
    const data = new FormData();
    data.append('file', file as File);
    data.append('upload_preset', 'ankora');
    data.append('cloud_name', 'ankora-health');

    const response = await fetch(
      fileExtension?.startsWith('image') ? cldImgURL : cldRawURL,
      { method: 'post', body: data },
    );
    if (response.status !== 200) {
      setLoading(false);
      showToast('Error uploading file', 'error');
    }
    const result = await response.json();
    setLoading(false);
    setImgUrl(result?.url as string);
    onUploadComplete(id, result?.url, false);
  };

  return (
    <Column>
      {fileType === 'Image' && imgUrl ? (
        <></>
      ) : (
        <Row gap={1} align="center">
          <UploadIconLabel required={required} htmlFor="file-upload">
            <InputBase
              onChange={handleFileUpload}
              id="file-upload"
              data-testid={dataTestId}
              accept={getFileType(fileType)}
              type="file"
              hidden
            />
            <UploadIcon />
          </UploadIconLabel>
          <Column width="max-content" gap={0.2}>
            {label && (
              <LabelText color="black" weight="bold">
                {label}
              </LabelText>
            )}
            {labelText && (
              <InputLabelText htmlFor="file-upload" color={theme.grey[500]}>
                {labelText}
              </InputLabelText>
            )}
          </Column>
        </Row>
      )}
      {fileType === 'Image' && imgUrl && (
        <ImagePreview>
          <img src={imgUrl} alt="logo" />
        </ImagePreview>
      )}
      {loading && (
        <LabelText modStyles={{ py: 0 }} color={theme.grey[500]}>
          uploading...
        </LabelText>
      )}
      {!loading && image && !!imgUrl && (
        <Pill color="grey">
          <LabelText color={theme.grey[500]}>
            {image.name}
            <CancelIcon role="button" onClick={() => setImgUrl('')} />
          </LabelText>
        </Pill>
      )}
      {Boolean(error) && !loading && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </Column>
  );
};

export default ImageUpload;
