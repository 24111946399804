import { Button, Column, Row } from 'src/components';
import { theme } from 'src/utils';
import styled from 'styled-components';

export const TopCardRow = styled(Row)`
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Count = styled.p`
  position: absolute;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffd8d8;
  color: #9d0002;
  top: -8px;
  left: -8px;
`;

export const EllipsisButton = styled(Button)`
  height: 70px;
  width: 50px;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);
  & > svg {
    stroke-width: 0;
    stroke: ${theme.grey[650]};
  }
`;

export const DonutChartWrapper = styled(Column)`
  & > div {
    & > div {
      width: 100%;
      display: flex;
      justify-self: center;

      & > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        & > li {
          white-space: nowrap;
        }
      }
    }
  }
`;

export const PageHead = styled(Row)`
  @media print {
    display: none;
  }
`;

export const PrintWrapper = styled(Column)`
  @media print {
    width: max-content;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 1.3;

    @page {
      margin: 0.5cm;
    }
  }
`;

export const TruncatedText = styled.p`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
