import { InputProps } from 'src/types';
import React from 'react';
import { Column } from '../Flex';
import { InputLabel } from '../InputLabel';
import { SearchContainer, SearchInputBase, SearchLogo } from './styled';

const CustomInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = ({ label, ...props }, ref) => {
  return (
    <Column>
      {label && <InputLabel>{label}</InputLabel>}
      <SearchContainer align="center">
        <SearchLogo />
        <SearchInputBase ref={ref} {...props} />
      </SearchContainer>
    </Column>
  );
};

export const SearchInput = React.forwardRef(CustomInput);
