import { Card, Column, injectModStyles } from 'src/components';
import { font } from 'src/utils';
import styled, { css } from 'styled-components';

export const AlertCard = styled(Card)`
  display: flex;
  flex-flow: column;
  width: ${({ width }) => width || '35vw'};
  height: max-content;
  overflow-y: hidden;
  padding: 0;
  position: relative;
  @media (max-width: 960px) {
    min-width: 85%;
    span {
      font-size: ${font.sizes.sm};
    }
    h6 {
      font-size: ${font.sizes.base};
    }
  }
  ${({ modStyles }) => css`
    ${modStyles && injectModStyles(modStyles)}
  `}
`;

export const AlertChild = styled(Column)`
  overflow-x: hidden;
  height: 100%;
  padding: 0rem 1.875rem 1.875rem 1.875rem;
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  background-color: #00000066;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
`;
