import { Column } from "src/components";
import { getModalParams } from "src/utils";

const SettlementDocument = () => {
  const imgUrl = getModalParams("url");
  const file = imgUrl?.split("/")?.slice(-1)?.[0];
  const fileName = file?.split(".", 2)[0];
  const fileExtension = file?.split(".", 2)[1];
  const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension);

  return (
    <Column 
      width="100%" 
      align="center" 
      height="100%" 
      background="lightgray"
      position="relative"
    >
      {isImage ? (
        <img
          src={imgUrl}
          width="100%"
          height="100%"
          alt={fileName}
          className="modal-image"
        />
      ) : (
        <iframe
          width="100%"
          height="100%"
          src={imgUrl}
          title={fileName}
        />
      )}
    </Column>
  );
};

export default SettlementDocument;
