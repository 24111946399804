import { Column, Row, Input, Select, Paragraph } from 'src/components';
import { theme, addPatientValidation } from 'src/utils';
import { Patient } from 'src/types';
import { useFormikContext } from 'formik';
import { NEXT_OF_KINS } from 'src/constants';
import { ModalSubTitle } from '../styled';

type AddPatientValidation = typeof addPatientValidation;

const NextOfKin = () => {
  const {
    values,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    errors
  } = useFormikContext<Patient>();

  const { nextOfKin } = values;
  const { nextOfKin: touch } = touched;
  const err = errors as AddPatientValidation;

  return (
    <Column justify="space-between" gap={1} align="center">
      <Column>
        <ModalSubTitle data-testid="section-title">Next of kin / Guardian Information</ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          In the case of emergency, we can contact this person
        </Paragraph>
      </Column>
      <Row gap={1}>
        <Column gap={1} justify="space-between">
          <Input
            label="First Name"
            placeholder="Next of kin's First Name"
            name="nextOfKin.firstName"
            type="text"
            required
            onChange={(event) => setFieldValue("nextOfKin.firstName", event.target.value.trim())}
            onBlur={handleBlur}
            value={nextOfKin.firstName.trim()}
            error={touch?.firstName ? err['nextOfKin.firstName'] : ""}
          />
          <Input
            label="Phone Number"
            placeholder="E.g. 0801 234 5678"
            name="nextOfKin.phoneNumber"
            type="text"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={nextOfKin.phoneNumber.replace(/\D/g, "")}
            error={touch?.phoneNumber ? err['nextOfKin.phoneNumber'] : ""}
          />
          <Input
            label="Home Address"
            placeholder="12, Acme Street"
            name="nextOfKin.homeAddress"
            type="text"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={nextOfKin.homeAddress}
            error={touch?.homeAddress ? err['nextOfKin.homeAddress'] : ""}
          />
        </Column>
        <Column gap={1} justify="space-between">
          <Input
            label="Last Name"
            placeholder="Next of Kin's Last Name"
            name="nextOfKin.lastName"
            type="text"
            required
            onChange={(event) => setFieldValue("nextOfKin.lastName", event.target.value.trim())}
            onBlur={handleBlur}
            value={nextOfKin.lastName.trim()}
            error={touch?.lastName ? err['nextOfKin.lastName'] : ""}
          />
          <Input
            label="Email Address"
            placeholder="marvin@acme.com"
            name="nextOfKin.emailAddress"
            type="text"
            onChange={(event) => setFieldValue("nextOfKin.emailAddress", event.target.value.trim())}
            onBlur={handleBlur}
            value={nextOfKin.emailAddress}
            error={touch?.emailAddress ? err['nextOfKin.emailAddress'] : ""}
          />
          <Select
            onChange={handleChange}
            onBlur={handleBlur}
            label="Relationship to Patient"
            name="nextOfKin.relationshipToPatient"
            value={nextOfKin.relationshipToPatient}
            required
            error={touch?.relationshipToPatient ? err['nextOfKin.relationshipToPatient'] : ""}
          >
            <option disabled value="">Choose Option</option>
            {NEXT_OF_KINS.map(val => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </Select>
        </Column>
      </Row>
    </Column>
  );
};

export default NextOfKin;
