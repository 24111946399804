import { theme, font } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { DropdownWrapperProps } from 'src/types';
import { Column } from '../Flex';
import { Button } from '../Button';
import { injectModStyles } from '..';

export const DropdownTrigger = styled(Button)<{
  icon?: boolean;
}>`
  font-weight: ${font.weights.normal};
  font-size: ${font.sizes.base};
  color: ${theme.primary[400]};
  font-weight: ${font.weights.normal};
  justify-content: space-between;
  border-radius: 0.625rem;
  padding: 0.75rem 1.5rem;
  align-self: stretch;
  text-transform: capitalize;
  & > span {
    white-space: nowrap;
  }
  svg {
    transform: ${(props) =>
      props['aria-expanded'] ? 'rotate(180deg)' : 'initial'};
    transition: ${(props) =>
      props.icon ? 'initial' : 'transform 0.2s ease-in-out'};
  }
  path {
    fill: ${theme.primary[400]};
  }
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
    width: 100%;
  }
`;

export const DropdownContainer = styled(Column)`
  position: relative;
  color: #9b9b9b;
  ${(props) => props.modStyles && injectModStyles(props.modStyles)};
`;

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  color: ${theme.primary[400]};
  list-style-type: none;
  top: 100%;
  right: 0%;
  min-width: 100%;
  z-index: 1000;
  background-color: ${theme.white[100]};
  border-radius: 0.375rem;
  padding: 0.5rem 0;
  margin-top: 0.375rem;
  filter: drop-shadow(0px 0px 5px ${theme.grey[200]});
  input[type='radio']:checked + label {
    background-color: #e7f2ff;
    color: #3592ff;
  }
`;

export const DropdownList = styled.ul<{
  position?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    isFixed?: boolean;
  };
}>`
  width: max-content;
  list-style: none;
  transition: opacity 0.2s ease-in-out;
  position: ${({ position }) => (position?.isFixed ? 'fixed' : 'absolute')};
  color: ${theme.primary[400]};
  list-style-type: none;
  min-width: ${({ position }) => (position?.isFixed ? 'max-content' : '100%')};
  z-index: 9999;
  background-color: ${theme.white[100]};
  border-radius: 0.375rem;
  max-height: 300px;
  overflow-y: auto;
  padding: 0.5rem 0;
  margin-top: 0.375rem;
  box-shadow: 3px 8px 30px 5px rgba(0, 0, 0, 0.08);
  ${({ position }) => {
    return (
      position &&
      css`
        top: ${position.top || '100%'};
        right: ${position.right || '0%'};
        bottom: ${position.bottom || 'unset'};
        left: ${position.left || 'unset'};
      `
    );
  }}
`;

export const DropdownListItem = styled.li<{ active: boolean }>`
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  text-align: left;
  padding: 0.5rem 1rem;
  color: ${theme.grey[900]};
  &:hover {
    background-color: ${theme.grey[50]};
  }
  ${({ active }) => {
    return (
      active &&
      css`
        background-color: ${theme.grey[50]};
      `
    );
  }}
`;

export const DropdownItem = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

export const DropdownLabelItem = styled.label`
  margin: 0;
  text-align: left;
  padding: 0.5rem 1.5rem;
  text-transform: capitalize;
  cursor: default;
  width: 100%;
  &:hover {
    background-color: #e7f2ff;
    color: #3592ff;
  }
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
`;
