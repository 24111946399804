import { gql } from "@apollo/client";

export const settlementFieldsFragment = gql`
  fragment SettlementFields on Settlement {
    id: _id
    updatedAt
    settlementId
    proof
    paidOn
    deletedAt
    debt
    createdBy {
      user {
        firstName
        lastName
      }
    }
    createdAt
    amount
  }
`;
