import { gql } from '@apollo/client';
import { staffFieldsFragment } from '../Fragments/staff';
import { invitedStaffFieldsFragment } from '../Fragments/invitedStaff';
import { userFieldsFragment } from '../Fragments/user';
import { facilityFieldsFragment } from '../Fragments/facility';

export const GET_STAFFS = gql`
  query GetStaffs($query: StaffSearchQuery) {
    getStaffs(query: $query) {
      staff {
        ...StaffFields
        ...InvitedStaffFields
      }
      total
    }
  }
  ${staffFieldsFragment}
  ${invitedStaffFieldsFragment}
`;

export const SEARCH_STAFF = gql`
  query SearchStaff($input: SearchStaffInput!) {
    searchStaff(input: $input) {
      staff {
        ...StaffFields
        ...InvitedStaffFields
      }
      total
    }
  }
  ${staffFieldsFragment}
  ${invitedStaffFieldsFragment}
`;

export const GET_STAFF = gql`
  query GetStaff($staffId: String) {
    getStaff(staffId: $staffId) {
      id: _id
      user {
        ...UserFields
      }
      facility {
        ...FacilityFields
      }
      staffGroup {
        name
        id: _id
        permissions {
          id: _id
          name
        }
      }
      permissions {
        id: _id
        name
      }
      status
    }
  }
  ${userFieldsFragment}
  ${facilityFieldsFragment}
`;
