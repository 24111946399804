import { gql } from "@apollo/client";
import { stepTemplateFieldsFragment } from "../Fragments/stepTemplate";

export const CREATE_STEP_TEMPLATE = gql`
  mutation CreateStepTemplate($input: CreateStepTemplateInput!) {
    createStepTemplate(input: $input) {
      success
      message
      stepTemplate {
        ...StepTemplateFields
      }
    }
  }
  ${stepTemplateFieldsFragment}
`;

export const UPDATE_STEP_TEMPLATE = gql`
  mutation UpdateStepTemplate($updateStepTemplateId: ID!, $input: UpdateStepTemplateInput!) {
    updateStepTemplate(id: $updateStepTemplateId, input: $input) {
      success
      message
      stepTemplate {
        ...StepTemplateFields
      }
    }
  }
  ${stepTemplateFieldsFragment}
`;


export const DELETE_STEP_TEMPLATE = gql`
  mutation DeleteStepTemplate($deleteStepTemplateId: ID!) {
    deleteStepTemplate(id: $deleteStepTemplateId) {
      success
      message
      stepTemplate {
        ...StepTemplateFields
      }
    }
  }
  ${stepTemplateFieldsFragment}
`;