import { useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import Chart from 'react-google-charts';
import Skeleton from 'react-loading-skeleton';
import { Column, Row, Paragraph, SkeletonWrapper } from 'src/components';
import { GET_PATIENT_VITALS_CHART_DATA } from 'src/constants';
import { ValueTypes } from 'src/types';
import { theme } from 'src/utils';
import { useRoute } from 'wouter';
import { NoChart } from './NoChart';
import {
  EntriesButton,
  ChartContainer,
  UpperCriticalRegion,
  LowerCriticalRegion,
  EmptyContainer,
} from './styled';

interface ChartConfig {
  boundKey:
    | 'temperature'
    | 'systolic'
    | 'diastolic'
    | 'pulse'
    | 'respiratory_rate_below_twelve_years'
    | 'respiratory_rate_above_twelve_years';
  vitalId: string;
  vitalName: string;
}

interface Vital extends ValueTypes {
  createdAt: string;
}

export const getChartOptions = (bounds: number[]) => {
  return {
    curveType: 'function',
    colors: ['#3592FF'],
    crosshair: { trigger: 'both' },
    lineWidth: 0,
    chartArea: {
      top: 10,
      right: 35,
      left: 0,
      bottom: 10, // 10
    },
    series: {
      0: {
        targetAxisIndex: 1,
      },
    },
    vAxis: {
      textStyle: {
        color: '#3592FF',
        bold: true,
      },
      minValue: bounds[0],
      maxValue: bounds[1],
      orientation: 'right',
      baselineColor: 'white',
      baseline: bounds[0] - 1,
      gridlines: { color: theme.secondary[700] },
      ticks: [bounds[0], bounds[1]],
      viewWindowMode: 'maximized',
    },
    legend: 'none',
  };
};

const bounds = {
  temperature: [97.6, 99.6],
  systolic: [90, 119],
  diastolic: [60, 79],
  pulse: [60, 100],
  respiratory_rate_below_twelve_years: [20, 30],
  respiratory_rate_above_twelve_years: [12, 18],
};

const VitalChart: React.FC<{ chartConfig: ChartConfig }> = ({
  chartConfig,
}) => {
  const { vitalId, vitalName, boundKey } = chartConfig;
  const [entries, setEntries] = useState(10);
  const [, params] = useRoute('/admin/patients/:id');
  const { data, loading, error, refetch } = useQuery(
    GET_PATIENT_VITALS_CHART_DATA,
    {
      variables: {
        input: {
          patient: params?.id,
          vital: vitalId,
          entries,
        },
      },
      skip: !params?.id,
      errorPolicy: 'all',
    },
  );
  const vitals =
    data &&
    data.getVitalsChart.reduce(
      (acc: Array<[Date, number | string]>, vital: Vital) => {
        if (vital.textValue) {
          acc.push([new Date(vital.createdAt), Number(vital.textValue)]);
        } else if (vital.rowValue) {
          acc.push([
            new Date(vital.createdAt),
            Number(vital.rowValue[1].textValue),
          ]);
        }
        return acc;
      },
      [['Entries', vitalName]],
    );

  const onEntryChange = (entry: number) => {
    setEntries(entry);
    refetch({
      input: {
        entries: entry,
        patient: params?.id,
        vital: vitalId,
      },
    });
  };

  const getConfig = useCallback(
    () => getChartOptions(bounds[boundKey]),
    [boundKey],
  );

  return (
    <Column gap={1}>
      <Row justify="space-between" align="center" wrap="wrap">
        <Paragraph size="md" weight="bold" color={theme.grey[700]}>
          {vitalName}
        </Paragraph>
        <Row width="max-content" gap={0.625}>
          <EntriesButton
            onClick={() => onEntryChange(100000)}
            isActive={entries === 100000}
          >
            All Time Entries
          </EntriesButton>
          <EntriesButton
            onClick={() => onEntryChange(100)}
            isActive={entries === 100}
          >
            Last 100 Entries
          </EntriesButton>
          <EntriesButton
            onClick={() => onEntryChange(10)}
            isActive={entries === 10}
          >
            Last 10 Entries
          </EntriesButton>
        </Row>
      </Row>
      {!loading && error && <p>Unable to load Chart :(</p>}
      {loading && (
        <SkeletonWrapper>
          <Skeleton borderRadius="0.625rem" width="100%" height={300} />
        </SkeletonWrapper>
      )}
      {!loading && vitals.length <= 1 && (
        <EmptyContainer width="100%" justify="center">
          <NoChart />
        </EmptyContainer>
      )}
      {!loading && vitals.length > 1 && !error && (
        <ChartContainer width="100%">
          <UpperCriticalRegion>Critical Region</UpperCriticalRegion>
          <Chart
            chartType="ScatterChart"
            width="100%"
            height="300px"
            data={vitals}
            options={getConfig()}
          />
          <LowerCriticalRegion>Critical Region</LowerCriticalRegion>
        </ChartContainer>
      )}
      {/* {!loading && (
        <ChartContainer width="100%">
          <UpperCriticalRegion>Critical Region</UpperCriticalRegion>
          <Chart
            chartType="ScatterChart"
            width="100%"
            height="300px"
            data={mocks[boundKey]}
            options={getConfig()}
          />
          <LowerCriticalRegion>Critical Region</LowerCriticalRegion>
        </ChartContainer>
      )} */}
    </Column>
  );
};

export default VitalChart;
