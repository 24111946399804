import { Theme } from 'src/types';

export const theme: Theme = {
  primary: {
    700: '#171717',
    600: '#3D3D3D',
    500: '#626262',
    400: '#888888',
    300: '#AEAEAE',
    200: '#D3D3D3',
    100: '#F9F9F9',
  },
  secondary: {
    700: '#F97B5C',
    600: '#FA8E74',
    500: '#FBA18B',
    400: '#FCB5A3',
    300: '#FDC8BB',
    200: '#FEDBD2',
    100: '#FFEEEA',
  },
  accent: {
    700: '#FFB341',
    600: '#FFBF5F',
    500: '#FFCA7D',
    400: '#FFD69B',
    300: '#FFE2B8',
    200: '#FFEDD6',
    100: '#FFF9F4',
  },
  blue: {
    700: '#2365A1',
    600: '#3FB2E3',
    500: '#3FB2E3',
    400: '#88D1EE',
    300: '#ACE0F3',
    200: '#D1F0F9',
    100: '#F5FFFE',
  },
  red: {
    700: '#D94A53',
    600: '#F64811',
    500: '#F86B3F',
    400: '#FA8E6C',
    300: '#FBB29A',
    200: '#FDD5C7',
    100: '#FFF8F5',
  },
  green: {
    700: '#01A539',
    600: '#1FBC55',
    500: '#42C76F',
    400: '#65D189',
    300: '#89DCA4',
    200: '#ACE6BE',
    100: '#CFF1D8',
  },
  yellow: {
    700: '#D78E00',
    600: '#FFB341',
    500: '#EDB460',
    400: '#F1C684',
    300: '#F6D7A8',
    200: '#FAE9CC',
    100: '#FFFAF0',
  },
  grey: {
    900: '#303030',
    800: '#484848',
    700: '#686868',
    650: '#848484',
    600: '#626262',
    500: '#888888',
    400: '#AEAEAE',
    300: '#D3D3D3',
    200: '#BCBCBC',
    100: '#F9F9F9',
    50: '#F6F6F6',
  },
  white: {
    200: '#FFFBF2',
    100: '#FFFFFF',
  },
  black: {
    200: '#68614E',
    100: '#171717',
  },
};

export const font = {
  sizes: {
    base: '1rem',
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '2.25rem',
    xxl: '2.875rem',
    xxxl: '3.625rem',
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

export const testJson = {
  description: 'Form to record Patient Check-in',
  id: '636df6d5bd346889b14a54f0',
  title: 'Patient Check-in',
  fields: [
    {
      id: 'next_of_kin',
      title: 'Next of kin',
      type: 'Row',
      fields: [
        {
          id: 'fullname',
          placeholder: 'Full Name',
          title: 'Full Name',
          type: 'Text',
          validation: 'required',
        },
        {
          id: 'phone_number',
          placeholder: 'Phone Number',
          title: 'Phone Number',
          type: 'Number',
          validation: 'required',
        },
        {
          id: 'address',
          placeholder: 'Address',
          title: 'Address',
          type: 'Text',
          validation: 'required',
        },
      ],
    },
    {
      id: 'id_type',
      title: 'Identification Type',
      type: 'MultiSelect',
      options: [
        {
          label: 'PP',
          value: 'PP',
        },
        {
          label: 'DP',
          value: 'DP',
        },
        {
          label: 'ID',
          value: 'ID',
        },
        {
          label: 'BP',
          value: 'BP',
        },
      ],
      placeholder: 'Select',
      validation: 'required',
    },
    {
      id: 'nationality',
      title: 'Nationality',
      type: 'Text',
      placeholder: 'Nigerian',
      validation: 'required',
    },
    {
      id: 'fullname',
      title: 'Full Name',
      type: 'Text',
      placeholder: 'John Doe',
      validation: 'required',
    },
    {
      id: 'dob',
      title: 'DOB',
      type: 'Date',
      placeholder: 'Date',
      validation: 'required',
    },
    {
      id: 'gender',
      title: 'Gender',
      type: 'Select',
      options: [
        {
          label: 'male',
          value: 'Male',
        },
        {
          label: 'Female',
          value: 'female',
        },
        {
          label: 'non-binary',
          value: 'Non-binary',
        },
      ],
      validation: 'required',
    },
  ],
};

export const medicalImaging = {
  title: 'Medical Imaging',
  description: 'Film/Report request',
  fields: [
    {
      id: 'scan_report',
      title: 'Upload Scan',
      type: 'image',
      validate: 'required',
    },
    {
      id: 'date_needed',
      title: 'Date Needed',
      type: 'date',
      validate: 'required',
      placeholder: 'Date',
    },
    {
      id: 'time_needed',
      title: 'Time Needed',
      type: 'time',
      validate: 'required',
      placeholder: 'Time',
    },
    {
      id: 'request_taken_by',
      title: 'Request Taken By?',
      type: 'compound', // "row" or "compound"
      fields: [
        {
          id: 'fullname',
          title: 'Full Name',
          type: 'text',
          validate: 'required',
          placeholder: 'John Doe',
        },
        {
          id: 'date_needed',
          title: 'Date Needed',
          type: 'date',
          validate: 'required',
          placeholder: 'Date',
        },
      ],
    },
    {
      id: 'request_completed_by',
      title: 'Request Completed By?',
      type: 'compound', // "row" or "compound"
      fields: [
        {
          id: 'fullname',
          title: 'Full Name',
          type: 'text',
          validate: 'required',
          placeholder: 'John Doe',
        },
        {
          id: 'date_needed',
          title: 'Date Needed',
          type: 'date',
          validate: 'required',
          placeholder: 'Date',
        },
      ],
    },
    {
      id: 'relationship_to_patient',
      title: 'Relationship to patient',
      type: 'select',
      validate: 'required',
      options: [
        {
          label: 'Brother',
          value: 'Brother',
        },
        {
          label: 'Father',
          value: 'Father',
        },
        {
          label: 'Mother',
          value: 'Mother',
        },
      ],
    },
    {
      id: 'authority',
      title: 'Describe authority to act on behalf of patient',
      type: 'textarea',
      validate: 'required',
      placeholder: 'Write your description here...',
    },
  ],
};

export const birthForm = {
  title: 'Birth Form',
  description: 'Hospital Birth Form',
  fields: [
    {
      id: 'new_born_name',
      title: 'Newborn Name',
      type: 'text',
      validate: 'required',
      placeholder: 'John Doe',
    },
    {
      id: 'date_time',
      title: 'Date and Time Info',
      type: 'compound', // "row" or "compound"
      fields: [
        {
          id: 'dob',
          title: 'DOB',
          type: 'date',
          validate: 'required',
          placeholder: 'Date',
        },
        {
          id: 'time_of_birth',
          title: 'Time Of Birth',
          type: 'time',
          validate: 'required',
          placeholder: '3:00pm',
        },
      ],
    },
    {
      id: 'dad_name',
      title: 'Dad Name',
      type: 'text',
      validate: 'required',
      placeholder: 'John Doe',
    },
    {
      id: 'dad_nationality',
      title: 'Dad Nationality',
      type: 'text',
      validate: 'required',
      placeholder: 'Nigerian',
    },
    {
      id: 'mum_name',
      title: 'Mum Name',
      type: 'text',
      validate: 'required',
      placeholder: 'Jenny Doe',
    },
    {
      id: 'mum_nationality',
      title: 'Mum Nationality',
      type: 'text',
      validate: 'required',
      placeholder: 'Nigerian',
    },
    {
      id: 'withnesses',
      title: 'Withnesses',
      type: 'table',
      columns: [
        {
          id: 'fullname',
          title: 'Full Name',
          type: 'text',
          validate: 'required',
          placeholder: 'Full Name',
        },
        {
          id: 'age',
          title: 'Age',
          type: 'text',
          validate: 'required',
          placeholder: 'Age',
        },
        {
          id: 'nationality',
          title: 'Nationality',
          type: 'text',
          validate: 'required',
          placeholder: 'Nigerian',
        },
      ],
    },
    {
      id: 'signature',
      title: 'Upload Signature With Date',
      type: 'image',
      validate: 'required',
    },
  ],
};

export const testgJson = {
  title: 'Covid-19 Vaccination',
  description: 'Covid-19 Vaccination Pre-registration Form - 1st dose',
  fields: [
    {
      id: 'vaccination_site',
      title: 'Vaccination Site',
      type: 'text',
      validate: 'required',
      placeholder: 'site name',
    },
    {
      id: 'basic_info',
      title: 'Basic Information',
      type: 'compound', // "row" or "compound"
      fields: [
        {
          id: 'fullname',
          title: 'Full Name',
          type: 'text',
          validate: 'required',
          placeholder: 'Full Name',
        },
        {
          id: 'dob',
          title: 'DOB',
          type: 'date',
          validate: 'required',
          placeholder: 'Date',
        },
        {
          id: 'gender',
          title: 'Gender',
          type: 'select',
          validate: 'required',
          options: [
            {
              label: 'Male',
              value: 'Male',
            },
            {
              label: 'Female',
              value: 'Female',
            },
            {
              label: 'Non-binary',
              value: 'Non-binary',
            },
          ],
        },
      ],
    },
    {
      id: 'id_type',
      title: 'Identification Type',
      type: 'multiselect',
      validate: 'required',
      placeholder: 'Select',
      options: [
        {
          label: 'PP',
          value: 'PP',
        },
        {
          label: 'DP',
          value: 'DP',
        },
        {
          label: 'ID',
          value: 'ID',
        },
        {
          label: 'BP',
          value: 'BP',
        },
      ],
    },
    {
      id: 'nationality',
      title: 'Nationality',
      type: 'text',
      validate: 'required',
      placeholder: 'Nigerian',
    },
    {
      id: 'next_of_kin',
      title: 'Next of Kin Information',
      type: 'compound', // "row" or "compound"
      fields: [
        {
          id: 'fullname',
          title: 'Full Name',
          type: 'text',
          validate: 'required',
          placeholder: 'Full Name',
        },
        {
          id: 'phone',
          title: 'Phone Number',
          type: 'number',
          validate: 'required',
          placeholder: 'Phone Number',
        },
        {
          id: 'address',
          title: 'Address',
          type: 'text',
          validate: 'required',
          placeholder: 'Address',
        },
      ],
    },
  ],
};

export const records = [
  {
    data: '{"data":{"reason_for_visit":"just cheking up on him","observation":"nothing to observe","medications":[{"drug_name":"paracetamol","dose":"twice daily"}],"blod_presure":[{"value":"50mmHg","level":"Medium"}]},"meta":{"reason_for_visit":{"type":"text"},"observation":{"type":"text"},"medications":{"type":"table"},"blod_presure":{"type":"compound"}}}',
    _id: '633c45208c212d272b4f11c5',
  },
  {
    data: '{"data":{"reason_for_visit":"Just another visitation, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting v Just here to visit, i no dey observe anyting Just here to visit, i no dey observe anyting","observation":"Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, Just here to visit, i no dey observe anyting, v","medications":[{"drug_name":"paracetamol","dose":"twice daily"},{"drug_name":"Flagile","dose":"once daily"},{"drug_name":"vitamin C","dose":"3, Moring and night"}],"blod_presure":[{"value":"50mmHg","level":"Medium"}]},"meta":{"reason_for_visit":{"type":"text"},"observation":{"type":"text"},"medications":{"type":"table"},"blod_presure":{"type":"compound"}}}',
    _id: '633c45db8c212d272b4f1221',
  },
  {
    data: '{"data":{"scan_report":"http://res.cloudinary.com/ankora-health/image/upload/v1664930268/aquila-memoji_te7elm.png","reason_for_visit":"hello there","observation":"anything","medications":[{"drug_name":"paracetamol","dose":"twice daily"}],"blod_presure":[{"value":"50mmHg","level":"Medium"}]},"meta":{"scan_report":{"type":"image"},"reason_for_visit":{"type":"text"},"observation":{"type":"text"},"medications":{"type":"table"},"blod_presure":{"type":"compound"}}}',
    _id: '633cd2108c212d272b4f196d',
  },
  {
    data: '{"data":{"scan_report":"http://res.cloudinary.com/ankora-health/image/upload/v1667211872/image_z59fdr.png","reason_for_visit":"I want to buy weed","observation":"Testing","medications":[{"drug_name":"Paracetamol","dose":"200"},{"drug_name":"Aphazole","dose":"300"},{"drug_name":"Albendazole","dose":"200"}],"blod_presure":[{"value":"150","level":"High"}]},"meta":{"scan_report":{"type":"image"},"reason_for_visit":{"type":"text"},"observation":{"type":"text"},"medications":{"type":"table"},"blod_presure":{"type":"compound"}}}',
    _id: '635fa278843fa3b954c8d383',
  },
  {
    input: {
      patient: '633c2cba8c212d272b4f091a',
      facility: '633c29b78c212d272b4f0787',
      provider: '633c29b78c212d272b4f078d',
      template: '636df6d5bd346889b14a54f0',
      data: [
        {
          id: 'next_of_kin',
          title: 'Next Of Kin',
          type: 'RowValue',
          value: {
            rowValue: [
              {
                id: 'fullname',
                title: 'Full Name',
                type: 'TextValue',
                value: {
                  textValue: 'Emmanuel Afuadajo',
                },
              },
              {
                id: 'phone_number',
                title: 'Phone Number',
                type: 'TextValue',
                value: {
                  textValue: '08143696946',
                },
              },
              {
                id: 'address',
                title: 'Address',
                type: 'TextValue',
                value: {
                  textValue: '12, Crescent road',
                },
              },
            ],
          },
        },
        {
          id: 'id_type',
          title: 'Identification Type',
          type: 'MultiSelectValue',
          value: {
            multiSelectValue: ['PP', 'DP', 'BP'],
          },
        },
        {
          id: 'nationality',
          title: 'Nationality',
          type: 'TextValue',
          value: {
            textValue: 'Nigerian',
          },
        },
        {
          id: 'fullname',
          title: 'Full Name',
          type: 'TextValue',
          value: {
            textValue: 'Emmanuel Afuadajo',
          },
        },
        {
          id: 'dob',
          title: 'DOB',
          type: 'DateValue',
          value: {
            dateValue: '28-03-2018',
          },
        },
        {
          id: 'gender',
          title: 'Gender',
          type: 'SelectValue',
          value: {
            selectValue: 'male',
          },
        },
      ],
    },
  },
];

// const permissions = [
//   'MANAGE_FACILITY',
//   'EDIT_FACILITY',
//   'ADD_NEW_FACILITY',
//   'DELETE_FACILITY',
//   'MANAGE_STAFFS',
//   'ADD_STAFF',
//   'DELETE_STAFF',
//   'EDIT_STAFF',
//   'MANAGE_PATIENTS',
//   'ADD_NEW_PATIENT',
//   'EDIT_PATIENT',
//   'MANAGE_SERVICES',
//   'ADD_NEW_SERVICE',
//   'EDIT_SERVICE',
//   'DELETE_SERVICE',
//   'VIEW_DASHBOARD',

//   'INVITE_NEW_STAFF',
//   'CREATE_APPOINTMENT',
//   'DISABLE_STAFF',

//   // new permissions
//   'VIEW_CALENDAR',
//   'C'
// ];

export enum PERMISSIONS {
  ADD_NEW_FACILITY = 'ADD_NEW_FACILITY',
  EDIT_FACILITY = 'EDIT_FACILITY',
  VIEW_FACILITY = 'VIEW_FACILITY',
  DELETE_FACILITY = 'DELETE_FACILITY',
  ADD_STAFF = 'ADD_STAFF',
  DELETE_STAFF = 'DELETE_STAFF',
  EDIT_STAFF = 'EDIT_STAFF',
  VIEW_STAFF = 'VIEW_STAFF',
  ADD_NEW_PATIENT = 'ADD_NEW_PATIENT',
  EDIT_PATIENT = 'EDIT_PATIENT',
  VIEW_PATIENT = 'VIEW_PATIENT',
  DELETE_PATIENT = 'DELETE_PATIENT',
  ADD_NEW_SERVICE = 'ADD_NEW_SERVICE',
  EDIT_SERVICE = 'EDIT_SERVICE',
  DELETE_SERVICE = 'DELETE_SERVICE',
  VIEW_SERVICE = 'VIEW_SERVICE',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  INVITE_NEW_STAFF = 'INVITE_NEW_STAFF',
  ADD_RECORD = 'ADD_RECORD',
  VIEW_RECORD = 'VIEW_RECORD',
  DELETE_RECORD = 'DELETE_RECORD',
  EDIT_RECORD = 'EDIT_RECORD',
  ADD_RECORD_TEMPLATE = 'ADD_RECORD_TEMPLATE',
  EDIT_RECORD_TEMPLATE = 'EDIT_RECORD_TEMPLATE',
  VIEW_RECORD_TEMPLATE = 'VIEW_RECORD_TEMPLATE',
  DELETE_RECORD_TEMPLATE = 'DELETE_RECORD_TEMPLATE',
  VIEW_APPOINTMENT_DETAILS = 'VIEW_APPOINTMENT_DETAILS',
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  EDIT_APPOINTMENT = 'EDIT_APPOINTMENT',
  UPDATE_ORGANISATION_INFORMATION = 'UPDATE_ORGANISATION_INFORMATION',
  VIEW_ORGANISATION = 'VIEW_ORGANISATION',
  DELETE_ORGANISATION = 'DELETE_ORGANISATION',
  ADD_TODO = 'ADD_TODO',
  DELETE_TODOS = 'DELETE_TODOS',
  EDIT_TODOS = 'EDIT_TODOS',
  VIEW_TODOS = 'VIEW_TODOS',
  ADD_TAG = 'ADD_TAG',
  DELETE_TAG = 'DELETE_TAG',
  EDIT_TAG = 'EDIT_TAG',
  VIEW_TAG = 'VIEW_TAG',
}

// const defaultPermissions = {
//   admin: 'all permissions',
//   owner: 'all permissions',
//   nurse: ['VIEW_PATIENT_LIST', 'ADD_NEW_PATIENT', 'EDIT_PATIENT', 'VIEW_RECORD', 'VIEW_STAFF'],
//   doctor: [
//     'VIEW_PATIENT_LIST',
//     'ADD_NEW_PATIENT',
//     'EDIT_PATIENT',
//     'VIEW_STAFF',
//     'VIEW_SERVICE',
//     'VIEW_FACILITY',
//     'VIEW_DASHBOARD',
//     'all permissions involving records, record templates, appointments, tag and todo'
//      '
//   ],
// };

// const t = {
//   input: {
//     title: 'Patient Check-in',
//     description: 'Form to record Patient Check-in',
//     facility: '633c29b78c212d272b4f0787',
//     fields: [
//       {
//         id: 'next_of_kin',
//         title: 'Next of kin',
//         type: 'Row',
//         fields: [
//           {
//             id: 'fullname',
//             title: 'Full Name',
//             type: 'Text',
//             validation: 'required',
//             placeholder: 'Full Name',
//           },
//           {
//             id: 'phone_number',
//             title: 'Phone Number',
//             type: 'Number',
//             validation: 'required',
//             placeholder: 'Phone Number',
//           },
//           {
//             id: 'address',
//             title: 'Address',
//             type: 'Text',
//             validation: 'required',
//             placeholder: 'Address',
//           },
//         ],
//       },
//       {
//         id: 'id_type',
//         title: 'Identification Type',
//         type: 'MultiSelect',
//         validation: 'required',
//         placeholder: 'Select',
//         options: [
//           {
//             label: 'PP',
//             value: 'PP',
//           },
//           {
//             value: 'DP',
//             label: 'DP',
//           },
//           {
//             label: 'ID',
//             value: 'ID',
//           },
//           {
//             label: 'BP',
//             value: 'BP',
//           },
//         ],
//       },
//       {
//         id: 'nationality',
//         title: 'Nationality',
//         type: 'Text',
//         validation: 'required',
//         placeholder: 'Nigerian',
//       },
//       {
//         id: 'fullname',
//         title: 'Full Name',
//         type: 'Text',
//         validation: 'required',
//         placeholder: 'John Doe',
//       },
//       {
//         id: 'dob',
//         title: 'DOB',
//         type: 'Date',
//         validation: 'required',
//         placeholder: 'Date',
//       },
//       {
//         id: 'gender',
//         title: 'Gender',
//         type: 'Select',
//         validation: 'required',
//         placeholder: 'Select',
//         options: [
//           {
//             label: 'male',
//             value: 'Male',
//           },
//           {
//             value: 'female',
//             label: 'Female',
//           },
//           {
//             label: 'non-binary',
//             value: 'Non-binary',
//           },
//         ],
//       },
//     ],
//   },
// };

// mutation AddRecordTemplate($input: AddRecordTemplateInput) {
//   addRecordTemplate(input: $input) {
//     message
//     recordTemplate {
//       _id
//       fields {
//         id
//         title
//         type
//         ... on DateField {
//           id
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on MultiSelectField {
//           id
//           options {
//             label
//             value
//           }
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on NumberField {
//           id
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on TextField {
//           id
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on SelectField {
//           id
//           options {
//             label
//             value
//           }
//           title
//           type
//           validation
//         }
//       }
//     }
//   }
// }

// query GetRecordTemplates($input: GetRecordTemplatesOrRecordsInput) {
//   getRecordTemplates(input: $input) {
//     recordTemplates {
//       _id
//       description
//       id
//       title
//       fields {
//         id
//         title
//         type
//         ... on DateField {
//           id
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on MultiSelectField {
//           id
//           options {
//             label
//             value
//           }
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on NumberField {
//           id
//           placeholder
//           title
//           type
//           validation
//         }
//         ... on RowField {
//           fields {
//             ... on DateField {
//               id
//               placeholder
//               title
//               type
//               validation
//             }
//             ... on MultiSelectField {
//               id
//               options {
//                 label
//                 value
//               }
//               placeholder
//               title
//               type
//               validation
//             }
//             ... on NumberField {
//               id
//               placeholder
//               title
//               type
//               validation
//             }
//             ... on SelectField {
//               id
//               options {
//                 label
//                 value
//               }
//               title
//               type
//               validation
//             }
//             ... on TextField {
//               id
//               placeholder
//               title
//               type
//               validation
//             }
//             type
//             title
//             id
//           }
//           id
//           title
//           type
//         }
//         ... on SelectField {
//           id
//           options {
//             label
//             value
//           }
//           title
//           type
//           validation
//         }
//         ... on TableField {
//           columns {
//             id
//             title
//             type
//             ... on DateField {
//               id
//               placeholder
//               title
//               type
//               validation
//             }
//             ... on MultiSelectField {
//               id
//               options {
//                 label
//                 value
//               }
//               placeholder
//               title
//               type
//               validation
//             }
//             ... on NumberField {
//               id
//               placeholder
//               title
//               type
//               validation
//             }
//             ... on SelectField {
//               id
//               options {
//                 label
//                 value
//               }
//               title
//               type
//               validation
//             }
//             ... on TextField {
//               id
//               placeholder
//               title
//               type
//               validation
//             }
//           }
//           id
//           title
//           type
//         }
//         ... on TextField {
//           id
//           placeholder
//           title
//           type
//           validation
//         }
//       }
//     }
//   }
// }

export const temperatureMock = [
  ['Entries', 'Temperature'],
  [new Date('2023-04-20'), 99.7],
  [new Date('2023-04-18'), 97.6],
  [new Date('2023-04-17'), 98],
  [new Date('2023-04-16'), 99],
  [new Date('2023-04-15'), 98.5],
  [new Date('2023-04-14'), 97.8],
  [new Date('2023-04-13'), 97],
  [new Date('2023-04-12'), 98.2],
  [new Date('2023-04-11'), 99.6],
  [new Date('2023-04-24'), 99],
];
export const pulseMock = [
  ['Entries', 'Pulse'],
  [new Date('2023-04-20'), 80],
  [new Date('2023-04-18'), 67],
  [new Date('2023-04-17'), 90],
  [new Date('2023-04-16'), 85],
  [new Date('2023-04-15'), 55],
  [new Date('2023-04-14'), 40],
  [new Date('2023-04-13'), 100],
  [new Date('2023-04-12'), 110],
  [new Date('2023-04-11'), 99],
  [new Date('2023-04-24'), 87],
];
export const respiratory_rateMock = [
  ['Entries', 'Respiratory Rate'],
  [new Date('2023-04-20'), 12],
  [new Date('2023-04-18'), 10],
  [new Date('2023-04-17'), 18],
  [new Date('2023-04-16'), 20],
  [new Date('2023-04-15'), 14],
  [new Date('2023-04-14'), 16],
  [new Date('2023-04-13'), 17],
  [new Date('2023-04-12'), 15],
  [new Date('2023-04-11'), 9],
  [new Date('2023-04-24'), 8],
];

export const diastolicMock = [
  ['Entries', 'Diastolic blood pressure'],
  [new Date('2023-04-20'), 54],
  [new Date('2023-04-18'), 69],
  [new Date('2023-04-17'), 73],
  [new Date('2023-04-16'), 60],
  [new Date('2023-04-15'), 80],
  [new Date('2023-04-14'), 82],
  [new Date('2023-04-13'), 78],
  [new Date('2023-04-12'), 72],
  [new Date('2023-04-11'), 80],
  [new Date('2023-04-24'), 68],
];

export const systolicMock = [
  ['Entries', 'Systolic blood pressure'],
  [new Date('2023-04-20'), 88],
  [new Date('2023-04-18'), 98],
  [new Date('2023-04-17'), 119],
  [new Date('2023-04-16'), 130],
  [new Date('2023-04-15'), 118],
  [new Date('2023-04-14'), 92],
  [new Date('2023-04-13'), 107],
  [new Date('2023-04-12'), 100],
  [new Date('2023-04-11'), 90],
  [new Date('2023-04-24'), 128],
];

export const mocks: { [key: string]: typeof temperatureMock } = {
  temperature: temperatureMock,
  respiratory_rate_above_twelve_years: respiratory_rateMock,
  pulse: pulseMock,
  diastolic: diastolicMock,
  systolic: systolicMock,
};
