import { gql } from '@apollo/client';
import { familyFieldsFragment } from '../Fragments/family';


export const SEARCH_FAMILIES = gql`
  query SearchFamily($input: SearchFamilyInput!) {
    searchFamily(input: $input) {
      total
      families {
        ...FamilyFields
        patients {
          id: _id
          patientId
          createdAt
          user {
            firstName
            lastName
            fullName
            emails
          }
        }
      }
    }
  }
  ${familyFieldsFragment}
`;


export const GET_FAMILY = gql`
  query GetFamily($familyId: ID!) {
    getFamily(familyId: $familyId) {
      ...FamilyFields
      patients {
        id: _id
        patientId
        createdAt
        user {
          firstName
          lastName
          fullName
          emails
        }
      }
    }
  }
  ${familyFieldsFragment}
`;