import { gql } from "@apollo/client";

export const patientFieldsFragment = gql`
  fragment PatientFields on Patient {
    id: _id
    patientId
    status
    createdAt
    updatedAt
    basicInfo {
      phoneNumber
      dateOfBirth
      gender
      maritalStatus
      nationality
    }
    otherInfo {
      languageSpoken
      secondaryPhoneNumber
      homeAddress
      workAddress
      hmo {
        hmoProviderId
        id: _id
        hmo {
          name
          hmoId
          createdAt
          id: _id
        }
      }
    }
    nextOfKin {
      firstName
      lastName
      phoneNumber
      emailAddress
      homeAddress
      relationshipToPatient
    }
    user {
      firstName
      lastName
      fullName
      emails
    }
    identity {
      typeOfIdentity
      identityNumber
    }
  }
`;
