import * as React from 'react';
import { AppointmentType } from 'src/types';
import AppointmentsContext from './context';

const AppointmentProvider: React.FC = ({ children }) => {
  const [initialAppointments, setInitialAppointments] = React.useState<AppointmentType>({} as AppointmentType);

  return (
    <AppointmentsContext.Provider 
      value={{ initialAppointments, setInitialAppointments }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};

export default AppointmentProvider;
