import { gql } from "@apollo/client";


export const staffGroupFieldsFragment = gql`
  fragment StaffGroupFields on StaffGroup {
    id: _id
    name
    suggestedPermissions {
      id: _id
      name
    }
  }
`;