import { gql } from "@apollo/client";
import { settlementFieldsFragment } from "../Fragments/settlement";


export const CREATE_SETTLEMENT = gql`
  mutation CreateSettlement($input: CreateSettlementInput!) {
    createSettlement(input: $input) {
      success
      message
      settlement {
        ...SettlementFields
      }
    }
  }
  ${settlementFieldsFragment}
`;

export const DELETE_SETTLEMENT = gql`
  mutation DeleteSettlement($deleteSettlementId: ID!) {
    deleteSettlement(id: $deleteSettlementId) {
      success
      message
      settlement {
        ...SettlementFields
      }
    }
  }
  ${settlementFieldsFragment}
`;

export const UPDATE_SETTLEMENT = gql`
  mutation UpdateSettlement($input: UpdateSettlementInput!, $updateSettlementId: ID!) {
    updateSettlement(input: $input, id: $updateSettlementId) {
      success
      message
      settlement {
        ...SettlementFields
      }
    }
  }
  ${settlementFieldsFragment}
`;
