import styled, { css } from 'styled-components';
import { font, theme } from 'src/utils/variables';
import { ModStylesUtil } from 'src/types';
import { injectModStyles } from '../ModStyles';

export const InputLabel = styled.label<{
  required?: boolean;
  modStyles?: ModStylesUtil;
}>`
  font-size: ${font.sizes.base};
  color: ${theme.black[200]};
  font-weight: ${font.weights.semibold};
  text-align: left;
  margin: 0.5rem 0;
  ${({ required }) => {
    return (
      required &&
      css`
        &::after {
          content: ' *';
          color: ${theme.red[500]};
        }
      `
    );
  }}
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;
