/* eslint-disable no-underscore-dangle */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { AllInvoices, Invoice, InvoiceItem } from 'src/types';
import { useRoute } from 'wouter';
import {
  GET_INVOICE,
  GET_INVOICES,
  ADD_INVOICE_ITEM,
  DELETE_INVOICE_ITEM,
  UPDATE_INVOICE,
  UPDATE_INVOICE_ITEM,
} from 'src/constants';
import { currencyformatter } from 'src/utils';
import { RangeData } from 'src/components/DateRange';
import moment from 'moment';
import { useStaff, useToast } from '..';
import InvoiceContext from './context';

export const defaultState = {
  items: [
    {
      serviceCode: '',
      isHMOPayable: 'OOP',
      units: 1,
      unitCost: 0,
      formattedCost: '',
      description: '',
      currency: '',
    },
  ] as InvoiceItem[],
  notes: '',
  paymentMethod: 'CASH' as const,
};

export const formatData = (d: Invoice) => {
  let items;
  if (!d.items.length) {
    items = defaultState.items;
  } else {
    items = d.items.reduce((acc, item) => {
      acc.push({
        ...item,
        isHMOPayable: item.isHMOPayable ? 'HMO' : 'OOP',
        totalCost: Number(item.unitCost || 0) * Number(item.units || 1),
        formattedCost: currencyformatter.format(
          parseInt(item.unitCost?.toString() as string, 10),
        ),
      });
      return acc;
    }, [] as InvoiceItem[]);
  }
  d.items = items;
  d.paymentMethod = d.paymentMethod || 'CASH';
  return d;
};

const useInvoices = () => {
  const { allInvoices, setAllInvoices, openInvoices, setOpenInvoices } =
    useContext(InvoiceContext);
  const [filteredInvoices, setFilteredInvoices] = useState<AllInvoices>({});
  const [page, setPage] = useState(1);
  const { hasPermission } = useStaff();
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id as string;
  const patientInvoice = allInvoices[patientId];
  const patientOpenInvoice = openInvoices[patientId];

  const { showToast } = useToast();

  const pageLimit = 10;
  const patientInvoiceCount = patientInvoice?.total as number;

  const {
    loading: invoicesLoading,
    error: invoicesError,
    refetch: refetchAllInvoices,
  } = useQuery(GET_INVOICES, {
    errorPolicy: 'all',
    skip:
      patientInvoiceCount > 0 || !hasPermission('VIEW_PATIENT_INVOICE_HISTORY'),
    variables: {
      input: {
        patient: patientId,
        status: 'PAID',
        limit: pageLimit,
        page: 1,
      },
    },
    onCompleted: (data) => {
      const invoices = data.getInvoices.invoices.map((inv: Invoice) =>
        formatData(inv),
      );
      setAllInvoices((prev) => ({
        ...prev,
        [patientId]: {
          invoices: [...invoices],
          total: data.getInvoices.total,
        },
      }));
    },
  });

  const [apolloGetInvoices, { loading: filterLoading, error: filterError }] =
    useLazyQuery(GET_INVOICES, {
      errorPolicy: 'all',
      onCompleted() {
        if (getInvoiceError) {
          showToast('unable to fetch invoice, please try again', 'error');
        }
      },
    });
  const [
    apolloGetInvoice,
    { loading: getInvoiceLoading, error: getInvoiceError },
  ] = useLazyQuery(GET_INVOICE, {
    errorPolicy: 'all',
    onCompleted() {
      if (getInvoiceError) {
        showToast('unable to fetch invoice, please try again', 'error');
      }
    },
  });

  const [addInvoiceItem, { loading: addItemLoading, error: addItemError }] =
    useMutation(ADD_INVOICE_ITEM, {
      awaitRefetchQueries: true,
    });

  const [
    updateInvoiceItem,
    { error: updateItemError, loading: updateItemLoading },
  ] = useMutation(UPDATE_INVOICE_ITEM);

  const [
    deleteInvoiceItem,
    { error: deleteItemError, loading: deleteItemLoading },
  ] = useMutation(DELETE_INVOICE_ITEM);

  const [
    updateInvoice,
    { error: updateInvoiceError, loading: updateInvoiceLoading },
  ] = useMutation(UPDATE_INVOICE);

  const [dateFilterApplied, setDateFilterApplied] = useState(false);
  const [dateRange, setDateRange] = useState<RangeData>({
    startDate: null as unknown as Date,
    endDate: new Date(''),
    key: 'selection',
  });

  const formattedRange = {
    startDate: moment(dateRange.startDate).format('Do MMM, yyyy'),
    endDate: moment(dateRange.endDate).format('Do MMM, yyyy'),
  };

  const applyFilters = () => {
    setDateFilterApplied(true);
    setPage(1);

    apolloGetInvoices({
      variables: {
        input: {
          patient: patientId,
          status: 'PAID',
          limit: pageLimit,
          page: 1,
          startDate: moment(dateRange.startDate).add(1, 'day').toISOString(),
          endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
        },
      },
      onCompleted(data) {
        const invoices = data.getInvoices.invoices.map((inv: Invoice) =>
          formatData(inv),
        );
        setFilteredInvoices((prev) => ({
          ...prev,
          [patientId]: {
            invoices: [...invoices],
            total: data.getInvoices.total,
          },
        }));
      },
    });
  };

  const clearFilters = () => {
    setFilteredInvoices({});
    setPage(1);
    setDateFilterApplied(false);
  };

  const fetchNewpage = (newPage: number) => {
    apolloGetInvoices({
      variables: {
        input: {
          patient: patientId,
          status: 'PAID',
          limit: pageLimit,
          page: newPage,
          ...(dateFilterApplied
            ? {
                startDate: moment(dateRange.startDate)
                  .add(1, 'day')
                  .toISOString(),
                endDate: moment(dateRange.endDate).add(1, 'day').toISOString(),
              }
            : {}),
        },
      },
      onCompleted(data) {
        const invoices = data.getInvoices.invoices.map((inv: Invoice) =>
          formatData(inv),
        );
        setFilteredInvoices({
          [patientId]: {
            invoices: [...invoices],
            total: data.getInvoices.total,
          },
        });
      },
    });
  };

  const refetchInvoices = () => {
    if (patientInvoiceCount > 0) {
      refetchAllInvoices();
    }
  };

  const getInvoiceFromCache = (id: string) => {
    const patientInvoces = patientInvoice;
    const invoice = patientInvoces?.invoices.find((s) => s?._id === id);
    return invoice;
  };

  return {
    page,
    setPage,
    fetchNewpage,
    applyFilters,
    updateInvoice,
    updateInvoiceError,
    updateInvoiceLoading,
    dateFilterApplied,
    clearFilters,
    dateRange,
    setDateRange,
    setDateFilterApplied,
    formattedRange,
    allInvoices,
    filteredInvoices,
    setFilteredInvoices,
    updateItemError,
    deleteItemError,
    setAllInvoices,
    filterLoading,
    filterError,
    pageLimit,
    addInvoiceItem,
    updateInvoiceItem,
    deleteInvoiceItem,
    getInvoiceFromCache,
    refetchInvoices,
    refetchAllInvoices,
    getInvoiceLoading,
    getInvoiceError,
    apolloGetInvoice,
    invoicesLoading,
    invoicesError,
    addItemLoading,
    addItemError,
    updateItemLoading,
    deleteItemLoading,
    patientInvoice:
      dateFilterApplied || page !== 1
        ? filteredInvoices[patientId]
        : patientInvoice,
    patientOpenInvoice,
    setOpenInvoices,
    patientId,
    formatData,
  };
};

export { useInvoices };
