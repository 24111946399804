import { useFormik } from 'formik';
import { Column, Row, Input, SkeletonColumn } from 'src/components';
import { addStaffGroupValidation, getErrorMessage, pluralizeWord, theme } from 'src/utils';
import { MultiRowForm } from 'src/containers';
import { ArrowUpIcon, DeleteIcon, PlusIcon } from 'src/assets/icons';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import { useMultiRowParams } from 'src/hooks/useMultiRowParams';
import { useAlert, useStaff, useStaffGroups, useToast } from 'src/state';
import Validator from 'validatorjs';
import { useModalParams } from 'src/hooks';
import { FORMID_5, PERMISSION_ACTION_MESSAGE } from 'src/constants';
import _ from 'lodash';
import { useEffect } from 'react';
import PermissionsModal from '../PermissionsModal';


interface MultiFormProps {
  setLoading?: (value: boolean) => void,
  onComplete?: () => void,
}

interface FormState {
  staffGroups: {
    id: string;
    name: string;
    permissions: string[]
  }[]
};

const MultiForm:React.FC<MultiFormProps> = ({ setLoading, onComplete }) => {
  const state = {
    id: "",
    name: "",
    permissions: [],
  }
  const { hasPermission } = useStaff();
  const { showToast } = useToast();
  const { showAlert, removeAlert } = useAlert()
  const { 
    useApolloStaffGroups, 
    useCreateStaffGroup, 
    useDeleteStaffGroup, 
    useUpdateStaffGroup 
  } = useStaffGroups();
  const { createStaffGroup, loading: createStaffGroupLoading } = useCreateStaffGroup()
  const { updateStaffGroup, loading: updateStaffLoading } = useUpdateStaffGroup()
  const { deleteStaffGroup } = useDeleteStaffGroup()
  const { staffGroups, loading } = useApolloStaffGroups()
  const { setModalParams, params, hash } = useModalParams();
  const staffGroupData = staffGroups?.getStaffGroups;
  const adminId = staffGroups?.getStaffGroups?.staffGroups?.find((el) => el.name.toLowerCase() === "admin")?.id
  const defaultStaffGroups = staffGroupData?.staffGroups
  ?.filter((el) => el.name.toLowerCase() !== "owner")
  ?.sort((a) => {
    if (a.name.toLowerCase() === "admin") return -1;
    return 0
  })?.map((el) => ({
    id: el.id,
    name: pluralizeWord(el.name) ,
    permissions: el.suggestedPermissions.map(permission => permission.id)
  }));

  const { handleChange, values, errors, setValues, setFieldValue, handleSubmit } = useFormik<FormState>({
    initialValues: { staffGroups: defaultStaffGroups },
    enableReinitialize: true,
    onSubmit: async (_values) => {

      const changedValues = _.differenceWith(_values.staffGroups, defaultStaffGroups, _.isEqual)
      const newValues = _.map(_.filter(changedValues, { id: "" }), ({ name, permissions }) => ({ 
        name: name.endsWith("s") ? name.slice(0, -1) : name, 
        permissions 
      }));
      const updatedValues =  _.map(_.filter(changedValues, ({ id }) => id !== ""), ({ id, name, ...rest }) => ({
        _id: id, 
        name: name.endsWith("s") ? name.slice(0, -1) : name,
        ...rest 
      }));

      if (newValues.length > 0) {
        await createStaffGroup({ staffGroups: newValues })
      }
      if (updatedValues.length > 0) {
        await updateStaffGroup({ staffGroups: updatedValues })
      }
      onComplete?.()
    },
    validate: (_values) => {
      const validation = new Validator(
        _values, 
        addStaffGroupValidation, 
        { required: 'Field is required' }
      );
      validation.passes();
      return validation.errors.errors;
    },
  });
  

  const { addNewRow, deleteRow } = useMultiRowParams<FormState>(
    "staffGroups",
    state,
    values, 
    setValues
  );


  useEffect(() => {
    setLoading?.(updateStaffLoading || createStaffGroupLoading)
  }, [createStaffGroupLoading, setLoading, updateStaffLoading])

  const deleteItem = (index: number, id?: string, name?: string) => {
    if (!hasPermission("DELETE_STAFF_GROUP")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
    if (!id) return deleteRow(index)
    return showAlert({
      type: 'decision',
      title: 'Confirm Delete',
      message: `Do you want to delete ${name} staff Group`,
      confirm: async () => {
        await deleteStaffGroup(id)?.then((res) => {
          if (res?.data) {
            deleteRow(index)
            removeAlert();
          }
        })
      }
    })
  }

  const getPermissionValue = (name: string, permissions: string[]) => {
    if (name && permissions.length === 0) return `No permissions selected`;
    if (!name && permissions.length === 0) return `Select Default Permissions`;
    return `${permissions.length} permissions selected`;
  }
  

  useEffect(() => {
    if (staffGroupData?.total > 0 && params.get("edit-permission") === "default") {
      const requestAnimationFrameId = requestAnimationFrame(() => {
        addNewRow();
      });
      return () => cancelAnimationFrame(requestAnimationFrameId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffGroupData?.total]);

  return (
    <form id={FORMID_5} noValidate onSubmit={handleSubmit}>
      <Column 
        justify="space-between" 
        maxHeight='46vh' 
        width="100%" 
        modStyles={{ overflowY: "auto", overflowX: 'hidden', pr: 0.75 }} 
        gap={1}
      >
        {loading && staffGroupData?.total === 0 ? <SkeletonColumn width={843} height={49} /> : (
          <MultiRowForm
            shouldShowSuggestion={values?.staffGroups?.[values?.staffGroups?.length - 1]?.name !== ''}
            onAddNewRow={addNewRow}
          >
            {values.staffGroups?.map(({ name, permissions, id }, index) => {
              const modalParameter = (name && id) && name || 'default';

              return (
                <Column width="100%" key={index}>
                  <Row gap={1} width="100%" align="flex-end">
                    <Row
                      align="center"
                      justify="space-between"
                      width="100%"
                      gap={1}
                    >
                      <Input
                        label={index === 0 ? "Staff Groups" : ""}
                        data-testid="staffGroups"
                        placeholder="Enter Staff Group Name"
                        name={`staffGroups[${index}].name`}
                        value={name}
                        required={index === 0}
                        disabled={id === adminId}
                        error={getErrorMessage(errors as Record<string, string[]>, "name", "staffGroups", index)}
                        onChange={handleChange}
                      />
                      <Input
                        label={index === 0 ? "Default Permissions" : ""}
                        className='text-left'
                        required={index === 0}
                        data-testid="permissions"
                        name={`staffGroups[${index}].permissions`}
                        value={getPermissionValue(name, permissions)}
                        error={getErrorMessage(errors as Record<string, string[]>, "permissions", "staffGroups", index)}
                        readOnly
                        type="button"
                        disabled={!hasPermission("EDIT_STAFF_GROUP")}
                        onClick={() => {
                          if (hash.startsWith("#manage-staffgroup") && !hasPermission("EDIT_STAFF_GROUP")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
                          setModalParams({ "edit-permission": modalParameter })
                        }}
                        icon={name ? <PlusIcon className='fill-primary' /> : <ArrowUpIcon />}
                      />
                    </Row>
                    <Row width="2.875rem">
                      {id !== adminId && (
                        <MultiRowDeleteButton
                          variant="solid"
                          data-testid="delete-param-row"
                          background={theme.grey[100]}
                          color={theme.grey[200]}
                          size="2.5rem"
                          disabled={!hasPermission("DELETE_STAFF_GROUP")}
                          type="button"
                          onFocus={(e) => e.stopPropagation()}
                          onClick={() => deleteItem(index, id, name)}
                        >
                          <DeleteIcon />
                        </MultiRowDeleteButton>
                      )}
                    </Row>
                  </Row>
                  {params.get("edit-permission") === modalParameter && (
                    <PermissionsModal
                      setModalParams={setModalParams}
                      modalParams={modalParameter}
                      defaultPermissions={permissions}
                      setFieldValue={setFieldValue}
                      hash={hash}
                      name={`staffGroups[${index}].permissions`}
                    />
                  )}
                </Column>
              )
            })}
          </MultiRowForm>
        )}
      </Column>
    </form>
  );
};

export default MultiForm;