import _ from 'lodash';
import {
  Button,
  Column,
  Row,
  Text,
  H6,
  Checkbox,
  InputLabel,
  SearchInput,
  SkeletonColumn
} from 'src/components';
import { Accordion, Modal } from 'src/containers';
import { useAlert, usePerms } from 'src/state';
import { CancelIcon } from 'src/assets/icons';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { theme } from 'src/utils';
import { CheckboxBase } from 'src/components/Checkbox/styled';
import { EmptyTodo } from 'src/assets/images';
import { LabelButton } from './styled';


interface PermissionModalProps {
  setModalParams: (newParams: Record<string, string | undefined>) => void
  modalParams: string
  name: string
  hash?: string
  defaultPermissions: string[],
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
}


interface Props {
  id: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  children: React.ReactNode
}



const PermissionsModal: React.FC<PermissionModalProps> = ({ 
  setModalParams, 
  modalParams, 
  name,
  hash,
  defaultPermissions,
  setFieldValue
}) => {
  const { showAlert, removeAlert } = useAlert();
  const [searchValue, setSearchValue] = useState<string>("");
  const [ids, setIds] = useState<string[]>(defaultPermissions);
  const { usePermsissionsConfig, nameToIdMap } = usePerms(); 
  const { loading, permissionConfig } = usePermsissionsConfig();

  const viewAdminDashboardId = nameToIdMap("VIEW ADMINS DASHBOARD") as string;
  const viewDoctorsDashboardId = nameToIdMap("VIEW DOCTORS DASHBOARD") as string;

  const isDashboardId = (id: string) => {
    if (id === viewAdminDashboardId) return viewAdminDashboardId
    if (id === viewDoctorsDashboardId) return viewDoctorsDashboardId
    return ``
  }

  const alternateId = (id: string) => { 
    if (isDashboardId(id) === ``) return ``;
    if (id === viewAdminDashboardId) return viewDoctorsDashboardId;
    if (id === viewDoctorsDashboardId) return viewAdminDashboardId
  }


  const permissions = (permissionConfig || [])
  .filter(el => !searchValue || el.name.toLowerCase().includes(searchValue.toLowerCase()))

  useEffect(() => {
    setIds(defaultPermissions)
  }, [defaultPermissions])


  const isManageStaffGroup = hash?.toLowerCase() === "#manage-staffgroup" || hash?.toLowerCase() === "#edit-staff";
  const title = `Edit ${modalParams.toLowerCase() !== "default" ? `'${_.startCase(modalParams)}'` : ''} Staff Group Permissions`;
  const closeModal = () => setModalParams({ "edit-permission": undefined });
  const saveChanges = () => {
    setFieldValue(name, ids);
    closeModal();
  }


  const ConfirmButton: React.FC<Props> = ({ id, checked, onChange, children }) => (
    <LabelButton>
      <CheckboxBase 
        id={id}
        name={name}
        value={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {children}
    </LabelButton>
  )


  const CloseButton = () => (
    <Button 
      variant='outlined'
      width='8.32rem' 
      onClick={removeAlert} 
      background="neutral"
      color="black"
      modStyles={{ 
        br: `0.42rem`, 
        fontSize: `12px`, 
        lineHeight: `14.3px`,
        py: `10px`, 
        px: `13.57px`,
        color: `#484848`,
      }}
    >
      Close
    </Button>
  )


  const onCheck = (event: ChangeEvent<HTMLInputElement>, arr?: string[]) => {
    const { value, checked } = event.target
    const newIds = checked ? [...ids, ...(arr || []), value] : ids.filter(el => el !== value)
    setIds(_.uniq(newIds))
  }


  const manageToast = (event: ChangeEvent<HTMLInputElement>, id: string, permissionName: string, dependents: string[]) => {
    event.preventDefault()
    const pluralSuffix = dependents.length > 2 ? "s" : '';

    const message = `Unselecting "${permissionName}" may cause "${dependents[0]}${dependents.length > 1 ? ` and ${dependents.length - 1} other selected permission${pluralSuffix}` : ''}" to not function properly`;
    
    showAlert({ 
      type: 'decision',
      message, 
      confirmButton: (
        <ConfirmButton
          id={id} 
          checked={ids.includes(id)}
          onChange={(ev) => {
            onCheck(ev)
            removeAlert()
          }}
        >
          Unselect
        </ConfirmButton>
      ), 
      closeButton: <CloseButton /> 
    })
  };



  const checkToast = (event: ChangeEvent<HTMLInputElement>, id: string, permissionName: string, altId: string) => {
    event.preventDefault();
    const alternatePermission = permissionConfig.find(el => el.id === altId)?.name as string

    const message = `Checking "${permissionName}" permission will uncheck "${alternatePermission}" permission`;
    showAlert({ 
      type: 'decision',
      message, 
      confirmButton: (
        <ConfirmButton
          id={id} 
          checked={ids.includes(id)}
          onChange={(ev) => {
            const newIds = ids.filter(el => el !== altId);
            const updatedIds = [...newIds, ev.target.value];
            setIds(_.uniq(updatedIds));
            removeAlert()
          }}
        >
          Check
        </ConfirmButton>
      ), 
      closeButton: <CloseButton /> 
    })
  }

  return (
    <Modal 
      closeButton={false}
      width={!isManageStaffGroup ? "43rem" : "52rem"} 
      modStyles={{ 
        // px: !isManageStaffGroup ? 0 : 2.5, 
        pt: 3.125, 
        // pb: !isManageStaffGroup ? 0.25: 1.875 
      }} 
    >
      <Column gap={1}>
        {isManageStaffGroup && (
          <Row justify='space-between'>
            <Column gap={0.65}>
              <H6 color='#313131' modStyles={{ my: 0, lineHeight: '26.4px' }}>
                {title}
              </H6> 
              <Text color="#666" size="sm" weight="semibold" modStyles={{ my: 0, lineHeight: '18.48px' }}>
                Make changes to this staff grooup
              </Text>
            </Column>
            <Button width="max-content" onClick={closeModal} variant="text">
              <CancelIcon className='stroke-width-0' />
            </Button>
          </Row>
        )} 
        <SearchInput 
          onChange={(ev) => setSearchValue(ev.target.value)} 
          placeholder='Search Permissions'
          autoFocus 
        />
        <Column 
          gap={0.65} 
          maxHeight='44vh' 
          className="scrollable-content"
        >
          {loading && <SkeletonColumn width={713} height={49} />}
          {!loading && permissions?.length === 0 && (
            <Column width="100%" align='center'>
              <EmptyTodo />
              <Text>No Permissions to show</Text>
            </Column>
          )}
          {!loading && permissions.map(({ name: permissionName, dependencies, id, dependents }, idx) => {
            const hasDependencies = dependencies.length > 0;
            const featureDependenciesId = dependencies.map(item => item.id);
            const stringifyName = (value: string) => _.startCase(_.toLower(value));
            
            return (
              <Column key={idx} width="100%" justify="space-between" gap={1}>
                <Accordion openByDefault={false}>
                  <Accordion.Header hasArrowIcon={hasDependencies}>
                    <Row 
                      background='#F4F5F5' 
                      align="center" 
                      justify='space-between'
                      modStyles={{ py: '0.875rem', px: '0.75rem', br: '0.625rem' }}
                    >
                      <InputLabel 
                        className='flex items-center'
                        onClick={(ev) => ev.stopPropagation()}
                        modStyles={{ 
                          my: 0, 
                          textTransform: `capitalize`, 
                          fontSize: `1rem`, 
                          lineHeight: `21.12px`,
                          color: `#626262`
                        }}
                      >
                        <CheckboxBase 
                          id={id}
                          name={name}
                          checked={ids.includes(id)}
                          value={id}
                          type="checkbox"
                          background={theme.secondary[600]}
                          border="1px solid #E5E5E5"
                          onChange={(ev) => {

                            if (isDashboardId(id) && !ids.includes(id) && ids.includes(alternateId(id) as string)) {
                              return checkToast(ev, id, permissionName, alternateId(id) as string)
                            }

                            if (dependents.length > 0 && ids.includes(id)) {
                              return manageToast(ev, id, permissionName, dependents) 
                            }
                            return onCheck(ev, featureDependenciesId)
                          }}
                        />
                        {permissionName}
                      </InputLabel>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Content show={hasDependencies} className="br-10 shadow-accordion">
                    <Column gap={0.875}>
                      <Text 
                        weight='semibold' 
                        size="xs" 
                        color="#666666"
                        modStyles={{ lineHeight: '13.8px', my: 0 }}
                      >
                        Selecting &apos;{stringifyName(permissionName)}&apos; grants access to the following permissions by default.
                      </Text>
                      {dependencies.map((el, index) => {
                        const { name: dependencyName, description, id: dependencyId } = el;
                        return (
                          <Row key={index} align="center">
                            <Checkbox 
                              background='#9B9B9B'
                              border="1px solid #E5E5E5"
                              onChange={(ev) => ev.preventDefault()}
                              value={dependencyId}
                              checked={ids.includes(dependencyId)}
                            />
                            <InputLabel 
                              modStyles={{ 
                                my: 0, 
                                fontSize: `0.875rem`, 
                                color: `#282828`, 
                                lineHeight: `18.48px` 
                              }}
                            >
                              {`${dependencyName} - ${description}`}
                            </InputLabel>
                          </Row>
                        )
                      })}
                    </Column>
                  </Accordion.Content>
                </Accordion>
              </Column>
            )
          })}
        </Column>
        {!loading && (
          <Row width="100%" justify="space-between">
            <Button 
              type="button"
              onClick={closeModal}
              background="neutral"
              width="max-content"
              color="black"
            >
              Cancel
            </Button>
            <Button 
              type="button" 
              onClick={saveChanges}
              width="max-content"
            >
              Save
            </Button>
          </Row>
        )}
      </Column>
    </Modal>
  );
};

export default PermissionsModal;