import styled from 'styled-components';
import { Button, Row, H6, IconButton } from 'src/components';
import { font } from 'src/utils/variables';

export const ModalTitle = styled(H6)`
  font-size: 1.25rem;
  margin: 0;
  font-weight: ${font.weights.bold}
`;

export const MainInputGroup = styled(Row)`
  @media (max-width: 960px) {
    flex-wrap: wrap;
    gap: 0.5;
    margin-bottom: 1rem;
  }
`;
export const InnerInputGroup = styled(Row)`
  @media (max-width: 960px) {
    flex-wrap: wrap;
    gap: 0.5;
  }
`;
export const DimInputGroup = styled(Row)`
  display: none;
  opacity: 0.4;
  @media (max-width: 960px) {
    flex-wrap: wrap;
    gap: 0.5;
  }
`;
export const AddStaffButton = styled(Button)`
  width: 10.8125rem;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const BinButton = styled(IconButton)`
  align-self: flex-end;
  path {
    stroke-width: 0.1;
  }
`;

export const FullWidthForm = styled.form`
  width: 100%;
`;