/* eslint-disable no-underscore-dangle */
import { Button, Column, ConfirmationPrompt, H6, Pill, Row, Select } from 'src/components';
import { useLocation } from 'wouter';
import { useContext, useState } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { getModalParams, removeEmptyValues } from 'src/utils';
import { paymentOptions } from 'src/pages/admin/PatientDetails/Invoice/constants';
import { Invoice, PaymentMethodType } from 'src/types';
import moment from 'moment';
import SummaryInfo from 'src/pages/admin/PatientDetails/Invoice/SummaryInfo';
import {
  GET_INVOICES_TOTAL,
  GET_STOCK_ALERT,
  GET_TOTAL_SALES,
  UPDATE_INVOICE,
} from 'src/constants';
import { useMutation } from '@apollo/client';
import { useToast } from 'src/state';
// import { useInventoryInvoices } from 'src/store/inventory-invoices';
import mixpanel from 'mixpanel-browser';
import SummaryTable from '../components/SummaryTable';
import { ViewContext } from '.';
import { includeTotalCost } from './InvoicePreview';

const InvoiceConfirmation = () => {
  const [, setLocation] = useLocation();
  const { setView, currentInvoice, setCurrentInvoice, patient, staff } =
    useContext(ViewContext);
  // const { fetchInventoryInvoices } = useInventoryInvoices();
  const invoiceType = getModalParams('type');
  const [completed, setCompleted] = useState(false);
  const [newInvoice, setNewInvoice] = useState<Invoice>();
  const { showToast } = useToast();

  const [markInvoice, { loading: markInvoiceLoading }] = useMutation(
    UPDATE_INVOICE,
    { errorPolicy: 'all' },
  );

  const goBack = () => {
    setView('open');
    setLocation(`#inventory-invoice&mView=open&type=${invoiceType}`);
  };

  const goToInvoice = () => {
    setView('preview');
    setLocation(`#inventory-invoice&mView=preview&id=${newInvoice?._id}`);
  };

  const handleSubmit = async () => {
    mixpanel.track(`Click 'Confirm Invoice' button`, {feature: 'Inventory Invoice'})
    const extraFields = {
      requestedBy: staff?.id,
      patient: patient?.id,
    };

    await markInvoice({
      variables: {
        input: {
          status: 'PAID',
          paymentMethod: currentInvoice.paymentMethod,
          note: currentInvoice.note,
          ...removeEmptyValues(extraFields),
        },
        invoiceId: currentInvoice._id,
      },
      refetchQueries: [GET_TOTAL_SALES, GET_STOCK_ALERT, GET_INVOICES_TOTAL],
      // awaitRefetchQueries: true,
      onCompleted(data) {
        if (data?.updateInvoice?.success) {
          setCompleted(true);
          setCurrentInvoice({} as Invoice);
          const inv = data?.updateInvoice?.invoice
            ? {
                ...data?.updateInvoice?.invoice,
                items: includeTotalCost(data?.updateInvoice?.invoice?.items),
              }
            : undefined;
          setNewInvoice(inv);
          showToast('Invoice Successfully marked', 'success');
          // fetchInventoryInvoices();
        }
      },
    });
  };

  return (
    <>
      {!completed && !newInvoice && (
        <ViewWrapper goBack={goBack}>
          <Column width="100%" height="100%" gap={0.5}>
            <Row justify="space-between">
              <H6>
                <span>Invoice </span>
                {currentInvoice?.invoiceId && (
                  <span data-testid="invoice-id">
                    {` : ${currentInvoice?.invoiceId}`}
                  </span>
                )}
              </H6>
              <Pill data-testid="invoice-status" color="red" outlined>
                Unpaid
              </Pill>
            </Row>
            {currentInvoice && (
              <Row justify="space-between">
                <SummaryInfo
                  label="INVOICE ID"
                  value={currentInvoice?.invoiceId || ''}
                />
                <SummaryInfo
                  label="Date Generated"
                  value={moment(currentInvoice?.createdAt || '').format(
                    'h:mm a, MMM. D, YYYY',
                  )}
                />
              </Row>
            )}
            {patient?.hmo && (
              <Row justify="space-between">
                <SummaryInfo
                  label="HMO Provider"
                  value={patient?.hmo?.hmo?.hmo?.name || ''}
                />
                <SummaryInfo
                  label="HMO ID"
                  value={patient?.hmo?.hmo?.hmo?.hmoId || ''}
                />
              </Row>
            )}
            <SummaryTable currentInvoice={currentInvoice} />
            <Select
              label="Choose Payment Mode"
              placeholder="Choose Method"
              name="paymentMethod"
              data-testid="payment-method"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setCurrentInvoice((prev) => ({
                  ...prev,
                  paymentMethod: e.target.value as PaymentMethodType,
                }))}
            >
              {paymentOptions.map(({ label, value }, idx) => (
                <option key={idx} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            <Button
              data-testid="confirm-paid"
              isLoading={markInvoiceLoading}
              width="max-content"
              onClick={handleSubmit}
            >
              Confirm Invoice
            </Button>
          </Column>
        </ViewWrapper>
      )}
      {completed && (
        <ConfirmationPrompt
          title="Invoice Payment Confirmed"
          message="You have successfully marked this invoice as paid"
          onConfirm={goToInvoice}
          confirmText="View Invoice"
          dismissText="Close"
        />
      )}
    </>
  );
};

export default InvoiceConfirmation;
