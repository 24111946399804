import React from 'react';
import { BaseFlexProps } from 'src/types';
import { AvatarGroupContent } from './styled';

export const AvatarGroup: React.FC<BaseFlexProps> = ({
  children,
  ...props
}) => {
  return (
    <AvatarGroupContent align="center" justify="center" {...props}>
      {children}
    </AvatarGroupContent>
  );
};
