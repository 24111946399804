import _ from 'lodash';
import { useEffect, useState } from 'react';
import { usePatientDetails } from 'src/pages/admin/PatientDetails/hooks/usePatientDetails';
import { useAppointments, useToast } from 'src/state';
import {
  addStep,
  addJourneyStep,
  useAppDispatch,
  usePatientJourney,
  endPatientJourney,
  useSteps,
} from 'src/store';
import { CreateStepTemplate } from 'src/types';
import {
  getModalParams,
  openModal,
  removeEmptyValues,
  removeModalHash,
} from 'src/utils';
import { useLocation } from 'wouter';

export type ParameterType = {
  displayValue: string;
  type: string;
  selectValue: string;
  description: string;
  required: boolean;
}[];

interface Step extends CreateStepTemplate {
  id: string;
}

const templateToStatusMap: Record<string, string> = {
  'CHECK-IN': 'CHECKED_IN',
  ADMIT: 'ADMITTED',
  // CHECKOUT: 'INACTIVE',
};

const getParameters = (template: Step) => {
  return template?.parameters.map((param) => ({
    displayValue: '',
    selectValue: '',
    type: param.type,
    description: param.title,
    required: param.entryType === 'Required',
  }));
};

export const useFormControls = () => {
  const template = getModalParams('template');
  const [location, setLocation] = useLocation();
  const { showToast } = useToast();
  const pId = getModalParams('patientId')?.split('?')[0];
  const {
    patientId,
    patientJourney,
    addStepToJourney,
    findStepTemplate,
    findStepTemplateError,
    getPatientOpenJourney,
    findStepTemplateLoading,
  } = usePatientJourney();

  const { updatePatientStatus, updatePatientCache } = usePatientDetails(
    patientId || pId,
  );
  const { fetchAllSteps } = useSteps();
  const appointmentId = getModalParams("appointmentId");
  const date = new Date();
  const { useCheckInAppointment } = useAppointments();
  const { checkInAppointment: checkIn, loading: checkInLoading } =
    useCheckInAppointment();


  const [search, setSearch] = useState('');
  const [step, setStep] = useState<Step>();
  const [parameters, setParameters] = useState<ParameterType>();
  const [validationErrors, setValidationErrors] = useState<string[]>(
    new Array(parameters?.length),
  );
  const [loadingTemplate, setLoadingTemplate] = useState(
    findStepTemplateLoading,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchTemplate = async () => {
      setSearch(template);
      const stepTemplate = await findStepTemplate(template);
      setParameters(getParameters(stepTemplate));
      setStep(stepTemplate);
    };
    if (!step && template) {
      fetchTemplate();
    }
  }, [template, findStepTemplate, step]);

  const handleSearchSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    d?: Record<string, unknown>,
  ) => {
    const data = d as unknown as Step;
    setStep(data);
    setParameters(getParameters(data));
    setSearch(data.name);
    // if (location === "/admin/in-patient") {
    //   openModal("step-info", data.id, `&patientId=${patientId}&template=${data.name}`)
    // }
    // setValidationErrors(data.parameters.map(__ => ''))
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchBlur = () => {
    if (!step || step.name !== search) {
      setStep(undefined);
      setParameters(undefined);
      setSearch('');
    }
  };

  const clearValidationError = (idx: number, value: string) => {
    const newValidationErrors = [...validationErrors];
    newValidationErrors[idx] = value.length ? '' : 'This field is required';
    setValidationErrors(newValidationErrors);
  };

  const onParamChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    idx?: number,
  ) => {
    if (parameters) {
      const edited = parameters.map((param, id) => {
        if (id === idx) {
          param.displayValue = e.target.value;
          param.selectValue = '';
        }
        return param;
      });
      clearValidationError(idx as number, edited[idx as number].displayValue);
      setParameters(edited);
    }
  };

  const onParamSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    d?: Record<string, unknown>,
    idx?: number,
  ) => {
    if (parameters) {
      const edited = parameters.map((param, id) => {
        if (id === idx) {
          param.displayValue =
            param.type === 'Staff'
              ? `${_.get(d, 'user.firstName')} ${_.get(d, 'user.lastName')}`
              : (_.get(d, 'name') as string);
          param.selectValue = _.get(d, 'id') as string;
        }
        return param;
      });
      clearValidationError(idx as number, edited[idx as number].displayValue);
      setParameters(edited);
    }
  };

  const onParamBlur = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx?: number,
  ) => {
    if (parameters) {
      const val = parameters[idx as number];
      if (!val.selectValue.length) {
        const edited = parameters.map((param, id) => {
          if (id === idx) {
            param.displayValue = '';
            param.selectValue = '';
          }
          return param;
        });
        setParameters(edited);
      }
    }
  };

  const onAddItem = () => {
    const newValidationErrors = parameters?.map(
      ({ type, displayValue, selectValue, required }) => {
        if (
          ((type === 'Staff' && !selectValue) ||
            (type !== 'Staff' && !displayValue)) &&
          required
        ) {
          return 'This field is required';
        }
        return '';
      },
    ) as string[];
    setValidationErrors(newValidationErrors);
    if (!newValidationErrors.some((error) => error !== '')) {
      setLoading(true);
      const filterParameters = parameters?.map((param) =>
        removeEmptyValues({
          value:
            param.type !== 'Staff' ? param.displayValue : param.selectValue,
          description: param.description,
          type: param.type,
        }),
      );

      if (
        template &&
        template === 'CHECKOUT' &&
        step?.parameters?.length === 0
      ) {
        setLoadingTemplate(true);
        addStepToJourney({
          variables: {
            input: {
              patient: patientId || pId,
              stepTemplate: step?.id,
            },
          },
          async onCompleted(d) {

            if (d) {
              dispatch(
                addJourneyStep({
                  patientId: patientId || pId,
                  step: d?.addStep?.step,
                }),
              );
              dispatch(addStep({ step: d?.addStep?.step }));
              setLoadingTemplate(false);

              if (location === '/admin/in-patients' || location === '/calendar') {
                openModal(
                  'step-info',
                  d?.addStep?.step?.id,
                  `&patientId=${d?.addStep?.step.patient.id}`,
                );
              }
              return setLocation(
                `#journey&mView=step&stepId=${d?.addStep?.step.id}`,
              );
            }
          },
          onError() {
            setLoadingTemplate(false);
          },
        });
        return;
      }

      addStepToJourney({
        variables: {
          input: {
            patient: patientId || pId,
            stepTemplate: step?.id,
            parameters: filterParameters,
          },
        },
        async onCompleted(d) {
          if (template) {
            if (template === 'CHECKOUT') {
              if (step?.actions.length === 0) {
                try {
                  await updatePatientStatus('CHECKED_OUT');
                  await fetchAllSteps();
                  dispatch(endPatientJourney({ patientId: patientId || pId }));
                  removeModalHash();
                } catch {
                  return undefined;
                }
              }
            } else {
              updatePatientCache({ status: templateToStatusMap[template] });
            }
          }
          if (d?.addStep) {
            if (!patientJourney) {
              await getPatientOpenJourney();
              dispatch(addStep({ step: d?.addStep?.step }));
              if (templateToStatusMap[step?.name as string]) {
                updatePatientCache({
                  status: templateToStatusMap[step?.name as string],
                });
              }
            } else {
              dispatch(
                addJourneyStep({
                  patientId: patientId || pId,
                  step: d?.addStep?.step,
                }),
              );
              dispatch(addStep({ step: d?.addStep?.step }));
              if (templateToStatusMap[step?.name as string]) {
                updatePatientCache({
                  status: templateToStatusMap[step?.name as string],
                });
              }
            }
            setLoading(false);
            setLoadingTemplate(false);
            if (location === '/admin/in-patients' || location === '/calendar') {
              if (appointmentId) {
                await checkIn({
                  appointmentId,
                  date,
                })?.then((res) => {
                  if (res) {
                    openModal(
                      'step-info',
                      d?.addStep?.step?.id,
                      `&patientId=${d?.addStep?.step.patient.id}`,
                    );
                  }
                });
              }

              openModal(
                'step-info',
                d?.addStep?.step?.id,
                `&patientId=${d?.addStep?.step.patient.id}`,
              );
            } else {
              setLocation(`#journey&mView=step&stepId=${d?.addStep?.step.id}`);
            }
            if (!appointmentId) {
              showToast('Step add successfully', 'success');
            }
          } else {
            setLoading(false);
          }
        },
        onError() {
          setLoading(false);
        },
      });
    }
  };

  return {
    step,
    search,
    loading,
    template,
    checkInLoading,
    onAddItem,
    parameters,
    setLoading,
    onParamBlur,
    onParamChange,
    setParameters,
    onParamSelect,
    loadingTemplate,
    validationErrors,
    findStepTemplate,
    handleSearchBlur,
    handleSearchChange,
    handleSearchSelect,
    setValidationErrors,
    findStepTemplateError,
    getParameters,
    setStep,
    setSearch,
  };
};
