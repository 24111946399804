import React from 'react';
import { GetStaffGroups } from 'src/types';

export type StaffGroupsContextType = {
  staffGroups: GetStaffGroups;
  setStaffGroups: (value: GetStaffGroups) => void;
};

const StaffGroupContext = React.createContext({} as StaffGroupsContextType);

export default StaffGroupContext;
