import styled from 'styled-components';
import { theme, font } from 'src/utils';
import { Button } from '../Button';
import { Row } from '../Flex';

export const GoButton = styled(Button)`
  gap: 0.62rem;
  font-size: ${font.sizes.sm};
  color: ${theme.primary[700]};
  font-weight: ${font.weights.semibold};
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
  }
  ${Row} {
    border-radius: 50%;
    background-color: ${theme.primary[200]};
    path {
      fill: ${theme.primary[700]};
      stroke-width: 0;
      width: 1.5rem;
      height: 1.5rem;
      @media (max-width: 960px) {
        height: 1rem;
        width: 1rem;
      }
    }
  }
  @media print {
    display: none;
  }
`;
