import _ from "lodash";
import { Cell, Pie, PieChart, Label, Legend, Tooltip } from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { Margin } from "recharts/types/util/types";
import { CustomTooltipProps, Text } from "src/components";
import { font, theme } from "src/utils";


export interface PieChartProps {
  size?: number;
  innerRadius?: number;
  outerRadius?: number;
  paddingAngle?: number;
  data: Record<string, unknown>[];
  colors?: string[];
  text?: string | number;
  label?: string;
  dataKey: string;
  margin?: Margin;
  height?: number;
  nameKey?: string;
  legend?: boolean;
  toolTip?: boolean;
  colorKey?: string;
  isPieChart?: boolean;
  customToolTip?: React.FC<CustomTooltipProps>;
};

export interface ExtendedPayload extends Payload {
  [key: string]: unknown
}

export interface TooltipProps {
  payload: Payload[]
}


export const DonutChart: React.FC<PieChartProps> = ({ 
  data, 
  size = 250,
  innerRadius = 85,
  outerRadius = 105,
  isPieChart = false,
  legend = true,
  margin,
  toolTip = false,
  nameKey = "name",
  height,
  colors, 
  text, 
  paddingAngle = 2,
  label,
  dataKey = "value",
  colorKey = "color",
  customToolTip: CustomToolTip,
}) => {
  const zeroValue = [{value: 100}];
  const coordinates =  Math.ceil(size / 2);
  const hasPositiveValue = _.some(data, (item) => item?.[dataKey] as number > 0);


  const ToolTip: React.FC<TooltipProps> = ({ payload }) => {
    if (payload.length > 0) {
      const extendedPayload = payload?.[0]?.payload as ExtendedPayload;
      return (
        <Text 
          transform="capitalize" 
          modStyles={{ 
            my: 0, 
            bg: `${theme.grey[100]}`,
            px: 1, 
            py: 0.25, 
            br: 3,
            border: `1px solid ${extendedPayload[colorKey] || theme.grey[400]}` 
          }} 
          size="sm"
          weight="semibold" 
          color="#000000"
        >
          {`${extendedPayload[nameKey]}: `}
          <strong>{`${extendedPayload[dataKey]}`}</strong>
        </Text>
      )
    }
  
    return null;
  };

  return (
    <PieChart data={data} margin={margin} width={size} height={height || size}>
      {toolTip && hasPositiveValue && (
        <Tooltip
          content={CustomToolTip ? <CustomToolTip /> : (props) => {
            return <ToolTip payload={props.payload as Payload[]} />
          }}
        />
      )}
      {legend && hasPositiveValue && (
        <Legend 
          layout="vertical" 
          iconType="circle" 
          verticalAlign="bottom"
          alignmentBaseline="after-edge"
          wrapperStyle={{ position: 'absolute', bottom: 0, left: 0 }}
          iconSize={15}
          formatter={(_value, entry) => {
            const { payload } = entry;
            const extendedPayload = payload as unknown as ExtendedPayload;
            return (
              <Text 
                transform="capitalize" 
                modStyles={{ my: 0 }} 
                size="sm"
                weight="semibold" 
                color="#000000"
              >
                {`${extendedPayload[nameKey]} (${extendedPayload[dataKey]})`}
              </Text>
            )
          }}
        />
      )}
      <Pie
        data={hasPositiveValue ? data : zeroValue}
        name={nameKey}
        cx={coordinates}
        cy={coordinates}
        innerRadius={isPieChart ? 0 : innerRadius}
        outerRadius={outerRadius}
        paddingAngle={paddingAngle}
        dataKey={dataKey}
      >
        {text && !isPieChart && (
          <Label 
            value={text} 
            position={label ? "centerBottom" : "center"} 
            className='label-top' 
            fontSize="1.75rem"
            color={theme.black[100]} 
            fontWeight={font.weights.bold}
            dy={-5}
          />
        )}
        {label && !isPieChart && (
          <Label 
            value={label} 
            position={text ? "centerTop" : "center"}
            color={theme.grey[800]}
            className='label-bottom'
            dy={5}
          />
        )}
        {data.map((_entry, index) => (
          <Cell 
            key={`cell-${index}`} 
            fill={!hasPositiveValue ? theme.grey[400] : (data[index][colorKey] as string || colors?.[index % colors?.length])} 
          />
        ))}
      </Pie>
    </PieChart>
  );
};
