import { AuthAction, AuthStateType } from 'src/types';
import { getLocalStorage } from 'src/utils/storageHelper';

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

const userToken = getLocalStorage('token') || '';
const refreshToken = getLocalStorage('refreshToken') || '';

export const initialState: AuthStateType = {
  token: userToken || '',
  refreshToken: refreshToken || '',
};

const reducer = (
  state: AuthStateType = initialState,
  action: AuthAction,
): AuthStateType => {
  const current: AuthStateType = state;
  const currentToken = action.payload?.token || '';
  const currentRefreshToken = action.payload?.refreshToken || '';

  switch (action.type) {
    case LOG_IN:
      return {
        ...current,
        token: currentToken,
        refreshToken: currentRefreshToken,
      };

    case LOG_OUT:
      return { ...current, token: '', refreshToken: '' };

    default:
      return current;
  }
};

export default reducer;
