import { theme } from 'src/utils/variables';
import styled from 'styled-components';

export const Toggle = styled.input`
  appearance: none;
  width: 2.625rem;
  height: 1.375rem;
  display: inline-block;
  position: relative;
  border-radius: 0.9375rem;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #E5E5E5;
  transition: background-color ease 0.3s;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    width: 1.125rem;
    height: 1.125rem;
    background-color: ${theme.white[100]};
    left: 2px;
    top: 2px;
    border-radius: 50%;
    white-space: nowrap;
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  }
  &:checked {
    background-color: ${theme.secondary[700]};
    &:before {
      left: 1.375rem;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;
