import Skeleton from 'react-loading-skeleton';
import { H6, Row, Text } from 'src/components';
import { currencyformatter } from 'src/utils';
import { ColoredCard } from './styled';

interface Props {
  label: string;
  amount: number;
  loading?: boolean
}

const SettlementCard: React.FC<Props> = ({ label, amount, loading }) => {
  return (
    <ColoredCard>
      <Text weight="semibold">{label}</Text>
      {loading && (
        <Row><Skeleton inline width="19.75rem" height={30} /></Row>
      )}
      {!loading && (
        <H6 modStyles={{ my: 0 }}>{currencyformatter.format(amount)}</H6>
      )}
    </ColoredCard>
  );
};

export default SettlementCard;
