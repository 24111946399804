import { gql } from '@apollo/client';
import { 
  cardReportFieldsFragment,
  doughnutReportFieldsFragment, 
  lineChartReportFieldsFragment, 
  tableReportFieldsFragment, 
  verticalReportFieldsFragment 
} from '../Fragments/report';

export const PRODUCT_STATISTICS = gql`
  ${doughnutReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        productStatistics {
          ...DoughnutReportFieldData
        }
      }
    }
  }
`;


export const FINANCIAL_PERFORMANCE = gql`
  ${lineChartReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        financialPerformance {
          ...LineChartReportFieldData
        }
      }
    }
  }
`;


export const SALES_STATISTICS = gql`
  ${verticalReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        salesStatistics {
          ...VerticalReportFieldData
        }
      }
    }
  }
`;

export const MOST_SOLD_PRODUCTS = gql`
  ${tableReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        mostSoldProducts {
          ...TableReportFieldData
        }
      }
    }
  }
`;


export const PRODUCTS = gql`
  ${cardReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        products {
          ...CardReportFieldData
        }
      }
    }
  }
`;

export const TOTAL_BUYING_VALUE = gql`
  ${cardReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        totalBuyingValue {
          ...CardReportFieldData
        }
      }
    }
  }
`;

export const TOTAL_SELLING_VALUE = gql`
  ${lineChartReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        totalSellingValue {
          ...CardReportFieldData
        }
      }
    }
  }
`;

export const INVOICE_STATISTICS = gql`
  ${doughnutReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        invoiceStatistics {
          ...DoughnutReportFieldData
        }
      }
    }
  }
`;

export const TOTAL_SALES = gql`
  ${cardReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        totalSales {
          ...CardReportFieldData
        }
      }
    }
  }
`;

export const RETURN_AND_REFUND = gql`
  ${cardReportFieldsFragment}
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        returnAndRefund {
          ...CardReportFieldData
        }
      }
    }
  }
`;


export const INVOICE = gql`
  query Inventory($input: GetReportInput!) {
    getReport(input: $input) {
      inventory {
        invoice {
          ...CardReportFieldData
        }
      }
    }
  }
  ${cardReportFieldsFragment}
`;