import { OperationVariables, useQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import {
  GET_HMO_LIST,
  GET_INVOICES,
  GET_HMO,
  GET_HMOS,
  GET_HMO_PATIENTS,
  HMO_OVERVIEW,
  GET_SETTLEMENTS,
  GET_SETTLEMENT,
  GET_HMO_DEBT,
} from 'src/constants';
import {
  GetHMOInvoices,
  GetHMOPatients,
  HMOList,
  HMOOverview,
  HMOType,
  HMOsType,
  Invoice,
  Settlement,
  SettlementProps,
  GetSettlementProps,
  GetHMOType,
  getHMODebt,
} from 'src/types';
import _ from 'lodash';
import { useRoute } from 'wouter';
import { useApolloSearch } from 'src/hooks';
import { useStaff } from '../staff';
import HmosContext from './context';
import { formatData } from '../invoice';
import { useRowData } from '../rowData';

const useQueries = () => {
  const { hmos, setHmos, hmoList, setHmoList } = useContext(HmosContext);

  const [, params] = useRoute('/admin/hmo/:id');
  const hmoId = params?.id as string;
  const { hasPermission } = useStaff();
  const { updateRowData, getDataByKey } = useRowData();

  const useHMO = (skip?: boolean) => {
    const result = useQuery<GetHMOType>(GET_HMO, {
      errorPolicy: 'all',
      skip:
        skip ||
        !!currentHMO?.id ||
        !hasPermission("VIEW_HMO_DETAILS") ||
        !hmoId,
      variables: { getHmoId: hmoId },
      onCompleted: (data) => updateRowData('hmo', data.getHmo),
    });

    return { ...result };
  };

  const useHMOs = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();
    const defaultValue = { page: 1 };
    const isEqualValue = _.isEqual(defaultValue, value);
    const isEmptyValue = _.isEmpty(value);
    const isEmptySearchValue = _.isEmpty(searchValue);

    const result = useQuery<HMOsType>(GET_HMOS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_HMO_LIST"),
      variables: {
        input: {
          ...value,
          search: searchValue,
        },
      },
      onCompleted: (data) => {
        if ((isEqualValue || isEmptyValue) && isEmptySearchValue) {
          setHmos(data);
        }
      },
    });

    return { ...result, hmos, searchValue, ...others };
  };

  const usePatientHMOs = (value?: OperationVariables, skip?: boolean) => {
    const result = useQuery<GetHMOPatients>(GET_HMO_PATIENTS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_HMO_DETAILS") || !hmoId,
      variables: { input: { hmoId, ...value } },
    });

    return { ...result };
  };


  const useHMOList = (skip?: boolean) => {
    const { hmos: hmoData } = useHMOs({}, Boolean(hmos?.getHmos));
    const hmoListCount = hmoList?.length > 0;
    const result = useQuery<{ getHmoList: HMOList }>(GET_HMO_LIST, {
      errorPolicy: 'all',
      skip:
        skip || hmoListCount || !hasPermission("VIEW_HMO_LIST"),
    });

    useEffect(() => {
      if (result.data && hmoData?.getHmos) {
        const registeredHMOs = new Set(
          hmoData.getHmos.hmos.map((el) => el.name) || [],
        );
        const unRegisteredHMOs = result.data.getHmoList.filter(
          (el) => !registeredHMOs.has(el.name),
        );
        setHmoList(unRegisteredHMOs);
      }
    }, [result.data, hmoData?.getHmos]);

    return { ...result, hmoList };
  };

  const useInvoiceHMOs = (value?: OperationVariables, skip?: boolean) => {
    const result = useQuery<GetHMOInvoices>(GET_INVOICES, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_HMO_DETAILS") || !hmoId,
      variables: { input: { hmo: hmoId, ...value } },
    });

    const { data } = result;
    const invoices = data?.getInvoices?.invoices?.map((inv: Invoice) =>
      formatData(inv),
    );

    return {
      ...result,
      availableInvoices: { invoices, total: data?.getInvoices.total },
    };
  };

  const useHMOOverview = (value?: OperationVariables, skip?: boolean) => {
    const result = useQuery<HMOOverview>(HMO_OVERVIEW, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_HMO_DETAILS") || !hmoId,
      variables: { input: { hmoId, ...value } },
      onCompleted: (data) => updateRowData('hmo-overview', data),
    });

    return { ...result };
  };

  const useSettlements = (value?: OperationVariables, skip?: boolean) => {
    const result = useQuery<SettlementProps>(GET_SETTLEMENTS, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_HMO_DETAILS") || !hmoId,
      variables: { input: { hmoId, ...value } },
    });

    return { ...result };
  };

  const useSettlement = (id: string, skip?: boolean) => {
    const result = useQuery<GetSettlementProps>(GET_SETTLEMENT, {
      errorPolicy: 'all',
      skip:
        skip ||
        !!currentSettlement?.id ||
        !hasPermission("VIEW_HMO_DETAILS"),
      variables: { getSettlementId: id },
      onCompleted: (d) => updateRowData('settlement', d.getSettlement),
    });

    return { ...result };
  };

  const useHMODebt = (id?: string, skip?: boolean) => {
    const result = useQuery<getHMODebt>(GET_HMO_DEBT, {
      errorPolicy: 'all',
      skip: skip || !hasPermission("VIEW_HMO_DETAILS"),
      variables: { hmoId: hmoId || id },
      onCompleted: (d) => updateRowData('hmo-debt', d),
    });

    return { ...result };
  };

  const currentHMO = getDataByKey('hmo') as HMOType;
  const currentHMODebt = getDataByKey('hmo-debt') as getHMODebt;
  const currentHMOInvoice = getDataByKey('invoice') as Invoice;
  const currentSettlement = getDataByKey('settlement') as Settlement;

  return {
    currentHMO,
    currentHMOInvoice,
    currentHMODebt,
    currentSettlement,
    usePatientHMOs,
    useInvoiceHMOs,
    useHMOOverview,
    useSettlements,
    useHMOList,
    useHMO,
    useSettlement,
    useHMOs,
    useHMODebt,
    skipHMOs: !!hmos?.getHmos,
  };
};

export default useQueries;
