export const steps = [
  {
    selector: '.dashboard',
    title: `Admin's Dashboard`,
    content:
      'Monitor key performance indicators of the facility, including all operational aspects, patient statistics, staff management, and system performance metrics . Get real-time data and analytics to facilitate informed decision-making and ensure smooth, efficient management of resources and personnel.',
    permission: 'VIEW_ADMINS_DASHBOARD',
  },
  {
    selector: '.dashboard',
    title: 'Dashboard',
    content:
      'View all upcoming appointments and overview of all in-patients. Easily access patient records, review medical histories, and track ongoing treatments to ensure optimal patient care.',
    permission: 'VIEW_DOCTORS_DASHBOARD',
  },
  {
    selector: '.patients',
    title: 'Manage Patients',
    content:
      'Manage patient records, appointments, and journeys. Easily update patient information, track medical histories, and manage treatment plans.',
    permission: 'VIEW_PATIENT_LIST',
  },
  {
    selector: '.patients',
    title: 'In-Patients',
    content:
      'Get a comprehensive overview of all patient journeys, track the progress of each journey and see the staff groups assigned to each case, along with specific tasks assigned to you.',
    permission: 'VIEW_IN_PATIENTS_AND_THEIR_JOURNEY',
  },
  {
    selector: '.calendar',
    title: 'Calendar',
    content:
      'Easily schedule new appointments, view all appointments and manage recurring events. Stay on top of your schedule with personal reminders and avoid scheduling conflicts to ensure smooth operations.',
    permission: 'VIEW_CALENDAR',
  },
  {
    selector: '.more',
    title: 'Inventory',
    content:
      'Streamline the tracking and replenishment of medical supplies and equipment. Easily monitor stock levels, track expiry dates, and manage orders with automated alerts for low stock and expiring items.',
    permission: 'VIEW_INVENTORY_LIST',
  },
  {
    selector: '.more',
    title: 'Reports',
    content:
      'Stay updated on key metrics and performance indicators. Administrators and staff can generate, customize, and analyze various reports. From financial summaries to patient care metrics, this provides comprehensive insights into the facility’s operations.',
    permission: 'VIEW_REPORTS',
  },
  {
    selector: '.more',
    title: 'Services',
    content:
      'Define and organize the various healthcare services offered by the facility. Set up service categories, pricing, and insurance availability to ensure patients can access the care they need.',
    permission: 'VIEW_SERVICE_LIST',
  },
];
