import { useSearchParams } from 'src/hooks/useSearchParams';
import { View, Views } from 'react-big-calendar';
import { ArrowBackIcon, ArrowForwardIcon } from 'src/assets/icons';
import { Row, Button, Dropdown } from 'src/components';
import { useStaff, useFacilities } from 'src/state';
import moment, { DurationInputArg2 } from 'moment';
import { CURRENT_DATE } from 'src/constants';
import { theme, openModal } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import { DateRangePicker } from './dateRangePicker';
import {
  WeeklyDateRow,
  CalendarViewSwitch,
  CalendarIconButton,
} from '../styled';

export const CalendarToolBar = () => {
  const dateFromat = `YYYY-MM-DD`;
  const { useFacilities: useFacilitiesData } = useFacilities();
  const { initialFacilities } = useFacilitiesData();
  const { staff,  hasPermission  } = useStaff();
  const staffGroup = staff.getStaff.staffGroup.name;
  const isDoctor = staffGroup?.toLowerCase() === 'doctor';
  const isOwner = staffGroup?.toLowerCase() === 'owner';
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const { params, setModalParams } = useSearchParams();
  const dateParams = params.get("date") || CURRENT_DATE;
  const view = (params.get('view') || Views.DAY) as View;
  const idx = params.get('idx') as string;
  const facility = params.get('facility') || staff.getStaff.facility.id;

  const facilities = initialFacilities?.searchFacilities?.facilities;
  const title = facilities?.find((item) => item.id === facility);

  const handleDropdownChange = (_name: string, value: string) => {
    setModalParams({ facility: value });
  };

  return (
    <Row
      justify="space-between"
      align="center"
      height="max-content"
      width="100%"
      gap={1}
    >
      <Row width="100%" align="center" gap={1}>
        <Row
          justify={isMobile ? 'space-between' : 'flex-start'}
          align="center"
          width="max-content"
          gap={0.25}
        >
          <CalendarIconButton
            type="button"
            background={theme.grey[100]}
            data-testid="calendar-previous-button"
            onClick={() => {
              const date = moment(dateParams)
                .subtract(1, view as DurationInputArg2)
                .format(dateFromat);

              setModalParams({ date, idx: undefined });
            }}
          >
            <ArrowBackIcon />
          </CalendarIconButton>
          <DateRangePicker isDoctor={isDoctor} />
          <CalendarIconButton
            type="button"
            background={theme.grey[100]}
            data-testid="calendar-next-button"
            onClick={() => {
              const date = moment(dateParams)
                .add(1, view as DurationInputArg2)
                .format(dateFromat);

              setModalParams({ date, idx: undefined });
            }}
          >
            <ArrowForwardIcon />
          </CalendarIconButton>
        </Row>
        {!isDoctor && !isMobile && view === Views.WEEK && (
          <WeeklyDateRow gap={0.5}>
            {Array(7)
              .fill(0)
              .map((_i, index) => {
                const isWeekOrDay = idx ? 'd' : 'w';
                const weekStartDate = moment(dateParams).subtract(idx, 'd');
                const getCurrentDate = moment(weekStartDate)
                  .startOf(isWeekOrDay)
                  .add(`${index}`, 'd')
                  .format(dateFromat);
                const isCurrent = dateParams === getCurrentDate;

                return (
                  <Button
                    type="button"
                    key={index}
                    data-testid={`week-button-${index}`}
                    width="max-content"
                    className={!isCurrent ? 'disabled' : 'enabled'}
                    onClick={() => {
                      setModalParams({
                        date: getCurrentDate,
                        idx: index as unknown as string,
                      });
                    }}
                  >
                    {moment(weekStartDate)
                      .startOf(isWeekOrDay)
                      .add(`${index}`, 'd')
                      .format('ddd[.] D')}
                  </Button>
                );
              })}
          </WeeklyDateRow>
        )}
      </Row>
      {!isMobile && (
        <Row justify="flex-end" align="center" width="70%" gap={1}>
          <CalendarViewSwitch
            view={view}
            gap={1}
            align="center"
            justify="center"
          >
            <Button onClick={() => setModalParams({ view: Views.DAY, idx: undefined })}>
              Day
            </Button>
            <Button onClick={() => setModalParams({ view: Views.WEEK })}>
              Week
            </Button>
          </CalendarViewSwitch>

          {hasPermission('CREATE_APPOINTMENT') && !isOwner && (
            <Button
              data-testid="appointment-button-1"
              type="button"
              width="max-content"
              onClick={() =>  openModal('add-appointment')}
            >
              Create Appointment
            </Button>
          )}
          {isOwner && (
            <Dropdown
              title={title?.state || 'Facility'}
              width="max-content"
              name="facility"
              handleDropdownChange={handleDropdownChange}
              options={facilities?.map((v: { id: string; state: string }) => ({
                value: v?.id,
                label: v?.state,
                checked: facility === v?.id,
              }))}
            />
          )}
        </Row>
      )}
    </Row>
  );
};
