import {
  Button,
  Column,
  ConfirmationPrompt,
  H6,
  Row,
  Text,
} from 'src/components';
import { useFormik } from 'formik';
import Validator from 'validatorjs';
import {
  strictProductValidation,
  removeModalHash,
  theme,
  strictBatchValidation,
  filterErrors,
} from 'src/utils';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { SolidCheck, CardPlusIcon, AlertIcon } from 'src/assets/icons';
import { useInventoryApis } from 'src/store';
import { ProductBatchType, ProductDetailsType } from 'src/types';
import { GET_PRODUCTS } from 'src/constants';
import { useToast } from 'src/state';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'wouter';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { InventoryModal } from '../styled';
import ProductForm from '../components/ProductForm';
import BatchForm from '../components/BatchForm';

const defaultState: {
  details: ProductDetailsType;
  batch: ProductBatchType;
  isSubmit?: boolean;
} = {
  details: {
    name: '',
    extraInfo: '',
    categories: [],
    brandName: '',
    form: '',
    isOTC: false,
  },
  batch: {
    batchNumber: '',
    quantity: 0,
    buyingPrice: 0,
    sellingPrice: 0,
    expiryAlert: '',
    stockAlert: 0,
    expiryDate: '',
    status: ['IN_STOCK'],
    pushToShelf: false,
  },
};
const AddProduct = () => {
  const { addProduct, addProductLoading, saveProduct, saveProductLoading } =
    useInventoryApis();
  const [duplicateId, setDuplicateId] = useState('');
  const [productValidation, setProductValidation] = useState<
    Record<string, string>
  >({ ...strictProductValidation, ...strictBatchValidation });
  const formRef = useRef<HTMLDivElement>(null);

  const onDuplicateFound = (productId: string) => [
    setDuplicateId(productId as string),
  ];

  const { showToast } = useToast();
  const [, setLocation] = useLocation();
  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    setErrors,
  } = useFormik({
    initialValues: { ...defaultState },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(validationValues, productValidation);
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: async (value, { resetForm }) => {
      if (value.isSubmit) {
        await addProduct({
          variables: {
            input: {
              products: [
                {
                  ...values.details,
                  batches: [_.pickBy(value.batch, _.identity)],
                },
              ],
            },
          },
          refetchQueries: [GET_PRODUCTS],
          awaitRefetchQueries: true,
          onCompleted(d) {
            if (d?.addProduct) {
              showToast(d.addProduct?.message, 'success');
              removeModalHash();
            }
          },
        });
        return value;
      }
      await saveProduct({
        variables: {
          input: {
            products: [
              {
                ...values.details,
                batches: [_.pickBy(value.batch, _.identity)],
              },
            ],
          },
        },
        refetchQueries: [GET_PRODUCTS],
        awaitRefetchQueries: true,
        onCompleted(d) {
          if (d?.addProduct) {
            showToast(d.addProduct?.message, 'success');
            resetForm();
            formRef?.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }
        },
      });
      return value;
    },
  });

  useEffect(() => {
    if (values.batch.status[0] === 'ORDER_PLACED') {
      setErrors({ ...filterErrors(errors, 'batch.') });
      setProductValidation({ ...strictProductValidation });
    } else {
      setProductValidation({
        ...strictProductValidation,
        ...strictBatchValidation,
      });
    }
  }, [errors, setErrors, values.batch.status]);

  return (
    <InventoryModal>
      {!duplicateId ? (
        <Column width="100%" height="100%" minHeight="70vh" gap={1.5}>
          <Column gap={0.3}>
            <H6 modStyles={{ ma: 0 }}>Add Product</H6>
            <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
              Add a new product to the inventory
            </Text>
          </Column>
          <FullWidthForm onSubmit={handleSubmit}>
            <Column gap={0.5} ref={formRef}>
              <ProductForm
                values={values.details}
                setFieldValue={setFieldValue}
                errors={errors}
                handleChange={handleChange}
                onDuplicateFound={onDuplicateFound}
              />
              <BatchForm
                values={values.batch}
                setFieldValue={setFieldValue}
                errors={errors}
                handleChange={handleChange}
              />
              <Row justify="space-between">
                <Button
                  background={theme.grey[100]}
                  data-testid="save-more-product-btn"
                  width="max-content"
                  color={theme.grey[600]}
                  type="submit"
                  disabled={addProductLoading || saveProductLoading}
                  isLoading={saveProductLoading}
                  onClick={() => {
                    mixpanel.track(
                      `Click on 'Save & Add More Product' Button`,
                      { feature: 'Inventory' },
                    );
                    setFieldValue('isSubmit', false);
                  }}
                >
                  <CardPlusIcon color={theme.grey[600]} /> Save & Add More
                  Product
                </Button>
                <Button
                  variant="solid"
                  width="max-content"
                  type="submit"
                  data-testid="submit-product-btn"
                  disabled={addProductLoading || saveProductLoading}
                  isLoading={addProductLoading}
                  onClick={() => {
                    mixpanel.track(`Click on 'Save Product' Button`, {
                      feature: 'Inventory',
                    });
                    setFieldValue('isSubmit', true);
                  }}
                  noIconStyles
                >
                  <SolidCheck /> Save Product
                </Button>
              </Row>
            </Column>
          </FullWidthForm>
        </Column>
      ) : (
        <ConfirmationPrompt
          title="Product Exists"
          message=" This Product already exists, do you want to add as a batch or discard?"
          icon={<AlertIcon />}
          onConfirm={() => {
            removeModalHash();
            setLocation(`#product&id=${duplicateId}&mView=add-batch`);
          }}
          onDismiss={() => setDuplicateId('')}
          confirmText="Add"
          dismissText="Discard"
        />
      )}
    </InventoryModal>
  );
};

export default AddProduct;
