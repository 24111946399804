import { Column, H3, Row, Text } from 'src/components';
import { theme } from 'src/utils/variables';
import styled from 'styled-components';

export const FieldTitle = styled(Text)`
  display: flex;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.25rem;
  color: ${theme.black[100]};
`;

export const TableField = styled('table')`
  width: max-content;
  text-transform: capitalize;
  & th,
  td {
    text-align: start;
    padding-left: 1rem;
    &:first-child {
      padding-left: 0;
    }
  }
`;

export const TableFieldHead = styled('thead')`
  text-align: left;
  color: ${theme.grey[400]};
`;

export const RecordTitle = styled(H3)`
  width: 100%;
`;

export const RecordWrapper = styled(Column)`
  height: 100%;
  overflow-y: auto;
  padding: 1.25rem;
`;

export const ToolbarWrapper = styled(Row)`
  & button {
    margin-right: 0.625rem;
    font-weight: 400;
  }
  & svg {
    stroke: unset;
    & path {
      stroke: unset;
    }
  }
  @media (max-width: 600px) {
    font-size: 0.75rem;
    margin-bottom: 1rem;
    & svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  @media print {
    display: none;
  }
`;
