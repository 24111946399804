import { LeftSideArrow } from 'src/assets/icons';
import { Column, Text } from 'src/components';
import { theme } from 'src/utils/variables';
import { RoundButton, ViewHeader } from './styled';

const ViewWrapper: React.FC<{ goBack?: () => void }> = ({
  goBack,
  children,
}) => {
  return (
    <Column gap={1}>
      {goBack && (
        <ViewHeader gap={0.625} align="center">
          <RoundButton
            color="black"
            data-testid="back-btn"
            background={theme.primary[100]}
            onClick={goBack}
          >
            <LeftSideArrow />
          </RoundButton>
          <Text weight="semibold">Back</Text>
        </ViewHeader>
      )}
      {children}
    </Column>
  );
};

export default ViewWrapper;
