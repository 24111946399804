import { Column } from 'src/components';
import { useAlert, useAppointments, usePatients, useRowData, useToast } from 'src/state';
import moment from 'moment';
import {
  removeModalHash,
  getModalParams,
  getURLParams,
  openModal,
} from 'src/utils';
import { APPLY_TO } from 'src/constants';
import { Appointments } from 'src/types';
import { AppointmentModal, Divider } from './styled';
import AppointmentDetails from './appointment-details';
import AppointmentActions from './appointment-actions';


export interface AppointmentActionsType extends Pick<Appointments, "createdBy" | "status"> {
  loading: boolean;
  isExpired: boolean;
  patientLoading: boolean;
  checkOutLoading: boolean;
  handleCancel: VoidFunction;
  handleCheckIn: VoidFunction;
  appointmentLoading: boolean;
  handleCheckOut: VoidFunction;
  cancelAppointmentLoading: boolean;
}

export interface AppointmentDetailsType extends Pick<Appointments, "type" | "patient" | "provider" | "recurrenceRule" | "visitTime" | 'status'> {
  isExpired: boolean;
  eventDate: string;
  appointmentLoading: boolean;
}

const ViewAppointment = () => {
  const { showToast } = useToast()
  const { getDataByKey } = useRowData()
  const id = getModalParams('id') as string;
  const { useAppointment } = useAppointments()
  const date = getURLParams('date') as string || new Date()
  const currentAppointment = (getDataByKey('appointment') || {}) as unknown as Appointments;
  const currentAppointmentId = !!currentAppointment?.id


  const { loading: appointmentLoading, data, error } = useAppointment({
    input: id,
    date
  }, currentAppointmentId)

  const { usePatient } = usePatients()
  const { showAlert, removeAlert } = useAlert();
  const appointment = (data?.getAppointment?.[0] || currentAppointment || {}) as Appointments;

  const eventDate = moment(date).format('MMMM DD, yyyy');
  const isExpired = moment(eventDate).isBefore( moment().toISOString(), 'day');

  const { useCancelAppointment, useCheckInAppointment, useCheckOutAppointment } = useAppointments();

  const { checkInAppointment: checkIn, loading } = useCheckInAppointment();
  const { cancelAppointment, loading: cancelAppointmentLoading } = useCancelAppointment();
  const { checkOutAppointment: checkOut, loading: checkOutLoading } = useCheckOutAppointment();
  
  
  const patientId = appointment?.patient?.id;
  const isSameDay = moment().isSame(date, 'day');
  const { data: patient, loading: patientLoading } = usePatient(patientId, !patientId || !isSameDay);

  const patientStatus = patient?.getPatient?.status;

  const handleCheckIn = async () => {
    if (!isSameDay) {
      return showToast("You can only check in on appointment day", 'warning')
    }

    if (patientStatus === "CHECKED_IN" || patientStatus === "ADMITTED") {
      return checkIn({
        appointmentId: id,
        date
      })?.then((res) => {
        if (res.data) removeModalHash()
      })
    }

    return openModal('step-info', "", `&patientId=${patientId}&appointmentId=${id}&view=newStep&template=CHECK-IN`);
  };



  const handleCheckOut = async () => {
    await checkOut({
      appointmentId: id,
      date
    })?.then((res) => {
      if (res.data) removeModalHash()
    });
  };

  
  const cancelAppointmentOperation = async (value?: string) => {
    await cancelAppointment({
      appointmentId: id,
      date,
      applyTo: value
    })?.then((res) => {
      if (res.data) {
        removeModalHash()
        removeAlert()
      }
    })
  }
  

  const handleCancel = async () => {
    const { isRecurringException, recurrenceRule } = appointment || {}
    if (recurrenceRule && !isRecurringException) {
      return showAlert({
        width: '29.125rem',
        hasCancelBtn: true,
        title: "Cancel Appointment",
        type: "decision",
        message: "Do you want to cancel this appointment or all recurring appointments?",
        confirmText: 'This Appointment',
        closeText: 'All Appointments',
        confirm: () => cancelAppointmentOperation(APPLY_TO.This),
        remove: () => cancelAppointmentOperation(APPLY_TO.ThisAndFuture)
      })
    }
    
    return cancelAppointmentOperation()
  };


  if (error) {
    return <></>;
  }

  const appointmentDetailsProps: AppointmentDetailsType = {
    ...appointment, 
    appointmentLoading, 
    eventDate, 
    isExpired
  }

  const appointmentActionProps: AppointmentActionsType = {
    ...appointment, 
    checkOutLoading, 
    loading, 
    patientLoading,
    cancelAppointmentLoading, 
    appointmentLoading, 
    isExpired, 
    handleCancel, 
    handleCheckOut, 
    handleCheckIn
  }

  return (
    <AppointmentModal>
      <Column>
        <AppointmentDetails {...appointmentDetailsProps} />
        <Divider />
        <AppointmentActions {...appointmentActionProps} />
      </Column>
    </AppointmentModal>
  );
};

export default ViewAppointment;
