import { useQuery } from '@apollo/client';
import * as React from 'react';
import { GET_STAFF } from 'src/constants';
import { PageLoader } from 'src/components';
import { Permissions, StaffProps } from 'src/types';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../auth';
import StaffContext from './context';

const StaffProvider: React.FC = ({ children }) => {
  const [staff, setStaff] = React.useState<StaffProps>({} as StaffProps);
  const { state } = useAuth();
  const { loading } = useQuery<StaffProps>(GET_STAFF, {
    skip: !state.token,
    onCompleted: (data) => {
      const { permissions, staffGroup } = data.getStaff;

      setStaff((prev) => ({
        ...prev,
        getStaff: {
          ...data.getStaff,
          permissions: _.uniqBy(
            [...permissions, ...staffGroup.permissions],
            'id',
          ),
        },
      }));
      mixpanel.identify(data.getStaff.user.id);
      mixpanel.people.set({
        $name: `${data.getStaff.user.firstName} ${data.getStaff.user.lastName}`,
        $email: data.getStaff.user.emails[0],
        role: data.getStaff.staffGroup.name,
        organization_id: data.getStaff.facility.organisation.id,
        organization: data.getStaff.facility.organisation.name,
        facility_id: data.getStaff.facility.id,
        facility: data.getStaff.facility.address,
      });
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  const permissions = staff?.getStaff?.permissions?.map((p) => p.name) || [];

  return (
    <StaffContext.Provider
      value={{
        staff,
        setStaff,
        staffPermissions: permissions,
        hasPermission: (value: Permissions) => permissions?.includes(value),
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};

export default StaffProvider;
