import { gql } from '@apollo/client';
import { hmoFieldsFragment } from '../Fragments/hmo';


export const ADD_MULTIPLE_HMO_PROVIDER = gql`
  mutation AddMultipleHmoProvider($input: AddMultipleHmosInput!) {
    addMultipleHmoProvider(input: $input) {
      success
      message
      hmos {
        ...HMOFields
      }
    }
  }
  ${hmoFieldsFragment}
`;

export const ADD_NEW_HMO_PROVIDER = gql`
  mutation AddHmoProvider($input: AddHmoInput!) {
    addHmoProvider(input: $input) {
      message
      success
      hmo {
        ...HMOFields
      }
    }
  }
  ${hmoFieldsFragment}
`;

export const UPDATE_HMO_PROVIDER = gql`
  mutation UpdateHmoProvider($input: UpdateHmoInput!) {
    updateHmoProvider(input: $input) {
      success
      message
      hmo {
        ...HMOFields
      }
    }
  }
  ${hmoFieldsFragment}
`;

export const DELETE_HMO_PROVIDER = gql`
  mutation DeleteHmoProvider($deleteHmoProviderId: ID!) {
    deleteHmoProvider(id: $deleteHmoProviderId) {
      success
      message
      hmo {
        ...HMOFields
      }
    }
  }
  ${hmoFieldsFragment}
`;
