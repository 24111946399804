import { theme, font } from 'src/utils/variables';
import { Text } from 'src/components/Typography';
import styled from 'styled-components';
import { Column, Row } from '../Flex';

export const SelectBase = styled.select<{ background?: string }>`
  width: 100%;
  border: none;
  background-color: ${({ background }) => background || theme.grey[100]};
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 0;
  outline: 0;
  font-size: ${font.sizes.sm};
  color: ${theme.grey[400]};
  font-weight: ${font.weights.bold};
  color: ${theme.grey[400]};
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.15rem;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

export const SelectButton = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: ${theme.grey[100]};
  padding: 0.25rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 0;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

export const MultiSelectContainer = styled(Column)`
  position: absolute;
  top: -84px;
  z-index: 9;
  background: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  padding: 15px 10px;
`;

export const Icon = styled.div`
  position: absolute;
  right: 0;
  margin: 0 0.75rem;
`;

export const SelectContainer = styled(Row)`
  position: relative;
`;

export const ErrorText = styled(Text)`
  position: absolute;
  z-index: 100;
  top: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 0.25rem;
  font-weight: ${font.weights.bold};
`;
