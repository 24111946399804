import { gql } from '@apollo/client';
import { stepFieldsFragment } from '../Fragments/step';

export const ADD_STEP_TO_JOURNEY = gql`
  mutation AddStep($input: AddStepInput!) {
    addStep(input: $input) {
      step {
        ...StepFields
      }
    }
  }
  ${stepFieldsFragment}
`;

export const UPDATE_STEP_ACTION_ITEM = gql`
  mutation UpdateStepActionItem(
    $input: UpdateStepActionItemInput!
    $stepActionItemId: String!
  ) {
    updateStepActionItem(input: $input, stepActionItemId: $stepActionItemId) {
      success
      message
      stepActionItem {
        step {
          id: _id
          updatedAt
          status
          actions {
            isCompleted
            metadata
          }
          eventLogs {
            staff {
              user {
                firstName
                lastName
                profileAvatar
              }
            }
            updatedAt
          }
        }
      }
    }
  }
`;

export const DELETE_STEP = gql`
  mutation DeleteStep($stepId: String!) {
    deleteStep(stepId: $stepId) {
      message
      success
    }
  }
`;
