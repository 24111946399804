/* eslint-disable react/no-danger */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React from 'react';
import { EditorPreviewContainer } from './styled';
import { ErrorText } from '../Input/styled';

interface Editor {
  name?: string;
  value: string;
  error?: string | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const TextEditor: React.FC<Editor> = ({
  onChange,
  name,
  value,
  error,
}) => {
  const editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        'insertTable',
        '|',
        'undo',
        'redo',
      ],
    },
    blockIndent: '2em',
  };

  return (
    <>
      <CKEditor
        id={name}
        editor={ClassicEditor}
        config={editorConfig}
        data={value}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          editor: { getData: () => string },
        ) => {
          if (onChange) {
            onChange(event, editor.getData());
          }
        }}
      />
      {Boolean(error) && (
        <ErrorText color="red" size="xs">
          {error}
        </ErrorText>
      )}
    </>
  );
};

export const TextEditorPreview: React.FC<{ value: string }> = ({ value }) => {
  return (
    <EditorPreviewContainer
      gap={0.5}
      modStyles={{ pa: 0.5 }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};
