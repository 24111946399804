import { OperationVariables, useMutation } from '@apollo/client';
import {
  ADD_NEW_PATIENT,
  UPDATE_PATIENT,
  GRANT_ACCESS_TO_PATIENT,
  GET_HMOS,
  GET_PATIENTS,
  SEARCH_PATIENTS,
  GET_TOTAL_NUMBER_OF_PATIENTS,
  GET_PATIENTS_STATISTICS,
  GET_PATIENT_DATA,
} from 'src/constants';
import { useRoute } from 'wouter';
import { useRowData, useStaff, useToast } from 'src/state';
import {
  AddPatientProps,
  GrantFacilityAccessProps,
  UpdatePatientProps,
} from 'src/types';

const useMutations = () => {
  const {  hasPermission  } = useStaff();
  const { showToast } = useToast();
  const { updateRowData } = useRowData();
  const [, params] = useRoute('/admin/patients/:id');
  const patientId = params?.id;

  const useAddPatient = () => {
    const [execute, result] = useMutation<AddPatientProps>(ADD_NEW_PATIENT);

    const addPatient = (value?: OperationVariables) => {
      if (!hasPermission("ADD_NEW_PATIENT")) return;
      return execute({
        variables: { input: value },
        refetchQueries: [
          GET_HMOS,
          GET_PATIENTS,
          SEARCH_PATIENTS,
          GET_TOTAL_NUMBER_OF_PATIENTS,
          GET_PATIENTS_STATISTICS,
        ],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, addPatient };
  };

  const useUpdatePatient = () => {
    const [execute, result] = useMutation<UpdatePatientProps>(UPDATE_PATIENT);

    const updatePatient = (value?: OperationVariables) => {
      if (!hasPermission("EDIT_PATIENT")) return;
      return execute({
        variables: { patientId, input: value },
        onCompleted: (d) => {
          showToast('Patient Information updated successfully', 'success');
          updateRowData('patient', d.updatePatient.patient);
        },
        refetchQueries: [
          GET_PATIENTS,
          GET_HMOS,
          SEARCH_PATIENTS,
          { query: GET_PATIENT_DATA, variables: { patientId } },
        ],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, updatePatient };
  };

  const useGrantAccess = () => {
    const [execute, result] = useMutation<GrantFacilityAccessProps>(
      GRANT_ACCESS_TO_PATIENT,
    );

    const grantAccess = (value?: OperationVariables) => {
      if (!hasPermission('ADD_NEW_PATIENT')) return;
      return execute({
        variables: { input: value },
        refetchQueries: [GET_PATIENTS, GET_HMOS, SEARCH_PATIENTS],
        onError: (err) => showToast(err.message, 'error'),
      });
    };

    return { ...result, execute, grantAccess };
  };

  return {
    useAddPatient,
    useGrantAccess,
    useUpdatePatient,
  };
};

export default useMutations;
