import { Input } from 'src/components';
import { getErrorMessage, theme } from 'src/utils';
import { DeleteIcon } from 'src/assets/icons';
import { Medications } from 'src/types';
import { FormikErrors } from 'formik';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import { TableCell } from 'src/pages/admin/PatientDetails/Invoice/styled';


export interface RowInputGroupType
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  arrIdx: number;
  deleteRow: (index: number) => null | undefined
  item:  Omit<Medications, 'prescribedBy' | '__typename'>;
  handleRowInputClick: (index: number) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors?: FormikErrors<Record<string, unknown>>
}

const RowInputGroup: React.FC<RowInputGroupType> = ({
  item,
  arrIdx,
  handleChange,
  handleRowInputClick,
  errors,
  deleteRow,
  ...props
}) => {

  
  const formErrors = errors as unknown as Record<string, string[]>
  const requiredField = "Field is required"

  return (
    <tr {...props} data-testid="row-input-group">
      <TableCell width="20%" modStyles={{ pr: 0.5 }}>
        <Input
          data-testid="drug-name"
          placeholder="Ibucap"
          name={`medications[${arrIdx}].drugName`}
          value={item.drugName}
          readOnly
          error={getErrorMessage(formErrors, 'drugName', `medications`, arrIdx) && requiredField}
          onClick={() => handleRowInputClick(arrIdx)}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell width="20%" modStyles={{ px: 0.5 }}>
        <Input
          data-testid="form"
          readOnly
          placeholder="Tablet"
          name={`medications[${arrIdx}].form`}
          value={item.form}
          error={getErrorMessage(formErrors, `form`, `medications`, arrIdx)}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell width="20%" modStyles={{ px: 0.5 }}>
        <Input
          data-testid="dosage"
          placeholder="5gm"
          name={`medications[${arrIdx}].dosage`}
          value={item.dosage}
          error={getErrorMessage(formErrors, `dosage`, `medications`, arrIdx) && requiredField}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell width="20%" modStyles={{ px: 0.5 }}>
        <Input
          data-testid="frequency"
          placeholder="1 daily"
          name={`medications[${arrIdx}].frequency`}
          value={item.frequency}
          error={getErrorMessage(formErrors, `frequency`, `medications`, arrIdx) && requiredField}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell width="20%" modStyles={{ px: 0.5 }}>
        <Input
          data-testid="duration"
          placeholder="2 weeks"
          name={`medications[${arrIdx}].duration`}
          value={item.duration}
          error={getErrorMessage(formErrors, `duration`, `medications`, arrIdx) && requiredField}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell modStyles={{ pl: 0.5 }}>
        <MultiRowDeleteButton
          variant="solid"
          data-testid="delete-medication-item"
          background={theme.grey[100]}
          color={theme.grey[200]}
          size="2.5rem"
          type="button"
          onClick={() => deleteRow(arrIdx)}
        >
          <DeleteIcon />
        </MultiRowDeleteButton>
      </TableCell>
    </tr>
  );
};

export default RowInputGroup;
