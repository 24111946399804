import { DropdownSearch, ErrorComponent, Row } from 'src/components';
import { FormRenderer } from 'src/containers';
import { useEffect, useState } from 'react';
import { useRoute } from 'wouter';
import { useNoteTemplate, useStaff } from 'src/state';
import RecordSuccessModal from 'src/pages/admin/PatientDetails/MedicalTimeline/Forms/SuccessModal';
import { openModal, removeModalHash } from 'src/utils';
import { CancelIcon } from 'src/assets/icons';
import {
  ModalChild,
  ModalChildContainer,
  ModalCloseButton,
  Overlay,
} from 'src/containers/Modal/styled';
import _ from 'lodash';
import { RecordTemplate } from 'src/types';
import { useTimeline } from 'src/store';
import { NotesTempModal } from './styled';

const NotesModal: React.FC = () => {

  const { staff } = useStaff();
  const [, params] = useRoute('/admin/patients/:id');
  const { 
    availableNoteTemplates,
    searchValue,
    searchLoading,
    useFetchNoteTemplates,
    hasNextPage,
    handleSearchChange,
    fetchNoteCallback,
    addNoteCallback,
    addNoteData,
    notesDropdown,
    resetNoteDropdown,
    loadMoreNote,
    refetchLoading,
  } = useNoteTemplate();

  const { noteResult } = useFetchNoteTemplates(notesDropdown?.total > 0);
  const { loading, error } = noteResult;

  const urlParams = new URLSearchParams(window.location.hash);
  const id = urlParams.get('id');
  const { fetchTimeline } = useTimeline();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recordTemplate = _.find(availableNoteTemplates, { id }) as RecordTemplate;


  useEffect(() => {
    if (id && !recordTemplate) {
      fetchNoteCallback(id)
    }
  }, [fetchNoteCallback, id, recordTemplate]);

  const handleFormSubmit = (formData: Record<string, unknown>) => {
    setIsSubmitting(true)
    addNoteCallback({
      patient: params?.id,
      facility: staff.getStaff.facility.id,
      provider: staff.getStaff.id,
      template: recordTemplate.id,
      data: formData.data,
    }).then(async (res) => {
      if (res?.data) {
        await fetchTimeline();
        setSubmitted(true);
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
      }
    })
  };

  return (
    <Overlay>
      <NotesTempModal>
        <Row
          width="100%"
          align="center"
          justify={
            !loading && !submitted && recordTemplate
              ? 'space-between'
              : 'flex-end'
          }
        >
          {!loading && !submitted && recordTemplate && (
            <DropdownSearch
              name="template"
              width="12rem"
              title="Select a template"
              value={searchValue || ''}
              onChange={(e) => openModal('notes', e.target.value || '')}
              onSearchInputChange={handleSearchChange}
              placeholder="search notes template"
              hasNextPage={hasNextPage}
              loading={searchLoading}
              onClose={resetNoteDropdown}
              data={notesDropdown?.recordTemplates}
              valueAccessor="id"
              labelAccessor={["title"]}
              refetchLoading={refetchLoading}
              onLoadMore={loadMoreNote}
              modStyles={{pl: 1.875, pt: 1.875}}
            />
          )}
          <ModalCloseButton
            onClick={removeModalHash}
            variant="text"
            size="1rem"
          >
            <CancelIcon />
          </ModalCloseButton>
        </Row>
        <ModalChild>
          <ModalChildContainer>
            {loading && (
              <Row width="100%" justify="center">
                Loading...
              </Row>
            )}
            {error && <ErrorComponent />}
            {!loading && !submitted && recordTemplate && (
              <FormRenderer
                config={{
                  id: recordTemplate.id,
                  title: recordTemplate.title,
                  description: recordTemplate.description,
                  fields: recordTemplate.fields,
                }}
                onSubmit={handleFormSubmit}
                isSubmitting={isSubmitting}
              />
            )}
            {submitted && addNoteData?.addRecord?.success && !isSubmitting && (
              <RecordSuccessModal
                templateTitle={recordTemplate?.title}
                setSubmitted={setSubmitted}
                recordId={addNoteData?.addRecord?.record?.id}
              />
            )}
          </ModalChildContainer>
        </ModalChild>
      </NotesTempModal>
    </Overlay>
  );
};

export default NotesModal;
