import { Row, Column, Button, Input, Select, Paragraph } from 'src/components';
import { Formik, Form } from 'formik';
import Validator from 'validatorjs';
import { COUNTRIES, RULES, REQUIRED_FIELD_OPTIONS } from 'src/constants';
import { Modal } from 'src/containers';
import { useRowData, useFacilities } from 'src/state';
import { getModalParams, removeModalHash } from 'src/utils/modalHelper';
import { Facility } from 'src/types';
import { ModalTitle } from '../AddServices/styled';
import { ModalSizer } from './styled';

const EditFacilityModal = () => {
  const id = getModalParams('id');
  const { getDataByKey } = useRowData();
  const { state, address, country } = (getDataByKey('facility') ||
    {}) as unknown as Facility;

  const { useUpdateFacility } = useFacilities();
  const { updateFacility, loading } = useUpdateFacility();

  const formData = {
    state: state || "",
    address: address || "",
    country: country || "Nigeria",
  };

  return (
    <Modal>
      <Column gap={1}>
        <Column>
          <ModalTitle>Edit Facility</ModalTitle>
          <Paragraph>Make changes to Facility Details</Paragraph>
        </Column>
        <Formik
          initialValues={formData}
          validate={(values) => {
            const validation = new Validator(
              values,
              {
                state: RULES.requiredfield,
                address: RULES.requiredfield,
                country: RULES.requiredfield,
              },
              {
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
              },
            );
            validation.passes();
            return validation.errors.errors;
          }}
          onSubmit={async (values) => {
            if (
              values.state === state &&
              values.country === country &&
              values.address === address
            ) {
              removeModalHash();
            }

            await updateFacility({
              ...values,
              facilityId: id,
            })?.then((res) => {
              if (res?.data) {
                removeModalHash();
              }
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => {
            const findCountry = COUNTRIES.find(
              (loc) => loc.countryName === values.country,
            );
            
            return (
              <Form>
                <ModalSizer gap={2.5}>
                  <Input
                    value={values.address}
                    onChange={handleChange}
                    label="Facility Address"
                    name="address"
                    required
                    onBlur={handleBlur}
                    error={touched.address ? errors.address : ''}
                  />
                  <Row gap={1}>
                    <Select
                      label="Facility State"
                      name="state"
                      required
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      error={touched.state ? errors.state : ''}
                    >
                      <option disabled value="">
                        Select a State
                      </option>
                      {findCountry?.regions.map((states, idx) => (
                        <option key={idx} value={states.name}>
                          {states.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      label="Facility Country"
                      name="country"
                      onChange={handleChange}
                      disabled
                      value={values.country}
                      onBlur={handleBlur}
                      error={touched.country ? errors.country : ''}
                    >
                      <option disabled value="">
                        Select a country
                      </option>
                      {COUNTRIES.map((Country) => (
                        <option
                          key={Country.countryShortCode}
                          value={Country.countryName}
                        >
                          {Country.countryName}
                        </option>
                      ))}
                    </Select>
                  </Row>
                  <Row width="50%">
                    <Button
                      type="submit"
                      width="max-content"
                      data-testid="save-changes"
                      disabled={loading}
                      isLoading={loading}
                    >
                      Save Changes
                    </Button>
                  </Row>
                </ModalSizer>
              </Form>
            );
          }}
        </Formik>
      </Column>
    </Modal>
  );
};

export default EditFacilityModal;
