import { gql } from "@apollo/client";
import { stepFieldsFragment } from "../Fragments/step";

export const ADD_STEP = gql`
  mutation AddStep($input: AddStepInput!) {
    addStep(input: $input) {
      success
      message
      step {
        ...StepFields
      }
    }
  }
  ${stepFieldsFragment}
`;

export const UPDATE_STEP = gql`
  mutation UpdateStep($input: UpdateStepInput!, $stepId: String!) {
    updateStep(input: $input, stepId: $stepId) {
      success
      message
      step {
        ...StepFields
      }
    }
  }
  ${stepFieldsFragment}
`;

