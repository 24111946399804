import React from 'react';
import { DeleteIcon } from 'src/assets/icons';
import { Row, Button, Column, Paragraph, InputLabel } from 'src/components';
import { Fields, FormRendererProps, FormFieldValue } from 'src/types';
import { theme } from 'src/utils/variables';
import { useFormik } from 'formik';
import Validator from 'validatorjs';
import {
  getFormErrorMessage,
  checkLastItemValue,
  reduceFormConfig,
  transformData,
  checkRequired,
  removeUnwantedRowFields,
  DataType,
  isDeeplyEmpty,
} from 'src/utils/formRenderHelper';
import { FieldInput } from './FieldInput';
import { MultiRowForm } from '../MultiRowForm';
import {
  InfoDescription,
  InfoTitle,
  FullWidthForm,
  FormTitle,
  RowWrap,
  SubmitButton,
} from './styled';

export const FormRenderer: React.FC<FormRendererProps> = ({
  config,
  onSubmit,
  isSubmitting,
}) => {
  const getInitialFormState = React.useMemo(
    () => reduceFormConfig(config),
    [config],
  );
  const [formState, setFormState] = React.useState<Record<string, unknown>>(
    getInitialFormState.state,
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
  } = useFormik({
    initialValues: formState,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(
        removeUnwantedRowFields(validationValues as Record<string, DataType>),
        getInitialFormState.validation,
      );
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: (value) => {
      value = removeUnwantedRowFields(value as Record<string, DataType>);
      const keys = Object.keys(value);
      onSubmit({
        data: transformData(keys, config, value),
        rawData: value,
      });
    },
  });

  const onAddRow = (key: string) => {
    const oldState = (values[key] as Array<Record<string, unknown>>)[0];
    const newState = { ...oldState };
    Object.keys(newState).map((elm) => {
      newState[elm] = undefined;
      return elm;
    });
    setFormState({
      ...values,
      [key]: [...(values[key] as Array<Record<string, unknown>>), newState],
    });
  };

  const onDeleteRow = (key: string, rowIdx: number) => {
    if ((values[key] as Array<Record<string, unknown>>).length > 1) {
      const oldState = (values[key] as Array<Record<string, unknown>>).filter(
        (elm, idx) => idx !== rowIdx,
      );

      setFormState({
        ...values,
        [key]: [...oldState],
      });
    }
  };

  return (
    <Column gap={1} minWidth='36vw'>
      <Column>
        <FormTitle weight="bold">{config.title}</FormTitle>
        <Paragraph size="sm" weight="semibold" color={theme.grey[500]}>
          {config.description}
        </Paragraph>
      </Column>
      <FullWidthForm onSubmit={handleSubmit}>
        <Column gap={1}>
          <Column gap={1}>
            {config.fields.map((field, idx) => {
              return (
                <Column gap={0.25} key={idx}>
                  {/* Info fields */}
                  {field.type === 'Info' && (
                    <Column gap={0.3}>
                      <InfoTitle>{field.title}</InfoTitle>
                      <InfoDescription>{field?.description}</InfoDescription>
                    </Column>
                  )}
                  {field.type !== 'Info' && (
                    <InputLabel required={checkRequired(field)}>
                      {field.title}
                    </InputLabel>
                  )}
                  {/* Plain fields */}
                  {!field.columns && !field.fields && field.type !== 'Info' && (
                    <FieldInput
                      key={idx}
                      value={values[field.id] as string | number}
                      field={field}
                      name={field.id}
                      validation={field.validation}
                      errors={getFormErrorMessage(errors, field.id)}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {/* Table Fields */}
                  {field.columns && formState[field.id] && (
                    <MultiRowForm
                      shouldShowSuggestion={checkLastItemValue(
                        values[field.id] as Array<Record<string, unknown>>,
                      )}
                      onAddNewRow={() => onAddRow(field.id)}
                    >
                      {(
                        formState[field.id] as Array<Record<string, unknown>>
                      ).map((elm, elmId) => {
                        return (
                          <RowWrap key={elmId} gap={0.5} align="flex-end">
                            {config.fields[idx].columns?.map((f, i) => {
                              const fieldObj = (
                                values[field.id] as Array<
                                  Record<string, FormFieldValue>
                                >
                              )[elmId];

                              return (
                                fieldObj && (
                                  <FieldInput
                                    key={i}
                                    name={`${field.id}[${elmId}].${f.id}`}
                                    validation={f.validation}
                                    value={fieldObj[f.id]}
                                    field={
                                      (config.fields[idx].columns as Fields[])[
                                        i
                                      ]
                                    }
                                    errors={getFormErrorMessage(
                                      errors,
                                      `${field.id}.${elmId}.${f.id}`,
                                    )}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                  />
                                )
                              );
                            })}
                            <Button
                              width="max-width"
                              type="button"
                              style={{ marginBottom: '1rem', strokeWidth: 0 }}
                              background={theme.grey[100]}
                              color={theme.grey[700]}
                              onClick={() => onDeleteRow(field.id, elmId)}
                            >
                              <DeleteIcon />
                            </Button>
                          </RowWrap>
                        );
                      })}
                    </MultiRowForm>
                  )}
                  {/* Row fields */}
                  {field.fields &&
                    formState[field.id] &&
                    (formState[field.id] as Array<Record<string, unknown>>).map(
                      (elm, elmId) => {
                        const fieldObj = (
                          values[field.id] as Array<
                            Record<string, FormFieldValue>
                          >
                        )[elmId];
                        return (
                          <RowWrap key={elmId} gap={0.5} align="flex-end">
                            {config.fields[idx].fields?.map((f, i) => {
                              return (
                                fieldObj && (
                                  <FieldInput
                                    key={i}
                                    name={`${field.id}[${elmId}].${f.id}`}
                                    value={fieldObj[f.id]}
                                    validation={f.validation}
                                    field={
                                      (config.fields[idx].fields as Fields[])[i]
                                    }
                                    errors={getFormErrorMessage(
                                      errors,
                                      `${field.id}.${elmId}.${f.id}`,
                                    )}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                  />
                                )
                              );
                            })}
                          </RowWrap>
                        );
                      },
                    )}
                </Column>
              );
            })}
          </Column>
          <Row align="stretch" gap={2}>
            <Column width="40%">
              <SubmitButton
                data-testid="submit-form"
                type="submit"
                isLoading={isSubmitting}
                disabled={isDeeplyEmpty(values)}
              >
                Submit
              </SubmitButton>
            </Column>
          </Row>
        </Column>
      </FullWidthForm>
    </Column>
  );
};
