import { CheckboxProps } from 'src/types';
import { Row } from '../Flex';
import { CheckboxBase } from './styled';
import { Spinner } from '../PageLoader/styled';

export const Checkbox: React.FC<CheckboxProps> = ({ children, ...props }) => {
  return (
    <Row
      width="max-content"
      modStyles={{ opacity: props.isLoading || props.disabled ? 0.5 : 1 }}
    >
      <CheckboxBase id={props.label} type="checkbox" {...props} />
      {props.label ? (
        <Row width='max-content' align="center">
          <label htmlFor={props.label}>{props.label}</label>{' '}
          {props.isLoading && (
            <Spinner size={0.8} thickness={0.15} modStyles={{ ml: 1 }} />
          )}
        </Row>
      ) : (
        children
      )}
    </Row>
  );
};
