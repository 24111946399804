import { Column, Paragraph, Row, Text } from "src/components";
import { Action, Parameters } from "src/types";
import { UpperRightArrow } from "src/assets/icons";
import { theme } from "src/utils";
import _ from "lodash";
import { NO_DETAILS } from "src/constants";
import { ColoredCard } from "../styled"


interface stepDetailsprops {
  title: string;
  text: string;
  assigned: string[]
};

interface stepParamsprops {
  parameters?: Parameters[];
};

interface actionItemsProps {
  actions?: Action[]
};

const NoDetailsText = () => (
  <Text size="xs" weight="semibold" color={theme.red[500]}>
    {NO_DETAILS}
  </Text>
);


export const StepDetailsSummary: React.FC<stepDetailsprops> = ({
  title,
  text,
  assigned
}) => {
  return (
    <Column>
      <Paragraph 
        modStyles={{ mb: 0.25 }} 
        weight="semibold" 
        color={theme.grey[500]}
        size="sm"
      >
        Step Details
      </Paragraph>
      {(text && title && assigned?.length > 0) ? (
        <ColoredCard>
          <Column gap={0.375}>
            <Text size="sm" weight="semibold" color="#313131" data-testid="summary-title">{title}</Text>
            <Text size="sm" weight="semibold" color="#666" data-testid="summary-description">{text}</Text>
          </Column>
        </ColoredCard>
      ) : <NoDetailsText />}
    </Column>
  )
}

export const StepParamsSummary: React.FC<stepParamsprops> = ({ 
  parameters 
}) => {

  const hasIndexValues = parameters !== undefined && 
  parameters.length > 0 && 
  Object.values(parameters?.[0]).every(value => value);


  return (
    <Column>
      <Paragraph  
        modStyles={{ mb: 0.25 }} 
        weight="semibold" 
        color={theme.grey[500]}
        size="sm"
      >
        Step parameters
      </Paragraph>
      {!hasIndexValues ? <NoDetailsText /> : 
        parameters?.map((pars, idx) => (
          <ColoredCard key={idx} data-testid="parameter-summary-value">
            <Text size="sm" weight="semibold" color={theme.primary[700]}>
              {pars.title}
            </Text>
          </ColoredCard>
        )
      )}
    </Column>
  )
}


export const ActionItemsSummary: React.FC<actionItemsProps> = ({ actions }) => {
  return (
    <Column>
      <Paragraph 
        modStyles={{ mb: 0.25 }} 
        weight="semibold" 
        size="sm"
        color={theme.grey[500]}
      >
        Action items
      </Paragraph>
      {_.isEmpty(actions?.[0]?.title) ? <NoDetailsText /> : 
        actions?.map(({title, tag}, idx) => (
          <ColoredCard key={idx} data-testid="action-summary-value">
            <Row align="center" gap={2} justify="space-between">
              <Text size="sm" weight="semibold" color={theme.primary[700]}>
                {title}
              </Text>
              {tag && (
                <Row width="35%" align="center" gap={0.65}>
                  <UpperRightArrow className="external-icon" />
                  <Text size="sm" weight="semibold" color={theme.primary[700]}>
                    {tag}
                  </Text>
                </Row>
              )}
            </Row>
          </ColoredCard>
        )
      )}
    </Column>
  )
}
