/* eslint-disable no-underscore-dangle */
import {
  AsyncSearchInput,
  Button,
  Column,
  ConfirmationPrompt,
  H6,
  Row,
  Text,
  Textarea,
} from 'src/components';
import { useLocation } from 'wouter';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  addInvoiceItemValidation,
  currencyformatter,
  getModalParams,
  isAllFilled,
  removeEmptyValues,
  removeModalHash,
  theme,
} from 'src/utils';
import { UserPlusIcon } from 'src/assets/icons';
import {
  ADD_TO_PATIENT_INVOICE,
  GET_INVOICE,
  GET_INVOICES_TOTAL,
  SEARCH_INVENTORY,
} from 'src/constants';
import { Invoice, InvoiceItem, ProductBatchType } from 'src/types';
import { useStaff, useToast } from 'src/state';
import { useFormik } from 'formik';
import Validator from 'validatorjs';
import { inputHeaders } from 'src/pages/admin/PatientDetails/Invoice/constants';
import { MultiRowForm } from 'src/containers';
import { Hr } from 'src/pages/admin/PatientDetails/Invoice/styled';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import moment from 'moment';
import { InvoiceLoader } from 'src/pages/admin/PatientDetails/Invoice/Empty';
import mixpanel from 'mixpanel-browser';
import RowInputGroup from '../components/RowInputGroup';
import ProductExtraInfo from '../components/ProductExtraInfo';
import { ViewContext } from '.';
import InvoiceParticipants from '../components/InvoiceParticipants';
import PatientPrescription from '../components/PatientPrescription';

export type InvoiceType = 'facility' | 'patient' | 'external';
export type SavePayload = {
  invoiceItem: InvoiceItem;
  arrIdx?: number;
  currInv: Invoice;
  queueId?: number;
  cb?: (inv: InvoiceItem) => void;
};
const descriptionMap: Record<string, string> = {
  external: 'For External Customer',
  patient: 'For Internal Patient',
  facility: 'For Facility Use',
};

const defaultActiveRow = {
  idx: -1,
  value: '',
};

export const defaultLoadingRow = {
  idx: -1,
  loading: false,
};

export const dummyItem: InvoiceItem = {
  serviceCode: '',
  isHMOPayable: 'OOP',
  units: 1,
  unitCost: 0,
  formattedCost: '',
  description: '',
  currency: '',
  totalCost: 0,
};

const currentInvoiceState: Invoice = {
  items: [{ ...dummyItem }],
  note: '',
  paymentMethod: 'CASH',
  status: 'UNPAID',
};

export const isItemValid = (item: InvoiceItem) => {
  const { description, unitCost, units } = item;
  return isAllFilled({
    description,
    // isHMOPayable,
    unitCost,
    units,
  });
};

export const extractRequiredFields = (invoice: InvoiceItem) => {
  const { description, unitCost, units, isHMOPayable } = invoice;
  return { description, unitCost, units, isHMOPayable };
};

const renderDrugItem = (product: Record<string, unknown>) => {
  const d = product as unknown as ProductBatchType;
  return (
    <ProductExtraInfo
      name={d?.product?.name as string}
      brandName={d?.product?.brandName}
      extraInfo={d?.product?.extraInfo as string}
      batchId={d.batchNumber}
      status={d.status?.filter((s) => ['EXPIRING_SOON'].includes(s))}
      quantityLeft={d.quantityOnShelf}
      expiryDate={
        d?.expiryDate ? moment(d?.expiryDate).format('DD-MM-YY') : 'NIL'
      }
      isOTC={d?.product?.isOTC}
    />
  );
};

const OpenInvoice = () => {
  const [, setLocation] = useLocation();
  const {
    setView,
    patient,
    staff,
    currentInvoice,
    setCurrentInvoice,
    setPatient,
    setStaff,
  } = useContext(ViewContext);
  const invoiceType = getModalParams('type') as InvoiceType;
  const id = getModalParams('id') as InvoiceType;
  const { staff: facilityStaff } = useStaff();
  const facilityId = facilityStaff.getStaff.facility.id;
  const [completed, setCompleted] = useState(false);
  const [isFacilityPatientOpen, toggleIsFacilityPatientOpen] = useState(
    invoiceType === 'patient',
  );
  const { showToast } = useToast();
  const [networkErrors, setNetworkErrors] = useState<Record<number, string>>(
    {},
  );

  const searchRef = useRef<HTMLInputElement | null>(null);
  const participantsRef = useRef<HTMLDivElement>(null);

  const [isActiveRow, setIsActiveRow] = useState({ ...defaultActiveRow });
  const [loading, setLoading] = useState({ ...defaultLoadingRow });
  const [isWritesBlocked, setIsWritesBlocked] = useState(false);

  const goBack = () => {
    setView('history');
    setCurrentInvoice({} as Invoice);
    setLocation(`/admin/inventory#inventory-invoice&mView=history`);
  };

  const handleRowInputClick = (idx: number) => {
    setIsActiveRow({
      idx,
      value: '',
    });
    searchRef.current?.focus();
  };

  const [addToPatientInvoice, { loading: isAddingToPatientInvoice }] =
    useMutation(ADD_TO_PATIENT_INVOICE, { errorPolicy: 'all' });
  const [fetchInvoice, { loading: fetchInvoiceLoading }] = useLazyQuery(
    GET_INVOICE,
    { errorPolicy: 'all' },
  );

  const fetchOpenInvoice = useCallback(async () => {
    await fetchInvoice({
      variables: { invoiceId: id },
      onCompleted(d) {
        const data = d?.getInvoice;
        const items = d?.getInvoice.items.map((item: InvoiceItem) => ({
          ...item,
          totalCost: (item.unitCost || 0) * (item.units || 0),
        }));
        setCurrentInvoice({ ...data, items, paymentMethod: 'CASH' });
        if (data?.patient) {
          setPatient({
            firstName: data?.patient?.user?.firstName as string,
            lastName: data.patient?.user.lastName as string,
            id: data?.patient?._id,
            hmo: data?.patient?.otherInfo?.hmo,
            inJourney: data?.patient?.status === 'CHECKED_IN',
          });
        } else {
          setPatient(undefined);
        }
        if (data?.requestedBy) {
          setStaff({ ...data.requestedBy, id: data._id });
        } else {
          setStaff(undefined);
        }
      },
      onError() {
        showToast('Error fetching open invoice, please reload', 'error');
      },
    });
  }, [fetchInvoice, id, setCurrentInvoice, setPatient, setStaff, showToast]);

  useEffect(() => {
    if (id && !currentInvoice._id) {
      fetchOpenInvoice();
    }
    if (!id && !currentInvoice._id) {
      setCurrentInvoice(currentInvoiceState)
    }
  }, [currentInvoice._id, fetchOpenInvoice, id, setCurrentInvoice]);

  const handleSearchSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    data?: Record<string, unknown>,
  ) => {
    const items = [..._.cloneDeep(currentInvoice.items)];
    let activeIdx: number;
    if (isActiveRow.idx >= 0) {
      activeIdx = isActiveRow.idx;
    } else if (items.length === 1 && !items[0].description) {
      activeIdx = 0;
    } else {
      activeIdx = items.length;
    }
    const product = data as unknown as ProductBatchType;
    if (product.quantityOnShelf === 0) {
      showToast('No more items on shelf', 'warning');
      return;
    }
    const extra = {
      _id: items?.[activeIdx]?._id,
      itemAddQueued: items?.[activeIdx]?.itemAddQueued,
    };
    const invoiceItem: InvoiceItem = {
      ..._.pickBy(extra, _.identity),
      unitCost: Number(product.sellingPrice),
      isHMOPayable: 'OOP',
      currency: 'NGN',
      description: product?.product?.name as string,
      units: 1,
      productCode: product.product?.productCode,
      batchNo: product.batchNumber,
      formattedCost: currencyformatter.format(
        parseInt(product?.sellingPrice.toString() as string, 10),
      ),
      totalCost: Number(product.sellingPrice || 0) * 1,
    };
    setNetworkErrors(prev => {
      delete prev[activeIdx]
      return prev
    });

    setIsActiveRow({ idx: -1, value: '' });

    if (
      !invoiceItem._id &&
      !items?.[activeIdx]?.description &&
      isItemValid(invoiceItem)
    ) {
      invoiceItem.itemAddQueued = true;
    } else {
      invoiceItem.itemUpdateQueued = true;
    }
    items[activeIdx] = invoiceItem;
    setCurrentInvoice((prev) => ({ ...prev, items }));
  };

  const addNewServiceRow = () => {
    setCurrentInvoice((prev) => {
      return {
        ...prev,
        items: [...prev.items, { ...dummyItem }],
      };
    });
  };

  const lastItem = (currentInvoice?.items || [])[
    currentInvoice?.items?.length - 1
  ];

  const { hmoAmount, oopAmount, totalamount } =
    currentInvoice?.items?.reduce(
      (totals, val) => {
        if (
          (typeof val.isHMOPayable === 'string' &&
            val.isHMOPayable === 'HMO') ||
          (typeof val.isHMOPayable === 'boolean' && val.isHMOPayable)
        ) {
          totals.hmoAmount +=
            Number(val.unitCost || 0) * Number(val.units || 1);
        } else if (
          (typeof val.isHMOPayable === 'string' &&
            val.isHMOPayable === 'OOP') ||
          (typeof val.isHMOPayable === 'boolean' && !val.isHMOPayable)
        ) {
          totals.oopAmount +=
            Number(val.unitCost || 0) * Number(val.units || 1);
        }
        totals.totalamount = totals.hmoAmount + totals.oopAmount;
        return totals;
      },
      {
        hmoAmount: 0,
        oopAmount: 0,
        totalamount: 0,
      },
    ) || {};

  const extraValidation = {
    patient: invoiceType === 'patient' ? 'required' : undefined,
    requestedBy: invoiceType === 'facility' ? 'required' : undefined,
  };

  const extraFields = {
    requestedBy: staff?.id,
    patient: patient?.id,
  };

  const { handleSubmit, errors, setFieldValue } = useFormik({
    initialValues: {
      invoice: { ...currentInvoice },
      ...removeEmptyValues(extraFields),
      isSubmit: true,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      if (
        validationValues.invoice.items.length > 1 &&
        !validationValues.invoice.items[
          validationValues.invoice.items.length - 1
        ].description.length
      ) {
        validationValues.invoice.items.pop();
        setCurrentInvoice(validationValues.invoice);
      }
      const validation = new Validator(validationValues, {
        ...addInvoiceItemValidation,
        ..._.pickBy(extraValidation, _.identity),
      });
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: async (value) => {
      if (Object.keys(networkErrors).length) return;
      if (!value?.isSubmit) {
        setView('confirmation');
        setLocation(
          `#inventory-invoice&mView=confirmation&type=${invoiceType}`,
        );
        return;
      }
      await addToPatientInvoice({
        variables: { invoiceId: currentInvoice._id, patientId: patient?.id },
        errorPolicy: 'all',
        refetchQueries: [GET_INVOICES_TOTAL],
        onCompleted(d) {
          if (d?.addInvoiceToPatientInvoice?.success) {
            setCompleted(true);
            setLocation(`#inventory-invoice&mView=open&type=${invoiceType}`);
            setCurrentInvoice({} as Invoice);
            showToast(d?.addInvoiceToPatientInvoice?.message, 'success');
          }
        },
      });
    },
  });

  return (
    <>
      {fetchInvoiceLoading && (
        <Column minWidth="50vw">
          <InvoiceLoader />
        </Column>
      )}
      {!completed && !fetchInvoiceLoading && (
        <ViewWrapper goBack={id ? goBack : undefined}>
          <Column width="100%" height="100%" minHeight="70vh" gap={1}>
            <Column gap={0.3} modStyles={{ mb: 1 }}>
              <H6 modStyles={{ ma: 0 }}>Invoice</H6>
              <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
                {descriptionMap[invoiceType]}
              </Text>
            </Column>
            <InvoiceParticipants
              elementRef={participantsRef}
              isFacilityPatientOpen={isFacilityPatientOpen}
              errors={errors}
            />
            {patient && <PatientPrescription />}
            <Column gap={0.3}>
              <Text weight="bold" size="sm" color={theme.grey[650]}>
                Add new items to invoice
              </Text>
              <AsyncSearchInput
                label="Product Name"
                elementRef={searchRef}
                name="isActiveRow.value"
                emptyText="No item found"
                placeholder="Search or choose product"
                dataTestId="search-drug"
                value={isActiveRow.value}
                handleChange={(e) =>
                  setIsActiveRow((prev) => ({ ...prev, value: e.target.value }))}
                query={SEARCH_INVENTORY}
                queryOptions={{ facility: facilityId }}
                handleSelect={handleSearchSelect}
                render={renderDrugItem}
                refetchOnMount
                selectors={{
                  dataSelector: 'searchInventory.batches',
                  valueSelector: 'id',
                  labelSelector: 'id',
                  totalSelector: 'searchInventory.total',
                }}
              />
            </Column>
            <FullWidthForm onSubmit={handleSubmit}>
              <MultiRowForm
                shouldShowSuggestion={isItemValid(lastItem || {})}
                onAddNewRow={addNewServiceRow}
                options={{
                  isTable: true,
                  headers: inputHeaders,
                  moreProps: { item: dummyItem },
                }}
              >
                {currentInvoice?.items?.map((item, idx) => (
                  <RowInputGroup
                    handleRowInputClick={() => handleRowInputClick(idx)}
                    item={item}
                    key={idx}
                    arrIdx={idx}
                    errors={errors}
                    loading={loading}
                    invoiceType={invoiceType}
                    setLoading={setLoading}
                    isWritesBlocked={isWritesBlocked}
                    setIsWritesBlocked={setIsWritesBlocked}
                    networkErrors={networkErrors}
                    setNetworkErrors={setNetworkErrors}
                  />
                ))}
              </MultiRowForm>
              <Hr />
              <Column gap={0.5}>
                <Row width="100%" justify="space-between">
                  <Text color={theme.grey[600]}>Total HMO payment</Text>
                  <Text
                    data-testid="hmo-amount"
                    weight="semibold"
                    color={theme.black[200]}
                  >
                    {currencyformatter.format(
                      parseInt(hmoAmount?.toString(), 10),
                    )}
                  </Text>
                </Row>
                <Row width="100%" justify="space-between">
                  <Text color={theme.grey[600]}>Total Out-of-pocket</Text>
                  <Text
                    data-testid="oop-amount"
                    weight="semibold"
                    color={theme.black[200]}
                  >
                    {currencyformatter.format(
                      parseInt(oopAmount?.toString(), 10),
                    )}
                  </Text>
                </Row>
                <Row width="100%" justify="space-between">
                  <Text color={theme.grey[600]}>Gross Total</Text>
                  <Text
                    data-testid="total-amount"
                    weight="semibold"
                    color={theme.black[200]}
                  >
                    {currencyformatter.format(
                      parseInt(totalamount?.toString(), 10),
                    )}
                  </Text>
                </Row>
              </Column>
              <Textarea
                name="note"
                label="Notes"
                data-testid="invoice-note"
                placeholder="Write your Note Here"
                onChange={(e) =>
                  setCurrentInvoice((prev) => ({
                    ...prev,
                    note: e.target.value,
                  }))}
                defaultValue={currentInvoice?.note || ''}
              />
              <Row justify="space-between" width="100%">
                {invoiceType !== 'external' && patient?.inJourney && (
                  <Button
                    background={theme.grey[100]}
                    data-testid="push-btn"
                    width="max-content"
                    color={theme.grey[600]}
                    type="submit"
                    disabled={loading.loading}
                    isLoading={isAddingToPatientInvoice}
                    onClick={() => {
                      mixpanel.track(`Click 'Add To Patient's invoice' button`, {feature: 'Inventory Invoice'})
                      setFieldValue('isSubmit', true);
                    }}
                  >
                    <UserPlusIcon color={theme.grey[600]} /> Add to
                    Patient&apos;s Invoice
                  </Button>
                )}
                {invoiceType === 'facility' &&
                  !isFacilityPatientOpen &&
                  !patient && (
                    <Button
                      background={theme.grey[100]}
                      data-testid="save-product"
                      width="max-content"
                      color={theme.grey[600]}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleIsFacilityPatientOpen(!isFacilityPatientOpen);
                      }}
                    >
                      <UserPlusIcon color={theme.grey[600]} /> For a Patient?
                      Click here
                    </Button>
                  )}
                <Button
                  variant="solid"
                  width="max-content"
                  data-testid="pay-now-btn"
                  type="submit"
                  disabled={isAddingToPatientInvoice || loading.loading}
                  isLoading={isAddingToPatientInvoice || loading.loading}
                  onClick={() => {
                    mixpanel.track(`Click 'Pay Now' button`, {feature: 'Inventory Invoice'})
                    setFieldValue('isSubmit', false);
                    if (
                      (invoiceType === 'patient' && !patient?.id) ||
                      (invoiceType === 'facility' && !staff?.id)
                    ) {
                      participantsRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }}
                  modStyles={{ ml: 'auto' }}
                  noIconStyles
                >
                  Pay Now
                </Button>
              </Row>
            </FullWidthForm>
          </Column>
        </ViewWrapper>
      )}
      {completed && (
        <ConfirmationPrompt
          title="Invoice Added to Patient Journey"
          message="You have successfully added this invoice to patient journey"
          onConfirm={() => {
            removeModalHash();
            setLocation(`/admin/patients/${patient?.id}?tab=invoice`);
          }}
          confirmText="View Patient's Invoice"
          dismissText="Close"
        />
      )}
    </>
  );
};

export default OpenInvoice;
