import { Formik, Form } from 'formik';
import Validator from 'validatorjs';
import { Row, Column, Button, Input, Select, Paragraph } from 'src/components';
import { COUNTRIES, RULES, REQUIRED_FIELD_OPTIONS } from 'src/constants';
import { Modal } from 'src/containers';
import { useFacilities } from 'src/state';
import { removeModalHash } from 'src/utils/modalHelper';
import { ModalTitle } from '../AddServices/styled';

const AddFacilityModal = () => {
  const { useAddFacility } = useFacilities();
  const { addFacility, loading } = useAddFacility();
  const formData = {
    state: '',
    address: '',
    country: 'Nigeria',
  };

  return (
    <Modal>
      <Column gap={1}>
        <Column>
          <ModalTitle weight="bold">Add New Facility</ModalTitle>
          <Paragraph>
            Add new Facility to the list of existing facility that you have
            already
          </Paragraph>
        </Column>
        <Formik
          initialValues={formData}
          validate={(values) => {
            const validation = new Validator(
              values,
              {
                state: RULES.requiredfield,
                address: RULES.requiredfield,
                country: RULES.requiredfield,
              },
              {
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
                ...REQUIRED_FIELD_OPTIONS,
              },
            );
            validation.passes();
            return validation.errors.errors;
          }}
          onSubmit={async (values) => {
            await addFacility(values)?.then((res) => {
              if (res?.data) {
                removeModalHash();
              }
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => {
            const findCountry = COUNTRIES.find(
              (country) => country.countryName === values.country,
            );
            return (
              <Form>
                <Column gap={2.5}>
                  <Input
                    value={values.address}
                    onChange={handleChange}
                    label="Facility Address"
                    required
                    name="address"
                    onBlur={handleBlur}
                    error={touched.address ? errors.address : ''}
                  />
                  <Row gap={1}>
                    <Select
                      label="Facility State"
                      name="state"
                      required
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      error={touched.state ? errors.state : ''}
                    >
                      <option disabled value="">
                        Select a State
                      </option>
                      {findCountry?.regions.map((states, idx) => (
                        <option key={idx} value={states.name}>
                          {states.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      label="Facility Country"
                      name="country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      value={values.country}
                      error={touched.country ? errors.country : ''}
                    >
                      <option disabled value="">
                        Select a country
                      </option>
                      {COUNTRIES.map((country) => (
                        <option
                          key={country.countryShortCode}
                          value={country.countryName}
                        >
                          {country.countryName}
                        </option>
                      ))}
                    </Select>
                  </Row>
                  <Row width="50%">
                    <Button
                      width="max-content"
                      type="submit"
                      data-testid="submit"
                      disabled={loading}
                      isLoading={loading}
                    >
                      {loading ? 'Loading' : 'Add Facility'}
                    </Button>
                  </Row>
                </Column>
              </Form>
            );
          }}
        </Formik>
      </Column>
    </Modal>
  );
};

export default AddFacilityModal;
