import { gql } from "@apollo/client";
import { facilityFieldsFragment } from "../Fragments/facility";


export const GET_FACILITY = gql`
  query Facility {
    facility {
      ...FacilityFields
    }
  }
  ${facilityFieldsFragment}
`;


export const GET_FACILITIES = gql`
  query GetFacilities($input: GetfacilitiesInput) {
    getFacilities(input: $input) {
      facilities {
        ...FacilityFields
      }
      total
    }
  }
  ${facilityFieldsFragment}
`;


export const SEARCH_FACILITIES = gql`
  query SearchFacilities($input: SearchFacilitiesInput!) {
    searchFacilities(input: $input) {
      facilities {
        ...FacilityFields
      }
      total
    }
  }
  ${facilityFieldsFragment}
`;


export const GET_FACILITIES_IDS = gql`
  query GetFacilities($input: GetfacilitiesInput) {
    getFacilities(input: $input) {
      facilities {
        id: _id
        state
      }
      total
    }
  }
`;

