import styled from 'styled-components';
import { Form } from 'formik';
import { IconButton, Row, Text, H4, Column, H6, Button } from 'src/components';
import { theme, font } from 'src/utils/variables';

export const ModalTitle = styled(H4)`
  font-size: 1.75rem;
  margin: 0;
  font-weight: ${font.weights.bold};
`;

export const ModalSubTitle = styled(H6)`
  margin: 0;
`;

export const PatientContainer = styled(Column)`
  @media (max-width: 960px) {
    width: 100vw;
  }
`;

export const RegisteredPatientRow = styled(Row)`
  padding: 1rem;
`;

export const RegisteredPatientText = styled(Text)`
  margin: 0;
  max-width: 13rem;
`;

export const TabHeaderIndicatorButton = styled(IconButton)<{
  active?: boolean;
}>`
  padding: 0.75rem;
  background: ${(props) =>
    props.active ? theme.secondary[100] : theme.grey[50]};
  svg,
  path {
    fill: ${(props) => (props.active ? theme.secondary[700] : theme.grey[500])};
    stroke: ${(props) =>
      props.active ? theme.secondary[700] : theme.grey[500]};
    stroke-width: 0.25;
    width: 1.125rem;
    height: 1.125rem;
  },
`;

export const TabIndicatorText = styled(Text)`
  margin: 0rem;
  width: 100%;
`;

export const FixedTabs = styled(Row)`
  top: 0;
  z-index: 120;
  position: sticky;
  background-color: ${theme.white[100]};
  & > div {
    justify-content: space-between;
    gap: 3rem;
  }
`;

export const AddPatientTabContainer = styled(Row)`
  position: relative;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const PatientButton = styled(Button)`
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const PatientsTabForm = styled(Form)<{
  display?: 'block' | 'none';
}>`
  display: ${(props) => props.display || 'initial'};
  width: 100%;
`;

export const SubmitButton = styled(Button)<{
    visibility?: 'visible' | 'hidden';
}>`
  visibility: ${(props) => props.visibility || "visible"};
  & > svg > path {
    fill: currentColor;
    stroke: none;
  }
`
