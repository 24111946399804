import { gql } from '@apollo/client';
import { stepTemplateFieldsFragment } from '../Fragments/stepTemplate';

export const GET_STEP_TEMPLATES = gql`
  query GetStepTemplates($input: GetStepTemplatesQuery) {
    getStepTemplates(input: $input) {
      total
      stepTemplates {
        ...StepTemplateFields
      }
    }
  }
  ${stepTemplateFieldsFragment}
`;

export const GET_STEP_TEMPLATE = gql`
  query GetStepTemplate($getStepTemplateId: ID!) {
    getStepTemplate(id: $getStepTemplateId) {
      ...StepTemplateFields
    }
  }
  ${stepTemplateFieldsFragment}
`;

export const GET_MOST_USED_STEP_TEMPLATES = gql`
  query GetMostUsedStepTemplates {
    getMostUsedStepTemplates {
      total
      stepTemplates {
        ...StepTemplateFields
      }
    }
  }
  ${stepTemplateFieldsFragment}
`;
