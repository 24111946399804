import React, { useContext } from 'react';
import Validator from 'validatorjs';
import { TabContext } from 'src/containers';
import { useStepTemplates } from 'src/state';
import { createStepValidation, getModalParams, stepValidationErrorMessages } from 'src/utils';
import { CreateStepTemplate, StepTemplate } from 'src/types';
import _ from 'lodash';
import { TabItems } from './tabItems';
import { FormProps } from '../index';

interface AddStepsTabContentProps {
  setCompleted: (value: FormProps) => void;
  completed: FormProps;
  setData: (value: StepTemplate) => void;
}

export const AddStepsTabsContent: React.FC<AddStepsTabContentProps> = ({
  setCompleted,
  setData,
  completed
}) => {
  const isEditStep = getModalParams("id");
  const { activeTab } = useContext(TabContext);
  const { currentStepTemplate } = useStepTemplates();
  const {
    name,
    description,
    parameters,
    actions,
    assigned
  } = (currentStepTemplate || {}) as StepTemplate;
  
  const defaultParameters = [{
    type: "",
    title: "",
    entryType: ""
  }];

  const defaultActions = [{
    title: "",
    tag: ""
  }];

  const formValues: Omit<CreateStepTemplate, "facility"> = {
    name: "",
    description: "",
    assigned: [],
    parameters: defaultParameters,
    actions: defaultActions
  };

  const editFormValues: Omit<CreateStepTemplate, "facility">  = {
    name,
    description,
    parameters: parameters?.length === 0 ? defaultParameters : parameters,
    actions:  actions?.length === 0 ? defaultActions : actions,
    assigned: assigned?.map(el => el.id) || []
  };

  const initialValues = isEditStep ? editFormValues : formValues;
  const stepDetailKeys = ['name', 'description', 'assigned'];

  const next = (validate: typeof createStepValidation) => {
    switch (activeTab) {
      case 'add-step-0':
        return _.pick(validate, stepDetailKeys)

      case 'add-step-1':
        return _.pick(validate, ['parameters.*.entryType', 'parameters.*.type', 'parameters.*.title'])

      case 'add-step-2':
        return _.pick(validate, ['actions.*.title', 'actions.*.tag']);
      case 'add-step-3':
      default:
        return validate;
    }
  };

  
  const checkValidity = (values: Record<string, unknown>) => {

    const stepValidation = createStepValidation;
    const { parameters: dParameters, actions: dActions } = values as unknown as CreateStepTemplate;

    dActions.every((el) => {
      stepValidation['actions.*.tag'] = el.title.length > 0
      ? `string|required_if:${el.title}`
      : `string`;

      stepValidation['actions.*.title'] = el.tag.length > 0
      ? `string|required_if:${el.tag}`
      : `string`;

      return null
    })

    dParameters.every((el) => {
      stepValidation['parameters.*.entryType'] = el.title.length > 0 || el.type.length > 0
      ? `string|required_if:${el.title} string|required_if:${el.type}`
      : `string`;

      stepValidation['parameters.*.type'] = el.title.length > 0 || el.entryType.length > 0
      ? `string|required_if:${el.title} string|required_if:${el.entryType}`
      : `string`;

      stepValidation['parameters.*.title'] = el.type.length > 0 || el.entryType.length > 0
      ? `string|required_if:${el.type} string|required_if:${el.entryType}`
      : `string`;

      return null
    })


    const validation = new Validator(
      values, 
      next(stepValidation),
      stepValidationErrorMessages
    );

    validation.passes();

    const isSectionInvalid = (keys: string[], prefix: string) =>
      keys.some((value) => value.startsWith(prefix));

    if (activeTab === 'add-step-3') {
      const keys = Object.keys(validation.errors.errors);
      setCompleted({
        ...completed,
        stepDetails: stepDetailKeys.some((value) => keys.includes(value))
        ? 'invalid'  : 'active',
        stepParameters: isSectionInvalid(keys, 'parameters') ? 'invalid' : 'active',
        actionItem:  isSectionInvalid(keys, 'actions') ? 'invalid' : 'active',
        summary: keys.length > 0 ? "invalid" : "active"
      })
    }
    return validation.errors.errors;
  };

  return (
    <TabItems
      setData={setData}
      completed={completed}
      setCompleted={setCompleted}
      initialValues={initialValues}
      checkValidity={checkValidity}
    />
  )
};
