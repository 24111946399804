import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_BATCH,
  EDIT_PRODUCT,
  EDIT_PRODUCT_BATCH,
  GET_PRODUCT,
  GET_PRODUCTS,
  PUSH_TO_SHELF,
} from 'src/constants';

export const useInventoryApis = () => {
  const [
    fetchAllProducts,
    { loading: fetchAllProductsLoading, error: fetchAllProductsError },
  ] = useLazyQuery(GET_PRODUCTS);

  const [
    fetchProduct,
    { loading: fetchProductLoading, error: fetchProductError },
  ] = useLazyQuery(GET_PRODUCT);

  const [addProduct, { error: addProductError, loading: addProductLoading }] =
    useMutation(ADD_PRODUCT, { errorPolicy: 'all' });

  const [
    saveProduct,
    { error: saveProductError, loading: saveProductLoading },
  ] = useMutation(ADD_PRODUCT, { errorPolicy: 'all' });

  const [
    editProduct,
    { error: editProductError, loading: editProductLoading },
  ] = useMutation(EDIT_PRODUCT, { errorPolicy: 'all' });

  const [
    addProductBatch,
    { error: addProductBatchError, loading: addProductBatchLoading },
  ] = useMutation(ADD_PRODUCT_BATCH, { errorPolicy: 'all' });

  const [
    editProductBatch,
    { error: editProductBatchError, loading: editProductBatchLoading },
  ] = useMutation(EDIT_PRODUCT_BATCH, { errorPolicy: 'all' });

  const [
    pushToShelf,
    { error: pushToShelfError, loading: pushToShelfLoading },
  ] = useMutation(PUSH_TO_SHELF, { errorPolicy: 'all' });

  return {
    fetchAllProducts,
    fetchAllProductsLoading,
    fetchAllProductsError,
    fetchProduct,
    fetchProductError,
    fetchProductLoading,
    editProductError,
    editProductLoading,
    addProductBatchError,
    addProductBatchLoading,
    addProduct,
    addProductBatch,
    addProductLoading,
    addProductError,
    pushToShelf,
    pushToShelfError,
    pushToShelfLoading,
    editProduct,
    editProductBatch,
    editProductBatchError,
    editProductBatchLoading,
    saveProduct,
    saveProductError,
    saveProductLoading,
  };
};
