import { RRule } from 'rrule';

export * from './graphql';
export * from './Queries';
export * from './Mutations';
export * from './countries';
export * from './states';
export * from './enums';
export * from './validator';
export * from './measurementForms';
export const URL_PATTERN = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-zA-Z0-9-.]+ ?$/;
export const PASSWORD_PATTERN = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6, }$/;
export const FORMID_4 = 'add-hmo-form';
export const FORMID_3 = 'add-services-form';
export const FORMID_2 = 'invite-staff-form';
export const FORMID_1 = 'logo-upload-form';
export const FORMID_5 = 'staff-group-form';
export const NO_DEBT = 'You have no outstanding debt to settle';
export const NO_IMAGE_PLACEHOLDER = `https://via.placeholder.com/120.png?text=No+Image`;
export const PERMISSION_ERROR_MESSAGE = `You do not have permission to access this document`;
export const PERMISSION_ACTION_MESSAGE = `You do not have permission to perform this action`;
export const OWNER_ONBOARDING_STEPS: Array<OWNER_ONBOARDING_TYPE> = [
  'Logo',
  'StaffGroups',
  'Staff',
  'Services',
  'Success',
];
export type OWNER_ONBOARDING_TYPE =
  | 'Logo'
  | 'StaffGroups'
  | 'Staff'
  | 'Services'
  | 'Success';
export const HMO_PROVIDERS = [
  {
    hmo: 'Hygeia HMO',
    plans: ['Basic', 'Classic', 'Elite'],
  },
  {
    hmo: 'Total Health Trust',
    plans: ['Silver', 'Gold', 'Platinum'],
  },
  {
    hmo: 'Clearline HMO',
    plans: ['Essential', 'Comprehensive', 'Elite'],
  },
  {
    hmo: 'Redcare HMO',
    plans: ['Basic', 'Classic', 'Elite'],
  },
  {
    hmo: 'Axa Mansard HMO',
    plans: ['Family', 'Executive', 'Elite'],
  },
  {
    hmo: 'Niger Insurance HMO',
    plans: ['Essential', 'Comprehensive', 'Elite'],
  },
  {
    hmo: 'Salus Trust HMO',
    plans: ['Silver', 'Gold', 'Platinum'],
  },
  {
    hmo: 'Leadway HMO',
    plans: ['Starter', 'Classic', 'Elite'],
  },
  {
    hmo: 'Fidelis HMO',
    plans: ['Bronze', 'Silver', 'Gold'],
  },
  {
    hmo: 'Regal HMO',
    plans: ['Essential', 'Comprehensive', 'Elite'],
  },
  {
    hmo: 'Aviva HMO',
    plans: ['Family', 'Executive', 'Elite'],
  },
  {
    hmo: 'Medisafe HMO',
    plans: ['Basic', 'Classic', 'Elite'],
  },
  {
    hmo: 'HealthPlus HMO',
    plans: ['Essential', 'Comprehensive', 'Elite'],
  },
  {
    hmo: 'Optimum Health HMO',
    plans: ['Silver', 'Gold', 'Platinum'],
  },
  {
    hmo: 'Armstrong Health HMO',
    plans: ['Basic', 'Classic', 'Elite'],
  },
  {
    hmo: 'Trustcare HMO',
    plans: ['Essential', 'Comprehensive', 'Elite'],
  },
  {
    hmo: 'AfriLife HMO',
    plans: ['Family', 'Executive', 'Elite'],
  },
  {
    hmo: 'Regency HMO',
    plans: ['Silver', 'Gold', 'Platinum'],
  },
  {
    hmo: 'Healthways HMO',
    plans: ['Essential', 'Comprehensive', 'Elite'],
  },
];

export const ENTRY_TYPE = ['Required', 'Optional'];
export const INFORMATION_TYPE = ['Text', 'Staff', 'StaffGroup'];
export const NO_DETAILS = `Details not avaialble`;
export const ACTIONS_TYPE = [
  'Manual Action',
  'Take Vitals',
  'Create Invoice',
  'Log Medication',
  'Add Record',
  'Take Note',
  'Add Allergy',
];

export const HMO_OVERVIEW_DATA = [
  {
    title: 'Total Debt - Pending Invoice Value',
    amount: 9234566.57,
    stats: 90.7,
  },
  {
    title: 'Last Settlement Amount',
    amount: 89024.57,
    stats: 70,
  },
  {
    title: 'Total Invoice Amount',
    amount: 10024449.57,
    stats: 63.5,
  },
  {
    title: 'Number of Patients',
    numberOfPatients: 256,
    stats: -7.5,
  },
  {
    title: 'Total Number of Invoices',
    numberOfInvoices: 73,
    stats: 3.5,
  },
];

export const ACTIVE = `active`;
export const DOES_NOT_REPEAT = `does not repeat`;
export const EXPIRED_APPOINTMENT = `Appointment has expired`;
export const NOTE = `Note`;
export const DEFAULT_NOTES = `General Note`;
export const TOTAL_APPOINTMENT_MINUTES = 24 * 60;
export const WEEK_TYPE = [
  'this week',
  'last week',
  '2 weeks ago',
  '3 weeks ago',
  '4 weeks ago',
  '5 weeks ago',
];

export const DAYS_ORDER = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const ACTION_LIST = [
  {
    label: 'Switch Workspace',
    value: '#switch-workspace',
    checked: false,
  },
  {
    label: 'Log Out',
    value: '#log-out',
    checked: false,
  },
];

export const FREQUENCY_SET = {
  [RRule.DAILY]: 'DAILY',
  [RRule.WEEKLY]: 'WEEKLY',
  [RRule.MONTHLY]: 'MONTHLY',
  [RRule.YEARLY]: 'YEARLY',
} as const;

export enum APPLY_TO {
  'This' = 'This',
  'ThisAndFuture' = 'ThisAndFuture',
}

export const WEEKLY_SET = {
  [RRule.MO as unknown as string]: 'MONDAY',
  [RRule.TU as unknown as string]: 'TUESDAY',
  [RRule.WE as unknown as string]: 'WEDNESDAY',
  [RRule.TH as unknown as string]: 'THURSDAY',
  [RRule.FR as unknown as string]: 'FRIDAY',
  [RRule.SA as unknown as string]: 'SATURDAY',
  [RRule.SU as unknown as string]: 'SUNDAY',
} as const;

export const MONTHLY_SET = {
  1: 'JAN',
  2: 'FEB',
  3: 'MAR',
  4: 'APR',
  5: 'MAY',
  6: 'JUN',
  7: 'JUL',
  8: 'AUG',
  9: 'SEP',
  10: 'OCT',
  11: 'NOV',
  12: 'DEC',
} as const;

export const WeekValues = {
  MONDAY: RRule.MO,
  TUESDAY: RRule.TU,
  WEDNESDAY: RRule.WE,
  THURSDAY: RRule.TH,
  FRIDAY: RRule.FR,
  SATURDAY: RRule.SA,
  SUNDAY: RRule.SU,
} as const;

export const MonthValues = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
} as const;

export const NEXT_OF_KINS = [
  'mother',
  'father',
  'brother',
  'sister',
  'child',
  'cousin',
  'husband',
  'wife',
  'uncle',
  'aunt',
  'friend',
  'other',
];

export const AGE_GROUP = [
  {
    label: '0 - 10',
    value: 'AGE_0_10',
  },
  {
    label: '11 - 20',
    value: 'AGE_11_20',
  },
  {
    label: '21 - 30',
    value: 'AGE_21_30',
  },
  {
    label: '31 - 40',
    value: 'AGE_31_40',
  },
  {
    label: '41 - 50',
    value: 'AGE_41_50',
  },
  {
    label: '51 - 60',
    value: 'AGE_51_60',
  },
  {
    label: '61 - 70',
    value: 'AGE_61_70',
  },
  {
    label: '71 - 80',
    value: 'AGE_71_80',
  },
  {
    label: '81 - 90',
    value: 'AGE_81_90',
  },
  {
    label: '91 - 100',
    value: 'AGE_91_100',
  },
  {
    label: '100 Above',
    value: 'AGE_100_ABOVE',
  },
];

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];

export const SETTLEMENT_SCORE = [
  {
    label: 'Poor',
    value: 'Poor',
  },
  {
    label: 'Fair',
    value: 'Fair',
  },
  {
    label: 'Satisfactory',
    value: 'Satisfactory',
  },
];

export const IDENTITY_TYPE = [
  'National Id card',
  'Passport',
  'Permanent Voters card (PVC)',
  'Others',
];

export const GENERAL_INFORMATION_KEY = [
  'First Name',
  'Last Name',
  'Date of Birth',
  'Gender',
  'Marital Status',
  'Nationality',
  'Languages Spoken',
];

export const CONTACT_INFORMATION_KEY = [
  'Email Address',
  'Phone Number',
  'Address',
];

export const NEXT_OF_KIN_INFROMATION_KEY = [
  'Full Name',
  'Relationship',
  'Email Address',
  'Phone Number',
];

export const APPOINTMENT_TYPE = [
  'Doctor Visit',
  'Regular Checkup',
  'Surgery',
  'Dental Checkup',
  'Optician Visit',
  'Laboratory Test',
  'Consultation Visit',
  'Emergency',
  'Patient Admission',
];

export const APPOINTMENT_SCHEDULE = [
  'Does not Repeat',
  'Daily',
  'Weekly on Monday',
  'Monday to Friday',
  'Monthly on  First Monday',
  'Monday to Friday',
  'Monthly on  First Monday',
];

export const SEVERITY_LEVEL = ['critical', 'mild', 'moderate'];

export const GENDER_ARRAY = ['male', 'female'];

export const STAFF_STATUS_ARRAY = ['Active', 'Pending', 'Disabled'];

export const TIMEOUT = 500;

export const AVATAR_PLACHOLDERS = [
  'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
];

export const STAFF_PLACEHOLDER = [
  {
    fullName: 'Sevan Quame G',
    email: 'test1@gmail.com',
    staffgroup: 'Doctor',
    status: 'Active',
    facility: 'Ikeja Branch',
    permissions: ['Read', 'Write', 'Delete'],
  },
  {
    fullName: 'Sevan Quame G',
    email: 'test2@gmail.com',
    staffgroup: 'Doctor',
    status: 'Active',
    facility: 'Ikeja Branch',
    permissions: ['Read', 'Write', 'Delete'],
  },
  {
    fullName: 'Sevan Quame G',
    email: 'test2@gmail.com',
    staffgroup: 'Doctor',
    status: 'Active',
    facility: 'Ikeja Branch',
    permissions: ['Read', 'Write', 'Delete'],
  },
  {
    fullName: 'Sevan Quame G',
    email: 'test2@gmail.com',
    staffgroup: 'Doctor',
    status: 'Active',
    facility: 'Ikeja Branch',
    permissions: ['Read', 'Write', 'Delete'],
  },
];

export const LOG_ERROR = 'LOG_ERROR';
export const INIT = 'INIT';
export const SET_LOADING = 'SET_LOADING';
export const API_URL = 'https://ankora-api-test.herokuapp.com/graphql';
export const AVATAR_PLACHOLDER =
  'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20';
export const DOCTORS = [
  // {
  //   id: 1,
  //   name: 'Dr. John Doe',
  //   profileImage:
  //     'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  // },
  // {
  //   id: 2,
  //   name: 'Dr. John Doeette',
  //   profileImage:
  //     'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  // },
  {
    id: 3,
    name: 'Dr. Johnette',
    profileImage:
      'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  },
  {
    id: 4,
    name: 'Dr. Johnette',
    profileImage:
      'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  },
  {
    id: 5,
    name: 'Dr. Johnette',
    profileImage:
      'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  },
  {
    id: 6,
    name: 'Dr. Johnette',
    profileImage:
      'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  },
  {
    id: 7,
    name: 'Dr. Johnette',
    profileImage:
      'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  },
  {
    id: 8,
    name: 'Dr. Johnette',
    profileImage:
      'https://avatars.dicebear.com/api/initials/Marvin%20tunji.svg?background=%23BCBCBC&size=20',
  },
];

export const APPOINTMENTS = [
  {
    physician: {
      name: 'Dr John Doe.',
      id: 3,
    },
    patient: {
      name: 'Asake 2',
      id: 2,
    },
    appointmentType: 'Board meeting 3',
    start: new Date(2022, 2, 18, 5, 29, 0),
    end: new Date(2022, 2, 18, 6, 0, 0),
    appointmentDate: new Date(2022, 2, 18, 0, 0, 0),
    id: 3,
    color: 'blue',
  },
  {
    physician: {
      name: 'Dr John Doe.',
      id: 3,
    },
    patient: {
      name: 'Asake 2',
      id: 2,
    },
    appointmentType: "Asake's checkup",
    start: new Date(2022, 2, 17, 6, 29, 0),
    end: new Date(2022, 2, 17, 10, 0, 0),
    appointmentDate: new Date(2022, 2, 17, 0, 0, 0),
    id: 3,
    color: 'red',
  },
  {
    physician: {
      name: 'Dr John Doe.',
      id: 3,
    },
    patient: {
      name: 'Asake 2',
      id: 2,
    },
    appointmentType: "Tobi's checkup",
    start: new Date(2022, 2, 17, 8, 29, 0),
    end: new Date(2022, 2, 17, 10, 0, 0),
    appointmentDate: new Date(2022, 2, 17, 0, 0, 0),
    id: 3,
    color: 'green',
  },
  {
    physician: {
      name: 'Dr John Doe.',
      id: 3,
    },
    patient: {
      name: 'Asake 2',
      id: 2,
    },
    appointmentType: "Tobe's checkup",
    start: new Date(2022, 2, 17, 6, 0, 0),
    end: new Date(2022, 2, 17, 8, 0, 0),
    appointmentDate: new Date(2022, 2, 17, 0, 0, 0),
    id: 3,
    color: 'red',
  },
];

export const FACILITIES = [
  {
    facilityName: 'Ikeja Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 30,
    country: 'Nigeria',
    id: `${Date.now()}100`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'Oluyole Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 24,
    country: 'Nigeria',
    id: `${Date.now()}200`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'Allen Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 30,
    country: 'Nigeria',
    id: `${Date.now()}300`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'Lekki Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 24,
    country: 'Nigeria',
    id: `${Date.now()}400`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'Jakande Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 30,
    country: 'Nigeria',
    id: `${Date.now()}500`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'Yaba Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 24,
    country: 'Nigeria',
    id: `${Date.now()}600`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'Lagos Island Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 30,
    country: 'Nigeria',
    id: `${Date.now()}700`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
  {
    facilityName: 'VI Branch',
    facilityAddress: 'No 6, Castle Rock Avenue, Ikeja Gra, Lagos',
    numberOfStaff: 24,
    country: 'Nigeria',
    id: `${Date.now()}800`,
    dateAdded: '2022-02-13T08:11:51.973Z',
  },
];

export const SERVICES = [
  {
    id: `${Date.now()}`,
    serviceCode: 'FBC001',
    description: 'Full Body Check up',
    facility: 'Ikeja',
    amount: 4000,
  },
  {
    id: `${Date.now()}300`,
    serviceCode: 'CVD019',
    description: 'Covid Test & Vaccination',
    facility: 'Lekki',
    amount: 30000,
  },
];

export const servicePaymentMethod = [
  {
    label: 'NO',
    value: 'OOP',
  },
  {
    label: 'YES',
    value: 'HMO',
  },
];

export const serviceCategoryOption = [
  {
    label: 'Others',
    value: 'OTHERS',
  },
  {
    label: 'Laboratory',
    value: 'LABORATORY',
  },
];
