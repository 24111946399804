import { useQuery } from '@apollo/client';
import { useContext, useState, useEffect } from 'react';
import {
  GET_ALL_RECORD_TEMPLATE,
  GET_RECORD_TEMPLATE,
  SEARCH_RECORD_TEMPLATE
} from 'src/constants';
import { PaginatedTemplates, RecordTemplate } from 'src/types';
import { useApolloSearch } from 'src/hooks';
import RecordTemplateContext from './context';
import { useRowData } from '../rowData';
import { useToast } from '../toast';


const useQueries = () => {
  const limit = 10;
  const { showToast } = useToast();
  const { updateRowData, getDataByKey } = useRowData();
  const { 
    recordTemplates, 
    setRecordTemplates 
  } = useContext(RecordTemplateContext);
  
  const currentRecordTemplate = getDataByKey('recordTemplate') as RecordTemplate
  const [
    searchRecordTemplates, 
    setSearchRecordTemplates 
  ] = useState<PaginatedTemplates>({} as PaginatedTemplates);
  const { globalTemplates, otherTemplates } = recordTemplates;


  const getPageNumber = (docLength: number) => Math.ceil(docLength / 10);
  const globalTemplatesPage =
    getPageNumber(globalTemplates?.recordTemplates?.length) || 1;
  const otherTemplatesPage =
    getPageNumber(otherTemplates?.recordTemplates?.length) || 1;
  const searchRecordTemplatePage =
    getPageNumber(searchRecordTemplates?.recordTemplates?.length) || 1;
 

  const useFetchRecordTemplate = (id: string, skip?: boolean) => {
    const result = useQuery(GET_RECORD_TEMPLATE, {
      errorPolicy: 'all',
      skip,
      variables: { input: id },
      onCompleted: (d) => updateRowData("recordTemplate", d.getRecordTemplate)
    });

    return { ...result };
  };


  const useFetchRecordTemplates = (skip?: boolean) => {
    const [page1, setPage1] = useState<number>(1);
    const [page2, setPage2] = useState<number>(1);

    const variables = {
      input: {
        otherTemplatesInput: {
          page: page1,
          limit,
        },
        globalTemplatesInput: {
          page: page2,
          limit,
        }
      }
    }

    const result = useQuery(GET_ALL_RECORD_TEMPLATE, {
      variables,
      errorPolicy: 'all',
      skip,
      onCompleted: (d) => {
        setLoadMoreGlobalTemplatesLoading(false);
        setLoadMoreOtherTemplatesLoading(false);

        setRecordTemplates((prev) => ({
          ...prev,
          pinnedTemplates: d.getRecordTemplates.pinnedTemplates,
          recentTemplates: d.getRecordTemplates.recentTemplates,
          globalTemplates: 
            (result.variables?.input.globalTemplatesInput.page || 1) > 1
            ? {
                recordTemplates: [
                  ...recordTemplates.globalTemplates.recordTemplates, 
                  ...d.getRecordTemplates.globalTemplates.recordTemplates
                ],
                total: recordTemplates.globalTemplates.total
              }
            : d.getRecordTemplates.globalTemplates,
          otherTemplates:
            (result.variables?.input.otherTemplatesInput.page || 1) > 1
            ? {
                recordTemplates: [
                  ...recordTemplates.otherTemplates.recordTemplates, 
                  ...d.getRecordTemplates.otherTemplates.recordTemplates
                ],
                total: recordTemplates.otherTemplates.total
              }
            : d.getRecordTemplates.otherTemplates,
        }));
      },
    });

    const [loadMoreGlobalTemplatesLoading, setLoadMoreGlobalTemplatesLoading] = useState(false);
    const [loadMoreOtherTemplatesLoading, setLoadMoreOtherTemplatesLoading] = useState(false);
    const loadMoreGlobalTemplates = () => setPage2((prev) => prev + 1);
    const loadMoreOtherTemplates = () => setPage1((prev) => prev + 1);


    useEffect(() => {
      if (result.loading && (result.variables?.input.globalTemplatesInput.page || 1) > 1) {
        setLoadMoreGlobalTemplatesLoading(result.loading)
      }
      if (result.loading && (result.variables?.input.otherTemplatesInput.page || 1) > 1) {
        setLoadMoreOtherTemplatesLoading(result.loading)
      }
    }, [result.loading, result.variables?.input.globalTemplatesInput.page, result.variables?.input.otherTemplatesInput.page])

    return {
      ...result,
      loadMoreGlobalTemplates,
      loadMoreOtherTemplates,
      loadMoreGlobalTemplatesLoading,
      loadMoreOtherTemplatesLoading,
    };
  };


  const useSearchRecordTemplates = (skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();

    const result = useQuery(SEARCH_RECORD_TEMPLATE, {
      errorPolicy: 'all',
      skip: skip || !searchValue,
      variables: { 
        input: { 
          search: searchValue,
          page: 1
        }
      },
      onCompleted: (d) => {
        if ((result.variables?.input.page || 1)  > 1) {
          return setSearchRecordTemplates((prevState) => ({
            ...prevState,
            recordTemplates: [
              ...prevState.recordTemplates,
              ...d.searchRecordTemplates.recordTemplates,
            ],
            total: d.searchRecordTemplates.total,
          })); 
        }
        return setSearchRecordTemplates(d.searchRecordTemplates)
      },
      onError: (err) => showToast(err.message, 'error')
    });

    const [loadMoreTemplatesLoading, setLoadMoreTemplatesLoading] = useState(false);

    const loadMoreTemplates = async () => {
      setLoadMoreTemplatesLoading(true);
      try {
        await result.refetch({ 
          input: {
            search: searchValue,
            page: searchRecordTemplatePage + 1
          }
        });
      } finally {
        setLoadMoreTemplatesLoading(false);
      }
    }

    return { 
      ...result, 
      searchValue, 
      ...others, 
      loadMoreTemplates, 
      loadMoreTemplatesLoading 
    };
  };


  const templatesData = [
    ...(recordTemplates?.pinnedTemplates || []),
    ...(recordTemplates?.recentTemplates || []),
    ...(recordTemplates?.otherTemplates?.recordTemplates || []),
    ...(recordTemplates?.globalTemplates?.recordTemplates || []),
    ...(searchRecordTemplates?.recordTemplates || []),
  ];


  return {
    searchRecordTemplates,
    setSearchRecordTemplates,
    globalTemplatesPage,
    otherTemplatesPage,
    searchRecordTemplatePage,
    recordTemplates,
    setRecordTemplates,
    useFetchRecordTemplate,
    useFetchRecordTemplates,
    useSearchRecordTemplates,
    currentRecordTemplate,
    templatesCount: templatesData?.length
  };
};

export default useQueries;