/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import { Invoice } from 'src/types';

export type InventoryInvoiceType = {
  invoices: Invoice[];
  total: number;
};

const initialState = {} as InventoryInvoiceType;

type PayloadType = {
  payload: InventoryInvoiceType;
};

const inventorySlice = createSlice({
  name: 'inventory-invoice',
  initialState,
  reducers: {
    setInvoices: (state: InventoryInvoiceType, action: PayloadType) => {
      const { invoices, total } = action.payload;
      state.invoices = invoices;
      state.total = total;
    },
  },
});

export const { setInvoices } = inventorySlice.actions;

export default inventorySlice.reducer;
