import React from 'react';
import { ArrowUpIcon } from 'src/assets/icons';
import { ChildrenWrapper, AccordionTitle, Icon } from './styled';

interface AccordionProps {
  openByDefault?: boolean
}

interface Accordion extends React.FC<AccordionProps> {
  Header: typeof AccordionHeader;
  Content: typeof AccordionContent;
}

export const Accordion: Accordion = ({ children, openByDefault = true }) => {
  const [isOpen, setIsOpen] = React.useState(openByDefault);
  const toggleOpen = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);
  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, {
          isOpen,
          toggleOpen
        });
      })}
    </>
  );
};

const AccordionHeader: React.FC<{
  children: React.ReactNode;
  toggleOpen?: () => void;
  isOpen?: boolean;
  className?: string;
  hasArrowIcon?: boolean;
}> = ({ children, isOpen, toggleOpen, className, hasArrowIcon = false }) => {
  return (
    <AccordionTitle
      align="center"
      className={className}
      justify="space-between"
      onClick={(e) => {
        e.stopPropagation();
        if (toggleOpen) {
          toggleOpen();
        }
      }}
    >
      {children}
      {hasArrowIcon && <Icon><ArrowUpIcon className={isOpen ? "accordion-transition" : ""} /></Icon>}
    </AccordionTitle>
  );
};

const AccordionContent: React.FC<{
  children: React.ReactNode;
  isOpen?: boolean;
  show?: boolean;
  className?: string;
}> = ({ children, isOpen, className, show = true }) => {
  return (isOpen && show) ? <ChildrenWrapper className={className}>{children}</ChildrenWrapper> : <></>;
};

Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;
