import { InputLabel } from "src/components";
import { theme } from "src/utils";
import styled from "styled-components";

export const LabelButton = styled(InputLabel)`
  width: 8.32rem;
  margin-block: 0; 
  font-size: 12px;
  line-height: 14.3px;
  color: #fff;
  background: ${theme.primary[700]};
  padding-block: 10.86px; 
  padding-inline: 13.57px;
  border-radius: 0.42rem; 
  display: flex;
  justify-content: center;
  cursor: pointer;
  & > input {
    display: none;
  }
`;