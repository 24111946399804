import { Button, Column, H6, Text } from 'src/components';
import { useLocation } from 'wouter';
import { useContext, useEffect, useState } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { strictBatchValidation, getModalParams } from 'src/utils';
import { useFormik } from 'formik';
import { ProductBatchType } from 'src/types';
import Validator from 'validatorjs';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { SolidCheck } from 'src/assets/icons';
import { useToast } from 'src/state';
import { useInventoryApis } from 'src/store';
import { GET_PRODUCTS, GET_PRODUCT } from 'src/constants';
import { ViewContext } from '.';
import BatchForm from '../components/BatchForm';

const defaultState: { batch: ProductBatchType } = {
  batch: {
    batchNumber: '',
    quantity: 0,
    buyingPrice: 0,
    sellingPrice: 0,
    expiryAlert: '',
    stockAlert: 0,
    expiryDate: '',
    status: ['IN_STOCK'],
    pushToShelf: false,
  },
};

const AddBatch = () => {
  const [, setLocation] = useLocation();
  const [batchValidation, setBatchValidation] = useState<
    Record<string, string>
  >(strictBatchValidation);
  const { setView } = useContext(ViewContext);
  const productId = getModalParams('id');

  const { addProductBatch, addProductBatchLoading } = useInventoryApis();
  const { showToast } = useToast();

  const goBack = () => {
    setView('details');
    setLocation(`#product&id=${productId}&mView=details`);
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    setErrors,
  } = useFormik({
    initialValues: { ...defaultState },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(validationValues, batchValidation);
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: async (value) => {
      const { expiryAlert } = value.batch;
      await addProductBatch({
        variables: {
          productId,
          input: {
            ...value.batch,
            expiryAlert: !expiryAlert ? 0 : expiryAlert,
          },
        },
        refetchQueries: [
          GET_PRODUCTS,
          { query: GET_PRODUCT, variables: { input: productId } },
        ],
        awaitRefetchQueries: true,
        onCompleted(d) {
          if (d?.addProductBatch) {
            showToast(d.addProductBatch?.message, 'success');
            goBack();
          }
        },
      });
      return value;
    },
  });

  useEffect(() => {
    if (values.batch.status[0] === 'ORDER_PLACED') {
      setErrors({});
      setBatchValidation({});
    }
  }, [setErrors, values.batch.status]);

  return (
    <ViewWrapper goBack={goBack}>
      <Column gap={1.5}>
        <Column gap={0.3}>
          <H6 modStyles={{ ma: 0 }}>Add Batch</H6>
          <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
            Add a new batch to product
          </Text>
        </Column>
        <FullWidthForm onSubmit={handleSubmit}>
          <BatchForm
            values={values.batch}
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={handleChange}
          />
          <Button
            variant="solid"
            width="max-content"
            type="submit"
            data-testid="submit-batch-btn"
            isLoading={addProductBatchLoading}
            modStyles={{ ml: 'auto' }}
            noIconStyles
          >
            <SolidCheck /> Add New Batch
          </Button>
        </FullWidthForm>
      </Column>
    </ViewWrapper>
  );
};

export default AddBatch;
