import { useState } from 'react';
import _ from 'lodash';
import { FormikErrors } from 'formik';
import { FileFolder } from 'src/assets/icons';
import {
  AsyncSearchInput,
  Checkbox,
  Column,
  Input,
  MultiSelect,
  Row,
  Select,
  Textarea,
} from 'src/components';
import { getFormErrorMessage } from 'src/utils';
import { Drug, ProductDetailsType } from 'src/types';
import { SEARCH_DRUGS, measurementForms } from 'src/constants';
import mixpanel from 'mixpanel-browser';
import { FlexContainer, SectionHeader } from '../styled';
import ProductExtraInfo from './ProductExtraInfo';

export type ProductFormErrors = FormikErrors<Record<string, unknown>>;

interface ProductFormProps {
  values: ProductDetailsType;
  errors: ProductFormErrors;
  setFieldValue?: (
    field: string,
    value: number | string | boolean | string[],
  ) => void;
  handleChange: (e: React.ChangeEvent) => void;
  onDuplicateFound?: (productId: string) => void;
}

const productCategories = [
  'Medical',
  'Non-Medical',
  'Liquid',
  'Solid',
  'Perishable',
  'Non-Perishable',
];

const getDrugExtraInfo = (drug: Drug) =>
  `Form: ${drug.form || 'N/A'}, Ingredients: ${
    drug.ingredients || 'N/A'
  }, Strength: ${drug.strength || 'N/A'}`;

const renderDrugItem = (drug: Record<string, unknown>) => {
  const d = drug as unknown as Drug;
  const extraInfo = getDrugExtraInfo(d).replace('&amp;', '&');
  return (
    <ProductExtraInfo
      name={d.name}
      brandName={d.brandName}
      extraInfo={extraInfo}
    />
  );
};

const ProductForm: React.FC<ProductFormProps> = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  onDuplicateFound,
}) => {
  const [product, setProduct] = useState<Drug>();
  const handleMultiSelectChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
    value?: string[],
  ) => {
    if (value === undefined) {
      setFieldValue?.(name, [e.target.value]);
    } else if (e.target.checked) {
      setFieldValue?.(name, [...(value as Array<string>), e.target.value]);
    } else {
      setFieldValue?.(
        name,
        [...(value as Array<string>)].filter((val) => val !== e.target.value),
      );
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (product) setProduct(undefined);
    handleChange(e);
  };
  const handleSearchSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    d?: Record<string, unknown>,
  ) => {
    const drug = d as unknown as Drug;
    if (drug.productId) {
      onDuplicateFound?.(drug.productId);
      return;
    }
    setProduct(drug);
    setFieldValue?.('details.name', drug.name);
    setFieldValue?.('details.brandName', drug.brandName);
    setFieldValue?.('details.form', drug.form);
    setFieldValue?.('details.drugId', drug.drugId);
    setFieldValue?.('details.extraInfo', getDrugExtraInfo(drug));
  };

  return (
    <Column gap={1} minWidth="50vw">
      <SectionHeader justify="space-between">
        <Row gap={0.5} align="center" width="max-content">
          <FileFolder />
          Product Details
        </Row>
      </SectionHeader>
      <Column>
        <AsyncSearchInput
          label="Product Name"
          name="details.name"
          emptyText="No item found"
          error={getFormErrorMessage(errors, 'details.name')}
          placeholder="Search or choose product"
          dataTestId="search-drug"
          value={values.name}
          required
          query={SEARCH_DRUGS}
          handleSelect={handleSearchSelect}
          handleChange={handleSearchChange}
          render={renderDrugItem}
          selectors={{
            dataSelector: 'searchDrugs.drugs',
            valueSelector: 'name',
            labelSelector: 'name',
            totalSelector: 'searchDrugs.total',
          }}
        />
        <Textarea
          id="extra-information"
          label="Extra Information"
          required
          value={values.extraInfo}
          readOnly={!!product}
          name="details.extraInfo"
          placeholder="e.g Amoxicillin (Amoxicillin Trihydrate); Clavulanic Acid (Potassium Clavulanate) "
          onChange={handleChange}
          error={getFormErrorMessage(errors, 'details.extraInfo')}
        />
        <Row gap={1}>
          <MultiSelect
            id="product-category"
            dataTestId="product-categories"
            name="details.categories"
            required
            placeholder="Select Categories"
            label="Product Category"
            defaultSelected={values.categories}
            onChange={(e) =>
              handleMultiSelectChange(
                e,
                'details.categories',
                values.categories,
              )}
            error={getFormErrorMessage(errors, 'details.categories')}
            options={productCategories.map((category) => ({
              label: category,
              value: category,
            }))}
          />
          <Input
            id="brand-name"
            label="Brand Name"
            value={values.brandName}
            required
            readOnly={!!product}
            name="details.brandName"
            placeholder="e.g Emzor"
            onChange={handleChange}
            error={getFormErrorMessage(errors, 'details.brandName')}
          />
        </Row>
        <Row gap={1} align="center">
          <FlexContainer>
            <Select
              id="measurement-form"
              value={values.form}
              label="Measurement/Form"
              name="details.form"
              required
              disabled={!!product}
              onChange={handleChange}
              error={getFormErrorMessage(errors, 'details.form')}
            >
              {[
                { label: `Select Measurement/Form`, value: undefined },
                ...measurementForms.map((form) => ({
                  label: _.capitalize(form),
                  value: form,
                })),
              ].map(({ label, value: val }, i) => (
                <option key={i} value={val} data-testid={val}>
                  {label}
                </option>
              ))}
            </Select>
          </FlexContainer>
          <FlexContainer modStyles={{ mt: 1.5 }}>
            <Checkbox
              data-testid="otc"
              activeColor="green"
              label="Over the Counter (OTC)"
              checked={values.isOTC}
              name="details.isOTC"
              onChange={handleChange}
              onClick={() => {
                mixpanel.track(`Clicked OTC checkbox`, {
                  feature: 'Inventory Product Form',
                });
              }}
            />
          </FlexContainer>
        </Row>
      </Column>
    </Column>
  );
};

export default ProductForm;
