import { Row, Column, Input, Select } from 'src/components';
import { DeleteIcon } from 'src/assets/icons';
import { MultiRowForm } from 'src/containers';
import { theme } from 'src/utils/variables';
import {
  FORMID_3,
  serviceCategoryOption,
  servicePaymentMethod,
} from 'src/constants';
import { useServices, useStaff } from 'src/state';
import { FormikErrors, useFormik } from 'formik';
import Validator from 'validatorjs';
import { addServiceValidation, removeModalHash } from 'src/utils';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import { MainInputGroup, InnerInputGroup, FullWidthForm } from '../styled';

const defaultState = {
  services: [
    {
      serviceCode: '',
      description: '',
      amount: '',
      category: 'OTHERS',
      isHMOPayable: 'OOP',
    },
  ],
};

const ServicesMultiRowForm: React.FC<{
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  onComplete?: () => void;
}> = ({ onComplete, setLoading }) => {
  const { addService } = useServices();
  const { staff } = useStaff();
  const isOwnerOnboarding = window.location.hash.includes('#owner-onboarding');

  const { handleChange, handleSubmit, setFieldValue, values, errors } =
    useFormik({
      initialValues: { ...defaultState },
      validateOnChange: false,
      enableReinitialize: true,
      validate: (validationValues) => {
        const validation = new Validator(
          validationValues,
          addServiceValidation,
        );
        validation.passes();
        return validation.errors.errors;
      },
      onSubmit: async (value) => {
        setLoading?.(true);
        const services = value.services.map(({ ...sv }) => ({
          ...sv,
          isHMOPayable: sv.isHMOPayable === 'HMO',
        }));
        await addService({ services }, staff.getStaff.facility.id).then(
          (res) => {
            if (res?.data) {
              if (!isOwnerOnboarding) {
                removeModalHash();
              }
              onComplete?.();
            }
          },
        );
        setLoading?.(false);
      },
    });

  const addNewServicesRow = () => {
    setFieldValue('services', [...values.services, defaultState.services[0]]);
  };

  const deleteRow = (index: number) => {
    if (values.services.length === 1) return;
    const services = [...values.services];
    services.splice(index, 1);
    setFieldValue('services', services);
  };

  const getErrorMessage = (idx: number, field: string) => {
    return (
      (errors as FormikErrors<Record<string, unknown>>)[
        `services.${idx}.${field}`
      ] &&
      (
        (errors as FormikErrors<Record<string, unknown>>)[
          `services.${idx}.${field}`
        ] as unknown as string[]
      )[0]
        .split(' ')
        .splice(2)
        .join(' ')
    );
  };

  return (
    <FullWidthForm id={FORMID_3} noValidate onSubmit={handleSubmit}>
      <Column width="100%" gap={1}>
        <MultiRowForm
          shouldShowSuggestion={
            values.services[values.services.length - 1].serviceCode !== '' ||
            values.services[values.services.length - 1].description !== ''
          }
          onAddNewRow={addNewServicesRow}
        >
          {values.services.map(
            (
              { description, amount, serviceCode, category, isHMOPayable },
              index,
            ) => (
              <Column key={index}>
                <MainInputGroup gap={1} align="flex-end">
                  <InnerInputGroup
                    data-testid="row-input-group"
                    align="center"
                    justify="space-between"
                    width="100%"
                    gap={1}
                  >
                    <Input
                      label="Service Code"
                      data-testid="service-code"
                      placeholder="Leave blank to auto-generate"
                      type="text"
                      name={`services[${index}].serviceCode`}
                      value={serviceCode}
                      onChange={handleChange}
                    />
                    <Input
                      label="Short Description"
                      required
                      data-testid="service-description"
                      placeholder="pregnancy test"
                      name={`services[${index}].description`}
                      type="text"
                      value={description}
                      onChange={handleChange}
                      error={getErrorMessage(index, 'description')}
                    />
                    <Input
                      label="Amount (₦)"
                      data-testid="service-amount"
                      placeholder="₦5000"
                      required
                      name={`services[${index}].amount`}
                      type="text"
                      value={amount.replace(/\D/g, '')}
                      onChange={handleChange}
                      error={getErrorMessage(index, 'amount')}
                    />
                    <Select
                      label="Service Category"
                      name={`services[${index}].category`}
                      onChange={handleChange}
                      value={category}
                    >
                      {serviceCategoryOption?.map(({ label, value }, idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                    <Select
                      label="HMO Payable"
                      name={`services[${index}].isHMOPayable`}
                      onChange={handleChange}
                      value={isHMOPayable}
                    >
                      {servicePaymentMethod?.map(({ label, value }, idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  </InnerInputGroup>
                  <Row width="2.875rem">
                    <MultiRowDeleteButton
                      variant="solid"
                      data-testid="delete-service-row"
                      background={theme.grey[100]}
                      color={theme.grey[200]}
                      size="2.5rem"
                      type="button"
                      onClick={() => deleteRow(index)}
                    >
                      <DeleteIcon />
                    </MultiRowDeleteButton>
                  </Row>
                </MainInputGroup>
              </Column>
            ),
          )}
        </MultiRowForm>
      </Column>
    </FullWidthForm>
  );
};

export default ServicesMultiRowForm;
