import Skeleton from 'react-loading-skeleton';
import { InvoiceImage } from 'src/assets/images';
import { Column, Row, SkeletonWrapper, Text } from 'src/components';
import useMediaQuery from 'src/utils/useMediaQuery';
import { theme } from 'src/utils/variables';
import { VerticalLine } from './styled';

const NoSteps = () => {
  const isMobile = !useMediaQuery('(min-width: 960px)');
  return (
    <Row
      height={isMobile ? 'calc(100vh - 20rem)' : '100%'}
      justify="center"
      gap={1}
      align="center"
    >
      <Column width="43%" gap={2.2} align="center" modStyles={{ pa: 2 }}>
        <InvoiceImage />
        <Text
          size={isMobile ? 'xs' : 'base'}
          weight="semibold"
          color={theme.grey[700]}
        >
          No Steps.
        </Text>
      </Column>
    </Row>
  );
};

export const StepLoader: React.FC = () => {
  return (
    <Column
      align="center"
      modStyles={{ mt: 1 }}
      gap={1}
      width="100%"
      minWidth="36vw"
    >
      <Row align="center">
        <Column gap={0.5}>
          <Skeleton width={80} height={10} style={{ marginRight: '5px' }} />
          <Skeleton width={250} height={10} style={{ marginRight: '5px' }} />
        </Column>
        <Skeleton borderRadius={10} width={50} />
      </Row>
      <Row align="center">
        <Skeleton
          circle
          width={50}
          height={50}
          style={{ marginRight: '5px' }}
        />
        <Skeleton width={250} height={10} style={{ marginRight: '5px' }} />
      </Row>
      <Column width="100%" align="center">
        <SkeletonWrapper>
          <Skeleton width="100%" height={60} style={{ marginBottom: '15px' }} />
          <Skeleton width="100%" height={30} />
        </SkeletonWrapper>
      </Column>
      <Row justify="space-between">
        <Skeleton width={80} height={40} />
        <Skeleton width={80} height={40} />
      </Row>
    </Column>
  );
};

export const StepsLoader: React.FC = () => {
  return (
    <Column align="center">
      <Column width="80%" align="center">
        <Skeleton width="5rem" height={40} borderRadius="1.0625rem" />
        <VerticalLine />
        <SkeletonWrapper>
          <Skeleton width="100%" height={50} borderRadius="1.0625rem" />
        </SkeletonWrapper>
        <VerticalLine />
        <SkeletonWrapper>
          <Skeleton width="100%" height={50} borderRadius="1.0625rem" />
        </SkeletonWrapper>
        <VerticalLine />

        <SkeletonWrapper>
          <Skeleton width="100%" height={50} borderRadius="1.0625rem" />
        </SkeletonWrapper>
        <VerticalLine />

        <Skeleton width="10rem" height={40} borderRadius="1.0625rem" />
      </Column>
    </Column>
  );
};

export const TemplateLoader: React.FC = () => {
  return (
    <Column align="center" modStyles={{ mt: 1 }}>
      <SkeletonWrapper>
        <Skeleton width="100%" height={320} borderRadius="0.5rem" />
      </SkeletonWrapper>
    </Column>
  );
};

export default NoSteps;
