import styled from 'styled-components';
import { Row } from 'src/components';

export const Icon = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 0.75rem;
    height: 0.5rem;
  }
`;

export const ChildrenWrapper = styled(Row)`
  padding: 1rem;
  flex-wrap: wrap;
`;

export const Details = styled.details`
  width: 100%;
  background-color: inherit;
  ${Icon} > svg {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
  }
  &[open] ${Icon} > svg {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }
  @media (max-width: 960px) {
  }
`;

export const AccordionTitle = styled(Row)`
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
  position: relative;
`;
