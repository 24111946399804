import {
  Button,
  Column,
  Row,
  Text,
  H6
} from 'src/components';
import { Modal } from 'src/containers';
import { removeModalHash } from 'src/utils/modalHelper';
// import { CancelIcon } from 'src/assets/icons';
import { FORMID_5 } from 'src/constants';
import { useState } from 'react';
import MultiForm from './multiForm';

const ManageStaffGroup: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  
  return (
    <Modal>
      <Column minWidth="61.25rem" gap={1}>
        <Row justify='space-between'>
          <Column gap={0.65}>
            <H6 color='#313131' modStyles={{ my: 0, lineHeight: '26.4px' }}>
              Manage Staff Groups
            </H6> 
            <Text color="#666" size="sm" weight="semibold" modStyles={{ my: 0, lineHeight: '18.48px' }}>
              Add, delete or edit staff groups 
            </Text>
          </Column>
          {/* <Button width="max-content" onClick={removeModalHash} variant="text">
            <CancelIcon className='stroke-width-0' />
          </Button> */}
        </Row>
        <Column width="100%" justify="space-between" gap={2}>
          <MultiForm setLoading={setLoading} onComplete={removeModalHash} />
          <Row width="100%" justify="space-between">
            <Button 
              type="button"
              onClick={removeModalHash} 
              width="max-content"
              background="neutral"
              color="black" 
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              form={FORMID_5}
              width="max-content"
              isLoading={loading}
            >
              Done
            </Button>
          </Row>
        </Column>
      </Column>
    </Modal>
  );
};
export default ManageStaffGroup;