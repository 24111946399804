export const PageCollectionIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="User Interface / Page Collection">
      <path
        id="Vector"
        d="M1.6685 7.28647L7.6685 10.7531C7.76985 10.8116 7.88481 10.8424 8.00184 10.8424C8.11886 10.8424 8.23382 10.8116 8.33517 10.7531L14.3352 7.28647C14.4362 7.22786 14.5201 7.14375 14.5783 7.04254C14.6366 6.94134 14.6673 6.82659 14.6673 6.7098C14.6673 6.59301 14.6366 6.47826 14.5783 6.37706C14.5201 6.27585 14.4362 6.19174 14.3352 6.13313L8.33517 2.66646C8.23382 2.60795 8.11886 2.57715 8.00184 2.57715C7.88481 2.57715 7.76985 2.60795 7.6685 2.66646L1.6685 6.13313C1.56748 6.19174 1.48362 6.27585 1.42533 6.37706C1.36704 6.47826 1.33635 6.59301 1.33635 6.7098C1.33635 6.82659 1.36704 6.94134 1.42533 7.04254C1.48362 7.14375 1.56748 7.22786 1.6685 7.28647ZM8.00184 4.01313L12.6685 6.6798L8.00184 9.3798L3.33517 6.70646L8.00184 4.01313ZM13.6685 9.20646L8.00184 12.4798L2.33517 9.20646C2.25948 9.16211 2.17578 9.13313 2.08886 9.12119C2.00195 9.10925 1.91353 9.11458 1.82868 9.13687C1.74384 9.15917 1.66422 9.19799 1.59441 9.25112C1.52459 9.30425 1.46595 9.37063 1.42184 9.44647C1.37776 9.52255 1.34917 9.60661 1.33771 9.6938C1.32626 9.78098 1.33216 9.86957 1.35509 9.95447C1.37802 10.0394 1.41751 10.1189 1.4713 10.1884C1.52509 10.258 1.59211 10.3162 1.6685 10.3598L7.6685 13.8265C7.76985 13.885 7.88481 13.9158 8.00184 13.9158C8.11886 13.9158 8.23382 13.885 8.33517 13.8265L14.3352 10.3598C14.4116 10.3162 14.4786 10.258 14.5324 10.1884C14.5862 10.1189 14.6257 10.0394 14.6486 9.95447C14.6715 9.86957 14.6774 9.78098 14.666 9.6938C14.6545 9.60661 14.6259 9.52255 14.5818 9.44647C14.5377 9.37063 14.4791 9.30425 14.4093 9.25112C14.3395 9.19799 14.2598 9.15917 14.175 9.13687C14.0901 9.11458 14.0017 9.10925 13.9148 9.12119C13.8279 9.13313 13.7442 9.16211 13.6685 9.20646Z"
        fill={color || '#848484'}
      />
    </g>
  </svg>
);
