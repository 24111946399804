import { Formik } from 'formik';
import { Column, Row, Input, Button, Text } from 'src/components';
import { RULES, SIZE_OPTIONS } from 'src/constants';
import { usePatients } from 'src/state';
import { theme, pinify } from 'src/utils';
import Validator from 'validatorjs';
import { PatientData, PatientProps } from 'src/types';
import { PatientsTabForm } from '../styled';

interface GetPatientDataProps {
  content: PatientData;
  setData: (value: string) => void;
  setPatientData: (value: PatientProps) => void;
}

export const GetPatientData: React.FC<GetPatientDataProps> = ({
  content,
  setData,
  setPatientData
}) => {
  const { useGrantAccess } = usePatients();
  const { loading, grantAccess } = useGrantAccess();

  return (
    <Column gap={1.75}>
      <Text color={theme.primary[700]}>
        Enter 6-digit code sent to the patient’s Email address (
        {content.user.emails[0]}) or Phone number (
        {pinify(content.basicInfo.phoneNumber)}). Try Another Method
      </Text>
      <Formik
        initialValues={{ code: '' }}
        validate={(values) => {
          const validation = new Validator(
            values,
            { code: RULES.code },
            SIZE_OPTIONS,
          );
          validation.passes();
          return validation.errors.errors;
        }}
        onSubmit={async (values) => {
          await grantAccess({
            ...values,
            patientId: content.id,
          })?.then((res) => {
            if (res?.data) {
              setPatientData(res?.data.grantFacilityAccessToPatientData.patient)
              setData(res?.data.grantFacilityAccessToPatientData.patient.id);
            }
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <PatientsTabForm>
            <Row gap={2}>
              <Input
                placeholder="Enter 6-digit code"
                name="code"
                autoFocus
                value={values.code.replace(/\D/g, '')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.code ? errors.code : ''}
              />
              <Button width="max-content" disabled={loading} type="submit" isLoading={loading}>
                Submit
              </Button>
            </Row>
          </PatientsTabForm>
        )}
      </Formik>
    </Column>
  );
};
