import styled from 'styled-components';
import { Button, Row, H6,Column } from 'src/components';
import { font, theme } from 'src/utils/variables';

export const MainInputGroup = styled(Row)`
  @media (max-width: 960px) {
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5;
    margin-bottom: 1rem;
  }
`;

export const ModalTitle = styled(H6)`
  font-size: 1.25rem;
  margin: 0;
  font-weight: ${font.weights.bold}
`;

export const InnerInputGroup = styled(Row)`
  @media (max-width: 960px) {
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5;
  }
`;
export const AddStaffButton = styled(Button)`
  width: max-content;
  path {
    fill: white;
  }
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const FullWidthForm = styled.form`
  width: 100%;
`;

export const HiddenContainer = styled(Column)<{
  display: "none" | "flex"
}>`
  display: ${(props) => `${props.display || "flex"}`};
`

export const DropdownButton = styled(Button)<{
  hasValue: boolean
}>`
  width: 100%;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  font-size: ${font.sizes.sm};
  font-weight: ${(props) => `${props.hasValue ? font.weights.semibold : font.weights.bold }`};
  color: ${(props) => `${props.hasValue ? theme.grey[600] : theme.grey[200]}`};
  background-color:  ${theme.grey[100]};
`;