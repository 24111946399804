import { ClockIcon, NoOngoingVisits, RedUserIcon } from 'src/assets/icons';
import {
  Row,
  Column,
  Card,
  PageLoader,
  SearchInput,
  ErrorComponent,
} from 'src/components';
import { generateTimeObject, openModal, theme } from 'src/utils';
import _ from 'lodash';
import { Appointments } from 'src/types';
import { useQuery } from '@apollo/client';
import {
  GET_APPOINTMENTS,
  APPOINTMENT_STATUS,
  SEARCH_APPOINTMENTS,
  PERMISSION_ACTION_MESSAGE,
  CURRENT_DATE,
} from 'src/constants';
import { useRowData, useStaff, useToast } from 'src/state';
import useSearch from 'src/hooks/useSearch';
import moment from 'moment';
import { TodoText, PaddingContainer } from '../styled';

interface OngoingVisitsCardProps {
  appointment: Appointments;
}

export const OngoingVisitsCard: React.FC<OngoingVisitsCardProps> = ({
  appointment,
}) => {
  const { updateRowData } = useRowData();
  const {  hasPermission  } = useStaff();
  const { showToast } = useToast();

  const startTimeObject = moment(
    generateTimeObject(
      appointment.appointmentDate,
      appointment.visitTime.startTime,
    ),
  ).format('hh:mm a');

  const endTimeObject = moment(
    generateTimeObject(
      appointment.appointmentDate,
      appointment.visitTime.endTime,
    ),
  ).format('hh:mm a');

  const dateObject = moment(appointment.appointmentDate)
    .calendar()
    .split(' ')[0];

  return (
    <Card
      width="100%"
      onClick={() => {
        if (!hasPermission("VIEW_APPOINTMENT_DETAILS")) {
          return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
        }
        openModal('view-appointment', appointment.id);
        updateRowData(
          'appointment',
          appointment as unknown as Record<string, unknown>,
        );
      }}
    >
      <Column width="100%" gap={0.75}>
        <TodoText color="inherit" weight="semibold">
          {appointment.type}
        </TodoText>
        <Column gap={0.5}>
          <Row width="max-content" align="center" gap={0.5}>
            <RedUserIcon />
            <TodoText size="xs" color={theme.grey[700]}>
              {`${_.startCase(appointment.patient.user.lastName)} ${_.startCase(
                appointment.patient.user.firstName,
              )}`}
            </TodoText>
          </Row>
          <Row width="max-content" align="center" gap={0.5}>
            <ClockIcon />
            <TodoText size="xs" color={theme.grey[700]}>
              {`${dateObject}, ${startTimeObject} - ${endTimeObject}`}
            </TodoText>
          </Row>
        </Column>
      </Column>
    </Card>
  );
};

export const AppointmentTab = () => {
  const { staff } = useStaff();
  const urlParams = new URLSearchParams(window.location.search);
  const facility = urlParams.get('facility') || staff.getStaff.facility.id;
  const {
    data: appointments,
    loading,
    error,
  } = useQuery(GET_APPOINTMENTS, {
    variables: {
      input: {
        status: APPOINTMENT_STATUS.ONGOING,
        startDate: CURRENT_DATE,
        endDate: CURRENT_DATE,
        facility,
      },
    },
    skip: staff.getStaff.staffGroup.name.toLowerCase() !== 'nurse',
    errorPolicy: 'all',
  });

  const { handleSearchChange, searchData, searchLoading } =
    useSearch(SEARCH_APPOINTMENTS);

  const data = searchData?.searchAppointments || appointments?.getAppointments;

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <Column gap={1} align="center" width="100%">
      <SearchInput
        placeholder="Search ongoing appointments"
        onChange={handleSearchChange}
      />
      {(loading || searchLoading) && <PageLoader />}
      <PaddingContainer width="100%" gap={1.25} align="stretch">
        {!(loading || searchLoading) &&
          data &&
          data?.length > 0 &&
          data.map((appointment: Appointments, idx: number) => (
            <OngoingVisitsCard key={idx} appointment={appointment} />
          ))}
        {!(loading || searchLoading) && data?.length === 0 && (
          <Column align="center" height="calc(100vh - 15rem)" justify="center">
            <NoOngoingVisits />
          </Column>
        )}
      </PaddingContainer>
    </Column>
  );
};
