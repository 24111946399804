import { CheckCircleIcon } from "src/assets/icons"
import { Column, H4, Button, Text, Row } from "src/components"
import { useRowData } from "src/state";
import { Settlement } from "src/types";
import { openModal, removeModalHash } from "src/utils";

const SuccessComponent: React.FC<{data: Settlement}> = ({ data }) => {
  const { updateRowData } = useRowData();

  return (
    <Column gap={1} align="center">
      <CheckCircleIcon />
      <Column maxWidth="25rem" align="center">
        <H4 align="center">New Settlement Created</H4>
        <Text align="center" size="sm">
          A new settlement has successfully been created
        </Text>
      </Column>
      <Row gap={1} justify="center">
        <Button
          background="neutral"
          color="black"
          type="button"
          width="max-content"
          onClick={() => removeModalHash()}
        >
          Close
        </Button>
        <Button
          type="button"
          width="max-content"
          onClick={() => {
            openModal("settlement-info", data.id)
            updateRowData('settlement', data)
          }}
        >
          View Settlement
        </Button>
      </Row>
    </Column>
  )
}

export default SuccessComponent;