import { Column, Paragraph, Row, injectModStyles } from 'src/components';
import { Modal } from 'src/containers';
import { ModStylesUtil, TextProps } from 'src/types';
import { theme, font } from 'src/utils';
import styled, { css } from 'styled-components';

export const Title = styled(Paragraph)`
  color: ${theme.grey[600]};
  max-width: 20rem;
  span {
    font-weight: ${font.weights.semibold};
    color: ${theme.grey[800]};
  }
`;

export const Multiplication = styled.p`
  margin-bottom: 1rem;
  color: ${theme.primary[700]};
`;

export const Hr = styled.hr`
  width: 100%;
  border-top: 1px solid ${theme.grey[100]};
`;

export const InvoiceModal = styled(Modal)<{ modStyles?: ModStylesUtil }>`
  h6 {
    @media (max-width: 960px) {
      font-size: ${font.sizes.base};
    }
  }
`;

export const TableContent = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  overflow: auto;
  table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
    thead > tr {
      position: sticky;
      top: 0;
      white-space: nowrap;
      background-color: ${theme.grey[100]};
      z-index: 5;
    }
    & th,
    td {
      text-align: start;
    }

    & th {
      padding: 1rem;
      color: ${theme.grey[700]};
      font-size: ${font.sizes.sm};
      font-weight: ${font.weights.semibold};
      @media (max-width: 960px) {
        font-size: ${font.sizes.xxs};
        padding: 1rem 0.5rem;
      }
    }

    & td {
      padding: 0.62rem;
      // font-weight: ${font.weights.normal};
      // color: ${theme.black[100]};
      white-space: nowrap;
      @media (max-width: 960px) {
        font-size: ${font.sizes.xs};
        padding: 1rem;
      }
    }
  }
`;

export const TableCell = styled.td<{ modStyles?: ModStylesUtil }>`
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;

export const TD = styled.td<
  {
    hasBorder?: boolean;
    colored?: boolean;
  } & TextProps
>`
  font-size: ${(props) => font.sizes[props.size ?? 'base']};
  color: ${(props) => props.color || theme.black[100]};
  font-weight: ${(props) =>
    font.weights[props.weight ?? 'normal'] || font.weights.normal};
  text-align: ${(props) => props.align || 'left'};
  margin: 0.5rem 0;
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
  ${({ hasBorder }) => {
    if (hasBorder)
      return css`
        border-bottom: 1px solid #e5e5e5;
      `;
    return '';
  }}
  ${({ colored }) => {
    if (colored)
      return css`
        background-color: ${theme.grey[100]};
      `;
    return '';
  }}
`;

export const TR = styled.tr<{
  noBorder?: boolean;
  colored?: boolean;
  highlight?: boolean;
}>`
  & td {
    text-transform: capitalize;
  }
  & td:first-child,
  th:first-child {
    text-align: left;
  }
  & td:not(:first-child):not(:last-child),
  th:not(:first-child):not(:last-child) {
    text-align: center;
  }
  & td:last-child,
  th:last-child {
    text-align: right;
  }
  ${({ noBorder }) => {
    if (!noBorder)
      return css`
        border-bottom: 1px solid #e5e5e5;
      `;
    return '';
  }}
  ${({ colored }) => {
    if (colored)
      return css`
        background-color: ${theme.grey[100]};
      `;
    return '';
  }}
  ${({ highlight }) => {
    if (highlight)
      return css`
        position: relative;
        td {
          background-color: #fedbd2;
          &:first-child {
            border-top-left-radius: 0.3125rem;
            border-bottom-left-radius: 0.3125rem;
          }
          &:last-child {
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
          }
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.3125rem;
          outline: 1px solid #fcb5a3;
        }
      `;
    return '';
  }}
`;

export const PreviewWrapper = styled(Column)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
`;

export const PreviewInner = styled(Column)`
  border-bottom: 10px solid #febf5f;
`;

export const PreviewHeader = styled(Row)`
  background-color: ${theme.grey[100]};
`;

export const ImagePreview = styled(Column)`
  position: relative;
  padding: 0.5rem;
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e5e5;
  background-color: ${theme.white[50]};
  & img {
    width: 100%;
  }
  & svg {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
  }
`;

export const ItemCard = styled(Row)<{ showFull?: boolean }>`
  position: relative;
  // max-height: ${(props) => (props.showFull ? 'none' : '100px')};
  overflow: hidden;
  border-radius: 10px;
  padding: 15px;
  border: 0.5px solid #f4f4f4;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
`;

export const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: linear-gradient(to bottom, transparent, white);
`;

export const ItemIndicator = styled.div`
  height: 0.94rem;
  width: 0.94rem;
  display: block;
  border-radius: 50%;
  border: 0.3125rem solid ${(props) => props.color};
  position: absolute;
  left: -2rem;
  top: 1.7rem;
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgba(135, 107, 101, 0.19);
`;
