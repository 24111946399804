import { ModStylesUtil } from 'src/types';
import { CancelIcon } from 'src/assets/icons';
import { removeModalHash } from 'src/utils/modalHelper';
import {
  Overlay,
  ModalCard,
  ModalChildContainer,
  ModalCloseButton,
  ModalChild,
} from './styled';

interface ModalProps {
  height?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  visible?: boolean;
  modStyles?: ModStylesUtil;
  handleDismiss?: () => void;
  onDismiss?: (visible: boolean) => void;
  closeButton?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  onDismiss,
  width,
  height,
  modStyles,
  children,
  closeButton = true,
  ...rest
}) => {
  const handleDismiss = () => {
    removeModalHash();
    if (onDismiss) {
      onDismiss(false);
    }
  };

  return (
    <Overlay>
      <ModalCard data-testid="modal" height={height} width={width} {...rest}>
        {closeButton && (
          <ModalCloseButton
            data-testid="close-modal"
            onClick={rest.handleDismiss ? rest.handleDismiss : handleDismiss}
            variant="text"
            size="1rem"
          >
            <CancelIcon />
          </ModalCloseButton>
        )}
        <ModalChild>
          <ModalChildContainer modStyles={modStyles}>
            {children}
          </ModalChildContainer>
        </ModalChild>
      </ModalCard>
    </Overlay>
  );
};
