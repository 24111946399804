import { gql } from '@apollo/client';
import { hmoFieldsFragment } from '../Fragments/hmo';
import { patientFieldsFragment } from '../Fragments/patient';


export const GET_HMOS = gql`
  query GetHmos($input: GetHmosInput) {
    getHmos(input: $input) {
      total
      hmos {
        ...HMOFields
      }
    }
  }
  ${hmoFieldsFragment}
`;

export const GET_HMO_LIST = gql`
  query GetHmoList {
    getHmoList {
      name
      logo
    }
  }
`;

export const GET_HMO = gql`
  query GetHmo($getHmoId: ID!) {
    getHmo(id: $getHmoId) {
      ...HMOFields
    }
  }
  ${hmoFieldsFragment}
`;

export const GET_HMO_PATIENTS = gql`
  query GetHMOPatients($input: GetHMOPatientsInput!) {
    getHMOPatients(input: $input) {
      total
      patients {
        ...PatientFields
      }
    }
  }
  ${patientFieldsFragment}
`;

export const HMO_OVERVIEW = gql`
  query HmoOverview($input: GetHmoOverviewInput!) {
    hmoOverview(input: $input) {
      totalInvoiceCountResult {
        value
        percent
      }
      totalInvoiceAmountResult {
        value
        percent
      }
      totalDebt
      numberOfPatients
      lastSettlementAmount
    }
  }
`;

export const GET_HMO_DEBT = gql`
  query Query($hmoId: ID!) {
    getHmoDebt(hmoId: $hmoId)
  }
`;