import { Column, Text } from 'src/components';
import { FormRenderer } from 'src/containers';
import { useState } from 'react';
import { useRoute } from 'wouter';
import {
  useMedicalSummary,
  useRecordTemplate,
  useStaff,
  useToast,
} from 'src/state';
import RecordSuccessModal from 'src/pages/admin/PatientDetails/MedicalTimeline/Forms/SuccessModal';
import { FormRendererModal } from 'src/pages/admin/PatientDetails/MedicalTimeline/styled';
import { useTimeline } from 'src/store';
import { FormLoader } from 'src/containers/FormRenderer/Loaders';

const FormModal: React.FC = () => {
  const { staff } = useStaff();
  const [, params] = useRoute('/admin/patients/:id');
  const urlParams = new URLSearchParams(window.location.hash);
  const id = urlParams.get('id') as unknown as string;
  const { fetchTimeline } = useTimeline();
  const { showToast } = useToast();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refreshMedicalSummary } = useMedicalSummary();
  const {
    useAddRecord,
    useFetchRecordTemplate,
    currentRecordTemplate: currTemplate,
  } = useRecordTemplate();

  const { addRecord, data } = useAddRecord();
  const { error, loading } = useFetchRecordTemplate(id, !!currTemplate?.id);

  const handleFormSubmit = async (formData: Record<string, unknown>) => {
    setIsSubmitting(true);
    await addRecord({
      patient: params?.id,
      facility: staff.getStaff.facility.id,
      provider: staff.getStaff.id,
      template: currTemplate?.id,
      data: formData.data,
    })?.then(async (res) => {
      if (res?.data) {
        if (id === process.env.REACT_APP_VITALS_FORM_ID) {
          await refreshMedicalSummary();
        }
        await fetchTimeline();
        showToast('Record saved', 'success');
        setSubmitted(true);
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        showToast('Error adding record', 'error');
      }
    });
  };

  return (
    <FormRendererModal>
      <Column minWidth="36vw">
        {loading && <FormLoader />}
        {error && <Text>Error loading form...</Text>}
        {currTemplate && !submitted && !loading && (
          <FormRenderer
            config={{
              id: currTemplate?.id,
              title: currTemplate?.title,
              description: currTemplate?.description,
              fields: currTemplate?.fields,
            }}
            onSubmit={handleFormSubmit}
            isSubmitting={isSubmitting}
          />
        )}
        {data && data.addRecord.success && !isSubmitting && (
          <RecordSuccessModal
            templateTitle={currTemplate?.title as string}
            setSubmitted={setSubmitted}
            recordId={data.addRecord.record.id}
          />
        )}
      </Column>
    </FormRendererModal>
  );
};

export default FormModal;
