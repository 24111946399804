import styled from 'styled-components';
import { BaseFlexProps } from 'src/types';
import { injectModStyles } from '..';

export const BaseFlex = styled.div<BaseFlexProps>`
  display: ${(props) => props.display || 'flex'};
  position: ${(props) => props.position || 'relative'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'flex-start'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'unset'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  background-color: ${(props) => props.background || 'initial'};
  box-sizing: border-box;
  gap: ${(props) => props.gap || '0'}rem;
  max-width: ${(props) => props.maxWidth || 'unset'};
  min-width: ${(props) => props.minWidth || 'unset'};
  max-height: ${(props) => props.maxHeight || 'unset'};
  min-height: ${(props) => props.minHeight || 'unset'};
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;

export const Row = styled(BaseFlex)`
  flex-direction: row;
`;

export const Column = styled(BaseFlex)`
  flex-direction: column;
  @media print {
    width: 100%;
  }
`;
