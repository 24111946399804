import { createSlice } from '@reduxjs/toolkit';
import { PatientJourneyType, StepData, StepStatus } from 'src/types';
import { RootState } from '..';

type InitialState = {
  [key: string]: PatientJourneyType | null;
};

const initialState: InitialState = {};

type PayloadType = {
  payload: {
    patientId: string;
    journey: PatientJourneyType;
  };
};

const patientJourneySlice = createSlice({
  name: 'patient-journey',
  initialState,
  reducers: {
    setPatientJourney: (state: InitialState, action: PayloadType) => {
      const { patientId, journey } = action.payload;
      state[patientId] = journey;
    },
    endPatientJourney: (
      state: InitialState,
      action: { payload: { patientId: string } },
    ) => {
      const { patientId } = action.payload;
      state[patientId] = null;
    },
    addJourneyStep: (
      state: InitialState,
      action: { payload: { patientId: string; step: StepData } },
    ) => {
      const { patientId, step } = action.payload;
      state[patientId]?.steps.push(step);
    },
    deleteJourneyStep: (
      state: InitialState,
      action: { payload: { patientId: string; stepId: string } },
    ) => {
      const { patientId, stepId } = action.payload;
      const steps = state[patientId]?.steps.filter((s) => s.id !== stepId);

      state[patientId] = {
        ...(state[patientId] as PatientJourneyType),
        steps: steps as StepData[],
      };
    },
    updateJourneyStepStatus: (
      state: InitialState,
      action: {
        payload: { patientId: string; stepId: string; status: StepStatus };
      },
    ) => {
      const { patientId, stepId, status } = action.payload;
      if (!state[patientId]) return;
      const steps = state[patientId]?.steps.map((s) => {
        if (s.id === stepId) {
          return {
            ...s,
            status,
          };
        }
        return s;
      });
      state[patientId] = {
        ...(state[patientId] as PatientJourneyType),
        steps: steps as StepData[],
      };
    },
    updateJourneyStepActionItem(
      state: InitialState,
      action: {
        type: string;
        payload: {
          patientId: string;
          stepId: string;
          arrIdx: number;
          checked: boolean;
          step: StepData;
        };
      },
    ) {
      const { patientId, arrIdx, stepId, checked, step } = action.payload;
      if (!state[patientId]) return;
      const steps = state[patientId]?.steps?.map((s) => {
        if (s.id === stepId) {
          return {
            ...s,
            status: checked ? 'STARTED' : s.status,
            eventLogs: step.eventLogs,
            updatedAt: step.updatedAt,
            actions: s.actions.map((a, idx) =>
              idx === arrIdx ? { ...a, isCompleted: checked } : a,
            ),
          };
        }
        return s;
      });
      state[patientId] = {
        ...(state[patientId] as PatientJourneyType),
        steps: steps as StepData[],
      };
    },
  },
});

export const getPatientJourney = (patientId: string) => (state: RootState) => {
  const patientJourney = state.patientJourneys[patientId];
  return patientJourney;
};

export const getStepById =
  (patientId: string, stepId: string) => (state: RootState) => {
    const patientJourney = state.patientJourneys[patientId];
    return patientJourney?.steps.find((step) => step.id === stepId);
  };

export const {
  setPatientJourney,
  updateJourneyStepActionItem,
  updateJourneyStepStatus,
  endPatientJourney,
  addJourneyStep,
  deleteJourneyStep,
} = patientJourneySlice.actions;

export default patientJourneySlice.reducer;
