import { Button, Column, ErrorComponent, H6, Text } from 'src/components';
import { useLocation } from 'wouter';
import { useContext } from 'react';
import ViewWrapper from 'src/modals/PatientJourney/ViewWrapper';
import { strictBatchValidation, getModalParams } from 'src/utils';
import { useFormik } from 'formik';
import { Product, ProductBatchType } from 'src/types';
import Validator from 'validatorjs';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { SolidCheck } from 'src/assets/icons';
import { useQuery } from '@apollo/client';
import { GET_EXPIRY_ALERT, GET_PRODUCT, GET_PRODUCTS } from 'src/constants';
import { useInventoryApis } from 'src/store';
import { useToast } from 'src/state';
import BatchForm from '../components/BatchForm';
import { ViewContext } from '.';

const EditBatch = () => {
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const productId = getModalParams('id');
  const batchId = getModalParams('batchId');
  const { editProductBatch, editProductBatchLoading } = useInventoryApis();
  const { showToast } = useToast();

  const {
    data,
    // loading: isProductLoading,
    error: productError,
  } = useQuery(GET_PRODUCT, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: !productId,
    variables: {
      input: productId,
    },
  });

  const product = data?.getProduct as Product;
  const batch = product && product?.batches?.find((b) => b.id === batchId);

  const goBack = () => {
    setView('details');
    setLocation(`#product&id=${productId}&mView=details`);
  };

  const { handleChange, handleSubmit, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        batch: {
          batchNumber: batch?.batchNumber,
          quantity: batch?.quantity,
          buyingPrice: batch?.buyingPrice,
          sellingPrice: batch?.sellingPrice,
          expiryAlert: batch?.expiryAlert,
          stockAlert: batch?.stockAlert,
          expiryDate: batch?.expiryDate,
          status: batch?.status,
          quantityLeft: batch?.quantityLeft,
        },
      },
      validateOnChange: false,
      enableReinitialize: true,
      validate: (validationValues) => {
        const validation = new Validator(
          validationValues,
          strictBatchValidation,
        );
        validation.passes();
        return validation.errors.errors;
      },
      onSubmit: async (value) => {
        delete value.batch.quantityLeft;
        await editProductBatch({
          variables: {
            batchId,
            input: { ...value.batch },
          },
          refetchQueries: [
            GET_PRODUCTS,
            GET_EXPIRY_ALERT,
            { query: GET_PRODUCT, variables: { input: productId } },
          ],
          awaitRefetchQueries: true,
          onCompleted(d) {
            if (d?.editProductBatch) {
              showToast(d.editProductBatch?.message, 'success');
              goBack();
            }
          },
        });
        return value;
      },
    });

  if (productError) {
    return <ErrorComponent />;
  }

  return (
    <ViewWrapper goBack={goBack}>
      <Column gap={1.5}>
        <Column gap={0.3}>
          <H6 modStyles={{ ma: 0 }}>Edit Batch</H6>
          <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
            Make changes to this batch details
          </Text>
        </Column>
        <FullWidthForm onSubmit={handleSubmit}>
          <BatchForm
            values={values.batch as ProductBatchType}
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={handleChange}
          />
          <Button
            variant="solid"
            width="max-content"
            type="submit"
            data-testid="save-batch-edit-btn"
            isLoading={editProductBatchLoading}
            modStyles={{ ml: 'auto' }}
            noIconStyles
          >
            <SolidCheck /> Save Edit
          </Button>
        </FullWidthForm>
      </Column>
    </ViewWrapper>
  );
};

export default EditBatch;
