import styled from 'styled-components';
import { AvatarProps } from 'src/types';
import { theme } from 'src/utils/variables';
import { injectModStyles } from '../ModStyles';

export const Avatar = styled.img<AvatarProps>`
  display: block;
  border-radius: 50%;
  max-width: 100%;
  min-width: ${(props) => `${props.size}px` || '50px'};
  object-fit: ${(props) => `${props.objectFit}` || 'cover'};
  background-color: ${theme.grey[300]};
  padding: 0.2em;
  width: ${(props) => `${props.size}px` || '50px'};
  height: ${(props) => `${props.size}px` || '50px'};
  ${(props) => props.modStyles && injectModStyles(props.modStyles)};
`;
